import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";

export default function GraficoPadraoComDataVerticalCaminhao({
	url,
	title,
	unidadeMedida = "",
	parametroGrafico,
	posisaoUnidadeMedida,
	colors = "#f60809",
	setCaminhaoSelecionado = () => {},
	dataParam,
	arrayData,
	dataFiltered
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-caminhoes",
				toolbar: {
					show: true
				}
				
			},
			grid: {
				show: false,
			},
			colors: [colors],
			plotOptions: {
				bar: {
					horizontal: false,
					dataLabels: {
						position: "top",
						orientation: "vertical",
					},
				},
				dataLabels: {
					enabled: true,
					style: {
						colors: [colors],
					},
					// offsetX: 150
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					return `${numerosEditadoBR(value)}`;
				},
				offsetY: 10,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},
				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				style: {
					fontSize: "10px",
				},
				fixed: {
					enabled: false,
					position: "topLeft",
					offsetX: 50,
					offsetY: 30,
				},
			},
			xaxis: {
				labels: {
					show: true,
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: false,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		setLoading(true);
		let resultado = dataFiltered || arrayData;

		setOptions((last) => ({
			...last,
			series: [
				{
					name: parametroGrafico.replaceAll('_',' ').toUpperCase(),
					data: resultado
						.sort((a, b) => {
							if (Number(a[parametroGrafico]) > Number(b[parametroGrafico])) return 1;
							if (Number(a[parametroGrafico]) < Number(b[parametroGrafico])) return -1;
							return 0;
						})
						.filter((item) => item[parametroGrafico] > 0)
						.map((item) => Number(item[parametroGrafico]).toFixed(1)),
				},
			],
			options: {
				...options.options,
				title: {
					text: `Total ${parametroGrafico}: ${resultado
							.sort((a, b) => {
								if (Number(a[parametroGrafico]) > Number(b[parametroGrafico])) return 1;
								if (Number(a[parametroGrafico]) < Number(b[parametroGrafico])) return -1;
								return 0;
							})
							.filter((item) => item[parametroGrafico] > 0)
							.map((item) => Number(item[parametroGrafico]).toFixed(1)).reduce((p, c) => p + Number(c), 0).toLocaleString('pt-br')
						}`,
				},
				xaxis: {
					...options.options.xaxis,
					categories: resultado
						.sort((a, b) => {
							if (Number(a[parametroGrafico]) > Number(b[parametroGrafico])) return 1;
							if (Number(a[parametroGrafico]) < Number(b[parametroGrafico])) return -1;
							return 0;
						})
						.filter((item) => item[parametroGrafico] > 0)
						.map((item) => item.placa),
				},
			},
		}));
		
		setTimeout(() => {
				setLoading(false);
		}, 1000);
	}, [arrayData, dataFiltered]);

	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center  pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				{/* <span className="filter-data" title={periodoInicial} onClick={() => setDataParam(last => ({...last, show: true}))}>
                    Filtrar Data 
                    <span className="filho">{periodoInicial}</span>
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							// height={400}
						/>
					</div>
				)}
			</Card.Body>
			
			{/* {dataParam.show ? <ModalAtualizarDataEntradaVST title={'Perido do Gráfico'} show={dataParam.show} setShow={setDataParam} {...dataParam} setDataParam={setDataParam} fn={getApiData} /> : ""} */}
		</Card>
	);
}
