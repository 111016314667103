import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { insertPedidos, insertProdutos } from "../../services/Servicos";
import Loading from "../loading/Loading";
import ModalError from "../modal/ModalError";
import { ListarCarga } from "../planoLogistica/cargas/Cargas";

import "./Modal.scss";

const headList = [
	"index",
	`admin`,
	`id`,
	`nome`,
	`peso_total`,
	`volume_total`,
	`qtde_pedidos`,
	`qtde_produtos`,
	`observacao`,
	`status`,
	`data_saida`,
	`created_at`,
	`updated_at`,
	`caminhao`,
	`rota`,
	`data_chegada`,
	`caminhoneiro`,
];
export default function ModalAtribuirProdutos({
	msg,
	title,
	show,
	setShow,
	msgButton,
	setCarga,
	carga,
	setPedidos = () => {},
	setAtribuido = () => {},
}) {
	const user = useSelector((state) => state.userSlice);
	const [nomeCarga, setNomeCarga] = useState({
		cargas_id: "",
		nome: "",
		disponivel: false,
	});

	const [btnAtribuir, setBtnAtribuir] = useState({
		flag: false,
		msg: "Atribuir",
		style: "primary",
	});
	const [modalError, setModalError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const handlerAttProdutos = async (e) => {
		setBtnAtribuir((last) => ({ ...last, flag: true, msg: "Atribuindo" }));
		setTimeout(() => {
			setBtnAtribuir((last) => ({
				...last,
				flag: false,
				msg: "Atribuido",
				style: "success",
			}));
			setTimeout(() => {
				setPedidos((last) => [
					...last.map((item) => {
						if (
							item.idFilial == show.pedido.id_filial &&
							item.codigoPedido == show.pedido.numero_pedido
						) {
							item.stLogistica = 0;
						}
						return item;
					}),
				]);
			}, 500);
		}, 500);

		insertPedidos(show.pedido, user.tipo)
			.then((resp) => {
				const promiseAll = Promise.all([
					...show.produtos.map((item) => insertProdutos(item, user.tipo)),
				]);
				promiseAll
					.then((resp) => {
						setAtribuido(true);
						setBtnAtribuir((last) => ({
							...last,
							flag: false,
							msg: "Concluido",
							style: "success",
						}));
						setTimeout(() => {
							handlerSimpleClose();
						}, 1000);
					})
					.catch((error) => {
						setBtnAtribuir((last) => ({
							...last,
							flag: false,
							msg: "Erro!!!",
							style: "danger",
						}));
						setErrorMsg(error.msg + ". Contate o Suporte!");
						setModalError(true);
					});
			})
			.catch((error) => {
				setBtnAtribuir((last) => ({
					...last,
					flag: false,
					msg: "Erro!!!",
					style: "danger",
				}));
				setBtnAtribuir((last) => ({
					...last,
					flag: false,
					msg: "Erro!!!",
					style: "danger",
				}));
				setErrorMsg(error.msg + ". Contate o Suporte!");
				setModalError(true);
			});
	};

	const handlerSimpleClose = () => setShow((last) => ({ flag: false }));

	useEffect(() => {
		setShow((last) => ({
			...last,
			pedido: {
				...last.pedido,
				cargas_id: nomeCarga.id,
			},
			produtos: [
				...last.produtos.map((item) => {
					item.cargas_id = nomeCarga.id;
					return item;
				}),
			],
		}));
	}, [nomeCarga]);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>{title}:</span>
						<span>
							<b>
								{nomeCarga.id
									? `${Object.keys(nomeCarga)
											.filter(
												(item) =>
													item === "nome" ||
													item === "id" ||
													item === "qtde_produtos"
											)
											.map((key) => {
												if (key === "disponivel") {
												} else {
													return `${key}:${nomeCarga[key]} `;
												}
											})}`
									: ""}
							</b>
						</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListarCarga
						headList={headList}
						height={300}
						setNomeCarga={setNomeCarga}
					/>
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button
						variant={btnAtribuir.style}
						disabled={
							btnAtribuir.style === "success" ||
							btnAtribuir.style === "danger" ||
							!nomeCarga.disponivel
						}
						onClick={handlerAttProdutos}
					>
						{btnAtribuir.flag ? (
							<Loading msg={"Carregando"} />
						) : (
							<span style={{ color: "#ffffff" }}>
								{btnAtribuir.msg}
								{btnAtribuir.style === "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</span>
						)}
					</Button>
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>
			<ModalError
				msg={errorMsg}
				setShow={setModalError}
				show={modalError}
				title="Erro na Criação do Romaneio"
			/>
		</>
	);
}
