import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useLayoutEffect,
	useState,
} from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../loading/Loading";
import {
	getAllData,
	getTableColumns,
	Unauthorized,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import { update, updateRotasList } from "../../../redux/slicers/user";
import BodyRowRotas from "../../table/body/BodyRowRotas";
import HeadTable from "../../table/head/HeadRow";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	getErrorMessage,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

const tbodyRef = createRef();
const tableDiv = createRef();
const formFiltros = createRef();
let lastFilter = "";

export default function RotasCadastradas() {
	const rotas = useSelector((state) => state.userSlice.rotasList);
	const user = useSelector((state) => state.userSlice);

	const [headList, setHead] = useState([]);

	const [loading, setLoading] = useState(false);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList((last) => last + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const updateRotasArray = async (e) => {
		const [header, rotas] = await Promise.all([
			getTableColumns("Rotas"),
			getAllData("rotas", {
				id_filial: user.tipo == "empresa" ? null : user.id_filial,
			}),
		]);
		setHead([
			"index",
			'admin',
			'id_filial',
			...Object.keys(header.data)
				.filter((key) => key !== "empresas_id")
				.filter((key) => key !== "opcao_selecionada")
				.filter((key) => key !== "created_at")
				.filter((key) => key !== "updated_at")
				.filter((key) => key !== "id")
				.filter((key) => key !== "id_filial"),
		]);
		dispatch(updateRotasList(rotas.data));
	};

	useLayoutEffect(() => {
		updateRotasArray();
	}, []);

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [rotas.data];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const [error, setError] = useState({
		show: false,
		message: "",
	});

	const getRotas = async () => {
		try {
			const { data } = await getAllData("rotas", {
				id_filial: user.tipo == "empresa" ? null : user.id_filial,
			})
			dispatch(updateRotasList(data));
		} catch (error) {
			setError({ show: true, message: getErrorMessage(error) });

		}
	}
	return (
		<Fragment>
			<div className="section-filters-table mt-5">
				<div className="card-header">
					<h3>Filtros Selecionados</h3>
				</div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header">
					<h3 style={{ display: "flex", alignItems: "center" }}>
						Tabela de Rotas{" "}
						{loading ? (
							<Spinner
								style={{ marginLeft: "10px", fontSize: "1rem" }}
								animation="border"
								size="sm"
							/>
						) : (
							""
						)}
					</h3>
				</div>

				<ScrollbarLeft tableRef={tableDiv} coluna2={3} maxima_distancia={70} />
				<div className="responsive-table" ref={tableDiv}>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								arrayValues={filteredTable ? filteredTable : [...rotas.data]}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>
						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowRotas
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													getRotas={getRotas}
												/>
											);
										}
								  })
								: [...rotas.data].reverse().map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowRotas
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													getRotas={getRotas}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < rotas.data.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</Fragment>
	);
}
