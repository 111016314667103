import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";

export default function GraficoPadraoComDataHorizontalCaminhao({
	url,
	title,
	unidadeMedida = "",
	parametroGrafico,
	posisaoUnidadeMedida,
	colors = "#f60809",
	setCaminhaoSelecionado = () => {},
	dataParam,
	arrayData,
	dataFiltered
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
				events: {
					click: function (event, chartContext, config) {
						let ID = config.config.xaxis.categories[config.dataPointIndex];
						setCaminhaoSelecionado(ID);
					},
				},
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					// columnWidth: '70%',
					barHeight: "35%",
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value)}`;
					}
					return `${numerosEditadoBR(value)} ${unidadeMedida} `;
				},
				offsetX: 20,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},

				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				style: {
					fontSize: "10px",
				},
				fixed: {
					enabled: false,
					position: "topLeft",
					offsetX: 50,
					offsetY: 30,
				},
			},
			xaxis: {
				labels: {
					show: false,
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: true,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		let resultado = dataFiltered || arrayData;
		let caminhoes = resultado.filter(
			(i, n, s) => {
				return s.findIndex((o) => o.caminhao === i.caminhao) === n
			 }
		);

		const resultatoGeral = caminhoes
			.map((item) => {
				return {
					caminhao: item.caminhao,
					valorTotal: resultado
						.filter((item2) => item2.caminhao == item.caminhao)
						.reduce((p, c) => p + c[parametroGrafico], 0),
				};
			})
			.sort((a, b) => {
				if (a.valorTotal > b.valorTotal) return 1;
				if (a.valorTotal < b.valorTotal) return -1;
				return 0;
			})
			.filter((item) => item.valorTotal)
			.reverse();

		setOptions((last) => ({
			...last,
			options: {
				...last.options,
				colors: [colors],
				xaxis: {
					...last.options.xaxis,
					show: false,
					categories: resultatoGeral.map((item) => item.caminhao),
				},
			},
			series: [
				{
					name: title,
					data: resultatoGeral.map((item) =>
						Number(item.valorTotal).toFixed(3)
					),
				},
			],
			length: resultatoGeral.length < 7 ? 300 : 500
		}));
		
		setTimeout(() => {
				setLoading(false);
		}, 1000);
	}, [arrayData, dataFiltered]);

	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center  pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				{/* <span className="filter-data" title={periodoInicial} onClick={() => setDataParam(last => ({...last, show: true}))}>
                    Filtrar Data 
                    <span className="filho">{periodoInicial}</span>
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							height={options.length}
						/>
					</div>
				)}
			</Card.Body>
			
			{/* {dataParam.show ? <ModalAtualizarDataEntradaVST title={'Perido do Gráfico'} show={dataParam.show} setShow={setDataParam} {...dataParam} setDataParam={setDataParam} fn={getApiData} /> : ""} */}
		</Card>
	);
}
