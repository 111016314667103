import { useLayoutEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getTableColumns } from "../../services/Servicos"

import './Modal.scss'
import PedidosVstFromCargas from "../planoLogistica/apiVST/PedidosVstFromCargas"
import PedidosDeTransferencia from "../planoLogistica/apiVST/PedidosDeTransferencia"

export default function ModalAddPedidosTransferencia({ title, show, setShow, transferencia, cargas_id, cidades = [], id_filial }) {
    const [headList, setHead] = useState(['index'])


    const updateArrayProdutos = async () => {
        const romaneiosGet =  await getTableColumns('CargasProdutos')
        setHead(last => ([...last, ...Object.keys(romaneiosGet.data)]))
    }

    useLayoutEffect(() => {
        updateArrayProdutos()
    },[])


    const handlerSimpleClose = () => setShow(last => ({ error: { flag: false } }))
    

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <span>
                            {title}: <b>Carga N°{cargas_id}, Transferencia { transferencia }</b> 
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PedidosDeTransferencia cargas_id={cargas_id} filters_init={[{ idFilial: "" + id_filial }]} transferencia={ transferencia } />
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border">
                    <Button variant='secondary' onClick={handlerSimpleClose}>Sair</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

