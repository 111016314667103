import { Fragment, useEffect, useRef, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
// import ModalAtualizarUsuarios from "../../modal/ModalAtualizarUsuarios"

import "./BodyRow.scss";
import ModalFotoReport from "../../diarioDeBordo/ModalFotoReport";
import { locationDev } from "../../../services/Servicos";

export default function BodyRowDiarios({
	headList,
	bodyList,
	index,
	setArrayFilters = () => {},
	arrayFilters,
}) {
	const caminhoneiros = useSelector((state) => state.userSlice.funcionariosList)
		.data.filter((item) => item.tipo === "Caminhoneiro")
		.map((item) => item.cpf);

	const ref = useRef(null);
	const refRow = useRef(null);
	const refEditar = useRef(null);
	const refExcluir = useRef(null);

	const [adminFunction, setAdminFunction] = useState({
		flag: false,
		imgSrc: "",
	});

	// const [showImg, setShowImg] = useState({
	//     flag: false,
	//     url:''
	// })

	const onChangeCaminhoneiros = (e) => {
		e.preventDefault();
	};

	const onClickEditarRow = (e) => {
		e.preventDefault();
		if (headList.indexOf("placa") >= 0) {
			setAdminFunction({
				error: {
					flag: true,
					title: "Editar Caminhão",
					msg: {
						title: `Deseja Editar o caminhão:`,
						msg: {
							...refRow.current.children,
							pk: refRow.current.children[5].innerText,
						},
					},
					confirmar: "Atualizar",
				},
			});
		} else if (headList.indexOf("cpf") >= 0) {
			setAdminFunction({
				error: {
					flag: true,
					title: "Editar Usuário",
					msg: {
						title: `Deseja editar o usuário:`,
						msg: {
							...refRow.current.children,
							pk: refRow.current.children[3].innerText,
						},
					},
					confirmar: "Atualizar",
				},
			});
		}
	};

	const onClickExcluirRow = (e) => {
		e.preventDefault();

		let ref1 = null,
			ref2 = null,
			ref3 = null,
			ref4 = null;

		if (headList.indexOf("placa") >= 0) {
			ref1 = refRow.current.children[3].innerText; //status
			ref2 = refRow.current.children[4].innerText; //tipo
			ref3 = refRow.current.children[6].innerText; //placa

			setAdminFunction({
				error: {
					flag: true,
					title: "Excluir Caminhão",
					msg: {
						title: `Deseja excluir o caminhão:`,
						msg: {
							TIPO: ref2,
							PLACA: ref3,
							STATUS: ref1,
						},
					},
					confirmar: "Excluir",
				},
			});
		} else if (headList.indexOf("cpf") >= 0) {
			ref4 = refRow.current.children[3].innerText; //ID
			ref1 = refRow.current.children[4].innerText; //tipo
			ref2 = refRow.current.children[5].innerText; //nome
			ref3 = refRow.current.children[6].innerText; //cpf

			setAdminFunction({
				error: {
					flag: true,
					title: "Excluir Usuário",
					msg: {
						title: `Deseja excluir o usuário:`,
						// msg: `Tipo: ${ref1}; Nome: ${ref2}; CPF: ${ref3}; ID: ${ref4}`
						msg: {
							TIPO: ref1,
							NOME: ref2,
							CPF: ref3,
							ID: ref4,
						},
					},
					confirmar: "Excluir",
				},
			});
		}
	};

	// useEffect(() => {
	//     headList
	//         .filter(item => item !== 'admin')
	//         .filter(item => item !== 'index')
	//         .forEach(element => {
	//
	//             if (arrayFilters[element]) {
	//                 setArrayFilters(last => ({
	//                     ...last,
	//                     [element]: [
	//                         ...last[element] ,
	//                         { show: false, value: bodyList[element] }
	//                     ]
	//                 }))

	//             }
	//     });
	// }, [bodyList])

	return (
		<Fragment>
			{
				<tr ref={refRow} style={{ verticalAlign: "middle" }}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Excluir"
												onClick={onClickExcluirRow}
												ref={refExcluir}
											></i>
										</span>
										<span className="editar">
											<i
												className="bi bi-pencil"
												title="Editar"
												onClick={onClickEditarRow}
												ref={refEditar}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "mensagem") {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									<textarea value={bodyList[item]} rows={4}></textarea>
								</td>
							);
						} else if (item === "status") {
							return bodyList[item] ? (
								<td key={_index} className={item}>
									<div className="div-status">
										<span className="div-status-ativo">Ativo</span>
									</div>
								</td>
							) : (
								<td key={_index} className={item}>
									<div className="div-status">
										<span className="div-status-inativo">Inativo</span>
									</div>
								</td>
							);
						} else if (item === "Atribuir") {
							return (
								<td key={_index}>
									<Form.Select onChange={onChangeCaminhoneiros} ref={ref}>
										<option value=""></option>
										{caminhoneiros.map((item, index__) => (
											<option value={item} key={index__}>
												{item}
											</option>
										))}
									</Form.Select>
								</td>
							);
						} else if (
							item === "createdAt" ||
							item === "updatedAt" ||
							item === "name"
						) {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "10rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "upload_file") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "100px" }}>
									<Card.Img
										variant="top"
										src={locationDev + "/files/users" + bodyList[item]}
										style={{ cursor: "pointer" }}
										onClick={(e) =>
											setAdminFunction({ flag: true, imgSrc: bodyList[item] })
										}
									/>
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{adminFunction.flag ? (
				<ModalFotoReport
					imgSrc={adminFunction.imgSrc}
					setShow={setAdminFunction}
					show={adminFunction.flag}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
