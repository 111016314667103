import { useLayoutEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getTableColumns } from "../../services/Servicos"
import { ListarProdutos } from "../planoLogistica/romaneios/Romaneios"

import './Modal.scss'

export default function ModalListarProdutos({ title, show, setShow, pedido, cargas_id }) {

    const [headList, setHead] = useState(['index','admin','conferido','planejado','carregado','descarregado'])


    const updateArrayProdutos = async () => {
        const romaneiosGet =  await getTableColumns('CargasProdutos')
        setHead(last => ([...last
            , ...Object
                .keys(romaneiosGet.data)
                .filter(item => item !== 'qr_code_id')
                .filter(item => item !== 'data_cancelamento')
        ]))
    }

    useLayoutEffect(() => {
        updateArrayProdutos()
    },[])

    const handlerSimpleClose = () => setShow(last => ({ error: { flag: false } }))


    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <span>
                            {title}: 
                        </span>
                        <span>
                            <b>
                            {pedido.numero_pedido} - {pedido.cargas_id}
                            </b>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListarProdutos headList={headList} height={500} numero_pedido={pedido.numero_pedido} cargas_id={pedido.cargas_id || pedido.id} />
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border">
                    <Button variant='secondary' onClick={handlerSimpleClose}>Sair</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

