import { Fragment, useEffect, useRef, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { QRCodeSVG } from "qrcode.react"

import './Modal.scss'


export default function ModalSuccessPretty({ show, setShow, message  }) {

    const handlerSimpleClose = () => setShow(last => ({ ...last, show: false }))

    return (
        <>
            <Modal 
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='sm'
                style={{
                    backdropFilter:'brightness(0.3)'
                }}
            >                    
                <Modal.Body className="pt-0">
                    <Row className="text-center">
                        <Col sm='12' className="mb-2">
                            <div style={{
                                position: 'absolute',
                                left: '100px',
                                top:'-70px',
                                width: '100px', height: '100px', background: 'green', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}>
                                <i className="bi bi-check-circle text-white" style={{fontSize:'4rem'}}></i>
                            </div>
                        </Col>
                        <Col sm='12' className="mt-5 mb-3">
                            <h4>Tudo certo!</h4>
                        </Col>
                        <Col sm='12' className="mb-4">
                            "<i style={{fontSize:'1rem'}}>{message}</i>"
                        </Col>
                        <Col sm='12' className="mb-2">
                            <Button variant='success' onClick={handlerSimpleClose} style={{width:'150px'}}>Ok</Button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        </>
    )
}
