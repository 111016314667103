import { Modal } from "react-bootstrap"



export default function ModalFoto({ show, setShow, imgSrc, alternativo }) {
    
    const handlerSimpleClose = () => setShow(last => ({ ...last, flag: false }))

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Foto Enviada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{textAlign:"center"}}>
                        <img  src={imgSrc} alt={alternativo} width={'80%'} height={'100%'}  />
                    </p>
                </Modal.Body>
            </Modal>
        </>
    )
}
