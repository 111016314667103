import { useState } from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	update,
	updateCargaSelecionada,
	updateCarga_Id,
	updateEmbalagensList,
	updateProdutosList,
	updateRomaneiosList,
} from "../../redux/slicers/user";
import { updateTable } from "../../services/Servicos";

import "./Modal.scss";
import {
	danfeController,
	fotosController,
	removeProduto,
} from "../../redux/slicers/carga";

export default function ModalIniciarEntrega({
	msg,
	title,
	show,
	setShow,
	cargas_id,
	processo,
	downloadDanfes = () => {},
}) {
	const dispatch = useDispatch();
	const carga_selecionada = useSelector(
		(state) => state.userSlice.cargaSelecionada
	);

	const handlerSimpleClose = () => setShow(false);
	const [loading, setLoading] = useState({
		flag: false,
		msg: "Confirmar",
		style: "primary",
	});
	const [data, setData] = useState(null);

	const confirmarInicio = async () => {
		const data = new Date();
		setData(data);
		let body = {
			[processo]: data.toISOString(),
		};

		try {
			for await (let carga of carga_selecionada) {
				if (processo === "data_chegada") {
					dispatch(
						update({
							processo_entrega: {
								flag: false,
								locations: [],
							},
						})
					);

					if (carga.regiao_entrega == "LOCAL") {
						body.status = 15;
					}
				} else {
					dispatch(
						update({
							processo_entrega: {
								flag: true,
								locations: [],
							},
						})
					);
				}

				setLoading({
					flag: true,
					msg: "Atualizando...",
					style: "warning",
				});

				await updateTable(
					"cargas/motorista/update-carga-iniciar-entrega",
					carga.id,
					body
				);
			}

			if (processo === "data_chegada") {
				// resetando informações
				dispatch(updateCargaSelecionada([]));
				dispatch(updateCarga_Id(null));
				dispatch(updateRomaneiosList([]));
				dispatch(updateProdutosList([]));
				dispatch(updateEmbalagensList([]));
				dispatch(removeProduto([]));
				dispatch(
					danfeController({
						action: "removeAll",
					})
				);
				dispatch(
					fotosController({
						action: "delete-all",
					})
				);
			} else {
				setLoading({
					flag: true,
					msg: "Baixando Danfes...",
					style: "warning",
				});
				await downloadDanfes();
				dispatch(
					updateCargaSelecionada(
						carga_selecionada.map((item) => ({
							...item,
							[processo]: body[processo],
						}))
					)
				);
			}
			setLoading({
				flag: false,
				msg: "Enviado",
				style: "success",
			});

			setTimeout(() => {
				window.location.replace("/dashboard");
			}, 1500);
		} catch (error) {}
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{msg}
					<br></br>
					<p style={{ margin: 0 }}>
						<b>Data: {data?.toLocaleDateString("pt-BR")}</b>
					</p>
					<p style={{ margin: 0 }}>
						<b>Hora: {data?.toLocaleTimeString("pt-BR")}</b>
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Col>
						<Button
							variant={loading.style}
							onClick={confirmarInicio}
							disabled={!loading.style.includes("primary")}
						>
							{loading.msg}
							{loading.flag ? (
								<Spinner animation="border" color="black" size="sm" />
							) : (
								""
							)}
							{loading.style === "success" ? (
								<i className="bi bi-check"></i>
							) : (
								""
							)}
						</Button>
					</Col>
					<Col>
						<Button variant="secondary" onClick={handlerSimpleClose}>
							Fechar
						</Button>
					</Col>
				</Modal.Footer>
			</Modal>
		</>
	);
}
