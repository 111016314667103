import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateProdutosListDimensoes } from "../../redux/slicers/user";
import {
	apenasNumeros,
	numerosDecimal3,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";

import "./Modal.scss";
import LoadingNew from "../loading/LoadingNew";

export default function ModalCalculadora(props) {
	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));
	const user = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();
	const [showLoading, setShowLoading] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar",
	});
	const [parametros, setParametros] = useState({
		altura: "0",
		comprimento: "0",
		largura: "0",
	});

	const onChangeValue = (e) => {
		e.preventDefault();
		setParametros((last) => ({
			...last,
			[e.target.name]: numerosDecimal3(apenasNumeros(e.target.value)),
		}));
	};

	const confirmarEdicao = async () => {
		setShowLoading((last) => ({
			...last,
			flag: true,
			style: "warning",
			msg: "Salvando...",
		}));
		let url = verificaTipoUsuario(user) + "cargas/gestao/produtos/volume";

		const volumeTotal = Number(
			Number(parametros.altura.replace(",", ".")) *
				Number(parametros.comprimento.replace(",", ".")) *
				Number(parametros.largura.replace(",", "."))
		).toFixed(3);
		const body = {
			volume: volumeTotal,
			qr_sku: props.qr_sku,
			cargas_id: props.cargas_id,
		};

		//
		//
		updateTable(url, body.cargas_id, body);
		dispatch(
			updateProdutosListDimensoes({
				qr_sku: body.qr_sku,
				dimension: { name: "volume", value: volumeTotal },
			})
		);

		setTimeout(() => {
			setShowLoading((last) => ({
				...last,
				flag: false,
				style: "success",
				msg: "Salvo",
			}));

			setTimeout(() => {
				setShowLoading((last) => ({
					...last,
					flag: false,
					style: "primary",
					msg: "Salvar",
				}));
				handlerSimpleClose();
			}, 750);
		}, 1000);
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>Calcular Volume</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => e.preventDefault()}>
						<Row className="align-items-center justify-content-center">
							<Col sm="3">
								<Form.Label name="parameter">Altura:</Form.Label>
								<Form.Control
									type="text"
									className="Form-control mb-2"
									placeholder={props.currentValue}
									onChange={onChangeValue}
									name="altura"
									value={parametros.altura}
								/>
							</Col>
							<Col sm="1">X</Col>
							<Col sm="3">
								<Form.Label name="parameter">Comprimento:</Form.Label>
								<Form.Control
									type="text"
									className="Form-control mb-2"
									placeholder={props.currentValue}
									onChange={onChangeValue}
									name="comprimento"
									value={parametros.comprimento}
								/>
							</Col>
							<Col sm="1">X</Col>
							<Col sm="3">
								<Form.Label name="parameter">Largura:</Form.Label>
								<Form.Control
									type="text"
									className="Form-control mb-2"
									placeholder={props.currentValue}
									onChange={onChangeValue}
									name="largura"
									value={parametros.largura}
								/>
							</Col>
						</Row>
						<p
							style={{
								fontSize: "1.4rem",
								fontWeight: "bolder",
								textAlign: "end",
								marginTop: "10px",
								marginBottom: "0",
							}}
						>
							Total:{" "}
							{Number(
								Number(parametros.altura.replace(",", ".")) *
									Number(parametros.comprimento.replace(",", ".")) *
									Number(parametros.largura.replace(",", "."))
							).toLocaleString("pt-BR")}{" "}
							[ m<sup>3</sup> ]
						</p>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={showLoading.style}
								disabled={showLoading.style !== "primary" ? true : false}
								onClick={confirmarEdicao}
							>
								<LoadingNew
									show={showLoading.show}
									msg={showLoading.msg}
									style={showLoading.style}
									icone={""}
								/>
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
