import { createRef, Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../loading/Loading";

import "../../modal/Modal.scss";
import ModalError from "../../modal/ModalError";
export default function ModalCancelamento(props) {
	const dispatch = useDispatch();

	const userS = useSelector((state) => state.userSlice);
	const filiaisList = userS.filiaisList.data;
	const caminhoesList = userS.caminhoesList.data;
	const [user, setUser] = useState(null);

	const [loading, setLoading] = useState({
		isLoading: false,
        msg: 'Confirmar',
        style:'danger'
	});
	const [error, setError] = useState(false);
   
	const onClickButton = async () => {
		setLoading({isLoading: true, style: 'warning', msg:'Aguarde'})
		await props.fn()
		setLoading({ isLoading: false, style: 'success', msg: 'Cancelado!' })
		setTimeout(() => {
			handlerSimpleClose()
		}, 500)
	} 

	const handlerSimpleClose = () => props.setShow((last) => ({ error: { flag: false } }));

	return (
		<>
			<Modal
				show={props.flag}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.title}: {props.bodyList.id}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						{
							Object
								.keys(props.bodyList)
								.map((key, index) =>
									<Row className='align-items-end' key={index}>
										<Col sm='3' className='mb-2'>
											<Form.Label >{key.toUpperCase()}:</Form.Label>
										</Col>
										<Col className='mb-2'>
											<Form.Control className='form-control-input' defaultValue={props.bodyList[key]} disabled/>
										</Col>
									</Row>
								)
						}
						
					</Form>
                    {error.flag ? <ModalError show={error.flag} setShow={setError} title={error.title} msg={error.msg} />:""}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={onClickButton}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
                                )}
                                {loading.style == 'success' ? <i className="bi bi-check"></i> : ''}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Sair
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}