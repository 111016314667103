import { Fragment } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import "./NavbarUser.scss";
import { useSelector } from "react-redux";

export default function NavbarUser() {
	const user = useSelector((state) => state.userSlice);
	return (
		<Fragment>
			<Nav variant="tabs">
				{user.tipo === "empresa" ||
				user.email.toUpperCase() == process.env.REACT_APP_EMAIL_SUPORTE ? (
					<Fragment>
						<Nav.Item>
							<NavLink className="nav-link" to="cadastrados">
								Usuários Cadastrados
							</NavLink>
						</Nav.Item>
						<Nav.Item>
							<NavLink className="nav-link" to="cadastro">
								Cadastrar Usuários
							</NavLink>
						</Nav.Item>
					</Fragment>
				) : (
					""
				)}
				<Nav.Item>
					<NavLink className="nav-link" to="profile">
						Perfil Usuário
					</NavLink>
				</Nav.Item>
				{/* <Nav.Item>
                        <NavLink className="nav-link" to='settings'>Configurações</NavLink>
                    </Nav.Item> */}
			</Nav>
			<Outlet />
		</Fragment>
	);
}
