import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { update, updateRomaneiosList } from "../../redux/slicers/user";
import { getData, Unauthorized, uploadImg } from "../../services/Servicos";

import "./Modal.scss";
import ModalError from "./ModalError";
import LoadingNew from "../loading/LoadingNew";

export default function ModalUploadImg(props) {
	const user = useSelector((state) => state.userSlice);
	const cargasSelecionadas = user.cargaSelecionada;

	const [showLoading, setShowLoading] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar",
	});

	const [error, setError] = useState({
		flag: false,
		msg: "",
	});
	const [foto, setFoto] = useState(null);

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, show: false }));

	const dispatch = useDispatch();

	const getRomaneiosEntrega = async () => {
		try {
			let romaneios = [];
			for await (let cargas of cargasSelecionadas) {
				const data = await getData("entregas/carga", cargas.id);
				romaneios.push(...data.data);
			}
			dispatch(updateRomaneiosList(romaneios));
			handlerSimpleClose();
		} catch (error) {}
	};

	const clickSalvar = async (e) => {
		e.preventDefault();
		setShowLoading((last) => ({
			...last,
			flag: true,
			style: "warning",
			msg: "Salvando...",
		}));

		console.info(props);
		await uploadImg(
			"entregas/pedido/upload_img",
			props.romaneio,
			props.cargas_id,
			{ foto_pedido_entregue: foto }
		)
			.then((reponse) => {
				setShowLoading((last) => ({
					...last,
					flag: false,
					style: "success",
					msg: "Salvo",
				}));
				setTimeout(() => {
					getRomaneiosEntrega();
				}, 500);
			})
			.catch((err) => {
				setError({
					flag: true,
					msg: err.response || err,
				});

				setShowLoading((last) => ({
					...last,
					flag: false,
					style: "primary",
					msg: "Salvar",
				}));

				if (err.msg === "Unauthorized") {
					Unauthorized(dispatch, update);
				}
			});
	};

	const onChangeInputFile = async (e) => {
		e.preventDefault();

		const imageFile = e.target.files[0];
		setFoto(imageFile);
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton style={{ border: "none" }}>
					<Modal.Title>
						{props.title}
						<br />
						<i style={{ fontSize: "inherit" }}>
							Romaneio N°{String(props.romaneio).padStart(7, 0)}
						</i>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-center">
						<Row className="my-1">
							<Form>
								<Row>
									<Col className="mb-3">
										<Form.Control
											type="file"
											name="teste"
											onChange={onChangeInputFile}
											// capture='environment'
											required
										/>
									</Col>
								</Row>
							</Form>
						</Row>
						<Row className="justify-content-center">
							<Col sm="6">
								<Button
									variant={showLoading.style}
									disabled={
										showLoading.style !== "primary" || !foto ? true : false
									}
									onClick={clickSalvar}
								>
									<LoadingNew
										show={showLoading.show}
										msg={showLoading.msg}
										style={showLoading.style}
										icone={""}
									/>
								</Button>
							</Col>
							<Col sm="6">
								<Button variant="secondary" onClick={handlerSimpleClose}>
									Cancelar
								</Button>
							</Col>
						</Row>
					</div>
				</Modal.Body>
			</Modal>
			{error.flag ? (
				<ModalError
					title={"Erro no Lancamento da Foto"}
					msg={error.msg}
					show={error.flag}
					setShow={setError}
				/>
			) : (
				""
			)}
		</>
	);
}
