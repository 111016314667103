import { Fragment, useEffect, useRef, useState } from "react"
import { Button, Col, Form, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import './Modal.scss'
import Loading from "../loading/Loading"
import { getErrorMessage } from "../../services/helpers"
import ModalErrorPretty from "./ModalErrorPretty"
import ModalSuccessPretty from "./ModalSuccessPretty"


export default function ModalControleRetiradaPedido({ show, setShow, pedido, callback = false }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [error, setError] = useState({show: false})
    const [success, setSuccess] = useState({show: false})
    const [loading, setLoading] = useState({
        flag: false,
        style: 'warning',
        msg:'Sim'
    })

    const user = useSelector(state => state.userSlice)
    const filiaisList = user.filiaisList.data 
    
    const handlerSimpleClose = () => setShow(last => ({ ...last, show: false })) 
    const encerrarPedidos = async () => {
        setLoading({
            flag: true, style:'info', msg: 'Atualizando...'
        })

        try {
            await axios.get(`${process.env.REACT_APP_API_VST}st/1/id_filial/${pedido.idFilial}/id_saida/${pedido.codigoPedido}?status=26`)
            setLoading({
                flag: false, style:'success', msg: 'Atualizado'
            })
            
            setSuccess({ show: true, message: 'Pedido Encerrado com sucesso' })
            setTimeout(() => {
                callback()
            }, 1000)
        } catch (error) {
            setError({show: true, message: getErrorMessage(error)})
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Retirada do pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: 'auto', margin: '0 20px', }}>
                    <div>Emissao: <b style={{ fontSize: '1.5rem' }}>{new Date(pedido.dtemissao).toLocaleDateString("pt-BR", {timeZone: "UTC",})}</b></div>
                    <div>Cliente: <b style={{ fontSize: '1.5rem' }}>{pedido.nomeRazao}</b></div>
                    <div>Pedido: <b style={{ fontSize: '1.5rem' }}>{pedido.codigoPedido}</b></div>
                    <div>Vendedor: <b style={{ fontSize: '1.5rem' }}>{pedido.nomefunc}</b></div>
                    <p className="mt-4" style={{ fontSize: '1.5rem', fontWeight:'bold' }}>Deseja confirmar retirada do pedido pelo cliente?</p>
                    
                </Modal.Body>
                <Modal.Footer className="d-flex flex-nowrap px-2 mx-2 justify-content-center">
                    <Col sm='6'>
                        <Button
                            variant={loading.style}
                            onClick={encerrarPedidos}
                            disabled={loading.style != 'warning'}
                            className="d-flex justify-content-center align-items-center"
                        >
                            {loading.msg}
                            {loading.style == 'success' ? <i className="bi bi-check"></i>:''}
                            {loading.flag ? <Loading color={'black'} />:''}
                        </Button>
                    </Col>
                    <Col sm='6'>
                        <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
            {error.show ? <ModalErrorPretty setShow={setError} {...error}/> : ""}
            {success.show ? <ModalSuccessPretty setShow={setSuccess} {...success}/> : ""}
        </>
    )
}
