import { Modal, Row, Col, Form, Button, Spinner } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { alterData, getAllData, getData, updateTable } from "../../../services/Servicos"
import { update } from "../../../redux/slicers/user"

export default function ModalFotoReport({ show, setShow, title, id, email }) {
    const handlerSimpleClose = () => setShow(last => ({ ...last, flag: false }))
    
    const [error, setError] = useState('')
    const [btnLoading, setBtnLoading] = useState({
        flag:false,
        msg:'Salvar',
        style:'primary'
    })

    const dispatch = useDispatch()
    const [img, setImg] = useState({
        title,
        email,
        fotoCnh: '',
    })
    
    const onChangeInputFile = async (e) => {
        e.preventDefault()
        setError('')

        setImg(last => ({
            ...last,
            fotoCnh: e.target.files[0]
        }))
    }

    const handlerSalvar = async (e) => {
        e.preventDefault()
        setBtnLoading({
            flag: true, style:'warning', msg:'Salvando...'
        })
        try {
            await alterData('funcionarios/foto', id, img)
            await getAllData('funcionario')
                .then(resp => {
                    dispatch(update(resp.data))
                    setBtnLoading({
                        flag: false, style:'success', msg:'Salvo'
                    })
                    
                    setTimeout(() => {
                        handlerSimpleClose()
                    },1500)
                })
            
        } catch (error) {
            
            setError(error?.msg ?? "erro de servidor") 
            setBtnLoading({
                flag: false, style:'danger', msg:'Erro'
            })
            
            setTimeout(() => {
                setBtnLoading({
                    flag: false, style:'primary', msg:'Salvar'
                })
            },1500)
            return
        }
        
        
    }
    
    useEffect(() => {
        
    }, [])



    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Atualizar Imagem - { title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="mb-3 text-center">
                            <Form.Label className="mb-4">Anexar Imagem:</Form.Label>
                            <Form.Control 
                                type='file' 
                                name='teste'
                                accept="image/png, image/jpeg"
                                onChange={onChangeInputFile}
                            />
                        </Col>
                        {error.length ? <p>{ error }</p> : ''}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={btnLoading.style} onClick={handlerSalvar} disabled={btnLoading.style == 'primary' ? false : true}>
                        {btnLoading.flag ? <Spinner animation='border' size='sm'/> : ""}
                        {btnLoading.style == 'success' ? <i className="bi bi-check"></i> : ""}
                        {btnLoading.msg}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
