/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

function getTruckAberto(tipo) {
    let caminhao = ''
    switch(tipo){
        case 'toco': 
            // caminhao =  require('../../assets/caminhoes/aberto/TOCO.glb')
            caminhao =  require('../../assets/caminhoes/aberto/TOCO-SEM-TAMPAS.glb')
            break
        case 'truck': 
            // caminhao =  require('../../assets/caminhoes/aberto/TRUCK.glb')
            caminhao =  require('../../assets/caminhoes/aberto/TRUCK-SEM-TAMPAS.glb')
            break
        case 'bitruck': 
            // caminhao =  require('../../assets/caminhoes/aberto/BITRUCK.glb')
            caminhao =  require('../../assets/caminhoes/aberto/BITRUCK-SEM-TAMPAS.glb')
            break
    }
    return caminhao
} 
function getTruckFechado(tipo) {
    let caminhao = ''
    switch(tipo){
        case 'toco': 
            // caminhao =  require('../../assets/caminhoes/fechado/TOCO.glb')
            caminhao =  require('../../assets/caminhoes/fechado/TOCO-SEM-TAMPAS-2.glb')
            break
        case 'truck': 
            // caminhao =  require('../../assets/caminhoes/fechado/TRUCK.glb')
            caminhao =  require('../../assets/caminhoes/fechado/TRUCK-SEM-TAMPAS-2.glb')
            break
        case 'bitruck': 
            // caminhao =  require('../../assets/caminhoes/fechado/BITRUCK.glb')
            caminhao =  require('../../assets/caminhoes/fechado/BITRUCK-SEM-TAMPAS-2.glb')
            break
    }
    return caminhao
} 
function getCarreta(tipo) {
    let caminhao = ''
    switch(tipo){
        case 2: 
            caminhao =  require('../../assets/caminhoes/carreta/CARRETA-DOIS-EIXOS-2.glb')
            break
        case 3: 
            caminhao =  require('../../assets/caminhoes/carreta/CARRETA-TRES-EIXOS-3.glb')
            break
        default:
            caminhao =  require('../../assets/caminhoes/carreta/CARRETA-DOIS-EIXOS-2.glb')
            break
    }
    return caminhao
} 

function LoadMesh() {
    const caminhaoDB = useSelector(state => state.userSlice.caminhaoSelecionado)
    let caminhao = null

    if (caminhaoDB.caminhao_tipo.toLowerCase() === 'carreta') {
        
        caminhao = getCarreta(caminhaoDB.caminhao_qtd_eixos)
    } else {

        if(caminhaoDB.caminhao_altura < 1){
            caminhao = getTruckAberto(String(caminhaoDB.caminhao_tipo).toLowerCase())
        }else{
            caminhao = getTruckFechado(String(caminhaoDB.caminhao_tipo).toLowerCase())
        }
        
    }

    const model = useLoader(GLTFLoader, caminhao)

    return <primitive object={model.scene} rotation={[0, Math.PI, 0]} />
}

export default function Caminhao(props) {

    return <LoadMesh />
}

useGLTF.preload(<LoadMesh />);
