import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./GestaoCarregamento.scss";
import {
	updateCaminhaoSelecionado,
	updateCargasList,
	updateProdutosList,
} from "../../redux/slicers/user";
import {
	getAllData,
	getStatusCarga,
	getStatusPedidosTransferencia,
	getTimesAgo,
	verificaTipoUsuario,
} from "../../services/Servicos";
import ModalPlanejamentoCarga from "../modal/ModalPlanejamentoCarga";
import { removeAll } from "../../redux/slicers/carga";
import ModalControleManifesto from "../modal/ModalControleManifesto";
import ModalPlanejamentoCargaSeparador from "../modal/ModalPlanejamentoCargaSeparador";
import ModalError from "../modal/ModalError";

export default function GestaoCarregamento() {
	const cargasR = useSelector((state) => state.userSlice.cargasList.data);
	const transferencias = useSelector(
		(state) => state.userSlice.transferenciasList.data
	);
	const user = useSelector((state) => state.userSlice);
	const caminhoneiros = user.funcionariosList.data.filter(
		(item) => item.tipo?.toLowerCase() === "caminhoneiro"
	);
	const dispatch = useDispatch();

	const [colorText, setColorText] = useState({});
	const [error, setError] = useState({ flag: false, msg: "", title: "" });

	const [cargaClick, setCargaClick] = useState({
		target: 0,
		style: {},
	});
	const [transferenciaClick, setTransferenciaClick] = useState({
		target: 0,
		style: {},
	});
	const [caminhaoClick, setCaminhaoClick] = useState({
		placa: "",
		flag: false,
		carga_id: 0,
	});

	const [planejarCarga, setPlanejarCarga] = useState({
		flag: false,
		cargas_id: "",
	});

	const [loading, setLoading] = useState(true);

	const onClickCarga = (e, id, status, caminhao) => {
		e.preventDefault();

		if (!caminhao)
			return setError({
				flag: true,
				title: "Aviso!",
				msg: "Caminhão não especificado para a carga!",
			});

		dispatch(removeAll());
		dispatch(updateCaminhaoSelecionado({ status: false }));
		dispatch(updateProdutosList([]));

		setCaminhaoClick({ placa: "" });
		setColorText({
			color: "rgb(255 255 255)",
			transition: ".5s",
		});
		if (
			status !== 3 &&
			status !== 4 &&
			status !== 9 &&
			status !== 10 &&
			status !== 11 &&
			status !== 12
		) {
			setCargaClick({
				target: id,
				style: {
					// color: 'rgb(255 255 255)',
					backgroundColor: "#b12048",
				},
			});
			return;
		}
		setCargaClick({
			target: id,
			style: {
				backgroundColor: "#4aa541",
				transition: ".5s",
			},
		});
		setTimeout(() => {
			setCaminhaoClick({
				placa: caminhao,
				flag: true,
				carga_id: id,
				status,
			});
		}, 1000);
	};

	const onClickTransferencia = (e, item) => {
		e.preventDefault();

		dispatch(removeAll());
		dispatch(updateCaminhaoSelecionado({ status: false }));
		dispatch(updateProdutosList([]));

		setCaminhaoClick({
			placa: "",
		});
		if (!item.cargas_id) {
			setTransferenciaClick({
				target: item.id,
				style: {
					// color: 'rgb(255 255 255)',
					backgroundColor: "#b12048",
				},
			});
			return;
		}

		setColorText({
			color: "rgb(255 255 255)",
			transition: ".5s",
		});

		setTransferenciaClick({
			target: item.id,
			style: {
				backgroundColor: "#4aa541",
				transition: ".5s",
			},
		});

		setPlanejarCarga({
			flag: true,
			cargas_id: item.cargas_id,
			transferencia: item.transferencia,
		});
	};

	const updateArrayCargas = async () => {
		if (user.tipo !== "empresa") {
			const cargas = await getAllData(verificaTipoUsuario(user) + "cargas", {
				id_filial: user.id_filial,
			});

			dispatch(updateCargasList(cargas.data));
			setLoading(false);
			return;
		}
		const cargas = await getAllData(verificaTipoUsuario(user) + "cargas");
		dispatch(updateCargasList(cargas.data));
		setLoading(false);
	};

	useEffect(() => {
		updateArrayCargas();
	}, []);

	return (
		<div style={{ padding: "0 10px" }}>
			<div>
				{loading ? <h1>Carregando...</h1> : ""}
				<Row>
					{cargasR
						.filter((item) => item.regiao_entrega != "LOCAL")
						.filter((item) => item.status >= 1 && item.status < 13)
						.map((item, key) => {
							return (
								<Col sm="6" lg="3" key={key}>
									<Card
										className={`card-dashboard card-select-cargo`}
										onClick={(e) =>
											onClickCarga(e, item.id, item.status, item.caminhao)
										}
										style={cargaClick.target == item.id ? cargaClick.style : {}}
									>
										<h5
											style={
												cargaClick.target == item.id ? { ...colorText } : {}
											}
										>
											Carga {item.nome}
										</h5>
										<p
											style={
												cargaClick.target == item.id ? { ...colorText } : {}
											}
										>
											{" "}
											{getStatusCarga(item.status)}
										</p>
										<p
											style={
												cargaClick.target == item.id ? { ...colorText } : {}
											}
										>
											{" "}
											Caminhao: {item.caminhao || "NÃO DEFINIDO"}
										</p>
										<p
											style={
												cargaClick.target == item.id ? { ...colorText } : {}
											}
										>
											{" "}
											Caminhoneiro:{" "}
											{caminhoneiros.find(
												(_item) => _item.cpf === item.caminhoneiro
											)?.name || "NÃO DEFINIDO"}
										</p>
										<p
											style={
												cargaClick.target == item.id ? { ...colorText } : {}
											}
										>
											{" "}
											Atualizado a {getTimesAgo(item.updatedAt)}
										</p>
										<i
											className={
												cargaClick.target == item.id && item.status === 0
													? "bi bi-x-circle bi-dashboard bg-danger"
													: item.status <= 2
													? "bi bi-exclamation-circle bi-dashboard bg-warning"
													: item.status <= 4
													? "bi bi-check-lg bi-dashboard bg-success" +
													  `${
															cargaClick.target == item.id
																? " cardSelected"
																: ""
													  }`
													: item.status <= 6
													? "bi bi-truck bi-dashboard bg-info"
													: "bi bi-flag-fill bi-dashboard bg-dark" +
													  `${
															cargaClick.target == item.id
																? " cardSelected"
																: ""
													  }`
											}
										></i>
									</Card>
								</Col>
							);
						})}
				</Row>
				<Row>
					{transferencias
						.filter((item) => item.status <= 5 && item.status > 2)
						.filter((item) => !item.data_cancelamento)
						.map((item, key) => {
							return (
								<Col sm="6" lg="3" key={key}>
									<Card
										className={`card-dashboard card-select-cargo`}
										onClick={(e) => onClickTransferencia(e, item)}
										style={
											transferenciaClick.target == item.id
												? transferenciaClick.style
												: {}
										}
									>
										<h5
											style={
												transferenciaClick.target == item.id
													? { ...colorText }
													: {}
											}
										>
											Transferência {item.transferencia}
										</h5>
										<p
											style={
												transferenciaClick.target == item.id
													? { ...colorText }
													: {}
											}
										>
											{" "}
											{getStatusPedidosTransferencia(item.status)}
										</p>
										<p
											style={
												transferenciaClick.target == item.id
													? { ...colorText }
													: {}
											}
										>
											{" "}
											Carga: {item.cargas_id || "NÃO DEFINIDO"}
										</p>
										<p
											style={
												transferenciaClick.target == item.id
													? { ...colorText }
													: {}
											}
										>
											{" "}
											Atualizado a {getTimesAgo(item.updatedAt)}
										</p>
										<i
											className={
												transferenciaClick.target == item.id &&
												item.status === 0
													? "bi bi-x-circle bi-dashboard bg-danger"
													: item.status <= 2
													? "bi bi-exclamation-circle bi-dashboard bg-warning"
													: item.status <= 5
													? "bi bi-check-lg bi-dashboard bg-success" +
													  `${
															transferenciaClick.target == item.id
																? " cardSelected"
																: ""
													  }`
													: item.status <= 6
													? "bi bi-truck bi-dashboard bg-info"
													: "bi bi-flag-fill bi-dashboard bg-dark" +
													  `${
															transferenciaClick.target == item.id
																? " cardSelected"
																: ""
													  }`
											}
										></i>

										{/* </Card.Body>
                                            {item.status == 10 || item.status == 11 ? <Button variant='info' className='mt-3' onClick={() => setManifesto({flag: true, ...item})}>Manifesto</Button>: ""} */}
									</Card>
								</Col>
							);
						})}
				</Row>
			</div>

			{error.flag ? (
				<ModalError
					title={"ATENÇÃO"}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
			{caminhaoClick.flag ? (
				<ModalPlanejamentoCarga
					caminhao_placa={caminhaoClick.placa}
					setShow={setCaminhaoClick}
					show={caminhaoClick.flag}
					carga_id={caminhaoClick.carga_id}
					setCargaClick={setCargaClick}
					status={caminhaoClick.status}
				/>
			) : (
				""
			)}

			{planejarCarga.flag ? (
				<ModalPlanejamentoCargaSeparador
					setShow={setPlanejarCarga}
					show={planejarCarga.flag}
					carga_id={planejarCarga.cargas_id}
					transferencia={planejarCarga.transferencia}
					setTransferenciaClick={setTransferenciaClick}
				/>
			) : (
				""
			)}
		</div>
	);
}
