import axios from "axios"
import { useEffect, useLayoutEffect, useState } from "react"
import { Modal, Spinner, Table } from "react-bootstrap"

import './ModalError.scss'

export default function ModalTableProdutosInformacoes({show, setShow, ID_EMPRESA, ID_FILIAL, ID_PEDIDO, pedido, carga_id = 1, setPedidos= () => {} }) {

    // /* to editing data */
    const [produtos, setProdutos] = useState([])
    const [produtosMedida, setProdutosMedida] = useState([])
    const [produtosVolumes, setProdutosVolumes] = useState([])
    const [headListProdutos, setHeadListProdutos] = useState(['INDEX'])
    const [headListMedidas, setHeadListMedidas] = useState(['INDEX'])
    const [headListVolumes, setHeadListVolumes] = useState(['INDEX'])
    const [atribuido, setAtribuido] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)


    const [cargaModal, setCargaModal] = useState({
        flag: false,
        pedido,
        produtos: []
    })  
   

    const handlerSimpleClose = () => setShow(last => ({ error: { flag: false } }))

    useLayoutEffect(() => {
        axios.get(`${process.env.REACT_APP_API_VST}/${ID_EMPRESA}/id_filial/${ID_FILIAL}/id_saida/${ID_PEDIDO}`)
        .then(resp =>{
            setProdutos(resp.data.Produtos)
            setHeadListProdutos(last => ([...Object.keys(resp.data.Produtos[0])]))

            if(!resp.data.ProdutosMedida) return            
            setProdutosMedida(resp.data.ProdutosMedida)
            setHeadListMedidas(last => ([...Object.keys(resp.data.ProdutosMedida[0])]))

            if(!resp.data.ProdutosVolumes) return            
            setProdutosVolumes(resp.data.ProdutosVolumes)
            setHeadListVolumes(last => ([...Object.keys(resp.data.ProdutosVolumes[0])]))
        }).catch(error => {
            setError({
                flag: true,
                msg: error,
                title:'Error VST'
            })
        }).finally(() => setLoading(false))

    }, [])

    useEffect(() => {
        if (atribuido) {
            handlerSimpleClose()
        }
    }, [atribuido])

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Pedido: {ID_PEDIDO}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loading ?
                            <p> Carregando 
                                <Spinner size="sm" animation='border' />
                            </p>
                            : ""
                    }
                <div className=" responsive-table" style={{height: 'auto', marginBottom:'2rem'}}>
                    <h4>Produtos</h4>
                    <Table hover size='sm' style={{position: 'relative', height:'100px'}} >
                        <thead>
                            <tr>
                                {
                                    headListProdutos.map((item, _index) => {
                                        if(_index > 19 ){
                                            return 
                                        }                                       
                                        
                                        return <th key={_index} className={item} style={{minWidth:'100px'}}> {item}</th>
                                    })
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {
                                produtos.map((item, index) => {
                                    
                                    return <tr key={index}>
                                        {
                                            headListProdutos.map((_item, _index) => {
                                                if (_item.toLowerCase() === 'index') {
                                                    return <td key={_index}> {index + 1}</td>
                                                } 
                                                return <td key={_index} className={item} style={{minWidth:'100px'}}> {item[_item]}</td>
                                            })
                                        }
                                    </tr> 
                                    
                                })
                                }
                        </tbody>
                    </Table>
                 </div>
                 <Medidas headListMedidas={headListMedidas} produtosMedidas={produtosMedida}/>
                 <Volumes headListVolumes={headListVolumes} produtosVolumes={produtosVolumes}/>
                </Modal.Body>
                
            </Modal>
        </>
    )
}

function Volumes({headListVolumes, produtosVolumes}){
    return(
        <div className=" responsive-table" style={{height: 'auto', marginBottom:'2rem'}}>
            <h4>Volumes</h4>
            {
                !produtosVolumes.length ? <p>Volumes dos produtos <i><b>não fornecidos!</b></i></p>:
                <Table hover size='sm' style={{position: 'relative', height:'100px'}} >
                    <thead>
                        <tr>
                            {
                                headListVolumes.map((item, _index) => {
                                    if(_index > 19 ){
                                        return 
                                    }                                       
                                    
                                    return <th key={_index} className={item} style={{minWidth:'100px'}}> {item}</th>
                                })
                            }
                        </tr>

                    </thead>
                    <tbody>
                        {
                            produtosVolumes.map((item, index) => {
                                
                                return <tr key={index}>
                                    {
                                        headListVolumes.map((_item, _index) => {
                                            if (_item.toLowerCase() === 'index') {
                                                return <td key={_index}> {index + 1}</td>
                                            } 
                                            return <td key={_index} className={item} style={{minWidth:'100px'}}> {item[_item]}</td>
                                        })
                                    }
                                </tr> 
                                
                            })
                            }
                    </tbody>
                </Table>

            }
        </div>
    )
}

function Medidas({headListMedidas, produtosMedidas}){
    return(
        <div className=" responsive-table" style={{height: 'auto', marginBottom:'2rem'}}>
            <h4>Medidas</h4>
            {
                !produtosMedidas.length ? <p>Medidas dos produtos <i><b>não fornecidos!</b></i></p>:
                <Table hover size='sm' style={{position: 'relative', height:'100px'}} >
                    <thead>
                        <tr>
                            {
                                headListMedidas.map((item, _index) => {
                                    if(_index > 19 ){
                                        return 
                                    }                                       
                                    
                                    return <th key={_index} className={item} style={{minWidth:'100px'}}> {item}</th>
                                })
                            }
                        </tr>

                    </thead>
                    <tbody>
                        {
                            produtosMedidas.map((item, index) => {
                                
                                return <tr key={index}>
                                    {
                                        headListMedidas.map((_item, _index) => {
                                            if (_item.toLowerCase() === 'index') {
                                                return <td key={_index}> {index + 1}</td>
                                            } 
                                            return <td key={_index} className={item} style={{minWidth:'100px'}}> {item[_item]}</td>
                                        })
                                    }
                                </tr> 
                                
                            })
                            }
                    </tbody>
                </Table>

            }
        </div>
    )
}