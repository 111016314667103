import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userSlice from '../slicers/user'
import sidebarSlice from '../slicers/sidebar'
import cargaSlicer from '../slicers/carga'
import vendasSlice from '../slicers/vendas'
import thunk from 'redux-thunk'


const reducers = combineReducers({ userSlice, sidebarSlice, cargaSlicer, vendasSlice })

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export let store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})
export let persistor = persistStore(store)
export const clearPersistData = async () => {
    persistor.pause();
    persistor.flush().then(() => {
        return persistor.purge();
    });
}