import { useSelector } from "react-redux";
import NavbarPonto from "./controle-ponto/NavbarPonto";
import Dashboard from "./dashboard/Dashboard";
import NavbarFrota from "./frota/NavbarFrota";
import NavbarPlanoLogistica from "./planoLogistica/NavbarPlanoLogistica";
import { NavbarSeparacao } from "./separacao-carga/Separacao";
import Template from "./template/Template";
import NavbarUser from "./user/NavbarUser";
import Entregas from "./entregas/Entregas";
import Descarregamento from "./entregas/Descarregamento";
import Financeiro from "./financeiro/Financeiro";
import GestaoCarregamento from "./gestaoCarregamento/GestaoCarregamento";
import CarregarCaminhaoNew from "./carregamento/CarregarCaminhaoNew";
import Abastecimento from "./abastecimento/Abastecimento";
import NavbarDiario from "./diarioDeBordo/NavbarDiario";
import Monitoramento from "./frota/monitoramentoFrota/Monitoramento";
import NavbarEquipamentos from "./equipamentos/NavbarEquipamentos";
import NavbarTransferencias from "./transferencias/NavbarTransferencias";
import MonitoramentoPedidos from "./planoLogistica/monitoramentoPedidos/MonitoramentoPedidos";
import ControleDeMensagens from "./controleDeMensagens/ControleDeMensagens";
import MotoristasRoute from "./motoristas/MotoristasRoute";
import { Container } from "react-bootstrap";
import MonitoramentoPedidosFinanceiro from "./planoLogistica/monitoramentoPedidos/MonitoramentoPedidosFinanceiro";

export function PageDashboard({ user }) {
	const userTipo = useSelector((state) => state.userSlice);
	return (
		<Template>
			<Container>
				<h1>
					Dashboard - <span>{userTipo.tipo.toUpperCase()}</span>
				</h1>
				<hr></hr>
				<Dashboard />
			</Container>
		</Template>
	);
}
export function PageMonitPedidos({ user }) {
	const userTipo = useSelector((state) => state.userSlice);
	const filiaisList = userTipo.filiaisList.data;
	return (
		<Template>
			<Container>
				<h1>
					Monitoramento de Pedidos -{" "}
					<span>
						{filiaisList.find((item) => item.idFilial == userTipo.id_filial)
							?.identificacaoInterna || userTipo.tipo.toUpperCase()}
					</span>
				</h1>
				<hr></hr>
				<MonitoramentoPedidos />
			</Container>
		</Template>
	);
}
export function PageMonitPedidosFinanceiro({ user }) {
	const userTipo = useSelector((state) => state.userSlice);
	const filiaisList = userTipo.filiaisList.data;
	return (
		<Template>
			<Container>
				<h1>
					Controle de Pedidos
				</h1>
				<hr></hr>
				<MonitoramentoPedidosFinanceiro />
			</Container>
		</Template>
	);
}
export function PageMotoristas({ user }) {
	return (
		<Template>
			<Container>
				<h1>Relatório de Entregas</h1>
				<hr></hr>
				<MotoristasRoute />
			</Container>
		</Template>
	);
}

export function PageInbox() {
	return (
		<Template>
			<Container>
				<h1>INBOX ROUTE</h1>
				<hr></hr>
			</Container>
		</Template>
	);
}

export function PageChat() {
	return (
		<Template>
			<Container>
				<h1>CHAT ROUTE</h1>
				<hr></hr>
			</Container>
		</Template>
	);
}

export function PageMap() {
	return (
		<Template>
			<Container>
				<h1>Area de Entregas - Mapa</h1>
				<hr></hr>
				<Entregas />
			</Container>
		</Template>
	);
}
export function PageDescarregamento() {
	return (
		<Template>
			<Container>
				<h1>Area de Entregas - Descarregamento</h1>
				<hr></hr>
				<Descarregamento />
			</Container>
		</Template>
	);
}

export function PageDiario() {
	return (
		<Template>
			<Container>
				<h1>Diário de Bordo</h1>
				<hr></hr>
				<NavbarDiario />
			</Container>
		</Template>
	);
}
export function PageManutencao() {
	return (
		<Template>
			<Container>
				<h1>USER PROFILE ROUTE</h1>
				<hr></hr>
			</Container>
		</Template>
	);
}
export function PagePlanoLogistica() {
	return (
		<Template>
			<Container>
				<h1>Controle de Planos de Logística</h1>
				<hr></hr>
				<NavbarPlanoLogistica />
			</Container>
		</Template>
	);
}

export function PageFrota() {
	return (
		<Template>
			<Container>
				<h1>Controle de Frota</h1>
				<hr></hr>
				<NavbarFrota />
			</Container>
		</Template>
	);
}
export function PageGestaoCarregamento() {
	return (
		<Template>
			<Container>
				<h1>Planejamento de Produtos nos Caminhões</h1>
				<hr></hr>
				<GestaoCarregamento />
				{/* <Carregamento /> */}
			</Container>
		</Template>
	);
}
export function PageCarregamento() {
	return (
		<Template>
			<Container>
				<h1>Carregamento de Carga</h1>
				<hr></hr>
				<CarregarCaminhaoNew />
				{/* <Carregamento /> */}
			</Container>
		</Template>
	);
}

export function PageFinanceiro(children) {
	return (
		<Template>
			<h1>Financeiro</h1>
			<hr></hr>
			<Financeiro />
		</Template>
	);
}

export function PageAbastecimento() {
	return (
		<Template>
			<Container>
				<h1>Abastecimento</h1>
				<hr></hr>
				<Abastecimento />
			</Container>
		</Template>
	);
}

export function PageMonitoramentoFrota() {
	return (
		<Template>
			<h1>Motiramento da Frota em Rota de Entrega</h1>
			<hr></hr>
			<Monitoramento />
		</Template>
	);
}
export function PageSeguranca() {
	return (
		<Template>
			<Container>
				<h1>Segurança ROUTE</h1>
				<hr></hr>
			</Container>
		</Template>
	);
}

export function PageControleDePonto() {
	return (
		<Template>
			<Container>
				<h1>Controle de Pontos</h1>
				<hr></hr>
				<NavbarPonto />
			</Container>
			{/* <ControlePonto/> */}
		</Template>
	);
}

export function PageSeparacaoCarga() {
	return (
		<Template>
			<Container>
				<h1>Separação</h1>
				<hr></hr>
				<NavbarSeparacao />
			</Container>
		</Template>
	);
}

export function UserRoute() {
	return (
		<Template>
			<h1>Usuário</h1>
			<hr></hr>
			<NavbarUser />
		</Template>
	);
}

export function PageEquipamentos() {
	return (
		<Template>
			<Container>
				<h1>Controle de Equipamentos</h1>
				<hr></hr>
				<NavbarEquipamentos />
			</Container>
		</Template>
	);
}

export function PageTransferencias() {
	return (
		<Template>
			<Container>
				<h1>Controle de Tranferencias</h1>
				<hr></hr>
				<NavbarTransferencias />
			</Container>
		</Template>
	);
}

export function PageMensagens() {
	return (
		<Template>
			<Container>
				<h1>Controle de Mensagens</h1>
				<hr></hr>
				<ControleDeMensagens />
			</Container>
		</Template>
	);
}
