import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
	valorMoeda,
} from "../../../services/Servicos";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";

export default function GraficoPadraoCardsCaminhao({
	url,
	title,
	unidadeMedida = "",
	parametroGrafico,
	posisaoUnidadeMedida,
	colors = "#f60809",
	caminhaoSelecionado = null,
	setCaminhaoSelecionado = () => {},
	dataParam,
	arrayData,
	dataFiltered,
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					// columnWidth: '70%',
					barHeight: "25%",
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value)}`;
					}
					return `${numerosEditadoBR(value)} ${unidadeMedida} `;
				},
				offsetX: 20,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},

				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			xaxis: {
				labels: {
					show: false,
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: true,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});

	const [despesas, setDespesas] = useState({
		cafe: [],
		almoco: 0,
		jantar: 0,
		manutencao: 0,
		pedagio: 0,
		outros: 0,
		total: 0,
	});

	const [loading, setLoading] = useState(true);

	const [periodoInicial, setPeriodoInicial] = useState(``);
	// const [dadosApi, setDadosApi] = useState([]);
	
	useEffect(() => {
		if (!caminhaoSelecionado) return;
		const dadosApi = dataFiltered || arrayData;
		setLoading(true);
		setTimeout(() => {
			setDespesas((last) => ({
				...last,
				cafe: valorMoeda(
					dadosApi
						.filter((item2) => item2.tipo === "refeicao")
						.filter((item2) => item2.tipo_alimentacao === "café")
						.filter((item2) => item2.caminhao === caminhaoSelecionado)
						.reduce((p, c) => p + c.valor, 0)
				),
				almoco: valorMoeda(
					dadosApi
						.filter((item2) => item2.tipo === "refeicao")
						.filter((item2) => item2.tipo_alimentacao === "almoço")
						.filter((item2) => item2.caminhao === caminhaoSelecionado)
						.reduce((p, c) => p + c.valor, 0)
				),
				jantar: valorMoeda(
					dadosApi
						.filter((item2) => item2.tipo === "refeicao")
						.filter((item2) => item2.tipo_alimentacao === "jantar")
						.filter((item2) => item2.caminhao === caminhaoSelecionado)
						.reduce((p, c) => p + c.valor, 0)
				),
				manutencao: valorMoeda(
					dadosApi
						.filter((item2) => item2.tipo === "manutencao")
						.filter((item2) => item2.caminhao === caminhaoSelecionado)
						.reduce((p, c) => p + c.valor, 0)
				),
				pedagio: valorMoeda(
					dadosApi
						.filter((item2) => item2.tipo === "pedagio")
						.filter((item2) => item2.caminhao === caminhaoSelecionado)
						.reduce((p, c) => p + c.valor, 0)
				),
				outros: valorMoeda(
					dadosApi
						.filter((item2) => item2.tipo === "outros")
						.filter((item2) => item2.caminhao === caminhaoSelecionado)
						.reduce((p, c) => p + c.valor, 0)
				),
				total: valorMoeda(
					dadosApi
						.filter((item2) => item2.caminhao === caminhaoSelecionado)
						.reduce((p, c) => p + c.valor, 0)
				),
			}));
			setLoading(false);
		}, 300);
	}, [caminhaoSelecionado]);

	useEffect(() => {
		
		setLoading(true);
		
		const resultado = dataFiltered || arrayData;
		
		const tipos = resultado.filter(
			(i, n, s) => s.findIndex((o) => o.tipo === i.tipo) === n
		);

		tipos.forEach((item) => {});
		setDespesas((last) => ({
			...last,
			cafe: valorMoeda(
				resultado
					.filter((item2) => item2.tipo === "refeicao")
					.filter((item2) => item2.tipo_alimentacao === "café")
					.reduce((p, c) => p + c.valor, 0)
			),
			almoco: valorMoeda(
				resultado
					.filter((item2) => item2.tipo === "refeicao")
					.filter((item2) => item2.tipo_alimentacao === "almoço")
					.reduce((p, c) => p + c.valor, 0)
			),
			jantar: valorMoeda(
				resultado
					.filter((item2) => item2.tipo === "refeicao")
					.filter((item2) => item2.tipo_alimentacao === "jantar")
					.reduce((p, c) => p + c.valor, 0)
			),
			manutencao: valorMoeda(
				resultado
					.filter((item2) => item2.tipo === "manutencao")
					.reduce((p, c) => p + c.valor, 0)
			),
			pedagio: valorMoeda(
				resultado
					.filter((item2) => item2.tipo === "pedagio")
					.reduce((p, c) => p + c.valor, 0)
			),
			outros: valorMoeda(
				resultado
					.filter((item2) => item2.tipo === "outros")
					.reduce((p, c) => p + c.valor, 0)
			),
			total: valorMoeda(resultado.reduce((p, c) => p + c.valor, 0)),
		}));

		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, [arrayData, dataFiltered]);

	return (
		<div className="p-3" style={{ height: "max-content" }}>
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span
					style={{
						marginRight: "10px",
						font: "inherit",
						whiteSpace: "break-spaces",
					}}
				>
					{title}
				</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
			</Card.Title>
			<Row>
				<Col sm="12">
					{caminhaoSelecionado ? (
						<p
							style={{
								fontSize: "1.2rem",
								fontWeight: "bold",
								textAlign: "center",
							}}
						>
							Caminhão Selecionado: {caminhaoSelecionado}
						</p>
					) : (
						""
					)}
				</Col>
				<Col sm="12" lg="6">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								despesas.cafe
							)}
						</h3>
						<p>Café</p>
						<i
							className="bi bi-cup-hot bi-dashboard"
							style={{ backgroundColor: colors }}
						></i>
					</Card>
				</Col>
				<Col sm="12" lg="6">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								despesas.almoco
							)}
						</h3>
						<p>Almoço</p>
						<i
							className="bi bi-egg-fried bi-dashboard"
							style={{ backgroundColor: colors }}
						></i>
					</Card>
				</Col>
				<Col sm="12" lg="6">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								despesas.jantar
							)}
						</h3>
						<p>Jantar</p>
						<i
							className="bi bi bi-egg bi-dashboard"
							style={{ backgroundColor: colors }}
						></i>
					</Card>
				</Col>
				<Col sm="12" lg="6">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								despesas.manutencao
							)}
						</h3>
						<p>Manutenção </p>
						<i
							className="bi bi-tools bi-dashboard"
							style={{ backgroundColor: colors }}
						></i>
					</Card>
				</Col>
				<Col sm="12" lg="6">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								despesas.pedagio
							)}
						</h3>
						<p>Pedágio </p>
						<i
							className="bi bi-currency-dollar bi-dashboard"
							style={{ backgroundColor: colors }}
						></i>
					</Card>
				</Col>
				<Col sm="12" lg="6">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								despesas.outros
							)}
						</h3>
						<p>Outros </p>
						<i
							className="bi bi-plus-lg bi-dashboard"
							style={{ backgroundColor: colors }}
						></i>
					</Card>
				</Col>
				<Col sm="12" lg="6">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								despesas.total
							)}
						</h3>
						<p>Total </p>
						<i
							className="bi bi-cash bi-dashboard"
							style={{ backgroundColor: colors }}
						></i>
					</Card>
				</Col>
				{/* {dataParam.show ? <ModalAtualizarDataEntradaVST title={'Perido do Gráfico'} show={dataParam.show} setShow={setDataParam} {...dataParam} setDataParam={setDataParam} fn={ getApiData } /> : ""} */}
			</Row>
		</div>
	);
}
