import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import Loading from "../../loading/Loading";
import {
	updateEmbalagensList,
	updateManifestosList,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getDateInitMonth,
	getTableColumns,
} from "../../../services/Servicos";
import HeadTable from "../../table/head/HeadRow";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import BodyRowManifestos from "../../table/body/BodyRowManifestos";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";

const tableRef = createRef();
const tbodyRef = createRef();

const buttomStyle = {
	textAlign: "center",
	padding: "15px",
	boxShadow: "0 5px 10px #00000021",
	borderRadius: "5px",
	marginBottom: "0.8rem",
};

export default function ManifestosNaoEncerrados() {
	const user = useSelector((state) => state.userSlice);
	const [manifestos, setManifestos] = useState([])

	const dispatch = useDispatch();

	const [error, setError] = useState({
		flag: false,
		msg: "",
	});

	const [indexList, setIndexList] = useState(15);
	const [loading, setLoading] = useState(true);

	const [headList, setHead] = useState(["index", "admin", 'cargas_id','caminhao','caminhoneiro', 'chave','protocolo']);

	
	const listNaoEncerrados = async (data_inicial, data_final) => {
		try {
			const {data} = await getAllData("manifesto/v2/consulta/nao-encerrados");
			const dados = data;

			setManifestos(dados)
		} catch (error) {
			setError({
				list:
					"Error na obtenção dos Manifestos existentes." +
					JSON.stringify(error),
				flag: true,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		listNaoEncerrados();
	}, []);

	const [filtersNew, setFiltersNew] = useState({});
	const [filters, setFilters] = useState([]);
	const [filteredTable, setFilteredTable] = useState(false);

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(15);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];

			testeFilters[param].push(String(ele[param]).toLowerCase());

			//linha adicionada para incluir filtros com valor nulo
			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [manifestos];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	return (
		<>
			<div>
				{filters.map((item, key) =>
					Object.keys(item).map((key, index) => {
						return (
							<span className="filters-card" key={index}>
								<span className="filters">
									<span className="title">
										{key}: {item[key]}
									</span>
									<i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
								</span>
							</span>
						);
					})
				)}
			</div>
			<ScrollbarLeft tableRef={tableRef} coluna2={4} maxima_distancia={150} />
			<div
				className=" responsive-table"
				ref={tableRef}
				style={{ position: "relative", maxHeight: "600px" }}
			>
				<Table size="sm" bordered>
					<thead style={{ zIndex: "49" }}>
						<HeadTable
							arrayValues={[...manifestos].reverse()}
							headerList={headList}
							setArrayFilters={setFiltersNew}
							arrayFilters={filtersNew}
							setFiltersOld={setFilters}
							filtersOld={filters}
							fn={listNaoEncerrados}
						/>
					</thead>
					<tbody ref={tbodyRef}>
						{filteredTable
							? [...filteredTable]
									.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowManifestos
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={self.length}
													setError={setError}
												/>
											);
										}
									})
							: [...manifestos]
								.map((item, index, self) => {
									if (index < indexList) {
										return (
											<BodyRowManifestos
												key={index}
												bodyList={item}
												headList={headList}
												index={index}
												tableLength={self.length}
												setError={setError}
											/>
										);
									}
							  })}
					</tbody>
				</Table>
				{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
				{loading ? 
				<Loading color={"black"} msg="Loading" />
				:""}
			</div>
		</>
	);
}
