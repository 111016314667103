import { Fragment, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";

import logoFermat from "../../assets/fermat_logo_3.png";
import axios from "axios";
import { updateFiliaisList } from "../../redux/slicers/user";
import {
	getImages,
	getNotificacoes,
	verifyConnection,
} from "../../services/Servicos";

function verificarTipoSeparador(tipo, children, route) {
	return tipo === "Separador" ? children : "";
}

function verificarTipo(tipo, children, route) {
	return tipo === "empresa" ? children : "";
}

function verificarTipoCaminhoneiro(tipo, children, route) {
	return tipo === "Caminhoneiro" ? children : "";
}

function verificarTipos(tipo, children, route) {
	return tipo.toLowerCase() === "caminhoneiro" ||
		tipo.toLowerCase() === "empresa"
		? children
		: "";
}
function verificarTipoGestao(tipo, children, route) {
	return tipo.toLowerCase() === "gestor" || tipo.toLowerCase() === "empresa"
		? children
		: "";
}
function verificarTipoSuporte(tipo, children, route) {
	return tipo.toUpperCase() === process.env.REACT_APP_EMAIL_SUPORTE
		? children
		: "";
}

export default function Sidebar({ show }) {
	// const dispatch = useDispatch()

	const user = useSelector((state) => state.userSlice);
	const [error, setError] = useState({ show: false, message: "" });
	const dispatch = useDispatch();

	const [isOnline, setIsOnline] = useState(true);

	const verifyConnection = async () => {
		if (!isOnline)
			return setError({
				show: true,
				message: "Você esta sem intertet. Tente novamente mais tarde.",
			});
	};

	useEffect(() => {
		//online
		function toggleOnline() {
			setIsOnline(true);
		}
		function toggleOffline() {
			setIsOnline(false);
		}
		window.addEventListener("online", toggleOnline);
		window.addEventListener("offline", toggleOffline);
		return () => {
			window.removeEventListener("online", toggleOnline);
			window.removeEventListener("offline", toggleOffline);
		};
	}, []);

	const userS = useSelector((state) => state.userSlice);
	useEffect(() => {
		//
		if (!userS.authenticated) return;
		if (userS?.filiaisList?.data?.length) return;
		axios
			.get(process.env.REACT_APP_API_VST + "filial")

			.then((response) => {
				const filiais = response.data.Filiais.filter(
					(item) =>
						![
							"LOJA 01 - BEIRA RIO",
							"FERMAT - ARAQUARI",
							"FERMAT - LOJA 08 - FERMAT EXTERNA",
							"FERMAT - ADMINISTRATIVO",
							"TAK ADMINISTRADORA DE BENS LTDA",
							"LOJA 08 - FERMAT EXTERNA",
						].includes(item.identificacaoInterna.trim())
				).map((item) => ({
					...item,
					identificacaoInterna: item.identificacaoInterna.replace(
						"FERMAT - ",
						""
					),
				}));
				dispatch(updateFiliaisList(filiais));
			})
			.catch((err) => {});
	}, []);

	return (
		<Fragment>
			<Nav className={"d-lg-block sidebar " + show}>
				<div className="sidebar-nav" style={{ paddingBottom: "100px" }}>
					{user.tipo.toLowerCase() != "financeiro" ? (
						<NavLink
							className="nav-link nav-link-e"
							to="/dashboard"
							onClick={(e) => verifyConnection(e)}
						>
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-clipboard-data"></i>
								</span>
								<span className="sidebar-option-title">Dashboard</span>
							</div>
						</NavLink>
					) : (
						<NavLink
							className="nav-link nav-link-e"
							to="/monitoramento-pedido-financeiro"
						>
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-list"></i>
								</span>
								<span className="sidebar-option-title">
									Controle de Pedidos
								</span>
							</div>
						</NavLink>
					)}
					{verificarTipoGestao(
						user.tipo,
						<>
							<NavLink
								className="nav-link nav-link-e"
								to="/monitoramento-pedidos"
							>
								<div className="sidebar-option">
									<span className="sidebar-option-icon">
										<i className="bi bi-ui-checks"></i>
									</span>
									<span className="sidebar-option-title">
										Monitor de Pedidos
									</span>
								</div>
							</NavLink>
							<NavLink className="nav-link nav-link-e" to="/motoristas">
								<div className="sidebar-option">
									<span className="sidebar-option-icon">
										<i className="bi bi-person-workspace"></i>
									</span>
									<span className="sidebar-option-title">Motoristas</span>
								</div>
							</NavLink>
						</>,
						"/dashboard"
					)}

					{verificarTipoCaminhoneiro(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/map">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-ui-checks"></i>
								</span>
								<span className="sidebar-option-title">Entregas</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{
						/* descarregar && */ verificarTipoCaminhoneiro(
							user.tipo,
							<NavLink className="nav-link nav-link-e" to="/descarregamento">
								<div className="sidebar-option">
									<span className="sidebar-option-icon">
										<i className="bi bi-boxes"></i>
									</span>
									<span className="sidebar-option-title">Descarregamento</span>
								</div>
							</NavLink>,
							"/dashboard"
						)
					}
					{verificarTipoGestao(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/plano-logistica">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-card-list"></i>
								</span>
								<span className="sidebar-option-title">
									Planejamento de Carga
								</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipo(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/equipamentos">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-phone"></i>
								</span>
								<span className="sidebar-option-title">Equipamentos</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoGestao(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/frota">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-truck"></i>
								</span>
								<span className="sidebar-option-title">Frota</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoGestao(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/carregamento-gestor">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-boxes"></i>
								</span>
								<span className="sidebar-option-title">Gestão Carga</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoCaminhoneiro(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/carregamento">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-boxes"></i>
								</span>
								<span className="sidebar-option-title">Carregamento</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoGestao(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/financeiro">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-currency-dollar"></i>
								</span>
								<span className="sidebar-option-title">Financeiro</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoGestao(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/abastecimento">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-fuel-pump"></i>
								</span>
								<span className="sidebar-option-title">Abastecimento</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}

					{verificarTipoSeparador(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/separacao">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-ui-checks-grid"></i>
								</span>
								<span className="sidebar-option-title">Separação</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}

					<NavLink
						className="nav-link nav-link-e"
						to="/controle-ponto"
						onClick={(e) => verifyConnection(e)}
					>
						<div className="sidebar-option">
							<span className="sidebar-option-icon">
								<i className="bi bi-calendar3"></i>
							</span>
							<span className="sidebar-option-title">Controle de Ponto</span>
						</div>
					</NavLink>

					<NavLink className="nav-link nav-link-e" to="/user">
						<div className="sidebar-option">
							<span className="sidebar-option-icon">
								<i className="bi bi-people"></i>
							</span>
							<span className="sidebar-option-title">Usuário</span>
						</div>
					</NavLink>

					{verificarTipos(
						user.tipo,
						<NavLink
							className="nav-link nav-link-e"
							to="/diario"
							onClick={(e) => verifyConnection(e)}
						>
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-person-bounding-box"></i>
								</span>
								<span className="sidebar-option-title">Diário de Bordo</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoCaminhoneiro(
						user.tipo,
						<NavLink
							className={`nav-link nav-link-e ${
								user.integracao ? "bg-warning" : ""
							}`}
							to="/diario"
							onClick={(e) => e.preventDefault()}
						>
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i
										className={`bi bi-${
											user.integracao ? "play-circle" : "stop-circle"
										}`}
									></i>
								</span>
								<span className="sidebar-option-title">
									{user.integracao
										? "Integração Iniciada"
										: "Integração Pausada"}
								</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoGestao(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/monitoramento-frota">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-pin-map-fill"></i>
								</span>
								<span className="sidebar-option-title">
									Monitoramento Frota
								</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoGestao(
						user.tipo,
						<NavLink className="nav-link nav-link-e" to="/transferencias">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-arrow-down-up"></i>
								</span>
								<span className="sidebar-option-title">Transferências</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}
					{verificarTipoSuporte(
						user.email,
						<NavLink className="nav-link nav-link-e" to="/controle-mensagens">
							<div className="sidebar-option">
								<span className="sidebar-option-icon">
									<i className="bi bi-whatsapp"></i>
								</span>
								<span className="sidebar-option-title">Mensagens</span>
							</div>
						</NavLink>,
						"/dashboard"
					)}

					<div style={{ width: "220px", position: "relative" }}>
						<img
							src={getImages("logos-empresa/fermat-logo.png")}
							className="logo"
							alt="Logo"
							width={"100%"}
							style={{}}
						/>
					</div>
				</div>
			</Nav>
		</Fragment>
	);
}
