import { useEffect, useLayoutEffect, useState } from "react";
import { confirmarTokenContabilidade } from "../../services/Servicos";
import ModalLoading from "../modal/ModalLoading";
import { useDispatch } from "react-redux";
import { update } from "../../redux/slicers/user";

export function ConfirmacaoFechamento() {
	const [loading, setLoading] = useState({
		show: false,
		msg: false,
		show_msg: "",
	});
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		const queryString = window.location.search;
		const params = new URLSearchParams(queryString);
		const token = params.get("token");
		if (!token) {
			window.location.replace("/login");
			return;
		}
		setLoading((last) => ({ ...last, show: true }));
	}, []);

	useEffect(() => {
		const queryString = window.location.search;
		const params = new URLSearchParams(queryString);
		const token = params.get("token");
		const carga = params.get("carga");
		const file = params.get("file");

		confirmarTokenContabilidade({
			token,
			confirmacaoFechamentoThiago: true,
			carga,
			file,
		})
			.then((response) => {
				setLoading({
					show: true,
					show_msg: true,
					msg: "Confirmação Realizada com Sucesso",
				});
				setTimeout(() => {
					dispatch(update({ authenticated: false }));
					window.location.replace("/login");
				}, 2000);
			})
			.catch((err) => {
				setLoading({
					show: true,
					show_msg: true,
					msg: "Confirmação Não Realizada. Tente Novamente",
				});
				setTimeout(() => {
					dispatch(update({ authenticated: false }));
					window.location.replace("/login");
				}, 5000);
			});
	}, []);

	return (
		<>
			{loading.show ? (
				<ModalLoading
					show={loading.show}
					setShow={setLoading}
					msg={loading.msg}
					show_msg={loading.show_msg}
				/>
			) : (
				""
			)}
		</>
	);
}
