import { Fragment, useCallback, useEffect, useState } from "react";
import {
	Card,
	Container,
	Nav,
	Navbar,
	NavDropdown,
	Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
	update,
	updateNotificacoesList,
	updateProdutosEEmbalagensBySku,
} from "../../redux/slicers/user";
import Footer from "../footer/Footer";
import Sidebar from "../sidebar/Sidebar";
import "./NavbarTop.scss";
import { switchSidebar } from "../../redux/slicers/sidebar";

import logo from "../../assets/blog1.png";
import { clearPersistData } from "../../redux/storeConfig/store";
import ModalAtualizarRecebimentoDinheiro from "../modal/ModalAtualizarRecebimentoDinheiro";
import ModalDiarioDeBordo from "../modal/ModalDiarioDeBordo";
import {
	getImages,
	getNotificacoes,
	getTimesAgo,
	locationDev,
	savalDanfeAssinada,
	updateTable,
	uploadImg,
} from "../../services/Servicos";
import { danfeController, fotosController } from "../../redux/slicers/carga";
import ModalError from "../modal/ModalError";
import ModalLoadingLogout from "../modal/ModalLoadingLogout";

const TIMER = 5000;
export default function NavbarTop({ children }) {
	const sidebarClose = useSelector((state) => state.sidebarSlice).sidebar;
	const fotoPerfil = useSelector((state) => state.userSlice).foto_perfil;
	const tipoUsuario = useSelector((state) => state.userSlice).tipo;
	const notificacoes = useSelector((state) => state.userSlice.notificacoesList);

	const [loading, setLoading] = useState({
		show: false,
		msg: "Saindo com Segurança",
	});
	const notificacoesList = [...notificacoes.data];

	const user = useSelector((state) => state.userSlice);
	const danfes = useSelector((state) => state.cargaSlicer.listasDanfes);
	let fotos = useSelector((state) => state.cargaSlicer.listasFotos);
	const produtosEEmbalagens = user.produtosEEmbalagens.data;
	const cargas = user.cargaSelecionada;
	const forcarIntegracao = user?.forcarIntegracao;

	const dispatch = useDispatch();

	const [msgCard, setMsgCard] = useState({
		show: false,
	});
	const [confirmarRecebimento, setConfirmarRecebimento] = useState({
		show: false,
	});
	const [diarioDeBordo, setDiarioDeBordo] = useState({
		show: false,
	});
	const [manutencaoMessage, setManutencaoMessage] = useState({
		flag: false,
	});
	const [tacografoMessage, setTacografoMessage] = useState({
		flag: false,
	});

	const onClickSidebar = () => {
		dispatch(switchSidebar());
	};

	const onClickShowMsgs = (e) => {
		e.preventDefault();
		setMsgCard((last) => ({ ...last, show: !last.show }));
	};
	const [error, setError] = useState({ flag: false, msg: "", title: "" });

	const onLogOut = async () => {
		if (user.authenticated || user.tipo === "Caminhoneiro") {
			if (user?.processo_entrega?.flag) {
				return setError({
					title: "ALERTA",
					flag: true,
					list: ["msg: Processo de entrega não finalizado. Logout inativado"],
				});
			}
		}
		setLoading({ show: true, msg: "Saindo com Segurança" });
		await clearPersistData();
		// dispatch(update({ authenticated: false, token: '', foto_cnh: '', email: user.email}))
		setTimeout(() => {
			window.location.replace("/login");
		}, 1000);
	};

	const setViewdNotifications = (e, msg) => {
		e.preventDefault();

		setConfirmarRecebimento({
			show: true,
			...msg,
		});
	};

	useEffect(() => {
		getNotificacoes({ user: user.email }).then((response) => {
			const manutencaoNotificacao = response.data.filter(
				(item) => item.tipo_notificacao == "MANUTENÇÃO"
			);
			const tacografoNotificacao = response.data.filter(
				(item) => item.tipo_notificacao == "VENCIMENTO DE TACOGRAFO"
			);

			if (manutencaoNotificacao.length) {
				let obj = [];
				manutencaoNotificacao.map((el, index) => {
					updateTable("notificacoes", el.id, {
						data_leitura_msg: new Date().toISOString(),
						status: 1,
					});
					obj.push(`Mensagem_${index + 1}: ${el.msg}`);
				});
				setManutencaoMessage({ flag: true, list: obj });
				return;
			}
			if (tacografoNotificacao.length) {
				let obj = [];
				tacografoNotificacao.map((el, index) => {
					updateTable("notificacoes", el.id, {
						data_leitura_msg: new Date().toISOString(),
						status: 1,
					});
					obj.push(`Mensagem_${index + 1}: ${el.msg}`);
				});
				setTacografoMessage({ flag: true, list: obj });
				return;
			}

			dispatch(updateNotificacoesList(response.data));
		});
	}, []);

	// automação de envio de danfes caso internet do usuario caia e ele se esqueca de enviar!
	const enviarDanfeAssinada = async () => {
		const blobToFile = (blob, fileName) => {
			blob.lastModifiedDate = new Date();
			blob.name = fileName;
			const file = new File([blob], `${fileName}.pdf`);
			return file;
		};

		for await (let danfe of danfes.filter(
			(item) => item.status.toUpperCase() == "ASSINADA"
		)) {
			const blob = new Blob([danfe.danfe_assinada], {
				type: "application/pdf",
			});

			try {
				await savalDanfeAssinada({
					file: blobToFile(blob, `${danfe.numero_pedido}`),
					teste: new Date().toLocaleTimeString(),
					cargas_id: danfe.cargas_id,
					numero_pedido: danfe.numero_pedido,
					caminhoneiro: user.cpf,
					caminhao: user.caminhaoSelecionado.caminhao_placa,
				});
				dispatch(
					danfeController({
						action: "update_status",
						numero_pedido: danfe.numero_pedido,
						id_filial: danfe.id_filial,
						status: "ENVIADA",
					})
				);
			} catch (error) {}
		}
	};

	// automação de envio das danfes caso internet do usuario caia e ele se esqueca de enviar!
	const enviarFotos = async () => {
		function dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(","),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[arr.length - 1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		}

		// })))
		// return
		let error = null;

		for await (let fotoL of fotos.filter(
			(item) => item.status.toLowerCase() != "enviada"
		)) {
			try {
				const data = await uploadImg(
					"entregas/pedido/upload_img",
					fotoL.cargas_id,
					fotoL.numero_pedido,
					{ foto: dataURLtoFile(fotoL.imgUrl, `Image-${fotoL.numero_pedido}`) }
				);
				dispatch(
					fotosController({
						action: "update",
						id: fotoL.id,
						dados: {
							status: "Enviada",
						},
					})
				);
			} catch (error) {
				error = error;
			}
		}
		if (error) {
			return;
		}
	};

	// automação de envio dos Produtos Descarregados caso internet do usuario caia e ele se esqueca de enviar!
	const atualizarProdutos = async () => {
		dispatch(update({ integracao: true }));
		try {
			const produtosDescarregado = produtosEEmbalagens.filter(
				(item) => item.descarregado && !item.integrado
			);
			// const produtosDescarregado = produtosEEmbalagens.filter(item => item.descarregado)

			if (produtosDescarregado.length) {
				for await (let produto of produtosDescarregado) {
					const objAtt = {
						user_descarregamento: produto.user_descarregamento,
						data_descarregamento: produto.data_descarregamento,
						descarregado: true,
						qr_sku: produto.qr_sku,
						tipo: produto.tipo,
						regiao_entrega: cargas.find((item) => item.id === produto.cargas_id)
							?.regiao_entrega,
						numero_embalagem: produto.numero_embalagem,
						numero_pedido: produto.numero_pedido,
					};
					try {
						const updated = await updateTable(
							"cargas/motorista/update-descarregamento",
							produto.cargas_id,
							{ ...objAtt }
						);
						dispatch(
							updateProdutosEEmbalagensBySku({
								parameter: "integrado",
								qr_sku: produto.qr_sku,
								integrado: true,
							})
						);
					} catch (error) {
						dispatch(update({ integracao: false }));
						break;
					}
				}
			}
		} catch (error) {}
		setTimeout(() => {
			dispatch(update({ integracao: false }));
		}, 500);
	};

	useEffect(() => {
		if (user.tipo !== "Caminhoneiro") return;
		if (
			!danfes.find(
				(danfe) => danfe.status == "ASSINADA" && danfe.danfe_assinada
			)
		)
			return;
		enviarDanfeAssinada()
		const interval = setInterval(() => {
			enviarDanfeAssinada();
		}, TIMER);

		return () => {
			clearInterval(interval);
		};
	}, [danfes, forcarIntegracao]);

	useEffect(() => {
		if (user.tipo !== "Caminhoneiro") return;
		if (!fotos.find((foto) => foto.status == "Não Enviada")) return;

		const interval = setInterval(() => {
			enviarFotos();
		}, 2 * 60 * 1000); //loop de 2 minuto

		return () => {
			clearInterval(interval);
		};
	}, [fotos, forcarIntegracao]);

	useEffect(() => {
		if (!user.authenticated || !user.tipo === "Caminhoneiro") return;

		if (!user?.processo_entrega?.flag) return;

		const interval = setInterval(
			() => {
				atualizarProdutos();
			},
			TIMER,
			user
		);

		return () => clearInterval(interval);
	}, [
		user.processo_entrega,
		produtosEEmbalagens.filter((item) => item.descarregado),
	]);

	return (
		<Fragment>
			<Navbar collapseOnSelect className="navbar-e">
				<Container className="container-e">
					<Navbar.Brand href="/">
						{/* {image.loading ? '' :  <img src={image.src} className='logo' alt="Logo" height={54} />} */}
						<img
							src={getImages("logos-empresa/b-log-mini.png")}
							className="logo"
							alt="Logo"
							height={54}
						/>
					</Navbar.Brand>
					<Navbar.Text onClick={onClickSidebar} className="me-auto i-e">
						<i
							className={
								sidebarClose ? "bi bi-x-lg opacity100" : "bi bi-x-lg opacity0"
							}
						></i>
						<i
							className={
								sidebarClose ? "bi bi-list opacity0" : "bi bi-list opacity100"
							}
						></i>
					</Navbar.Text>

					<Nav>
						<Nav className="me-auto"></Nav>
						<Nav className="align-items-center ">
							{tipoUsuario.toLowerCase() === "caminhoneiro" ? (
								<Nav.Item className="d-none d-sm-flex  notifications last-mails ms-2    ">
									<div
										className="notifications-div"
										onClick={() => setDiarioDeBordo({ show: true })}
										style={{
											cursor: "pointer",
											display: "flex",
											alignItems: "center",
										}}
									>
										<i
											className="bi bi-card-heading"
											title="Diário de Bordo"
										></i>
										Diário de Bordo
									</div>
								</Nav.Item>
							) : (
								""
							)}
							<Nav.Item className="notifications last-notifications">
								<div className="notifications-div" onClick={onClickShowMsgs}>
									<i className={`bi bi-bell`}></i>
									<div className="notifications-alert">
										{notificacoes.data.length ? (
											<Spinner animation="grow" size="sm" />
										) : (
											""
										)}
									</div>
									{msgCard.show ? (
										<Card className="span-notification-msg">
											{notificacoesList
												.sort(
													(a, b) =>
														new Date(a.createdAt).getTime() -
														new Date(b.createdAt).getTime()
												)
												.reverse()
												.map((item, index) => {
													return (
														<a
															href={item.msg}
															className="nav-link nav-link-e mx-0"
															onClick={(e) => setViewdNotifications(e, item)}
															key={index}
														>
															<div className="navLinkNotification">
																<span className="title">
																	{item.msg.length > 50
																		? `${item.msg.slice(0, 50)}...`
																		: item.msg}
																</span>
																<span className="time">
																	{getTimesAgo(item.createdAt)}
																</span>
															</div>
														</a>
													);
												})}
											{notificacoesList.length == 0 ? (
												<div className="navLinkNotification my-3">
													<span className="title text-center">
														Sem notificações
													</span>
												</div>
											) : (
												""
											)}
										</Card>
									) : (
										// <div className="span-notification-msg">
										// </div>
										""
									)}
								</div>
							</Nav.Item>
						</Nav>
					</Nav>
					<NavDropdown
						title={TitleDropdownUser(user.name, fotoPerfil)}
						className="dropdown-e ms-3"
						align={{ xs: "start" }}
					>
						<NavLink
							className="nav-link nav-link-e border-0 nav-link-e-profile"
							to="/user/profile"
						>
							<div className="">
								<div className="sidebar-option-profile">
									<span className="sidebar-option-icon-profile">
										<i className="bi bi-person"></i>
									</span>
									<span className="sidebar-option-title-profile">Profile</span>
								</div>
							</div>
						</NavLink>
						<NavLink
							className="nav-link nav-link-e border-0 nav-link-e-profile"
							to="/suporte"
						>
							<div className="">
								<div className="sidebar-option-profile">
									<span className="sidebar-option-icon-profile">
										<i className="bi bi-question-circle"></i>
									</span>
									<span className="sidebar-option-title-profile">Support</span>
								</div>
							</div>
						</NavLink>
						<NavLink
							className="nav-link nav-link-e border-0 nav-link-e-profile"
							to="/user/settings"
						>
							<div className="">
								<div className="sidebar-option-profile">
									<span className="sidebar-option-icon-profile">
										<i className="bi bi-gear"></i>
									</span>
									<span className="sidebar-option-title-profile">Settings</span>
								</div>
							</div>
						</NavLink>
						<Nav.Link
							className="nav-link-e border-0 nav-link-e-profile"
							onClick={onLogOut}
							hidden={() => {
								return false;
							}}
						>
							<div className="">
								<div className="sidebar-option-profile">
									<span className="sidebar-option-icon-profile">
										<i className="bi bi-box-arrow-right"></i>
									</span>
									<span className="sidebar-option-title-profile">Logout</span>
								</div>
							</div>
						</Nav.Link>
					</NavDropdown>
				</Container>
			</Navbar>

			{sidebarClose ? (
				<Sidebar show="transition100" />
			) : (
				<Sidebar show="transition0" />
			)}
			{sidebarClose ? (
				<Main className="main-section" children={children} />
			) : (
				<Main className="main-section-hide" children={children} />
			)}
			{confirmarRecebimento.show ? (
				<ModalAtualizarRecebimentoDinheiro
					show={confirmarRecebimento.show}
					setShow={setConfirmarRecebimento}
					{...confirmarRecebimento}
				/>
			) : (
				""
			)}
			{diarioDeBordo.show ? (
				<ModalDiarioDeBordo
					show={diarioDeBordo.show}
					setShow={setDiarioDeBordo}
					title="Relatório do Dia"
				/>
			) : (
				""
			)}
			{manutencaoMessage.flag ? (
				<ModalError
					show={manutencaoMessage.flag}
					setShow={setManutencaoMessage}
					title="Aviso de Manutenção"
					{...manutencaoMessage}
				/>
			) : (
				""
			)}
			{tacografoMessage.flag ? (
				<ModalError
					show={tacografoMessage.flag}
					setShow={setTacografoMessage}
					title="Aviso de Tacografo"
					{...tacografoMessage}
				/>
			) : (
				""
			)}
			{loading.show ? (
				<ModalLoadingLogout
					show={loading.show}
					setShow={setLoading}
					msg={loading.msg}
				/>
			) : (
				""
			)}

			{error.flag ? (
				<ModalError setShow={setError} show={error.flag} {...error} />
			) : (
				""
			)}
		</Fragment>
	);
}

function Main({ className, children }) {
	return (
		<Fragment>
			<div className={className}>
				{children}
				<Footer />
			</div>
		</Fragment>
	);
}

function TitleDropdownUser(name, fotoPerfil) {
	const user = useSelector((state) => state.userSlice).name;
	const [nome, sobrenome] = user.split(" ");
	return (
		<Fragment>
			<span>{`${nome} ${sobrenome ? sobrenome : ""}`}</span>
			{fotoPerfil ? (
				<img
					src={locationDev + "/files/users/" + fotoPerfil}
					className="img-perfil-navbar"
					alt="imagem"
				/>
			) : (
				<i className="fa-regular fa-circle-user"></i>
			)}
		</Fragment>
	);
}
