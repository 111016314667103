import { Fragment, useEffect, useRef, useState } from "react";
import {
	compareDates,
	getAllData,
	getDateInitMonth,
	getDateInitSEMANAL,
	getStatusPedidos,
	getStatusTrackingPedidos,
	numerosEditadoBR,
	valorMoeda,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import Loading from "../../loading/Loading";
import "./MonitoramentoPedidos.scss";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ModalError from "../../modal/ModalError";
import axios from "axios";
import ModalMonitorarPedidosVST from "./ModalMonitorarPedidosVST";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";

export default function MonitoramentoPedidos() {
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;
	const [cargas, setCargas] = useState([]);
	const [pedidos, setPedidos] = useState([]);
	const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedFilter, setSelectedFilter] = useState("");
	const [selectTableOrList, setSelectTableOrList] = useState(2);
	const [wordSearch, setWordSearch] = useState("");
	const [error, setError] = useState({ flag: false, msg: "", title: "" });
	const [sorted, setSorted] = useState({ option: "", order: 1 });
	const campoBuscarRef = useRef();
	const dispatch = useDispatch();
	const [dataParam, setDataParam] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "",
	});
	const [periodoInicial, setPeriodoInicial] = useState(``);

	const updateArrayCargas = async () => {
		let id_filial = user.id_filial;
		let cargas = [];

		if (user.id_filial == 2) {
			id_filial = null;
		}
		if (user.tipo !== "empresa") {
			cargas = await getAllData(verificaTipoUsuario(user) + "cargas", {
				id_filial,
			});
		} else {
			cargas = await getAllData(verificaTipoUsuario(user) + "cargas");
		}
		setCargas(cargas.data);
	};

	const findAllOrders = () => {
		let stringfilters = user.tipo === "empresa" ? "" : "";

		getAllData("dashboard/monitoramento-pedidos", { stringfilters })
			.then((response) => {
				const pedidos = response.data
					.filter(
						(i, n, s) =>
							s.findIndex((o) => o.numero_pedido === i.numero_pedido) == n
					)
					.sort((a, b) => {
						if (a.numero_pedido > b.numero_pedido) return 1;
						if (a.numero_pedido < b.numero_pedido) return -1;
						return 0;
					})
					.map((item) => ({
						...item,
						ultima_atualizacao_base: item.ultima_atualizacao,
						ultima_atualizacao: new Date(item.ultima_atualizacao)
							.toLocaleString("pt-BR")
							.toUpperCase(),
						emissao: new Date(item.emissao)
							.toLocaleDateString("pt-BR", { month: "long", day: "numeric" })
							.toUpperCase(),
						filial:
							filiaisList
								.find((item_) => item_.idFilial == item.id_filial)
								?.identificacaoInterna.replace("FERMAT - ", "") || "N/A",
						vendedor: item.nome_vendedor || "SEM VENDEDOR",
						pedido: item.numero_pedido,
						status_pedido: getStatusTrackingPedidos(item.status),
						produtos: response.data
							.filter((i) => item.numero_pedido === i.numero_pedido)
							.map((item) => ({
								id: item.id,
								descricao: item.descricao,
								qtde: item.qtde,
								peso: item.peso,
								hidden: false,
							})),
						cidade: item.cidade || "CIDADE NÃO INFORMADA",
					}));
				setPedidos(pedidos);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const filteredListFn = (fn = () => {}) => {
		if (selectedFilter == "filial") {
			fn((last) => [
				...last.map((item) => ({
					...item,
					hidden: item.filial.includes(wordSearch) ? false : true,
				})),
			]);
			return;
		}
		if (selectedFilter == "vendedor") {
			fn((last) => [
				...last.map((item) => ({
					...item,
					hidden: String(item.vendedor).includes(wordSearch) ? false : true,
				})),
			]);
			return;
		}
		if (selectedFilter == "pedido") {
			fn((last) => [
				...last.map((item) => ({
					...item,
					hidden: String(item.numero_pedido).includes(wordSearch)
						? false
						: true,
				})),
			]);
			return;
		}
		if (selectedFilter == "romaneio") {
			fn((last) => [
				...last.map((item) => ({
					...item,
					hidden: String(item.romaneio).includes(wordSearch) ? false : true,
				})),
			]);
			return;
		}
		if (selectedFilter == "cidade") {
			fn((last) => [
				...last.map((item) => ({
					...item,
					hidden: String(item.cidade).toUpperCase().includes(wordSearch)
						? false
						: true,
				})),
			]);
			return;
		}
		if (selectedFilter == "carga") {
			fn((last) => [
				...last.map((item) => ({
					...item,
					hidden: String(item.carga).toUpperCase().includes(wordSearch)
						? false
						: true,
				})),
			]);
			return;
		}
		fn((last) => [...last.map((item) => ({ ...item, hidden: false }))]);
	};
	useEffect(() => {
		findAllOrders();
		// updateArrayCargas()
	}, []);

	useEffect(() => {
		if (pedidosFiltrados.length) {
			filteredListFn(setPedidosFiltrados);
		} else {
			filteredListFn(setPedidos);
		}
	}, [wordSearch, selectedFilter]);

	const handleClickFilter = () => {
		if (filters.findIndex((o) => o[selectedFilter] == wordSearch) >= 0) {
			setError({
				flag: true,
				msg: "Item ja Filtrado",
			});
			return;
		}
		if (!wordSearch || !selectedFilter) return;
		setFilters((last) => [...last, { [selectedFilter]: wordSearch }]);
		campoBuscarRef.current.value = "";
		setWordSearch("");
		setSelectedFilter("");
	};

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filters, setFilters] = useState([]);

	useEffect(() => {
		if (!filters.length) {
			setPedidosFiltrados([]);
			filteredListFn(setPedidos);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [pedidos];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setPedidosFiltrados(arrayF);
	}, [filters]);

	useEffect(() => {
		let pedidosSorted = pedidosFiltrados.length
			? [...pedidosFiltrados]
			: [...pedidos];
		const pedidosS = pedidosSorted.sort((a, b) => {
			if (sorted.option == "status" || sorted.option == "pedido") {
				let a_v = Number(a[sorted.option]);
				let b_v = Number(b[sorted.option]);

				if (a_v > b_v) return 1;
				if (a_v < b_v) return -1;
				return 0;
			}
			if (sorted.option == "ultima_atualizacao") {
				let a_v = new Date(a.ultima_atualizacao_base).getTime();
				let b_v = new Date(b.ultima_atualizacao_base).getTime();

				if (a_v > b_v) return 1;
				if (a_v < b_v) return -1;
				return 0;
			}
			if (a[sorted.option] > b[sorted.option]) return 1;
			if (a[sorted.option] < b[sorted.option]) return -1;
			return 0;
		});

		if (pedidosFiltrados.length) {
			setPedidosFiltrados(sorted.order ? pedidosS : pedidosS.reverse());
		} else {
			setPedidos(sorted.order ? pedidosS : pedidosS.reverse());
		}
	}, [sorted]);

	const setDataAtt = (data_inicial, data_final) => {
		setDataParam({ data_inicial, data_final });
	};

	return (
		<Fragment>
			<div className="d-flex justify-content-end">
				{filters.map((item, key) =>
					Object.keys(item).map((key, index) => {
						return (
							<span className="filters-card" key={index}>
								<span className="filters">
									<span className="title">
										{key}: {item[key]}
									</span>
									<i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
								</span>
							</span>
						);
					})
				)}
			</div>
			<div className="d-flex justify-content-between align-items-center flex-wrap">
				<div className="bi-select-option mb-3">
					<i
						className={`bi bi-card-checklist ${
							selectTableOrList == 0 ? "selected" : ""
						}`}
						onClick={() => setSelectTableOrList(0)}
					></i>
					<i
						className={`bi bi-table ${
							selectTableOrList == 1 ? "selected" : ""
						}`}
						onClick={() => setSelectTableOrList(1)}
					></i>
					<i
						className={`bi bi-list ${selectTableOrList == 2 ? "selected" : ""}`}
						onClick={() => setSelectTableOrList(2)}
					></i>
				</div>

				{selectTableOrList == 2 ? <h5> {periodoInicial} </h5> : ""}
				<div className="card-filters-monit  mb-3">
					{loading ? <Loading msg={"Carregando"} color={"black"} /> : ""}

					<div className="card-new-filter">
						<i className="bi bi-search " onClick={handleClickFilter}></i>
						<input
							className="campo-buscar"
							placeholder="Buscar..."
							onChange={(e) => setWordSearch(e.target.value.toUpperCase())}
							ref={campoBuscarRef}
						></input>
					</div>
					{selectTableOrList == 2 ? (
						<div
							className={`option`}
							onClick={() =>
								setDataParam((last) => ({
									...last,
									show: true,
									filtro: "Monitoramento",
								}))
							}
						>
							Data Emissao
						</div>
					) : (
						""
					)}
					{selectTableOrList !== 2 &&
					(user.id_filial == 2 || user.tipo == "empresa") ? (
						<div
							className={`option ${
								selectedFilter === "filial" ? "selected" : ""
							}`}
							onClick={() => {
								if (!filiaisList.length)
									return setError({
										flag: true,
										title: "Aviso",
										msg: "B-LOG não conseguiu carregar informações de filiais do VST. Não será possivel filtrar por FILIAL",
									});
								setSelectedFilter((last) => (last == "filial" ? "" : "filial"));
							}}
						>
							Filial
						</div>
					) : (
						""
					)}
					{selectTableOrList !== 2 ? (
						<>
							<div
								className={`option ${
									selectedFilter === "vendedor" ? "selected" : ""
								}`}
								onClick={() =>
									setSelectedFilter((last) =>
										last == "vendedor" ? "" : "vendedor"
									)
								}
							>
								Vendedor
							</div>
							<div
								className={`option ${
									selectedFilter === "pedido" ? "selected" : ""
								}`}
								onClick={() =>
									setSelectedFilter((last) =>
										last == "pedido" ? "" : "pedido"
									)
								}
							>
								Pedido
							</div>
							<div
								className={`option ${
									selectedFilter === "romaneio" ? "selected" : ""
								}`}
								onClick={() =>
									setSelectedFilter((last) =>
										last == "romaneio" ? "" : "romaneio"
									)
								}
							>
								Romaneio
							</div>
							<div
								className={`option ${
									selectedFilter === "cidade" ? "selected" : ""
								}`}
								onClick={() =>
									setSelectedFilter((last) =>
										last == "cidade" ? "" : "cidade"
									)
								}
							>
								Cidade
							</div>
							<div
								className={`option ${
									selectedFilter === "carga" ? "selected" : ""
								}`}
								onClick={() =>
									setSelectedFilter((last) => (last == "carga" ? "" : "carga"))
								}
							>
								Carga
							</div>
						</>
					) : (
						""
					)}
				</div>
			</div>

			<Row>
				{selectTableOrList == 0 ? (
					<ShowPedidosEmTabela
						pedidosFiltrados={pedidosFiltrados}
						pedidos={pedidos}
						sorted={sorted}
						setSorted={setSorted}
					/>
				) : selectTableOrList == 1 ? (
					<ShowPedidosEmCards
						pedidosFiltrados={pedidosFiltrados}
						pedidos={pedidos}
					/>
				) : (
					<ShowCargasEmTabela
						pedidos={cargas}
						setDataParam={setDataParam}
						dataParam={dataParam}
						setPeriodoInicial={setPeriodoInicial}
						periodoInicial={periodoInicial}
					/>
				)}
			</Row>

			{error.flag ? (
				<ModalError
					title={"ATENÇÃO"}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
			{dataParam.show ? (
				<ModalAtualizarDataEntradaVST
					title={"Editar Data de Saida"}
					show={dataParam.show}
					setShow={setDataParam}
					{...dataParam}
					setDataParam={setDataParam}
					fn={setDataAtt}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function ShowPedidosEmTabela({ pedidosFiltrados, pedidos, sorted, setSorted }) {
	return (
		<Fragment>
			<div className="card-header"></div>
			<Row className="p-1 ">
				<Card
					style={{
						backgroundColor: "transparent",
						boxShadow: "unset",
						marginBottom: "5px",
					}}
				>
					<Row>
						<Col sm="3" className="d-flex">
							<div
								className="d-flex align-items-center"
								style={{ cursor: "pointer" }}
								onClick={() =>
									setSorted((last) => ({
										option: "filial",
										order: !last.order,
									}))
								}
							>
								{sorted.order == 1 && sorted.option == "filial" ? (
									<i className="bi bi-sort-alpha-down"></i>
								) : (
									<i className="bi bi-sort-alpha-up-alt"></i>
								)}
								<div
									style={{
										fontWeight: sorted.option == "filial" ? "800" : "300",
										marginLeft: "5px",
									}}
								>
									Filial
								</div>
							</div>
							<div
								className="d-flex align-items-center"
								style={{ cursor: "pointer", marginLeft: "50px" }}
								onClick={() =>
									setSorted((last) => ({ option: "carga", order: !last.order }))
								}
							>
								{sorted.order == 1 && sorted.option == "carga" ? (
									<i className="bi bi-sort-alpha-down"></i>
								) : (
									<i className="bi bi-sort-alpha-up-alt"></i>
								)}
								<div
									style={{
										fontWeight: sorted.option == "carga" ? "800" : "300",
										marginLeft: "5px",
									}}
								>
									Carga
								</div>
							</div>
						</Col>
						<Col sm="2" className="text-center">
							<div
								className="d-flex align-items-center"
								style={{ cursor: "pointer" }}
								onClick={() =>
									setSorted((last) => ({
										option: "cidade",
										order: !last.order,
									}))
								}
							>
								{sorted.order == 1 && sorted.option == "cidade" ? (
									<i className="bi bi-sort-alpha-down"></i>
								) : (
									<i className="bi bi-sort-alpha-up-alt"></i>
								)}
								<div
									style={{
										fontWeight: sorted.option == "cidade" ? "800" : "300",
										marginLeft: "5px",
									}}
								>
									Destino
								</div>
							</div>
						</Col>
						<Col sm="2">
							<div
								className="d-flex align-items-center"
								style={{ cursor: "pointer" }}
								onClick={() =>
									setSorted((last) => ({
										option: "vendedor",
										order: !last.order,
									}))
								}
							>
								{sorted.order == 1 && sorted.option == "vendedor" ? (
									<i className="bi bi-sort-alpha-down"></i>
								) : (
									<i className="bi bi-sort-alpha-up-alt"></i>
								)}
								<div
									style={{
										fontWeight: sorted.option == "vendedor" ? "800" : "300",
										marginLeft: "5px",
									}}
								>
									Vendedor
								</div>
							</div>
						</Col>
						<Col
							sm="2"
							className="justify-content-center d-flex"
							style={{ cursor: "pointer" }}
							onClick={() =>
								setSorted((last) => ({ option: "pedido", order: !last.order }))
							}
						>
							<div className="d-flex align-items-center">
								{sorted.order == 1 && sorted.option == "pedido" ? (
									<i className="bi bi-sort-alpha-down"></i>
								) : (
									<i className="bi bi-sort-alpha-up-alt"></i>
								)}
								<div
									style={{
										fontWeight: sorted.option == "pedido" ? "800" : "300",
										marginLeft: "5px",
									}}
								>
									Pedido
								</div>
							</div>
						</Col>
						<Col
							sm="3"
							className="justify-content-center d-flex"
							style={{ cursor: "pointer" }}
							onClick={() =>
								setSorted((last) => ({ option: "status", order: !last.order }))
							}
						>
							<div className="d-flex align-items-center">
								{sorted.order == 1 && sorted.option == "status" ? (
									<i className="bi bi-sort-alpha-down"></i>
								) : (
									<i className="bi bi-sort-alpha-up-alt"></i>
								)}
								<div
									style={{
										fontWeight: sorted.option == "status" ? "800" : "300",
										marginLeft: "5px",
									}}
								>
									Status
								</div>
							</div>
						</Col>
					</Row>
				</Card>
			</Row>
			{pedidosFiltrados.length
				? pedidosFiltrados.map((item, key) => {
						return (
							<Row
								key={key}
								style={{ marginBottom: "7px" }}
								hidden={item.hidden}
							>
								<Card className="p-3 monitoramento-pedido-list">
									<Row className="align-items-center p-1">
										<Col sm="2">
											<div className="d-flex">
												<i
													className="bi bi-shop"
													style={{ fontSize: "2.5rem" }}
												></i>
												<div style={{ marginLeft: "7px" }}>
													<div style={{ fontWeight: "800" }}>{item.filial}</div>
													<div
														className="text-muted"
														style={{ fontSize: "0.8rem" }}
													>
														Carga N°{item.carga}
													</div>
													<div
														className="text-muted"
														style={{ fontSize: "0.8rem" }}
													>
														{String(item.romaneio).padStart(7, 0)}
													</div>
												</div>
											</div>
										</Col>
										<Col sm="1" className="text-center">
											<span
												style={{
													transform: "rotate(0deg)",
													display: "inline-block",
												}}
											>
												<i
													className="bi bi-arrow-right"
													style={{ fontSize: "1.9rem" }}
												></i>
											</span>
										</Col>
										<Col sm="2">{item.cidade}</Col>
										<Col sm="2">{item.vendedor || "SEM VENDEDOR"}</Col>
										<Col sm="2">
											<div className="text-center">
												<div style={{ fontWeight: "800" }}>
													{String(item.pedido).padStart(7, 0)}
												</div>
												<div
													className="text-muted"
													style={{ fontSize: "0.8rem" }}
												>
													{item.emissao}
												</div>
											</div>
										</Col>
										<Col sm="3">
											<div className="text-center">
												<span
													style={{
														fontSize: "0.8rem",
														padding: "7px 15px",
														borderRadius: "10px",
														backgroundColor:
															item.status <= 2
																? "#00b4e1"
																: item.status <= 5
																? "#c3e100"
																: item.status <= 10
																? "#c3e100"
																: "#00c66a",
													}}
												>
													{getStatusTrackingPedidos(item.status)}
												</span>
												<div
													className="text-muted  mt-1"
													style={{ fontSize: "0.8rem" }}
												>
													{item.ultima_atualizacao}
												</div>
											</div>
										</Col>
									</Row>
								</Card>
							</Row>
						);
				  })
				: pedidos.map((item, key) => {
						return (
							<Row
								key={key}
								style={{ marginBottom: "7px" }}
								hidden={item.hidden}
							>
								<Card className="p-3 monitoramento-pedido-list">
									<Row className="align-items-center p-1">
										<Col sm="2">
											<div className="d-flex">
												<i
													className="bi bi-shop"
													style={{ fontSize: "1.9rem" }}
												></i>
												<div style={{ marginLeft: "7px" }}>
													<div style={{ fontWeight: "800" }}>{item.filial}</div>
													<div
														className="text-muted"
														style={{ fontSize: "0.8rem" }}
													>
														Carga N°{item.carga}
													</div>
													<div
														className="text-muted"
														style={{ fontSize: "0.8rem" }}
													>
														{String(item.romaneio).padStart(7, 0)}
													</div>
												</div>
											</div>
										</Col>
										<Col sm="1" className="text-center">
											<span
												style={{
													transform: "rotate(0deg)",
													display: "inline-block",
												}}
											>
												<i
													className="bi bi-arrow-right"
													style={{ fontSize: "1.9rem" }}
												></i>
											</span>
										</Col>
										<Col sm="2">{item.cidade}</Col>
										<Col sm="2">{item.vendedor || "SEM VENDEDOR"}</Col>
										<Col sm="2">
											<div className="text-center">
												<div style={{ fontWeight: "800" }}>
													{String(item.pedido).padStart(7, 0)}
												</div>
												<div
													className="text-muted"
													style={{ fontSize: "0.8rem" }}
												>
													{item.emissao}
												</div>
											</div>
										</Col>
										<Col sm="3">
											<div className="text-center">
												<span
													style={{
														fontSize: "0.8rem",
														padding: "7px 15px",
														borderRadius: "10px",
														backgroundColor:
															item.status <= 2
																? "#00b4e1"
																: item.status <= 5
																? "#c3e100"
																: item.status <= 10
																? "#c3e100"
																: "#00c66a",
													}}
												>
													{getStatusTrackingPedidos(item.status)}
												</span>
												<div
													className="text-muted  mt-1"
													style={{ fontSize: "0.8rem" }}
												>
													{item.ultima_atualizacao}
												</div>
											</div>
										</Col>
									</Row>
								</Card>
							</Row>
						);
				  })}
		</Fragment>
	);
}

function ShowPedidosEmCards({ pedidosFiltrados, pedidos }) {
	return pedidosFiltrados.length
		? pedidosFiltrados.map((item, index) => {
				return (
					<Col sm="12" md="6" xl="3" hidden={item.hidden} key={index}>
						<Card className="monitoramento-pedido">
							<Row className="header m-0 align-items-top">
								<Col>
									Romaneio <br />
									{String(item.romaneio).padStart(7, 0)}
								</Col>
								<Col className="header-status">
									{getStatusTrackingPedidos(item.status)}
								</Col>
							</Row>
							<div className="body">
								<Row
									className="justify-content-between align-items-center text-center"
									style={{ color: "white" }}
								>
									<Col xs="5">
										<h6>{String(item.numero_pedido).padStart(7, 0)}</h6>
										<p style={{ fontSize: "0.8rem" }}>{item.emissao}</p>
									</Col>
									<Col xs="2">
										<i className="bi bi-arrow-right"></i>
									</Col>
									<Col xs="5">
										<h6 title={item.cidade}>{String(item.cidade)}</h6>
									</Col>
								</Row>
								<h6 className="card-header mt-3 p-0">Produtos</h6>
								{item.produtos.map((p, key_) => {
									return (
										<Row
											className=" mt-3 justify-content-between align-items-center"
											key={key_}
										>
											<Col xs="6">
												<div className="text-title">{p.descricao}</div>
											</Col>
											<Col xs="2">
												<div className="text  text-center">{p.qtde}</div>
											</Col>
											<Col xs="4">
												<div className="text  text-end">
													{numerosEditadoBR(p.peso)} kg
												</div>
											</Col>
										</Row>
									);
								})}
								<hr></hr>
								<Row className=" mt-1 justify-content-between align-items-center">
									<Col sm="3">
										<div className="text-muted" style={{ fontWeight: "bold" }}>
											Total
										</div>
									</Col>
									<Col className="text-end">
										<div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
											{valorMoeda(item.valor_total)}
										</div>
										<div
											className="text-muted"
											style={{ fontSize: "0.8rem", fontWeight: "bold" }}
										>
											{numerosEditadoBR(
												item.produtos.reduce((p, c) => p + c.peso, 0)
											)}{" "}
											kg
										</div>
									</Col>
								</Row>
							</div>
							<div
								className="text-center"
								style={{
									fontSize: "1.2rem",
									fontWeight: "600",
									margin: "10px 5px",
								}}
							>
								{item.filial || "Dados de Filiais não Carregados"} -{" "}
								{item.nome_vendedor || "SEM VENDEDOR"}
							</div>
						</Card>
					</Col>
				);
		  })
		: pedidos.map((item, index) => {
				return (
					<Col sm="12" md="6" xl="3" hidden={item.hidden} key={index}>
						<Card className="monitoramento-pedido">
							<Row className="header m-0 align-items-top">
								<Col>
									Romaneio <br />
									{String(item.romaneio).padStart(7, 0)}
								</Col>
								<Col className="header-status">
									{getStatusTrackingPedidos(item.status)}
								</Col>
							</Row>
							<div className="body">
								<Row
									className="justify-content-between align-items-center text-center"
									style={{ color: "white" }}
								>
									<Col xs="5">
										<h6>{String(item.numero_pedido).padStart(7, 0)}</h6>
										<p style={{ fontSize: "0.8rem" }}>{item.emissao}</p>
									</Col>
									<Col xs="2">
										<i className="bi bi-arrow-right"></i>
									</Col>
									<Col xs="5">
										<h6 title={item.cidade}>{String(item.cidade)}</h6>
									</Col>
								</Row>
								<h6 className="card-header mt-3 p-0">Produtos</h6>
								{item.produtos.map((p, key_) => {
									return (
										<Row
											className=" mt-3 justify-content-between align-items-center"
											key={key_}
										>
											<Col xs="6">
												<div className="text-title">{p.descricao}</div>
											</Col>
											<Col xs="2">
												<div className="text  text-center">{p.qtde}</div>
											</Col>
											<Col xs="4">
												<div className="text  text-end">
													{numerosEditadoBR(p.peso)} kg
												</div>
											</Col>
										</Row>
									);
								})}
								<hr></hr>
								<Row className=" mt-1 justify-content-between align-items-center">
									<Col sm="3">
										<div className="text-muted" style={{ fontWeight: "bold" }}>
											Total
										</div>
									</Col>
									<Col className="text-end">
										<div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
											{valorMoeda(item.valor_total)}
										</div>
										<div
											className="text-muted"
											style={{ fontSize: "0.8rem", fontWeight: "bold" }}
										>
											{numerosEditadoBR(
												item.produtos.reduce((p, c) => p + c.peso, 0)
											)}{" "}
											kg
										</div>
									</Col>
								</Row>
							</div>
							<div
								className="text-center"
								style={{
									fontSize: "1.2rem",
									fontWeight: "600",
									margin: "10px 5px",
								}}
							>
								{item.filial || "Dados de Filiais não Carregados"} -{" "}
								{item.nome_vendedor || "SEM VENDEDOR"}
							</div>
						</Card>
					</Col>
				);
		  });
}

function ShowCargasEmTabela({
	dataParam,
	setDataParam = () => {},
	periodoInicial,
	setPeriodoInicial = () => {},
}) {
	const user = useSelector((state) => state.userSlice);
	const [resultados, setResultados] = useState([]);
	const [pedidos, setPedidos] = useState([]);
	const [loading, setLoading] = useState(pedidos.length ? false : true);
	// const [periodoInicial, setPeriodoInicial] = useState(``)
	const [error, setError] = useState({
		flag: false,
		msg: "",
	});

	const [cargaClick, setCargaClick] = useState({
		flag: false,
		target: null,
		style: {},
	});
	const getVSTAPI = async (data_inicial, data_final) => {
		setResultados([]);
		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setPeriodoInicial(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);

		// axios.get(`${process.env.REACT_APP_API_VST}/1/dtini/2023-10-01/dtfim/2023-11-27`, { timeout: 60000 })
		axios
			.get(
				`${process.env.REACT_APP_API_VST}/1/dtini/${data_inicial}/dtfim/${data_final}`,
				{ timeout: 60000 }
			)
			.then(async (resp) => {
				const vendas = resp.data.Vendas.filter(
					(item) => item.clienteRetira != "SIM"
				).filter((item) => Number(item.stLogistica) <= 12);
				const tipoOperacao = vendas
					.filter(
						(i, n, s) =>
							s.findIndex((o) => o.tipoOperacao === i.tipoOperacao) === n
					)
					.map((item) => item.tipoOperacao);

				const filiais = [...user.filiaisList.data];
				filiais.forEach((fili) => {
					let resultados = [];
					tipoOperacao.forEach((operacao) => {
						resultados.push({
							operacao,
							vendas: vendas
								.filter(
									(vendas) =>
										vendas.tipoOperacao == operacao &&
										fili.idFilial == vendas.idFilial
								)
								.map((item) => ({
									...item,
									dias_producao: compareDates(
										item.dtcadastro,
										item.dtfaturamento
									),
									status_pedido: getStatusTrackingPedidos(item.stLogistica),
									data_faturamento: item.dtfaturamento,
									data_cadastro: item.dtcadastro,
								})),
						});
					});
					setResultados((last) => [
						...last,
						{
							filial: fili.identificacaoInterna.replace("FERMAT - ", ""),
							operacoes: resultados,
						},
					]);
				});

				setLoading(false);
			})
			.catch((error) => {
				setError((last) => ({
					...last,
					msg: {
						msg: `Erro na Resposta do VST: ${
							error.message ?? "Sem mensagem de Erro"
						}`,
						...error,
					},
					flag: true,
				}));
				setLoading(false);
			});
	};

	/* first charge */
	useEffect(
		() => {
			const date = new Date();
			const dateCorrect = getDateInitMonth(date);
			getVSTAPI(dateCorrect.data_inicial, dateCorrect.data_final);
		},
		[
			/* dataParametros */
		]
	);

	useEffect(() => {
		if (!dataParam.data_final && !dataParam.data_inicial) return;
		setLoading(true);

		if (dataParam.filtro === "previsaoEntrega") {
			// setFilteredTableByDate(dataParam.data_inicial, dataParam.data_final, dataParam.filtro)
			const [anoI, mesI, diaI] = dataParam.data_inicial.split("-");
			const dataI = new Date(anoI, Number(mesI) - 1, diaI);
			const [anoF, mesF, diaF] = dataParam.data_final.split("-");
			const dataF = new Date(anoF, Number(mesF) - 1, diaF);

			// setFilters(last => ([...last, { [dataParam.filtro]: `de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`}]))

			setDataParam({
				show: false,
				data_inicial: null,
				data_final: null,
			});
			setLoading(false);
			return;
		}

		getVSTAPI(dataParam?.data_inicial, dataParam?.data_final).then(() => {
			setDataParam({
				show: false,
				data_inicial: null,
				data_final: null,
			});
		});
	}, [dataParam]);

	const onClickCarga = (e, filial, operacoes) => {
		e.preventDefault();

		// setColorText({
		//     color: 'rgb(255 255 255)',
		//     transition:'.5s',
		// })

		setCargaClick({
			flag: true,
			target: filial,
			operacoes,
			style: {
				// color: 'rgb(255 255 255)',
				backgroundColor: "#b12048",
			},
		});
	};

	return (
		<Fragment>
			{loading ? (
				<Loading msg={"carregando"} color={"black"} />
			) : (
				<Row>
					{resultados.map((item, key) => (
						<Col sm="6" xl="4" key={key}>
							<Card
								className="card-dashboard card-color-2"
								onClick={(e) => onClickCarga(e, item.filial, item.operacoes)}
								style={
									cargaClick.target == item.id
										? cargaClick.style
										: { width: "auto" }
								}
							>
								<h5 className="mb-4">{item.filial}</h5>
								<ul>
									{item.operacoes
										.sort((a, b) => {
											if (a.operacao > b.operacao) return 1;
											if (a.operacao < b.operacao) return -1;
											return 0;
										})
										.map((op, key_op) => (
											<li className="mb-2" key={key_op}>
												<span
													style={{
														fontWeight: "800",
														whiteSpace: "break-spaces",
													}}
												>
													{op.operacao}
												</span>
												<ul>
													<li>Pedidos: {op.vendas?.length}</li>
												</ul>
											</li>
										))}
								</ul>
								<p className="text-muted"></p>
								<i
									className={`bi bi-shop bi-dashboard ${
										cargaClick.target == item.filial ? " cardSelected" : ""
									}`}
								></i>
							</Card>
						</Col>
					))}
				</Row>
			)}
			{cargaClick.target ? (
				<ModalMonitorarPedidosVST
					show={cargaClick.flag}
					setShow={setCargaClick}
					operacoes={cargaClick.operacoes}
					filial={cargaClick.target}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
