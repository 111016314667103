import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateCargasList } from "../../redux/slicers/user";
import {
	apenasNumeros,
	numerosDecimal,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";

export default function ModalAtualizarFinanceiro(props) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});
	const [valores, setValores] = useState({
		cartao: props.cartao,
		valor_pedagio: props?.pedagio ?? "0",
		valor_refeicao: props?.valor_refeicao ?? "0",
		valor_cartao: props?.valor_cartao ?? "0",
		valor_dinheiro: props?.valor_dinheiro ?? "0",
		cargas_id: props.cargas_id,
	});

	const user = useSelector((state) => state.userSlice);

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const cargasR = useSelector((state) => state.userSlice.cargasList.data);

	const clickSalvar = async (e) => {
		e.preventDefault();

		setLoading((last) => ({
			...last,
			isLoading: true,
			style: "warning",
			msg: "Salvando",
		}));

		/* update data on db */
		// cargas/produtos/separador
		let body = {};

		Object.keys(valores).map((item) => {
			if (valores[item] && valores[item] != 0 && item !== "cartao") {
				body[item] = String(valores[item]).replace(",", ".");
			} else {
				body[item] = valores[item];
			}
		});

		await updateTable(
			verificaTipoUsuario(user) + "cargas/produtos/financeiro",
			props.cargas_id,
			body
		)
			.then((resp) => {
				setTimeout(() => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						style: "success",
						msg: "Salvo",
					}));

					setTimeout(() => {
						props.setAtualizarDespesa({ flag: true });
						handlerSimpleClose();
					}, 500);
				}, 1000);
			})
			.catch((e) => {
				alert("Error on updating table");
			});
	};

	const onChangeValue = (e) => {
		e.preventDefault();

		setValores((last) => ({
			...last,
			[e.target.name]: numerosDecimal(apenasNumeros(e.target.value)),
		}));
		if (e.target.name === "valor_pedagio") {
		}
	};

	const onChangeNameCartao = (e) => {
		e.preventDefault();

		setValores((last) => ({
			...last,
			cartao: String(e.target.value).toUpperCase(),
		}));
	};

	useEffect(() => {
		const valor_refeicao = Number(
			String(valores.valor_refeicao).replace(",", ".")
		);
		const valor_pedagio = Number(
			String(valores.valor_pedagio).replace(",", ".")
		);
		const valor_cartao = (valor_refeicao + valor_pedagio).toFixed(2);
		setValores((last) => ({
			...last,
			valor_cartao: `${valor_cartao}`.replace(".", ","),
		}));
	}, [valores.valor_refeicao, valores.valor_pedagio]);

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Editar Carga N° {props.cargas_id} - {props.nome}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => e.preventDefault()}>
						<Row className="align-items-end">
							<Col sm="4">
								<Form.Label name="parameter" style={{ fontSize: "1.2rem" }}>
									Cartão:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									className="form-control mb-2"
									placeholder={props.cartao}
									onChange={onChangeNameCartao}
									value={valores.cartao}
									name="cartao"
								/>
							</Col>
						</Row>
						<Row className="align-items-end">
							<Col sm="4">
								<Form.Label name="parameter" style={{ fontSize: "1.2rem" }}>
									Pedágio:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									className="form-control mb-2"
									placeholder={props.valor_pedagio ?? 0}
									onChange={onChangeValue}
									value={valores.valor_pedagio}
									name="valor_pedagio"
								/>
							</Col>
						</Row>
						<Row className="align-items-end">
							<Col sm="4">
								<Form.Label name="parameter" style={{ fontSize: "1.2rem" }}>
									Refeição:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									className="form-control mb-2"
									placeholder={props.valor_refeicao ?? 0}
									onChange={onChangeValue}
									value={valores.valor_refeicao}
									name="valor_refeicao"
								/>
							</Col>
						</Row>

						<Row className="align-items-end">
							<Col sm="4">
								<Form.Label name="parameter" style={{ fontSize: "1.2rem" }}>
									Valor do Cartão:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									className="form-control mb-2"
									placeholder={props.valor_cartao ?? 0}
									value={valores.valor_cartao}
									disabled
								/>
							</Col>
						</Row>
						<Row className="align-items-end">
							<Col sm="4">
								<Form.Label name="parameter" style={{ fontSize: "1.2rem" }}>
									Valor em Dinheiro:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									className="form-control mb-2"
									placeholder={props.valor_dinheiro ?? 0}
									value={valores.valor_dinheiro}
									onChange={onChangeValue}
									name="valor_dinheiro"
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={clickSalvar}
								disabled={loading.style !== "primary" ? true : false}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
