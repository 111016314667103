import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";

import "./Modal.scss";
import axios from "axios";
import { PDFDocument } from "pdf-lib";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import ModalError from "./ModalError";

export default function ModalVisualizarPDF(props) {
	const canvasRef = useRef();
	pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

	const [pdfRef, setPdfRef] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [numberPages, setNumberOfPages] = useState(1);

	const [loading, setLoading] = useState(true);
	const [pdfFile, setPdfFile] = useState(null);

	async function modifyPdf(data) {
		const pdfDoc = await PDFDocument.load(data);

		const pages = pdfDoc.getPages();
		const firstPage = pages[0];
		const { width, height } = firstPage.getSize();

		const pdfBytes = await pdfDoc.save();

		const bytes = new Uint8Array(pdfBytes);
		const blob = new Blob([bytes], { type: "application/pdf" });
		const docUrl = URL.createObjectURL(blob);

		setPdfFile(docUrl);
	}

	useEffect(() => {
		// modifyPdf()

		axios({
			method: "get",
			url: props.pdf,
			responseType: "arraybuffer",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/pdf",
			},
		})
			.then((response) => {
				modifyPdf(response.data);
			})
			.catch((err) => {
				// alert(JSON.stringify(err))

				setError({
					flag: true,
					msg: err,
					title: "Atenção",
				});
				setLoading(false);
			});
	}, []);

	const [error, setError] = useState({
		flag: false,
		title: "",
	});

	const renderPage = useCallback(
		(pageNum, pdf = pdfRef) => {
			pdf &&
				pdf.getPage(pageNum).then(function (page) {
					const viewport = page.getViewport({ scale: 1.3 });
					const canvas = canvasRef.current;
					const context = canvas.getContext("2d");
					var outputScale = window.devicePixelRatio || 1;
					canvas.width = Math.floor(viewport.width * outputScale);
					canvas.height = Math.floor(viewport.height * outputScale);
					canvas.style.width = Math.floor(viewport.width) + "px";
					canvas.style.height = Math.floor(viewport.height) + "px";

					var transform =
						outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

					var renderContext = {
						canvasContext: context,
						transform: transform,
						viewport: viewport,
					};
					page.render(renderContext);
				});
		},
		[pdfRef]
	);

	useEffect(() => {
		renderPage(currentPage, pdfRef);
	}, [pdfRef, currentPage, renderPage]);

	useEffect(() => {
		if (!pdfFile) return;
		const loadingTask = pdfjsLib.getDocument(pdfFile);
		loadingTask.promise.then(
			(loadedPdf) => {
				setPdfRef(loadedPdf);
				setNumberOfPages(loadedPdf.numPages);
				setLoading(false);
			},
			function (reason) {}
		);
	}, [pdfFile]);

	const nextPage = () =>
		pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

	const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, show: false, msg: "" }));

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex">{props.title} </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row style={{ justifyContent: "center", alignItems: "center" }}>
						<Col sm="4" md="2">
							<Button variant="info" onClick={prevPage}>
								<i className="bi bi-caret-left-fill"></i> Anterior
							</Button>
						</Col>
						<Col sm="4" md="2">
							{loading ? (
								<div style={{ textAlign: "center" }}>
									Carregando <Spinner size="sm" animation="border" />
								</div>
							) : (
								<div className="text-center" style={{ fontSize: "1.3rem" }}>
									{" "}
									{currentPage} / {numberPages}
								</div>
							)}
						</Col>
						<Col sm="4" md="2">
							<Button variant="info" onClick={nextPage}>
								Próximo <i className="bi bi-caret-right-fill"></i>
							</Button>
						</Col>
					</Row>
					<Row style={{ justifyContent: "center", padding: "0" }}>
						<div
							style={{
								display: "flex",
								maxHeight: "75vh",
								overflow: "auto",
								marginTop: "20px",
								padding: 0,
								justifyContent: "center",
							}}
						>
							<canvas
								ref={canvasRef}
								style={{
									boxShadow: "#000000b8 0px 0px 24px inset",
									padding: "64px",
									backgroundColor: "#9f9292",
								}}
							></canvas>
						</div>
					</Row>
				</Modal.Body>
			</Modal>
			{error.flag ? (
				<ModalError
					setShow={setError}
					show={error.flag}
					title={error.title}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</>
	);
}
