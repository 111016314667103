import { Fragment, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ModalError from "../../modal/ModalError"

import './BodyRow.scss'
import ModalAtualizarEquipamento from "../../modal/ModalAtualizarEquipamento"

export default function BodyRowEquipamentos({ headList, bodyList, index }) {
    const refRow = useRef(null)
    const refEditar = useRef(null)
    const dispatch = useDispatch()
    const userS = useSelector(state => state.userSlice)
    const filiaisList = userS.filiaisList.data

    const [adminFunction, setAdminFunction] = useState({
        flag: false,
        body: {}
    })
    const [error, setError] = useState({
        flag: false,
        title: '',
        msg: '',
    })

    const onClickEditarRow = (e) => {
        e.preventDefault()
        setAdminFunction({
            flag: true,
            body: bodyList
        })
        
    }


    return (
        <Fragment>
            {
                <tr ref={refRow}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            } else if (item === 'admin') {

                                return <td key={_index}>
                                    <div className="admin">
                                        {/* <span className="excluir"><i className="bi bi-x-lg" title="Excluir" onClick={onClickExcluirRow} ref={refExcluir}></i></span> */}
                                        <span className="editar"><i className="bi bi-pencil" title="Editar" onClick={onClickEditarRow} ref={refEditar}></i></span>
                                    </div>
                                </td>
                            } else if (item === 'id') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            }
                            else if (item === 'placa') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            }
                            else if (item === 'id_filial') { /* primary key can not be modified */

                                return <td key={_index}>{filiaisList.filter(item => item.idFilial == bodyList.id_filial)[0]?.identificacaoInterna ?? 'NÃO INFORMADO'}</td>

                            }
                            else if (item === 'id_filial_destino') { /* primary key can not be modified */

                                return <td key={_index}>{filiaisList.filter(item => item.idFilial == bodyList.id_filial_destino)[0]?.identificacaoInterna ?? 'NÃO INFORMADO'}</td>

                            }
                            else if (item === 'emissao') { /* primary key can not be modified */

                                return <td key={_index}>{new Date(bodyList.emissao).toLocaleDateString('pt-BR')}</td>

                            }
                            else if (item === 'status') {

                                return bodyList[item] ?
                                    <td key={_index} className={item}>
                                        <div className="div-status">
                                            <span className="div-status-ativo">Ativo</span>
                                        </div>
                                    </td> : <td key={_index} className={item}>
                                        <div className="div-status">
                                            <span className="div-status-inativo">Inativo</span>
                                        </div>
                                    </td>

                            } else if (item === 'createdAt' || item === 'updatedAt') { /* primary key can not be modified */

                                return <td key={_index} style={{minWidth:'10rem'}}>{bodyList[item]}</td>

                            } 
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            {adminFunction.flag ? <ModalAtualizarEquipamento title={'Atualizar Equipamento'} msg={adminFunction.msg} show={adminFunction} setShow={setAdminFunction} body={adminFunction.body} /> : ""}
            {error.flag ? <ModalError show={error.flag} setShow={setError} msg={error.msg} title={error.title}/> : ""}
        </Fragment>
    )
}