import {
	Page,
	Text,
	Document,
	StyleSheet,
	View,
	Image,
} from "@react-pdf/renderer";
import logoFermat from "../../assets/fermat_logo_3.png";
import { Fragment, useEffect, useState } from "react";
import {
	getCorretData,
	getStatusCarga,
	locationDev,
	numeroDecimais,
	numerosEditadoBR,
	valorMoeda,
} from "../../services/Servicos";

const styles = StyleSheet.create({
	body: {
		paddingTop: 10,
		paddingBottom: 50,
		paddingHorizontal: 30,
		fontSize: 10,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
	},
	text: {
		margin: 12,
		fontSize: 14,
		textAlign: "justify",
	},
	header: {
		fontSize: 8,
		color: "grey",
		width: "100%",
		textAlign: "right",
	},
	pageNumber: {
		fontSize: 10,
		textAlign: "center",
		color: "grey",
		marginBottom: 5,
	},
	headerFixed: {
		display: "flex",
		flexDirection: "row",
		margin: 12,
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: 0.5,
		borderColor: "#000",
		paddingBottom: 15,
	},
	footerFixed: {
		position: "absolute",
		bottom: 0,
		margin: 12,
		left: 12,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		color: "grey",
		// border:1,
		// borderColor: '#000',
	},
	bodyText: {
		padding: 3,
		marginBottom: 5,
		backgroundColor: "rgb(150, 150, 150)",
	},
	bodyTextOverline: {
		verticalAlign: "super",
		fontSize: "5px",
	},
	thead: {
		marginHorizontal: 12,
		width: "100%",
		color: "white",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		border: 1,
		borderColor: "white",
		// padding:0
	},
	td: {
		fontSize: 8,
		borderRight: 1,
		borderColor: "white",
		paddingHorizontal: 10,
		marginBottom: 0,
		textAlign: "left",
		backgroundColor: "transparent",
	},
});

export default function PdfMotorista(props) {
	const [dadosCaminhaoCaminhoneiro, setDadosCaminhaoCaminhoneiro] = useState({
		...props,
	});
	const pritierNumber = (num) => Number(num).toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2,})

	return (
		// <PDFViewer height={800} width={"100%"} showToolbar={false}>
		<Document title={`Relatorio de Entregas - ${props.motorista}`}>
			<Page style={styles.body}>
				<View style={styles.headerFixed} fixed>
					<View style={{ width: "50%" }}>
						<Image style={{ img: "100%" }} src={logoFermat}></Image>
					</View>
					<View style={styles.header}>
						<Text style={{ marginBottom: 5 }}>
							{props.cargas_id} - {props.nome}
						</Text>
						<Text style={{ marginBottom: 5 }}>Sistema B-LOG</Text>
						<Text style={{ marginBottom: 5 }}>
							FERMAT - Ind. e Com. de Texteis LTDA
						</Text>
					</View>
				</View>
				<View style={{ marginHorizontal: 12 }} fixed>
					<Text style={{ ...styles.bodyText }}>
						Motorista: {dadosCaminhaoCaminhoneiro.motorista}
					</Text>
					<Text style={{ ...styles.bodyText }}>
						Filial: {dadosCaminhaoCaminhoneiro.filial}
					</Text>
					<Text style={{ ...styles.bodyText }}>
						CPF: {dadosCaminhaoCaminhoneiro.cpfEdited} TEL:{" "}
						{dadosCaminhaoCaminhoneiro.telefone}
					</Text>
				</View>

				{/* new page because current view has break parameter */}
				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 18,
						color: "black",
						margin: 12,
						marginBottom: 40,
						backgroundColor: "transparent",
					}}
					fixed
					// break
				>
					Relatório de Cargas
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "100%",
						alignItems: "center",
						padding: 0,
					}}
				>
					{props?.cargas?.map((item, key, self) => {
						return (
							<>
								<View style={{ marginRight: 24 }} key={item.uuid}>
									<Text style={{ fontSize: 10, margin: 12, marginTop: 30 }}></Text>
									<Text style={{ fontSize: 10, marginHorizontal: 12, marginTop: 22 }}>
										Emissão: {getCorretData(item.emissao_carga)} | Filial de Gestão : { item.filial_carga } | Carga N° {item.id} 
									</Text>
									<Text style={{ fontSize: 10, marginHorizontal: 12, marginTop: 6, marginBottom: 6 }}>
										Peso:{" "} {pritierNumber(item.peso_total)} | Caminhão: {item.caminhao} | Status: {item.status_carga}
									</Text>
									<View
										style={{
											...styles.thead,
											backgroundColor: "rgb(16, 25, 53)",
										}}
									>
										<Text
											style={{
												...styles.bodyText,
												...styles.td,
												width: 60,
												fontSize: 6,
											}}
										>
											Emissao
										</Text>
										<Text
											style={{
												...styles.bodyText,
												...styles.td,
												width: 120,
												fontSize: 6,
											}}
										>
											Status
										</Text>
										<Text
											style={{
												...styles.bodyText,
												...styles.td,
												width: 160,
												fontSize: 6,
											}}
										>
											Cliente
										</Text>
										<Text
											style={{
												...styles.bodyText,
												...styles.td,
												width: 80,
												fontSize: 6,
											}}
										>
											Romaneio / Pedido
										</Text>
										<Text
											style={{
												...styles.bodyText,
												...styles.td,
												width: 40,
												fontSize: 6,
											}}
										>
											Vol
										</Text>
										<Text
											style={{
												...styles.bodyText,
												...styles.td,
												borderRight: 0,
												fontSize: 6,
											}}
										>
											Peso [kg]
										</Text>
									</View>
									{props?.pedidos
										?.filter((pedido) => pedido.id === item.id)
										?.map((pedido, key) => {
											const backgroundColor = "rgb(122, 138, 184)";
											return (
												<View
													style={{
														...styles.thead,
														backgroundColor: backgroundColor,
														borderTop: 0,
														color: "black",
													}}
													key={pedido.uuid}
												>
													<Text
														style={{
															...styles.bodyText,
															...styles.td,
															width: 60,
															borderCollapse: 'collapse',
															fontSize: 6,
														}}
													>
														{getCorretData(pedido.emissao_nf)}
													</Text>
													<Text
														style={{
															...styles.bodyText,
															...styles.td,
															width: 120,
															borderCollapse: 'collapse',
															fontSize: 6,
														}}
													>
														{pedido.status_pedido}
													</Text>
													<Text
														style={{
															...styles.bodyText,
															...styles.td,
															width: 160,
															borderCollapse: 'collapse',
															fontSize: 6,
														}}
													>
														{pedido.cliente.slice(0,35)}{pedido.cliente.length > 35 ? '...':''}
													</Text>
													<Text
														style={{
															...styles.bodyText,
															...styles.td,
															width: 80,
															borderCollapse: 'collapse',
															fontSize: 6,
														}}
													>
														{pedido.romaneio} / {pedido.numero_pedido}
													</Text>
													<Text
														style={{
															...styles.bodyText,
															...styles.td,
															width: 40,
															borderCollapse: 'collapse',
															fontSize: 6,
														}}
													>
														{pritierNumber(pedido.volume_pedido)}
													</Text>
													<Text
														style={{
															...styles.bodyText,
															...styles.td,
															borderRight: 0,
															fontSize: 6,
														}}
													>
														{pritierNumber(pedido.peso_nf)}
													</Text>
												</View>
											);
										})}
								</View>
							</>
						);
					})}
					<Text style={{ fontSize: 14, marginLeft: 24, marginTop: 75 }}>
						Total de Cargas: {props?.cargas?.length}
					</Text>
					<Text style={{ fontSize: 14, marginLeft: 24, marginTop: 10 }}>
						Total de Pedidos: {props?.pedidos?.length}
					</Text>
					<Text style={{ fontSize: 14, marginLeft: 24, marginTop: 10 }}>
						Peso Total:{" "}
						{pritierNumber( props?.pedidos?.reduce((p, c) => p + c.peso_nf, 0))}
					</Text>
				</View>

				<View style={styles.footerFixed} fixed>
					<View>
						<Text style={{ marginBottom: 5 }}>
							THIAGO ARAUJO -{" "}
							{new Date().toLocaleString("pt-BR", {}).replace(",", "")}
						</Text>
					</View>
					<View>
						<Text
							style={styles.pageNumber}
							render={({ pageNumber, totalPages }) =>
								`${pageNumber}/${totalPages}`
							}
						/>
					</View>
				</View>
			</Page>
		</Document>
		// </PDFViewer>
	);
}
