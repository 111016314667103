import { Fragment, createRef, useEffect, useState } from "react"
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { getAllData, getCaminhoes, getDateInitMonth, getTableColumns, verificaTipoUsuario } from "../../services/Servicos"
import './Abastecimento.scss'
import ModalLancarAbastecimento from "../modal/ModalLancarAbastecimento"
import { update, updateAbastecimentosList, updateCaminhoes } from "../../redux/slicers/user"
import BodyRowAbastecimento from "../table/body/BodyRowAbastecimento"
import ScrollbarLeft from "../scrollbars/ScrollbarLeft"
import HeadTable from "../table/head/HeadRow"
import { filtroDinamicoArrays, removerDadosDuplicados } from "../../services/helpers"
import DashboardAbastecimentos from "./DashboardAbastecimentos"
import ExportToExcel from "../exportToExcel/ExportToExcel"


const tableRef = createRef()
const tbodyRef = createRef()


export default function Abastecimento() {
    const user = useSelector(state => state.userSlice)
    return (
        <Fragment>
            <DashboardAbastecimentos />
            <Col sm='12'>
                <div className="card-header d-flex mt-4 pb-2"> 
                    <h3 className="">Base de Dados</h3>
                </div>
            </Col>
            <Card className='card-user'>
                <ListarAbastecimentos />
            </Card>
        </Fragment>
    ) 
}

function ListarAbastecimentos() {
    const dispatch = useDispatch()

    const abastecimentos = useSelector(state => state.userSlice.abastecimentosList)
    const user = useSelector(state => state.userSlice)
    const [loading, setLoading] = useState(true)
    const [indexList, setIndexList] = useState(10)
    const [filteredTable, setFilteredTable] = useState(false)
    const [filters, setFilters] = useState([])
    const [lancarModal, setLancarModal] = useState({
        flag:false
    })
    const [editarAbastecimento, setEditarAbastecimento] = useState({
        flag:false
    })
    const [dataFiltro, setDataFiltro] = useState('')
    const [error, setError] = useState({
        flag:false,
        msg:''
    })

    useEffect(() => {
        if(!filters.length){
            setIndexList(15)
            setFilteredTable(false)
            return
        }

        let testeFilters = {}

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            testeFilters[param] = []
        })

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            // testeFilters[param].push(ele[param])
            testeFilters[param].push(String(ele[param]).toLowerCase())
            
            if (String(ele[param]).toLowerCase() == 'false') {
                testeFilters[param].push('null')
                
            }
        })

        
        let arrayRetorno = [abastecimentos.data]

        Object.keys(testeFilters).forEach((key, index) => {
            arrayRetorno.push(filtroDinamicoArrays(testeFilters, key, arrayRetorno[arrayRetorno.length - 1], ))
        })

        const arrayF = removerDadosDuplicados(arrayRetorno[arrayRetorno.length - 1], 'id')
        setFilteredTable(arrayF.reverse())
        
    }, [filters, abastecimentos])


    useEffect(() => {
        const refTable = tableRef.current
        const scrollHandler = (e) => {
            const tbodyHeight = tbodyRef.current.clientHeight
            const divTableHeight = e.target.clientHeight
            const scrollPosition = e.target.scrollTop
            if(scrollPosition >= tbodyHeight - divTableHeight){
                setTimeout(() => {
                    setIndexList(indexList + 10)
                },1000)
            }

        }

        refTable.addEventListener('scroll', scrollHandler, false)

        return () => refTable.removeEventListener('scroll', scrollHandler, false)
    })


    const onClickRemoveFilter = (e) => {
        e.preventDefault()
        const value = e.target.parentNode.firstElementChild.innerText
        const [key, keyValue] = value.split(':')
        setFilters(last => ([...last.filter(item => String(item[key]).replaceAll(' ','') !== keyValue.replaceAll(' ',''))]))

    }


    const [headList, setHead] = useState([])


    const updateColums = async () => {
        const abastecimentopHeader = await getTableColumns('Combustivel')
        const except = ['emissao', 'created_at','updated_at','empresas_id','data_abastecimento']

        if (user.email.toUpperCase() === process.env.REACT_APP_EMAIL_SUPORTE) {
            setHead(last => [
                'suporte',
                'index',
                'admin',
                'emissao',
                ...Object
                    .keys(abastecimentopHeader.data)
                    .filter(key => !except.includes(key))
            ])
        } else {
            setHead(
                [
                    'index',
                    'admin',
                    'emissao',
                    ...Object
                        .keys(abastecimentopHeader.data)
                        .filter(key => !except.includes(key))
                ]
            )
            
        }
    }

    const updateData = async (data_inicial, data_final) => {
        setLoading(true)
        let id_filial = user.id_filial

        if (user.id_filial == 2 || user.tipo.toLowerCase() == 'empresa') {
            id_filial = null
        }
        
        const [anoI, mesI, diaI] = data_inicial.split('-')
        const dataI = new Date(anoI, Number(mesI) - 1, diaI)
        const [anoF, mesF, diaF] = data_final.split('-')
        const dataF = new Date(anoF, Number(mesF) - 1, diaF)
        let objCons = {id_filial, data_inicial, data_final}

        setDataFiltro(`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`)
        try {
            const dados = await getAllData(verificaTipoUsuario(user) + 'abastecimentos', objCons)
            dispatch(updateAbastecimentosList(dados.data))
        } catch (error) {
            setError({
                msg: 'Error na obtenção dos Manifestos existentes.' + JSON.stringify(error),
                flag: true
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        updateColums()
    }, [])

    const [atualizar, setAtualizar] = useState(0)
    useEffect(() => {
        const date = new Date()
        const dateCorrect = getDateInitMonth(date)
        updateData(dateCorrect.data_inicial, dateCorrect.data_final)
    }, [atualizar])
    
    
    const [filtersNew, setFiltersNew] = useState({})


    return (
        <Fragment>
            <div className="section-filters-table mb-5">
                <div className="card-header"> 
                    <h3>Filtros Selecionados - <i style={{fontSize:'inherit'}}>{dataFiltro}</i></h3>
                </div>
                <Row className='mt-2 row-filters'>
                    <Col>
                        <div>
                            {
                                filters.map((item, key) => 
                                    Object
                                        .keys(item)
                                        .map((key, index) => {
                                            return <span className="filters-card" key={index}>
                                                        <span  className='filters'>
                                                            <span className='title'>
                                                                {key}: {item[key]}
                                                            </span>
                                                            <i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
                                                        </span>
                                                </span>
                                        })
                                )
                            }

                        </div>
                    </Col>
                </Row>
            </div>
            <div className="section-filters-table">
                <div className="card-header pb-2"> 
                    <Row className='justify-content-between'>
                        <Col sm='12' md='6' lg='8'>
                            <h3 style={{display: 'flex',alignItems:'center'}}>Tabela de Abastecimentos {loading ? <Spinner style={{marginLeft:'10px', fontSize:'1rem'}} animation="border" size="sm" />:""}</h3>
                        </Col>
                        
                        <Col sm='12' md='6' lg='2'>
                            <ExportToExcel tableName="Abastecimentos" reference={filteredTable.length ? filteredTable : [...abastecimentos.data]} />
                        </Col>

                        <Col sm='12' md='6' lg='2'>
                            <Button variant="primary" onClick={() => setLancarModal({flag: true, setAtualizar})}>+Adicionar</Button>
                        </Col>
                        
                    </Row>
                </div>
                <div className=" responsive-table" ref={tableRef} style={{position: 'relative',height:'600px', maxHeight:'unset'}}>
                    <ScrollbarLeft tableRef={tableRef} coluna2={3} maxima_distancia={180} />
                    {
                        loading ? "" :
                        <Table hover size='sm'>
                            <thead style={{ zIndex: 49 }}>
                                <HeadTable rota='caminhoes'
                                    arrayValues={abastecimentos.data}
                                    headerList={headList}
                                    setArrayFilters={setFiltersNew}
                                    arrayFilters={filtersNew}
                                    setFiltersOld={setFilters}
                                    filtersOld={filters}
                                    fn={updateData}
                                />
                            </thead>
                            <tbody ref={tbodyRef}>

                                {
                                    filteredTable ?
                                        filteredTable.map((item, index, self) => {
                                            if (index < indexList) {
                                                return <BodyRowAbastecimento key={index} bodyList={item} headList={headList} index={index} setEditarAbastecimento={setEditarAbastecimento} tableLength={self.length} />
                                            }
                                            // return item
                                        })
                                        :
                                        [...abastecimentos.data].reverse().map((item, index, self) => {
                                            if (index < indexList) {
                                                return <BodyRowAbastecimento key={index} bodyList={item} headList={headList} index={index} setEditarAbastecimento={setEditarAbastecimento} tableLength={self.length} />
                                            }
                                            // return item
                                        })
                                }

                            </tbody>
                        </Table>
                    }
                </div>
            </div>  
            {lancarModal.flag ? <ModalLancarAbastecimento show={lancarModal.flag} setShow={setLancarModal} title={'Lançar Novo Abastecimento'} {...lancarModal} /> : ""}
            {editarAbastecimento.flag ? <ModalLancarAbastecimento setAtualizar={ setAtualizar } show={editarAbastecimento.flag} setShow={setEditarAbastecimento} title={editarAbastecimento.title} editar={true} body={editarAbastecimento.body} />: ""}
        </Fragment>
    )
}

