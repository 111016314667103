import { Canvas, useFrame } from "@react-three/fiber";
import { Fragment, Suspense, useEffect, useRef, useState } from "react";
import {
	OrbitControls,
	TransformControls,
	useGLTF,
	/* OrbitControls, */ useHelper,
} from "@react-three/drei";
import * as THREE from "three";
import "./Carregamento.scss";
import {
	Selection,
	Select,
	EffectComposer,
	Outline,
} from "@react-three/postprocessing";
import { useDispatch, useSelector } from "react-redux";
import {
	addProduto,
	removeProduto,
	updateProduto,
} from "../../redux/slicers/carga";
import { v4 as uuidv4 } from "uuid";
import {
	getData,
	numerosEditadoBR,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";
import {
	update,
	updateCaminhaoSelecionado,
	updateEmbalagensList,
	updateEmbalagensListPlanejados,
	updateProdutosList,
	updateProdutosListPlanejados,
} from "../../redux/slicers/user";
import Loading from "../loading/Loading";
import ModalError from "../modal/ModalError";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { InformacaoParametro } from "./CarregarCaminhaoNew";
import Caminhao from "./Caminhao";
import {
	Line,
	Loader,
	distanceVector,
	fatorMedida,
	positionCalculadaProduto,
} from "./Helpers";
import ModalGetCargaCarregamentoSeparador from "../modal/ModalGetCargaCarregamentoSeparador";
import ModalErrorCarregametoSeparador from "../modal/ModalErrorCarregametoSeparador";

function WoodBox({
	position,
	args,
	target,
	setTarget,
	name,
	mass,
	argsDesenho,
	argsRealCaminhao,
	actual,
	last,
	tipo,
	setInformacoesCarga = () => {},
	cor_caixa,
	disponivel,
}) {
	const [inside, setInside] = useState(false);

	const caminhaoDB = useSelector(
		(state) => state.userSlice.caminhaoSelecionado
	);
	const dispatch = useDispatch();

	const fatorMedidaObjeto = fatorMedida(argsDesenho, argsRealCaminhao);
	const argsReal = [
		args[0] * fatorMedidaObjeto.fator_largura,
		args[1] * fatorMedidaObjeto.fator_altura,
		args[2] * fatorMedidaObjeto.fator_comprimento,
	];
	const argsSimulado = (args) => [
		args[0] / fatorMedidaObjeto.fator_largura,
		args[1] / fatorMedidaObjeto.fator_altura,
		args[2] / fatorMedidaObjeto.fator_comprimento,
	];
	const argsRealLast = (args) => [
		args[0] * fatorMedidaObjeto.fator_largura,
		args[1] * fatorMedidaObjeto.fator_altura,
		args[2] * fatorMedidaObjeto.fator_comprimento,
	];

	const positionCalculada = positionCalculadaProduto(position, argsReal); // [position[0] + argsReal[0] / 2, position[1] + argsReal[1] / 2, position[2] + argsReal[2] / 2]

	const ref = useRef(null);

	const [hover, setHover] = useState(false);

	/* restrições de movimento para o componente */
	useFrame(() => {
		if (target && target.name === name) {
			/* restrições de movimento para os objetos */
			target.position.x =
				target.position.x < argsReal[0] / 2
					? argsReal[0] / 2
					: target.position.x < argsDesenho.l - argsReal[0] / 2
					? target.position.x
					: argsDesenho.l - argsReal[0] / 2;
			target.position.y =
				target.position.y < argsReal[1] / 2
					? argsReal[1] / 2
					: target.position.y < argsDesenho.a - argsReal[1] / 2
					? target.position.y
					: argsDesenho.a - argsReal[1] / 2;
			target.position.z =
				target.position.z < argsReal[2] / 2
					? argsReal[2] / 2
					: target.position.z < argsDesenho.c - argsReal[2] / 2
					? target.position.z
					: argsDesenho.c - argsReal[2] / 2;
			const lastArgs = last.geometry.parameters;
			const teste2 = distanceVector(actual.position, last.position);
			const para1 = distanceVector(last.position, actual.position);

			const distanceOrigem1 = distanceVector(actual.position, {
				x: 0,
				y: 0,
				z: 0,
			});

			if (actual.position.z > last.position.z) {
				teste2.z -= lastArgs?.depth - lastArgs?.depth;
				para1.z -= lastArgs?.depth - lastArgs?.depth;

				/* origem */
				distanceOrigem1.z -= lastArgs?.depth - lastArgs?.depth;
			} else {
				teste2.z += lastArgs?.depth - lastArgs?.depth;
				para1.z += lastArgs?.depth - lastArgs?.depth;

				/* origem */
				distanceOrigem1.z += lastArgs?.depth - lastArgs?.depth;
			}
			if (actual.position.y > last.position.y) {
				teste2.z -= lastArgs?.height - lastArgs.height;
				para1.z -= lastArgs?.height - lastArgs?.height;
				/* origem */
				distanceOrigem1.z -= lastArgs?.height - lastArgs.height;
			} else {
				teste2.z += lastArgs?.height - lastArgs?.height;
				para1.z += lastArgs?.height - lastArgs?.height;
				/* origem */
				distanceOrigem1.z += lastArgs?.height - lastArgs?.height;
			}
			if (actual.position.x > last.position.x) {
				teste2.z -= lastArgs?.width - lastArgs?.width;
				para1.z -= lastArgs?.width - lastArgs?.width;
				// origem
				distanceOrigem1.z -= lastArgs?.width - lastArgs?.width;
			} else {
				teste2.z += lastArgs?.width - lastArgs?.width;
				para1.z += lastArgs?.width - lastArgs?.width;
				/* origem */
				distanceOrigem1.z += lastArgs?.width - lastArgs?.width;
			}

			let teste4 = {};
			let teste5 = {};
			let teste6 = {};
			teste4.D = (distanceOrigem1.x / argsReal[0]) * args[0] - args[0] / 2;
			teste4.P = (distanceOrigem1.y / argsReal[1]) * args[1] - args[1] / 2;
			teste4.T = (distanceOrigem1.z / argsReal[2]) * args[2] - args[2] / 2;
			// Frente
			teste5.E =
				(distanceOrigem1.x / argsReal[0]) * args[0] +
				args[0] / 2 -
				caminhaoDB.caminhao_largura;
			teste5.T =
				(distanceOrigem1.y / argsReal[1]) * args[1] +
				args[1] / 2 -
				caminhaoDB.caminhao_altura;
			teste5.F =
				(distanceOrigem1.z / argsReal[2]) * args[2] +
				args[2] / 2 -
				caminhaoDB.caminhao_comprimento;

			setInformacoesCarga((last) => ({
				...last,
				distancia_ao_caminhao: {
					...last.distancia_ao_caminhao,
					tampa_frontral: numerosEditadoBR(teste5.F), // frontal
					teto: numerosEditadoBR(teste5.T), // teto
					tampa_lateral_esquerda: numerosEditadoBR(teste5.E), // lateral esquerda
					tampa_traseira: numerosEditadoBR(teste4.T), // traseira
					acoalho: numerosEditadoBR(teste4.P), // piso
					tampa_lateral_direita: numerosEditadoBR(teste4.D), // lateral direita
				},
			}));

			const dimensaoRealLast = argsSimulado([
				last?.geometry?.parameters?.width,
				last?.geometry?.parameters?.height,
				last?.geometry?.parameters?.depth,
			]);
			const dimensaoDesenholLast = argsRealLast([
				dimensaoRealLast[0],
				dimensaoRealLast[1],
				dimensaoRealLast[2],
			]);

			if (!dimensaoRealLast[0]) {
				if (inside) setInside(false);
				return;
			}
			const menorLarg =
				dimensaoRealLast[0] > args[0] ? args[0] : dimensaoRealLast[0];
			const menorAlt =
				dimensaoRealLast[1] > args[1] ? args[1] : dimensaoRealLast[1];
			const menorComp =
				dimensaoRealLast[2] > args[2] ? args[2] : dimensaoRealLast[2];

			const maiorLarg =
				dimensaoRealLast[0] < args[0] ? args[0] : dimensaoRealLast[0];
			const maiorAlt =
				dimensaoRealLast[1] < args[1] ? args[1] : dimensaoRealLast[1];
			const maiorComp =
				dimensaoRealLast[2] < args[2] ? args[2] : dimensaoRealLast[2];

			// // objeto
			teste6.l =
				-1 *
				((para1.x / dimensaoDesenholLast[0]) * dimensaoRealLast[0] -
					dimensaoRealLast[0] / 2 +
					args[0] / 2);
			teste6.a =
				-1 *
				((para1.y / dimensaoDesenholLast[1]) * dimensaoRealLast[1] -
					dimensaoRealLast[1] / 2 +
					args[1] / 2);
			teste6.c =
				-1 *
				((para1.z / dimensaoDesenholLast[2]) * dimensaoRealLast[2] -
					dimensaoRealLast[2] / 2 +
					args[2] / 2);
			const selecteMaiorAltura = (param) => {
				if (param) {
					if (maiorAlt * -1 <= teste6.a && teste6.a <= menorAlt * 1) {
						return true;
					} else {
						return false;
					}
				} else {
					if (maiorAlt >= teste6.a && teste6.a >= menorAlt * -1) {
						return true;
					} else {
						return false;
					}
				}
			};
			const selecteMaiorComprimento = (param) => {
				if (param) {
					if (maiorComp * -1 <= teste6.c && teste6.c <= menorComp * 1) {
						return true;
					} else {
						return false;
					}
				} else {
					if (maiorComp >= teste6.c && teste6.c >= menorComp * -1) {
						return true;
					} else {
						return false;
					}
				}
			};
			const selecteMaiorLargura = (param) => {
				if (param) {
					if (maiorLarg * -1 <= teste6.l && teste6.l <= menorLarg * 1) {
						return true;
					} else {
						return false;
					}
				} else {
					if (maiorLarg >= teste6.l && teste6.l >= menorLarg * -1) {
						return true;
					} else {
						return false;
					}
				}
			};

			const produtoMaiorLarguraSelecionado =
				args[0] === maiorLarg ? true : false;
			const produtoMaiorAlturaSelecionado = args[1] === maiorAlt ? true : false;
			const produtoMaiorComprimentoSelecionado =
				args[2] === maiorComp ? true : false;
			const flagAltura = produtoMaiorAlturaSelecionado
				? selecteMaiorAltura(true)
				: selecteMaiorAltura(false);
			const flagComprimento = produtoMaiorComprimentoSelecionado
				? selecteMaiorComprimento(true)
				: selecteMaiorComprimento(false);
			const flagLargura = produtoMaiorLarguraSelecionado
				? selecteMaiorLargura(true)
				: selecteMaiorLargura(false);

			if (flagAltura && flagLargura && flagComprimento) {
				if (!inside) {
					setInside(true);
				}
			} else {
				if (inside) {
					setInside(false);
				}
			}

			setInformacoesCarga((last) => ({
				...last,
				distancia_ao_produto: {
					...last.distancia_ao_caminhao,
					altura: numerosEditadoBR(teste6.a), // frontal
					largura: numerosEditadoBR(teste6.l), // teto
					comprimento: numerosEditadoBR(teste6.c), // lateral esquerda
				},
			}));

			return;
		}
	});

	const updateProdutoSelected = (target) => {
		const position = [target.position.x, target.position.y, target.position.z];
		const argsReal = [
			target.geometry.parameters.width,
			target.geometry.parameters.height,
			target.geometry.parameters.depth,
		];
		const positionCalculada = [
			position[0] - argsReal[0] / 2,
			position[1] - argsReal[1] / 2,
			position[2] - argsReal[2] / 2,
		];

		const item = {
			itemName: target.name,
			newPosition: positionCalculada,
		};
		dispatch(updateProduto(item));
	};

	return (
		<Select enabled={hover}>
			<mesh
				dispose={null}
				castShadow
				receiveShadow
				onClick={(e) => {
					e.stopPropagation(); /* para não continuar e pegar o objeto atras do selecionado */
					if (target) updateProdutoSelected(target);
					setTarget(e.object);
				}}
				name={name}
				side={THREE.DoubleSide}
				ref={ref}
				position={positionCalculada}
				mass={mass}
				tipo={tipo}
				disponivel={disponivel}
			>
				<boxGeometry args={argsReal} />
				{disponivel ? (
					<meshLambertMaterial
						color={inside && target?.name === name ? "#ff0000" : cor_caixa}
					/>
				) : (
					<meshLambertMaterial
						color={inside && target?.name === name ? "#ff0000" : cor_caixa}
						opacity={disponivel ? 1 : 0.5}
						transparent={true}
					/>
				)}
			</mesh>
		</Select>
	);
}

export function Palete(props) {
	const { nodes, materials } = useGLTF("/PALETE.gltf");
	const [hover, setHover] = useState(false);

	return (
		<Select enabled={hover}>
			<group /* {...props}  */ dispose={null}>
				<mesh
					castShadow
					// receiveShadow
					geometry={nodes.Plane001.geometry}
					material={materials.MADEIRA}
					onClick={(e) => {
						e.stopPropagation(); /* para não continuar e pegar o objeto atras do selecionado */
						props.setTarget(e.object);
					}}
					// onPointerMissed={() => {
					//     setTarget(null)
					// }}
				/>
			</group>
		</Select>
	);
}

function LightComponent({ position, args }) {
	const sLightRef = useRef();
	useHelper(sLightRef, THREE.PointLightHelper, 1, "red");
	return (
		<pointLight
			/* ref={sLightRef} */ position={position}
			args={args}
			castShadow
		/>
	);
}

function LightScene() {
	return (
		<Fragment>
			<LightComponent position={[50, 10, 0]} args={["#ffffff", 1]} />
			<LightComponent position={[-50, 10, 0]} args={["#ffffff", 1]} />
			<LightComponent position={[0, 50, 50]} args={["#ffffff", 1]} />
			<LightComponent position={[0, 50, -50]} args={["#ffffff", 1]} />
			<ambientLight args={["#ffffff", 0.1]} />
		</Fragment>
	);
}

function AxisHelper() {
	return <primitive object={new THREE.AxesHelper(5)} />;
}

function Cena({
	setTarget,
	target,
	boxes,
	setBoxes,
	setInformacoesCarga = () => {},
}) {
	/*  caminhao Direcionado pela empresa para aquele determinado caminhoneiro*/
	const caminhao = useSelector((state) => state.cargaSlicer.dimensoesCaminhao);
	const caminhaoDB = useSelector(
		(state) => state.userSlice.caminhaoSelecionado
	);
	const produtos = useSelector((state) => state.userSlice.produtosList.data);
	const embalagens = useSelector(
		(state) => state.userSlice.embalagensList.data
	);
	const romaneiosList = useSelector(
		(state) => state.userSlice.romaneiosList.data
	);
	const [argsDesenho, setArgsDesenho] = useState({
		c: 0,
		a: 0,
		l: 0,
	});

	const selectProduto = (id) => produtos.filter((item) => item.id === id)[0];
	const selectEmbalagem = (id) =>
		embalagens.filter((item) => item.id === id)[0];
	const selectRomaneio = (numero_pedido) =>
		romaneiosList.find((item) => item.numero_pedido === numero_pedido);
	const [last, setLast] = useState({
		name: "",
		position: {
			x: 0,
			y: 0,
			z: 0,
		},
		geometry: {
			parameters: {
				width: 0,
				depth: 0,
				height: 0,
			},
		},
	});

	// const [reset, setReset] = useState(false)
	const [actual, setActual] = useState({
		name: "",
		position: {
			x: 0,
			y: 0,
			z: 0,
		},
		geometry: {
			parameters: {
				width: 0,
				depth: 0,
				height: 0,
			},
		},
	});

	useEffect(() => {
		if (target) {
			if (target.name !== actual.name) {
				setLast(actual);
				setActual(target);
				return;
			}
			setActual(target);
			return;
		}
	}, [target, actual]);

	useEffect(() => {
		switch (caminhaoDB.caminhao_tipo) {
			case "Carreta":
				if (caminhaoDB.caminhao_qtd_eixos == 2) {
					setArgsDesenho({
						c: 7.33725,
						a: 2.55226,
						l: 2.35579,
					});
					return;
				}
				if (caminhaoDB.caminhao_qtd_eixos == 3) {
					setArgsDesenho({
						c: 9.87056,
						a: 2.55226,
						l: 2.35579,
					});
					return;
				}
			default:
				setArgsDesenho({ c: 6.00757, a: 2.4785, l: 2.3774 });
		}
	}, []);

	useEffect(() => {
		if (!target) {
			setInformacoesCarga((last) => ({
				...last,
				info_produto: {
					...last.info_produto,
					descricao: "",
					codigo: 0,
					tipo: "",

					altura: 0,
					comprimento: 0,
					largura: 0,
					peso: 0,
					volume: 0,
				},
			}));
			setActual({
				name: "",
				position: {
					x: 0,
					y: 0,
					z: 0,
				},
				geometry: {
					parameters: {
						width: 0,
						depth: 0,
						height: 0,
					},
				},
			});

			setLast({
				name: "",
				position: {
					x: 0,
					y: 0,
					z: 0,
				},
				geometry: {
					parameters: {
						width: 0,
						depth: 0,
						height: 0,
					},
				},
			});
			return;
		}

		const targetBox = boxes.filter((item) => item.name === target.name);
		let produto = {};
		let romaneio = {};
		if (target.tipo === "EMBALAGEM") {
			produto = selectEmbalagem(Number(targetBox[0]?.id));
			const produtoFind = produtos.find(
				(item) =>
					Number(item.numero_embalagem) == Number(produto.numero_embalagem)
			);
			romaneio = selectRomaneio(produtoFind.numero_pedido);
		} else {
			produto = selectProduto(Number(targetBox[0]?.id));
			if (!produto?.transferencia) {
				romaneio = selectRomaneio(produto?.numero_pedido);
			} else {
				romaneio = {
					romaneio: produto.transferencia,
				};
			}
		}

		setInformacoesCarga((last) => ({
			...last,
			info_produto: {
				...last.info_produto,
				descricao:
					produto?.descricao.length > 20
						? `${produto?.descricao.slice(0, 20)}... `
						: `${produto?.descricao}`,
				codigo: produto?.codigo || produto?.numero_embalagem,
				numero_pedido: romaneio?.numero_pedido || "NÃO ENCONTRADO",
				cliente:
					romaneio?.nome_razao?.length > 20
						? `${romaneio?.nome_razao.slice(0, 20)}...`
						: romaneio?.nome_razao,
				romaneio: String(romaneio?.romaneio).padStart(7, 0),
				cidade: romaneio?.cidade || "NÃO ENCONTRADO",
				tipo: target.tipo,

				qtde: numerosEditadoBR(produto?.qtde || produto?.qtde_produtos || 0),
				altura: numerosEditadoBR(produto?.altura),
				comprimento: numerosEditadoBR(produto?.comprimento),
				largura: numerosEditadoBR(produto?.largura),
				peso: numerosEditadoBR(produto?.peso),
				volume: numerosEditadoBR(produto?.volume),
				id_volume: produto?.id_volume,
			},
		}));

		setActual((last) => ({ ...last, position: target.position }));
	}, [target]);

	return (
		<Fragment>
			{/* eixo central de ajuda do sistema */}
			<AxisHelper />
			<LightScene />
			{/* <Environment files="/estacao_de_onibus/hamburg_hbf_4k.hdr" ground={{ height: 32, radius: 130 }} /> */}
			{/* <Stage preset="rembrandt" intensity={0.2} environment="city"> */}

			{target && (
				<TransformControls
					object={target}
					mode={"translate"}
					enabled={target.disponivel ? true : false}
				/>
			)}

			{/* </Stage> */}
			<Selection>
				<EffectComposer multisampling={80} autoClear={false}>
					{/* <Palete 
                    target={target}
                    setTarget={setTarget}
                /> */}
					<Outline
						blur
						visibleEdgeColor="white"
						edgeStrength={1000}
						width={5000}
					/>
					{boxes.map((item, index) => (
						<WoodBox
							key={index}
							position={item.position}
							args={item.args}
							name={item.name}
							target={target}
							setTarget={setTarget}
							argsDesenho={argsDesenho}
							argsRealCaminhao={{
								a: caminhaoDB.caminhao_altura,
								c: caminhaoDB.caminhao_comprimento,
								l: caminhaoDB.caminhao_largura,
							}}
							mass={item.mass}
							actual={actual}
							last={last}
							tipo={item.tipo}
							setInformacoesCarga={setInformacoesCarga}
							cor_caixa={item.cor_caixa}
							disponivel={item.disponivel}
						/>
					))}
				</EffectComposer>
			</Selection>
			{target ? (
				<Line
					lineStart={actual.position}
					lineEnd={last.position}
					lineStraight={distanceVector(actual.position, last.position)}
				/>
			) : (
				""
			)}

			{/* responsavel pela movimentação do ambiente */}

			<OrbitControls
				makeDefault
				rotateSpeed={0.15}
				maxDistance={50} /* distancia maxima do centro da eixo */
				minDistance={1} /* distancia minima do centro do eixo */
			/>
		</Fragment>
	);
}

export default function CarregarCaminhaoSeparador(props) {
	const boxes = useSelector((state) => state.cargaSlicer);
	const user = useSelector((state) => state.userSlice);
	const embalagensCarregadas = useSelector(
		(state) => state.userSlice.embalagensList.data
	);
	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);

	// const cena = memo(Cena)
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [target, setTarget] = useState(null);
	const [produtosList, setProdutosList] = useState(false);
	const [error, setError] = useState(false);
	const [errorP, setErrorP] = useState({
		flag: false,
		msg: "",
		error: "",
	});

	const [errorCargaAEntregar, setErrorCargaAEntregar] = useState({
		flag: false,
		msg: "",
		error: "",
	});

	const [loadingFinalizar, setLoadingFinalizar] = useState({
		flag: false,
		style: "dark",
		msg: "Finalizar Planejamento",
	});

	const [loadingRemover, setLoadingRemover] = useState({
		flag: false,
		style: "warning",
		msg: "Remover Produto",
	});

	const onRemove = async (e) => {
		e.preventDefault();
		if (!target) return;
		let indexPlanejadoRemoved = null;

		if (!target.disponivel) {
			setError({
				flag: true,
				title: "Aviso!",
				msg: {
					erro: "Tentativa de remoção produto da carga",
					motivo: "Produto inacessível.",
				},
			});
			return;
		}

		setLoadingRemover((last) => ({ ...last, flag: true, msg: "Removendo" }));
		indexPlanejadoRemoved = boxes.listaProdutos.filter(
			(item) => item.name === target.name
		)[0].step;
		const objAtt = {
			pos_x: null,
			pos_y: null,
			pos_z: null,
			user_planejamento: null,
			data_planejamento: null,
			planejado: 0,
			step_planejado: null,
			cor_caixa: "",
		};

		if (target.tipo === "EMBALAGEM") {
			const productId = boxes.listaProdutos.filter(
				(item) => item.name === target.name
			)[0].qr_sku;

			dispatch(
				updateEmbalagensListPlanejados({
					qr_sku: productId,
					planejado: false,
					step_planejado: null,
				})
			);
			let produtoAtt = {
				pos_x: null,
				pos_y: null,
				pos_z: null,
				step_planejado: null,
				user_planejamento: null,
				data_planejamento: null,
				planejado: false,
				cor_caixa: "",
				cargas_id: props.carga_id, // passado para atualizar embalagens
			};

			const embalagem = embalagensCarregadas.filter(
				(item) => item.qr_sku === productId
			)[0];
			/* update table of embalagens */
			await updateTable(
				"gestao/cargas/empresas/embalagens/atualizar",
				productId,
				produtoAtt
			)
				.then((resp) => {
					console.log({
						flag: false,
						msg: "Embalagem atualizada com Sucesso",
						style: "success",
					});
				})
				.catch((error) => {
					// let msg = null
					setError({
						flag: true,
						title: "Erro ao atualizar embalagens",
						msg: error,
					});
				});

			// for await(let element of user.produtosList.data){
			// user.produtosList.data.forEach(element => {
			const produtoAtt2 = {
				user_planejamento: produtoAtt.user_planejamento,
				data_planejamento: produtoAtt.data_planejamento,
				planejado: false,
				step_planejado: produtoAtt.step_planejado,
			};
			await updateTable(
				verificaTipoUsuario(user) + "carga/carregador/produto/embalados",
				Number(embalagem.numero_embalagem),
				produtoAtt2
			)
				.then((resp) => {
					console.log({
						flag: false,
						msg: "Produto embalado atualizado com Sucesso",
						style: "success",
					});
				})
				.catch((error) => {
					// let msg = null
					setError({
						flag: true,
						title: "Erro ao atualizar produtos",
						msg: error,
					});
				});
		} else {
			// verificaTipoUsuario(user) +
			const produto = boxes.listaProdutos.filter(
				(item) => item.name === target.name
			)[0];

			await updateTable("transferencias/produtos/atualizar", produto.id, objAtt)
				.then((resp) => {
					console.log({
						flag: false,
						msg: "Produto Atualizado com Sucesso",
						style: "success",
					});
				})
				.catch((error) => {
					// let msg = null
					setError({
						flag: true,
						title: "Erro ao atualizar produtos",
						msg: error,
					});
				});

			dispatch(
				updateProdutosListPlanejados({
					qr_sku: produto.qr_sku,
					planejado: false,
					step_planejado: null,
				})
			);
		}

		let newArray = [...boxes.listaProdutos];
		newArray = newArray
			.map((item) => {
				if (item.step > indexPlanejadoRemoved) {
					return { ...item, step: item.step - 1 };
				}
				return item;
			})
			.filter((item) => item.name !== target.name);

		dispatch(removeProduto(newArray));
		setTarget(null);

		setLoadingRemover((last) => ({
			...last,
			flag: false,
			msg: "Remover Produto",
		}));
	};

	const onAdd = (e) => {
		e.preventDefault();

		if (props.carga_selecionada.tipo_carga !== "TRANSFERÊNCIA") {
			if (
				produtosRomaneio.data.find((item) => !item.conferido) ||
				!produtosRomaneio.data.length
			) {
				setError({
					flag: true,
					title: "Aviso!",
					msg: {
						erro: "Tentativa de adicionar produto a carga",
						motivo: "Existem produtos não separados.",
					},
				});
				return;
			}
		}

		if (target) updateProdutoSelected(target);
		setProdutosList(true);
		setTarget(null);
	};

	const finalizar = async () => {
		const produtosList = user.produtosList.data.filter(
			(item) => item.transferencia
		);
		const produtosCheckeds = produtosList.filter((item) => item?.planejado);

		if (!produtosCheckeds.length) {
			setError((last) => ({
				...last,
				flag: true,
				msg: "Nenhum produto planejado. Por favor, inicie o processo de planejamento!",
				title: "Erro ao Finalizar Carregamento",
			}));
			return;
		}
		if (produtosCheckeds.length !== produtosList.length) {
			setErrorP((last) => ({
				...last,
				flag: true,
				msg: "Existem produtos não planejados. Deseja salvar planejamento parcial?",
				title: "Atenção!",
			}));
			return;
		}

		setLoadingFinalizar({
			flag: true,
			msg: "Finalizando",
			style: "warning",
		});
		// const placaCaminhao = user.caminhaoSelecionado.caminhao_placa

		const produtosCarregados = user.produtosList.data.filter(
			(item) => item.conferido && item.transferencia
		);
		const embalagensCarregadas = user.embalagensList.data.filter(
			(item) => item.planejado
		);

		for await (let produto of produtosCarregados.filter(
			(item) => item.planejado
		)) {
			const produtoCarregado = boxes.listaProdutos.filter(
				(item) => item.qr_sku == produto?.qr_sku
			);

			let produtoAtt = {};
			if (!produtoCarregado.length) {
				continue;
			} else {
				const [pos_z, pos_y, pos_x] = produtoCarregado[0]?.position;
				produtoAtt = {
					pos_x,
					pos_y,
					pos_z,
					step_planejado: produtoCarregado[0].step,
					user_planejamento: produto.user_planejamento,
					data_planejamento: produto.data_planejamento,
					planejado: produto.planejado,
					cor_caixa: produto.cor_caixa,
					cargas_id: props.carga_id,
				};
			}

			await updateTable(
				"transferencias/produtos/atualizar",
				produto.id,
				produtoAtt
			) //nivel de funcionario
				.then((resp) => {
					console.log({
						flag: false,
						msg: "Produto atualizado com Sucesso",
						style: "success",
					});
				})
				.catch((error) => {
					// let msg = null
					console.log({
						flag: false,
						msg: "Error!!!!",
						style: "warning",
					});
				});
		}
		for await (const embalagem of embalagensCarregadas) {
			const embalagemCarregada = boxes.listaProdutos.filter(
				(item) => item.qr_sku == embalagem?.qr_sku
			);

			let produtoAtt = {};
			if (!embalagemCarregada.length) {
				continue;
			} else {
				const [pos_z, pos_y, pos_x] = embalagemCarregada[0]?.position;
				produtoAtt = {
					pos_x,
					pos_y,
					pos_z,
					step_planejado: embalagemCarregada[0].step,
					user_planejamento: embalagem.user_planejamento,
					data_planejamento: embalagem.data_planejamento,
					planejado: embalagem.planejado,
					cargas_id: props.carga_id,
					cor_caixa: embalagem.cor_caixa,
				};
			}

			await updateTable(
				"transferencias/embalagens",
				embalagem.qr_sku,
				produtoAtt
			)
				.then((resp) => {
					console.log({
						flag: false,
						msg: "Embalagem atualizada com Sucesso",
						style: "success",
					});
				})
				.catch((error) => {
					// let msg = null
					console.log({
						flag: false,
						msg: "Error!!!!",
						style: "warning",
					});
				});

			const produtoAtt2 = {
				user_planejamento: produtoAtt.user_planejamento,
				data_planejamento: produtoAtt.data_planejamento,
				planejado: true,
				step_planejado: produtoAtt.step_planejado,
			};

			await updateTable(
				"transferencias/produtos/embalados/atualizar",
				Number(embalagem.numero_embalagem),
				produtoAtt2
			)
				.then((resp) => {
					console.log({
						flag: false,
						msg: "Produto atualizado com Sucesso",
						style: "success",
					});
				})
				.catch((error) => {
					// let msg = null
					console.log({
						flag: false,
						msg: "Error!!!!",
						style: "warning",
					});
				});
			// }
		}

		updateTable("transferencias/pedidos", "", {
			status: 5,
			transferencia: props.transferencia,
		})
			.then((resp) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "success",
					msg: "Produtos Planejados!",
				}));
				window.location.reload();
			})
			.catch((error) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "danger",
					msg: "Error!",
				}));
			});
	};

	const onClickFinalizarCarregamento = async (e) => {
		e.preventDefault();

		// colocando dois parametros para atualizar antes de finalizar
		if (target) {
			updateProdutoSelected(target);
			setTarget(null);
		}
		finalizar();
	};

	const updateProdutoSelected = (target) => {
		const position = [target.position.x, target.position.y, target.position.z];
		const argsReal = [
			target.geometry.parameters.width,
			target.geometry.parameters.height,
			target.geometry.parameters.depth,
		];
		const positionCalculada = [
			position[0] - argsReal[0] / 2,
			position[1] - argsReal[1] / 2,
			position[2] - argsReal[2] / 2,
		];

		const item = {
			itemName: target.name,
			newPosition: positionCalculada,
		};
		dispatch(updateProduto(item));
	};

	const verificarCargaCarregamento = async () => {
		const cargaDisponivelCarregamento = props.carga_id;
		const produtosGet = await getData(
			"gestao/planejamento/cargas/get-produtos",
			cargaDisponivelCarregamento,
			{}
		);

		if (!produtosGet.data.length) return;
		const produtos = produtosGet.data.filter((item) => item.conferido);
		dispatch(updateProdutosList(produtos));
		updateArrayProdutosCarregados(produtosGet.data);
	};

	const updateArrayProdutosCarregados = async (listaProdutos = []) => {
		// const caminhaoPlaca = user.caminhaoSelecionado?.caminhao_placa
		if (boxes.listaProdutos.length) {
			return;
		}
		// const getNextCarga = await getData('cargas/produtos/caminhao', caminhaoPlaca)
		// const cargaDisponivelCarregamento = getNextCarga.data.filter(item => item.status >= 4 && item.status <= 6 )[0]?.id //4, 5, 6
		const cargaDisponivelCarregamento = props.carga_id;

		const embalagensGet = await getData(
			"gestao/cargas/empresas/embalagens/carga_id",
			cargaDisponivelCarregamento,
			{}
		);
		const embalagensPlanejadas = embalagensGet.data.filter(
			(item) => item.planejado
		);
		const produtosPlanejados = listaProdutos.filter(
			(item) => item.planejado && !item.embalado
		);
		const produtosTransfeferencia = await getData(
			"transferencias/get-produtos",
			"",
			{ transferencia: props.transferencia }
		);
		const produtosTransfPlanejados = produtosTransfeferencia.data.filter(
			(item) => item.planejado && !item.data_cancelamento
		);
		const produtosTransfSeparados = produtosTransfeferencia.data.filter(
			(item) => item.conferido && !item.data_cancelamento
		);

		const produtosTransfeferenciaEmbalagens = await getData(
			"transferencias/embalagens",
			"",
			{ transferencia: props.transferencia }
		);
		const prodTransEmbPlanejados =
			produtosTransfeferenciaEmbalagens.data.filter((item) => item.planejado);
		const prodTransEmbSeparados = produtosTransfeferenciaEmbalagens.data.filter(
			(item) => item.conferido
		);

		for (const produtos in produtosPlanejados) {
			const item = produtosPlanejados[produtos];
			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					descarregado: item.descarregado,
					step: item.step_planejado,
					tipo: "PRODUTO",
					cor_caixa: item.cor_caixa,
					disponivel: false,
				})
			);
		}

		for (const embalagems in embalagensPlanejadas) {
			const item = embalagensPlanejadas[embalagems];
			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					descarregado: item.descarregado,
					step: item.step_planejado,
					tipo: "EMBALAGEM",
					cor_caixa: item.cor_caixa,
					disponivel: false,
				})
			);
		}

		for (let i = 0; i < produtosTransfPlanejados.length; i++) {
			const item = produtosTransfPlanejados[i];
			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					tipo: "TRANSFERENCIA",
					cor_caixa: item.cor_caixa,
					disponivel: true,
				})
			);
		}

		for (let i = 0; i < prodTransEmbPlanejados.length; i++) {
			const item = prodTransEmbPlanejados[i];
			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					tipo: "TRANSFERENCIA - EMBALAGEM",
					cor_caixa: item.cor_caixa,
					disponivel: true,
				})
			);
		}

		const carregados = [
			...listaProdutos.filter((item) => item.conferido),
			...produtosTransfSeparados,
		];
		dispatch(updateProdutosList(carregados));
		dispatch(updateEmbalagensList(prodTransEmbSeparados));
	};

	useEffect(() => {
		if (!props.carga_selecionada.tipo_carga === "TRANSFERÊNCIA") return;
		updateArrayProdutosCarregados([]);
	}, [user.caminhaoSelecionado]);

	useEffect(() => {
		if (!user.caminhaoSelecionado.status) return;
		verificarCargaCarregamento();
	}, [user.caminhaoSelecionado]);

	const [informacoesCarga, setInformacoesCarga] = useState({
		distancia_ao_caminhao: {
			tampa_traseira: 0,
			tampa_frontral: 0,
			acoalho: 0,
			teto: 0,
			tampa_lateral_direita: 0,
			tampa_lateral_esquerda: 0,
		},
		info_produto: {
			// romaneio
			romaneio: "",
			numero_pedido: "",
			cliente: "",
			cidade: "",
			descricao: 0,
			codigo: 0,
			tipo: "",

			qtde: 0,
			altura: 0,
			comprimento: 0,
			largura: 0,
			peso: 0,
			volume: 0,
			id_volume: 0,
		},
		distancia_ao_produto: {
			altura: 0,
			comprimento: 0,
			largura: 0,
		},
	});
	const caminhaoDB = user.caminhaoSelecionado;

	const updateArrayProdutos = async (carga_id = 1) => {
		if (
			produtosRomaneio.data.length > 0 &&
			(produtosRomaneio.data.filter((item) => !item.planejado).length ||
				produtosRomaneio.data.filter((item) => item.planejado).length ==
					produtosRomaneio.data.length)
		) {
			setLoading(false);
			return;
		}

		// rota destinda a funcionarios
		let produtosGet = await getData(
			"gestao/cargas/produtos/id",
			props.carga_id,
			{}
		);

		if (!produtosGet.data.length) {
			return;
		}

		const produtosPlanejados = produtosGet.data.filter(
			(item) => item.conferido
		);
		dispatch(updateProdutosList(produtosPlanejados));
	};
	/* protudos */
	useEffect(() => {
		updateArrayProdutos();
	}, []);

	const [styledButton, setStyledButton] = useState(false);

	// useEffect(() => {
	//     )
	// }, [boxes])
	return (
		<Fragment>
			{!user.caminhaoSelecionado.status ? (
				<div>
					{loading ? (
						<Loading msg={"Carregando"} color={"black"} />
					) : (
						<h2>Caminhão Não Disponibilizado pela Empresa!</h2>
					)}
				</div>
			) : (
				<div className="carregamento">
					<Canvas
						shadows
						style={{ background: "#0CABA8" }}
						orthographic
						camera={{ zoom: 50, position: [100, 100, 100] }}
						onPointerMissed={() => {
							if (!target) return;
							updateProdutoSelected(target);
							setTarget(null);
						}}
					>
						<Suspense fallback={<Loader />}>
							<Caminhao />
							<Cena
								target={target}
								setTarget={setTarget}
								boxes={boxes.listaProdutos}
								setBoxes={updateProdutoSelected}
								setInformacoesCarga={setInformacoesCarga}
							/>
						</Suspense>
					</Canvas>
					<div className="info-products">
						<pre>
							<div className="mb-2">
								<Button variant="primary" onClick={onAdd}>
									Adicionar Produto
								</Button>
							</div>
							<div className="mb-2">
								{/* <Button variant='warning' onClick={onRemove}>Remover Produto</Button> */}
								<Button variant={loadingRemover.style} onClick={onRemove}>
									{
										<Fragment>
											{loadingRemover.msg}{" "}
											{loadingRemover.flag ? (
												<Spinner animation="border" variant="black" size="sm" />
											) : (
												""
											)}
										</Fragment>
									}
								</Button>
							</div>
							<div className="mb-2">
								<Button
									variant={loadingFinalizar.style}
									onClick={onClickFinalizarCarregamento}
									disabled={target ? true : false}
								>
									{
										<Fragment>
											{loadingFinalizar.msg}{" "}
											{loadingFinalizar.flag ? (
												<Spinner animation="border" variant="black" size="sm" />
											) : (
												""
											)}
										</Fragment>
									}
								</Button>
							</div>
						</pre>
					</div>

					<div
						className={`info-products info-carga ${
							styledButton ? "hide-card" : ""
						}`}
					>
						<pre>
							<div className="d-flex justify-content-between align-items-center">
								<h4 className="m-0">Informações da Carga </h4>
								<i
									className={`i bi-arrow-up-circle-fill rotateButton ${
										styledButton ? "rotate-up" : ""
									}`}
									onClick={(e) => setStyledButton(!styledButton)}
								></i>
							</div>
							<h6 className="card-header">Produto Selecionado</h6>
							<ul>
								<li>
									Romaneio: <b>{informacoesCarga.info_produto.romaneio}</b>
								</li>
								<li>
									Pedido: <b>{informacoesCarga.info_produto.numero_pedido}</b>
								</li>
								<li>
									Cliente: <b>{informacoesCarga.info_produto.cliente}</b>
								</li>
								<li>
									Cidade: <b>{informacoesCarga.info_produto.cidade}</b>
								</li>
								<li>
									Descrição: <b>{informacoesCarga.info_produto.descricao}</b>
								</li>
								<li>
									Tipo: <b>{informacoesCarga.info_produto.tipo}</b>
								</li>
								<li>
									Código: <b>{informacoesCarga.info_produto.codigo}</b>
								</li>
								<li>
									Quantidade: <b>{informacoesCarga.info_produto.qtde}</b>
								</li>
								<li>
									Altura: <b>{informacoesCarga.info_produto.altura} [ m ]</b>
								</li>
								<li>
									Comprimento:{" "}
									<b>{informacoesCarga.info_produto.comprimento}</b> [ m ]
								</li>
								<li>
									Largura: <b>{informacoesCarga.info_produto.largura} [ m ]</b>
								</li>
								<li>
									Peso: <b>{informacoesCarga.info_produto.peso}</b> [ kg ]
								</li>
								<li>
									Volume:{" "}
									<b>
										{informacoesCarga.info_produto.volume} [ m<sup>3</sup> ]
									</b>
								</li>
								<li>
									Id Volume: <b>{informacoesCarga.info_produto?.id_volume}</b>
								</li>
							</ul>
							<h6 className="card-header">Distância do Produto ao Caminhão</h6>
							<ul>
								<li>
									TAMPA TRASEIRA:{" "}
									<b>{informacoesCarga.distancia_ao_caminhao.tampa_traseira}</b>{" "}
									[ m ]
									<InformacaoParametro
										title={
											"Distância entre a face TRASEIRA do ITEM e a FACE TRASEIRA interna do CAMINHÃO."
										}
									/>
								</li>
								<li>
									TAMPA FRONTAL:{" "}
									<b>{informacoesCarga.distancia_ao_caminhao.tampa_frontral}</b>{" "}
									[ m ]
									<InformacaoParametro
										title={
											"Distância entre a face FRONTAL do ITEM e a FACE FRONTAL interna do CAMINHÃO."
										}
									/>
								</li>
								<li>
									TETO: <b>{informacoesCarga.distancia_ao_caminhao.teto}</b> [ m
									]
									<InformacaoParametro
										title={
											"Distância entre a face SUPERIOR do ITEM e a FACE SUPERIOR interna do CAMINHÃO."
										}
									/>
								</li>
								<li>
									ASSOALHO:{" "}
									<b>{informacoesCarga.distancia_ao_caminhao.acoalho}</b> [ m ]
									<InformacaoParametro
										title={
											"Distância entre a face INFERIOR do ITEM e o ASSOALHO do CAMINHÃO."
										}
									/>
								</li>
								<li>
									TAMPA LATERAL ESQUERDA:{" "}
									<b>
										{
											informacoesCarga.distancia_ao_caminhao
												.tampa_lateral_esquerda
										}
									</b>{" "}
									[ m ]
									<InformacaoParametro
										title={
											"Distância entre a face LATERAL ESQUERDA do ITEM e a face LATERAL ESQUERDA do CAMINHÃO."
										}
									/>
								</li>
								<li>
									TAMPA LATERAL DIREITA:{" "}
									<b>
										{
											informacoesCarga.distancia_ao_caminhao
												.tampa_lateral_direita
										}
									</b>{" "}
									[ m ]
									<InformacaoParametro
										title={
											"Distância entre a face LATERAL DIREITA do ITEM e a face LATERAL DIREITA do CAMINHÃO."
										}
									/>
								</li>
							</ul>
							<h6 className="card-header">
								Distância do Produto ao Produto Anterior
							</h6>
							<ul>
								<li>
									ALTURA: <b>{informacoesCarga.distancia_ao_produto.altura}</b>{" "}
									[ m ]
									{/* <InformacaoParametro title={"Distância entre a face TRASEIRA do ITEM e a FACE TRASEIRA interna do CAMINHÃO."}/> */}
								</li>
								<li>
									COMPRIMENTO:{" "}
									<b>{informacoesCarga.distancia_ao_produto.comprimento}</b> [ m
									]
									{/* <InformacaoParametro title={"Distância entre a face FRONTAL do ITEM e a FACE FRONTAL interna do CAMINHÃO."}/> */}
								</li>
								<li>
									LARGURA:{" "}
									<b>{informacoesCarga.distancia_ao_produto.largura}</b> [ m ]
									{/* <InformacaoParametro title={"Distância entre a face SUPERIOR do ITEM e a FACE SUPERIOR interna do CAMINHÃO."}/> */}
								</li>
							</ul>

							<h6 className="card-header">Caminhão</h6>
							<ul>
								<li>
									PLACA: <b>{caminhaoDB.caminhao_placa}</b>
								</li>
								<li>
									CARGA: <b>{props.carga_id}</b>
								</li>
								<li>
									ROMANEIOS:{" "}
									<b>
										{
											user.produtosList.data.filter(
												(i, n, s) =>
													s.findIndex(
														(o) => o.numero_pedido === i.numero_pedido
													) === n
											).length
										}
									</b>
								</li>
								<li>
									PRODUTOS: <b>{user.produtosList.data.length}</b>
								</li>
								<li>
									EMBALAGENS: <b>{user.embalagensList.data.length}</b>
								</li>
								<li title="Produtos + Embalagens Carregadas no Caminhão">
									VOLUME CARREGADO:
									<b>
										{Number(
											user.produtosList.data
												.filter((item) => !item.embalado && item.planejado)
												.reduce((p, c) => p + c.volume, 0) +
												user.embalagensList.data
													.filter((item) => item.planejado)
													.reduce((p, c) => p + c.volume, 0)
										).toLocaleString("pr-BR")}{" "}
										[ m<sup>3</sup> ]
									</b>
								</li>
								<li title="Produtos + Embalagens Carregadas no Caminhão">
									PESO CARREGADO:
									<b>
										{Number(
											user.produtosList.data
												.filter((item) => !item.embalado && item.planejado)
												.reduce((p, c) => p + c.peso, 0) +
												user.embalagensList.data
													.filter((item) => item.planejado)
													.reduce((p, c) => p + c.peso, 0)
										).toLocaleString("pr-BR")}{" "}
										[ kg ]
									</b>
								</li>
							</ul>
						</pre>
					</div>
				</div>
			)}
			{produtosList ? (
				<ModalGetCargaCarregamentoSeparador
					show={produtosList}
					setShow={setProdutosList}
					setError={setError}
					placaCaminhao={props?.caminhao_placa}
					cargas_id={props.carga_id}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					msg={error.msg}
					title={error.title}
					show={error}
					setShow={setError}
				/>
			) : (
				""
			)}
			{errorP.flag ? (
				<ModalErrorCarregametoSeparador
					msg={errorP.msg}
					title={errorP.title}
					show={errorP.flag}
					setShow={setErrorP}
					transferencia={props.transferencia}
					cargas_id={props.carga_id}
				/>
			) : (
				""
			)}
			{errorCargaAEntregar.flag ? (
				<ModalError
					msg={errorCargaAEntregar.msg}
					title={errorCargaAEntregar.title}
					show={errorCargaAEntregar.flag}
					setShow={setErrorCargaAEntregar}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

/* memo function sereve para memorizar o ultimo estado do componente */
// export default memo(Carregamento)
