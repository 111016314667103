import { Modal } from "react-bootstrap"



export default function ModalFotoReport({ show, setShow, imgSrc, alternative, title }) {
    
    const handlerSimpleClose = () => setShow(last => ({ ...last, flag: false }))



    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Foto Enviada
                        <br/>
                        <i style={{ fontSize: 'inherit' }}>{title}</i>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{textAlign:"center"}}>
                        <img  src={imgSrc} alt={alternative} width={'80%'} height={'100%'}  />
                    </p>
                </Modal.Body>
            </Modal>
        </>
    )
}
