import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./Financeiro.scss";
import {
	update,
	updateCaminhoesCaminhoeiros,
	updateCargasList,
} from "../../redux/slicers/user";
import {
	Unauthorized,
	getAllData,
	getData,
	getStatusCarga,
	numerosEditadoBR,
	valorMoeda,
	verificaTipoUsuario,
} from "../../services/Servicos";
import ModalAtualizarFinanceiro from "../modal/ModalAtualizarFinanceiro";
import ModalAtualizarEnviarSMS from "../modal/ModalAtualizarEnviarSMS";
import ModalPDF from "../modal/ModalPDF";
import ModalAdicionarDespesa from "../modal/ModalAdicionarDespesa";
import ModalFechamentoFinanceiro from "../modal/ModalFechamentoFinanceiro";
import ModalLancarAbastecimentoChegada from "../modal/ModalLancarAbastecimentoChegada";
import ModalError from "../modal/ModalError";
import Loading from "../loading/Loading";

export default function Financeiro() {
	// const cargasR = useSelector(state => state.userSlice.cargasList.data)
	const [cargasR, setCargasR] = useState([]);
	const user = useSelector((state) => state.userSlice);
	const caminhoes = useSelector(
		(state) => state.userSlice.caminhoesCaminhoneirosList
	);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const [editarFinanceiro, setEditarFinanceiro] = useState({
		flag: false,
		pedagio: 0,
		valor_refeicao: 0,
		dias_na_estrada: 0,
		carga_id: 0,
	});

	const [editarLiberacao, setEditarLiberacao] = useState({
		flag: false,
		msg: "",
	});

	const [editarPdf, setEditarPdf] = useState({
		flag: false,
		msg: "",
	});

	const [botoesHidden, setBotoesHidden] = useState(true);

	const [lancarDespesas, setLancarDespesas] = useState({
		flag: false,
	});

	const [lancarAbastecimentos, setLancarAbastecimentos] = useState({
		flag: false,
	});

	const [fechamentoFinanceiro, setFechamentoFinanceiro] = useState({
		flag: false,
	});
	const [finalizarFinanceiro, setFinalizarFinanceiro] = useState({
		flag: false,
	});

	const [error, setError] = useState({
		flag: false,
		title: "",
		msg: "",
	});

	const [despesasAdicionais, setDespesasAdicionais] = useState({});
	const [fechamento, setFechamento] = useState({});

	const updateArrayCargas = async () => {
		let cargas = [];
		const cargas_new = await getAllData("dashboard/controle-financeiro");

		if (user.tipo !== "empresa") {
			if (user.id_filial == 2) {
				cargas = cargas_new.data;
			} else {
				cargas = cargas_new.data.filter(
					(item) => item.id_filial == user.id_filial
				);
			}
			// dispatch(updateCargasList(cargas.data))
		} else {
			cargas = cargas_new.data;
		}
		setCargasR(cargas);
		setLoading(false);
		// dispatch(updateCargasList(cargas.data.map(item => ({...item, nome_motorista: user.funcionariosList.data.find(item2 => item2.cpf == item.caminhoneiro)?.name ?? 'Sem Motorista'}))))

		// for await (let element of cargas.data.filter(item => item.status >= 5 && item.status <= 13)) {
		//     await getAbastecimentos(element.id, element.caminhao, element.id_abastecimento_partida, element.id_abastecimento_chegada)
		// }

		setBotoesHidden(false);
		setAtualizarDespesa({ flag: false });
	};

	const onClickEditar = (e, carga) => {
		setEditarFinanceiro((last) => ({
			...last,
			flag: true,
			pedagio: carga.valor_estimado_pedagio,
			valor_refeicao: carga.vale_refeicao,
			dias_na_estrada: carga.dias_na_estrada,
			cargas_id: carga.cargas_id,
			nome: carga.nome,
			setAtualizarDespesa,
		}));
	};

	const onClickLiberar = (e, carga) => {
		const caminhaoSelecionado = caminhoes.data.filter(
			(item) => item.caminhao_placa === carga.caminhao
		)[0];
		const valorAReceber = valorMoeda(carga.valor_cartao + carga.valor_dinheiro);
		const msg_sms = ` Pedido de Liberação de Valores de Viagem:
            * Valor: *${valorAReceber}*
            * Caminhoneiro: *${caminhaoSelecionado.nome_caminhoneiro}*
            * CPF: ${caminhaoSelecionado.cpf_caminhoneiro}
            * CNH: ${caminhaoSelecionado.cnh_caminhoneiro}
            * CAMINHÃO: 
                - PLACA: *${caminhaoSelecionado.caminhao_placa}*
                - TIPO: *${caminhaoSelecionado.caminhao_tipo}*


            * DADOS BANCÁRIOS: ??????
        `;
		if (!carga.data_saida) {
			setError({
				flag: true,
				list: "Data de Partida do caminhão não especificado. Atualize as iniformações.",
				title: "Aviso!",
			});
			return;
		}

		const dataValidade = new Date(carga.validade_cnh);
		const dataValidadeTacografo = new Date(carga.validade_tacografo);
		dataValidadeTacografo.setDate(dataValidadeTacografo.getDate() + 1); // para habilitar o dia do vencimento
		dataValidade.setDate(dataValidade.getDate() + 1); // para habilitar o dia do vencimento

		if (!carga.validade_tacografo) {
			setError({
				flag: true,
				list: "Tacografo do Caminhão Não Informado!!!",
				title: "Aviso!",
			});
			return;
		}
		if (dataValidadeTacografo.getTime() < new Date().getTime()) {
			setError({
				flag: true,
				list: "Tacografo do Caminhão Vencido!!!",
				title: "Aviso!",
			});
			return;
		}

		if (!carga.validade_cnh) {
			setError({
				flag: true,
				list: "Validade da CNH do Motorista não informada !!!",
				title: "Aviso!",
			});
			return;
		}
		if (dataValidade.getTime() < new Date().getTime()) {
			setError({
				flag: true,
				list: "CNH do motorista Vencida!!!",
				title: "Aviso!",
			});
			return;
		}

		if (!caminhaoSelecionado.cpf_caminhoneiro) {
			setError({
				flag: true,
				list: "Caminhoneiro não especificado. Atualize as iniformações.",
				title: "Aviso!",
			});
			return;
		}
		setEditarLiberacao((last) => ({
			...last,
			flag: true,
			msg_sms,
			cargas_id: carga.cargas_id,
			user: caminhaoSelecionado.cpf_caminhoneiro,
			msg: `Valor a receber: ${valorAReceber}`,
			empresas_id: caminhaoSelecionado.empresas_id,
			setAtualizarDespesa,
		}));
	};

	const onClickVerRelatorio = (e, carga) => {
		const data = new Date(carga.data_saida);

		setEditarPdf({
			flag: true,
			...carga,
			data_saida: data.toLocaleDateString(),
		});
	};

	const onClickAddDespesa = (e, carga) => {
		const caminhaoSelecionado = caminhoes.data.filter(
			(item) => item.caminhao_placa === carga.caminhao
		)[0];

		setLancarDespesas({
			flag: true,
			...carga,
			...caminhaoSelecionado,
			setAtualizarDespesa,
		});
	};

	const onClickFinalizar = async (e, carga) => {
		const caminhaoSelecionado = caminhoes.data.filter(
			(item) => item.caminhao_placa === carga.caminhao
		)[0];

		setFechamentoFinanceiro({
			flag: true,
			...carga,
			...caminhaoSelecionado,
			...despesasAdicionais[carga.id], // pegar apenas as despesas relacionadas a carga selecionada
		});

		// await updateTable('cargas/produtos/financeiro', carga.id, {status: 7}).then(console.log)
		// window.location.reload()
		return;
	};

	const getDespesas = async (carga) => {
		await getData(verificaTipoUsuario(user) + "despesas/carga", carga).then(
			(reponse) => {
				setDespesasAdicionais((last) => ({
					...last,
					[carga]: {
						...last[carga],
						qtde_despesas_extras: reponse.data.filter(
							(item) =>
								item.tipo_lancamento === "Adicional de Crédito" &&
								item.aprovacao_financeiro === "APROVADO"
						).length,
						valor_despesas_extras: reponse.data
							.filter(
								(item) =>
									item.tipo_lancamento === "Adicional de Crédito" &&
									item.aprovacao_financeiro === "APROVADO"
							)
							.reduce((p, c) => p + Number(c.valor), 0),
					},
				}));
				let despesas_fechamento = reponse.data.filter(
					(item) => item.tipo_lancamento === "Fechamento Financeiro"
				).length;
				let valor_despesas_fechamento = reponse.data
					.filter((item) => item.tipo_lancamento === "Fechamento Financeiro")
					.reduce((p, c) => p + Number(c.valor), 0);
				console.log({
					qtde_despesas: despesas_fechamento ? despesas_fechamento : 0,
					valor_despesas: valor_despesas_fechamento
						? valor_despesas_fechamento
						: 0,
				});
				setFechamento((last) => ({
					...last,
					[carga]: {
						...last[carga],
						qtde_despesas: despesas_fechamento ? despesas_fechamento : 0,
						valor_despesas: valor_despesas_fechamento
							? valor_despesas_fechamento
							: 0,
					},
				}));

				// }
			}
		);
		return 1;
	};

	const getAbastecimentos = async (
		carga,
		caminhao,
		id_abastecimento_partida,
		id_abastecimento_chegada
	) => {
		await getDespesas(carga);
		if (!caminhao) return;

		const response = await getData(
			verificaTipoUsuario(user) + "abastecimentos/caminhao",
			caminhao
		);
		//
		const lastAbastecimento = response.data.filter(
			(item) => item.id === id_abastecimento_partida
		)[0];
		const lastAbastecimentoChegada = response.data.filter(
			(item) => item.id === id_abastecimento_chegada
		)[0];
		const litros_abastecidos = lastAbastecimento?.litros_abastecidos ?? 0;
		const data_abastecimento =
			lastAbastecimento?.data_abastecimento ?? "Nenhuma";
		const ultimo_km = lastAbastecimento?.ultimo_km ?? 0;

		const litros_abastecidos_chegada =
			lastAbastecimentoChegada?.litros_abastecidos ?? 0;
		const data_abastecimento_chegada =
			lastAbastecimentoChegada?.data_abastecimento ?? 0;
		const ultimo_km_chegada = lastAbastecimentoChegada?.ultimo_km ?? 0;

		setDespesasAdicionais((last) => ({
			...last,
			[carga]: {
				...last[carga],
				litros_abastecidos,
				data_abastecimento,
				ultimo_km,
				litros_abastecidos_chegada,
				data_abastecimento_chegada,
				ultimo_km_chegada,
			},
		}));

		return 1;
	};

	const updateCaminhoesCaminhoenriosArray = async (e) => {
		await getAllData(
			verificaTipoUsuario(user) + "caminhoes/caminhoneiros",
			"",
			""
		)
			.then((resp) => {
				dispatch(updateCaminhoesCaminhoeiros(resp.data));
				updateArrayCargas();
			})
			.catch((error) => {
				if (error.msg === "Unauthorized") {
					Unauthorized(dispatch, update);
				}
			});
	};

	const setFinalizarCarga = (e, carga) => {
		setFinalizarFinanceiro({
			flag: true,
			fechamentoFinanceiro: true,
			cargas_id: carga.cargas_id,
			setAtualizarDespesa,
		});
	};

	useEffect(() => {
		updateCaminhoesCaminhoenriosArray();
	}, []);

	const [atualizarDespesa, setAtualizarDespesa] = useState({
		flag: false,
		carga: 0,
	});

	useEffect(() => {
		if (!atualizarDespesa.flag) return;
		updateArrayCargas();
	}, [atualizarDespesa.flag]);

	return (
		<Fragment>
			{loading ? <Loading msg={"Carregando... "} color={"black"} /> : ""}
			<div>
				<Row className="mx-auto">
					{cargasR.map((item, key) => {
						return (
							<Col sm="12" md="6" lg="6" xl="3" key={key}>
								<Card
									className={`card-financeiro active-animation-card-2 
                                    ${
																			!botoesHidden &&
																			item.aprovacao_gestor.toUpperCase() ===
																				"APROVADO"
																				? "active-animation-success"
																				: ""
																		}
                                    ${
																			!botoesHidden &&
																			item.aprovacao_gestor.toUpperCase() ===
																				"REPROVADO"
																				? "active-animation-danger"
																				: ""
																		}
                                    ${
																			!botoesHidden &&
																			item.aprovacao_gestor.toUpperCase() ===
																				"NÃO ENVIADO"
																				? "active-animation-warning"
																				: ""
																		}
                                    ${
																			!botoesHidden && item.status == 6
																				? "active-animation-dark"
																				: ""
																		}
                                    `}
									data-before={
										item.status == 6 ? "ENVIADO" : item.aprovacao_gestor
									}
								>
									<p className="carga-nome">
										<i style={{ fontSize: "inherit", fontWeight: "bold" }}>
											{item.nome}
										</i>{" "}
									</p>
									<h4 className="valor-carga">
										{valorMoeda(
											(Number(item?.valor_cartao) ?? 0) +
												(Number(item?.valor_dinheiro) ?? 0) +
												(despesasAdicionais[item.id]?.valor ?? 0)
										)}
									</h4>
									<p className="status-carga text-muted">
										{" "}
										{getStatusCarga(item.status)}
									</p>
									<div className="passos-realizados">
										<Icon variable={item.data_saida ?? false} />
										<span>
											Previsao de Saída{" "}
											<b>
												{item.data_saida
													? new Date(item.data_saida).toLocaleDateString(
															"pt-BR"
													  )
													: "N/A"}
											</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={true} />
										<span>
											Valor Pedágio <b>{valorMoeda(item.valor_pedagio ?? 0)}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={true} />
										<span>
											Valor Refeição{" "}
											<b>{valorMoeda(item.valor_refeicao ?? 0)}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={true} />
										<span>
											Cartão Corporativo <b>{item.cartao ?? ""}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={true} />
										<span>
											Valor no Cartão{" "}
											<b>{valorMoeda(item.valor_cartao ?? 0)}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={true} />
										<span>
											Valor no Dinheiro{" "}
											<b>{valorMoeda(item.valor_dinheiro ?? 0)}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={item.caminhao} />
										<span>
											Caminhão <b>{item.caminhao ? item.caminhao : "NÃO"}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={item.caminhoneiro} />
										<span>
											Caminhoneiro <b>{item.motorista || "N/A"}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon
											variable={
												item.ultimo_abastecimento !== "N/A" ? true : false
											}
										/>
										<span>
											Referência de Abastecimento:{" "}
											<b>{item.ultimo_abastecimento}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={item.ultimo_km_registrado} />
										<span>
											Ultimo Km Registrado{" "}
											<b>
												{item.ultimo_km_registrado
													? Number(
															Number(item.ultimo_km_registrado).toFixed(2)
													  ).toLocaleString("pt-BR")
													: "N/A"}
											</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={item.data_abastecimento} />
										<span>
											Data Abastecimento{" "}
											<b>
												{item.data_abastecimento
													? new Date(
															item.data_abastecimento
													  ).toLocaleDateString("pt-BR")
													: "N/A"}
											</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={item.litros_abastecidos} />
										<span>
											Abastecimento{" "}
											<b>{numerosEditadoBR(item.litros_abastecidos)} L</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={item.combustivel_adicional} />
										<span>
											Abastecimento Adicional{" "}
											<b>{numerosEditadoBR(item.combustivel_adicional)} L</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={item.credito_adicional} />
										<span>
											Despesas Adicionais
											<b>{valorMoeda(item.credito_adicional)}</b>
										</span>
									</div>
									<div className="passos-realizados">
										<Icon variable={item.fechamento_financeiro} />
										<span>
											Fechamento <b>{valorMoeda(item.fechamento_financeiro)}</b>
										</span>
									</div>

									<div className="passos-realizados">
										{item.status >= 9 ? (
											<>
												<Icon variable={true} />
												<span>
													Dinheiro <b>Recebido</b>!
												</span>
											</>
										) : (
											<>
												<Icon variable={false} />
												<span>
													Dinheiro <b>NÃO Recebido</b>!
												</span>
											</>
										)}
									</div>
									<div
										className="d-flex mt-3 justify-content-between"
										hidden={botoesHidden}
										style={{
											display: "flex",
											flexWrap: "wrap",
											justifyContent: "space-between",
										}}
									>
										<Button
											hidden={item.status == 5 ? false : true}
											className="btn-card-financeiro mb-2"
											onClick={(e) => onClickEditar(e, item)}
										>
											Atualizar
										</Button>
										{item.ultimo_abastecimento == "PARTIDA" ? (
											<Button
												hidden={
													item.caminhao && item.status == 5 ? false : true
												}
												variant="warning"
												className=" mb-2"
												onClick={(e) => onClickLiberar(e, item)}
											>
												Liberação
											</Button>
										) : (
											""
										)}
										<Button
											hidden={item.caminhao && item.status >= 5 ? false : true}
											variant="warning"
											className=" mb-2"
											onClick={(e) => onClickVerRelatorio(e, item)}
										>
											Relatório
										</Button>

										<Button
											hidden={
												item.status == 13 &&
												item.ultimo_abastecimento == "CHEGADA"
													? false
													: true
											}
											variant="danger"
											className="mb-2"
											onClick={(e) => onClickFinalizar(e, item)}
										>
											Lançar Notas
										</Button>

										<Button
											hidden={
												item.status >= 11 && item.status <= 12 ? false : true
											}
											className="btn-card-financeiro mb-2"
											style={{
												backgroundImage: "linear-gradient(#080808, #6c6e7f)",
											}}
											onClick={(e) => onClickAddDespesa(e, item)}
										>
											+Crédito
										</Button>
										{item.ultimo_abastecimento == "CHEGADA" ? (
											<Button
												hidden={item.status == 13 ? false : true}
												className="btn-card-financeiro mb-2"
												style={{
													backgroundImage:
														"linear-gradient(rgb(146 185 199), rgb(129 80 132))",
												}}
												onClick={(e) => setFinalizarCarga(e, item)}
											>
												Finalizar Carga
											</Button>
										) : (
											<Button
												hidden={item.status == 13 ? false : true}
												className="btn-card-financeiro mb-2"
												style={{
													backgroundImage: "linear-gradient(#080808, #6c6e7f)",
												}}
												onClick={(e) =>
													setLancarAbastecimentos({
														flag: true,
														...item,
														setAtualizarDespesa,
													})
												}
											>
												Abastecimento
											</Button>
										)}
									</div>
								</Card>
							</Col>
						);
					})}
				</Row>
				{editarFinanceiro.flag ? (
					<ModalAtualizarFinanceiro
						show={editarFinanceiro.flag}
						setShow={setEditarFinanceiro}
						{...editarFinanceiro}
					/>
				) : (
					""
				)}
				{editarLiberacao.flag ? (
					<ModalAtualizarEnviarSMS
						show={editarLiberacao.flag}
						setShow={setEditarLiberacao}
						{...editarLiberacao}
					/>
				) : (
					""
				)}
				{finalizarFinanceiro.flag ? (
					<ModalAtualizarEnviarSMS
						show={finalizarFinanceiro.flag}
						setShow={setFinalizarFinanceiro}
						{...finalizarFinanceiro}
						title={"Enviar Relatório Final para o Thiago"}
					/>
				) : (
					""
				)}
				{editarPdf.flag ? (
					<ModalPDF
						show={editarPdf.flag}
						setShow={setEditarPdf}
						{...editarPdf}
					/>
				) : (
					""
				)}
				{lancarDespesas.flag ? (
					<ModalAdicionarDespesa
						title={"Lançar Crédito Adicional"}
						show={lancarDespesas.flag}
						setShow={setLancarDespesas}
						{...lancarDespesas}
					/>
				) : (
					""
				)}
				{fechamentoFinanceiro.flag ? (
					<ModalFechamentoFinanceiro
						title={"Fechamento Fiscal da Carga N°"}
						show={fechamentoFinanceiro.flag}
						setShow={setFechamentoFinanceiro}
						{...fechamentoFinanceiro}
						setAtualizarDespesa={setAtualizarDespesa}
					/>
				) : (
					""
				)}
				{lancarAbastecimentos.flag ? (
					<ModalLancarAbastecimentoChegada
						show={lancarAbastecimentos.flag}
						setShow={setLancarAbastecimentos}
						{...lancarAbastecimentos}
					/>
				) : (
					""
				)}
			</div>
			{error.flag ? (
				<ModalError setShow={setError} show={error.flag} {...error} />
			) : (
				""
			)}
		</Fragment>
	);
}

function Icon({ variable = false }) {
	return (
		<i
			className={variable ? "bi bi-check-circle-fill" : "bi bi-x-circle-fill"}
			style={{ color: variable ? "#00ce00d6" : "#ff0000b8" }}
		></i>
	);
}
