import { Fragment, createRef, useCallback, useEffect, useRef, useState } from "react"
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { getAllData, getCaminhoes, getTableColumns, updateTable, valorMoeda } from "../../services/Servicos"
import ScrollbarLeft from "../scrollbars/ScrollbarLeft"
import HeadTable from "../table/head/HeadRow"
import { filtroDinamicoArrays, removerDadosDuplicados } from "../../services/helpers"
import ModalCriarFreteiro from "../modal/ModalCriarFreteiro"
import BodyRow from "../table/body/BodyRow"
import ModalLancamento from "./ModalLancamento"
import ModalCancelamento from "../frota/manutencao/ModalCancelamento"
import ModalEdicao from "../frota/manutencao/ModalEdicao"
import ModalError from "../modal/ModalError"


const tableRef = createRef()
const tbodyRef = createRef()


export default function ControleDeMensagens() {
    return(
        <Fragment>
            <Card className='card-user'>
                <ListarDados />
            </Card>
        </Fragment>
    ) 
}

function ListarDados() {
    const dispatch = useDispatch()

    const [dados, setDados] = useState([])
    const user = useSelector(state => state.userSlice)
    const [loading, setLoading] = useState(true)
    const [indexList, setIndexList] = useState(10)
    const [filteredTable, setFilteredTable] = useState(false)
    const [filters, setFilters] = useState([])
    const [lancarModal, setLancarModal] = useState({
        flag:false
    })
    const [editarAbastecimento, setEditarAbastecimento] = useState({
        flag:false
    })

    const [headList, setHead] = useState([])

    const getColumns = async () => {
        const header = await getTableColumns('DestinosDeNotificacoes')
        setHead([ 
            'index',
            'admin',
            ...Object
                .keys(header.data)
                .filter(key => key !== 'empresas_id')
                .filter(key => key !== 'created_at')
                .filter(key => key !== 'updated_at')
        ])

        

    }
    
    const getDados = async () => {
        getAllData('controle-de-mensagens')
            .then(response => {
                setDados(response.data)
            })
            .finally(() => {
                setLoading(false)
                setLancarModal(last => ({...last, flag: false}))
            })
    }
    
    useEffect(() => {
        getColumns()
        getDados()
    }, [])

    useEffect(() => {
        if(!filters.length){
            setIndexList(10)
            setFilteredTable(false)
            return
        }

        let testeFilters = {}

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            testeFilters[param] = []
        })

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            // testeFilters[param].push(ele[param])
            testeFilters[param].push(String(ele[param]).toLowerCase())
            
            if (String(ele[param]).toLowerCase() == 'false') {
                testeFilters[param].push('null')
                
            }
        })

        
        let arrayRetorno = [dados]

        Object.keys(testeFilters).forEach((key, index) => {
            arrayRetorno.push(filtroDinamicoArrays(testeFilters, key, arrayRetorno[arrayRetorno.length - 1], ))
        })

        const arrayF = removerDadosDuplicados(arrayRetorno[arrayRetorno.length - 1], 'id')
        setFilteredTable(arrayF.reverse())
        
    }, [filters])


    useEffect(() => {
        const refTable = tableRef.current
        const scrollHandler = (e) => {
            const tbodyHeight = tbodyRef.current.clientHeight
            const divTableHeight = e.target.clientHeight
            const scrollPosition = e.target.scrollTop
            if(scrollPosition >= tbodyHeight - divTableHeight){
                setTimeout(() => {
                    setIndexList(indexList + 10)
                },1000)
            }

        }

        refTable.addEventListener('scroll', scrollHandler, false)

        return () => refTable.removeEventListener('scroll', scrollHandler, false)
    })


    const onClickRemoveFilter = (e) => {
        e.preventDefault()
        const value = e.target.parentNode.firstElementChild.innerText
        const [key, keyValue] = value.split(':')
        setFilters(last => ([...last.filter(item => String(item[key]).replaceAll(' ','') !== keyValue.replaceAll(' ',''))]))

    }
    
    const [filtersNew, setFiltersNew] = useState({})


    return (
        <Fragment>
            <div className="section-filters-table mb-5">
                <div className="card-header"> 
                    <h3>Filtros Selecionados</h3>
                </div>
                <Row className='mt-2 row-filters'>
                    <Col>
                        <div>
                            {
                                filters.map((item, key) => 
                                    Object
                                        .keys(item)
                                        .map((key, index) => {
                                            return <span className="filters-card" key={index}>
                                                        <span  className='filters'>
                                                            <span className='title'>
                                                                {key}: {item[key]}
                                                            </span>
                                                            <i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
                                                        </span>
                                                </span>
                                        })
                                )
                            }

                        </div>
                    </Col>
                </Row>
            </div>
            <div className="section-filters-table">
                <div className="card-header pb-2"> 
                    <Row className='justify-content-between'>
                        <Col sm='12' md='6' lg='8'>
                            <h3 style={{display: 'flex',alignItems:'center'}}>Tabela de Dados {loading ? <Spinner style={{marginLeft:'10px', fontSize:'1rem'}} animation="border" size="sm" />:""}</h3>
                        </Col>
                        <Col sm='12' md='6' lg='2'>
                            <Button variant="primary" onClick={() => setLancarModal({flag: true})}>+Adicionar</Button>
                        </Col>
                    </Row>
                </div>
                <div className=" responsive-table" ref={tableRef}>
                    <ScrollbarLeft tableRef={tableRef} coluna2={3} maxima_distancia={180}/>
                        <Table hover size='sm'>
                            <thead style={{zIndex:49}}>
                                <HeadTable rota ='caminhoes' arrayValues={ dados } headerList={headList} setArrayFilters={setFiltersNew} arrayFilters={filtersNew} setFiltersOld={setFilters} filtersOld={filters} />
                            </thead>
                        <tbody ref={tbodyRef}>

                            {
                                filteredTable ? 
                                filteredTable.map((item, index, self) => {
                                    if(index < indexList){
                                        return <BodyRowLocal key={index} bodyList={item} headList={headList} index={index} tableLength={self.length}  setDataTable={setDados}/>
                                    }
                                    // return item
                                })
                                :
                                [...dados].reverse().map((item, index, self) => {
                                    if (index < indexList) {
                                        return <BodyRowLocal key={index} bodyList={item} headList={headList} index={index} tableLength={self.length} setDataTable={setDados}/>
                                    }
                                    // return item
                                })
                            }

                        </tbody>
                    </Table>
                </div>
            </div>  
            {lancarModal.flag ? <ModalLancamento show={lancarModal.flag} setShow={setLancarModal} title={'Lançar Novo Freteiro'} getData={ getDados } /> : ""}
        </Fragment>
    )
}



function BodyRowLocal({ headList, bodyList, index, setDataTable = () =>{}, tableLength = 0}) {
    const refRow = useRef(null)
    const userS = useSelector(state => state.userSlice)
    const filiaisList = userS.filiaisList.data

    const [controleCancelamento, setControleCancelamento] = useState({falg: false})
    const [controleEdicao, setControleEdicao] = useState({falg: false})
    const [error, setError] = useState({
        flag: false,
        title: '',
        msg: '',
    })


    const getData = useCallback(async () => {
        const url = 'controle-de-mensagens'
        try {
            const data = await getAllData(url)
            setDataTable(data.data)
            
        } catch (error) {
            setError({
                flag: true, msg: error, title:'Aviso!'
            })
        }
    })

    const onClickCancelItem = async () => {
        const url = 'controle-de-mensagens'
        await updateTable(url, bodyList.id, {data_cancelamento: new Date(), user_cancelamento: userS.name}) // cancelado
        getData(bodyList.manutencao_id)
    }

    const onClickEditar = async (bodyUpdate) => {
        const url = 'controle-de-mensagens'
        await updateTable(url, bodyList.id, { ...bodyUpdate, manutencao_id: bodyList.manutencao_id })
        getData(bodyList.manutencao_id)
    }

    const onClickTableRow = (e) => {
        e.preventDefault()
        refRow.current.classList = 'bg-selected'
        let refRowNumber = refRow.current.children[0].innerText

        let i = refRowNumber
        let actualSibling = refRow.current.nextSibling

        while(i < tableLength){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            
            actualSibling = actualSibling.nextSibling
            i++
        }

        i =  refRow.current.children[0].innerText
        actualSibling = refRow.current.previousSibling
        while(0 < i ){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            actualSibling = actualSibling.previousSibling
            i--
        }     
        // setCargaSelecionada({
        //     ...bodyList
        //     , disponivel: bodyList.status === 0 ? true : false
        // })
        
    }
    

    return (
        <Fragment>
            {
                <tr ref={refRow} style={{verticalAlign:'middle'}} onClick={onClickTableRow}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            }
                            else if (item === 'tipo') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span
                                            style={{
                                                fontSize: '0.8rem', padding: '7px 15px', borderRadius: '10px',
                                                backgroundColor: bodyList.tipo == 'CORRETIVA' ? "#ff0000bf" : '#b7d972'
                                            }}>{bodyList[item]}
                                        </span>
                                    </div>
                                </td>
                            }
                            else if (item === 'admin') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span className="excluir">
                                            <i className="bi bi-x-lg"
                                                title="Excluir"
                                                onClick={() => setControleCancelamento({ bodyList, flag: true, title: 'Cancelar Manutencao', fn: onClickCancelItem })}>
                                            </i>
                                        </span>
                                        {/* <span className="editar" hidden={bodyList.status == '2'}>
                                            <i className="bi bi-pencil"
                                                title="Editar"
                                                onClick={() => setControleEdicao({ bodyList, flag: true, title: 'Editar Manutencao', fn: onClickEditar })}>
                                            </i>
                                        </span> */}
                                    </div>
                                </td>
                            }
                            else if (item === 'id') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            }
                            else if (item === 'placa') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            }
                            else if (item === 'id_filial') { /* primary key can not be modified */

                                return <td key={_index}>{filiaisList.filter(item => item.idFilial == bodyList.id_filial)[0]?.identificacaoInterna ?? 'NÃO INFORMADO'}</td>

                            }
                            else if (item === 'status') {

                                return <td key={_index} className={item}>
                                {
                                    bodyList[item] == 2 ? 
                                        <div className="div-status">
                                            <span className="div-status-ativo bg-success">Finalizado</span>
                                        </div>
                                    :
                                        ''
                                }
                                {
                                    bodyList[item] == 1 ? 
                                        <div className="div-status">
                                            <span className="div-status-ativo bg-warning">Em Manutencao</span>
                                        </div>
                                    :
                                        ''
                                }
                                {
                                    bodyList[item] == 0 ? 
                                        <div className="div-status">
                                            <span className="div-status-ativo bg-info">Agendado</span>
                                        </div>
                                    :
                                        ''
                                }
                                </td>

                            }
                            else if (item === 'data_cancelamento') { /* primary key can not be modified */
                                return <td key={_index}>{bodyList[item] ? new Date(bodyList[item]).toLocaleString('pt-br') : 'N/A'}</td>

                            }
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            
            {controleCancelamento.flag ? <ModalCancelamento {...controleCancelamento} setShow={ setControleCancelamento } /> : ''}
            {controleEdicao.flag ? <ModalEdicao {...controleEdicao} setShow={setControleEdicao} /> : ''}
            {error.flag ? <ModalError show={error.flag} setShow={setError} msg={error.msg} title={error.title}/> : ""}
        </Fragment>
    )
}