import { Html, Text, useProgress } from "@react-three/drei"
import { useRef } from "react"
import * as THREE from 'three'

export function positionCalculadaProduto(positionProduto, argsCaminhao, type){
    return [positionProduto[0] + argsCaminhao[0] / 2, positionProduto[1] + argsCaminhao[1] / 2, positionProduto[2] + argsCaminhao[2] / 2]
}

export const fatorMedida = (caminhaoDesenho, caminhaoReal) =>{
    const comp_fator = caminhaoDesenho.c / caminhaoReal.c
    const alt_fator = caminhaoDesenho.a / (caminhaoReal.a > 1 ? caminhaoReal.a : caminhaoDesenho.a) 
    const larg_fator = caminhaoDesenho.l / caminhaoReal.l
    
    return {fator_largura: larg_fator, fator_altura: alt_fator,fator_comprimento: comp_fator}
}

export function Loader() {
    const { progress } = useProgress()
    return <Html center>{progress.toFixed(2)} % Carregado</Html>
}

export function distanceVector( v1, v2 )
{
    var dx = v1.x - v2.x;
    var dy = v1.y - v2.y;
    var dz = v1.z - v2.z;
    // const hipotenusa = Math.sqrt( dx * dx + dy * dy + dz * dz );

    return {x: dx, y: dy, z: dz}
}

export function Line({ lineEnd, lineStart, lineStraight }) {
    const ref = useRef();
    const points = []

    points.push(new THREE.Vector3(lineStart.x, lineStart.y, lineStart.z))
    points.push(new THREE.Vector3(lineEnd.x, lineEnd.y, lineEnd.z))
    points.push(new THREE.Vector3(lineStraight.x + lineEnd.x, lineEnd.y, lineEnd.z))
    points.push(new THREE.Vector3(lineEnd.x + lineStraight.x, lineStraight.y + lineEnd.y, lineEnd.z))
    points.push(new THREE.Vector3(lineEnd.x + lineStraight.x, lineStraight.y + lineEnd.y, lineStraight.z + lineEnd.z))

    const lineGeometry = new THREE.BufferGeometry().setFromPoints(points)

    return (
      <>
        <group>
          <line ref={ref} geometry={lineGeometry}>
            <lineBasicMaterial attach="material" color={'#ff0000'} linewidth={10} linecap={'round'} linejoin={'round'} />
          </line>
        </group>
      </>
    );
}

export function TextN({position, text}) {
    // const font = new FontLoader().parse(myFont);
    
    return (
        <Text
            scale={[1, 1, 1]}
            position={position}
            color="black" // default
            anchorX="top" // default
            anchorY="top" // default
        >
        {text}
      </Text>
    )
}