import { Fragment } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import './NavbarPlanoLogistica.scss'
import { useSelector } from "react-redux";

export default function NavbarPlanoLogistica() {
    const user = useSelector(state => state.userSlice)
    return (
        <Fragment>
            <Nav variant='tabs'>
                {
                    user.tipo === 'empresa' || user.tipo.toLowerCase() === 'gestor' ?
                        <Fragment>
                            <Nav.Item>
                                <NavLink className="nav-link" to='vendas'>Pedidos</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='pedidos/cargas'>Pedidos nas Cargas</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='rotas'>Rotas</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='cargas'>Cargas</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='embalagens'>Embalagens</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='romaneios'>Romaneios</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='entregues'>Cargas Entregues</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='manifestos'>Manifestos</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='pedidos_total'>Consultar Pedidos</NavLink>
                            </Nav.Item>
                                
                        </Fragment>
                        :
                        ""
                }
            </Nav>
            <Outlet />
        </Fragment>
    )
}






