import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	updateAbastecimentosList,
	updateCargasList,
	updateFreteirosList,
} from "../../redux/slicers/user";
import {
	cpf_mask,
	createFreteiro,
	getAllData,
	insertInto,
	numerosDecimal,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import { telefoneMask } from "../../services/helpers";
import ModalError from "../modal/ModalError";

export default function ModalLancamento(props) {
	const dispatch = useDispatch();
    const user = useSelector((state) => state.userSlice);
    const filiaisList = user.filiaisList.data

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({ responsavel: user.name });
	const [error, setError] = useState({
		flag: false,
	});

	const handlerSimpleClose = () => props.setShow((last) => ({ ...last, flag: false }));

	const submitDataEdit = () => {
		if (!body.id_filial) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento",
					motivo: "Não é possível finalizar sem a FILIAL do usuário.",
				},
			});
			return;
		}
		if (!body.cpf) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento",
					motivo: "Não é possível finalizar sem o CPF do usuário.",
				},
			});
			return;
		}
		if (!body.nome) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento",
					motivo: "Não é possível finalizar sem o NOME do usuário.",
				},
			});
			return;
		}
		if (!body.tipo_notificacao) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento",
					motivo: "Não é possível finalizar sem o TIPO DE NOTIFICAO informada.",
				},
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});
		let bodySend = {};

        Object.keys(body).forEach((e) => {
			bodySend[e] = body[e];
		});


		const url = 'controle-de-mensagens'

        insertInto(url, bodySend)
            .then(response => {
                setLoading({
                    isLoading: false,
                    msg: 'Enviado',
                    style:'success'
				})
                setTimeout(() => (
                    props.getData()
                ), 750)
				
            
				
            })
            .catch(error => {

                setLoading({
                    isLoading: false,
                    msg: 'Error',
                    style:'danger'
                })

                setError({
                    flag: true,
                    title:'Atenção',
                    msg: error
                })

                setTimeout(() => {
                    setLoading({
                        isLoading: false,
                        msg: 'Enviar',
                        style:'primary'
                    })
    
                },1000)
            })
	};

	const onChangeInput = (e) => {
		
		if (e.target.name === "cpf") {
			setBody((last) => ({
				...last,
				[e.target.name]: cpf_mask(e.target.value),
			}));
			return;
		}
		if (e.target.name === "telefone") {
			setBody((last) => ({
				...last,
				[e.target.name]: telefoneMask(e.target.value),
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value.toUpperCase(),
		}));
	};


	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							submitDataEdit();
						}}
					>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Nome:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="nome"
									onChange={onChangeInput}
									value={body.nome}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Email:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="email"
									name="email"
									onChange={onChangeInput}
                                    value={body.email}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>CPF:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="cpf"
									onChange={onChangeInput}
                                    value={body.cpf}
                                    maxLength={14}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Telefone:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="telefone"
									onChange={onChangeInput}
									value={body.telefone}
									maxLength={15}
								/>
							</Col>
						</Row>
						<Row  className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo de Notificação:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="tipo_notificacao"
									onChange={onChangeInput}
                                >
                                    <option></option>
                                    <option value='FINANCEIRO'>Financeiro</option>
                                    <option value='CONTABILIDADE'>Contabilidade</option>
                                </Form.Select>
							</Col>
						</Row>
						<Row  className="mb-2">
							<Col sm="4">
								<Form.Label>Filial:</Form.Label>
							</Col>
							<Col>
                                <Form.Select onChange={onChangeInput} name='id_filial'>
                                    <option></option>
                                    {filiaisList.map((item, index) => <option key={index} value={item.idFilial}>{ item.identificacaoInterna }</option>)}
                                </Form.Select>
							</Col>
						</Row>
						<button type="submit" hidden></button>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								type="submit"
								variant={loading.style}
								onClick={submitDataEdit}
								disabled={loading.style == "primary" ? false : true}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error ? (
					<ModalError
						show={error.flag}
						setShow={setError}
						title={error.title}
						msg={error.msg}
					/>
				) : (
					""
				)}
			</Modal>
		</>
	);
}

