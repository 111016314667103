import { QRCodeSVG } from "qrcode.react";
import { Fragment, useEffect, useRef, useState } from "react";
import ModalTableProdutos from "../../modal/ModalTableProdutos";

import "./BodyRow.scss";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	updateEmbalagensChecked,
	updateProdutosListChecked,
} from "../../../redux/slicers/user";
import ModalAtualizarDimensoes from "../../modal/ModalAtualizarDimensoes";
import ModalAtualizarDimensoesEmbalagens from "../../modal/ModalAtualizarDimensoesEmbalagens";
import ModalListarProdutosEmbalados from "../../modal/ModalListarProdutosEmbalados";
import {
	getAllData,
	getData,
	updateTableNew,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import ModalImprimirQRCode from "../../modal/ModalImprimirQRCode";
import { getColorOfFilial, getFilialName } from "../../../services/helpers";

const headListProdutos = [
	"index",
	"admin",
	"conferido",
	"planejado",
	"carregado",
	"descarregado",
	"numero_pedido",
	"codigo",
	"descricao",
	"qtde",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"abreviatura",
	"qr_sku",
	"numero_item_embalagem",
	// , 'numero_embalagem'
];
export default function BodyRowProdutoEmbalado({
	headList,
	bodyList,
	index,
	caminhao,
	setLoadingFinalizarParcial = () => {},
	setError = () => {},
	setSeparador = () => {},
	separador,
	tableLength,
}) {
	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const user = useSelector((state) => state.userSlice);
	const userEmail = user.email;
	const filiaisList = user.filiaisList.data;
	const refRow = useRef(null);
	const refRowCheck = useRef(null);
	const refEditar = useRef(null);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [loadingExcluir, setLoadingExcluir] = useState(false);

	const [editarDimensoes, setEditarDimensoes] = useState({
		flag: false,
		dimensao: "",
		produto: "",
		numero_embalagem: "",
		descricao: "",
		parametro: "",
		qr_sku: "",
		msg_button: "",
		currentValue: 0,
	});
	const [verProdutos, setVerProdutos] = useState({
		flag: false,
		produtosDaEmbalagem: [],
	});

	const setHandlerClicked = (e, bodyListReceived) => {
		const qr_sku = e.target.name.trim();
		const checked = e.target.checked;

		const produtos = produtosRomaneio.data.filter(
			(item) => item.numero_embalagem === bodyListReceived.numero_embalagem
		);
		produtos.forEach((element) => {
			dispatch(
				updateProdutosListChecked({
					qr_sku: element.qr_sku,
					conferido: checked,
					data: new Date().toISOString(),
					user: userEmail,
					setor: user.local_separacao,
				})
			);
		});
		dispatch(
			updateEmbalagensChecked({
				qr_sku: qr_sku,
				conferido: checked,
				data: new Date().toISOString(),
				user: userEmail,
				setor: user.local_separacao,
			})
		);
	};

	const onClickEditar = async (
		e,
		parametro,
		qr_sku,
		produto,
		currentValue,
		descricao,
		bodyList
	) => {
		if (separador.verificado && !separador.isSeparador) {
			setLoadingFinalizarParcial({
				flag: false,
				msg: "Error",
				style: "danger",
			});

			setTimeout(() => {
				setLoadingFinalizarParcial({
					flag: false,
					msg: "Finalizar Parcial",
					style: "primary",
				});
			}, 1500);

			setError({
				title: "Aviso",
				msg: "Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
				flag: true,
			});

			return;
		} else if (!separador.verificado) {
			setLoadingFinalizarParcial({
				flag: true,
				msg: "Verificando",
			});
			const teste = await getData(
				"cargas/embalagens/separador/verificar",
				bodyList.cargas_id
			);

			if (
				teste.data.exists &
				(user.email.toLowerCase() !== teste.data?.separador?.toLowerCase()) &
				(user.local_separacao.toLowerCase() ===
					teste.data?.setor?.toLowerCase())
			) {
				setLoadingFinalizarParcial({
					flag: false,
					msg: "Error",
					style: "danger",
				});

				setTimeout(() => {
					setLoadingFinalizarParcial({
						flag: false,
						msg: "Finalizar Parcial",
						style: "primary",
					});
				}, 1500);

				setError({
					title: "Aviso",
					msg: "Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
					flag: true,
				});

				setSeparador({ verificado: true, isSeparador: false });
				return;
			}
			setSeparador({ verificado: true, isSeparador: true });
		}

		// setLoadingFinalizarParcial({
		//     flag: true,
		//     msg: 'Verificando',
		// })

		// const teste = await getData('cargas/embalagens/separador/verificar', bodyList.cargas_id)
		// , teste.data?.separador?.toLowerCase())
		// if (teste.data.exists && user.email.toLowerCase() !== teste.data?.separador?.toLowerCase()) {
		//     setLoadingFinalizarParcial({
		//         flag: false,
		//         msg: 'Error',
		//         style:'danger'
		//     })

		//     setTimeout(() => {
		//         setLoadingFinalizarParcial({
		//             flag: false,
		//             msg: 'Finalizar Parcial',
		//             style:'primary'
		//         })
		//     }, 1500)

		//     setError({
		//         title:'Aviso',
		//         msg: 'Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.',
		//         flag: true
		//     })

		//     return
		// }
		setLoadingFinalizarParcial({
			flag: false,
			msg: "Finalizar Parcial",
		});

		let maxValue = caminhao[parametro];

		if (parametro === "peso") {
			maxValue = caminhao.capacidade;
		}

		setEditarDimensoes((last) => ({
			...last,
			flag: true,
			dimensao: parametro,
			produto,
			qr_sku,
			currentValue,
			maxValue,
			descricao,
			cargas_id: bodyList.cargas_id,
			numero_embalagem: bodyList.numero_embalagem,
		}));
	};

	const onClickProdutos = async (e, numero_embalagem) => {
		setLoading(true);
		const produtosGet = await getAllData(
			verificaTipoUsuario(user) + "cargas/produtos",
			{ numero_embalagem: numero_embalagem, cargas_id: bodyList.cargas_id }
		);
		setVerProdutos((last) => ({
			...last,
			flag: true,
			produtosDaEmbalagem: produtosGet.data,
			numero_embalagem,
		}));
		setLoading(false);
	};

	const [imprimir, setImprimir] = useState({
		flag: false,
		title: "",
		qr_sku: "",
		msg_obj: {},
	});

	const onClickPrint = (e) => {
		setImprimir({
			flag: true,
			msg_obj: {
				...bodyList,
				qtde: bodyList.qtde_produtos,
			},
			title: "Imprimir QRCode da Embalagem",
		});
	};

	const onClickExluir = (e) => {
		setLoadingExcluir(true);
		const body = {
			update: {
				data_cancelamento: new Date().toISOString(),
			},
			params: {
				numero_embalagem: bodyList.numero_embalagem,
				numero_pedido: bodyList.numero_pedido,
			},
		};
		updateTableNew(`embalagens/suporte`, body)
			.then((resp) => {
				setError({ msg: "Embalagem Exluida com Sucesso", flag: true });
				setTimeout(() => {
					window.location.reload();
				}, 750);
			})
			.catch((e) => {
				setError({ flag: true, msg: e });
			})
			.finally(() => {
				setLoadingExcluir(false);
			});
	};
	const onClickTableRow = (e) => {
		// e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = index;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = index;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
	};

	// useEffect(() => {
	//
	// }, [bodyList]);

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item.toLowerCase() === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										{user.email.toUpperCase() ===  process.env.REACT_APP_EMAIL_SUPORTE ? (
											<span className="excluir">
												{loadingExcluir ? (
													<Spinner size="sm" animation="border" />
												) : (
													<i
														className="bi bi-x-lg"
														title="Excluir Embalagem"
														onClick={onClickExluir}
														ref={refEditar}
													></i>
												)}
											</span>
										) : (
											""
										)}
										<span className="imprimir">
											<i
												className="bi bi-printer"
												title="Imprimir Romaneio"
												onClick={onClickPrint}
												ref={refEditar}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index}>
									<div style={{
										color: getColorOfFilial(bodyList.id_filial).color,
										backgroundColor: getColorOfFilial(bodyList.id_filial).bgColor,
										padding: '4px 6px',
										borderRadius: '4px',
										textAlign: 'center',
										boxShadow: '0 0 5px #0A061E80',
										border: '1px solid #E2E3DF80',
										width:'max-content'

									}}>
										{
											getFilialName(filiaisList, bodyList.id_filial)
										}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "comprimento") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.comprimento).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Comprimento"
													onClick={(e) => {
														onClickEditar(
															e,
															"comprimento",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.comprimento,
															bodyList.descricao,
															bodyList
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "altura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.altura).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Altura"
													onClick={(e) => {
														onClickEditar(
															e,
															"altura",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.altura,
															bodyList.descricao,
															bodyList
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "largura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.largura).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil bi-circle-animation"
													title="Editar Largura"
													onClick={(e) => {
														onClickEditar(
															e,
															"largura",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.largura,
															bodyList.descricao,
															bodyList
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "peso") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.peso).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil bi-circle-animation"
													title="Editar Peso"
													onClick={(e) => {
														onClickEditar(
															e,
															"peso",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.peso,
															bodyList.descricao,
															bodyList
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "volume") {
							return (
								<td key={_index}>
									{String(bodyList.volume).replace(".", ",")}
								</td>
							);
						} else if (
							["conferido", "planejado", "carregado", "descarregado"].includes(
								item
							)
						) {
							return (
								<td
									key={_index}
									className={item}
									style={{ minWidth: "50px", textAlign: "center" }}
								>
									<div className="div-status">
										<span
											className={`div-status-ativo ${
												bodyList[item] ? "bg-success" : "bg-warning"
											}`}
										>
											{bodyList[item] ? "SIM" : "NÃO"}
										</span>
									</div>
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td
									key={_index}
									className={item}
									style={{ whiteSpace: "nowrap" }}
								>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item.toLowerCase() === "qtde_produtos") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.qtde_produtos).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() === "separador" ||
										user.tipo.toLowerCase() === "empresa" ||
										user.tipo.toLowerCase() === "gestor" ? (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												{loading ? (
													<Spinner size="sm" animation="border" />
												) : (
													<i
														className="bi bi-plus-circle bi-circle-animation"
														title="Ver Produtos"
														onClick={(e) => {
															onClickProdutos(e, bodyList.numero_embalagem);
														}}
													></i>
												)}
											</span>
										) : (
											""
										)}
									</div>
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_code_id + "}"} />
								</td>
							);
						} else if (item === "carregado") {
							//
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList.carregado ? "SIM" : "NÃO"}
								</td>
							);
						} else if (item === "descarregado") {
							//
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList.descarregado ? "SIM" : "NÃO"}
								</td>
							);
						} else if (item === "data_carregamento") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "data_descarregamento") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "data_conferencia") {
							/* else if (item === 'qr_sku') {
                                return <td key={_index} className='rowDataWidth'><QRCodeSVG value={bodyList.qr_sku}/></td>
                                
                            }     */
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "numero_embalagem") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{String(bodyList.numero_embalagem).padStart(7, 0)}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "embalado") {
							return bodyList[item] ? (
								<td key={_index}> {bodyList.embalado ? "SIM" : "NÃO"}</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "DTEMISSAO") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						}
						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{verProdutos.flag ? (
				<ModalListarProdutosEmbalados
					setShow={setVerProdutos}
					show={verProdutos.flag}
					produtos={verProdutos.produtosDaEmbalagem}
					headList={headListProdutos}
					{...verProdutos}
				/>
			) : (
				""
			)}
			{editarDimensoes.flag ? (
				<ModalAtualizarDimensoesEmbalagens
					show={editarDimensoes.flag}
					setShow={setEditarDimensoes}
					caminhao={caminhao}
					{...editarDimensoes}
				/>
			) : (
				""
			)}
			{imprimir.flag ? (
				<ModalImprimirQRCode
					title={imprimir.title}
					show={imprimir.flag}
					setShow={setImprimir}
					msg_obj={imprimir.msg_obj}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
