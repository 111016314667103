import { Button, Card, Col,  Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import UserLayout from '../layout/UserLayout'

import semFoto from '../../../assets/perfil_sem_foto.jpg'
import ModalFoto from "../../modal/ModalFoto"
import { useEffect, useState } from "react"
import { locationDev, verifyConnection } from "../../../services/Servicos"
import ModalFotoReport from "./ModalFotoAtualizar"
import ModalError from "../../modal/ModalError"
import axios from "axios"
export default function UserProfile({img, bio, nome}){
    const user = useSelector(state => state.userSlice)
    const funcionarios = useSelector(state => state.userSlice.funcionariosList)
    const caminhoes = useSelector(state => state.userSlice.caminhoesList)
   
    const fotoCNH = useSelector(state => state.userSlice).foto_cnh
    const fotoPerfil = useSelector(state => state.userSlice).foto_perfil


    const [atualizarPerfil, setAtualizarPerfil] = useState({
        flag: false,
        id: ''
        
    })

    const [adminFunction, setAdminFunction] = useState({
        flag: false,
        imgSource: '',
        typeImg:'',
        id: user.id,
        empresa_id: user.empresas_id,
        email: user.email,
        title:'',
        fotoCnh: '',

    })
	const [error, setError] = useState({ flag: false, msg: "", title: "" });

    const showImageCard = (e, src = '', title = '') => {
        e.preventDefault()
        setAdminFunction(last => ({...last,
            flag: true,
            imgSource: src,
            title: title,
            id: user.id,
            empresa_id: user.empresas_id,
        }))
    }
    const verificarConex = async () => {
        try {
            const isConnected = await axios.get('https://bnautomacao.com.br', { timeout: '2000' })
            
        } catch (error) {
            setError({
                title: "ALERTA",
                flag: true,
                list:'Sem conexão com internet'
            });
        }
    }

    useEffect(() => {
       verificarConex()
    }, [])

    return(
        <UserLayout>
                <Row >
                    <Col sm={12} xl={6}>
                        <Row>
                            <Col sm={12}>
                                <Row className='my-2'>
                                    <Col sm={3}>
                                        <span>Nome:</span>
                                    </Col>
                                    <Col>
                                        <span>{user.name}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row className='my-2'>
                                    <Col sm={3}>
                                        <span>Email:</span>
                                    </Col>
                                    <Col>
                                        <span>{user.email}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row className='my-2'>
                                    <Col sm={3}>
                                        <span>Tipo:</span>
                                    </Col>
                                    <Col>
                                        <span>{user.tipo}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row className='my-2'>
                                    <Col sm={3}>
                                        <span>Filial:</span>
                                    </Col>
                                    <Col>
                                        <span>{user.filiaisList.data.find(item => item.idFilial == user.id_filial)?.identificacaoInterna}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row className='my-2'>
                                    <Col sm={3}>
                                        <span>Data de Cadastro:</span>
                                    </Col>
                                    <Col>
                                        <span>{new Date(user.createdAt).toLocaleString()}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} xl={6}>
                        <Row style={{textAlign: '-webkit-center'}}>
                            <Col sm={12} md={6}>
                                <Card style={{maxWidth:'18rem'}}>
                                    {
                                        fotoPerfil ? 
                                        <Card.Img  variant='top' src={locationDev + '/files/users/'+ fotoPerfil} alt='Imagem Perfil' />
                                        :
                                        <Card.Img  variant='top' src={semFoto} alt='Sem Imagem Perfil' />
                                    }
                                    <Card.Body>
                                        <Card.Title className="image-visualizar">Foto do Perfil</Card.Title>
                                        <Button variant='info' onClick={ e => showImageCard(e, locationDev + '/files/users/'+ fotoPerfil,'Foto do Perfil')} className="mt-2">Ver</Button>
                                        <Button variant='primary' onClick={ e => setAtualizarPerfil({flag: true, title:'Perfil', id: user.id, email: user.email})} className="mt-2">Atualizar</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} md ={6}>
                                <Card style={{maxWidth:'18rem'}}>
                                    {
                                        fotoCNH ? 
                                        <Card.Img  variant='top' src={locationDev + '/files/users'+ fotoCNH} alt='Imagem CNH' />
                                        :
                                        <Card.Img  variant='top' src={semFoto} alt='Sem Imagem Perfil' />
                                    }
                                    <Card.Body>
                                        <Card.Title>Foto da CNH</Card.Title>
                                        <Button variant='primary' onClick={ e => showImageCard(e, fotoCNH,'Foto da CNH')} className="mt-2">Editar</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>         
                {
                    user.tipo === 'empresa' ? 
                        <Row>
                            <Col sm={12}>
                                <Row className='my-4'>
                                <Row><h5>Funcionarios:</h5></Row>
                                    <Col sm={3}>
                                        <span>Cadastrados:</span>
                                    </Col>
                                    <Col sm={3}>
                                        <span>{funcionarios.data.length ? funcionarios.data.length : 0}</span>
                                    </Col>
                                    <Col sm={3}>
                                        <span>Última Atualização:</span>
                                    </Col>
                                    <Col sm={3}>
                                        <span>{funcionarios.lastUpdate}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row className='my-4'>
                                    <Row><h5>Caminhões:</h5></Row>
                                    <Col sm={3}>
                                        <span>Cadastrados:</span>
                                    </Col>
                                    <Col sm={3}>
                                        <span>{caminhoes.data.length}</span>
                                    </Col>
                                    <Col sm={3}>
                                        <span>Última Atualização:</span>
                                    </Col>
                                    <Col sm={3}>
                                        <span>{caminhoes.lastUpdate}</span>
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Row>              
                    :
                        ""
                }    
            {
                adminFunction.flag ? 
                    <ModalFoto 
                        imgSrc={adminFunction.imgSource}  
                        setShow={setAdminFunction} 
                        show={adminFunction.flag} 
                        dataUpdate={adminFunction}
                        />
                    : ""
            }
            {
                atualizarPerfil.flag ? 
                    <ModalFotoReport 
                        imgSource={atualizarPerfil.imgSource}  
                        setShow={setAtualizarPerfil} 
                        show={atualizarPerfil.flag} 
                        title={atualizarPerfil.title}
                        id={atualizarPerfil.id}
                        email={atualizarPerfil.email}
                        />
                    : ""
            }

            
			{error.flag ? (
				<ModalError setShow={setError} show={error.flag} {...error} />
			) : (
				""
			)}
        </UserLayout>
    )
}