import { useState } from "react"
import { Button, Modal } from "react-bootstrap"

import { ListarProdutos } from "../planoLogistica/romaneios/Romaneios"

import './Modal.scss'

export default function ModalListarProdutosEntregador({ title, show, setShow, pedido, cargas_id }) {

    const [headList, setHead] = useState([
        'index'
        ,'codigo'
        ,'embalado'
        ,'numero_embalagem'
        ,'numero_item_embalagem'
        ,'descricao'
        ,'pos_x'
        ,'pos_y'
        ,'pos_z'
        ,'descarregado'
        ,'data_descarregamento'
        ,'user_descarregamento'
        ,'step_planejado'
    ])

    const handlerSimpleClose = () => setShow(last => ({ error: { flag: false } }))
    

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <span>
                            {title}: 
                        </span>
                        <span>
                            <b>
                            {pedido} - {cargas_id}
                            </b>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListarProdutos headList={headList} height={500} numero_pedido={pedido} cargas_id={cargas_id} />
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border">
                    <Button variant='secondary' onClick={handlerSimpleClose}>Sair</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

