import { useEffect, useLayoutEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getTableColumns } from "../../services/Servicos"

import './Modal.scss'
import PedidosVstFromCargas from "../planoLogistica/apiVST/PedidosVstFromCargas"

export default function ModalAddPedidos({ title, show, setShow, pedido, cargas_id, cidades = [], id_filial }) {
    const [headList, setHead] = useState(['index'])

    const [pedidoWasChanged, setPedidoWasChanged] = useState(0)

    const updateArrayProdutos = async () => {
        const romaneiosGet =  await getTableColumns('CargasProdutos')
        setHead(last => ([...last, ...Object.keys(romaneiosGet.data)]))
    }

    useLayoutEffect(() => {
        updateArrayProdutos()
    },[])


    const handlerSimpleClose = () => {
        if (pedidoWasChanged > 1) {
            window.location.reload()
            return
        }
        setShow(last => ({ error: { flag: false } }))
    }
    

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <span>
                            {title}: 
                        </span>
                        <span>
                            <b>
                            {pedido}
                            {cargas_id}
                            </b>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PedidosVstFromCargas cargas_id={cargas_id} filters_init={[...cidades, { idFilial: "" + id_filial }]} setPedidosWasChanged={ setPedidoWasChanged } />
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border">
                    <Button variant='secondary' onClick={handlerSimpleClose}>Sair</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

