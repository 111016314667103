import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../loading/Loading";

import "../../modal/Modal.scss";
import ModalError from "../../modal/ModalError";
import {
	getAllData,
	getDateInitMonth,
	getTableColumns,
	insertInto,
} from "../../../services/Servicos";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ExportToExcel from "../../exportToExcel/ExportToExcel";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import BodyRowManutencoesItems from "../../table/body/BodyRowManutencoesItems";
import { updateManutencoesList } from "../../../redux/slicers/user";
import ModalErrorPretty from "../../modal/ModalErrorPretty";
export default function ModalLancamentoItens(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const id_filial = user.tipo.toLowerCase() == "empresa" ? 2 : user.id_filial;

	const formREf = useRef();
	const textArea = useRef(null);

	const [atualizar, setAtualizar] = useState(0);
	const [error, setError] = useState(false);
	const [showCardLancamento, setShowCardLancamento] = useState(true);
	const [showTitle, setShowTitle] = useState({ item: "", show: false });

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		responsavel: String(user.name).toUpperCase(),
		cpf_responsavel: user.email,
	});

	const atualizarQtde = async () => {
		const date = new Date();
		const dateCorrect = getDateInitMonth(date);
		const url = "manutencao";
		let data1 = await getAllData(url, { id_filial });
		if (user.tipo.toLowerCase().includes("gestor")) {
			data1 = data1.data.filter((item) => item.id_filial == user.id_filial);
		} else {
			data1 = data1.data;
		}
		dispatch(updateManutencoesList(data1));
		setTimeout(() => {
			setLoading({
				isLoading: false,
				msg: "Salvar",
				style: "primary",
			});
		}, 250);
	};

	const createItem = async () => {
		if (!body.descricao) {
			setError({
				show: true,
				title: "Erro",
				message:
					"Lançamento do Item de Manutenção. Descreva o Item da Manutenção.",
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});

		let bodySend = {
			data_abastecimento: new Date().toISOString(),
		};

		Object.keys(body).map((item) => {
			if (item.includes("data")) {
				bodySend[item] = body[item];
				return;
			}
			if (item.includes("valor")) {
				bodySend[item] = Number(
					body[item].toUpperCase().replace(".", "").replace(",", ".")
				);
				return;
			}
			if (item.includes("cpf_responsavel")) {
				bodySend[item] = body[item];
				return;
			}

			bodySend[item] = body[item]
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		bodySend.manutencao_id = props.bodyList.id;
		bodySend.emissao = new Date();

		const url = "manutencao/items";
		insertInto(url, bodySend)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				setBody({
					responsavel: String(user.name).toUpperCase(),
					observacoes: "",
				});
				setAtualizar((last) => last + 1);
				formREf.current.reset();

				atualizarQtde();
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setError({
					show: true,
					title: "Atenção",
					message: error.message,
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onClickButton = async () => {
		setLoading({ isLoading: true, style: "warning", msg: "Aguarde" });
		await createItem();
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const editValue = (e) => {
		let valor = String(e.target.value)
			.replaceAll(".", "")
			.replace(",", "")
			.replace(/\D/g, "");

		if (e.target.name == "aviso_data") {
			setBody((last) => ({
				...last,
				[e.target.name]: Number(Number(valor) * 1).toLocaleString("pt-br", {
					minimumFractionDigits: 0,
				}),
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: Number(Number(valor) * 0.01).toLocaleString("pt-br", {
				minimumFractionDigits: 2,
			}),
		}));
	};
	const handlerSimpleClose = () =>
		props.setShow((last) => ({ error: { flag: false } }));

	const [servicos, setServicos] = useState([]);
	const getServicos = async () => {
		const url = "manutencao/servicos";
		try {
			const data1 = await getAllData(url, { id_filial });
			setServicos(data1.data);
		} catch (e) {
			setError({
				show: true,
				title: "Atenção",
				message: e.message,
			});
		}
	};

	useEffect(() => {
		getServicos();
	}, []);

	return (
		<>
			<Modal
				show={props.flag}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size={!showCardLancamento ? "md" : "xl"}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.title}: {props.bodyList.id}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TabelaItens
						hidden={!showCardLancamento}
						id={props.bodyList.id}
						atualizar={atualizar}
					/>
					<Form hidden={showCardLancamento} ref={formREf}>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Descrição:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="descricao"
									onChange={onChangeInput}
									value={body?.descricao}
								>
									<option></option>
									{servicos.map((i, n, s) => (
										<option value={i.descricao} key={n} title={i.observacoes}>
											{i.descricao}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Data Previsão</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="date"
									name="data_previsao"
									onChange={onChangeInput}
									value={body?.data_previsao}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Km Previsão:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="km_previsao"
									onChange={editValue}
									value={body?.km_previsao}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Responsável:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={onChangeInput}
									value={body.responsavel}
									name="responsavel"
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Observações:</Form.Label>
							</Col>
							<Col>
								<textarea
									ref={textArea}
									onChange={onChangeInput}
									value={body?.observacoes}
									name="observacoes"
									style={{ width: "100%" }}
									rows={4}
								></textarea>
							</Col>
						</Row>
						<h5 className="card-header">Alertas</h5>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>
									Por Km{" "}
									<i
										className={`bi bi-info ${
											showTitle.item == "km" && showTitle.show
												? " describe"
												: ""
										}`}
										data-descricao="Quantidade de KM antes do KM de Previsão para emitir mensagens de alerta"
										onMouseOver={() => setShowTitle({ item: "km", show: true })}
										onMouseLeave={() =>
											setShowTitle({ item: "km", show: false })
										}
									></i>{" "}
									:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="aviso_km"
									onChange={editValue}
									value={body?.aviso_km}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>
									Por Data{" "}
									<i
										className={`bi bi-info ${
											showTitle.item == "data" && showTitle.show
												? " describe"
												: ""
										}`}
										data-descricao="Quantidade de dias antes da DATA PREVISÃO para emitir mensagens de alerta"
										onMouseOver={() =>
											setShowTitle({ item: "data", show: true })
										}
										onMouseLeave={() =>
											setShowTitle({ item: "data", show: false })
										}
									></i>{" "}
									:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="aviso_data"
									onChange={editValue}
									value={body?.aviso_data}
								/>
							</Col>
						</Row>
					</Form>
					{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant="info"
								onClick={() => {
									setShowCardLancamento((last) => !last);
								}}
							>
								{showCardLancamento ? (
									<span>Lancamentos</span>
								) : (
									<span>Tabela</span>
								)}
							</Button>
						</Col>
						<Col style={{ display: showCardLancamento ? "none" : "flex" }}>
							<Button variant={loading.style} onClick={onClickButton}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function TabelaItens(props) {
	const [error, setError] = useState({});
	const [headList, setHeadList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const userS = useSelector((state) => state.userSlice);
	const tableDiv = useRef();
	const tbodyRef = useRef();
	const getColumns = async () => {
		try {
			const columns = await getTableColumns("ManutencaoItems");
			setHeadList((last) => [
				"index",
				"admin",
				...Object.keys(columns.data)
					.filter((item) => item !== "empresas_id")
					.filter((item) => item !== "created_at")
					.filter((item) => item !== "updated_at")
					.filter((item) => item !== "cpf_responsavel")
					.filter((item) => item !== "user_cancelamento")
					.filter((item) => item !== "data_cancelamento")
					.filter((item) => item !== "manutencao_id")
					.filter((item) => item !== "fornecedor_cnpj")
					.filter((item) => item !== "valor"),
				"valor",
			]);
		} catch (error) {
			setError({
				flag: true,
				message: "Erro na obentção das Colunas",
				title: "Aviso!",
			});
		}
	};

	const getData = useCallback(async (manutencao_id) => {
		const url = "manutencao/items";
		try {
			const data = await getAllData(url, { manutencao_id });
			setDataTable(
				data.data.map((item) => ({
					...item,
					emissao: new Date(item.emissao).toLocaleDateString("pt-br", {
						timeZone: "UTC",
					}),
					data_previsao: item.data_previsao
						? new Date(item.data_previsao).toLocaleDateString("pt-br", {
								timeZone: "UTC",
						  })
						: "",
					data_finalizacao: item.data_finalizacao
						? new Date(item.data_finalizacao).toLocaleDateString("pt-br", {
								timeZone: "UTC",
						  })
						: "",
					data_garantia: item.data_garantia
						? new Date(item.data_garantia).toLocaleDateString("pt-br", {
								timeZone: "UTC",
						  })
						: "",
				}))
			);
		} catch (error) {
			setError({
				flag: true,
				message: error.message,
				title: "Aviso!",
			});
		}

		setLoading(false);
	});

	useEffect(() => {
		getData(props.id);
	}, [props.atualizar]);

	// dados de entrada
	useEffect(() => {
		getColumns();
	}, []);

	// scrollBar
	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [dataTable];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"placa"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<div hidden={props.hidden}>
			<Row className="mt-2 row-filters">
				<Col>
					<div>
						{filters.map((item, key) =>
							Object.keys(item).map((key, index) => {
								return (
									<span className="filters-card" key={index}>
										<span className="filters">
											<span className="title">
												{key}: {item[key]}
											</span>
											<i
												className="bi bi-x-lg"
												onClick={onClickRemoveFilter}
											></i>
										</span>
									</span>
								);
							})
						)}
					</div>
				</Col>
			</Row>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Dados{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</Col>

						<Col sm="12" md="6" lg="3">
							<ExportToExcel
								tableName="Manutencoes_Items"
								reference={
									filteredTable.length ? filteredTable : [...dataTable]
								}
							/>
						</Col>
					</Row>
				</div>

				<div
					className="responsive-table"
					ref={tableDiv}
					style={{ position: "relative", height: "370px", maxHeight: "unset" }}
				>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={dataTable}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowManutencoesItems
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
								  })
								: dataTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowManutencoesItems
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setDataTable={setDataTable}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < dataTable.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</div>
	);
}
