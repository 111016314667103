import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
	createEmbalagem,
	getAllData,
	getData,
	getTableColumns,
	insertPedidos,
	insertProdutos,
	updateTableNew,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./ModalError.scss";
import ModalError from "./ModalError";
import ModalListarProdutosEmbalados from "./ModalListarProdutosEmbalados";

export default function ModalTableProdutosTransferencia({
	show,
	setShow,
	ID_EMPRESA,
	ID_FILIAL,
	ID_PEDIDO,
	pedido,
	carga_id = 1,
	setPedidos = () => {},
	transferencia,
}) {
	// /* to editing data */
	const [produtos, setProdutos] = useState([]);
	const [produtosVolumes, setProdutosVolumes] = useState([]);
	const [embalagemsVolumes, setEmbalagemsVolumes] = useState([]);
	const [headListProdutos, setHeadListProdutos] = useState(["INDEX"]);
	const [headListVolumes, setHeadListVolumes] = useState(["INDEX"]);
	const [headListVolumesEmbalagens, setHeadListVolumesEmbalagens] = useState([
		"INDEX",
	]);
	const [error, setError] = useState(false);
	const user = useSelector((state) => state.userSlice);
	const [loading, setLoading] = useState(true);
	const [cargaModal, setCargaModal] = useState({
		flag: false,
		pedido,
		produtos: [],
	});

	const handlerSimpleClose = () =>
		setShow((last) => ({ error: { flag: false } }));

	const updateArrayProdutosTransferencia = async () => {
		setHeadListVolumes([
			"id",
			"codigo",
			"descricao",
			"altura",
			"largura",
			"comprimento",
			"volume",
			"qtde",
			"peso",
		]);
		setHeadListVolumesEmbalagens([
			"id",
			"numero_embalagem",
			"qtde_produtos",
			"descricao",
			"altura",
			"largura",
			"comprimento",
			"volume",
			"peso",
		]);

		if (user.tipo === "empresa") {
			const [produtosGet, volumesGet] = await Promise.all([
				getData("transferencias/get-produtos", "", {
					transferencia,
					data_cancelamento: "null",
				}),
				getData("transferencias/embalagens", "", { transferencia }),
			]);

			// const produtosGet = await getData('transferencias/get-produtos', '', { transferencia })
			setProdutosVolumes(
				produtosGet.data.filter((item) => !item.data_cancelamento)
			);
			setEmbalagemsVolumes(
				volumesGet.data.filter((item) => !item.data_cancelamento)
			);
			setLoading(false);
			return;
		}

		const [produtosGet, volumesGet] = await Promise.all[
			(getData("transferencias/funcionario/get-produtos", "", {
				transferencia,
			}),
			getData("transferencias/funcionarios/embalagens", "", { transferencia }))
		];

		setProdutosVolumes(
			produtosGet.data.filter(
				(item) => !item.data_cancelamento && !item.embalado
			)
		);
		setLoading(false);
	};

	useLayoutEffect(() => {
		updateArrayProdutosTransferencia();
	}, []);

	useLayoutEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_VST}/${ID_EMPRESA}/id_filial/${ID_FILIAL}/id_saida/${ID_PEDIDO}`
			)
			.then((resp) => {
				setProdutos(resp.data.Produtos);
				setHeadListProdutos((last) => [...Object.keys(resp.data.Produtos[0])]);

				// if(!resp.data.ProdutosVolumes) return
			})
			.catch((error) => {
				setError({
					list: error.message,
					flag: true,
					title: "Atenção - Problemas na api do VST",
				});
			});
	}, []);

	const returnValue = (unidade, referencia) => {
		if (unidade == "KG" && referencia == "PESO") {
			return true;
		}
		if (unidade == "MTS" && referencia == "COMPRIMENTO") {
			return true;
		}
		if (
			(unidade == "UN" ||
				unidade == "BAR" ||
				unidade == "PC" ||
				unidade == "RL" ||
				unidade == "FD" ||
				unidade == "LT") &&
			referencia == "QUANTIDADE"
		) {
			return true;
		}
		return false;
	};

	const handlerAddProd = async (e) => {
		setBtnAtribuir((last) => ({
			...last,
			flag: true,
			msg: "Padronizando Produtos",
			style: "warning",
		}));

		const updateStringTrans =
			user.tipo !== "empresa"
				? "gestao/cargas/funcionarios/embalagens"
				: "cargas/empresas/embalagens";
		const embalagemsCadastradas = await getAllData(updateStringTrans);
		let produtosSelecionados = [];
		let embalagemsSelecionadas = [];

		// calculo do metro quadrado
		let metros_quadrado = 0;
		for (let index = 0; index < produtos.length; index++) {
			const produto = produtos[index];
			if (
				produto.descricaoProduto.includes("TELHA") &&
				produto.descricaoProduto.includes("# ISOT")
			) {
				if (produto.undMedida == "PC") {
					const [_, comprimentoPeca] = produto.descricaoProduto.split("MM X ");
					metros_quadrado +=
						(produto.quantidade * Number(comprimentoPeca)) / 1000;
					continue;
				}

				metros_quadrado += produto.quantidade;
			}
		}

		for (let k = 0; k < embalagemsVolumes.length; k++) {
			const volume = embalagemsVolumes[k];
			let obj = {
				numero_embalagem_antigo: volume.numero_embalagem,
				numero_embalagem: embalagemsCadastradas.data.length + k + 1,
				descricao: volume.descricao, // + numero_embalagem correto
				numero_pedido: ID_PEDIDO,
				abreviatura: volume.abreviatura,
				qr_sku: `E${ID_EMPRESA}F${ID_FILIAL}P${ID_PEDIDO}${transferencia}E${k}`,
				cargas_id: pedido.cargas_id,
				id_filial: ID_FILIAL,
				altura: volume.altura,
				comprimento: volume.comprimento,
				largura: volume.largura,
				qtde_produtos: volume.qtde_produtos,
				peso: volume.peso,
				volume: volume.volume,

				//padrao
				conferido: false,
				data_conferencia: volume.data_conferencia,
				user_conferencia: volume.user_conferencia,
				planejado: volume.planejado,
				data_planejamento: volume.data_planejamento,
				user_planejamento: volume.user_planejamento,
				cor_caixa: volume.cor_caixa,
				pos_x: volume.pos_x,
				pos_y: volume.pos_y,
				pos_z: volume.pos_z,
			};
			embalagemsSelecionadas.push(obj);
		}

		for (let k = 0; k < produtosVolumes.length; k++) {
			const volume = produtosVolumes[k];

			let obj = {
				codigo: volume.codigo,
				descricao: volume.descricao,
				numero_pedido: ID_PEDIDO,
				abreviatura: volume.unidade,
				qr_sku: `E${ID_EMPRESA}F${ID_FILIAL}P${ID_PEDIDO}${transferencia}V${k}`,
				cargas_id: pedido.cargas_id,
				id_filial: ID_FILIAL,
				altura: volume.altura,
				comprimento: volume.comprimento,
				largura: volume.largura,
				qtde: volume.qtde,
				peso: volume.peso,
				volume: volume.volume,

				//padrao
				conferido: false,
				data_conferencia: volume.data_conferencia,
				user_conferencia: volume.user_conferencia,
				planejado: volume.planejado,
				data_planejamento: volume.data_planejamento,
				user_planejamento: volume.user_planejamento,
				cor_caixa: volume.cor_caixa,
				pos_x: volume.pos_x,
				pos_y: volume.pos_y,
				pos_z: volume.pos_z,
			};
			if (volume.embalado) {
				obj.numero_embalagem = embalagemsSelecionadas.find(
					(item) =>
						Number(item.numero_embalagem_antigo) ===
						Number(volume.numero_embalagem)
				).numero_embalagem;
				obj.embalado = true;
				obj.numero_item_embalagem = true;
			}
			produtosSelecionados.push(obj);
		}

		setCargaModal((last) => ({
			...last,
			pedido: { ...last.pedido, metros_quadrado },
			produtos: produtosSelecionados,
			embalagems: embalagemsSelecionadas,
			flag: true,
		}));

		return;
	};

	const [btnAtribuir, setBtnAtribuir] = useState({
		flag: false,
		msg: "Atribuir",
		style: "primary",
	});

	const handlerAttProdutos = async (e) => {
		const updateStringTrans =
			user.tipo !== "empresa"
				? "transferencias/funcionarios/pedidos"
				: "transferencias/pedidos";
		const updateStringPTransf = "transferencias/funcionario/produtos/atualizar";
		if (cargaModal.embalagems) {
			setBtnAtribuir((last) => ({
				...last,
				flag: true,
				msg: "Atribuindo Embalagens",
				style: "info",
			}));
			const promiseAll = Promise.all([
				...cargaModal.embalagems.map((item) =>
					createEmbalagem(item, user.tipo)
				),
			]);
		}
		setBtnAtribuir((last) => ({
			...last,
			flag: true,
			msg: "Atribuindo Pedido",
			style: "info",
		}));

		insertPedidos(cargaModal.pedido, user.tipo)
			.then((resp) => {
				setBtnAtribuir((last) => ({ ...last, msg: "Atribuindo Produtos" }));
				const promiseAll = Promise.all([
					...cargaModal.produtos.map((item) => insertProdutos(item, user.tipo)),
					updateTableNew(updateStringTrans, {
						cargas_id: pedido.cargas_id,
						transferencia,
						numero_pedido_real: ID_PEDIDO,
						status: 6,
					}),
					updateTableNew(updateStringPTransf, {
						transferencia,
						finalizado: true,
					}),
				]);
				promiseAll
					.then((resp) => {
						setBtnAtribuir((last) => ({
							...last,
							flag: false,
							msg: "Concluido",
							style: "success",
						}));
						setTimeout(() => {
							setPedidos((last) => [
								...last.map((item) => {
									if (
										item.idFilial == cargaModal.pedido.id_filial &&
										item.codigoPedido == cargaModal.pedido.numero_pedido
									) {
										item.stLogistica = 0;
									}
									return item;
								}),
							]);

							window.location.reload();
						}, 750);
					})
					.catch((error) => {
						setBtnAtribuir((last) => ({
							...last,
							flag: false,
							msg: "Erro!!!",
							style: "danger",
						}));

						setError({
							list: error.message,
							flag: true,
							title: "Atenção",
						});
					});
			})
			.catch((error) => {
				setBtnAtribuir((last) => ({
					...last,
					flag: false,
					msg: "Erro!!!",
					style: "danger",
				}));

				setError({
					list: error.message,
					flag: true,
					title: "Atenção",
				});
			});
	};

	useEffect(() => {
		if (!cargaModal.produtos.length) return;
		handlerAttProdutos();
	}, [cargaModal]);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Pedido: {ID_PEDIDO}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="card-header">
						<h4>Produtos</h4>
					</div>
					<div
						className=" responsive-table"
						style={{ height: "auto", marginBottom: "2rem" }}
					>
						<Table
							hover
							size="sm"
							style={{ position: "relative", height: "100px" }}
						>
							<thead>
								<tr>
									{headListProdutos.map((item, _index) => {
										if (_index > 19) {
											return;
										}

										return (
											<th
												key={_index}
												className={item}
												style={{ minWidth: "100px" }}
											>
												{" "}
												{item}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody>
								{produtos.map((item, index) => {
									return (
										<tr key={index}>
											{headListProdutos.map((_item, _index) => {
												if (_item.toLowerCase() === "index") {
													return <td key={_index}> {index + 1}</td>;
												}
												return (
													<td
														key={_index}
														className={item}
														style={{ minWidth: "100px" }}
													>
														{" "}
														{item[_item]}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<Volumes
						headListVolumes={headListVolumes}
						produtosVolumes={produtosVolumes.filter((item) => !item.embalado)}
						loading={loading}
					/>
					<Embalagens
						headListVolumes={headListVolumesEmbalagens}
						produtosVolumes={embalagemsVolumes}
						produtos={produtosVolumes.filter((item) => item.embalado)}
						loading={loading}
					/>
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button
						variant={btnAtribuir.style}
						onClick={handlerAddProd}
						disabled={btnAtribuir.flag ? true : false}
					>
						{btnAtribuir.flag ? (
							<Loading msg={btnAtribuir.msg} color={"black"} />
						) : (
							btnAtribuir.msg
						)}
					</Button>
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Fechar
					</Button>
				</Modal.Footer>
				{error.flag ? (
					<ModalError
						msg={error.msg}
						title={error.title}
						show={error.flag}
						setShow={setError}
						{...error}
					/>
				) : (
					""
				)}
			</Modal>
		</>
	);
}

function Volumes({ headListVolumes, produtosVolumes, loading }) {
	return (
		<>
			<div className="card-header">
				<h4>
					Volumes da Transferência Planejada{" "}
					{loading ? <Spinner size="sm" animation="border" /> : ""}
				</h4>
			</div>
			<div
				className=" responsive-table"
				style={{ height: "auto", marginBottom: "2rem" }}
			>
				{!produtosVolumes.length ? (
					<p>
						Volumes dos produtos{" "}
						<i>
							<b>não encontrados!</b>
						</i>
					</p>
				) : (
					<Table
						hover
						size="sm"
						style={{ position: "relative", height: "100px" }}
					>
						<thead>
							<tr>
								{headListVolumes.map((item, _index) => {
									if (_index > 19) {
										return;
									}

									return (
										<th
											key={_index}
											className={item}
											style={{ minWidth: "100px" }}
										>
											{" "}
											{item}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{produtosVolumes.map((item, index) => {
								return (
									<tr key={index}>
										{headListVolumes.map((_item, _index) => {
											if (_item.toLowerCase() === "index") {
												return <td key={_index}> {index + 1}</td>;
											} else if (
												/altura|largura|comprimento|volume|peso/.test(_item)
											) {
												return (
													<td key={_index} className={item}>
														{" "}
														{Number(item[_item]).toLocaleString()}
													</td>
												);
											}
											return (
												<td
													key={_index}
													className={item}
													style={{ minWidth: "100px" }}
												>
													{" "}
													{item[_item]}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</Table>
				)}
			</div>
		</>
	);
}
function Embalagens({ headListVolumes, produtosVolumes, loading, produtos }) {
	const onClickProdutos = (e, numero_embalagem) => {
		const produtosDaEmbalagem = produtos.filter(
			(item) => item.numero_embalagem === numero_embalagem
		);
		//
		setVerProdutos((last) => ({
			...last,
			flag: true,
			produtosDaEmbalagem,
			numero_embalagem,
		}));
	};

	const [verProdutos, setVerProdutos] = useState({
		flag: false,
		produtosDaEmbalagem: [],
	});

	return (
		<>
			<div className="card-header">
				<h4>
					Embalagem da Transferência Planejada{" "}
					{loading ? <Spinner size="sm" animation="border" /> : ""}
				</h4>
			</div>
			<div
				className=" responsive-table"
				style={{ height: "auto", marginBottom: "2rem" }}
			>
				{!produtosVolumes.length ? (
					<p>
						Volumes dos produtos{" "}
						<i>
							<b>não encontrados!</b>
						</i>
					</p>
				) : (
					<Table
						hover
						size="sm"
						style={{ position: "relative", height: "100px" }}
					>
						<thead>
							<tr>
								{headListVolumes.map((item, _index) => {
									if (_index > 19) {
										return;
									}

									return (
										<th
											key={_index}
											className={item}
											style={{ minWidth: "100px" }}
										>
											{" "}
											{item}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{produtosVolumes.map((item, index) => {
								return (
									<tr key={index}>
										{headListVolumes.map((_item, _index) => {
											if (_item.toLowerCase() === "index") {
												return <td key={_index}> {index + 1}</td>;
											} else if (_item.toLowerCase() === "qtde_produtos") {
												return (
													<td key={_index}>
														<div className="d-flex justify-content-between">
															<span
																className="verificar"
																style={{
																	fontSize: "inherit",
																	color: "inherit",
																}}
															>
																{String(item.qtde_produtos).replace(".", ",")}
															</span>
															<span
																className="verificar"
																style={{
																	fontSize: "inherit",
																	color: "inherit",
																	cursor: "pointer",
																}}
															>
																<i
																	className="bi bi-plus-circle bi-circle-animation"
																	title="Ver Produtos"
																	onClick={(e) => {
																		onClickProdutos(e, item.numero_embalagem);
																	}}
																></i>
															</span>
														</div>
													</td>
												);
											} else if (
												/altura|largura|comprimento|volume|peso/.test(_item)
											) {
												return (
													<td key={_index} className={item}>
														{" "}
														{Number(item[_item]).toLocaleString()}
													</td>
												);
											}
											return (
												<td
													key={_index}
													className={item}
													style={{ minWidth: "100px" }}
												>
													{" "}
													{item[_item]}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</Table>
				)}
			</div>
			{verProdutos.flag ? (
				<ModalListarProdutosEmbalados
					setShow={setVerProdutos}
					show={verProdutos.flag}
					produtos={verProdutos.produtosDaEmbalagem}
					headList={[
						"id",
						"codigo",
						"descricao",
						"altura",
						"largura",
						"comprimento",
						"volume",
						"qtde",
						"peso",
					]}
					{...verProdutos}
				/>
			) : (
				""
			)}
		</>
	);
}
