import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	createTransferencia,
	getData,
	getTableColumns,
	getTransferencias,
	insertProdutosTransferencia,
	updateTable,
} from "../../services/Servicos";

import "./Modal.scss";
import {
	filtroDinamicoArrays,
	getErrorMessage,
	removerDadosDuplicados,
} from "../../services/helpers";
import { updateProdutosList } from "../../redux/slicers/user";
import ScrollbarLeft from "../scrollbars/ScrollbarLeft";
import HeadTable from "../table/head/HeadRow";
import BodyRowProduto from "../table/body/BodyRowProdutos";
import Loading from "../loading/Loading";
import BodyRowProdutosTransferencias from "../table/body/BodyRowProdutosTransferencias";
import ExportToExcel from "../exportToExcel/ExportToExcel";
import ScrollbarDown from "../scrollbars/ScrollbarDown";
import ModalError from "./ModalError";
import HeadRowNew from "../table/head/HeadRowNew";
import ModalErrorPretty from "./ModalErrorPretty";

const tableRefP = createRef();
const tbodyRefP = createRef();

export default function ModalListarProdutosTransferencias({
	title,
	show,
	setShow,
	pedido,
	cargas_id,
}) {
	const [headList, setHead] = useState(["index", "admin"]);
	const [transferirProdutos, setTransferirProdutos] = useState(false);

	const updateArrayProdutos = async () => {
		const romaneiosGet = await getTableColumns("CargasProdutosTransferencias");
		setHead((last) => [...last, ...Object.keys(romaneiosGet.data)]);
	};
	

	useLayoutEffect(() => {
		updateArrayProdutos();
	}, []);

	const handlerSimpleClose = () =>
		setShow((last) => ({ error: { flag: false } }));

	const [updateList, setUpdateList] = useState(0)
	const handleUpdateList = () => {
		console.log(updateList)
		setUpdateList(last => last + 1)
	}
	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>{title}:</span>
						<span>
							<b>
								{pedido.transferencia} - {cargas_id}
							</b>
						</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListarProdutos
						headList={headList}
						height={500}
						transferencia={pedido.transferencia}
						cargas_id={cargas_id}
						updateList={ updateList }
					/>
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button variant="warning" onClick={() => setTransferirProdutos(true)}>
						Transferir Produtos
					</Button>
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Sair
					</Button>
				</Modal.Footer>
			</Modal>
			{transferirProdutos ? (
				<ModalListarTransferirProdutos
					show={transferirProdutos}
					setShow={setTransferirProdutos}
					headList={[
						"index",
						"id",
						"codigo",
						"descricao",
						"qtde_planejada",
						"peso",
					]}
					pedido={pedido}
					updateList={ handleUpdateList }
				/>
			) : (
				""
			)}
		</>
	);
}

export function ListarProdutos({
	headList,
	height = 400,
	setNomeromaneioSelecionada = () => {},
	transferencia,
	cargas_id,
	updateList
}) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);

	const produtosRomaneio1 = useSelector(
		(state) => state.userSlice.produtosList.data
	);
	const [produtosRomaneio, setProdutosRomaneio] = useState([
		...produtosRomaneio1.filter((item) => !item.data_cancelamento),
		...produtosRomaneio1.filter((item) => item.data_cancelamento),
	]);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	// const [produtosRomaneio, setProdutosRomaneio] = useState([])

	useEffect(() => {
		const refTable = tableRefP.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRefP.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [produtosRomaneio];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const updateArrayProdutos = async (transferencia) => {
		if (user.tipo === "empresa") {
			const produtosGet = await getData("transferencias/get-produtos", "", {
				transferencia,
			});
			setLoading(true);
			setTimeout(() => {
				// setProdutosRomaneio(produtosGet.data)
				// setLoading(false)
				setProdutosRomaneio([
					...produtosGet.data.filter((item) => !item.data_cancelamento),
					...produtosGet.data.filter((item) => item.data_cancelamento),
				]);
				dispatch(updateProdutosList(produtosGet.data));
				setLoading(false);
			}, 500);
			return;
		}

		const produtosGet = await getData(
			"transferencias/funcionario/get-produtos",
			"",
			{ transferencia }
		);
		setLoading(true);
		setTimeout(() => {
			setProdutosRomaneio([
				...produtosGet.data.filter((item) => !item.data_cancelamento),
				...produtosGet.data.filter((item) => item.data_cancelamento),
			]);
			dispatch(updateProdutosList(produtosGet.data));
			setLoading(false);
		}, 500);
	};

	useLayoutEffect(() => {
		setLoading(true)
		updateArrayProdutos(transferencia);
	}, [updateList]);

	const [filtersNew, setFiltersNew] = useState({});
	return (
		<>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<h3>Filtros Selecionados</h3>
				<hr />

				<Col>
					<div>
						{filters.map((item, key) =>
							Object.keys(item).map((key, index) => {
								return (
									<span className="filters-card" key={index}>
										<span className="filters">
											<span className="title">
												{key}: {item[key]}
											</span>
											<i
												className="bi bi-x-lg"
												onClick={onClickRemoveFilter}
											></i>
										</span>
									</span>
								);
							})
						)}
					</div>
				</Col>
			</div>
			<div className="section-filters-table">
				<Row className="justify-content-between">
					<Col sm="12" md="6" lg="8">
						<h3 style={{ display: "flex", alignItems: "center" }}>
							Tabela de Produtos
							{loading ? (
								<Spinner
									style={{ marginLeft: "10px", fontSize: "1rem" }}
									animation="border"
									size="sm"
								/>
							) : (
								""
							)}
						</h3>
					</Col>
					<Col sm="12" md="6" lg="2">
						<ExportToExcel
							tableName={`Produtos da Transferencia: ${transferencia}`}
							reference={produtosRomaneio
								.filter((item) => !item.data_cancelamento)
								.map((item) => ({
									id: item.id,
									codigo: item.codigo,
									descricao: item.descricao,
									quantidade: item.qtde,
									peso: item.peso,
								}))}
						/>
					</Col>
				</Row>

				<hr />

				<ScrollbarLeft
					tableRef={tableRefP}
					coluna2={4}
					maxima_distancia={250}
				/>
				<div
					className=" responsive-table"
					ref={tableRefP}
					style={{ position: "relative", height }}
				>
					{loading ? (
						""
					) : (
						<Table hover size="sm">
							<thead>
								<HeadRowNew
									arrayValues={produtosRomaneio}
									headerList={headList}
									setArrayFilters={setFiltersNew}
									arrayFilters={filtersNew}
									setFiltersOld={setFilters}
									filtersOld={filters}
									arrayPedidos={
										filteredTable.length ? filteredTable : produtosRomaneio
									}
									setPedidos={
										filteredTable.length
											? setFilteredTable
											: setProdutosRomaneio
									}
								/>
							</thead>
							<tbody ref={tbodyRefP}>
								{filteredTable
									? filteredTable.map((item, index) => {
											if (index < indexList) {
												return (
													<BodyRowProdutosTransferencias
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={filteredTable.length}
													/>
												);
											}
									  })
									: produtosRomaneio.map((item, index) => {
											if (index < indexList) {
												return (
													<BodyRowProdutosTransferencias
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={produtosRomaneio.length}
														setromaneioSelecionada={setNomeromaneioSelecionada}
														setArrayFilters={setFiltersNew}
													/>
												);
											}
									  })}
							</tbody>
						</Table>
					)}
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < produtosRomaneio.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
}

function ModalListarTransferirProdutos({ show, setShow, headList, pedido, updateList }) {
	const dispatch = useDispatch();
	const [error, setError] = useState({
		flag: false,
	});
	const userS = useSelector((state) => state.userSlice);
	const [listaProdutos, setListaProdutos] = useState([
		...useSelector((state) =>
			state.userSlice.produtosList.data.filter(
				(item) => !item.conferido && !item.transferido
			)
		),
	]);
	const [listaProdutosNova, setListaProdutosNova] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingSalvar, setLoadingSalvar] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar",
	});
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filtersNew, setFiltersNew] = useState({});
	const [rowSelected, setRowSelected] = useState({});
	const [rowSelected2, setRowSelected2] = useState({});
	const [filters, setFilters] = useState([]);
	const tableRef1 = useRef();
	const tbodyRef1 = useRef();
	const tableRef2 = useRef();
	const tbodyRef2 = useRef();

	const [transferencia, setTransferencia] = useState({
		...pedido,
		descricao: "Transferencia Parcial da Transferencia " + pedido.transferencia,
		responsavel: userS.name,
		emissao: new Date().toLocaleDateString(),
		transferencia: "",
		id: null,
		status: 0,
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [listaProdutos];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const handlerSimpleClose = () => {
		updateList()
		setShow(false)
	};

	const onClickSelectAll = () => {
		if (!listaProdutos.length) return setError({ show: true, message: 'Nenhum Produto para adicionar' }); 

		setListaProdutosNova((last) => [...listaProdutos]);
		setListaProdutos((last) => []);
		setRowSelected({});
	};
	const onClickAdd = () => {
		if (!rowSelected?.id) return setError({ show: true, message: 'Nenhum Produto Selecionado' }); 
		setListaProdutosNova((last) => [...last, rowSelected]);
		setListaProdutos((last) => [
			...last.filter((item) => item.id !== rowSelected.id),
		]);
		setRowSelected({});
	};
	const onClickRemove = () => {
		if (!rowSelected2?.id) {
			
			setError({ show: true, message: 'Nenhum Produto Selecionado' }); 
			return;
		}
		setListaProdutos((last) => [rowSelected2, ...last]);
		setListaProdutosNova((last) => [
			...last.filter((item) => item.id !== rowSelected2.id),
		]);
		setRowSelected2({});
	};

	const updateEquipamentos = () => {
		getTransferencias(userS.tipo)
			.then((response) => {
				window.location.replace("/transferencias/lancamentos");
			})
			.catch((error) => {});
	};

	const handlerAddProdutos = async (transferencia) => {
		let qtd = 1;
		const urlUpdate =
			userS.tipo == "empresa"
				? "transferencias/produtos/separador"
				: "transferencias/funcionario/produtos/separador";

		let bodyUpdate = {
			transferido: true,
			nova_transferencia: transferencia,
		};
		const salvarProdutos = listaProdutosNova
			.map((item) => {
				return { ...item, descricao: item.descricao.trim() };
			})
			.sort((a, b) => {
				if (a.descricao > b.descricao) return 1;
				if (a.descricao < b.descricao) return -1;
				return 0;
			});

		for await (const produto_ of salvarProdutos) {
			updateTable(urlUpdate, pedido.transferencia, {
				...bodyUpdate,
				qr_sku: produto_.qr_sku,
			});
			insertProdutosTransferencia(
				{
					...produto_,
					transferencia,
					data_cancelamento: null,
					user_cancelamento: null,
					cargas_id: null,
					setor_conferencia: null,
				},
				userS.tipo
			)
				.then((resp) => {})
				.catch((err) => {
					setLoadingSalvar({
						flag: false,
						style: "primary",
						msg: "Salvar",
					});
					setError({ show: true, message: getErrorMessage(err) }); 
				})
				.finally(() => {
					qtd++;
					if (qtd >= salvarProdutos.length) {
						setLoadingSalvar((last) => ({
							flag: false,
							msg: `Salvo`,
							style: "success",
						}));

						setTimeout(() => {
							handlerSimpleClose();
						}, 1000)
					}
				});
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();

		if (!listaProdutosNova.length)
			return setError({ show: true, message: "Não é possivel criar um pedido de transferencia sem produtos" }); 
		setLoadingSalvar({
			flag: true,
			style: "warning",
			msg: "Salvando",
		});

		createTransferencia(userS.tipo, {
			...transferencia,
			cargas_id: null,
			numero_pedido_real: null,
			emissao: new Date().toISOString(),
		})
			.then((response) => {
				setLoading({
					flag: false,
					style: "info",
					msg: "Adiconando Produtos",
				});
				handlerAddProdutos("TRANSF_" + response.id);
			})
			.catch((error) => {
				alert()
				setError({ show: true, message: getErrorMessage(error) });
				setLoadingSalvar({
					flag: false,
					style: "primary",
					msg: "Salvar",
				});
			});
	};

	useEffect(() => {
		setTransferencia((last) => ({
			...last,
			peso_total: listaProdutosNova.reduce((p, c) => p + c.peso, 0),
		}));
	}, [listaProdutosNova]);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Tranferência de Produtos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="my-2">
						{filters.map((item, key) =>
							Object.keys(item).map((key, index) => {
								return (
									<span className="filters-card" key={index}>
										<span className="filters">
											<span className="title">
												{key}: {item[key]}
											</span>
											<i
												className="bi bi-x-lg"
												onClick={onClickRemoveFilter}
											></i>
										</span>
									</span>
								);
							})
						)}
					</div>
					<Row>
						<Col sm="12" lg="6">
							<div className="section-filters-table">
								<div className="card-header">
									<h3 style={{ display: "flex", alignItems: "center" }}>
										Tabela Atual
									</h3>
								</div>

								<ScrollbarDown
									tableRef={tableRef1}
									tbody={tbodyRef1}
									indexList={indexList}
									setIndexList={setIndexList}
								/>
								<div
									className="responsive-table"
									ref={tableRef1}
									style={{ position: "relative", height: "600px" }}
								>
									{
										// !loading ? "" :
										<Table hover size="sm">
											<thead style={{ zIndex: "49" }}>
												<HeadTable
													arrayValues={[...listaProdutos]}
													headerList={headList}
													setArrayFilters={setFiltersNew}
													arrayFilters={filtersNew}
													setFiltersOld={setFilters}
													filtersOld={filters}
												/>
											</thead>
											<tbody ref={tbodyRef1}>
												{filteredTable
													? filteredTable.map((item, index) => {
															if (index < indexList) {
																return (
																	<BodyRowProdutosTransferencias
																		key={index}
																		bodyList={item}
																		headList={headList}
																		index={index}
																		setRowSelected={setRowSelected}
																	/>
																);
															}
													  })
													: listaProdutos.map((item, index, s) => {
															if (index < indexList) {
																return (
																	<BodyRowProdutosTransferencias
																		key={index}
																		bodyList={item}
																		headList={headList}
																		index={index}
																		tableLength={s.length}
																		setArrayFilters={setFiltersNew}
																		setRowSelected={setRowSelected}
																		selectedRow={rowSelected}
																	/>
																);
															}
													  })}
											</tbody>
										</Table>
									}

									{filteredTable ? (
										indexList < filteredTable.length ? (
											<Loading color={"black"} msg="Loading" />
										) : (
											""
										)
									) : indexList < listaProdutos.length ? (
										<Loading color={"black"} msg="Loading" />
									) : (
										""
									)}
								</div>
							</div>
						</Col>
						<Col sm="12" lg="6">
							<div className="section-filters-table">
								<div className="card-header">
									<h3 style={{ display: "flex", alignItems: "center" }}>
										Tabela Nova
									</h3>
								</div>

								<ScrollbarDown
									tableRef={tableRef2}
									tbody={tbodyRef2}
									setIndexList={() => {}}
								/>
								<div
									className="responsive-table"
									ref={tableRef2}
									style={{ position: "relative", height: "600px" }}
								>
									{
										// !loading ? "" :
										<Table hover size="sm">
											<thead style={{ zIndex: "49" }}>
												<HeadTable
													arrayValues={[...listaProdutosNova]}
													headerList={headList}
													setArrayFilters={setFiltersNew}
													arrayFilters={filtersNew}
													setFiltersOld={setFilters}
													filtersOld={filters}
												/>
											</thead>
											<tbody ref={tbodyRef2}>
												{listaProdutosNova.map((item, index, s) => {
													return (
														<BodyRowProdutosTransferencias
															key={index}
															bodyList={item}
															headList={headList}
															index={index}
															tableLength={s.length}
															setArrayFilters={setFiltersNew}
															setRowSelected={setRowSelected2}
															selectedRow={rowSelected2}
														/>
													);
												})}
											</tbody>
										</Table>
									}
								</div>
							</div>
						</Col>
					</Row>
					<div className="d-flex justify-content-center">
						<span onClick={onClickSelectAll}>
							<i class="bi bi-check-all bi-transf" title="Selecionar Tudo"></i>
						</span>
						<span onClick={onClickRemove}>
							<i
								className={`bi bi-arrow-left-circle-fill bi-transf`}
								title="Remover Produto Selecionado"
							></i>
						</span>
						<span onClick={onClickAdd}>
							<i
								className="bi bi-arrow-right-circle-fill bi-transf"
								title="Adicionar Produto Selecionado"
							></i>
						</span>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Col sm="12" lg="3">
						<Button
							variant={loadingSalvar.style}
							type="submit"
							className="mb-2"
							onClick={submitHandler}
						>
							{loadingSalvar.flag ? (
								<Loading msg="Salvando" />
							) : (
								loadingSalvar.msg
							)}
							{loadingSalvar.style === "success" ? (
								<i className="bi bi-check"></i>
							) : (
								""
							)}
						</Button>
					</Col>
					<Col sm="12" lg="3">
						<Button
							variant="secondary"
							className="mb-2"
							onClick={handlerSimpleClose}
						>
							Sair
						</Button>
					</Col>
				</Modal.Footer>
			</Modal>

			{error.show ? (
				<ModalErrorPretty
					setShow={setError}
					{...error}
				/>
			) : (
				""
			)}
		</>
	);
}
