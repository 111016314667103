import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import Loading from "../../loading/Loading";
import { updateEmbalagensList } from "../../../redux/slicers/user";
import { getAllData } from "../../../services/Servicos";
import HeadTable from "../../table/head/HeadRow";
import BodyRowProdutoEmbalado from "../../table/body/BodyRowProdutosEmbalado";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ModalError from "../../modal/ModalError";

const headListEmbalagens = [
	"index",
	"admin",
	"id_filial",
	"numero_embalagem",
	"numero_pedido",
	"conferido",
	"planejado",
	"carregado",
	"descarregado",
	"qtde_produtos",
	"descricao",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"qr_sku",
	"id",
	"cargas_id",
];

const tableRef = createRef();
const tbodyRef = createRef();

export default function Embalagens() {
	const user = useSelector((state) => state.userSlice);
	const embalagens =
		user.id_filial == "2" || user.tipo.toLowerCase() === "empresa"
			? user.embalagensList.data
			: user.embalagensList.data.filter(
					(item) => item.id_filial === user.id_filial
			  );

	const dispatch = useDispatch();

	const [error, setError] = useState({
		flag: false,
		msg: "",
	});

	const [indexList, setIndexList] = useState(30);

	// const [headList, setHead] = useState(['index','admin'])

	const updateEmbalagens = async () => {
		if (user.tipo === "empresa") {
			await getAllData("embalagens/empresa")
				.then((response) => {
					//
					dispatch(updateEmbalagensList(response.data));
				})
				.catch((error) => {
					setError({
						msg:
							"Error na obtenção das embalagens existentes." +
							JSON.stringify(error),
						flag: true,
					});
				});
		} else {
			await getAllData("cargas/funcionarios/embalagens")
				.then((response) => {
					//
					dispatch(updateEmbalagensList(response.data));
				})
				.catch((error) => {
					setError({
						msg:
							"Error na obtenção das embalagens existentes." +
							JSON.stringify(error),
						flag: true,
					});
				});
		}
	};

	useLayoutEffect(() => {
		updateEmbalagens();
	}, []);

	const [filtersNew, setFiltersNew] = useState({});
	const [filters, setFilters] = useState([]);
	const [filteredTable, setFilteredTable] = useState(false);

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [embalagens];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"numero_embalagem"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	return (
		<PlanoLositicaLayout>
			<div className="card-header">
				<h5>Tabela de Embalagens</h5>
			</div>
			<div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>

			{/* <ScrollbarLeft tableRef={tableRef} tbody={tbodyRef} distance_coluna_2={'85px'} /> */}
			<div
				className=" responsive-table"
				ref={tableRef}
				style={{ position: "relative", height: "600px" }}
			>
				<Table size="sm">
					<thead style={{ zIndex: "49" }}>
						<HeadTable
							arrayValues={[...embalagens].reverse()}
							headerList={headListEmbalagens}
							setArrayFilters={setFiltersNew}
							arrayFilters={filtersNew}
							setFiltersOld={setFilters}
							filtersOld={filters}
						/>
					</thead>
					<tbody ref={tbodyRef}>
						{filteredTable
							? filteredTable.reverse().map((item, index, self) => {
									if (index < indexList) {
										return (
											<BodyRowProdutoEmbalado
												key={index}
												bodyList={item}
												headList={headListEmbalagens}
												index={index}
												tableLength={self.length}
												setError={setError}
											/>
										);
									}
							  })
							: embalagens &&
							  [...embalagens].reverse().map((item, index, self) => {
									if (index < indexList) {
										return (
											<BodyRowProdutoEmbalado
												key={index}
												bodyList={item}
												headList={headListEmbalagens}
												index={index}
												tableLength={self.length}
												setError={setError}
											/>
										);
									}
							  })}
					</tbody>
				</Table>
				{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
				{filteredTable ? (
					indexList < filteredTable.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)
				) : indexList < embalagens.length ? (
					<Loading color={"black"} msg="Loading" />
				) : (
					""
				)}
			</div>

			{error.flag ? (
				<ModalError
					title={"ATENÇÃO"}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</PlanoLositicaLayout>
	);
}
