import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	correctDataUTC,
	getAllData,
	getStatusPedidosTransferencia,
	getTableColumns,
	getTransferencias,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";

import "./Modal.scss";

import TransferenciasEmitidas from "../transferencias/cadastrado/TransferenciasEmitidas";
import Loading from "../loading/Loading";
import ModalError from "./ModalError";
import { updateCargasList } from "../../redux/slicers/user";
import { QRCodeSVG } from "qrcode.react";
import HeadTable from "../table/head/HeadRow";
import ScrollbarLeft from "../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../services/helpers";
import ModalListarProdutosTransferencias from "./ModalListarProdutosTransferencias";

export default function ModalListarPedidosTransferencia({
	title,
	show,
	setShow,
	cargas_id,
}) {
	const handlerSimpleClose = () => {
		setShow((last) => ({ error: { flag: false } }));
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>
							{title}: <b>N°{cargas_id}</b>
						</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListaDeTransferencias cargas_id={cargas_id} />
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Sair
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function ListaDeTransferencias(props) {
	const tbodyRef = useRef();
	const tableDiv = useRef();
	const [transferenciasList, setTransferenciasList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const user = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();

	const [headList, setHead] = useState([]);
	const [error, setError] = useState({ flag: false, msg: "", title: "" });

	const updateHead = async () => {
		const abastecimentopHeader = await getTableColumns(
			"CargasPedidosTransferencias"
		);
		setHead([
			"index",
			"admin",
			...Object.keys(abastecimentopHeader.data)
				.filter((key) => key !== "empresas_id")
				.filter((key) => key !== "id"),
		]);

		const body =
			user.tipo != "empresa" && user.id_filial != 2
				? { id_filial: user.id_filial, cargas_id: props.cargas_id }
				: { cargas_id: props.cargas_id };

		getTransferencias(body)
			.then((response) => {
				setTransferenciasList(response);

				setLoading(false);
			})
			.catch((error) => {
				setError({
					flag: true,
					msg: error,
				});
			});
	};

	useEffect(() => {
		updateHead();
	}, []);

	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [transferenciasList];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<>
			<div className="section-filters-table">
				<h3>Filtrar Transferencias</h3>
				<hr />
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div>
					<h3 style={{ display: "flex", alignItems: "center" }}>
						Tabela de Transferencias{" "}
						{loading ? (
							<Spinner
								style={{ marginLeft: "10px", fontSize: "1rem" }}
								animation="border"
								size="sm"
							/>
						) : (
							""
						)}
					</h3>
				</div>
				<hr />
				<div className="responsive-table" ref={tableDiv}>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={transferenciasList}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowPedidosTransferencia
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setTransferenciasList={setTransferenciasList}
												/>
											);
										}
								  })
								: [...transferenciasList].reverse().map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowPedidosTransferencia
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={transferenciasList.length}
													setTransferenciasList={setTransferenciasList}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < transferenciasList.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>
			{error.flag ? (
				<ModalError
					title={"ERRO!"}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</>
	);
}

function BodyRowPedidosTransferencia({
	headList,
	bodyList,
	index,
	tableLength,
	setTransferenciasList,
}) {
	const refRow = useRef(null);
	const user = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();
	const filiaisList = user.filiaisList.data;
	const [loading, setLoading] = useState(false);
	const [produtosModal, setProdutosModal] = useState({
		flag: false,
		numero_pedido: "",
	});

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i <= tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
	};

	const [error, setError] = useState({
		flag: false,
	});

	const updateArrayCargas = async () => {
		let id_filial = user.id_filial;

		if (user.id_filial == 2) {
			id_filial = null;
		}
		if (user.tipo !== "empresa") {
			const cargas = await getAllData(verificaTipoUsuario(user) + "cargas", {
				id_filial,
			});
			//
			dispatch(updateCargasList(cargas.data));
			setLoading(false);
			return;
		}
		const cargas = await getAllData(verificaTipoUsuario(user) + "cargas");
		dispatch(updateCargasList(cargas.data));
	};

	const onClickCancelarPedido = async () => {
		setLoading(true);
		const url =
			user.tipo != "empresa" ? "gestao/transferencias" : "transferencias";
		const urlProdutos =
			user.tipo != "empresa"
				? "transferencias/funcionarios/produtos/atualizar"
				: "transferencias/produtos/atualizar";

		await updateTable(urlProdutos, "", {
			transferencia: bodyList.transferencia,
			cargas_id: null,
		});

		updateTable(url, bodyList.id, {
			cargas_id: null,
			cargas_id_to_delete: bodyList.cargas_id,
			status: 0,
		})
			.then((resp) => {
				setLoading({
					isLoading: false,
					msg: "Atribuido",
					style: "success",
				});
				setTransferenciasList((last) => [
					...last.filter((item) => item.id !== bodyList.id),
				]);
				updateArrayCargas();
			})
			.catch((err) => {
				setError({
					flag: true,
					msg: err,
				});
			})
			.finally(() => setLoading(false));
	};

	const onClickPlusRow = (e) => {
		e.preventDefault();

		setProdutosModal({
			flag: true,
			pedido: bodyList,
		});
	};

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										{loading ? (
											<Spinner size="sm" animation="border" />
										) : (user.tipo.toLowerCase() == "gestor" ||
												user.tipo.toLowerCase() == "empresa") &&
										  !loading &&
										  bodyList.status <= 2 ? (
											<span className="verificar">
												<i
													className="bi bi-x-lg"
													title="Remover Transferência"
													onClick={onClickCancelarPedido}
												></i>
											</span>
										) : (
											""
										)}
										<span className="verificar">
											<i
												className="bi bi-plus-circle"
												title="Verificar Produtos"
												onClick={onClickPlusRow}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id_filial") {
							/* primary key can not be modified */
							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "id_filial_destino") {
							/* primary key can not be modified */
							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial_destino
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "emissao") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.emissao)}
								</td>
							);
						} else if (item === "updated_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.updatedAt)}
								</td>
							);
						} else if (item === "created_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.createdAt)}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{getStatusPedidosTransferencia(bodyList.status)}
								</td>
							);
						} else if (item.toLowerCase() === "peso_total") {
							return (
								<td key={_index} className={item}>
									{" "}
									{Number(bodyList.peso_total).toLocaleString()}
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}

			{produtosModal.flag ? (
				<ModalListarProdutosTransferencias
					title={"Produtos da Transferencia"}
					show={produtosModal}
					setShow={setProdutosModal}
					pedido={produtosModal.pedido}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
