import { Fragment, createRef, useEffect, useRef, useState } from "react";
import ScrollbarLeft from "../scrollbars/ScrollbarLeft";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../loading/Loading";
import { QRCodeSVG } from "qrcode.react";
import ModalListarProdutosEmbalados from "../modal/ModalListarProdutosEmbalados";
import {
	getAllData,
	getData,
	numerosEditadoBR,
	updateTable,
} from "../../services/Servicos";
import ModalLerQRCode from "../modal/ModalLerQRCode";
import {
	updateCargaSelecionada,
	updateEmbalagensListCarregados,
	updateProdutosEEmbalagensBySku,
	updateProdutosListCarregados,
} from "../../redux/slicers/user";
import ModalError from "../modal/ModalError";
import ModalSelectCargas from "../modal/ModalSelectCargas";
import { addProduto } from "../../redux/slicers/carga";
import { unicos } from "../../services/helpers";

const headList = [
	"index",
	"carregado",
	"descarregado",
	"cargas_id",
	"tipo",
	"id",
	"numero_pedido/embalagem",
	"codigo",
	"descricao",
	"qtde",
	"peso",
	"altura",
	"largura",
	"comprimento",
	"volume",
];
const headListProdutos = [
	"index",
	"carregado",
	"descarregado",
	"numero_pedido",
	"codigo",
	"descricao",
	"qtde",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"abreviatura",
	"qr_sku",
	"numero_item_embalagem",
	// , 'numero_embalagem'
];

const tableRef = createRef();
const tbodyRef = createRef();

export default function DescarregarCargaLocal() {
	const user = useSelector((state) => state.userSlice);
	const produtosEEmbalagensList = user.produtosEEmbalagens.data;
	const produtosRomaneio = user.produtosList;
	const embalagens = user.embalagensList;
	const cargas_id = user.cargas_id;
	const [romaneios, setRomaneios] = useState([]);
	const [produtosEEmbalagens, setProdutosEEmbalagens] = useState([]);
	const [indexList, setIndexList] = useState(10);
	const [produtoSelecionado, setProdutoSelecionado] = useState({});
	const [errorP2, setErrorP2] = useState({ flag: false, msg: "", title: "" });
	const [loading, setLoading] = useState({
		style: "dark",
		msg: "Finalizar",
		flag: false,
	});
	const [modalSelecionarCargas, setModalSelecionarCargas] = useState({
		cargas: [],
		flag: false,
	});
	const [error, setError] = useState(false);

	const finalizarCarregamento = async () => {
		if (produtosEEmbalagens.find((item) => !item.descarregado)) {
			setError((last) => ({
				...last,
				flag: true,
				title: "Aviso",
				list: [
					"ERRO: Produtos não descarregados",
					...produtosEEmbalagens
						.filter((item) => !item.descarregado)
						.map(
							(item) =>
								`${item.codigo || item.numero_embalagem}: ${item.descricao}`
						),
				],
			}));
			return;
		}
		setLoading({
			flag: true,
			msg: "Salvando",
			style: "warning",
		});
		try {
			const data = await Promise.all([
				...unicos(produtosEEmbalagens, "cargas_id").map((item) =>
					updateTable("cargas/motorista/update-pedidos", item.cargas_id, {
						status: 13,
					})
				),
			]);
			setLoading({
				flag: false,
				msg: "Salvo",
				style: "success",
			});

			setTimeout(() => {
				window.location.replace("/map");
			}, 500);
		} catch (error) {
			setError((last) => ({
				...last,
				flag: true,
				msg: {
					ERRO: "Erro na finalização do carregamento!!",
					...error.response.data.separado.map((item) => ({
						pedido: `Numero do Pedido: ${item.numero_pedido}`,
						descricao: `Produto: ${item.codigo} - ${item.descricao}`,
						unidade: "Unidade: " + item.abreviatura,
						separado: item.conferido ? "SEPARADO: SIM" : "SEPARADO: NÃO",
						carregado: item.carregado ? "CARREGADO: SIM" : "CARREGADO: NÃO",
						item_embalagem: `Embalado: ${
							item.embalado
								? `SIM -> N°${String(item.numero_embalagem).padStart(
										7,
										0
								  )} - Item: ${item.numero_item_embalagem}`
								: "NÃO"
						}`,
					})),
					...error.response.data.carregado.map((item) => ({
						pedido: `Numero do Pedido: ${item.numero_pedido}`,
						descricao: `Produto: ${item.codigo} - ${item.descricao}`,
						unidade: "Unidade: " + item.abreviatura,
						separado: item.conferido ? "SEPARADO: SIM" : "SEPARADO: NÃO",
						carregado: item.carregado ? "CARREGADO: SIM" : "CARREGADO: NÃO",
						item_embalagem: `Embalado: ${
							item.embalado
								? `SIM -> N°${String(item.numero_embalagem).padStart(
										7,
										0
								  )} - Item: ${item.numero_item_embalagem}`
								: "NÃO"
						}`,
					})),
				},
				title: "Erro ao Finalizar Carregamento",
			}));
			setLoading({
				flag: false,
				msg: "Finalizar",
				style: "dark",
			});
		}
	};

	const verificaCargasDiponiveis = async () => {
		setModalSelecionarCargas({ flag: true, setProdutosEEmbalagens });
	};

	useEffect(() => {
		setProdutosEEmbalagens([...produtosEEmbalagensList]);
	}, [produtosEEmbalagensList]);

	return (
		<div className="section-filters-table  mt-1">
			<div className="card-header pb-1">
				<Row className="justify-content-between">
					<Col sm="12" md="6" lg="8" className="mb-1  text-sm-center ">
						<h3 style={{ display: "flex", alignItems: "center" }}></h3>
					</Col>

					<Col sm="12" md="6" lg="2" className="mb-1">
						<Button
							variant={loading.style}
							onClick={() => finalizarCarregamento()}
						>
							{loading.msg}
							{loading.style === "success" ? (
								<i className="bi bi-check"></i>
							) : (
								""
							)}
							{loading.flag ? <Spinner size="sm" animation="border" /> : ""}
						</Button>
					</Col>
				</Row>
			</div>

			<div
				className=" responsive-table"
				ref={tableRef}
				style={{ position: "relative", height: "500px" }}
			>
				<ScrollbarLeft
					tableRef={tableRef}
					tbody={tbodyRef}
					coluna2={3}
					distance_coluna_2={"85px"}
				/>
				<Table hover bordered size="sm">
					<thead>
						<tr>
							{headList.map((item, index) => {
								if (item === "index") {
									return <th key={index}>#</th>;
								} else if (item.toUpperCase() === "CREATED_AT") {
									return <th key={index}>DATA DE CRIAÇÃO</th>;
								} else if (item.toUpperCase() === "UPDATED_AT") {
									return <th key={index}>ULTIMA ATUALIZAÇÃO</th>;
								}
								return <th key={index}>{item.toUpperCase()}</th>;
							})}
						</tr>
					</thead>
					<tbody ref={tbodyRef}>
						{produtosEEmbalagens
							.filter((item) => !item.embalado)
							.map((item, index) => {
								// if(index < indexList){
								return (
									<BodyRowProdutoCarregamento
										key={index}
										bodyList={item}
										index={index}
										tableLength={romaneios.length}
										setProdutoSelecionado={setProdutoSelecionado}
										setError={setErrorP2}
										produtoSelecionado={produtoSelecionado}
									/>
								);
								// }
							})}
					</tbody>
				</Table>
				{indexList < produtosEEmbalagens.length ? (
					<Loading color={"black"} msg="Loading" />
				) : (
					""
				)}
			</div>

			{error.flag ? (
				<ModalError
					msg={error.msg}
					title={error.title}
					show={error}
					setShow={setError}
					list={error.list}
				/>
			) : (
				""
			)}
			{modalSelecionarCargas.flag ? (
				<ModalSelectCargas
					show={modalSelecionarCargas.flag}
					setShow={setModalSelecionarCargas}
					{...modalSelecionarCargas}
				/>
			) : (
				""
			)}
		</div>
	);
}

function BodyRowProdutoCarregamento({
	index,
	bodyList,
	tableLength,
	setProdutoSelecionado = () => {},
	produtoSelecionado,
}) {
	const refRow = useRef();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);
	const cargas = user.cargaSelecionada;
	const produtosEEmbalagens = user.produtosEEmbalagens.data;
	const [loadingCarregar, setLoadingCarregar] = useState(false);
	const [loadingEmbalagem, setLoadingEmbalagem] = useState(false);

	const [atualizarPedido, setAtualizarPedido] = useState(false);

	const [verProdutos, setVerProdutos] = useState({
		flag: false,
		produtosDaEmbalagem: [],
	});
	const [modalQrCode, setModalQrCode] = useState({ flag: false });
	const [error, setError] = useState({
		flag: false,
	});
	const onClickProdutos = async (e, numero_embalagem) => {
		setLoadingEmbalagem(true);
		try {
			const produtos = await getData(
				"cargas/produtos/separador",
				bodyList.cargas_id,
				{ numero_embalagem: bodyList.numero_embalagem }
			);
			setVerProdutos((last) => ({
				...last,
				flag: true,
				produtosDaEmbalagem: produtos.data,
				numero_embalagem,
			}));
		} catch (error) {
			setError({ flag: true, msg: JSON.stringify(error.response, null, 2) });
		}
		setLoadingEmbalagem(false);
	};

	const onVerificarQRCODE = async (e) => {
		if (user.cargaSelecionada.find((item) => !item.data_inicio_entrega)) {
			return setError({
				title: "Aviso",
				flag: true,
				list: ["msg: Processo de Entrega não iniciado"],
			});
		}

		setModalQrCode({ flag: true, title: "Identificar Produto", bodyList });
	};

	useEffect(() => {
		if (!produtoSelecionado.qr_sku) return;
		if (bodyList.qr_sku === produtoSelecionado.qr_sku) {
			if (produtoSelecionado.descarregado) {
				refRow.current.classList = "bg-selected-danger";
				setProdutoSelecionado({});
				return;
			} else {
				refRow.current.classList = "bg-selected";
			}
		} else {
			refRow.current.classList = "";
		}
	}, [produtoSelecionado]);

	const descarregarFn = async (e) => {
		setLoadingCarregar(true);

		dispatch(
			updateProdutosEEmbalagensBySku({
				parameter: "descarregado",
				qr_sku: produtoSelecionado.qr_sku,
				descarregado: true,
				data: new Date().toISOString(),
				user: user.user_descarregamento,
			})
		);

		setTimeout(() => {
			setLoadingCarregar(false);
		}, 500);
	};

	useEffect(() => {
		if (!atualizarPedido) return;
		// atualizarStatusPedido();
		setAtualizarPedido(false);
	}, [atualizarPedido]);

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={() => setProdutoSelecionado(bodyList)}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "carregado") {
							return (
								<td key={_index} style={{ textAlign: "center" }}>
									{!bodyList.carregado ? (
										<i className="bi bi-x" title="Produto Carregado"></i>
									) : (
										<i className="bi bi-check" title="Produto Carregado"></i>
									)}
								</td>
							);
						} else if (item === "descarregado") {
							return (
								<td key={_index} style={{ textAlign: "center" }}>
									{loadingCarregar ? (
										<Spinner size="sm" animation="border" />
									) : !bodyList.descarregado ? (
										!bodyList.carregado ? (
											<i className="bi bi-x" title="Produto Carregado"></i>
										) : (
											<div className="admin justify-content-center">
												<span
													className="editar"
													onClick={() => onVerificarQRCODE()}
												>
													<i
														className="bi bi-camera"
														title="Verificar QR Code"
														style={{ cursor: "pointer" }}
													></i>
												</span>
											</div>
										)
									) : (
										<i className="bi bi-check" title="Produto Carregado"></i>
									)}
								</td>
							);
						} else if (/qtde/i.test(item)) {
							return (
								<td key={_index} className={item}>
									{bodyList.tipo == "EMBALAGEM"
										? numerosEditadoBR(bodyList.qtde_produtos)
										: numerosEditadoBR(bodyList.qtde)}
								</td>
							);
						} else if (/peso|altura|largura|comprimento|volume/i.test(item)) {
							return (
								<td key={_index} className={item}>
									{numerosEditadoBR(bodyList[item])}
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td key={_index} className={item} style={{ minWidth: "500px" }}>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "DTEMISSAO") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item.toLowerCase() === "numero_pedido/embalagem") {
							if (bodyList.tipo !== "PRODUTO") {
								return (
									<td key={_index}>
										<div className="d-flex justify-content-between">
											<span
												className="verificar"
												style={{ fontSize: "inherit", color: "inherit" }}
											>
												{bodyList.numero_pedido}
											</span>
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												{loadingEmbalagem ? (
													<Spinner color="black" animation="border" size="sm" />
												) : (
													<i
														className={"bi bi-plus-circle bi-circle-animation"}
														title="Ver Produtos"
														onClick={(e) => {
															onClickProdutos(e, bodyList.numero_embalagem);
														}}
													></i>
												)}
											</span>
										</div>
									</td>
								);
							}
							return (
								<td key={_index} className={item}>
									{" "}
									{bodyList.numero_pedido}
								</td>
							);
						} else if (item.toLowerCase() === "codigo") {
							if (bodyList.tipo !== "PRODUTO") {
								return (
									<td key={_index} className={item}>
										{" "}
										{String(bodyList.numero_embalagem).padStart(7, 0)}
									</td>
								);
							}
							return (
								<td key={_index} className={item}>
									{" "}
									{bodyList.codigo}
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList.qr_sku}
								</td>
							);
						} else if (item === "qr_code_id_teste") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_sku + "}"} />
								</td>
							);
						}

						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}

			{verProdutos.flag ? (
				<ModalListarProdutosEmbalados
					setShow={setVerProdutos}
					show={verProdutos.flag}
					produtos={verProdutos.produtosDaEmbalagem}
					headList={headListProdutos}
					{...verProdutos}
				/>
			) : (
				""
			)}
			{modalQrCode.flag ? (
				<ModalLerQRCode
					msg={modalQrCode.msg}
					title={modalQrCode.title}
					show={modalQrCode.flag}
					setShow={setModalQrCode}
					setProdutoSelecionado={setProdutoSelecionado}
					produtoSelecionado={produtoSelecionado}
					entregarFn={descarregarFn}
					entregar={true}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError setShow={setError} show={error.flag} {...error} />
			) : (
				""
			)}
		</Fragment>
	);
}
