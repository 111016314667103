import PlanoLositicaLayout from "../PlanoLositicaLayout";
import { useEffect, useRef, useState } from "react";
import {
	GoogleMap,
	useJsApiLoader,
	Marker,
	Autocomplete,
	DirectionsRenderer,
	InfoWindow,
	DistanceMatrixService,
	Polyline,
} from "@react-google-maps/api";
import "../../entregas/Map.scss";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
	createRotas,
	getData,
	verificaTipoUsuario,
} from "../../../services/Servicos";

import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../redux/slicers/user";
import RotasCadastradas from "./RotasCadastradas";
import ModalError from "../../modal/ModalError";
import ModalRotaCadastrada from "../../modal/ModalRotaCadastrada";

const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const center = {
	lat: -15.61603896596096,
	lng: -56.068463002868285,
};
export default function Rotas() {
	const google = window.google;
	const [libraries] = useState(["places"]);
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
		libraries: libraries,
	});

	const formRef = useRef();
	const [formRefOrigem, setFormRefOrigem] = useState(null);
	const [formRefDestino, setFormRefDestino] = useState(null);
	const [formRefWaypoint, setFormRefOWaypoint] = useState(null);
	const [opcao_selecionada, setOpcao_selecionada] = useState(0);
	const [map, setMap] = useState(/** @type google.maps.Map */ (null));
	const [directions, setDirections] = useState(null);
	const [origemClicked, setOrigemClicked] = useState(false);
	const [destinoClicked, setDestinoClicked] = useState(false);
	const [paradaClicked, setParadaClicked] = useState(false);
	const [selectedMarker, setSelectedMarker] = useState(null);
	const [selectOrigemDestinoPin, setSelectOrigemDestinoPin] = useState({
		selected: "",
		action: "",
	});
	const [markers, setMarkers] = useState([]);
	const [waypoints, setWaypoints] = useState([]);
	const [legs, setLegs] = useState({
		index: 0,
		legs: [],
	});
	const [loading, setLoading] = useState({
		flag: false,
		local: "",
	});
	const [modal, setModal] = useState({
		flag: false,
		msg: "",
	});

	const [error, setError] = useState({ flag: false, msg: "", title: "" });

	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);

	const addParadas = (e) => {
		e.preventDefault();
		const waypoint = formRef.current.waypoints.value;
		if (!waypoint.length) {
			return;
		}
		if (waypoints.filter((item) => item === waypoint).length) {
			alert("item ja filtrado");
			return;
		}
		setWaypoints((last) => [...last, { waypoint, paradaClicked }]);

		formRef.current.waypoints.value = "";
		setParadaClicked(false);
	};

	const removeParada = (e) => {
		e.preventDefault();
		const waypoint = e.target.parentNode.firstElementChild.innerText;
		setWaypoints((last) => last.filter((item) => item.waypoint !== waypoint));
	};

	const calcularRotaHandler = async (e) => {
		setMarkers([]);
		e.preventDefault();
		const origem = formRef.current.origem.value;
		const destino = formRef.current.destino.value;

		/* geocoding of origin */
		if (!origem.length && !destino.length) return;
		const directionsService = new google.maps.DirectionsService();
		try {
			const directions = await directionsService.route({
				origin: origem,
				destination: destino,
				travelMode: google.maps.TravelMode.DRIVING,
				unitSystem: google.maps.UnitSystem.METRIC,
				// provideRouteAlternatives: true,
				waypoints: waypoints.map((item) => {
					if (!item.paradaClicked) {
						return { location: item.waypoint, stopover: true };
					}
					return {
						location: {
							lat: parseFloat(item.waypoint.split(",")[0].trim()),
							lng: parseFloat(item.waypoint.split(",")[1].trim()),
						},
						stopover: true,
					};
				}),
				optimizeWaypoints: true,
				provideRouteAlternatives: true,
				transitOptions: {
					departureTime: new Date(1337675679473),
					modes: ["BUS"],
					routingPreference: "FEWER_TRANSFERS",
				},

				// provideRouteAlternatives: false,
			});

			setMarkers([
				...directions.routes[0].legs.map((item, index) => {
					return {
						position: {
							lat: item.start_location.lat(),
							lng: item.start_location.lng(),
						},
					};
				}),
				{
					position: {
						lat: directions.routes[0].legs[
							directions.routes[0].legs.length - 1
						].end_location.lat(),
						lng: directions.routes[0].legs[
							directions.routes[0].legs.length - 1
						].end_location.lng(),
					},
				},
			]);

			setDirections(directions);
			setLegs({
				index: 0,
				legs: directions.routes[0].legs,
			});

			setOpcao_selecionada(0);
		} catch (error) {
			alert("error on getting directions");
		}
		return;
	};

	const apagarRotaHandler = (e) => {
		setDirections(null);
		setWaypoints([]);
		formRef.current.waypoints.value = "";
		formRef.current.origem.value = "";
		formRef.current.destino.value = "";
		setMarkers([]);
		setFormRefOrigem(null);
		setFormRefDestino(null);
		setFormRefOWaypoint(null);
		setLegs({
			index: 0,
			legs: [],
		});
		setSelectOrigemDestinoPin({ selected: "", action: "" });
	};

	const testOnClickMap = (e) => {
		const origem = formRef.current.origem.value;
		const destino = formRef.current.destino.value;
		const parada = formRef.current.waypoints.value;

		if (!selectOrigemDestinoPin.selected.length) {
			return;
		}

		setLoading((last) => ({ ...last, flag: true, local: "calcular" }));

		/* caso ja exista um pin no bloco a */
		if (
			origem.length &&
			markers.length &&
			selectOrigemDestinoPin.selected === "origem"
		) {
			let [lat, lng] = origem.split(",");
			lat = Number(lat);
			lng = Number(lng);
			setMarkers((last) =>
				last.filter(
					(item) => item.position.lat !== lat && item.position.lng !== lng
				)
			);
		} else if (
			destino.length &&
			markers.length &&
			selectOrigemDestinoPin.selected === "destino"
		) {
			let [lat, lng] = destino.split(",");
			lat = Number(lat);
			lng = Number(lng);
			setMarkers((last) =>
				last.filter(
					(item) => item.position.lat !== lat && item.position.lng !== lng
				)
			);
		} else if (
			parada.length &&
			markers.length &&
			selectOrigemDestinoPin.selected === "paradas"
		) {
			let [lat, lng] = parada.split(",");
			lat = Number(lat);
			lng = Number(lng);
			setMarkers((last) =>
				last.filter(
					(item) => item.position.lat !== lat && item.position.lng !== lng
				)
			);
		}

		setMarkers((last) => [
			...last,
			{
				position: {
					lat: e.latLng.lat(),
					lng: e.latLng.lng(),
				},
			},
		]);

		if (selectOrigemDestinoPin.selected === "origem") {
			formRef.current.origem.value = `${e.latLng.lat()}, ${e.latLng.lng()}`;
			setSelectOrigemDestinoPin({ selected: "", action: "" });
			setFormRefOrigem(`${e.latLng.lat()}, ${e.latLng.lng()}`);
		} else if (selectOrigemDestinoPin.selected === "destino") {
			formRef.current.destino.value = `${e.latLng.lat()}, ${e.latLng.lng()}`;
			setSelectOrigemDestinoPin({ selected: "", action: "" });
			setFormRefDestino(`${e.latLng.lat()}, ${e.latLng.lng()}`);
		} else if (selectOrigemDestinoPin.selected === "paradas") {
			formRef.current.waypoints.value = `${e.latLng.lat()}, ${e.latLng.lng()}`;
			setSelectOrigemDestinoPin({ selected: "", action: "" });
			setFormRefOWaypoint(`${e.latLng.lat()}, ${e.latLng.lng()}`);
		}
		setLoading((last) => ({ ...last, flag: false }));
	};

	const salvarRotaHandler = async (e) => {
		e.preventDefault();

		if (!directions) {
			return;
		}
		setLoading((last) => ({ ...last, flag: true, local: "salvar" }));

		const origem = formRef.current.origem.value;
		const destino = formRef.current.destino.value;
		const locations = [
			{ waypoint: origem, clicked: origemClicked },
			...waypoints.map((item) => ({
				waypoint: item.waypoint,
				clicked: item.paradaClicked,
			})),
			{ waypoint: destino, clicked: destinoClicked },
		];

		let geocoder = new google.maps.Geocoder();
		let rotasCriadas = await getData(
			verificaTipoUsuario(user) + "rotas",
			"",
			""
		);
		const rotas = rotasCriadas.data
			.filter((i, n, s) => s.findIndex((o) => o.rota === i.rota) === n)
			.map((item) => item.rota.replace("Rota_", ""))
			.sort((a, b) => {
				if (a.rota < b.rota) return 1;
				if (a.rota > b.rota) return -1;
				return 0;
			})
			.reverse();
		let rotasCriadasIndex = rotas.length ? Number(rotas[0]) + 1 : 1;

		//
		// return
		locations.map(async (item, index) => {
			if (!item.clicked) {
				await geocoder
					.geocode({ address: item.waypoint })
					.then(async (result) => {
						let cidade = "",
							estado = "",
							pais = "",
							cep = "",
							numero = "",
							rua = "",
							bairro = "",
							lat = "",
							lng = "";
						result.results.map((item) => {
							lat = item.geometry.location.lat();
							lng = item.geometry.location.lng();
							item?.address_components.map((item2) => {
								if (
									item2?.types.indexOf("administrative_area_level_2") >= 0 &&
									!cidade.length
								) {
									cidade = item2.long_name;
									return;
								}
								if (
									item2?.types.indexOf("administrative_area_level_1") >= 0 &&
									!estado.length
								) {
									estado = `${item2.long_name} - ${item2.short_name}`;
									return;
								}
								if (item2?.types.indexOf("country") >= 0 && !pais.length) {
									pais = item2.long_name;
									return;
								}
								if (item2?.types.indexOf("postal_code") >= 0 && !cep.length) {
									cep = item2.long_name;
									return;
								}
								if (item2?.types.indexOf("route") >= 0 && !rua.length) {
									rua = item2.long_name;
									return;
								}
								if (
									item2?.types.indexOf("street_number") >= 0 &&
									!numero.length
								) {
									numero = item2.long_name;
									return;
								}
								if (
									item2?.types.indexOf("sublocality_level_1") >= 0 &&
									!bairro.length
								) {
									bairro = item2.long_name;
									return;
								}
							});
						});

						try {
							/* somente manda criar as rotas! */
							const result = await createRotas(
								{
									// console.log({
									metodo: "location by address",
									numero,
									rua,
									bairro,
									cidade,
									estado,
									cep,
									pais,
									latidude: lat,
									longitude: lng,
									step: index + 1,
									rota: `Rota_${rotasCriadasIndex}`,
									opcao_selecionada,
									id_filial: user.tipo == "empresa" ? 2 : user.id_filial,
								},
								user.tipo
							);

							setLoading((last) => ({ ...last, flag: false }));
							/* apagar os dados */
							setModal({ flag: true, msg: "Rota Cadastrada com Sucesso" });
							setTimeout(() => {
								return window.location.reload();
							}, 1000);
						} catch (error) {
							setError({
								flag: true,
								title: "Erro no Servidor",
								msg: error,
							});
						}
					});
			} else {
				geocoder
					.geocode({
						location: {
							lat: parseFloat(item.waypoint.split(",")[0]),
							lng: parseFloat(item.waypoint.split(",")[1]),
						},
					})
					.then(async (result) => {
						let cidade = "",
							estado = "",
							pais = "",
							cep = "",
							numero = "",
							rua = "",
							bairro = "",
							lat = "",
							lng = "";
						result.results.map((item) => {
							lat = item.geometry.location.lat();
							lng = item.geometry.location.lng();
							item?.address_components.map((item2) => {
								if (
									item2?.types.indexOf("administrative_area_level_2") >= 0 &&
									!cidade.length
								) {
									cidade = item2.long_name;
									return;
								}
								if (
									item2?.types.indexOf("administrative_area_level_1") >= 0 &&
									!estado.length
								) {
									estado = `${item2.long_name} - ${item2.short_name}`;
									return;
								}
								if (item2?.types.indexOf("country") >= 0 && !pais.length) {
									pais = item2.long_name;
									return;
								}
								if (item2?.types.indexOf("postal_code") >= 0 && !cep.length) {
									cep = item2.long_name;
									return;
								}
								if (item2?.types.indexOf("route") >= 0 && !rua.length) {
									rua = item2.long_name;
									return;
								}
								if (
									item2?.types.indexOf("street_number") >= 0 &&
									!numero.length
								) {
									numero = item2.long_name;
									return;
								}
								if (
									item2?.types.indexOf("sublocality_level_1") >= 0 &&
									!bairro.length
								) {
									bairro = item2.long_name;
									return;
								}
							});
						});
						try {
							/* somente manda criar as rotas! */
							const result = await createRotas(
								{
									// console.log({
									metodo: "location by lat/lng",
									numero,
									rua,
									bairro,
									cidade,
									estado,
									cep,
									pais,
									latidude: lat,
									longitude: lng,
									step: index + 1,
									rota: `Rota_${rotasCriadasIndex}`,
									opcao_selecionada,
									id_filial: user.tipo == "empresa" ? 2 : user.id_filial,
								},
								user.tipo
							);

							setLoading((last) => ({ ...last, flag: false }));
							/* apagar os dados */
							setModal({ flag: true, msg: "Rota Cadastrada com Sucesso" });
							setTimeout(() => {
								return window.location.reload();
							}, 1000);
						} catch (error) {
							setError({
								flag: true,
								title: "Erro no Servidor",
								msg: error,
							});
						}
					});
			}
		});
	};

	return (
		<PlanoLositicaLayout>
			<div className="card-header">
				<h1>Cadastramento de Rotas</h1>
			</div>
			<div className="map-div">
				{isLoaded ? (
					<div className="map-div-routes">
						<div className="map-div-routes-filter">
							<Form ref={formRef}>
								<Form.Group as={Row} className="mb-3 align-items-center">
									<Form.Label column sm="12" md="3">
										Cidade Partida:
									</Form.Label>
									<Col sm="10" md="7">
										<Autocomplete>
											<Form.Control
												type="text"
												placeholder="Partida"
												name="origem"
												onKeyUp={(e) => setOrigemClicked(false)}
												onChange={(e) => setFormRefOrigem(e.target.value)}
											/>
										</Autocomplete>
									</Col>
									<Col sm="2">
										<i
											className={
												"bi bi-geo-alt-fill pin" +
												(selectOrigemDestinoPin.selected === "origem"
													? " pin-selected"
													: "")
											}
											style={{ cursor: "pointer" }}
											onClick={(e) => {
												setSelectOrigemDestinoPin({ selected: "origem" });
												setOrigemClicked(true);
												// alert('origem pin selecionado')
											}}
											title="Selecionar Origem no Mapa"
										></i>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3 align-items-center">
									<Form.Label column sm="12" md="3">
										Cidade Destino:
									</Form.Label>
									<Col sm="10" md="7">
										{/* api da google para autocompletar os locais */}
										<Autocomplete>
											<Form.Control
												type="text"
												placeholder="Destino"
												name="destino"
												onKeyUp={(e) => setDestinoClicked(false)}
												onSelect={(e) => setFormRefDestino(e.target.value)}
												disabled={formRefOrigem ? false : true}
											/>
										</Autocomplete>
									</Col>
									<Col sm="2">
										<i
											className={
												"bi bi-geo-alt-fill pin" +
												(selectOrigemDestinoPin.selected === "destino"
													? " pin-selected"
													: "")
											}
											style={{ cursor: "pointer" }}
											onClick={(e) => {
												if (!formRefOrigem) return;
												setSelectOrigemDestinoPin({
													selected: "destino",
													action: "click",
												});
												setDestinoClicked(true);
												// alert('origem pin selecionado')
											}}
											title="Selecionar Origem no Mapa"
										></i>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3 align-items-center">
									<Form.Label column sm="12" md="3">
										Cidade Parada:
									</Form.Label>
									<Col sm="10" md="7">
										{/* <InputGroup> */}
										<div className="wrapper-button-icon">
											<i
												className="bi bi-plus-lg icon bg-info"
												onClick={addParadas}
											></i>
											<Autocomplete>
												<Form.Control
													type="text"
													placeholder="Adicionar Parada"
													name="waypoints"
													className="input"
													onKeyUp={(e) => setParadaClicked(false)}
													disabled={!formRefDestino ? true : false}
												/>
											</Autocomplete>
										</div>
									</Col>
									<Col sm="2">
										<i
											className={
												"bi bi-geo-alt-fill pin" +
												(selectOrigemDestinoPin.selected === "paradas"
													? " pin-selected"
													: "")
											}
											style={{ cursor: "pointer" }}
											onClick={(e) => {
												if (!formRefDestino) return;
												setSelectOrigemDestinoPin({
													selected: "paradas",
													action: "click",
												});
												setParadaClicked(true);
												// alert('origem pin selecionado')
											}}
											title="Selecionar Origem no Mapa"
										></i>
									</Col>
								</Form.Group>
								{waypoints.length ? (
									<Form.Group as={Row} className="mb-3">
										<Form.Label column sm="12" md="3">
											Paradas:
										</Form.Label>
										<Col sm="12" md="8">
											<div className="waypoints">
												{waypoints.map((item, index) => (
													<span className="filters-card" key={index}>
														<span className="filters">
															<span className="title">{item.waypoint}</span>
															<i
																className="bi bi-x-lg"
																onClick={removeParada}
															></i>
														</span>
													</span>
												))}
											</div>
										</Col>
									</Form.Group>
								) : (
									""
								)}

								{legs.legs.length ? (
									<Form.Group as={Row} className="mb-3">
										<Form.Label column sm="12" md="3">
											Resumo:
										</Form.Label>
										<Col sm="10" md="8">
											<div className="waypoints-novo">
												{legs.legs.map((item, index) => (
													<span className="waypoints-novo-card" key={index}>
														<span className="filters-card-duration">
															<span className="filters">
																<i className="bi bi-arrow-90deg-down"></i>
																<span className="title">
																	{item.duration.text}
																</span>
																<i className="bi bi-arrow-90deg-down rotate-rigth"></i>
															</span>
														</span>
														<span className="filters-card-distance">
															<span className="filters">
																<i className="bi bi-arrow-90deg-up"></i>
																<span className="title">
																	{item.distance.text}
																</span>
																<i className="bi bi-arrow-90deg-up rotate-rigth"></i>
															</span>
														</span>
														<span className="filters-card">
															<span className="filters filters-address">
																<span className="title">
																	{item.start_address.slice(0, 25)}
																</span>
																<i
																	className="bi bi-x-lg"
																	onClick={removeParada}
																></i>
															</span>
														</span>
														<span className="filters-card">
															<span className="filters filters-address">
																<span className="title">
																	{item.end_address.slice(0, 25)}
																</span>
																<i
																	className="bi bi-x-lg"
																	onClick={removeParada}
																></i>
															</span>
														</span>
													</span>
												))}
											</div>
										</Col>
									</Form.Group>
								) : (
									""
								)}

								<Row className="justify-content-center align-items-center">
									<Col sm={12} md={12} lg={6} xl={3} className="mt-2">
										<Button variant="info" onClick={calcularRotaHandler}>
											{loading.flag && loading.local === "calcular" ? (
												<span style={{ color: "#ffffff" }}>
													Calculando{" "}
													<Spinner
														animation="border"
														variant="white"
														size="sm"
													/>
												</span>
											) : (
												"Calcular Rota"
											)}
										</Button>
									</Col>
									<Col sm={12} md={12} lg={6} xl={3} className="mt-2">
										<Button variant="secondary" onClick={apagarRotaHandler}>
											Cancelar
										</Button>
									</Col>
									<Col sm={12} md={12} lg={6} xl={3} className="mt-2">
										<Button variant="primary" onClick={salvarRotaHandler}>
											{loading.flag && loading.local === "salvar" ? (
												<span style={{ color: "#ffffff" }}>
													Salvando{" "}
													<Spinner
														animation="border"
														variant="white"
														size="sm"
													/>
												</span>
											) : (
												"Salvar Rota"
											)}
										</Button>
									</Col>
									<Col className="mt-2" onClick={(e) => map.panTo(center)}>
										<Button variant="warning">
											<i className="bi bi-cursor-fill"></i>
										</Button>
									</Col>
								</Row>
							</Form>
						</div>
					</div>
				) : (
					""
				)}
				{isLoaded ? (
					<GoogleMap
						mapContainerStyle={{ width: "100%", height: "100%" }}
						center={center}
						zoom={12}
						options={{
							streetViewControl: false,
							mapTypeControl: false,
							fullscreenControl: true,
						}}
						onLoad={setMap}
						onClick={testOnClickMap}
					>
						{markers.map((item, index) => {
							return (
								<Marker
									key={index}
									position={item.position}
									label={labels[index]}
									draggable={true}
									onDrag={(e) => {
										if (index == 0) {
											formRef.current.origem.value = `${e.latLng.lat()}, ${e.latLng.lng()}`;
										} else if (index == 1) {
											formRef.current.destino.value = `${e.latLng.lat()}, ${e.latLng.lng()}`;
										} else if (index == 2) {
											formRef.current.waypoints.value = `${e.latLng.lat()}, ${e.latLng.lng()}`;
										}
									}}
								/>
							);
						})}

						{
							directions && (
								<DirectionsRenderer
									directions={directions}
									onClick={setSelectedMarker}
								/>
							)
							// directions &&
							// directions.routes.map((item, index) => {
							//     return <Polyline options={{
							//         path: directions.routes[index].overview_path,
							//         strokeColor: index == legs.index ? '#008dff' : "#BBBDBF",  // you might want different colors per suggestion
							//         strokeOpacity: 2,
							//         strokeWeight: 5,
							//         zIndex: index == legs.index ? 1: 0
							//     }}
							//         onClick={() => {
							//             setOpcao_selecionada(index)
							//             setLegs({
							//                 index,
							//                 legs: directions.routes[index].legs
							//            })
							//         }}
							//     />

							// })
						}
						{selectedMarker && (
							<InfoWindow
								position={{
									lat: selectedMarker.geometry.cordinates[1],
									lng: selectedMarker.geometry.cordinates[0],
								}}
								onCloseClick={setSelectedMarker(null)}
							></InfoWindow>
						)}
					</GoogleMap>
				) : (
					""
				)}
			</div>
			{modal.flag ? (
				<ModalRotaCadastrada
					show={modal.flag}
					title="Atenção"
					setShow={setModal}
					msg={modal.msg}
				/>
			) : (
				""
			)}
			<RotasCadastradas />

			{error.flag ? (
				<ModalError
					title={"ATENÇÃO"}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</PlanoLositicaLayout>
	);
}
