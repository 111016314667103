import { useEffect } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { updateCaminhaoSelecionado, updateProdutosList, updateRomaneiosList } from "../../redux/slicers/user"
import { getAllData, verificaTipoUsuario } from "../../services/Servicos"

import './Modal.scss'
import CarregarCaminhaoGestor from "../carregamento/CarregarCaminhaoGestor"
import { removeAll } from "../../redux/slicers/carga"
import MonitoramentoCargaGestor from "../carregamento/MonitoramentoCargaGestor"


export default function ModalPlanejamentoCarga({ show, setShow, caminhao_placa, carga_id, setCargaClick, status }) {

    const dispatch = useDispatch()
    const user = useSelector(state => state.userSlice)
    
    const handlerSimpleClose = () => {
        dispatch(removeAll())
        dispatch(updateCaminhaoSelecionado({status: false}))
        dispatch(updateProdutosList([]))
       
        setShow(last => ({
            ...last
            , flag: false
        })) 
        // setColorText({
        //     color:'#797979'
        // })
        setCargaClick(last => ({
            ...last
            , target: 0
            , style:{
                scale:'1'
                , zIndex:'1'
                // , transition: '0.5s'

            }
        }))
    }

    const getRomaneiosEntrega = async () =>{
        const romaneiosGet =  await getAllData(verificaTipoUsuario(user) + 'planejamento/cargas/pedidos')
        let romaneiosList = romaneiosGet.data
        romaneiosList = romaneiosList.filter(item => item.cargas_id == carga_id)//.filter(item => item.status === 'AGUARDANDO ENTREGA')
        dispatch(updateRomaneiosList(romaneiosList))
    }

    useEffect(() => {
        getRomaneiosEntrega()
    },[])

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Area de Planejamento -  Carga N° <i>{carga_id}</i>, Caminhão <i>{caminhao_placa}</i></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status === 3 || status == 4 ? <CarregarCaminhaoGestor caminhao_placa={caminhao_placa} carga_id={carga_id}/> : ""}
                    {status >= 9 && status <= 12 ? <MonitoramentoCargaGestor caminhao_placa={caminhao_placa} carga_id={carga_id}/> : ""}
                    
                </Modal.Body>
                <Modal.Footer>
                    <Col sm='3'>
                        <Button  variant='primary' onClick={handlerSimpleClose}>Fechar</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>
    )
}
