import { Fragment } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import './NavbarFrota.scss'
import { useSelector } from "react-redux";

export default function NavbarFrota() {
    const user = useSelector(state => state.userSlice)
    return (
        <Fragment>
            <Nav variant='tabs'>
                {
                    user.tipo.toLowerCase() === 'empresa' || user.tipo.toLowerCase() === 'gestor' ?
                        <Fragment>
                            <Nav.Item>
                                <NavLink className="nav-link" to='cadastrados'>Caminhões Cadastrados</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='cadastro'>Cadastrar Caminhão</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='cadastro-freteiro'>Cadastrar Freteiro</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='caminhao-caminhoneiro'>Caminhão/Caminhoneiro</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='manutencoes'>Manutenção</NavLink>
                            </Nav.Item>
                        </Fragment>
                        :
                        ""
                }
            </Nav>
            <Outlet />
        </Fragment>
    )
}






