import { createRef, useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import UserLayout from "../layout/UserLayout";
import Loading from "../../loading/Loading";
import "../NavbarUser.scss";
import BodyRowUsuarios from "../../table/body/BodyRowUsuarios";
import HeadTable from "../../table/head/HeadRow";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import { updateFuncionarios } from "../../../redux/slicers/user";
import { getFuncionarios } from "../../../services/Servicos";

const headList = [
	"index",
	"admin",
	"status",
	"id",
	"id_filial",
	"tipo",
	"name",
	"cpf",
	"cnh",
	"email",
	"telefone",
	"local_separacao",
	"desc_setor_sep",
	"data_nascimento",
	"data_valid_cnh",
	"pis",
];
const tableRef = createRef();
const tbodyRef = createRef();

export default function UserCadastrado() {
	const funcionarios = useSelector((state) => state.userSlice.funcionariosList);

	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(30);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(30);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [funcionarios.data];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const updateFuncionariosList = async () => {
		try {
			const data = await getFuncionarios();
			dispatch(updateFuncionarios(data));
		} catch (error) {}
	};

	useLayoutEffect(() => {
		updateFuncionariosList();
		if (funcionarios?.data.length) {
			setLoading(false);
		}
	}, []);
	return (
		<UserLayout>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<div className="card-header">
					<h3>Filtros Selecionados</h3>
				</div>

				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header">
					<h3 style={{ display: "flex", alignItems: "center" }}>
						Tabela de Usuários{" "}
						{loading ? (
							<Spinner
								style={{ marginLeft: "10px", fontSize: "1rem" }}
								animation="border"
								size="sm"
							/>
						) : (
							""
						)}
					</h3>
				</div>
				<div
					className=" responsive-table"
					ref={tableRef}
					style={{ position: "relative", height: "600px", maxHeight: "unset" }}
				>
					<ScrollbarLeft
						tableRef={tableRef}
						coluna2={5}
						maxima_distancia={220}
					/>
					<Table hover size="sm">
						<thead>
							<HeadTable
								arrayValues={funcionarios.data}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
								rota="funcionarios"
							/>
						</thead>
						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowUsuarios
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
										// return item
								  })
								: funcionarios.data.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowUsuarios
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
										// return item
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < funcionarios.data.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>
		</UserLayout>
	);
}
