import { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
	getAllData,
	getData,
	getStatusCarga,
	getStatusPedidos,
	isMobile,
} from "../../services/Servicos";

import { pdf } from "@react-pdf/renderer";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import PdfMotorista from "./PdfMotorista";
import Loading from "../loading/Loading";
import { getNewId, unicos } from "../../services/helpers";

export default function ModalPdfMotorista(props) {
	const canvasRef = useRef();
	pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

	const [pdfRef, setPdfRef] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [numberPages, setNumberOfPages] = useState(1);

	const [loading, setLoading] = useState(true);
	const [pdfFile, setPdfFile] = useState(null);
	const [pdfFileFull, setPdfFileFull] = useState(null);
	const [isMobileFlag, setIsMobileFlag] = useState(null);
	const user = useSelector((state) => state.userSlice);
	const filiais = user.filiaisList?.data;

	const printPDF = (propsE) => {
		var obj = pdf(<PdfMotorista {...propsE} />)
			.toBlob()
			.then((blob) => {
				var blob_ = new Blob([blob], { type: "application/pdf" });
				const docUrl = URL.createObjectURL(blob_);
				if (isMobile(String(window.navigator.userAgent))) {
					setPdfFile(docUrl);
				} else {
					setPdfFileFull(docUrl);
					setLoading(false);
				}
			});
	};

	useEffect(() => {
		const getInfo = async () => {
			try {
				const [cargas] = await Promise.all([
					getAllData("dashboard/motoristas-cargas-pedidos", {
						stringfilters: ` and caminhoneiro = '${props.cpf}' and emissao_carga between '${props.data_i}' and '${props.data_f}' and status_carga between '${props.status_i}' and '${props.status_f}'`,
					}),
				]);
				const obj = {
					...props,
					cargas: unicos(cargas.data, "id").map((item) => ({
						...item,
						status_carga: getStatusCarga(item.status_carga),
						uuid: getNewId(),
						filial_carga: filiais.find(fili => fili.idFilial == item.filial_carga)?.identificacaoInterna,
					})),
					pedidos: unicos(cargas.data, "romaneio").map((item) => ({
						...item,
						status_pedido: getStatusPedidos(item.status_pedido),
						uuid: getNewId(),
					})),
				};

				printPDF(obj);
			} catch (error) {}
		};
		getInfo();
	}, []);

	const renderPage = useCallback(
		(pageNum, pdf = pdfRef) => {
			pdf &&
				pdf.getPage(pageNum).then(function (page) {
					const viewport = page.getViewport({ scale: 1.3 });
					const canvas = canvasRef.current;
					const context = canvas.getContext("2d");
					var outputScale = window.devicePixelRatio || 1;
					canvas.width = Math.floor(viewport.width * outputScale);
					canvas.height = Math.floor(viewport.height * outputScale);
					canvas.style.width = Math.floor(viewport.width) + "px";
					canvas.style.height = Math.floor(viewport.height) + "px";

					var transform =
						outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

					var renderContext = {
						canvasContext: context,
						transform: transform,
						viewport: viewport,
					};
					page.render(renderContext);
				});
		},
		[pdfRef]
	);

	useEffect(() => {
		renderPage(currentPage, pdfRef);
	}, [pdfRef, currentPage, renderPage]);

	useEffect(() => {
		if (!pdfFile) return;
		const loadingTask = pdfjsLib.getDocument(pdfFile);
		loadingTask.promise.then(
			(loadedPdf) => {
				setPdfRef(loadedPdf);
				setNumberOfPages(loadedPdf.numPages);
				setLoading(false);
			},
			function (reason) {}
		);
	}, [pdfFile]);

	const nextPage = () =>
		pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

	const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

	const handlerSimpleClose = () => props.setShow({ show: false });

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex">
						<span style={{ fontSize: "1.5rem" }}>
							Motorista {props?.motorista}
						</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{loading ? <Loading msg="Carregando" color="black" /> : ""}
					<Row style={{ justifyContent: "center", padding: "0" }}>
						{!isMobileFlag ? (
							<iframe src={pdfFileFull} style={{ height: "80vh" }}></iframe>
						) : (
							<div>Função indisponivel seu dispositivo!</div>
						)}
					</Row>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
}
