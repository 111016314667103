import { Modal, Spinner } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"


import './Modal.scss'


export default function ModalLoading({ msg, title, show, setShow, show_msg }) {

    const handlerSimpleClose = () => setShow(false)

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Body style={{
                    fontSize: '1.8rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {
                        show_msg ? <div>{msg}</div>:
                        <div>
                            {msg ? <h1 className="mb-5 text-center">{ msg } </h1>: "" }      
                            <Spinner color="black" size="md" animation="grow" style={{marginRight:'10px'}}/> Carregando... 
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
