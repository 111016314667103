import { useEffect, useState } from "react"
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { updateCaminhaoSelecionado, updateProdutosList, updateRomaneiosList } from "../../redux/slicers/user"
import { getAllData, verificaTipoUsuario } from "../../services/Servicos"

import './Modal.scss'
import carga, { removeAll, updateLogicaDeCarregamento } from "../../redux/slicers/carga"
import CarregarCaminhaoSeparador from "../carregamento/CarregarCaminhaoSeparador"


export default function ModalPlanejamentoCargaSeparador({ show, setShow, carga_id, transferencia, setTransferenciaClick }) {

    const dispatch = useDispatch()
    const user = useSelector(state => state.userSlice)
    const [caminhaoPlaca, setCaminhaoPlaca] = useState({
        flag: true,
        msg: 'Carregando',
        placa: null
    })
    
    const handlerSimpleClose = () => {
        dispatch(updateCaminhaoSelecionado({status: false}))
       
        setShow(last => ({
            ...last
            , flag: false
        })) 
        setTransferenciaClick(last => ({
            ...last
            , target: 0
            , style:{
                scale:'1'
                , zIndex:'1'
                // , transition: '0.5s'

            }
        }))
    }

    const getCargaTruck = async () => { 
        const url = user.tipo.toLowerCase() === 'empresa' ? 'cargas/transferencias' : 'gestao/cargas/transferencias'
        
        const cargasR = await getAllData(url, { id: carga_id })
        if (cargasR.data[0].tipo_transporte == 'FRETE') {
            setCaminhaoPlaca({ flag: false, msg: 'Carga Frete não precisa de Planejamento. Atualizando Transferencia ... ' })
            return
        }
        // if (cargasR.data[0].tipo_carga == 'TRANSFERÊNCIA') {
        //     setCaminhaoPlaca({ flag: false, msg: 'Carga de Transfe não precisa de Planejamento. Atualizando Transferencia ... ' })
        //     return
        // }

        if (!cargasR.data.length) return setCaminhaoPlaca({ flag: false, msg: 'Carga Inexistente' })
        if (!cargasR.data[0].caminhao) return setCaminhaoPlaca({ flag: false, msg: 'Caminhão não disponibilizado pela Empresa!' })

        setCaminhaoPlaca({
            flag: false,
            placa: cargasR.data[0].caminhao,
            carga: cargasR.data[0]
        })

        const caminhaoSelecionado = user.caminhoesList.data.find(item => item.placa == cargasR.data[0].caminhao)
        let caminhao = {}
        Object.keys(caminhaoSelecionado).forEach(key => {
            caminhao[`caminhao_${key}`] = caminhaoSelecionado[key]
        })
        
        dispatch(updateCaminhaoSelecionado({status: true, ...caminhao}))
    }
    const getRomaneiosEntrega = async () =>{
        const romaneiosGet =  await getAllData('gestao/planejamento/cargas/pedidos')
        let romaneiosList = romaneiosGet.data
        romaneiosList = romaneiosList.filter(item => item.cargas_id == carga_id)//.filter(item => item.status === 'AGUARDANDO ENTREGA')
        dispatch(updateRomaneiosList(romaneiosList))
    }

    useEffect(() => {
        
        dispatch(removeAll())
        dispatch(updateProdutosList([]))
        getRomaneiosEntrega()
        getCargaTruck()
    },[])

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Area de Planejamento -  Carga N° <i>{carga_id}</i>, Transferencia N° { transferencia }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !caminhaoPlaca.flag && caminhaoPlaca.placa ?
                            <CarregarCaminhaoSeparador caminhao_placa={caminhaoPlaca.placa} carga_id={carga_id} transferencia={transferencia} carga_selecionada={caminhaoPlaca.carga}/>
                            : <h1 className="text-center p-5">{ caminhaoPlaca.msg } { caminhaoPlaca.msg == 'Carregando'? <Spinner animation="border" size="sm"/>:"" }</h1>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Col sm='3'>
                        <Button  variant='primary' onClick={handlerSimpleClose}>Fechar</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>
    )
}
