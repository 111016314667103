import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { QRCodeSVG } from "qrcode.react";

import "./Modal.scss";

export default function ModalError({
	msg,
	title,
	show,
	setShow,
	receberNotificacoes,
	list = [],
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, flag: false }));

	// useEffect(() => {,
	//
	// }, [])
	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: "auto", margin: "0 20px" }}>
					{receberNotificacoes && (
						<div>
							<br />
							<br />
							<p>
								Compartilhe este QR Code para o recebimento das notificações
							</p>
							<p className="text-center">
								{" "}
								<QRCodeSVG
									value={
										"https://wa.me//5565998150052?text=Receber Notificações"
									}
								/>
							</p>
						</div>
					)}
					{
						<pre
							style={{
								fontSize: "1.2rem",
								fontFamily: "monospace",
								border: "solid 0.1px",
								padding: "10px",
							}}
						>
							{JSON.stringify(list || msg, null, 3)}
						</pre>
					}
				</Modal.Body>
				<Modal.Footer>
					<Col sm="6">
						<Button variant="secondary" onClick={handlerSimpleClose}>
							Fechar
						</Button>
					</Col>
				</Modal.Footer>
			</Modal>
		</>
	);
}
