import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../redux/slicers/user";
import { getData, verificaTipoUsuario } from "../../../services/Servicos";
import ModalAtribuirCaminhoeiro from "../../modal/ModalAtribuirCaminhoeiro";
import ModalAtualizarCaminhoes from "../../modal/ModalAtualizarCaminhoes";
import ModalError from "../../modal/ModalError";
import ModalAtribuido from "../../modal/ModalJaAtribuido";

import "./BodyRow.scss";
import { Spinner } from "react-bootstrap";
import ModalLancamentoItensEquipamentos from "../../frota/manutencao/ModalLancamentoItensEquipamentos";

export default function BodyRowCaminhoes({ headList, bodyList, index }) {
	const refRow = useRef(null);
	const refEditar = useRef(null);
	const refExcluir = useRef(null);
	const refAtribuir = useRef();
	const dispatch = useDispatch();
	const userS = useSelector((state) => state.userSlice);
	const filiaisList = userS.filiaisList.data;

	const [adminFunction, setAdminFunction] = useState({
		error: {
			flag: false,
			title: "",
			list: "",
			confirmar: "",
		},
	});
	const [atribuir, setAtribuir] = useState({
		flag: false,
		placa: "",
		returned: false,
	});
	const [jaAtribuido, setJaAtribuido] = useState({
		flag: false,
	});
	const [error, setError] = useState({
		flag: false,
		title: "",
		list: "",
	});
	const [loading, setLoading] = useState(false);

	const [equipamentos, setEquipamentos] = useState({
		flag: false,
	});

	const onClickEditarRow = (e) => {
		e.preventDefault();
		setAdminFunction({
			error: {
				flag: true,
				title: "Editar Caminhão",
				msg: {
					title: `Deseja Editar o caminhão:`,
					msg: {
						...refRow.current.children,
						pk: refRow.current.children[6].innerText,
					},
					body: bodyList,
				},
				confirmar: "Atualizar",
			},
		});
	};

	const onClickExcluirRow = (e) => {
		e.preventDefault();

		let ref1 = null,
			ref2 = null,
			ref3 = null;

		ref1 = refRow.current.children[3].innerText; //status
		ref2 = refRow.current.children[4].innerText; //tipo
		ref3 = refRow.current.children[6].innerText; //placa

		setAdminFunction({
			error: {
				flag: true,
				title: "Excluir Caminhão",
				msg: {
					title: `Deseja excluir o caminhão:`,
					msg: {
						TIPO: ref2,
						PLACA: ref3,
						STATUS: ref1,
					},
					body: bodyList,
				},
				confirmar: "Excluir",
			},
		});
	};

	const onClickAtribuir = (e) => {
		e.preventDefault();
		setLoading(true);
		const placa = bodyList.placa;
		const cargaAtual = userS.cargasList.data.find(
			(item) => item.caminhao === placa
		);
		//

		//verificar esta parte, por favor ! 10/07/2024
		if (
			cargaAtual &&
			[11, 12].includes(cargaAtual.status) &&
			cargaAtual.data_inicio_entrega
		) {
			setError({
				flag: true,
				title: "Aviso!",
				list: "NÃO É POSSÍVEL ATRELAR UM CAMINHÃO QUE JA ESTA EM VIAGEM! Finalize o Processo e tente novamente",
			});
			setLoading(false);
			return;
		}

		getData(verificaTipoUsuario(userS) + "caminhoes/caminhoneiros", placa, "")
			.then((resp) => {
				setLoading(false);
				const msgAlert = {
					id: resp.data.id_caminhoneiro,
					nome: resp.data.nome_caminhoneiro,
					cpf: resp.data.cpf_caminhoneiro,
					cnh: resp.data.cnh_caminhoneiro,
					telefone: resp.data.telefone_caminhoneiro,
				};
				if (!resp.data.id_caminhoneiro) {
					return setAtribuir({ flag: true, placa: placa });
				}

				setJaAtribuido((last) => ({
					...last,
					placa: placa,
					flag: true,
					msg: msgAlert,
				}));
			})
			.catch((error) => {
				setLoading(false);
				if (error.msg === "Unauthorized") {
					// alert('Session has Expired')
					dispatch(update({ authenticated: false, token: "" }));
					return window.location.replace("/login");
				}

				setAtribuir({ flag: true, placa: placa });
			});
	};

	useEffect(() => {
		if (jaAtribuido.returned) {
			setAtribuir({ flag: true, placa: jaAtribuido.placa });
		}
	}, [jaAtribuido]);
	return (
		<Fragment>
			{
				<tr ref={refRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Excluir"
												onClick={onClickExcluirRow}
												ref={refExcluir}
											></i>
										</span>
										<span className="editar">
											<i
												className="bi bi-pencil"
												title="Editar"
												onClick={onClickEditarRow}
												ref={refEditar}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "placa") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "id_filial") {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "status") {
							return bodyList[item] ? (
								<td key={_index} className={item}>
									{bodyList[item] == 1 ? (
										<div className="div-status">
											<span className="div-status-ativo">Ativo</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 2 ? (
										<div className="div-status">
											<span className="div-status-manutencao">Manutenção</span>
										</div>
									) : (
										""
									)}
								</td>
							) : (
								<td key={_index} className={item}>
									<div className="div-status">
										<span className="div-status-inativo">Inativo</span>
									</div>
								</td>
							);
						} else if (item === "createdAt" || item === "updatedAt") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "10rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "Atribuir") {
							return (
								<td key={_index}>
									<div className="admin">
										{/* <span className="excluir"><i className="bi bi-x-lg" title="Excluir" onClick={onClickExcluirRow} ref={refExcluir}></i></span> */}
										<span className="editar">
											{loading ? (
												<Spinner size="sm" color="black" animation="border" />
											) : (
												<i
													className={"bi bi-plus-circle"}
													title="Atribuir Caminhão a Caminhoneiro"
													onClick={onClickAtribuir}
													ref={refAtribuir}
												></i>
											)}
										</span>
									</div>
								</td>
							);
						} else if (item === "equipamentos") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="editar">
											<i
												className={"bi bi-plus-circle"}
												title="Atribuir Caminhão a Caminhoneiro"
												onClick={() =>
													setEquipamentos({
														flag: true,
														bodyList,
														title: "Lista de Equipamentos",
													})
												}
												ref={refAtribuir}
											></i>
										</span>
										<span> {bodyList[item] || 0}</span>
									</div>
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{adminFunction.error.flag ? (
				<ModalAtualizarCaminhoes
					title={adminFunction.error.title}
					msg={adminFunction.error.msg}
					show={adminFunction}
					setShow={setAdminFunction}
					msgButton={adminFunction.error.confirmar}
				/>
			) : (
				""
			)}
			{atribuir.flag ? (
				<ModalAtribuirCaminhoeiro
					placa={atribuir.placa}
					show={atribuir.flag}
					setShow={setAtribuir}
					setError={setError}
				/>
			) : (
				""
			)}
			{jaAtribuido.flag ? (
				<ModalAtribuido
					show={jaAtribuido.flag}
					setShow={setJaAtribuido}
					msgAlert={jaAtribuido.msg}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					show={error.flag}
					setShow={setError}
					msg={error.msg}
					title={error.title}
					{...error}
				/>
			) : (
				""
			)}

			{equipamentos.flag ? (
				<ModalLancamentoItensEquipamentos
					show={equipamentos.flag}
					setShow={setEquipamentos}
					msg={equipamentos.msg}
					{...equipamentos}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
