import { Fragment, createRef, useEffect, useLayoutEffect, useState } from "react"
import { Col, Row, Card, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { updatePontosList } from "../../redux/slicers/user"
import { getAllData, getDateInitMonth, locationDev } from "../../services/Servicos"
import CardLayoutNavbar from "../card/CardLayoutNavbar"
import Loading from "../loading/Loading"
import BodyRowUsuarios from "../table/body/BodyRowUsuarios"
import './ControlePonto.scss'
import ScrollbarLeft from "../scrollbars/ScrollbarLeft"
import HeadTable from "../table/head/HeadRow"
import { filtroDinamicoArrays, removerDadosDuplicados } from "../../services/helpers"
import ExportToExcel from "../exportToExcel/ExportToExcel"

import semFoto from '../../assets/perfil_sem_foto.jpg'
import BodyRow from "../table/body/BodyRow"

const headList = ['index', 'nome', 'cpf', 'ent_sai', 'dia_semana', 'ponto_data', 'ponto_hora', 'ponto', 'ajuste']
const tableRef = createRef()
const tbodyRef = createRef()

let lastFilter = ''




export default function ConsultarPontos() {
    const user = useSelector(state => state.userSlice)
    const pontos = useSelector(state => state.userSlice.pontosList)
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [indexList, setIndexList] = useState(20)
    const [filteredTable, setFilteredTable] = useState(false)
    const [filters, setFilters] = useState([])

    useEffect(() => {
        const refTable = tableRef.current
        const scrollHandler = (e) => {
            const tbodyHeight = tbodyRef.current.clientHeight
            const divTableHeight = e.target.clientHeight
            const scrollPosition = e.target.scrollTop
            if (scrollPosition >= tbodyHeight - divTableHeight) {
                setTimeout(() => {
                    setIndexList(last => last + 10)
                }, 1000)
            }

        }

        refTable.addEventListener('scroll', scrollHandler, false)

        return () => refTable.removeEventListener('scroll', scrollHandler, false)
    })


    const onClickRemoveFilter = (e) => {
        e.preventDefault()
        const value = e.target.parentNode.firstElementChild.innerText
        const [key, keyValue] = value.split(':')
        setFilters(last => ([...last.filter(item => String(item[key]).replaceAll(' ','') !== keyValue.replaceAll(' ',''))]))

    }


    const updateArrayPontos = async (data_inicial, data_final) => {
        let route = ''
        if (user.tipo.toLowerCase() === 'empresa') {
            route = 'empresas/controle-ponto'
        } else {
            route = 'funcionarios/controle-ponto'
            
        }
        const pontos = await getAllData(route, { data_inicial, data_final })
        dispatch(updatePontosList(pontos.data))
    }

    useEffect(() => {
        const date = new Date()
        const dateCorrect = getDateInitMonth(date)

        updateArrayPontos(dateCorrect.data_inicial, dateCorrect.data_final)
    },[])

    const [filtersNew, setFiltersNew] = useState({})
    useEffect(() => {
        if(!filters.length){
            setIndexList(20)
            setFilteredTable(false)
            return
        }

        let testeFilters = {}

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            testeFilters[param] = []
        })

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            // testeFilters[param].push(ele[param])
            testeFilters[param].push(String(ele[param]).toLowerCase())
            
            if (String(ele[param]).toLowerCase() == 'false') {
                testeFilters[param].push('null')
                
            }
        })

        
        let arrayRetorno = [pontos.data]

        Object.keys(testeFilters).forEach((key, index) => {
            arrayRetorno.push(filtroDinamicoArrays(testeFilters, key, arrayRetorno[arrayRetorno.length - 1], ))
        })

        const arrayF = removerDadosDuplicados(arrayRetorno[arrayRetorno.length - 1], 'id')
        setFilteredTable(arrayF.reverse())

    }, [filters])

    return (
        <CardLayoutNavbar>
            {loading ? <h1>Carregando...</h1> : ""}

            {/* <ReportUsuarios dados={[...pontos.data]}/> */}
            <div className="section-filters-table">
                <h3>Filtrar Pontos</h3>
                <hr />
                    
                <Row className='mt-2 row-filters'>
                    <Col>
                        <div>
                            {
                                filters.map((item, key) =>
                                    Object
                                        .keys(item)
                                        .map((key, index) => {
                                            return <span className="filters-card" key={index}>
                                                <span className='filters'>
                                                    <span className='title'>
                                                        {key}: {item[key]}
                                                    </span>
                                                    <i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
                                                </span>
                                            </span>
                                        })
                                )
                            }

                        </div>
                    </Col>
                </Row>
            </div>
            <div className="section-filters-table">
                <Row className='justify-content-between'>
                    <Col sm='12' md='6' lg='8'>
                        <h3 style={{display: 'flex',alignItems:'center'}}>Tabela de Dados</h3>
                    </Col>
                    <Col sm='12' md='6' lg='2'>
                        <ExportToExcel tableName="Pontos" paramExport={['funcionarios_id', 'nome', 'cpf', 'tipo', 'ent_sai', 'dia_semana', 'ponto_data', 'ponto_hora', 'ajuste','dia','mes','ano','hora','minuto']} reference={filteredTable.length ? filteredTable : [...pontos.data]}/>
                    </Col>
                </Row>
                {/* <h3></h3>
                <h3 style={{ display: 'flex', alignItems: 'center' }}> {loading ? <Spinner style={{ marginLeft: '10px', fontSize: '1rem' }} animation="border" size="sm" /> : ""}</h3> */}

                <hr />
                <div className=" responsive-table" ref={tableRef} style={{position: 'relative',height:'600px', maxHeight:'unset'}}>
                <ScrollbarLeft tableRef={tableRef} coluna2={4} maxima_distancia={180}/>
                    <Table hover size='sm'>
                        <thead style={{zIndex:49}}>
                            <HeadTable rota ='caminhoes' arrayValues={ pontos.data } headerList={headList} setArrayFilters={setFiltersNew} arrayFilters={filtersNew} setFiltersOld={setFilters} filtersOld={filters} fn={updateArrayPontos}/>
                        </thead>
                    <tbody ref={tbodyRef}>

                            {
                                filteredTable ?
                                    filteredTable
                                        .sort((a, b) => {
                                            if (a.ponto_data > b.ponto_data) return -1
                                            if (a.ponto_data < b.ponto_data) return 1
                                            return 0
                                        })
                                        .map((item, index) => {
                                        if (index < indexList) {
                                            return <BodyRow key={index} bodyList={item} headList={headList} index={index} />
                                        }

                                    })
                                    :
                                    [...pontos.data]
                                        .sort((a, b) => {
                                            if (a.ponto_data > b.ponto_data) return -1
                                            if (a.ponto_data < b.ponto_data) return 1
                                            return 0
                                        })
                                        .map((item, index) => {
                                        if (index < indexList) {
                                            return <BodyRow key={index} bodyList={item} headList={headList} index={index} />
                                        }

                                    })
                            }

                        </tbody>
                    </Table>
                    {/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
                    {
                        filteredTable ?
                            indexList < filteredTable.length ? <Loading color={'black'} msg='Loading' /> : ""
                            :
                            indexList < pontos.data.length ? <Loading color={'black'} msg='Loading' /> : ""
                    }
                </div>
            </div>
            
        </CardLayoutNavbar>
    )
}


function ReportUsuarios({ dados }) {
    const usuarios = useSelector(state => state.userSlice.funcionariosList.data)
    
    return (
        <Fragment>
            <Row style={{flexWrap: 'nowrap', overflow:'auto', width:'100%'}}>
                {
                    usuarios.map((i, k) => {
                        return (
                            <Col sm={12} md={3} key={k}>
                                <Card style={{maxWidth:'18rem'}}>
                                    {
                                        i.foto_perfil ? 
                                        <Card.Img  variant='left' src={locationDev + '/files/users/'+ i.foto_perfil} alt='Imagem Perfil' />
                                        :
                                        <Card.Img  variant='left' src={semFoto} alt='Sem Imagem Perfil' />
                                    }
                                    <Card.Body>
                                        <Card.Title className="image-visualizar">{i.name}</Card.Title>
                                        {/* <Button variant='primary' onClick={ e => showImageCard(e, i.foto_perfil,'Foto do Perfil')} className="mt-2">Editar</Button> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                        )
                    })
                }
            </Row>
        </Fragment>
    )
}