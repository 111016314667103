import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { update, updateManutencoesList } from "../../../redux/slicers/user"
import { getAllData, getData, updateTable, updateTableNew, valorMoeda, verificaTipoUsuario } from "../../../services/Servicos"
import ModalAtribuirCaminhoeiro from "../../modal/ModalAtribuirCaminhoeiro"
import ModalAtualizarCaminhoes from "../../modal/ModalAtualizarCaminhoes"
import ModalError from "../../modal/ModalError"
import ModalAtribuido from "../../modal/ModalJaAtribuido"

import './BodyRow.scss'
import { Spinner } from "react-bootstrap"
import ModalCancelamento from "../../frota/manutencao/ModalCancelamento"
import ModalLancamentoItens from "../../frota/manutencao/ModalLancamentoItens"
import ModalEdicao from "../../frota/manutencao/ModalEdicao"
import ModalControleRotacaoPneus from "../../frota/manutencao/ModalControleRotacaoPneus"
import ModalErrorPretty from "../../modal/ModalErrorPretty"

export default function BodyRowManutencoes({ headList, bodyList, index }) {
    const refRow = useRef(null)
    const refExcluir = useRef(null)
    const dispatch = useDispatch()
    const userS = useSelector(state => state.userSlice)
    const id_filial = userS.tipo.toLowerCase() == 'empresa' ? 2 : userS.id_filial
    const filiaisList = userS.filiaisList.data
    const caminhoes = userS.caminhoesList.data

    const [controleCancelamento, setControleCancelamento] = useState({falg: false})
    const [controleEdicao, setControleEdicao] = useState({falg: false})
    const [controleEdicaoItensManutencao, setControleEdicaoItensManutencao] = useState({ falg: false })
    const [controleRotacaoPneus, setControleRotacaoPneus] = useState({ falg: false })

    const [atribuir, setAtribuir] = useState({
        flag: false,
        placa:'',
        returned:false
    })
    const [jaAtribuido, setJaAtribuido] = useState({
        flag: false,
    })
    const [error, setError] = useState({
        show: false,
        title: '',
        message: '',
    })
    const [loading, setLoading] = useState(false)



    const getData = useCallback(async (data_inicial, data_final) => {
        
        const url = 'manutencao'
        try {
            const data = await getAllData(url, {id_filial})
            dispatch(updateManutencoesList(data.data))

        } catch (error) {
            setError({
                show: true, message: error.message, title:'Aviso!'
            })
        }
    })

    const onClickCancelItem = async () => {
        
        const url = 'manutencao'
        await updateTableNew(`${url}?id=${bodyList.id}`, {data_cancelamento: new Date(), user_cancelamento: userS.name, status: -1}) // cancelado
        getData()
    }

    const onClickEditar = async (bodyUpdate) => {
        
        const url = 'manutencao'
        await updateTableNew(`${url}?id=${bodyList.id}`, bodyUpdate)
        getData()
    }

    


    useEffect(() => {
        if(jaAtribuido.returned){
            setAtribuir({flag: true, placa: jaAtribuido.placa})
        }
    }, [jaAtribuido])
    return (
        <Fragment>
            {
                <tr ref={refRow} style={{verticalAlign:'middle'}}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            }
                            else if (item === 'tipo') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span
                                            style={{
                                                fontSize: '0.8rem', padding: '7px 15px', borderRadius: '10px',
                                                backgroundColor: bodyList.tipo == 'CORRETIVA' ? "#ff0000bf" : ( bodyList.tipo == 'PREVENTIVA' ? '#b7d972':"#72a8d9")
                                            }}>{bodyList[item]}
                                        </span>
                                    </div>
                                </td>
                            }
                            else if (item === 'admin') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span className="excluir">
                                            <i className="bi bi-x-lg"
                                                title="Excluir"
                                                onClick={() => setControleCancelamento({ bodyList, flag: true, title: 'Cancelar Manutencao', fn: onClickCancelItem })}>
                                            </i>
                                        </span>
                                        <span className="editar"  hidden={bodyList.status == '2'}>
                                            <i className="bi bi-pencil"
                                                title="Editar"
                                                onClick={() => setControleEdicao({ bodyList, flag: true, title: 'Editar Manutencao', fn: onClickEditar })}>
                                            </i>
                                        </span>
                                    </div>
                                </td>
                            }
                            else if (item === 'id') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            }
                            else if (item === 'placa') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            }
                            else if (item === 'id_filial') { /* primary key can not be modified */

                                return <td key={_index}>{filiaisList.filter(item => item.idFilial == bodyList.id_filial)[0]?.identificacaoInterna ?? 'NÃO INFORMADO'}</td>

                            }
                            else if (item === 'status') {

                                return <td key={_index} className={item}>
                                    {
                                        bodyList[item] == 2 ? 
                                            <div className="div-status">
                                                <span className="div-status-ativo bg-success">Finalizado</span>
                                            </div>
                                        :
                                            ''
                                    }
                                    {
                                        bodyList[item] == 1 ? 
                                            <div className="div-status">
                                                <span className="div-status-ativo bg-warning">Em Manutencao</span>
                                            </div>
                                        :
                                            ''
                                    }
                                    {
                                        bodyList[item] == 0 ? 
                                            <div className="div-status">
                                                <span className="div-status-ativo bg-info">Agendado</span>
                                            </div>
                                        :
                                            ''
                                    }
                                    </td>

                            }
                            else if (item === 'createdAt' || item === 'updatedAt') { /* primary key can not be modified */

                                return <td key={_index} style={{minWidth:'10rem'}}>{bodyList[item]}</td>

                            }
                            else if (item === 'observacoes') { /* primary key can not be modified */

                                return <td key={_index} style={{ minWidth: '10rem', cursor:'pointer' }}>
                                    <textarea value={bodyList[item] ?? 'N/A'}></textarea>
                                </td>

                            }
                            else if (item.includes('km')) {
                                return <td key={_index}>
                                    <div className="admin">
                                        {Number(bodyList[item]).toLocaleString('pt-br', {minimumFractionDigits: 2})}
                                    </div>
                                </td>
 
                            }
                            else if (item === 'valor') {
                                return <td key={_index}>
                                    <div className="admin">
                                        {valorMoeda(bodyList[item])}
                                    </div>
                                </td>
 
                            }
                            else if (item === 'itens') {
                                return <td key={_index}>
                                     <div className="admin">
                                        <span className="editar"> <i className="bi bi-plus-circle" title="Controle de Itens"
                                            onClick={() => bodyList.tipo == 'ROTAÇÃO DE PNEUS'? setControleRotacaoPneus({ flag: true, caminhao:  caminhoes.find(item => item.placa == bodyList.placa), bodyList}) : setControleEdicaoItensManutencao({ bodyList, flag: true, title: 'Adicionar Itens de Manutencao' })}></i></span>
                                        <span> {bodyList[item]}</span>
                                    </div>
                                </td>
 
                            }
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            
            {controleEdicaoItensManutencao.flag ? <ModalLancamentoItens {...controleEdicaoItensManutencao} setShow={ setControleEdicaoItensManutencao } /> : ''}
            {controleCancelamento.flag ? <ModalCancelamento {...controleCancelamento} setShow={ setControleCancelamento } /> : ''}
            {controleEdicao.flag ? <ModalEdicao {...controleEdicao} setShow={setControleEdicao} /> : ''}
            {controleRotacaoPneus.flag ? <ModalControleRotacaoPneus {...controleRotacaoPneus} setShow={ setControleRotacaoPneus } /> : ''}
            
            {atribuir.flag ? <ModalAtribuirCaminhoeiro  placa={atribuir.placa} show={atribuir.flag} setShow={setAtribuir} setError={setError}/> : ""}
            {jaAtribuido.flag ? <ModalAtribuido show={jaAtribuido.flag} setShow={setJaAtribuido} msgAlert={jaAtribuido.msg}/> : ""}
            {error.show ? <ModalErrorPretty setShow={setError} {...error}/> : ""}
        </Fragment>
    )
}