import { Fragment, useRef, useState } from "react"
import { Card } from "react-bootstrap"
import { useSelector } from "react-redux"
import ModalAtualizarUsuarios from "../../modal/ModalAtualizarUsuarios"

import './BodyRow.scss'
import { valorMoeda, locationDev, correctDataUTC } from "../../../services/Servicos"
import ModalFoto from "../../modal/ModalFoto"

export default function BodyRowAbastecimento({ headList, bodyList, index, setEditarAbastecimento = () => {}, tableLength}) {
    const caminhoneiros = useSelector(state => state.userSlice.funcionariosList).data.filter(item => item.tipo === 'Caminhoneiro').map(item => item.cpf)

   const refRow = useRef(null)
    const refEditar = useRef(null)
    const user = useSelector(state => state.userSlice)
    const filiaisList = user.filiaisList.data 

    const [adminFunction, setAdminFunction] = useState({
        flag: false,
        imgSrc: ''
    })
    const onClickEditarRow = (e) => {
        setEditarAbastecimento({
            flag: true,
            body: bodyList,
            title: 'Editar Abastecimento N°' + bodyList.id,
        })
    }

    const onClickTableRow = (e) => {
        e.preventDefault()
        refRow.current.classList = 'bg-selected'
        let refRowNumber = index

        let i = refRowNumber
        let actualSibling = refRow.current.nextSibling

        while(i < tableLength){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            
            actualSibling = actualSibling.nextSibling
            i++
        }

        i =  index
        actualSibling = refRow.current.previousSibling
        while(0 < i ){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            actualSibling = actualSibling.previousSibling
            i--
        }     
        
        
    }


    return (
        <Fragment>
            {
                <tr ref={refRow}  onClick={onClickTableRow}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            }
                            else if (item === 'suporte') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span className="editar"><i className="bi bi-gear" title="Editar" onClick={onClickEditarRow} ref={refEditar}></i></span>
                                    </div>
                                </td>
                            }
                                
                            else if (item === 'admin') {

                                return <td key={_index}>
                                    {
                                        (!bodyList.cargas_id || !bodyList.cargas_id_chegada) && bodyList.tipo_maquina == 'CAMINHAO' && bodyList.tipo_lancamento == 'LANÇAMENTO PADRÃO'? 
                                            <div className="admin">
                                                <span className="editar"><i className="bi bi-gear" title="Editar" onClick={onClickEditarRow} ref={refEditar}></i></span>
                                            </div>
                                            :
                                            <div className="admin">
                                                <span className="editar"><i className="bi bi-bookmark-check-fill" title="Editar" style={{color:"#04c704c7"}}></i></span>
                                            </div>
                                    }
                                </td>
                            } else if (item === 'id') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            } else if (item === 'placa') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            } 
                            else if (item === 'status') {

                                return bodyList[item] ?
                                    <td key={_index} className={item}>
                                        <div className="div-status">
                                            <span className="div-status-ativo">Ativo</span>
                                        </div>
                                    </td> : <td key={_index} className={item}>
                                        <div className="div-status">
                                            <span className="div-status-inativo">Inativo</span>
                                        </div>
                                    </td>

                            } 
                            else if (item.includes('valor')) { /* primary key can not be modified */

                                return <td key={_index} style={{minWidth:'10rem'}}>{valorMoeda(bodyList[item])}</td>

                            }
                            else if (item === 'data') { /* primary key can not be modified */

                                return <td key={_index} style={{minWidth:'10rem'}}>{bodyList[item]}</td>

                            }
                            else if (item === 'horimetro') { /* primary key can not be modified */

                                return <td key={_index} style={{ minWidth: '100px' }}>
                                    <Card.Img
                                        variant='top'
                                        src={`${locationDev}/files/users/abastecimentos/${bodyList.caminhao}/${bodyList[item]}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={e => setAdminFunction({flag: true, imgSrc: `${locationDev}/files/users/abastecimentos/${bodyList.caminhao}/${bodyList[item]}`})}
                                    />
                                </td>

                            }
                            else if (item === 'litros_abastecidos' || item === 'ultimo_km') { /* primary key can not be modified */

                                return <td key={_index} className={item}> {Number(bodyList[item]).toLocaleString('pt-br')}</td>

                            }
                            else if (item === 'emissao') { /* primary key can not be modified */

                                return <td key={_index} className={item}> {correctDataUTC(bodyList.emissao)}</td>

                            }
                            else if (item === 'id_filial') {
                                return <td key={_index}>{filiaisList.filter(item => item.idFilial == bodyList.id_filial)[0]?.identificacaoInterna ?? 'NÃO INFORMADO'}</td>
                            } 
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            {/* {adminFunction.error.flag ? <ModalAtualizarUsuarios title={adminFunction.error.title} msg={adminFunction.error.msg} show={adminFunction} setShow={setAdminFunction} msgButton={adminFunction.error.confirmar} /> : ""} */}
            {
                adminFunction.flag ? 
                    <ModalFoto 
                        imgSrc={adminFunction.imgSrc}  
                        setShow={setAdminFunction} 
                        show={adminFunction.flag} 
                        alternativo={'Foto do Horimetro'}
                        />
                    : ""
            }
        </Fragment>
    )
}