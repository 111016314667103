import { createRef, Fragment, useEffect, useState, useRef } from "react"
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { getAllData, getData } from "../../services/Servicos"
import Loading from "../loading/Loading"

import './Modal.scss'
import { addProduto, updateIdSelected } from "../../redux/slicers/carga"
import ModalErrorCarregamento from "./ModalErrorCarregameto"
import ModalErrorTotal from "./ModalErrorTotal"
import { updateEmbalagensList, updateProdutosList } from "../../redux/slicers/user"
import ModalListarProdutosEmbalados from "./ModalListarProdutosEmbalados"
import ScrollbarLeft from "../scrollbars/ScrollbarLeft"
import ModalLerQRCode from "./ModalLerQRCode";
import { QRCodeSVG } from "qrcode.react"


const tableRef = createRef()
const tbodyRef = createRef()
const headList = [
    'index',
    'descarregado',
    'cargas_id',
    'tipo',
    'numero_pedido/embalagem',
    'codigo',
    'descricao',
    'altura',
    'largura',
    'comprimento',
    'volume',
    'peso',
    // 'qr_code_id_teste',
    // 'pos_x',
    // 'pos_y',
    // 'pos_z',
    // 'id',
    // 'qr_sku',
    // 'step_planejado', 
]

const headListProdutos =[
    'index'
    , 'descarregado'
    , 'numero_pedido'
    , 'codigo'
    , 'descricao'
    , 'qtde'
    , 'comprimento'
    , 'altura'
    , 'largura'
    , 'volume'
    , 'peso'
    , 'abreviatura'
    , 'qr_sku'
    , 'numero_item_embalagem'
    // , 'numero_embalagem'
]


export default function ModalGetDescarregamento({ show, setShow, setError, placaCaminhao, cargas_id, setIdSelected}) {
    
    // const cofirmarPlanejamento = useState(state => state.userSlice.confirmar_planejamento)
    const user = useSelector(state => state.userSlice)
    const produtosRomaneio = useSelector(state => state.userSlice.produtosList)
    const embalagens = useSelector(state => state.userSlice.embalagensList)
	const produtosEEmbalagens = user.produtosEEmbalagens.data;
    const dispatch = useDispatch()
    const [romaneios, setRomaneios] = useState([])
    const [indexList, setIndexList] = useState(10)
    const [loading, setLoading] = useState(false)

    const handlerSimpleClose = () => setShow(false)
    const [produtoSelecionado, setProdutoSelecionado] = useState({})

    
    const [errorP, setErrorP] = useState({
        flag: false,
        msg:'',
    })
    const [errorP2, setErrorP2] = useState({
        flag: false,
        msg:'',
        title:'',
    })
    

    /* upload das embalagens */
    // useEffect(() =>{
    //     if(embalagens.data.length) return
    //     getAllData('cargas/funcionarios/embalagens')
    //         .then(response => {
    //            dispatch(updateEmbalagensList(response.data.filter(item => item.cargas_id === cargas_id)))
    //        })
    //        .catch(error => {
    //            setError({
    //                msg: 'Error na obtenção das embalagens existentes.' + JSON.stringify(error),
    //                flag: true
    //            })
    //        })
    // }, [])
    
    
    // const [produtosEEmbalagens, setProdutosEEmbalagens] = useState([])
    // useEffect(() =>{
    //     // updateArrayProdutos()

    //     const produtosH = [...produtosRomaneio.data.filter(item => !item.embalado)].map(item => ({...item, tipo: 'PRODUTO'}))
    //     const embalagemsH = [...embalagens.data.filter(item => item.cargas_id === cargas_id)].map(item => ({...item, tipo: 'EMBALAGEM'}))
    //     let produtosEEmbalagensL = [ ...produtosH, ...embalagemsH]
        
    //     setProdutosEEmbalagens(produtosEEmbalagensL.sort((a, b) => a.step_planejado - b.step_planejado).reverse())
    //     setLoading(false)

    // }, [])
    

    const [modalQrCode, setModalQrCode] = useState({flag: false})
    
    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Selecionar Produto: {placaCaminhao}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="section-filters-table">
                    <div className="card-header">
                        <h5>Itens da Carga {loading ? <Spinner style={{marginLeft:'10px', fontSize:'1rem'}}animation="border" size="sm" /> : ""}</h5>
                    </div>
                        
                        <ScrollbarLeft tableRef={tableRef} coluna2={4} maxima_distancia={150}/>
                    <div className=" responsive-table" ref={tableRef} style={{position: 'relative', height:'300px'}}>
                        <Table hover size='sm'>
                            <thead>
                                <tr>
                                    {headList.map((item, index) => {
                                        if(item === 'index'){
                                            return <th key={index}>#</th>
                                        }
                                        else if(item.toUpperCase() === 'CREATED_AT'){
                                            return <th key={index}>DATA DE CRIAÇÃO</th>
                                        }  
                                        else if(item.toUpperCase() === 'UPDATED_AT'){
                                            return <th  key={index}>ULTIMA ATUALIZAÇÃO</th>
                                        }  
                                        return <th key={index}>{item.toUpperCase()}</th>
                                    })} 
                                </tr>
                            </thead>
                            <tbody ref={tbodyRef} >

                                {/* {
                                    produtosRomaneio.data.filter(item => !item.embalado).map((item, index, self) => {
                                        if(index < indexList){
                                            return <BodyRowProdutoCarregamento key={index} bodyList={item} index={index} tableLength={self.length} setProdutoSelecionado={setProdutoSelecionado} setError={setErrorP2}/>
                                        }

                                    })
                                } */}
                                {
                                    produtosEEmbalagens.filter(item => !item.embalado).map((item, index) => {
                                        // if(index < indexList){
                                            return <BodyRowProdutoCarregamento key={index} bodyList={item} index={index} tableLength={produtosEEmbalagens.length} setProdutoSelecionado={setProdutoSelecionado} setError={setErrorP2} produtoSelecionado={produtoSelecionado}/>
                                        // }

                                    })
                                }

                            </tbody>
                        </Table>
                        {
                            indexList < romaneios.length ? <Loading color={'black'} msg='Loading'/>:""
                        }
                    </div>
                </div>

                
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{ width: '100%', justifyContent:'flex-end'}}>
                        
                        {/* <Col>
                            <Button variant='warning' onClick={() => setModalQrCode({flag: true, title:"Identificar Produto"})} >Identificar Produto</Button>
                        </Col>
                        <Col>
                            <Button variant='primary' onClick={onClickDescarregar} disabled={produtoSelecionado.qr_sku ? false : true}>Descarregar</Button>
                        </Col> */}
                        <Col sm='4'>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            {/* {error.flag ? <ModalError msg={error.msg} title={error.title} show={error} setShow={setError}/> : ""} */}
            {errorP.flag ? <ModalErrorCarregamento msg={errorP.msg} title={'Erro ao Finalizar Carregamento'} show={errorP.flag} setShow={setErrorP}/>: ""}
            {errorP2.flag ? <ModalErrorTotal msg={errorP2.msg} title={errorP2.title} show={errorP2.flag} setShow={setErrorP2}/>: ""}
            {modalQrCode.flag ? <ModalLerQRCode msg={modalQrCode.msg} title={modalQrCode.title} show={modalQrCode.flag} setShow={setModalQrCode} setProdutoSelecionado={setProdutoSelecionado}/>: ""}

        </>
    )
}


function BodyRowProdutoCarregamento({index, bodyList, tableLength, setProdutoSelecionado = () => {}, setRomaneioSelected = () => {}, produtoSelecionado}){
    const refRow = useRef()
    const refRowCheck = useRef(null)
    const produtosRomaneio = useSelector(state => state.userSlice.produtosList)
    const [verProdutos, setVerProdutos] = useState({
        flag: false,
        produtosDaEmbalagem:[]
    })
    
    const onClickProdutos = (e, numero_embalagem) =>{
        const produtosDaEmbalagem = produtosRomaneio.data.filter(item => item.numero_embalagem === numero_embalagem)
        setVerProdutos(last => ({...last, flag: true, produtosDaEmbalagem, numero_embalagem}))
    }
    
    useEffect(() => {
        if (!produtoSelecionado.qr_sku) return
        if (bodyList.qr_sku === produtoSelecionado.qr_sku) {
            if(produtoSelecionado.descarregado){
                refRow.current.classList = 'bg-selected-danger'
                setProdutoSelecionado({})
                return
    
            }else{
                refRow.current.classList = 'bg-selected'
            }
        } else {
            refRow.current.classList = ''
        }
    }, [produtoSelecionado])

    return(
        <Fragment>
            {
                <tr ref={refRow}>
                    {
                        headList.map((item, _index) => {
                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            }
                            else if (item === 'planejado') {
                                return <td key={_index} className="text-center">
                                    <Form.Check type='checkbox' disabled ref={refRowCheck} checked={bodyList?.planejado ?? false} key={index} />
                                </td>
                                
                            }
                            else if (item === 'descarregado') {
                                
                                return <td key={_index} className="text-center">
                                    <Form.Check type='checkbox' disabled ref={refRowCheck} checked={bodyList?.descarregado ?? false} key={index} />
                                </td>
                                
                            }
                            else if (item === 'descricao') {
                                return <td key={_index} className={item} style={{ minWidth: '500px' }}> {bodyList[item]}</td>
                                
                            }
                            else if (item === 'DTEMISSAO') {

                                return bodyList[item] ? <td key={_index}> {new Date(bodyList[item]).toLocaleString()}</td> : <td key={_index}></td>
                            }                            
                            else if (item.toLowerCase() === 'numero_pedido/embalagem') {
                                if (bodyList.tipo !== 'PRODUTO') { 
                                    return <td key={_index}>
                                    <div className="d-flex justify-content-between">
                                        <span className="verificar" style={{ fontSize: 'inherit', color: 'inherit' }}>{String(bodyList.numero_embalagem).padStart(7, 0)}</span>
                                        <span className="verificar" style={{ fontSize: 'inherit', color: 'inherit', cursor: 'pointer' }}>
                                            <i className="bi bi-plus-circle bi-circle-animation" title="Ver Produtos" onClick={e => { onClickProdutos(e, bodyList.numero_embalagem) }}></i>
                                        </span>
                                    </div>
                                </td>
                                }
                                return <td key={_index} className={item} > {bodyList.numero_pedido}</td>
                            }
                            
                            else if (item === 'qr_code_id_teste') {
                                return <td key={_index} className='rowDataWidth'>
                                    <QRCodeSVG value={"{\n" + bodyList.qr_sku + "}"} />
                                </td>
                                
                            }  

                            return <td key={_index} className={item} style={{minWidth:'100px'}}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            {
                verProdutos.flag ? 
                <ModalListarProdutosEmbalados 
                    setShow={setVerProdutos} 
                    show={verProdutos.flag} 
                    produtos={verProdutos.produtosDaEmbalagem}
                    headList={headListProdutos} 
                    {...verProdutos}
                 /> :""
            }
        </Fragment>
    )
}
