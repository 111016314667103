import { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { utils, writeFile } from "xlsx";
import { valorMoeda } from "../../services/Servicos";
import { useSelector } from "react-redux";
import "./ExportToExcel.scss";

export default function ExportToExcel({
	reference = [],
	tableName = "tabela",
	paramExport = [],
}) {
	const filiais = useSelector((state) => state.userSlice.filiaisList.data);
	const funcionarios = useSelector(
		(state) => state.userSlice.funcionariosList.data
	);
	const [loadingExport, setLoadingExport] = useState({
		flag: false,
		msg: "Download",
		style: "primary",
	});

	const exportTxtEspecial = useCallback(() => {
		setLoadingExport({
			flag: true,
			msg: "Carregando...",
			style: "warning",
		});
		let arrayColumns = [...reference];
		if (paramExport.length) {
			arrayColumns = [];
			reference.map((item) => {
				let objectColumns = {};
				paramExport.map((colum) => {
					objectColumns[colum] = item[colum];
				});
				arrayColumns.push(objectColumns);
			});
		}
		setTimeout(() => {
			const textToExport = [];
			arrayColumns.map((item) => {
				let obj = { ...item };

				const pis =
					funcionarios.find((funcionario) => funcionario.cpf == obj.cpf)?.pis ||
					"N/A";
				textToExport.push(
					`${String(obj.dia).padStart(2, 0)}/${String(obj.mes).padStart(
						2,
						0
					)}/${obj.ano} ${String(obj.hora).padStart(2, 0)}:${String(
						obj.minuto
					).padStart(2, 0)} ${pis.replace(/\D/g, "")}\r`
				);
			});

			const element = document.createElement("a");
			const file = new Blob(textToExport, { type: "text/plain" });
			element.href = URL.createObjectURL(file);
			element.download = `${tableName}.txt`;
			element.click();

			setLoadingExport({
				flag: false,
				msg: "Concluido",
				style: "success",
			});

			setTimeout(() => {
				setLoadingExport({
					flag: false,
					msg: "Exportar",
					style: "primary",
				});
			}, 1000);
		}, 1500);
	}, [reference]);
	const exportTxt = useCallback(() => {
		setLoadingExport({
			flag: true,
			msg: "Carregando...",
			style: "warning",
		});
		let arrayColumns = [...reference];
		if (paramExport.length) {
			arrayColumns = [];
			reference.map((item) => {
				let objectColumns = {};
				paramExport.map((colum) => {
					objectColumns[colum] = item[colum];
				});
				arrayColumns.push(objectColumns);
			});
		}
		setTimeout(() => {
			const textToExport = [];
			arrayColumns.map((item) => {
				let obj = { ...item };
				delete obj.createdAt;
				delete obj.updatedAt;
				delete obj.created_at;
				delete obj.updated_at;
				delete obj.empresas_id;
				delete obj.data_cancelamento;
				// delete obj.emissao

				if (item.createdAt) {
					obj.data_criacao = new Date(item.createdAt).toLocaleDateString(
						"pt-BR"
					);
				}
				if (item.updatedAt) {
					obj.data_atualizacao = new Date(item.updatedAt).toLocaleDateString(
						"pt-BR"
					);
				}
				if (item.dtemissao) {
					obj.dtemissao = new Date(item.dtemissao).toLocaleDateString("pt-BR", {
						timeZone: "UTC",
					});
				}
				if (item.data_cadastro) {
					obj.data_cadastro = new Date(item.data_cadastro).toLocaleDateString(
						"pt-BR",
						{ timeZone: "UTC" }
					);
				}
				if (item.data_faturamento) {
					obj.data_faturamento = new Date(
						item.data_faturamento
					).toLocaleDateString("pt-BR", { timeZone: "UTC" });
				}

				Object.keys(obj).forEach((key) => {
					if (key.includes("valor")) {
						obj[key] = valorMoeda(obj[key]);
					}
					if (key.includes("km")) {
						obj[key] = Number(obj[key]).toLocaleString("pt-br", {
							minimumFractionDigits: 2,
						});
					}
					if (key.includes("id_filial")) {
						obj[key] =
							filiais.find((item) => item.idFilial == obj[key])
								?.identificacaoInterna || "N/A";
					}
				});

				textToExport.push(JSON.stringify(obj, null, 3));
			});

			const element = document.createElement("a");
			const file = new Blob(textToExport, { type: "text/plai" });
			element.href = URL.createObjectURL(file);
			element.download = `${tableName}.txt`;
			element.click();

			setLoadingExport({
				flag: false,
				msg: "Concluido",
				style: "success",
			});

			setTimeout(() => {
				setLoadingExport({
					flag: false,
					msg: "Exportar",
					style: "primary",
				});
			}, 1000);
		}, 1500);
	}, [reference]);
	const exportExcel = useCallback(() => {
		setLoadingExport({
			flag: true,
			msg: "Carregando...",
			style: "warning",
		});
		let arrayColumns = [...reference];
		if (paramExport.length) {
			arrayColumns = [];
			reference.map((item) => {
				let objectColumns = {};
				paramExport.map((colum) => {
					objectColumns[colum] = item[colum];
				});
				arrayColumns.push(objectColumns);
			});
		}
		setTimeout(() => {
			const tableToExport = [];
			arrayColumns.map((item) => {
				let obj = { ...item };
				delete obj.createdAt;
				delete obj.updatedAt;
				delete obj.created_at;
				delete obj.updated_at;
				delete obj.empresas_id;
				delete obj.data_cancelamento;
				// delete obj.emissao

				if (item.createdAt) {
					obj.data_criacao = new Date(item.createdAt).toLocaleDateString(
						"pt-BR"
					);
				}
				if (item.updatedAt) {
					obj.data_atualizacao = new Date(item.updatedAt).toLocaleDateString(
						"pt-BR"
					);
				}
				if (item.dtemissao) {
					obj.dtemissao = new Date(item.dtemissao).toLocaleDateString("pt-BR", {
						timeZone: "UTC",
					});
				}
				if (item.data_cadastro) {
					obj.data_cadastro = new Date(item.data_cadastro).toLocaleDateString(
						"pt-BR",
						{ timeZone: "UTC" }
					);
				}
				if (item.data_faturamento) {
					obj.data_faturamento = new Date(
						item.data_faturamento
					).toLocaleDateString("pt-BR", { timeZone: "UTC" });
				}

				Object.keys(obj).forEach((key) => {
					if (key.includes("valor")) {
						obj[key] = valorMoeda(obj[key]);
					}
					if (key.includes("km")) {
						obj[key] = Number(obj[key]).toLocaleString("pt-br", {
							minimumFractionDigits: 2,
						});
					}
					if (key.includes("id_filial")) {
						obj[key] =
							filiais.find((item) => item.idFilial == obj[key])
								?.identificacaoInterna || "N/A";
					}
				});

				tableToExport.push(obj);
			});

			const data = String(new Date().toLocaleString("pt-BR"))
				.replaceAll("/", "")
				.replaceAll(":", "-");
			const worksheet = utils.json_to_sheet(tableToExport);
			const workbook = utils.book_new();

			utils.book_append_sheet(workbook, worksheet, data);
			writeFile(workbook, `${tableName}.xlsx`);
			setLoadingExport({
				flag: false,
				msg: "Concluido",
				style: "success",
			});

			setTimeout(() => {
				setLoadingExport({
					flag: false,
					msg: "Exportar",
					style: "primary",
				});
			}, 1000);
		}, 1500);
	}, [reference]);

	const [hiddenConfigs, setHiddenConfigs] = useState(true);
	const [modaisControle, setModaisControle] = useState({
		flag: false,
	});
	return (
		<div className="">
			<Button
				variant={loadingExport.style}
				className="button-export-config"
				onMouseOver={() => setHiddenConfigs(false)}
				onMouseLeave={() => setHiddenConfigs(true)}
			>
				{loadingExport.style == "success" ? (
					<i className="bi bi-check"></i>
				) : (
					""
				)}
				{loadingExport.flag == true && loadingExport.style != "primary" ? (
					<Spinner size="sm" animation="border" />
				) : (
					""
				)}
				{loadingExport.style == "primary" ? (
					<i className="bi bi-cloud-download-fill"></i>
				) : (
					""
				)}
				<div style={{ paddingLeft: "10px" }}>{loadingExport.msg}</div>
				<div className="configs" hidden={hiddenConfigs}>
					<div onClick={exportExcel}>Excel .xlsx</div>
					<div onClick={exportTxt}>Arquivo .txt</div>
					{tableName == "Pontos" ? (
						<div onClick={exportTxtEspecial}>Arquivo RH .txt</div>
					) : (
						""
					)}
				</div>
			</Button>
		</div>
	);
}
