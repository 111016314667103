import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../loading/Loading";

import "../../modal/Modal.scss";
import ModalError from "../../modal/ModalError";
import {
	cnpj_mask,
	cpf_cnpj_mask,
	getAllData,
	getDateInitMonth,
	getTableColumns,
	insertInto,
	updateTable,
	valorMoeda,
} from "../../../services/Servicos";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ExportToExcel from "../../exportToExcel/ExportToExcel";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import BodyRowManutencoes from "../../table/body/BodyRowManutencoes";
import BodyRowManutencoesItems from "../../table/body/BodyRowManutencoesItems";
import { updateManutencoesList } from "../../../redux/slicers/user";
import ModalCancelamento from "./ModalCancelamento";
import ModalEdicao from "./ModalEdicao";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

export default function ModalControleDeFornecedores(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const id_filial = user.tipo.toLowerCase() == "empresa" ? 2 : user.id_filial;
	const formREf = useRef();
	const textArea = useRef();

	const [atualizar, setAtualizar] = useState(0);
	const [error, setError] = useState({ show: false });
	const [showCardLancamento, setShowCardLancamento] = useState(true);

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		responsavel: String(user.name).toUpperCase(),
		cpf_responsavel: user.cpf || "N/A",
	});

	const createItem = async () => {
		if (!body.descricao) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Serviço",
					motivo: "Descreva o Item.",
				},
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});
		let bodySend = {
			id_filial,
		};

		Object.keys(body).map((item) => {
			if (item.includes("data")) {
				bodySend[item] = body[item];
				return;
			}
			if (item.includes("valor")) {
				bodySend[item] = Number(
					body[item].toUpperCase().replace(".", "").replace(",", ".")
				);
				return;
			}
			if (item.includes("cnpj")) {
				bodySend[item] = body[item];
				return;
			}

			bodySend[item] = String(body[item])
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		insertInto("manutencao/fornecedores", bodySend)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				setBody((last) => ({
					...last,
					responsavel: String(user.name).toUpperCase(),
					cpf_responsavel: user.cpf || "N/A",
					observacoes: "",
					descricao: "",
					cnpj: "",
				}));
				setAtualizar((last) => last + 1);

				formREf.current.reset();

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					});
				}, 750);
			})
			.catch((error) => {
				const message = error?.response?.data?.message || error.message;
				setError({
					show: true,
					title: "Atenção",
					message: message,
				});

				setLoading({
					isLoading: false,
					msg: "Enviar",
					style: "primary",
				});
			});
	};

	const onClickButton = async () => {
		setLoading({ isLoading: true, style: "warning", msg: "Aguarde" });
		await createItem();
	};

	const onChangeInput = (e) => {
		if (e.target.name == "cnpj") {
			setBody((last) => ({
				...last,
				[e.target.name]: cnpj_mask(e.target.value),
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ error: { flag: false } }));

	return (
		<>
			<Modal
				show={props.flag}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size={!showCardLancamento ? "md" : "lg"}
			>
				<Modal.Header closeButton>
					<Modal.Title>Controle de Fornecedores</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TabelaItens hidden={!showCardLancamento} atualizar={atualizar} />
					<Form hidden={showCardLancamento} ref={formREf}>
						<Row className="mb-2 align-items-center">
							<Col sm="4">
								<Form.Label>Descrição:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="descricao"
									onChange={onChangeInput}
									value={body?.descricao}
									placeholder="Razão Social, Nome Fantasia, Apelido ..."
								/>
							</Col>
						</Row>
						<Row className="mb-2 align-items-center">
							<Col sm="4">
								<Form.Label>CNPJ:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="cnpj"
									maxLength={18}
									onChange={onChangeInput}
									value={body?.cnpj}
								/>
							</Col>
						</Row>
						<Row className="mb-2  align-items-center">
							<Col sm="4">
								<Form.Label>Responsável:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={onChangeInput}
									value={body.responsavel}
									name="responsavel"
								/>
							</Col>
						</Row>
						<Row className="mb-2  align-items-center">
							<Col sm="4">
								<Form.Label>Observações:</Form.Label>
							</Col>
							<Col>
								<textarea
									ref={textArea}
									onChange={onChangeInput}
									value={body?.observacoes}
									name="observacoes"
									style={{ width: "100%" }}
									rows={4}
								></textarea>
							</Col>
						</Row>
					</Form>
					{/* {error.flag ? <ModalError show={error.flag} setShow={setError} title={error.title} msg={error.msg} />:""} */}
					{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant="info"
								onClick={() => {
									setShowCardLancamento((last) => !last);
								}}
							>
								{showCardLancamento ? (
									<span>Lancamentos</span>
								) : (
									<span>Tabela</span>
								)}
							</Button>
						</Col>
						<Col style={{ display: showCardLancamento ? "none" : "flex" }}>
							<Button variant={loading.style} onClick={onClickButton}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function TabelaItens(props) {
	const [error, setError] = useState({});
	const [headList, setHeadList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const tableDiv = useRef();
	const tbodyRef = useRef();
	const user = useSelector((state) => state.userSlice);
	const id_filial = user.tipo.toLowerCase() == "empresa" ? 2 : user.id_filial;

	const getColumns = async () => {
		try {
			const columns = await getTableColumns("ManutencaoFornecedor");
			setHeadList((last) => [
				"index",
				"admin",
				...Object.keys(columns.data)
					.filter((item) => item !== "empresas_id")
					.filter((item) => item !== "created_at")
					.filter((item) => item !== "updated_at")
					.filter((item) => item !== "cpf_responsavel")
					.filter((item) => item !== "user_cancelamento")
					.filter((item) => item !== "data_cancelamento")
					.filter((item) => item !== "manutencao_id"),
			]);
		} catch (error) {
			setError({
				flag: true,
				msg: { t: "Erro na obentção das Colunas" },
				title: "Aviso!",
			});
		}
	};

	const getData = useCallback(async () => {
		const url = "manutencao/fornecedores";
		const body = { id_filial };
		try {
			const data = await getAllData(url, body);
			setDataTable(
				data.data.map((item) => ({
					...item,
					// data_finalizacao: item.data_finalizacao ? new Date(item.data_finalizacao).toLocaleDateString('pt-br', { timeZone: 'UTC' }) : ''
				}))
			);
		} catch (error) {
			setError({
				flag: true,
				list: error,
				title: "Aviso!",
			});
		}
		setLoading(false);
	});

	// dados de entrada
	useEffect(() => {
		getColumns();
	}, []);

	useEffect(() => {
		getData();
	}, [props.atualizar]);

	// scrollBar
	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [dataTable];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<div hidden={props.hidden}>
			<Row className="mt-2 row-filters">
				<Col>
					<div>
						{filters.map((item, key) =>
							Object.keys(item).map((key, index) => {
								return (
									<span className="filters-card" key={index}>
										<span className="filters">
											<span className="title">
												{key}: {item[key]}
											</span>
											<i
												className="bi bi-x-lg"
												onClick={onClickRemoveFilter}
											></i>
										</span>
									</span>
								);
							})
						)}
					</div>
				</Col>
			</Row>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Dados{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</Col>

						<Col sm="12" md="6" lg="3">
							<ExportToExcel
								tableName="Manutencoes_Items"
								reference={
									filteredTable.length ? filteredTable : [...dataTable]
								}
							/>
						</Col>
					</Row>
				</div>

				<div
					className="responsive-table"
					ref={tableDiv}
					style={{ position: "relative", height: "390px", maxHeight: "unset" }}
				>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={dataTable}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={self.length}
												/>
											);
										}
								  })
								: dataTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setDataTable={setDataTable}
													tableLength={self.length}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < dataTable.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			{error.flag ? (
				<ModalError show={error.flag} setShow={setError} {...error} />
			) : (
				""
			)}
		</div>
	);
}

function BodyRowLocal({
	headList,
	bodyList,
	index,
	setDataTable = () => {},
	tableLength = 0,
}) {
	const refRow = useRef(null);
	const refExcluir = useRef(null);
	const dispatch = useDispatch();
	const userS = useSelector((state) => state.userSlice);
	const id_filial = userS.tipo.toLowerCase() == "empresa" ? 2 : userS.id_filial;
	const filiaisList = userS.filiaisList.data;

	const [controleCancelamento, setControleCancelamento] = useState({
		falg: false,
	});
	const [controleEdicao, setControleEdicao] = useState({ falg: false });
	const [controleEdicaoItensManutencao, setControleEdicaoItensManutencao] =
		useState({ falg: false });
	const [adminFunction, setAdminFunction] = useState({
		error: {
			flag: false,
			title: "",
			msg: "",
			confirmar: "",
		},
	});
	const [atribuir, setAtribuir] = useState({
		flag: false,
		placa: "",
		returned: false,
	});
	const [jaAtribuido, setJaAtribuido] = useState({
		flag: false,
	});
	const [error, setError] = useState({
		flag: false,
		title: "",
		msg: "",
	});
	const [loading, setLoading] = useState(false);

	const getData = useCallback(async () => {
		const url = "manutencao/fornecedores";

		try {
			const data = await getAllData(url, { id_filial });
			setDataTable(data.data);
		} catch (error) {
			setError({
				flag: true,
				msg: error,
				title: "Aviso!",
			});
		}
	});

	const onClickCancelItem = async () => {
		const url = "manutencao/fornecedores";

		await updateTable(url, bodyList.id, {
			data_cancelamento: new Date(),
			user_cancelamento: userS.name,
		}); // cancelado
		getData();
	};

	const onClickEditar = async (bodyUpdate) => {
		const url = "manutencao/fornecedores";
		await updateTable(url, bodyList.id, {
			...bodyUpdate,
			manutencao_id: bodyList.manutencao_id,
		});
		getData(bodyList.manutencao_id);
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
		// setCargaSelecionada({
		//     ...bodyList
		//     , disponivel: bodyList.status === 0 ? true : false
		// })
	};

	return (
		<Fragment>
			{
				<tr
					ref={refRow}
					style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}
					onClick={onClickTableRow}
				>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "tipo") {
							return (
								<td key={_index}>
									<div className="admin">
										<span
											style={{
												fontSize: "0.8rem",
												padding: "7px 15px",
												borderRadius: "10px",
												backgroundColor:
													bodyList.tipo == "CORRETIVA"
														? "#ff0000bf"
														: "#b7d972",
											}}
										>
											{bodyList[item]}
										</span>
									</div>
								</td>
							);
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Excluir"
												onClick={() =>
													setControleCancelamento({
														bodyList,
														flag: true,
														title: "Cancelar Manutencao",
														fn: onClickCancelItem,
													})
												}
											></i>
										</span>
										<span className="editar" hidden={bodyList.status == "2"}>
											<i
												className="bi bi-pencil"
												title="Editar"
												onClick={() =>
													setControleEdicao({
														bodyList,
														flag: true,
														title: "Editar Fornecedor",
														fn: onClickEditar,
													})
												}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "placa") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "id_filial") {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									{filiaisList.find(
										(item) => item.idFilial == bodyList.id_filial
									)?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} className={item}>
									{bodyList[item] == 2 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-success">
												Finalizado
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 1 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-warning">
												Em Manutencao
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 0 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-info">Agendado</span>
										</div>
									) : (
										""
									)}
								</td>
							);
						} else if (item === "createdAt" || item === "updatedAt") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "10rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "observacoes") {
							/* primary key can not be modified */
							return (
								<td
									key={_index}
									style={{ minWidth: "10rem", cursor: "pointer" }}
								>
									<textarea value={bodyList[item]}></textarea>
								</td>
							);
						} else if (item.includes("km")) {
							return (
								<td key={_index}>
									<div className="admin">
										{Number(bodyList[item]).toLocaleString("pt-br", {
											minimumFractionDigits: 2,
										})}
									</div>
								</td>
							);
						} else if (item === "valor") {
							return (
								<td key={_index}>
									<div className="admin">{valorMoeda(bodyList[item])}</div>
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}

			{controleCancelamento.flag ? (
				<ModalCancelamento
					{...controleCancelamento}
					setShow={setControleCancelamento}
				/>
			) : (
				""
			)}
			{controleEdicao.flag ? (
				<ModalEdicao {...controleEdicao} setShow={setControleEdicao} />
			) : (
				""
			)}
			{error.flag ? (
				<ModalError show={error.flag} setShow={setError} {...error} />
			) : (
				""
			)}
		</Fragment>
	);
}
