import { createRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Loading from "../../loading/Loading";
import CaminhaoLayout from "../layout/CaminhaoLayout";
import {
	apenasNumeros,
	createCaminhao,
	getCaminhoes,
	numerosDecimal,
} from "../../../services/Servicos";
import { useDispatch, useSelector } from "react-redux";
import { update, updateCaminhoes } from "../../../redux/slicers/user";
import { useNavigate } from "react-router-dom";

const placaD = createRef(null);
const comprimentoD = createRef(null);
const larguraD = createRef(null);
const alturaD = createRef(null);
const tipoD = createRef(null);
const tipoCavaloD = createRef(null);
const propriedadeD = createRef(null);
const qtd_eixosD = createRef(null);
const capacidadeD = createRef(null);
const pbtD = createRef(null);
const taraD = createRef(null);
const statusD = createRef(null);
const descricaoD = createRef(null);
const anoModeloD = createRef(null);
const chassiD = createRef(null);
const renavanD = createRef(null);
const dataTacografo = createRef(null);

export default function CaminhaoCadastro() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userS = useSelector((state) => state.userSlice);
	const filiaisList = userS.filiaisList.data;

	const [user, setUser] = useState({
		placa: "",
		comprimento: "",
		largura: "",
		altura: "",
		tipo: "",
		tipoCavalo: "",
		propriedade: "",
		descricao: "",
		ano_modelo: "",
		chassi: "",
		renavam: "",
		qtd_eixos: "",
		capacidade: "",
		status: false,
		pbt: "",
		tara: "",
		vencimento_tacografo: "",
		error: {
			flag: false,
			msg: "",
		},
	});

	const [loading, setLoading] = useState({
		flag: false,
		style: "primary",
	});

	const onChangeInput = (e) => {
		e.preventDefault();

		setUser((last) => ({
			...last,
			placa: placaD.current.value.toUpperCase(),
			capacidade: numerosDecimal(apenasNumeros(capacidadeD.current.value)),
			comprimento: numerosDecimal(apenasNumeros(comprimentoD.current.value)),
			largura: numerosDecimal(apenasNumeros(larguraD.current.value)),
			altura: numerosDecimal(apenasNumeros(alturaD.current.value)),
			tipo: tipoD.current.value,
			tipo_cavalo: tipoCavaloD.current.value,
			propriedade: propriedadeD.current.value,
			descricao: descricaoD.current.value,
			ano_modelo: anoModeloD.current.value,
			chassi: chassiD.current.value,
			renavam: renavanD.current.value,
			qtd_eixos: apenasNumeros(qtd_eixosD.current.value),
			status: statusD.current.value == "on" ? false : statusD.current.value,
			pbt: numerosDecimal(apenasNumeros(pbtD.current.value)),
			tara: numerosDecimal(apenasNumeros(taraD.current.value)),
			error: { flag: false, msg: "" },
			vencimento_tacografo: dataTacografo.current.value,
		}));
	};

	const updateCaminhoesList = () => {
		getCaminhoes(userS.tipo).then((response) => {
			dispatch(updateCaminhoes(response));
			navigate("/frota/cadastrados");
		});
	};

	const submitHandler = (e) => {
		e.preventDefault();

		const user_ = {
			placa: user.placa,
			comprimento: user.comprimento.replace(",", "."),
			largura: user.largura.replace(",", "."),
			altura: user.altura.replace(",", "."),
			tipo: user.tipo,
			tipoCavalo: user.tipoCavalo,
			id_filial: user.propriedade,
			descricao: user.descricao,
			renavam: user.renavam,
			chassi: user.chassi,
			ano_modelo: user.ano_modelo,
			qtd_eixos: user.qtd_eixos,
			vencimento_tacografo: user.vencimento_tacografo,
			capacidade: user.capacidade.replace(",", "."),
			status: user.status,
			pbt: user.pbt.replace(",", "."),
			tara: user.tara.replace(",", "."),
		};

		setLoading({
			flag: true,
			style: "warning",
		});
		createCaminhao(user_, userS.tipo)
			.then((response) => {
				setUser((last) => ({
					...last,
					error: {
						flag: response.error,
						msg: response.msg,
					},
				}));

				setLoading({
					flag: false,
					style: "success",
				});
				setTimeout(() => {
					updateCaminhoesList();
				}, 1000);
			})
			.catch((error) => {
				if (error.msg.toLowerCase() === "unauthorized") {
					// alert('Session has Expired')
					dispatch(update({ authenticated: false, token: "" }));
					window.location.replace("/login");
				}
				setUser((last) => ({
					...last,
					error: {
						flag: error.error,
						msg: error.msg,
					},
				}));
				setLoading(false);
			});
	};

	return (
		<CaminhaoLayout>
			<Form onSubmit={submitHandler}>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Descrição:</Form.Label>
						<Form.Control
							type="text"
							placeholder=""
							ref={descricaoD}
							value={user.descricao}
							onChange={onChangeInput}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Ano/Modelo:</Form.Label>
						<Form.Control
							type="text"
							placeholder=""
							ref={anoModeloD}
							value={user.anoModelo}
							onChange={onChangeInput}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Renavan:</Form.Label>
						<Form.Control
							type="text"
							placeholder=""
							ref={renavanD}
							value={user.renavan}
							onChange={onChangeInput}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Chassi:</Form.Label>
						<Form.Control
							type="text"
							placeholder=""
							ref={chassiD}
							value={user.chassi}
							onChange={onChangeInput}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Placa:</Form.Label>
						<Form.Control
							type="text"
							placeholder=""
							maxLength={7}
							required
							ref={placaD}
							value={user.placa}
							onChange={onChangeInput}
						/>
						{user.error.msg.includes("placa") ||
						user.error.msg.includes("PRIMARY") ? (
							<Form.Text className="text-muted error-form">
								Placa Já Cadastrada!
							</Form.Text>
						) : (
							""
						)}
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Propriedade:</Form.Label>
						<Form.Select ref={propriedadeD} onChange={onChangeInput}>
							<option value={0}></option>
							{filiaisList.map((item, index) => (
								<option key={index} value={item.idFilial}>
									{item.identificacaoInterna}
								</option>
							))}
							<option value="TERCEIRO">TERCEIRO</option>
						</Form.Select>
					</Col>
				</Row>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Capacidade:</Form.Label>
						<Form.Control
							type="text"
							placeholder="Toneladas"
							maxLength={5}
							ref={capacidadeD}
							onChange={onChangeInput}
							value={user.capacidade}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>PBT:</Form.Label>
						<Form.Control
							type="text"
							placeholder="Toneladas"
							required
							maxLength={5}
							ref={pbtD}
							onChange={onChangeInput}
							value={user.pbt}
						/>
						{user.error.msg.includes("cpf") ? (
							<Form.Text className="text-muted error-form">
								CPF Já Cadastrado!
							</Form.Text>
						) : (
							""
						)}
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>TARA:</Form.Label>
						<Form.Control
							type="text"
							placeholder="Tonedas"
							required
							maxLength={5}
							ref={taraD}
							onChange={onChangeInput}
							value={user.tara}
						/>
						{user.error.msg.includes("cpf") ? (
							<Form.Text className="text-muted error-form">
								CPF Já Cadastrado!
							</Form.Text>
						) : (
							""
						)}
					</Col>
				</Row>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Comprimento:</Form.Label>
						<Form.Control
							type="text"
							placeholder="Metros"
							required
							maxLength={5}
							ref={comprimentoD}
							onChange={onChangeInput}
							value={user.comprimento}
						/>
						{user.error.msg.includes("email") ? (
							<Form.Text className="text-muted error-form">
								Email Já Cadastrado!
							</Form.Text>
						) : (
							""
						)}
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Altura:</Form.Label>
						<Form.Control
							type="text"
							placeholder="Metros"
							required
							maxLength={5}
							ref={alturaD}
							onChange={onChangeInput}
							value={user.altura}
						/>
						{user.error.msg.includes("cpf") ? (
							<Form.Text className="text-muted error-form">
								CPF Já Cadastrado!
							</Form.Text>
						) : (
							""
						)}
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Largura:</Form.Label>
						<Form.Control
							type="text"
							placeholder="Metros"
							required
							maxLength={5}
							ref={larguraD}
							onChange={onChangeInput}
							value={user.largura}
						/>
						{user.error.msg.includes("cpf") ? (
							<Form.Text className="text-muted error-form">
								CPF Já Cadastrado!
							</Form.Text>
						) : (
							""
						)}
					</Col>
				</Row>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Tipo:</Form.Label>
						<Form.Select ref={tipoD} onChange={onChangeInput} required>
							<option value="Toco">Toco</option>
							<option value="Truck">Truck</option>
							<option value="BiTruck">BiTruck</option>
							<option value="Carreta">Carreta</option>
							<option value="Cavalo Mecanico Simples">
								Cavalo Mecanico Simples
							</option>
							<option value="Cavalo Mecanico Truck">
								Cavalo Mecanico Truck
							</option>
						</Form.Select>
					</Col>

					<Col md="4" className="mb-3">
						<Form.Label>Eixos:</Form.Label>
						<Form.Control
							type="text"
							maxLength={2}
							required
							ref={qtd_eixosD}
							onChange={onChangeInput}
							value={user.qtd_eixos}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Validade Tacografo:</Form.Label>
						<Form.Control
							type="date"
							required
							ref={dataTacografo}
							onChange={onChangeInput}
							value={user.vencimento_tacografo}
						/>
					</Col>

					<Col
						md="4"
						className="mb-3"
						hidden={user.tipo === "Carreta" ? false : true}
					>
						<Form.Label>Tipo de Cavalo:</Form.Label>
						<Form.Select
							ref={tipoCavaloD}
							onChange={onChangeInput}
							disabled={user.tipo === "Carreta" ? false : true}
						>
							<option value="Sem Cavalo"></option>
							<option value="Cavalo Mecânico Simples">
								Cavalo Mecânico Simples
							</option>
							<option value="Cavalo Mecânico Truckado">
								Cavalo Mecânico Truckado
							</option>
						</Form.Select>
					</Col>
				</Row>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Status:</Form.Label>
						<Form.Check
							type="switch"
							ref={statusD}
							onChange={() =>
								setUser((last) => ({ ...last, status: !last.status }))
							}
							label={user.status ? "Caminhão Ativo" : "Caminhão Inativo"}
						/>
					</Col>
				</Row>
				<Row className="mx-auto justify-content-center my-5">
					<Col md="3" className="mb-3">
						<Button variant={loading.style} type="submit">
							{loading.flag ? <Loading msg="Salvando" /> : "Salvar"}
							{loading.style === "success" ? (
								<i className="bi bi-check"></i>
							) : (
								""
							)}
						</Button>
					</Col>
					<Col md="3" className="mb-3">
						<Button variant="secondary" type="reset">
							Apagar
						</Button>
					</Col>
				</Row>
			</Form>
		</CaminhaoLayout>
	);
}
