import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../loading/Loading";

import "../../modal/Modal.scss";
import ModalError from "../../modal/ModalError";
import {
	getAllData,
	getAllDataN,
	getDateInitMonth,
	getTableColumns,
	insertInto,
	updateTable,
	valorMoeda,
} from "../../../services/Servicos";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ExportToExcel from "../../exportToExcel/ExportToExcel";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import BodyRowManutencoes from "../../table/body/BodyRowManutencoes";
import BodyRowManutencoesItems from "../../table/body/BodyRowManutencoesItems";
import { updateManutencoesList } from "../../../redux/slicers/user";
import ModalLancamentoItens from "./ModalLancamentoItens";
import ModalEdicao from "./ModalEdicao";
import ModalCancelamento from "./ModalCancelamento";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

export default function ModalLancamentoPecasItens(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const id_filial = user.tipo.toLowerCase() == "empresa" ? 2 : user.id_filial;
	const formREf = useRef();
	const textArea = useRef();

	const [atualizar, setAtualizar] = useState(0);
	const [error, setError] = useState(false);
	const [showCardLancamento, setShowCardLancamento] = useState(true);
	const [showTitle, setShowTitle] = useState({ item: "", show: false });

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		responsavel: String(user.name).toUpperCase(),
		cpf_responsavel: user.email,
	});

	const createItem = async () => {
		if (!body.descricao) {
			return setError({
				show: true,
				title: "Erro",
				message: "Lançamento de Item. Descreva o Item de Abastecimento.",
			});
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});

		let bodySend = {};

		Object.keys(body).map((item) => {
			if (item.includes("data")) {
				bodySend[item] = body[item];
				return;
			}
			if (item.includes("valor")) {
				bodySend[item] = Number(
					body[item].toUpperCase().replace(".", "").replace(",", ".")
				);
				return;
			}
			if (item.includes("cpf_responsavel")) {
				bodySend[item] = body[item];
				return;
			}

			bodySend[item] = body[item]
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		bodySend.manutencao_id = props.bodyList.manutencao_id;
		bodySend.item_manutencao = props.bodyList.id;
		bodySend.emissao = new Date();
		bodySend.tipo = props?.tipo;

		const url = "manutencao/pneus/pecas";

		insertInto(url, bodySend)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Salvo",
					style: "success",
				});
				setBody({
					responsavel: String(user.name).toUpperCase(),
					observacoes: "",
				});
				setAtualizar((last) => last + 1);
				formREf.current.reset();

				props.updateItem(props.bodyList.manutencao_id);

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					});
				}, 500);
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setError({
					show: true,
					title: "Atenção",
					message: error.message,
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onClickButton = async () => {
		setLoading({ isLoading: true, style: "warning", msg: "Aguarde" });
		await createItem();
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const editValue = (e) => {
		let valor = String(e.target.value)
			.replaceAll(".", "")
			.replace(",", "")
			.replace(/\D/g, "");

		if (e.target.name == "aviso_data") {
			setBody((last) => ({
				...last,
				[e.target.name]: Number(Number(valor) * 1).toLocaleString("pt-br", {
					minimumFractionDigits: 0,
				}),
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: Number(Number(valor) * 0.01).toLocaleString("pt-br", {
				minimumFractionDigits: 2,
			}),
		}));
	};
	const handlerSimpleClose = () =>
		props.setShow((last) => ({ error: { flag: false } }));

	const [fornecedores, setFornecedores] = useState([]);
	const getFornecedores = async () => {
		const url = "manutencao/fornecedores";
		try {
			const data1 = await getAllData(url, { id_filial });
			setFornecedores(data1.data);
		} catch (e) {
			setError({
				show: true,
				title: "Atenção",
				message: e.message,
			});
		}
	};

	useEffect(() => {
		getFornecedores();
	}, []);

	return (
		<>
			<Modal
				show={props.flag}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size={!showCardLancamento ? "md" : "xl"}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.title}: {props.bodyList.numero_pneu}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col sm="12" lg="6">
							<TabelaItens
								numero_pneu={props.bodyList.numero_pneu}
								manutencao_id={props.bodyList.manutencao_id}
								manutencao_item={props.bodyList.id}
								atualizar={atualizar}
								updateItem={props.updateItem}
							/>
						</Col>
						<Col sm="12" lg="6">
							<div
								className="card-header pb-2"
								style={{ margin: "10px 0 25px" }}
							>
								<Row className="justify-content-between">
									<h3 style={{ display: "flex", alignItems: "center" }}>
										Cadastro
									</h3>
								</Row>
							</div>
							<Form ref={formREf}>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Fornecedor:</Form.Label>
									</Col>
									<Col>
										<Form.Select
											type="text"
											name="fornecedor"
											onChange={onChangeInput}
											value={body?.fornecedor}
										>
											<option></option>
											{fornecedores.map((i, n, s) => (
												<option
													value={i.descricao}
													key={n}
													title={i.observacoes}
												>
													{i.descricao}
												</option>
											))}
										</Form.Select>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Descrição:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											onChange={onChangeInput}
											value={body?.descricao}
											name="descricao"
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Responsável:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											onChange={onChangeInput}
											value={body.responsavel}
											name="responsavel"
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Qtde:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											onChange={editValue}
											value={body.qtde}
											name="qtde"
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Valor:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											onChange={editValue}
											value={body.valor}
											name="valor"
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Observações:</Form.Label>
									</Col>
									<Col>
										<textarea
											ref={textArea}
											onChange={onChangeInput}
											value={body?.observacoes}
											name="observacoes"
											style={{ width: "100%" }}
											rows={4}
										></textarea>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
					{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "50%" }}>
						<Col style={{ display: "flex" }}>
							<Button
								variant={loading.style}
								onClick={onClickButton}
								disabled={props.bodyList.status != 0}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function TabelaItens(props) {
	const user = useSelector((state) => state.userSlice);
	const [error, setError] = useState({});
	const [headList, setHeadList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const tableDiv = useRef();
	const tbodyRef = useRef();
	const getColumns = async () => {
		try {
			const columns = await getTableColumns("ManutencaoPneuPeca");
			setHeadList((last) => [
				"index",
				"admin",
				...Object.keys(columns.data)
					.filter((item) => item !== "empresas_id")
					.filter((item) => item !== "created_at")
					.filter((item) => item !== "updated_at")
					.filter((item) => item !== "cpf_responsavel")
					.filter((item) => item !== "user_cancelamento")
					.filter((item) => item !== "data_cancelamento")
					.filter((item) => item !== "manutencao_id")
					.filter((item) => item !== "fornecedor_cnpj")
					.filter((item) => item !== "numero_pneu")
					.filter((item) => item !== "item_manutencao"),
			]);
		} catch (error) {
			setError({
				show: true,
				message: "Erro na obentção das Colunas",
				title: "Aviso!",
			});
		}
	};

	const getData = useCallback(
		async (numero_pneu, manutencao_id, item_manutencao) => {
			const url = "manutencao/pneus/pecas";
			try {
				const data = await getAllDataN(url, {
					numero_pneu,
					manutencao_id,
					item_manutencao,
				});
				setDataTable(
					data.data.map((item) => ({
						...item,
						emissao: new Date(item.emissao).toLocaleDateString("pt-br", {
							timeZone: "UTC",
						}),
					}))
				);
				props.updateItem(manutencao_id);
				setLoading(false);
			} catch (error) {
				setError({
					show: true,
					message: error.message,
					title: "Aviso!",
				});
			}
		}
	);

	useEffect(() => {
		getColumns();
	}, []);

	// dados de entrada
	useEffect(() => {
		getData(props.numero_pneu, props.manutencao_id, props.manutencao_item);
	}, [props.atualizar]);

	// scrollBar
	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [dataTable];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"placa"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<div>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Dados{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</Col>

						<Col sm="12" md="6" lg="3">
							<ExportToExcel
								tableName="Manutencoes_Items"
								reference={
									filteredTable.length ? filteredTable : [...dataTable]
								}
							/>
						</Col>
					</Row>
				</div>

				<div
					className="responsive-table"
					ref={tableDiv}
					style={{ position: "relative" }}
				>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={dataTable}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													updateItem={props.updateItem}
												/>
											);
										}
								  })
								: dataTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setDataTable={setDataTable}
													updateItem={props.updateItem}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < dataTable.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			<Row className="mt-2 row-filters">
				<Col>
					<div>
						{filters.map((item, key) =>
							Object.keys(item).map((key, index) => {
								return (
									<span className="filters-card" key={index}>
										<span className="filters">
											<span className="title">
												{key}: {item[key]}
											</span>
											<i
												className="bi bi-x-lg"
												onClick={onClickRemoveFilter}
											></i>
										</span>
									</span>
								);
							})
						)}
					</div>
				</Col>
			</Row>
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</div>
	);
}

function BodyRowLocal({
	headList,
	bodyList,
	index,
	setDataTable = () => {},
	updateItem = () => {},
}) {
	const refRow = useRef(null);
	const refExcluir = useRef(null);
	const dispatch = useDispatch();
	const userS = useSelector((state) => state.userSlice);
	const filiaisList = userS.filiaisList.data;

	const [controleCancelamento, setControleCancelamento] = useState({
		falg: false,
	});
	const [controleEdicao, setControleEdicao] = useState({ falg: false });
	const [controleEdicaoItensManutencao, setControleEdicaoItensManutencao] =
		useState({ falg: false });
	const [adminFunction, setAdminFunction] = useState({
		error: {
			flag: false,
			title: "",
			msg: "",
			confirmar: "",
		},
	});
	const [atribuir, setAtribuir] = useState({
		flag: false,
		placa: "",
		returned: false,
	});
	const [jaAtribuido, setJaAtribuido] = useState({
		flag: false,
	});
	const [error, setError] = useState({
		flag: false,
		title: "",
		msg: "",
	});
	const [loading, setLoading] = useState(false);

	const getData = useCallback(async (item_manutencao, manutencao_id) => {
		const url = "manutencao/pneus/pecas";
		try {
			const data = await getAllData(url, { item_manutencao, manutencao_id });
			setDataTable(
				data.data.map((item) => ({
					...item,
					emissao: new Date(item.emissao).toLocaleDateString("pt-br", {
						timeZone: "UTC",
					}),
				}))
			);

			//atualizar tabela de items de manutenção com novos dados
			updateItem(manutencao_id);
		} catch (error) {
			setError({
				show: true,
				message: error.message,
				title: "Aviso!",
			});
		}
	});

	const onClickCancelItem = async () => {
		try {
			const url = "manutencao/pneus/pecas";
			await updateTable(url, bodyList.id, {
				item_manutencao: bodyList.item_manutencao,
				data_cancelamento: new Date(),
				user_cancelamento: userS.name,
				manutencao_id: bodyList.manutencao_id,
				tipo: "item",
			}); // cancelado
			getData(bodyList.item_manutencao, bodyList.manutencao_id);
		} catch (error) {
			setError({ show: true, message: error.message, title: "Erro" });
		}
	};

	const onClickEditar = async (bodyUpdate) => {
		try {
			const url = "manutencao/pneus/pecas";
			await updateTable(url, bodyList.id, {
				...bodyUpdate,
				manutencao_id: bodyList.manutencao_id,
				tipo: "item",
				item_manutencao: bodyList.item_manutencao,
			});
			getData(bodyList.item_manutencao, bodyList.manutencao_id);
		} catch (error) {
			setError({ show: true, message: error.message, title: "Erro" });
		}
	};

	return (
		<Fragment>
			{
				<tr ref={refRow} style={{ verticalAlign: "middle" }}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "tipo") {
							return (
								<td key={_index}>
									<div className="admin">
										<span
											style={{
												fontSize: "0.8rem",
												padding: "7px 15px",
												borderRadius: "10px",
												backgroundColor:
													bodyList.tipo == "CORRETIVA"
														? "#ff0000bf"
														: "#b7d972",
											}}
										>
											{bodyList[item]}
										</span>
									</div>
								</td>
							);
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Excluir"
												onClick={() =>
													setControleCancelamento({
														bodyList,
														flag: true,
														title: "Cancelar Peça de Manutenção",
														fn: onClickCancelItem,
													})
												}
											></i>
										</span>
										<span className="editar" hidden={bodyList.status == "2"}>
											<i
												className="bi bi-pencil"
												title="Editar"
												onClick={() =>
													setControleEdicao({
														bodyList,
														flag: true,
														title: "Editar Peça de Manutenção",
														fn: onClickEditar,
													})
												}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "placa") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "id_filial") {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} className={item}>
									{bodyList[item] == 2 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-success">
												Finalizado
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 1 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-warning">
												Em Manutencao
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 0 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-info">Agendado</span>
										</div>
									) : (
										""
									)}
								</td>
							);
						} else if (item === "createdAt" || item === "updatedAt") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "10rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "observacoes") {
							/* primary key can not be modified */
							return (
								<td
									key={_index}
									style={{ minWidth: "10rem", cursor: "pointer" }}
								>
									<textarea value={bodyList[item]}></textarea>
								</td>
							);
						} else if (item.includes("km")) {
							return (
								<td key={_index}>
									<div className="admin">
										{Number(bodyList[item]).toLocaleString("pt-br", {
											minimumFractionDigits: 2,
										})}
									</div>
								</td>
							);
						} else if (item === "valor") {
							return (
								<td key={_index}>
									<div className="admin">{valorMoeda(bodyList[item])}</div>
								</td>
							);
						} else if (item === "itens") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="editar">
											{" "}
											<i
												className="bi bi-plus-circle"
												title="Controle de Itens"
												onClick={() =>
													setControleEdicaoItensManutencao({
														bodyList,
														flag: true,
														title: "Adicionar Itens de Manutencao",
													})
												}
											></i>
										</span>
										<span> {bodyList[item]}</span>
									</div>
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}

			{controleEdicaoItensManutencao.flag ? (
				<ModalLancamentoItens
					{...controleEdicaoItensManutencao}
					setShow={setControleEdicaoItensManutencao}
				/>
			) : (
				""
			)}
			{controleCancelamento.flag ? (
				<ModalCancelamento
					{...controleCancelamento}
					setShow={setControleCancelamento}
				/>
			) : (
				""
			)}
			{controleEdicao.flag ? (
				<ModalEdicao {...controleEdicao} setShow={setControleEdicao} />
			) : (
				""
			)}

			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</Fragment>
	);
}
