
import { Navigate } from "react-router-dom"
import { updateFiliaisList } from "../../redux/slicers/user"

export default function ProtectedRoute({ user, children, to = '/login' }) {
    
    
    
    if(!user){
        return <Navigate to={to} replace/>
    }

    return children
}