
import UserLayout from "../layout/UserLayout"
import '../NavbarUser.scss'

export default function UserSettings(){
    return(
        <UserLayout>
            <h3>[Settings]</h3>
        </UserLayout>
    )
}
