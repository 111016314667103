import { createRef, Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { update, updateCaminhoes } from "../../redux/slicers/user";
import { clearPersistData } from "../../redux/storeConfig/store";
import {
	alterFuncionarios_Caminhoes,
	apenasNumeros,
	getCaminhoes,
	numerosDecimal,
	numerosDecimal3,
	updateTable,
	updateTableNew,
	updateTableNewWithParams,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";

const comprimentoD = createRef(null);
const larguraD = createRef(null);
const alturaD = createRef(null);
const tipoD = createRef(null);
const tipoCavaloD = createRef(null);
const qtd_eixosD = createRef(null);
const capacidadeD = createRef(null);
const pbtD = createRef(null);
const taraD = createRef(null);
const statusD = createRef(null);
const tipoTransportD = createRef(null);
const dataTacografo = createRef(null);

export default function ModalAtualizarCaminhoes({
	msg,
	title,
	show,
	setShow,
	msgButton,
}) {
	const dispatch = useDispatch();

	const userS = useSelector((state) => state.userSlice);
	const filiaisList = userS.filiaisList.data;
	const caminhoesList = userS.caminhoesList.data;
	const [user, setUser] = useState({ status: msg.body.status });

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: msgButton,
		style: "primary",
	});
	const [error, setError] = useState(false);
	const [errorModal, setErrorModal] = useState({
		flag: false,
		title: "Aviso",
		msg: "",
	});

	const updateCaminhoesList = () => {
		getCaminhoes(userS.tipo).then((response) => {
			let caminhoesFilial = [];
			dispatch(updateCaminhoes(response));
			handlerSimpleClose();
			// window.location.reload()
		});
	};

	const submitDataExclude = async () => {
		setLoading({
			isLoading: true,
			msg: "Excluindo Caminhão",
			style: "warning",
		});
		try {
			await updateTableNew(`caminhoes?placa=${msg.msg.PLACA}`, {
				data_cancelamento: new Date().toISOString(),
				user_cancelamento: userS.name,
			});
			setLoading({
				isLoading: false,
				msg: "Excluido",
				style: "success",
			});

			setTimeout(() => {
				updateCaminhoesList();
			}, 1000);
		} catch (error) {
			setError({ flag: true, list: error.response.data, title: "Aviso" });
		}
		setTimeout(() => {
			setLoading({
				isLoading: false,
				msg: "Excluir",
				style: "primary",
			});
		}, 500);
	};

	const submitDataEdit = async () => {
		if (!user)
			return setErrorModal({
				flag: true,
				msg: "Nenhuma informação Enviada",
				title: "Aviso!",
			});
		setLoading({
			isLoading: true,
			msg: "Atualizando...",
			style: "warning",
		});
		let caminhaoToUpdate = {};
		Object.keys(user).forEach((key) => {
			if (String(user[key]).length) {
				caminhaoToUpdate[key] =
					typeof user[key] === "string"
						? user[key].replace(",", ".")
						: user[key];
			}
		});

		try {
			await await updateTableNew(
				`caminhoes?placa=${msg.body.placa}`,
				caminhaoToUpdate
			);
			setLoading({
				isLoading: false,
				msg: "Alterado",
				style: "success",
			});
			setTimeout(() => {
				updateCaminhoesList();
			}, 500);
		} catch (error) {
			setError(true);
			setLoading({
				isLoading: false,
				msg: msgButton,
			});
		}
	};

	const onChangeInput = (e) => {
		e.preventDefault();
		setError(false);
		setUser((last) => ({
			...last,
			// , placa: placaD.current.value
			capacidade: numerosDecimal(apenasNumeros(capacidadeD.current.value)),
			comprimento: numerosDecimal(apenasNumeros(comprimentoD.current.value)),
			largura: numerosDecimal(apenasNumeros(larguraD.current.value)),
			altura: numerosDecimal(apenasNumeros(alturaD.current.value)),
			tipo: tipoD.current.value,
			id_filial: tipoTransportD.current.value,
			tipo_cavalo: tipoCavaloD.current.value,
			vencimento_tacografo: dataTacografo.current.value,
			qtd_eixos: apenasNumeros(qtd_eixosD.current.value),
			// , status: statusD.current.value == 'on' ? false : statusD.current.value
			pbt: numerosDecimal(apenasNumeros(pbtD.current.value)),
			tara: numerosDecimal(apenasNumeros(taraD.current.value)),
		}));
	};

	const onChangeInputPadrao = (e) => {
		e.preventDefault();
		if (
			[
				"capacidade",
				"pbt",
				"tara",
				"comprimento",
				"altura",
				"comprimento",
				"largura",
			].includes(e.target.name)
		) {
			setUser((last) => ({
				...last,
				[e.target.name]: numerosDecimal(e.target.value),
			}));
			return;
		}
		setUser((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	useEffect(() => {
		if (msg.body.vencimento_tacografo == "N/A") return;
		const [dia, mes, ano] = String(msg.body.vencimento_tacografo).split("/");
		setUser((last) => ({
			...last,
			vencimento_tacografo: `${ano}-${mes}-${dia}`,
		}));
	}, []);

	const handlerSimpleClose = () =>
		setShow((last) => ({ error: { flag: false } }));

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{title}: {msg.msg.pk}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{title.includes("Excluir") ? <ExcluirDados msg={msg} /> : ""}
					{title.includes("Editar") ? (
						<Form>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Capacidade:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[12].innerText}
										maxLength={5}
										ref={capacidadeD}
										name="capacidade"
										onChange={onChangeInputPadrao}
										value={user?.capacidade}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>PBT:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[13].innerText}
										required
										maxLength={5}
										name="pbt"
										onChange={onChangeInputPadrao}
										value={user?.pbt}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Tara:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[14].innerText}
										required
										maxLength={5}
										name="tara"
										onChange={onChangeInputPadrao}
										value={user?.tara}
									/>
								</Col>
							</Row>

							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Comprimento:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[8].innerText}
										required
										maxLength={5}
										name="comprimento"
										onChange={onChangeInputPadrao}
										value={user?.comprimento}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Altura:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[10].innerText}
										required
										maxLength={5}
										name="altura"
										onChange={onChangeInputPadrao}
										value={user?.altura}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Largura:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[9].innerText}
										required
										maxLength={5}
										name="largura"
										onChange={onChangeInputPadrao}
										value={user?.largura}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Tipo:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										ref={tipoD}
										name="tipo"
										onChange={onChangeInputPadrao}
										defaultValue={msg.body.tipo}
									>
										<option value=""></option>
										<option value="Toco">Toco</option>
										<option value="Truck">Truck</option>
										<option value="BiTruck">BiTruck</option>
										<option value="Carreta">Carreta</option>
										<option value="Cavalo Mecanico Simples">
											Cavalo Mecanico Simples
										</option>
										<option value="Cavalo Mecanico Truck">
											Cavalo Mecanico Truck
										</option>
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Propriedade:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										ref={tipoTransportD}
										name="id_filial"
										onChange={onChangeInputPadrao}
										defaultValue={msg.body.id_filial}
									>
										<option value={0}></option>
										{filiaisList.map((item, index) => (
											<option key={index} value={item.idFilial}>
												{item.identificacaoInterna}
											</option>
										))}
										<option value="TERCEIRO">TERCEIRO</option>
									</Form.Select>
								</Col>
							</Row>
							<Row
								className="mb-2"
								hidden={user?.tipo === "Carreta" ? false : true}
							>
								<Col sm="4">
									<Form.Label>Tipo de Cavalo:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										name="tipo_cavalo"
										onChange={onChangeInputPadrao}
										disabled={user?.tipo === "Carreta" ? false : true}
									>
										<option value="Sem Cavalo"></option>
										<option value="Cavalo Mecânico Simples">
											Cavalo Mecânico Simples
										</option>
										<option value="Cavalo Mecânico Truckado">
											Cavalo Mecânico Truckado
										</option>
									</Form.Select>
								</Col>
							</Row>

							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Eixos:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[11].innerText}
										maxLength={2}
										required
										name="qtd_eixos"
										onChange={onChangeInputPadrao}
										value={user?.qtd_eixos}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Vencimento Tacografo:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="date"
										required
										name="vencimento_tacografo"
										onChange={onChangeInputPadrao}
										value={user?.vencimento_tacografo}
									/>
								</Col>
							</Row>
							{msg.body.tipo.toLowerCase() == "carreta" ||
							user?.tipo == "Carreta" ? (
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Trator Referência:</Form.Label>
									</Col>
									<Col>
										<Form.Select
											onChange={onChangeInputPadrao}
											name="trator"
											defaultValue={msg.body.trator}
										>
											<option value=""></option>
											{caminhoesList
												.filter((item) => item.tipo.includes("Cavalo"))
												.map((item, index) => (
													<option key={index} value={item.placa}>
														{item.placa}
													</option>
												))}
										</Form.Select>
									</Col>
								</Row>
							) : (
								""
							)}

							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Status:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										value={user.status}
										name="status"
										onChange={onChangeInputPadrao}
									>
										<option value={0}>Inativo</option>
										<option value={1}>Ativo</option>
										<option value={2}>Em Manutenção</option>
									</Form.Select>
									{/* <Form.Check
										type="switch"
										defaultChecked={msg.body.status}
										onChange={() => {
											if (!user?.status)
												return setUser((last) => ({
													...last,
													status: !msg.body.status,
												}));
											setUser((last) => ({ ...last, status: !last.status }));
										}}
										label={user?.status ? "Caminhão Ativo" : "Caminhão Inativo"}
									/> */}
								</Col>
							</Row>

							{error ? (
								<Form.Text className="text-muted error-form">
									Placa já cadastrada em outro caminhão.
								</Form.Text>
							) : (
								""
							)}
						</Form>
					) : (
						""
					)}
					{error.flag ? (
						<ModalError show={error.flag} setShow={setError} {...error} />
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={
									title.includes("Editar") ? submitDataEdit : submitDataExclude
								}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function ExcluirDados({ msg }) {
	return (
		<Fragment>
			<div>{msg.title}</div>
			<div>
				{Object.keys(msg.msg).map((key, index) => (
					<div key={index}>
						{key}:{" "}
						<b>
							<i>{msg.msg[key]}</i>
						</b>
					</div>
				))}
			</div>
		</Fragment>
	);
}
