import { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import SignatureCanvas from "react-signature-canvas";

import "./Modal.scss";
import { PDFDocument, rgb } from "pdf-lib";

export default function ModalPadAssinatura({
	msg,
	title,
	show,
	setShow,
	pdf,
	setPdf = () => {},
	danfesCliente,
	setDanfesCliente = () => {},
	romaneiosDoCliente,
}) {
	const handlerSimpleClose = () => setShow(false);
	const [sign, setSign] = useState(null);
	const danfes = useSelector((state) => state.cargaSlicer.listasDanfes);

	const salvarAssinatura = () => {
		const imgURL = sign.getTrimmedCanvas().toDataURL("image/png");
		modifyPdf(imgURL);
	};

	async function modifyPdf(imgUrl) {
		const pdfDoc = await PDFDocument.load(pdf);
		const imgLoaded = await pdfDoc.embedPng(imgUrl);
		const imgDims = imgLoaded.scale(0.3);

		const pages = pdfDoc.getPages();
		const firstPage = pages[0];
		const { width, height } = firstPage.getSize();
		firstPage.drawImage(imgLoaded, {
			x: 340,
			y: height / 1.15,
			width: imgDims.width,
			height: imgDims.height,
		});

		const pdfBytes = await pdfDoc.save();

		const bytes = new Uint8Array(pdfBytes);

		setPdf(bytes);
		let pedidosAssinados = [];
		// assinar demais pedidos do cliente, caso exista
		for await (let pedido of romaneiosDoCliente) {
			if (pedido.danfe) {
				continue;
			}
			const danfe = danfes.find(
				(item) => item.numero_pedido == pedido.numero_pedido
			);
			const uintArray = new Uint8Array(
				Object.keys(danfe.danfe).map((item) => Number(danfe.danfe[item]))
			);

			const pdfDoc = await PDFDocument.load(uintArray);

			const imgLoaded = await pdfDoc.embedPng(imgUrl);
			const imgDims = imgLoaded.scale(0.25);

			const pages = pdfDoc.getPages();
			const firstPage = pages[0];
			const { width, height } = firstPage.getSize();
			firstPage.drawImage(imgLoaded, {
				x: 340,
				y: height / 1.15,
				width: imgDims.width,
				height: imgDims.height,
			});

			const pdfBytes = await pdfDoc.save();

			const bytes = new Uint8Array(pdfBytes);
			pedidosAssinados.push({
				...danfe,
				PDF_SIGNED_FINAL: bytes,
			});
		}

		setDanfesCliente(pedidosAssinados);
		handlerSimpleClose();
	}

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row style={{ border: "solid 0.5px black" }}>
						<SignatureCanvas
							canvasProps={{ height: 350, className: "sigCanvas" }}
							ref={(data) => setSign(data)}
						/>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={salvarAssinatura}>
						Assinar
					</Button>
					<Button variant="secondary" onClick={() => sign.clear()}>
						Apagar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
