import { Fragment } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NavbarEquipamentos() {
    const user = useSelector(state => state.userSlice)
    return (
        <Fragment>
            <Nav variant='tabs'>
                {
                    user.tipo.toLowerCase() === 'empresa' || user.tipo.toLowerCase() === 'gestor' ?
                        <Fragment>
                            <Nav.Item>
                                <NavLink className="nav-link" to='cadastrados'>Cadastrados</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='cadastro'>Cadastrar</NavLink>
                            </Nav.Item>
                        </Fragment>
                        :
                        ""
                }
            </Nav>
            <Outlet />
        </Fragment>
    )
}






