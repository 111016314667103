import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState:{
        sidebar: true
    },
    reducers:{
        switchSidebar: (state) => {
            state.sidebar = !state.sidebar
        },

    }

})

export const { switchSidebar } = sidebarSlice.actions
export default sidebarSlice.reducer