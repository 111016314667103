import { Fragment, useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateCargasList, updateFiliaisList } from "../../../redux/slicers/user";
import { getData } from "../../../services/Servicos";
import axios from "axios";


export default function DashboardSeparador({ setLoading = () => {}, loading}){

    const dispatch = useDispatch()
    const [cargas, setCargas] = useState({
        separar:0
        , separadasParcialmente: 0
        , separadasTotal: 0
    })
    const user = useSelector(state => state.userSlice)

    const updateArrayCargas = async () => {
        const cargas = await getData('separacao/cargas', user.id_filial)
        const separar =   cargas.data.filter(item => item.status_pedido == 1).length ?? 0
        const separadasParcialmente =   cargas.data.filter(item => item.status_pedido == 2).length ?? 0
        const separadasTotal =   cargas.data.filter(item => item.status_pedido >= 3).length ?? 0
        setCargas(last => ({...last, separar, separadasParcialmente, separadasTotal}))
        setLoading(false)
        const cargas_unicas = cargas.data.filter(item => item.status_pedido == 1).filter((i, n, s) => s.findIndex(o => o.id === i.id) === n)
        dispatch(updateCargasList(cargas_unicas))
    }

    useEffect(()=>{
        updateArrayCargas()
    }, [])
    
    
    return (
        <Fragment>
            <Row>
                <Col sm='12'>
                    <div className="card-header d-flex pb-2"> 
                        <h5 className="">Pedidos</h5>
                    </div>
                </Col>
                <Col sm='6' lg='3'>
                    <Card style={{ width: 'auto' }} className='card-dashboard card-color-1'>
                        <h3>{loading ?<Spinner animation="grow" variant="primary"/>: cargas.separar}</h3>
                        <p>A Separar</p>
                        <i className="bi bi-exclamation-triangle bi-dashboard"></i>
                    </Card>
                </Col>
                <Col sm='6' lg='3'>
                    <Card style={{ width: 'auto' }} className='card-dashboard card-color-2'>
                        <h3>{loading ?<Spinner animation="grow" variant="primary"/>: cargas.separadasParcialmente }</h3>
                        <p>Separados Parcialmente</p>
                        <i className="bi bi-box-seam bi-dashboard"></i>
                    </Card>
                </Col>
                <Col sm='6' lg='3'>
                    <Card style={{ width: 'auto' }} className='card-dashboard card-color-3'>
                        <h3>{loading ?<Spinner animation="grow" variant="primary" />: cargas.separadasTotal }</h3>
                        <p>Finalizados</p>
                        <i className="bi bi-check-lg bi-dashboard"></i>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )

}