import { Fragment } from "react";
import { Card, Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../loading/Loading";
import "./Separacao.scss";

export function NavbarSeparacao() {
    const user = useSelector(state => state.userSlice)
    return (
        <Fragment>
            <Nav variant='tabs'>
                {
                    user.tipo.toLowerCase() === 'separador' ?
                        <Fragment>
                            <Nav.Item>
                                <NavLink className="nav-link" to='cargas'>Cargas</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='separar'>Conferir Produtos</NavLink>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <NavLink className="nav-link" to='pdf'>QR Code</NavLink>
                            </Nav.Item> */}
                            {/* <Nav.Item>
                                <NavLink className="nav-link" to='cargas'>Cargas</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='romaneios'>Romaneios</NavLink>
                            </Nav.Item> */}
                        </Fragment>
                        :
                        ""
                }
            </Nav>
            <Outlet />
        </Fragment>
    )
}

export function SeparacaoLayout({ children }) {
    return (
        <Fragment>
            <Card className='card-user'>
                {children}
            </Card>
        </Fragment>
    )
}