import { useEffect, useRef, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import Loading from "../loading/Loading"

import './Modal.scss'
import { update, updateCargasList } from "../../redux/slicers/user"
import { Unauthorized, updateTable, updateTableNew, verificaTipoUsuario } from "../../services/Servicos"
import ModalError from "../modal/ModalError"

export default function ModalAtualizarCarga(props) {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState({
        isLoading: false,
        msg: "Salvar",
        style: 'primary'
    })
    const [error, setError] = useState(false)
    const [parametros, setParametros] = useState({
        [props.parametro]: props.currentValue
    })
    const user = useSelector(state => state.userSlice)

    const handlerSimpleClose = () => props.setShow(last => ({...last, show: false }))

    
    const onChangeValueDate = (e) => {
        setParametros(last => ({
                ...last,
                [e.target.name] : e.target.value
            })
        ) 
    }
    
    const onSaveInfo = (e) => {
        if (!parametros.data_saida) {
            setError(true)
            return
        }
        setLoading({isLoading: true, msg:'Carregando',  style:'warning'})
        const [ano, mes, dia] = String(parametros.data_saida).split('-')

        const body = {
            ...parametros,
            data_saida : new Date(ano, mes - 1, dia).toISOString()
        }

        updateTableNew(`cargas?id=${props.cargas_id}`, body)
                .then(resp => {
                    setLoading({
                        isLoading: false,
                        msg: 'Atualizado com Sucesso'
                    })

                    setTimeout(() => {
                        updateCargasList()
                        window.location.reload()
                    }, 1000)
                })
                .catch(error => {
                    // let msg = null
                    if(error.msg.toLowerCase() === 'unauthorized'){
                        Unauthorized(dispatch, update)
                        window.location.replace('/login')
                    }                    
                    setLoading({
                        isLoading: false,
                        msg: 'Error'
                    })
                })
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={e => e.preventDefault()}>
                        <Row className='align-items-end'>
                            <Col sm='4'>
                                <Form.Label name='parameter'>{String(props.parametro).replaceAll('_',' ').toUpperCase()}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type='date' 
                                className="form-control mb-2" 
                                placeholder={props.currentValue} 
                                maxLength={5}
                                name={props.parametro}
                                onChange={onChangeValueDate}
                                value={parametros[props.parametro]} 
                                />
                                
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{width:'100%'}}>
                            
                        <Col>
                            <Button variant={loading.style} onClick={onSaveInfo} disabled={loading.style !== 'primary' ? true : false}>
                                {
                                    loading.isLoading ?
                                    <Loading msg={loading.msg} />:
                                    loading.msg
                                }
                            </Button>
                        </Col>
                        <Col>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            {error ? <ModalError show={error} setShow={setError} msg={"Data Não Informada"} title={'Erro na Requisição'}/> : ""}
        </>
    )
}
