import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { update } from "../../redux/slicers/user";
import { createDailyReport, Unauthorized } from "../../services/Servicos";

import "./Modal.scss";
import ModalError from "./ModalError";
import LoadingNew from "../loading/LoadingNew";

import imageCompression from "browser-image-compression";
export default function ModalDiarioDeBordo(props) {
	const userData = useSelector((state) => state.userSlice);

	const [showLoading, setShowLoading] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar",
	});

	const [error, setError] = useState({
		flag: false,
		msg: "",
	});

	const [user, setUser] = useState({
		nome: userData.name,
		cpf: userData.cpf,
		email: userData.email,
		caminhao: userData.caminhaoSelecionado.caminhao_placa,
		cargas_id: userData.cargas_id,
		mensagem: "",
		data: "",
		fotoReports: [],
	});

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, show: false }));

	const dispatch = useDispatch();

	const clickSalvar = async (e) => {
		e.preventDefault();

		// if (!userData.processo_entrega.flag) {
		//     setError({
		//         flag: true,
		//         msg: {tipo: 'Erro', motivo: 'Tentativa de Lancamento de diário de bordo', mensagem: 'Você nao está em PROCESSO DE ENTREGA'}
		//     })
		//     return
		// }
		setShowLoading((last) => ({
			...last,
			flag: true,
			style: "warning",
			msg: "Salvando...",
		}));

		const reports = await Promise.all(
			user.fotoReports.map((item) =>
				createDailyReport({
					caminhao: user.caminhao,
					cargas_id: user.cargas_id,
					data: user.data,
					cpf: user.cpf,
					email: user.email,
					mensagem: user.mensagem,
					nome: user.nome,
					fotoReport: item,
				})
			)
		);

		setShowLoading((last) => ({
			...last,
			flag: false,
			style: "success",
			msg: "Salvo",
		}));
		setTimeout(() => {
			// window.location.reload()
			handlerSimpleClose();
		}, 1000);

		return;
		await createDailyReport(user)
			.then((reponse) => {
				setShowLoading((last) => ({
					...last,
					flag: false,
					style: "success",
					msg: "Salvo",
				}));
				setTimeout(() => {
					// window.location.reload()
					handlerSimpleClose();
				}, 1000);
			})
			.catch((err) => {
				setError({
					flag: true,
					msg: {
						...err.response.data,
						status: err.response?.status || "",
						statusText: err.response?.statusText || "",
					},
				});

				setShowLoading((last) => ({
					...last,
					flag: false,
					style: "primary",
					msg: "Salvar",
				}));

				if (err.msg === "Unauthorized") {
					Unauthorized(dispatch, update);
				}
			});
	};

	const onChangeInputFile = async (e) => {
		e.preventDefault();
		if (!e.target.files.length) {
			setError({
				flag: true,
				msg: e.target.files[0],
			});
			return;
		}

		setUser((last) => ({
			...last,
			fotoReports: [...e.target.files],
		}));

		return;
	};

	const onChangeTextArea = (e) => {
		e.preventDefault();
		setUser((last) => ({
			...last,
			mensagem: e.target.value,
		}));
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="sm"
			>
				<Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
				<Modal.Body>
					{user.fotoReports.length ? (
						<div
							className="fotos-reports"
							style={{ height: `${user.fotoReports.length * 3 + 10}vh` }}
						>
							{user.fotoReports.map((item, key) => (
								<Card.Img
									src={URL.createObjectURL(item)}
									style={{ left: 50 * key, bottom: 25 * key }}
									key={key}
								/>
							))}
						</div>
					) : (
						""
					)}
					<div className="text-center">
						<h3
							style={{
								fontSize: "16px",
								textAlign: "center",
								marginBottom: "25px",
							}}
						>
							Relatório da Viagem
						</h3>
						<h6 className="text-muted">{new Date().toLocaleString()}</h6>
						<Row className="my-1">
							<Form>
								<Row className="p-3 text-left">
									<Form.Label>Acontecimento:</Form.Label>
									<br></br>
									<textarea
										rows={10}
										value={user.mensagem}
										onChange={onChangeTextArea}
									></textarea>
								</Row>
								<Row>
									<Col className="mb-3">
										<Form.Label>Anexar Imagem:</Form.Label>
										<Form.Control
											type="file"
											name="teste"
											onChange={onChangeInputFile}
											multiple
											// capture='environment'
										/>
									</Col>
								</Row>
							</Form>
						</Row>
						<Row className="justify-content-center">
							<Col sm="6">
								<Button
									variant={showLoading.style}
									disabled={showLoading.style !== "primary" ? true : false}
									onClick={clickSalvar}
								>
									<LoadingNew
										show={showLoading.show}
										msg={showLoading.msg}
										style={showLoading.style}
										icone={""}
									/>
								</Button>
							</Col>
							<Col sm="6">
								<Button variant="secondary" onClick={handlerSimpleClose}>
									Cancelar
								</Button>
							</Col>
						</Row>
					</div>
				</Modal.Body>
			</Modal>
			{error.flag ? (
				<ModalError
					title={"Erro no Lancamento do Diário"}
					msg={error.msg}
					show={error.flag}
					setShow={setError}
				/>
			) : (
				""
			)}
		</>
	);
}
