import { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	getDanfe,
	getDanfeVST,
	getData,
	getXMLString,
	savalDanfeAssinada,
} from "../../services/Servicos";

import "./Modal.scss";
import { PDFDocument, rgb, setStrokingCmykColor } from "pdf-lib";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import ModalPadAssinatura from "./ModalPadAssinatura";
import LoadingNew from "../loading/LoadingNew";
import { updateRomaneiosList } from "../../redux/slicers/user";

export default function ModalPDF_VerDanfe(props) {
	const canvasRef = useRef();
	const refSignedFile = useRef();
	pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

	const [pdfRef, setPdfRef] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [numberPages, setNumberOfPages] = useState(1);

	const [loading, setLoading] = useState(true);
	const [pdfFile, setPdfFile] = useState(null);
	const user = useSelector((state) => state.userSlice);
	const [pdf, setPdf] = useState(props.pdf);
	const [downloadPdf, setDownloadPdf] = useState(null);
	const dispatch = useDispatch();
	const romaneios = useSelector((state) => state.userSlice.romaneiosList.data);
	const romaneiosDoCliente = romaneios.filter(
		(item) =>
			item.cpf_cnpj === props.cpf_cnpj &&
			item.numero_pedido !== props.numero_pedido
	);
	const [danfesCliente, setDanfesCliente] = useState([]);

	async function modifyPdf() {
		const pdfDoc = await PDFDocument.load(pdf);

		const pages = pdfDoc.getPages();
		const firstPage = pages[0];
		const { width, height } = firstPage.getSize();
		firstPage.drawText(`${props.cliente}`, {
			x: 100,
			y: height / 1.078,
			size: 8,
			color: rgb(0, 0, 0),
			//   rotate: degrees(-45),
		});
		firstPage.drawText(`${new Date().toLocaleDateString()}`, {
			x: 20,
			y: height / 1.078,
			size: 8,
			color: rgb(0, 0, 0),
			//   rotate: degrees(-45),
		});

		const pdfBytes = await pdfDoc.save();

		const bytes = new Uint8Array(pdfBytes);
		const blob = new Blob([bytes], { type: "application/pdf" });
		setDownloadPdf(bytes);

		const docUrl = URL.createObjectURL(blob);
		setPdfFile(docUrl);
	}

	useEffect(() => {
		modifyPdf();
	}, [pdf]);

	const renderPage = useCallback(
		(pageNum, pdf = pdfRef) => {
			pdf &&
				pdf.getPage(pageNum).then(function (page) {
					const viewport = page.getViewport({ scale: 1.3 });
					const canvas = canvasRef.current;
					const context = canvas.getContext("2d");
					var outputScale = window.devicePixelRatio || 1;
					canvas.width = Math.floor(viewport.width * outputScale);
					canvas.height = Math.floor(viewport.height * outputScale);
					canvas.style.width = Math.floor(viewport.width) + "px";
					canvas.style.height = Math.floor(viewport.height) + "px";

					var transform =
						outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

					var renderContext = {
						canvasContext: context,
						transform: transform,
						viewport: viewport,
					};
					page.render(renderContext);
				});
		},
		[pdfRef]
	);

	useEffect(() => {
		renderPage(currentPage, pdfRef);
	}, [pdfRef, currentPage, renderPage]);

	useEffect(() => {
		if (!pdfFile) return;
		const loadingTask = pdfjsLib.getDocument(pdfFile);
		loadingTask.promise.then(
			(loadedPdf) => {
				setPdfRef(loadedPdf);
				setNumberOfPages(loadedPdf.numPages);
				setLoading(false);
			},
			function (reason) {}
		);
	}, [pdfFile]);

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false, msg: "" }));

	const [showSignPad, setShowSignPad] = useState({
		flag: false,
	});

	const blobToFile = (blob, fileName) => {
		blob.lastModifiedDate = new Date();
		blob.name = fileName;
		const file = new File([blob], `${fileName}.pdf`);
		return file;
	};

	const [showLoading, setShowLoading] = useState({
		flag: false,
		style: "dark",
		msg: "Salvar",
	});

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex">
						{props.title} - {props.numero_pedido}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row style={{ justifyContent: "center", padding: "0" }}>
						<div
							style={{
								display: "flex",
								maxHeight: "75vh",
								overflow: "auto",
								marginTop: "20px",
								padding: 0,
								justifyContent: "center",
								position: "relative",
							}}
						>
							<canvas
								ref={canvasRef}
								style={{
									boxShadow: "#000000b8 0px 0px 24px inset",
									padding: "64px",
									backgroundColor: "#9f9292",
								}}
							></canvas>
						</div>
					</Row>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
}
