import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
	valorMoeda,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";

export default function GraficoPadraoComDataPieGastos({
	title,
	parametroGrafico,
	arrayData,
	dataFiltered,
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		title: "Média de Valor Carregado por Caminhão",
		type: "pie",
		chart: {
			id: "chart-pedido-sem-logisica",
			toolbar: {
				show: true,
			},
		},
		
		options: {
			dataLabels: {
				formatter: function (value, index) {
					const name = index.w.globals.labels[index.seriesIndex];
					return [name, value.toFixed(1) + "%"];
					// return `${valorMoeda(value)}`;
				},
				style: {
					fontSize: "10px",
					colors: ["#fff"],
				},
			},
			plotOptions: {
				pie: {
					donut: {
						labels: {
							show: true,
							formatter: function (val) {
								if (parametroGrafico.includes('media')) {
									return `${valorMoeda(
										val.config.series.reduce((p, c) => p + c, 0) /
											val.config.series.length
									)}`;
									
								}
								return `${valorMoeda(val.config.series.reduce((p, c) => p + c, 0))}`;
							},
							total: {
								showAlways: true,
								show: true,
								formatter: function (val) {
									
									if (parametroGrafico.includes('media')) {
										return `${valorMoeda(
											val.config.series.reduce((p, c) => p + c, 0) /
												val.config.series.length
										)}`;
										
									}
									return `${valorMoeda(val.config.series.reduce((p, c) => p + c, 0))}`;
								},
							},
						},
					},
				},
			},
			tooltip: {
				enabled: true,
				y: {
					formatter: function (val) {
						return `${valorMoeda(val)}`;
					},
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
			},
		},
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		let resultado = dataFiltered || arrayData;
		let opt = {
			...options,
			series: resultado
				.sort((a, b) => {
					if (a[parametroGrafico] > b[parametroGrafico]) return 1;
					if (a[parametroGrafico] < b[parametroGrafico]) return -1;
					return 0;
				})
				.map((item) => Number(Number(item[parametroGrafico]).toFixed(2))),
			options: {
				...options.options,
				labels: resultado
					.sort((a, b) => {
						if (a[parametroGrafico] > b[parametroGrafico]) return 1;
						if (a[parametroGrafico] < b[parametroGrafico]) return -1;
						return 0;
					})
					.map((item) => item.placa),
			}
			
		}
	setOptions(opt)
		setTimeout(() => {
				setLoading(false);
		}, 1000);
	}, [arrayData, dataFiltered]);


	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center  pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options?.series.length ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica-pie`}>
						<Chart
							options={options.options}
							series={options.series}
							type="donut"
							height={400}
						/>
					</div>
				)} 
			</Card.Body>
		</Card>
	);
}
