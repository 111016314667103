import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";

export default function GraficoPadraoComDataHorizontal_2({
	url,
	title,
	unidadeMedida = "",
	parametroGrafico,
	posisaoUnidadeMedida,
	colors = "#f60809",
	dataParam,
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
				toolbar: {
					show: true,
				},
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					columnWidth: "90%",
					barHeight: "70%",
					dataLabels: {
						position: "top",
						// orientation: 'vertical',
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value.toFixed(2))}`;
					}
					return `${numerosEditadoBR(value.toFixed(0))} ${unidadeMedida} `;
				},
				offsetX: 75,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},

				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			xaxis: {
				labels: {
					show: false,
					style: {
						fontSize: "8px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: true,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});
	const [loading, setLoading] = useState(true);

	const [periodoInicial, setPeriodoInicial] = useState(``);

	const getApiData = async (data_inicial, data_final) => {
		setLoading(true);
		let filial = user.tipo === "empresa" ? "" : `?filiais=(${user.id_filial})`;

		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setPeriodoInicial(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);

		getAllData(url, {
			stringfilters: `AND CONVERT(CREATED_AT, DATE) >= '${data_inicial}' AND CONVERT(CREATED_AT, DATE) <= '${data_final}'`,
		})
			.then((resp) => {
				const resultado = resp.data;
				if (user.tipo !== "empresa" && user.id_filial != 2) {
					const filiais = [...user.filiaisList.data].filter(
						(item) => item.idFilial == user.id_filial
					);

					const resultatoGeral = filiais
						.map((item) => {
							return {
								filial: item.identificacaoInterna.replace("FERMAT - ", ""),
								valorTotal: resultado
									.filter((item2) => item2.id_filial == item.idFilial)
									.reduce((p, c) => p + c[parametroGrafico], 0),
							};
						})
						.sort((a, b) => {
							if (a.valorTotal > b.valorTotal) return 1;
							if (a.valorTotal < b.valorTotal) return -1;
							return 0;
						})
						.filter((item) => item.valorTotal);

					setOptions((last) => ({
						...last,
						options: {
							...last.options,
							colors: [colors],
							xaxis: {
								...last.options.xaxis,
								categories: resultatoGeral.map((item) => item.filial),
							},

							// title: {
							//     text: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`
							// },
							// title:
						},
						series: [
							{
								name: title,
								data: resultatoGeral.map((item) => item.valorTotal),
							},
						],
					}));
				} else {
					const filiais = [...user.filiaisList.data];

					const resultatoGeral = filiais
						.map((item) => {
							return {
								filial: item.identificacaoInterna.replace("FERMAT - ", ""),
								valorTotal: resultado
									.filter((item2) => item2.id_filial == item.idFilial)
									.reduce((p, c) => p + c[parametroGrafico], 0),
							};
						})
						.sort((a, b) => {
							if (a.valorTotal > b.valorTotal) return 1;
							if (a.valorTotal < b.valorTotal) return -1;
							return 0;
						})
						.filter((item) => item.valorTotal)
						.reverse();

					setOptions((last) => ({
						...last,
						options: {
							...last.options,
							colors: [colors],
							xaxis: {
								...last.options.xaxis,
								show: false,
								categories: resultatoGeral.map((item) => item.filial),
							},

							// title: {
							//     text: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`
							// },
							// title:
						},
						series: [
							{
								name: title,
								data: resultatoGeral.map((item) =>
									Number(item.valorTotal).toFixed(3)
								),
							},
						],
					}));
				}

				setLoading(false);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!user.filiaisList.data) return;
		const datas = getDateInit(new Date());
		getApiData(datas.data_inicial, datas.data_final);
	}, [user.filiaisList.data]);

	// const [dataParam, setDataParam] = useState({
	//     show: false,
	//     data_inicial: null,
	//     data_final: null,
	//     filtro: title

	// })

	useEffect(() => {
		if (!dataParam?.data_final && !dataParam?.data_inicial) return;
		setLoading(true);

		getApiData(dataParam?.data_inicial, dataParam?.data_final);
	}, [dataParam]);
	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				{/* <span className="filter-data" onClick={() => setDataParam(last => ({...last, show: true}))}>
                    Filtrar Data 
                <span className="filho"style={{backgroundColor:colors}}>{periodoInicial}</span>
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
						/>
					</div>
				)}
			</Card.Body>
			{/* {dataParam.show ? <ModalAtualizarDataEntradaVST fn={getApiData} title={'Perido do Gráfico'} show={dataParam.show} setShow={setDataParam} {...dataParam} setDataParam={setDataParam} /> : ""} */}
		</Card>
	);
}
