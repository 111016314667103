import { QRCodeSVG } from "qrcode.react";
import { Fragment, useEffect, useRef, useState } from "react";
import ModalTableProdutos from "../../modal/ModalTableProdutos";

import "./BodyRow.scss";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	updateEmbalagensChecked,
	updateProdutosListChecked,
} from "../../../redux/slicers/user";
import ModalAtualizarDimensoes from "../../modal/ModalAtualizarDimensoes";
import ModalAtualizarDimensoesEmbalagens from "../../modal/ModalAtualizarDimensoesEmbalagens";
import {
	getAllData,
	getDanfe,
	getData,
	getXMLString,
	updateTable,
} from "../../../services/Servicos";
import ModalImprimir from "../../modal/ModalImprimir";
import ModalImprimirQRCode from "../../modal/ModalImprimirQRCode";
import ModalError from "../../modal/ModalError";
import ModalVisualizarPDFComputer from "../../modal/ModalVisualizarPDFComputer";
import ModalListarProdutosEmbalados from "../../modal/ModalListarProdutosEmbalados";
import { getErrorMessage } from "../../../services/helpers";

const headListProdutos = [
	"index",
	"admin",
	"conferido",
	"numero_pedido",
	"codigo",
	"descricao",
	"qtde",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"abreviatura",
	"qr_sku",
	"numero_item_embalagem",
	// , 'numero_embalagem'
];
export default function BodyRowTabelaSeparacao({
	headList,
	bodyList,
	index,
	qr_sku = 0,
	caminhao,
	setArrayFilters = () => {},
	setLoadingFinalizarParcial = () => {},
	setError = () => {},
	setSeparador = () => {},
	separador,
	cargaSelecionada,
	tableLength,
	updateList,
}) {
	const userEmail = useSelector((state) => state.userSlice.email);
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;
	const refRow = useRef(null);
	const refRowCheck = useRef(null);
	const refEditar = useRef(null);

	const [produtos, setProdutos] = useState({
		flag: "",
		ID_EMPRESA: "",
		ID_FILIAL: "",
		ID_PEDIDO: "",
	});
	const [editarDimensoes, setEditarDimensoes] = useState({
		flag: false,
		dimensao: "",
		produto: "",
		descricao: "",
		parametro: "",
		qr_sku: "",
		msg_button: "",
		currentValue: 0,
	});
	const [imprimir, setImprimir] = useState({
		flag: false,
		title: "",
		qr_sku: "",
		msg_obj: {},
	});
	const [verProdutos, setVerProdutos] = useState({
		flag: false,
		produtosDaEmbalagem: [],
	});
	const [checked, setChecked] = useState(bodyList.conferido)

	const isSeparador = () => {
		if (separador.verificado && !separador.isSeparador) {
			
			setError({
				message: "Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
				show: true,
			});
			return false
		}
		return true
	}

	const setHandlerClicked = async (e, bodyList, checkedL) => {

		
		if(!isSeparador()) return 
		const qr_sku = e.target.name.trim();
		const obj = {
			conferido: checkedL,
			data_conferencia: new Date().toISOString(),
			user_conferencia: userEmail,
			setor_conferencia: user.local_separacao,
			comprimento: String(bodyList.comprimento).replace(",", "."),
			altura: String(bodyList.altura).replace(",", "."),
			largura: String(bodyList.largura).replace(",", "."),
			peso: String(bodyList.peso).replace(",", "."),
			volume: String(bodyList.volume).replace(",", "."),
		};
		setChecked(checkedL)
		try {
			if (bodyList.tipo == "PRODUTO") {
				const result = await updateTable(
					"carga/separador/produto/conferido",
					bodyList.id,
					obj
				);
				
				updateList((last) => [
					...last.map((item) => {
						let item_ = { ...item }
						if (item.qr_sku === qr_sku) {
							item_ = {
								...item_,
								qr_sku: qr_sku,
								conferido: checkedL,
								data: checked ? new Date().toISOString():"null",
								user: checked ? userEmail :"null",
								setor: checked ? user.local_separacao :"null",
								}
						}
						return item_
					}),
				]);
				
			} else if (bodyList.tipo === "EMBALAGEM") {
				const result = await updateTable(
					"carga/separador/embalagem/conferido",
					bodyList.numero_embalagem,
					obj
				);
				updateList((last) => [
					...last.map((item) => {
						let item_ = { ...item }
						if (item.qr_sku === qr_sku) {
							item_ = {
								...item_,
								qr_sku: qr_sku,
								conferido: checkedL,
								data: checked ? new Date().toISOString():"null",
								user: checked ? userEmail :"null",
								setor: checked ? user.local_separacao :"null",
							}

						}
						return item_
					}),
				]);
			}
		} catch (error) {
			setError({
				show: true,
				message: getErrorMessage(error)
			})
		}
	};

	const onClickEditar = async (
		e,
		parametro,
		qr_sku,
		produto,
		currentValue,
		descricao
	) => {
		if (
			cargaSelecionada.tipo_transporte == "PRÓPRIO" &&
			cargaSelecionada.tipo_carga == "PADRÃO" &&
			!cargaSelecionada.caminhao
		) {
			setError({
				message: "Não é Possível editar dimensões para carga PADRÃO sem CAMINHÃO",
				show: true,
			});

			return;
		}
		
		if(!isSeparador()) return 
		if (!separador.verificado) {
			
			const teste = await getData(
				"cargas/produtos/separador/verificar",
				bodyList.cargas_id,
				{ id_filial: bodyList.id_filial }
			);

			if (teste.data?.exists & (user.email?.toLowerCase() !== teste.data.separador?.toLowerCase()) & (user?.local_separacao?.toLowerCase() === teste.data?.setor?.toLowerCase())) {

				setError({
					message: "Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
					show: true,
				});

				setSeparador({ verificado: true, isSeparador: false });
				return;
			}
			setSeparador({ verificado: true, isSeparador: true });
		}
		let maxValue = caminhao[parametro];

		if (parametro === "peso") {
			maxValue = caminhao.capacidade ?? 0;
		}
		setEditarDimensoes((last) => ({
			...last,
			flag: true,
			dimensao: parametro,
			produto,
			qr_sku,
			currentValue,
			maxValue,
			descricao,
			cargas_id: bodyList.cargas_id,
			updateList,
			bodyList: bodyList
		}));
	};

	const onClickPrint = (e) => {
		setHandlerClicked({target:{name:bodyList.qr_sku}}, bodyList, true)
		// refRowCheck.current.click();
		setImprimir({
			flag: true,
			msg_obj: bodyList,
			title: "Imprimir QRCode do Produto",
		});
	};

	const [loading, setLoading] = useState(false);

	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});

	const onClickTableRow = (e) => {
		// e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = index;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = index;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
	};


	const [loadingBtn, setLoadingBtn] = useState(false);

	const onClickProdutos = async (e, numero_embalagem) => {
		setLoadingBtn(true);

		try {
			const produtosGet = await getAllData("gestao/cargas/produtos", {
				numero_embalagem: numero_embalagem,
				cargas_id: bodyList.cargas_id
			});

			setVerProdutos((last) => ({
				...last,
				flag: true,
				produtosDaEmbalagem: produtosGet.data,
				numero_embalagem,
			}));
		} catch (error) {
			setError({
				show: true,
				message: "message: Erro na tentativa de recuperar produtos embalados: Embalagem N°" +numero_embalagem,
			});
		}
		setLoadingBtn(false);
	};
	const fnVerificaDisabled = () => {
		if (cargaSelecionada.regiao_entrega == "LOCAL") {
			return bodyList.tipo === "EMBALAGEM"
				? !bodyList.qtde_produtos || !bodyList.peso
				: !bodyList.qtde || !bodyList.peso;
		}
		return !bodyList.altura || !bodyList.comprimento || !bodyList.largura;
	};
	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						// if(index > 19 ){
						//     return false
						// }

						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item.toLowerCase() === "admin") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										<span className="imprimir">
											<i
												className="bi bi-printer"
												title="Imprimir Romaneio"
												onClick={onClickPrint}
												ref={refEditar}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "qtde") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : bodyList.tipo == "EMBALAGEM" ? (
											<>
												<span
													className="verificar"
													style={{
														fontSize: "inherit",
														color: "inherit",
														cursor: "pointer",
													}}
												>
													{String(bodyList.qtde_produtos).replace(".", ",")}
												</span>
												{loadingBtn ? (
													<Spinner animation="border" color="black" size="sm" />
												) : (
													<i
														className="bi bi-plus-circle bi-circle-animation"
														title="Ver Produtos"
														style={{ cursor: "pointer" }}
														onClick={(e) => {
															onClickProdutos(e, bodyList.numero_embalagem);
														}}
													></i>
												)}
											</>
										) : (
											<>
												<span
													className="verificar"
													style={{ fontSize: "inherit", color: "inherit" }}
												>
													{String(bodyList.qtde).replace(".", ",")}
												</span>
												<span
													className="verificar"
													style={{
														fontSize: "inherit",
														color: "inherit",
														cursor: "pointer",
													}}
												>
													<i
														className="bi bi-pencil"
														title="Editar Quantidade"
														onClick={(e) => {
															onClickEditar(
																e,
																"qtde",
																bodyList.qr_sku,
																bodyList.codigo,
																bodyList.qtde,
																bodyList.descricao
															);
														}}
													></i>
												</span>
											</>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "comprimento") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.comprimento).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Comprimento"
													onClick={(e) => {
														onClickEditar(
															e,
															"comprimento",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.comprimento,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index}>
									{filiaisList.find(
										(item) => item.idFilial == bodyList.id_filial
									)?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item.toLowerCase() === "altura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.altura).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Altura"
													onClick={(e) => {
														onClickEditar(
															e,
															"altura",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.altura,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "largura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.largura).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil bi-circle-animation"
													title="Editar Largura"
													onClick={(e) => {
														onClickEditar(
															e,
															"largura",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.largura,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "peso") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{Number(bodyList[item]).toLocaleString()}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil bi-circle-animation"
													title="Editar Peso"
													onClick={(e) => {
														onClickEditar(
															e,
															"peso",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.peso,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "volume") {
							return (
								<td key={_index}>
									{String(bodyList.volume).replace(".", ",")}
								</td>
							);
						} else if (item === "conferido") {
							return (
								<td
									key={_index}
									className={item}
									style={{ minWidth: "50px", textAlign: "center" }}
								>
									<Form.Check
										ref={refRowCheck}
										checked={checked}
										onChange={(e) => setHandlerClicked(e, bodyList, !checked)}
										name={bodyList.qr_sku}
										disabled={fnVerificaDisabled}
									/>
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td
									key={_index}
									className={item}
									style={{ whiteSpace: "nowrap" }}
								>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_code_id + "}"} />
								</td>
							);
						} else if (
							item === "carregado" ||
							item === "descarregado" ||
							item === "planejado"
						) {
							return (
								<td
									key={_index}
									style={{ minWidth: "50px", textAlign: "center" }}
								>
									<Form.Check
										ref={refRowCheck}
										defaultChecked={bodyList[item] ?? false}
										disabled
									/>
								</td>
							);
						} else if (
							item === "data_carregamento" ||
							item === "data_descarregamento" ||
							item === "data_conferencia" ||
							item === "data_cancelamento" ||
							item === "DTEMISSAO"
						) {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "codigo/embalagem") {
							return (
								<td key={_index}>
									{" "}
									{bodyList.tipo == "EMBALAGEM"
										? String(bodyList.numero_embalagem).padStart(7, 0)
										: bodyList.codigo}
								</td>
							);
							// return bodyList[item] ? <td key={_index}> { String(bodyList.numero_embalagem).padStart(7, 0)}</td> : <td key={_index}></td>
						} else if (item === "embalado") {
							return <td key={_index}> {bodyList.embalado ? "SIM" : "NÃO"}</td>;
						} else if (item === "abreviatura") {
							return <td key={_index}> {bodyList.abreviatura ?? "N/A"}</td>;
						}
						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item] ?? "NÃO"}
							</td>
						);
					})}
				</tr>
			}
			{produtos.flag ? (
				<ModalTableProdutos
					show={produtos.flag}
					setShow={setProdutos}
					ID_EMPRESA={produtos.ID_EMPRESA}
					ID_FILIAL={produtos.ID_FILIAL}
					ID_PEDIDO={produtos.ID_PEDIDO}
				/>
			) : (
				""
			)}
			{editarDimensoes.flag ? (
				<ModalAtualizarDimensoes
					show={editarDimensoes.flag}
					setShow={setEditarDimensoes}
					{...editarDimensoes}
				/>
			) : (
				""
			)}

			{imprimir.flag ? (
				<ModalImprimirQRCode
					title={imprimir.title}
					show={imprimir.flag}
					setShow={setImprimir}
					msg_obj={imprimir.msg_obj}
				/>
			) : (
				""
			)}
			{verPDF.show ? (
				<ModalVisualizarPDFComputer
					title={verPDF.title}
					pdf={verPDF.PDF}
					setShow={setVerPDF}
					show={verPDF.show}
				/>
			) : (
				""
			)}
			{verProdutos.flag ? (
				<ModalListarProdutosEmbalados
					setShow={setVerProdutos}
					show={verProdutos.flag}
					produtos={verProdutos.produtosDaEmbalagem}
					headList={headListProdutos}
					setBtnLoaging={setLoadingBtn}
					{...verProdutos}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
