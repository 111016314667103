import { createRef, Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import "./Modal.scss";
import { v4 as uuidv4 } from "uuid";
import {
	getAllData,
	getData,
	getStatusCarga,
	getTableColumns,
	verificaTipoUsuario,
} from "../../services/Servicos";
import { returnDataWithCorrection } from "../../services/helpers";
import ModalListarProdutos from "./ModalListarProdutos";
import ModalListarPedidosTransferencia from "./ModalListarPedidosTransferencia";
import { ListarRomaneios } from "../planoLogistica/romaneios/Romaneios";
import BotaoSalvar from "../botoes/BotaoSalvar";
import {
	update,
	updateCargaSelecionada,
	updateCargasList,
	updateEmbalagensList,
	updateProdutosEEmbalagens,
	updateProdutosList,
	updateRomaneiosList,
} from "../../redux/slicers/user";
import ModalListarEmbalagens from "./ModalListarEmbalagens";
import {
	addProduto,
	danfeController,
	fotosController,
	removeProduto,
} from "../../redux/slicers/carga";

const headList = [
	"index",
	"select",
	"id",
	"id_filial",
	"status",
	"nome",
	"qtde_pedidos",
	"qtde_produtos",
	"embalagens",
];
const headListPedidos = [
	"index",
	"emissao",
	"id_filial",
	"tipo",
	"nome_razao",
	"status",
	"numero_pedido",
];

const tableRef = createRef();
const tbodyRef = createRef();

export default function ModalSelectCargas({
	show,
	setShow,
	setProdutosEEmbalagens,
}) {
	const user = useSelector((state) => state.userSlice);
	const motorista = user.cpf;
	const dispatch = useDispatch();

	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, flag: false }));

	const [loading, setLoading] = useState(true);
	const [cargas, setCargas] = useState([]);
	const [cargasSelecionadas, setCargasSelecionadas] = useState([]);
	const verificaCargasDiponiveis = async () => {
		try {
			const cargas = await getAllData("cargas/caminhoneiro", {
				caminhoneiro: user.cpf,
			});

			setCargas(cargas.data);
		} catch (error) {}
		setLoading(false);
	};
	const [btn, setBtn] = useState({
		msg: "Carregar",
		flag: false,
		style: "primary",
	});

	useEffect(() => {
		verificaCargasDiponiveis();
	}, []);

	const onFinalizarCargaRegional = async (pedidos, produtos, embalagens) => {
		dispatch(removeProduto([]));
		const produtosCarregados = produtos.filter(
			(item) => item.planejado && !item.embalado && item.carregado
		);
		const embalagensPlanejadas = embalagens.filter((item) => item.carregado);

		for (const produtos in produtosCarregados) {
			const item = produtosCarregados[produtos];
			const romaneio = pedidos.find(
				(item2) => item.numero_pedido === item2.numero_pedido
			)?.romaneio;
			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					descarregado: item.descarregado,
					step: item.step_planejado,
					tipo: "PRODUTO",
					cor_caixa: item.cor_caixa,
					romaneio,
					pedido: { ...item },
					data_carregamento: new Date(item.data_carregamento).getTime(),
				})
			);
		}

		for (const embalagems in embalagensPlanejadas) {
			const item = embalagensPlanejadas[embalagems];

			const romaneio = pedidos.find(
				(item2) => item.numero_pedido === item2.numero_pedido
			)?.romaneio;

			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					descarregado: item.descarregado,
					step: item.step_planejado,
					tipo: "EMBALAGEM",
					cor_caixa: item.cor_caixa,
					romaneio,
					pedido: { ...item },
					data_carregamento: new Date(item.data_carregamento).getTime(),
				})
			);
		}
		const carregados = produtos.filter((item) => item.conferido);
		const embalagensSeparadas = embalagens.filter((item) => item.conferido);

		dispatch(updateProdutosList(carregados));
		dispatch(updateEmbalagensList(embalagensSeparadas));

		// const romaneiosGet =  await getData('cargas/pedidos/separador', cargas_id)
	};
	const onFinalizar = async () => {
		dispatch(
			danfeController({
				action: "removeAll",
			})
		);
		dispatch(
			fotosController({
				action: "delete-all",
			})
		);
		dispatch(
			update({
				processo_entrega: {
					flag: false,
					locations: [],
				},
			})
		);

		if (!cargasSelecionadas.length) {
			dispatch(removeProduto([]));
			dispatch(updateCargaSelecionada([]));
			dispatch(updateProdutosEEmbalagens([]));

			dispatch(updateProdutosEEmbalagens([]));

			handlerSimpleClose();
			return;
		}
		setBtn({
			flag: true,
			msg: "Carregando",
			style: "warning",
		});

		try {
			const produtos = await Promise.all([
				...cargasSelecionadas.map((item) =>
					getData("cargas/produtos/separador", item)
				),
			]);
			const embalagens = await Promise.all([
				...cargasSelecionadas.map((item) =>
					getData("cargas/funcionarios/embalagens/carga_id", item)
				),
			]);
			const pedidos = await Promise.all([
				...cargasSelecionadas.map((item) => getData("entregas/carga", item)),
			]);

			let arrayProdutos = [
				...produtos.map((item) => item.data).reduce((p, c) => [...c, ...p], []),
			];
			let arrayEmbalagens = [
				...embalagens
					.map((item) => item.data)
					.reduce((p, c) => [...c, ...p], []),
			];

			const produtosA = arrayProdutos
				.filter((item) => !item.embalado && item.conferido)
				.map((item) => ({
					...item,
					tipo: "PRODUTO",
					integrado: item.descarregado ? true : false,
				}));
			const embalagensA = arrayEmbalagens
				.filter((item) => item.conferido)
				.map((item) => ({
					...item,
					tipo: "EMBALAGEM",
					integrado: item.descarregado ? true : false,
				}));
			setProdutosEEmbalagens([...produtosA, ...embalagensA]);
			dispatch(updateProdutosEEmbalagens([...produtosA, ...embalagensA]));
			dispatch(
				updateRomaneiosList(
					pedidos.map((item) => item.data).reduce((p, c) => [...c, ...p], [])
				)
			);
			dispatch(
				updateProdutosList(
					produtos.map((item) => ({ ...item, tipo: "PRODUTO" }))
				)
			);

			const cargasEnt = await Promise.all([
				...cargasSelecionadas.map((item) =>
					getData("cargas/motorista/find-carga-disponivel-entrega", item)
				),
			]);
			const cargas = cargasEnt.map((item) => item.data);
			dispatch(updateCargaSelecionada(cargas));

			if (cargas.find((item) => item.data_inicio_entrega)) {
				dispatch(
					update({
						processo_entrega: {
							flag: true,
							locations: [],
						},
					})
				);
			}

			if (cargas.find((item) => item.regiao_entrega !== "LOCAL")) {
				onFinalizarCargaRegional(
					pedidos.map((item) => item.data).reduce((p, c) => [...c, ...p], []),
					produtosA,
					embalagensA
				);
			}
			setBtn({
				flag: false,
				msg: "Finalizado",
				style: "success",
			});

			setTimeout(() => {
				handlerSimpleClose();
			}, 500);
		} catch (error) {}
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Selecionar Cargas{" "}
						{loading ? (
							<Spinner animation="border" color="black" size="sm" />
						) : (
							""
						)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: "auto", margin: "0 20px" }}>
					<Table hover bordered size="sm">
						<thead>
							<tr>
								{headList.map((item, index) => {
									if (item === "index") {
										return <th key={index}>#</th>;
									} else if (item.toUpperCase() === "CREATED_AT") {
										return <th key={index}>DATA DE CRIAÇÃO</th>;
									} else if (item.toUpperCase() === "UPDATED_AT") {
										return <th key={index}>ULTIMA ATUALIZAÇÃO</th>;
									}
									return <th key={index}>{item.toUpperCase()}</th>;
								})}
							</tr>
						</thead>
						<tbody ref={tbodyRef}>
							{cargas.map((item, index, self) => {
								// if(index < indexList){
								return (
									<BodyRowCargas
										key={index}
										bodyList={item}
										index={index}
										tableLength={self.length}
										headList={headList}
										setCargasSelecionadas={setCargasSelecionadas}
									/>
								);
								// }
							})}
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer className="d-block">
					<Row>
						<Col sm="6">
							<div style={{ minWidth: "150px" }}>
								<BotaoSalvar
									msg={btn.msg}
									style={btn.style}
									fnRetorno={onFinalizar}
								/>
							</div>
						</Col>
						<Col sm="6">
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Fechar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function BodyRowCargas({ headList, bodyList, index, setCargasSelecionadas }) {
	const refRow = useRef(null);

	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;

	const [romaneiosModal, setRomaneiosModal] = useState({
		flag: false,
		cargas_id: "",
	});

	const [produtosModal, setProdutosModal] = useState({
		flag: false,
		cargas_id: "",
	});

	const [listarTransferencias, setListarTransferencias] = useState({
		flag: false,
		cargas_id: "",
	});

	const onClickVerPedidos = (e) => {
		e.preventDefault();
		// let refRowNumber = refRow.current.children[2].innerText

		setRomaneiosModal({
			flag: true,
			cargas_id: Number(bodyList.id),
		});
	};

	const onClickVerProdutos = (e) => {
		e.preventDefault();
		// let refRowNumber = refRow.current.children[2].innerText
		setProdutosModal({
			flag: true,
			cargas_id: Number(bodyList.id),
		});
	};

	const onClickVerPedidosTransferencias = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";

		setListarTransferencias({
			flag: true,
			cargas_id: Number(bodyList.id),
		});
	};

	const onSelectCarga = (e) => {
		const checked = e.target.checked;
		if (checked) {
			setCargasSelecionadas((last) => [
				...last.filter((item) => item !== e.target.value),
				e.target.value,
			]);
			return;
		}
		setCargasSelecionadas((last) => [
			...last.filter((item) => item !== e.target.value),
		]);
	};
	const [embalagensModal, setEmbalagensModal] = useState({
		flag: false,
		cargas_id: "",
	});

	const onClickVerEmbalagens = (e) => {
		e.preventDefault();
		// let refRowNumber = refRow.current.children[2].innerText
		setEmbalagensModal({
			show: true,
			cargas_id: Number(bodyList.id),
			title: "Emabalagens da Carga",
		});
	};
	return (
		<Fragment>
			{
				<tr ref={refRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "select") {
							return (
								<td key={_index}>
									<div className="admin justify-content-center">
										<Form.Check
											className="form-check-selecao-carga"
											value={bodyList.id}
											onChange={onSelectCarga}
											style={{ width: "2em", height: "2em" }}
										></Form.Check>
									</div>
								</td>
							);
						} else if (item === "transferencias") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="verificar">
											<i
												className="bi bi-question-circle"
												title="Ver Transferências"
												onClick={onClickVerPedidosTransferencias}
											></i>
										</span>
										<span className="verificar">
											{bodyList.transferencias ?? 0}
										</span>
									</div>
								</td>
							);
						} else if (item === "qtde_pedidos") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="verificar">
											<i
												className="bi bi-question-circle"
												title="Ver Pedidos"
												onClick={onClickVerPedidos}
											></i>
										</span>
										<span className="verificar">{bodyList.qtde_pedidos}</span>
									</div>
								</td>
							);
						} else if (item === "qtde_produtos") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="verificar">
											<i
												className="bi bi-question-circle"
												title="Ver Pedidos"
												onClick={onClickVerProdutos}
											></i>
										</span>
										<span className="verificar">{bodyList.qtde_produtos}</span>
									</div>
								</td>
							);
						} else if (item === "embalagens") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="verificar">
											<i
												className="bi bi-question-circle"
												title="Ver Pedidos"
												onClick={onClickVerEmbalagens}
											></i>
										</span>
										<span className="verificar">
											{bodyList.embalagens ?? 0}
										</span>
									</div>
								</td>
							);
						} else if (item === "emissao") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{bodyList.emissao
										? returnDataWithCorrection(
												bodyList.emissao
										  ).toLocaleDateString("pt-br", { timeZone: "UTC" })
										: "N/A"}
								</td>
							);
						} else if (item === "status") {
							return (
								<td
									key={_index}
									className="rowDataWidth"
									name={Number(bodyList.status)}
								>
									{bodyList.status == -2 ? (
										<div className="div-status">
											<span className="div-status-manutencao">
												{getStatusCarga(Number(bodyList.status))}
											</span>
										</div>
									) : (
										""
									)}
									{bodyList.status == -3 ? (
										<div className="div-status">
											<span
												className="div-status-inativo"
												style={{ backgroundColor: "#ff495acf" }}
											>
												{getStatusCarga(Number(bodyList.status))}
											</span>
										</div>
									) : (
										""
									)}
									{bodyList.status == -4 ? (
										<div className="div-status">
											<span
												className="div-status-inativo"
												style={{ backgroundColor: "#49a5ffcf" }}
											>
												{getStatusCarga(Number(bodyList.status))}
											</span>
										</div>
									) : (
										""
									)}
									{bodyList.status > -2
										? getStatusCarga(Number(bodyList.status))
										: ""}
								</td>
							);
						} else if (item === "nome") {
							return (
								<td
									key={_index}
									style={{ whiteSpace: "nowrap" }}
									name={Number(bodyList.status)}
								>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{romaneiosModal.flag ? (
				<ModalListarRomaneios
					msg={""}
					title={"Romaneios da Carga"}
					show={romaneiosModal}
					setShow={setRomaneiosModal}
					carga={romaneiosModal.cargas_id}
					headList={headListPedidos}
				/>
			) : (
				""
			)}
			{produtosModal.flag ? (
				<ModalListarProdutos
					msg={""}
					title={"Produtos da Carga"}
					show={produtosModal}
					setShow={setProdutosModal}
					pedido={bodyList}
				/>
			) : (
				""
			)}
			{listarTransferencias.flag ? (
				<ModalListarPedidosTransferencia
					setShow={setListarTransferencias}
					show={listarTransferencias.flag}
					title={"Adicionar Transferência a carga"}
					cargas_id={listarTransferencias.cargas_id}
				/>
			) : (
				""
			)}
			{embalagensModal.show ? (
				<ModalListarEmbalagens
					msg={""}
					setShow={setEmbalagensModal}
					{...embalagensModal}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function ModalListarRomaneios({
	msg,
	title,
	show,
	setShow,
	msgButton,
	setCarga,
	carga,
}) {
	const [headList, setHead] = useState(headListPedidos);
	const [romaneioFiltered, setRomaneioFiltered] = useState([]);
	const user = useSelector((state) => state.userSlice);

	const updateArrayRomaneios = async () => {
		let carga_id = carga;
		let id_filial = user.id_filial;

		if (user.id_filial == 2 || user.tipo.toLowerCase() == "empresa") {
			id_filial = null;
		}

		let objCons = { cargas_id: carga_id };

		if (
			user.tipo.toLowerCase() !== "empresa" &&
			user.tipo.toLowerCase() !== "gestor"
		) {
			const romaneiosGet = await getData("cargas/pedidos/separador", carga_id);
			setRomaneioFiltered(romaneiosGet.data);
			return;
		}

		let romaneiosGet = await getAllData(
			verificaTipoUsuario(user) + "cargas/pedidos/empresa",
			objCons
		);
		setRomaneioFiltered(romaneiosGet.data);
	};

	const handlerSimpleClose = () => {
		// window.location.reload()
		setShow((last) => ({ error: { flag: false } }));
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>{title}:</span>
						<span>
							<b>{carga}</b>
						</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListarRomaneios
						headList={headList}
						height={400}
						carga_id={carga}
						romaneiosList={romaneioFiltered}
					/>
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Sair
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
