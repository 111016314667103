import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./ModalFilters.scss";
import BotaoSalvar from "../botoes/BotaoSalvar";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../services/helpers";

export default function ModalFiltros({
	show,
	setShow,
	arrayData,
	filterOptions,
	arrayFilters,
	setArrayFiltes = () => {},
	setFilteredTable = () => {},
	variable,
}) {
	const user = useSelector((state) => state.userSlice);
	const filiais = user.filiaisList.data;
	const motoristas = user.funcionariosList.data;

	const [btn, setBtn] = useState({
		msg: "Filtrar",
		flag: false,
		style: "primary",
	});

	const [filters, setFilters] = useState(
		filterOptions.reduce((p, c) => Object.assign(p, { [c]: [] }), {})
	);
	const [filtersSelected, setFiltersSelected] = useState(
		filterOptions.reduce((p, c) => Object.assign(p, { [c]: [] }), {})
	);
	const [clickedFilter, setClickedFilter] = useState({
		show: false,
		filter: "",
	});
	// const [arrayFilters, setArrayFiltes] = useState([]);

	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, show: false }));

	const selectOption = (filter, option) => {
		let optionC = "";
		if (filter == "id_filial") {
			const data =
				filiais.find((item) => item.idFilial === option)
					?.identificacaoInterna || "";
			optionC = `${option} - ${data}`;
		} else if (filter == "caminhoneiro") {
			const data = motoristas.find(
				(item) =>
					item.cpf.replaceAll(".", "").replaceAll("-", "") ===
					option.replaceAll(".", "").replaceAll("-", "")
			)?.name;
			optionC = `${option.replaceAll(".", "").replaceAll("-", "")}: ${data}`;
		} else {
			optionC = option;
		}

		return optionC;
	};

	const onCheckOption = (filter, option, checked) => {
		setFiltersSelected((last) => ({
			...last,
			[filter]: [
				...last[filter].filter((item) => Object.keys(item) != option),
				{ [option]: checked },
			],
		}));
		if (checked) {
			// setArrayFiltes((last) => [...last, { [filter]: String(option) }]);
			setArrayFiltes((last) => ({
				...last,
				[variable]: [...last[variable], { [filter]: String(option) }],
			}));
		} else {
			// setArrayFiltes((last) => [...last]);
			setArrayFiltes((last) => ({
				...last,
				[variable]: [...last[variable].filter((i) => i[filter] != option)],
			}));
		}
	};

	const setFilterTableC = () => {
		if (!arrayFilters[variable].length) {
			setFilteredTable(arrayData);
			handlerSimpleClose();
			return;
		}
		setBtn({
			flag: true,
			msg: "Carregando",
			style: "warning",
		});
		let testeFilters = {};

		arrayFilters[variable].forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		arrayFilters[variable].forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [arrayData];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);

		setFilteredTable(arrayF.reverse());
		setBtn({
			flag: false,
			msg: "Ok",
			style: "success",
		});

		setTimeout(() => {
			setBtn({
				flag: false,
				msg: "Filtrar",
				style: "primary",
			});
			handlerSimpleClose();
		}, 500);
	};

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		let [key, keyValue] = value.split(":");
		keyValue = keyValue.split("-")[0];

		setArrayFiltes((last) => ({
			...last,
			[variable]: [
				...last[variable].filter(
					(item) =>
						String(item[key]).replaceAll(" ", "") !==
						keyValue.replaceAll(" ", "")
				),
			],
		}));
	};

	const checkSeleced = (filter, value) => {
		const checkedOrNot = arrayFilters[variable].find(
			(item) => item[filter] == value
		);
		return checkedOrNot ? true : false;
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>Selecionar Filtros</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{arrayFilters[variable]?.map((item) =>
						Object.keys(item).map((key, index) => {
							let optionC;
							if (key == "id_filial") {
								const data =
									filiais.find((it) => it.idFilial == item[key])
										?.identificacaoInterna || "";
								optionC = `${item[key]} - ${data}`;
							} else if (key == "caminhoneiro") {
								const data = motoristas.find(
									(it) =>
										String(it.cpf).replaceAll(".", "").replaceAll("-", "") ===
										item[key]
								)?.name;
								optionC = `${item[key]
									.replaceAll(".", "")
									.replaceAll("-", "")} - ${data}`;
							} else {
								optionC = item[key];
							}

							return (
								<span className="filters-card" key={index}>
									<span className="filters">
										<span className="title">
											{key}: {optionC}
										</span>
										<i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
									</span>
								</span>
							);
						})
					)}
					<div className="filters">
						{Object.keys(filters).map((i, key) => (
							<div className="filters-option" key={key}>
								<div
									className="filters-option-headers"
									onClick={() =>
										setClickedFilter((last) => ({
											show: last.filter == i ? !last.show : true,
											filter: i,
										}))
									}
								>
									<div className="filters-option-headers-title">
										{" "}
										{i.toLocaleUpperCase().replaceAll("_", " ")}
									</div>
									<div className="filters-option-icon">
										<i
											className={
												clickedFilter.show && clickedFilter.filter == i
													? "bi bi-dash-circle"
													: "bi bi-plus-circle"
											}
										></i>
									</div>
								</div>
								<div
									className={`filters-option-options ${
										clickedFilter.show && clickedFilter.filter == i
											? "show"
											: ""
									}`}
								>
									{arrayData
										.filter(
											(it, n, s) => s.findIndex((o) => o[i] == it[i]) === n
										)
										.map((item, key_2) => (
											<Form.Check
												key={key + key_2}
												label={selectOption(i, item[i])}
												onChange={(e) =>
													onCheckOption(
														i,
														String(item[i])
															.replaceAll(".", "")
															.replaceAll("-", ""),
														e.currentTarget.checked
													)
												}
												checked={checkSeleced(
													i,
													String(item[i])
														.replaceAll(".", "")
														.replaceAll("-", "")
												)}
											></Form.Check>
										))}
								</div>
							</div>
						))}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div style={{ minWidth: "150px" }}>
						<BotaoSalvar
							msg={btn.msg}
							style={btn.style}
							fnRetorno={setFilterTableC}
						/>
					</div>
					<div style={{ minWidth: "150px" }}>
						<Button variant="secondary" onClick={handlerSimpleClose}>
							Fechar
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}
