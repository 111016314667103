import { createSlice } from "@reduxjs/toolkit";

export const cargaSlicer = createSlice({
	name: "carga",
	initialState: {
		listaProdutos: [],
		listaSteps: [],
		listasDanfes: [],
		listasFotos: [],
		listaFotoProdutoEntregue: [],
		dimensoesCaminhao: {
			comprimento: 12,
			altura: 2,
			largura: 2.4,
		},
		dimensoesDesenho: {
			comprimento: 6.00757,
			altura: 2.4785,
			largura: 2.3774,
		},
		logicaDeCarregamento: {
			sort_by_distance: {
				flag: false,
				msg: "Ativar",
				pedidos: [],
			},
			peso: {
				flag: false,
				msg: "Ativar",
			},
			comprimento: {
				flag: false,
				msg: "Ativar",
			},
			logica: [],
		},
	},
	reducers: {
		addProduto: (state, action) => {
			if (action.payload) {
				state.listaProdutos.push(action.payload);
			}
		},
		addSteps: (state, action) => {
			if (action.payload) {
				state.listaSteps.push(action.payload);
			}
		},
		removeProduto: (state, action) => {
			if (action.payload) {
				state.listaProdutos = action.payload;
			}
		},
		updateSteps: (state, action) => {
			if (action.payload) {
				state.listaSteps = state.listaSteps.map((item) => {
					if (item.qr_sku === action.payload.qr_sku) {
						item.concluido = action.payload.concluido;
					}
					return item;
				});
			}
		},
		updateProduto: (state, action) => {
			if (action.payload) {
				state.listaProdutos = state.listaProdutos.map((item) => {
					if (item.name === action.payload.itemName) {
						item.position = action.payload.newPosition;
					}
					return item;
				});
			}
		},
		updateIdSelected: (state, action) => {
			if (action.payload) {
				state.listaProdutos.map((item) => {

					if (item.id == action.payload.id) {
						item.id_selected = action.payload.id;
						// return item
					} else {
						delete item.id_selected;
					}
					return item;
				});
			}
		},
		updateLogicaDeCarregamento: (state, action) => {
			if (action.payload) {
				state.logicaDeCarregamento[action.payload.parameter].flag =
					action.payload.value;
				state.logicaDeCarregamento[action.payload.parameter].msg =
					action.payload.msg;
				state.logicaDeCarregamento.logica = state.logicaDeCarregamento[
					action.payload.parameter
				].flag
					? [...state.logicaDeCarregamento.logica, action.payload.parameter]
					: state.logicaDeCarregamento.logica.filter(
							(item) => item !== action.payload.parameter
					  );
				if (action.payload?.pedidos) {
					state.logicaDeCarregamento.sort_by_distance.pedidos =
						action.payload.pedidos;
				}
				// logica: !last.localizacao.flag ? [...last.logica, 'cidade'] : last.logica.filter(item => item !== 'cidade')
			}
		},
		removeAll: (state, action) => {
			state.listaProdutos = [];
		},
		addFotoProdutoEntregue: (state, action) => {
			// action espera um objeto
			//{foto_produto_entregue, enviado_ao_servidor}
			state.listaFotoProdutoEntregue.push(action);
		},
		danfeController: (state, action) => {
			/**
			 * action espera um objeto
			 * danfe: arrayBuffer()
			 * danfe_assinada: arrayBuffer()
			 * enviado_ao_servidor: Boolean
			 */
			switch (action.payload.action) {
				case "add":
					state.listasDanfes.push(action.payload.dados);

					break;
				case "removeAll":
					state.listasDanfes = []

					break;
				case "update":
					state.listasDanfes = state.listasDanfes.map((item) => {
						if (
							item.numero_pedido == action.payload.numero_pedido &&
							item.id_filial == action.payload.id_filial
						) {
							return {
								...item,
								danfe_assinada: action.payload.danfe_assinada,
								status: "ASSINADA",
							};
						}
						return item;
					});
					break;
				case "update_status":
					state.listasDanfes = state.listasDanfes.map((item) => {
						if (
							item.numero_pedido == action.payload.numero_pedido &&
							item.id_filial == action.payload.id_filial
						) {
							return {
								...item,
								status: action.payload.status,
							};
						}
						return item;
					});
					break;
			}
		},
		fotosController: (state, action) => {
			/**
			 * action espera um objeto
			 * danfe: arrayBuffer()
			 * danfe_assinada: arrayBuffer()
			 * enviado_ao_servidor: Boolean
			 */
			switch (action.payload.action) {
				case "add":
					state.listasFotos.push(action.payload.dados);

					break;
				case "update":
					state.listasFotos = state.listasFotos.map(item => {
						if (item.id === action.payload.id) {
							return ({ ...item, ...action.payload.dados })
						}
						return item
					})
					break;
				case "delete-one":
					state.listasFotos = state.listasFotos.filter(item => item.id != action.payload.id)
					break;
				case "delete-all":
					state.listasFotos = []
					break;
			}
		},
	},
});

export const {
	updateSteps,
	addProduto,
	addSteps,
	removeProduto,
	updateProduto,
	updateIdSelected,
	removeAll,
	updateLogicaDeCarregamento,
	danfeController,
	addFotoProdutoEntregue,
	fotosController
} = cargaSlicer.actions;
export default cargaSlicer.reducer;
