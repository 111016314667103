import { Fragment, useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	update,
	updateCaminhaoSelecionado,
	updateCargaSelecionada,
	updateCarga_Id,
	updateEmbalagensList,
	updateProdutosList,
	updateRomaneiosList,
} from "../../../redux/slicers/user";
import { getAllData, getData, getStatusCarga } from "../../../services/Servicos";
import { addProduto } from "../../../redux/slicers/carga";
import { v4 as uuidv4 } from "uuid";

export default function DashboardCaminhoneiro({
	setLoading = () => {},
	loading,
}) {
	const dispatch = useDispatch();
	const [cargas, setCargas] = useState(0);
	const user = useSelector((state) => state.userSlice);
	const cargaSelecionada = user.cargaSelecionada;
	const boxes = useSelector((state) => state.cargaSlicer);

	const getCaminhaoSelecionado = async () => {
		const [caminhao, carga, dashboard] = await Promise.all([
			getData("caminhao/caminhoneiro", user.id),
			getAllData("cargas/caminhoneiro", { caminhoneiro: user.cpf }),
			getAllData("dashboard/motorista/status-cargas", {
				stringfilters: `AND cpf = '${user.cpf}'`,
			}),
		]);

		const dashboardSel = dashboard.data[0];

		if (caminhao.data) {
			const result = {};

			if (typeof caminhao.data === "string") {
				/* significa que existe resposta, só que vazia */
				result.status = false;
				dispatch(updateCaminhaoSelecionado(result));
			} else if (user.caminhaoSelecionado.status) {
				/* para não precisar passar a todo momento a action de update */
			} else {
				result.status = true;
				Object.keys(caminhao.data).forEach((key) => {
					result[key] = caminhao.data[key];
				});
				dispatch(updateCaminhaoSelecionado(result));
			}
		}
		if (dashboardSel) {
			setCargas({
				cargas_em_planejamento: dashboardSel.cargas_em_planejamento,
				cargas_a_carregar: dashboardSel.cargas_a_carregar,
				cargas_a_entregar: dashboardSel.cargas_a_entregar,
				cargas_entregues: dashboardSel.cargas_entregues,
				pedidos_em_planejamento: dashboardSel.pedidos_em_planejamento,
				pedidos_a_carregar: dashboardSel.pedidos_a_carregar,
				pedidos_a_entregar: dashboardSel.pedidos_a_entregar,
				pedidos_entregues: dashboardSel.pedidos_entregues,
				produtos_em_planejamento: dashboardSel.produtos_em_planejamento,
				produtos_a_carregar: dashboardSel.produtos_a_carregar,
				produtos_a_entregar: dashboardSel.produtos_a_entregar,
				produtos_entregues: dashboardSel.produtos_entregues,
				nao_finalizadas: dashboardSel.cargas_nao_finalizadas,
				ultima_carga_entregue: dashboardSel.ultima_carga_entregue,
				verificado: true,
			});
			setLoading(false);
		}
	};

	const getRomaneiosEntrega = async (cargas_id) => {
		const romaneiosGet = await getData("entregas/carga", cargas_id);
		if (!cargas_id) {
			dispatch(updateRomaneiosList([]));
			return;
		}

		const filteredTable = romaneiosGet.data.filter(
			(i, n, s) => s.findIndex((o) => o.romaneio === i.romaneio) === n
		);
		dispatch(updateRomaneiosList(filteredTable));

	};

	useEffect(() => {
		getCaminhaoSelecionado();
	}, []);

	const updateArrayProdutosCarregados = async (cargas_id) => {
		if (boxes.listaProdutos.length) {
			setLoading(false);
			return;
		}
		const getNextCarga = await getData(
			"cargas/caminhoneiro/produtos/caminhao/carga_id",
			cargas_id
		);
		// const cargaDisponivelCarregamento = getNextCarga.data.filter(item => item.status >= 9 && item.status <= 12 )[0]?.id //4, 5, 6
		// dispatch(updateCarga_Id(cargas_id))
		const emProcessoDeEntrega = getNextCarga.data; //.filter(item => item.id == cargas_id)[0] //4, 5, 6
		if (
			emProcessoDeEntrega?.data_inicio_entrega &&
			!emProcessoDeEntrega?.data_chegada
		) {
			dispatch(
				update({
					processo_entrega: {
						flag: true,
						locations: [],
					},
				})
			);
		}

		const produtosGet = await getData(
			"cargas/produtos/separador",
			cargas_id,
			{}
		);
		const embalagensGet = await getData(
			"cargas/funcionarios/embalagens/carga_id",
			cargas_id,
			{}
		);
		const romaneiosGet = await getData("entregas/carga", cargas_id);
		const produtosCarregados = produtosGet.data.filter(
			(item) => item.planejado && !item.embalado && item.carregado
		);
		const embalagensPlanejadas = embalagensGet.data.filter(
			(item) => item.carregado
		);

		for (const produtos in produtosCarregados) {
			const item = produtosCarregados[produtos];
			const romaneio = romaneiosGet.data.find(
				(item2) => item.numero_pedido === item2.numero_pedido
			)?.romaneio;

			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					descarregado: item.descarregado,
					step: item.step_planejado,
					tipo: "PRODUTO",
					cor_caixa: item.cor_caixa,
					romaneio,
					pedido: { ...item },
					data_carregamento: new Date(item.data_carregamento).getTime()
				})
			);
		}

		for (const embalagems in embalagensPlanejadas) {
			const item = embalagensPlanejadas[embalagems];

			const produtoEmbalado = produtosGet.data.find(
				(item2) =>
					Number(item.numero_embalagem) === Number(item2.numero_embalagem)
			);
			const romaneio = romaneiosGet.data.find(
				(item2) =>
					Number(produtoEmbalado.numero_pedido) === Number(item2.numero_pedido)
			)?.romaneio;

			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					descarregado: item.descarregado,
					step: item.step_planejado,
					tipo: "EMBALAGEM",
					cor_caixa: item.cor_caixa,
					romaneio,
					pedido: { ...item },
                    data_carregamento: new Date(item.data_carregamento).getTime()
				})
			);
		}
		const carregados = produtosGet.data.filter((item) => item.conferido);
		const embalagensSeparadas = embalagensGet.data.filter(
			(item) => item.conferido
		);

		dispatch(updateProdutosList(carregados));
		dispatch(updateEmbalagensList(embalagensSeparadas));

		// const romaneiosGet =  await getData('cargas/pedidos/separador', cargas_id)

		if (!cargas_id) {
			dispatch(updateRomaneiosList([]));
			setLoading(false);
			return;
		}

		setLoading(false);
	};

	return (
		<Fragment>
			<h4 className="card-header">Atual</h4>
			<Row>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${!cargaSelecionada.length ? 'N/A': cargaSelecionada.map(item => getStatusCarga(item.status)).join(', ') }`
							)}
						</h3>
						<p>Status da Carga Atual</p>
						<i className="bi bi-question bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${!cargaSelecionada.length ? 'N/A': cargaSelecionada.map(item => item.id).join(', ') }`
								// `${user.cargas_id ? N° $`user.cargas_id`}`
							)}
						</h3>
						<p>Cargas Atual</p>
						<i className="bi bi-check bi-dashboard"></i>
					</Card>
				</Col>
				
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${cargas.nao_finalizadas}`
							)}
						</h3>
						<p>Cargas Não Finalizadas</p>
						<i
							className="bi bi-x-lg bi-dashboard"
							style={{ backgroundColor: "#d3d301ba" }}
						></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`N° ${cargas.ultima_carga_entregue}`
							)}
						</h3>
						<p>Última Carga Entregue </p>
						<i
							className="bi bi-exclamation-triangle bi-dashboard"
							style={{ backgroundColor: "yellow" }}
						></i>
					</Card>
				</Col>
			</Row>
			<h4 className="card-header">Histórico</h4>
			<Row>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.cargas_em_planejamento
							)}
						</h3>
						<p>Cargas Em Planejamento</p>
						<i className="bi bi-gear bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas?.cargas_a_carregar
							)}
						</h3>
						<p>Cargas a Carregar</p>
						<i className="bi bi-flag bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${cargas?.cargas_a_entregar}`
							)}
						</h3>
						<p>Cargas a Entregar</p>
						<i
							className="bi bi-truck bi-dashboard"
							style={{ backgroundColor: "#d3d301ba" }}
						></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${cargas?.cargas_entregues}`
							)}
						</h3>
						<p>Cargas Entregues</p>
						<i className="bi bi-check-lg bi-dashboard"></i>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.pedidos_em_planejamento
							)}
						</h3>
						<p>Pedidos Em Planejamento</p>
						<i className="bi bi-gear bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas?.pedidos_a_carregar
							)}
						</h3>
						<p>Pedidos a Carregar</p>
						<i className="bi bi-flag bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${cargas?.pedidos_a_entregar}`
							)}
						</h3>
						<p>Pedidos a Entregar</p>
						<i
							className="bi bi-truck bi-dashboard"
							style={{ backgroundColor: "#d3d301ba" }}
						></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${cargas?.pedidos_entregues}`
							)}
						</h3>
						<p>Pedidos Entregues</p>
						<i className="bi bi-check-lg bi-dashboard"></i>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.pedidos_em_planejamento
							)}
						</h3>
						<p>Produtos Em Planejamento</p>
						<i className="bi bi-gear bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas?.produtos_a_carregar
							)}
						</h3>
						<p>Produtos a Carregar</p>
						<i className="bi bi-flag bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${cargas?.produtos_a_entregar}`
							)}
						</h3>
						<p>Produtos a Entregar</p>
						<i
							className="bi bi-truck bi-dashboard"
							style={{ backgroundColor: "#d3d301ba" }}
						></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								`${cargas?.produtos_entregues}`
							)}
						</h3>
						<p>Produtos Entregues</p>
						<i className="bi bi-check-lg bi-dashboard"></i>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
}
