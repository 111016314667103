import { Fragment, useRef } from "react"
import { useSelector } from "react-redux"

import './BodyRow.scss'

export default function BodyRowAtribuir({ headList, bodyList, index, setCaminhoneiro, tableLength }) {
    const funcionarios = useSelector(state => state.userSlice.funcionariosList)
    const refRow = useRef(null)

    const onClickTableRow = (e) => {
        e.preventDefault()
        refRow.current.classList = 'bg-selected'
        let refRowNumber = refRow.current.children[0].innerText

        let i = refRowNumber
        let actualSibling = refRow.current.nextSibling

        while(i < tableLength){
            
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            
            actualSibling = actualSibling.nextSibling
            i++
        }

        i =  refRow.current.children[0].innerText
        actualSibling = refRow.current.previousSibling
        while(0 < i ){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            actualSibling = actualSibling.previousSibling
            i--
        }     
        
        setCaminhoneiro(last => ({
            ...last,
            /* pode ser que o usuário ainda nao fez o upload da imagem */
            foto: bodyList.foto,
            id_caminhoneiro: bodyList.id,
            nome_caminhoneiro: bodyList.name,
            cpf_caminhoneiro: bodyList.cpf,
            cnh_caminhoneiro: bodyList.cnh,
            telefone_caminhoneiro: bodyList.telefone,
            caminhao: bodyList.caminhao
        }))
    }

    return (
        <Fragment>
            {
                <tr ref={refRow} onClick={onClickTableRow} key={index}>
                    {
                        headList.map((item, _index) => {
                            if(item === 'index'){
                                return <td key={_index} className={item}>{index + 1}</td>

                            }
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
        </Fragment>
    )
}