import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateTable } from "../../services/Servicos";

import "./Modal.scss";

export default function ModalErrorCarregameto_old({
	msg,
	title,
	show,
	setShow,
}) {
	const produtosCarregados = useSelector(
		(state) => state.userSlice.produtosList.data
	).filter((item) => item.carregado);
	// const produtosCarregados2 = useSelector(state => state.userSlice.produtosList.data)//.filter(item => item.carregado)

	const embalagensCarregadas = useSelector(
		(state) => state.userSlice.embalagensList.data
	).filter((item) => item.carregado);
	const boxes = useSelector((state) => state.cargaSlicer);
	const [loadingFinalizar, setLoadingFinalizar] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar Parcial",
	});

	const onClickFinalizarParcial = async () => {
		setLoadingFinalizar({
			flag: true,
			style: "warning",
			msg: "Aguarde",
		});
		if (!produtosCarregados[0]?.cargas_id) return alert("Erro com a Carga");
		// [pos_z, pos_y, pos_x]
		// for await(let produto of produtosCarregados){
		//     const produtoCarregado = boxes.listaProdutos.filter(item => item.qr_sku == produto?.qr_sku)

		//     let produtoAtt = {}
		//     if(!produtoCarregado.length){
		//
		//         continue

		//     }else{
		//         produtoAtt = {
		//             user_carregamento: produto.user_carregamento,
		//             data_carregamento: produto.data_carregamento,
		//             carregado: produto.carregado,

		//         }
		//     }

		//     await updateTable('carga/caminhoneiro/produto/carregado', produto.id, produtoAtt)
		//         .then(resp => {
		//             console.log({
		//                 flag: false,
		//                 msg: 'Produto atualizado com Sucesso',
		//                 style:'success'
		//             })
		//         })
		//         .catch(error => {
		//             // let msg = null
		//             console.log({
		//                 flag: false,
		//                 msg: 'Error!!!!',
		//                 style:'warning',
		//             })
		//         })
		// }
		// for await(let embalagem of embalagensCarregadas){
		//     const embalagemCarregada = boxes.listaProdutos.filter(item => item.qr_sku == embalagem?.qr_sku)
		//     let produtoAtt = {}
		//     if(!embalagemCarregada.length){
		//         continue

		//     }else{
		//         produtoAtt = {
		//             user_carregamento: embalagem.user_carregamento,
		//             data_carregamento: embalagem.data_carregamento,
		//             carregado: embalagem.carregado,
		//             cargas_id: embalagem.cargas_id // passado para atualizar embalagens
		//         }
		//     }
		//

		//     await updateTable('cargas/funcionarios/embalagens', embalagemCarregada[0].qr_sku, produtoAtt)
		//         .then(resp => {
		//             console.log({
		//                 flag: false,
		//                 msg: 'Embalagem atualizada com Sucesso',
		//                 style:'success'
		//             })
		//         })
		//         .catch(error => {
		//             // let msg = null
		//             console.log({
		//                 flag: false,
		//                 msg: 'Error!!!!',
		//                 style:'warning',
		//             })
		//         })

		//     // const produtosDaEmbalagem = produtosCarregados.filter(item => item.embalado && Number(embalagem.numero_embalagem) === item.numero_embalagem)

		//     // for await(let produto of produtosDaEmbalagem){
		//     const produtoAtt2 = {
		//         user_carregamento: embalagem.user_carregamento,
		//         data_carregamento: embalagem.data_carregamento,
		//         carregado: embalagem.carregado,

		//     }
		//     await updateTable('carga/caminhoneiro/produtos/carregados/embalagens', embalagem.numero_embalagem, produtoAtt2)
		//         .then(resp => {
		//             console.log({
		//                 flag: false,
		//                 msg: 'Produto embalado atualizado com Sucesso',
		//                 style:'success'
		//             })
		//         })
		//         .catch(error => {
		//             // let msg = null
		//             console.log({
		//                 flag: false,
		//                 msg: 'Error!!!!',
		//                 style:'warning',
		//             })
		//         })
		//     // }
		// }
		await updateTable(
			"cargas/carregada/caminhoneiro",
			produtosCarregados[0]?.cargas_id,
			{ status: 10, pedidos: null, local: "carregamento", status_carga: 10 }
		)
			.then((resp) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "success",
					msg: "Produtos Carregados!",
				}));
				setTimeout(() => {
					handlerSimpleClose();
				}, 600);
				// window.location.reload()
			})
			.catch((error) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "danger",
					msg: "Error!",
				}));
			});

		// dispatch(removeAll())
		// dispatch(updateCaminhaoSelecionado({status: false}))
		// dispatch(updateProdutosList([]))
	};
	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, flag: false }));

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{msg}</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loadingFinalizar.style}
								onClick={onClickFinalizarParcial}
							>
								{loadingFinalizar.msg}{" "}
								{loadingFinalizar.flag ? (
									<Spinner animation="border" variant="black" size="sm" />
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
