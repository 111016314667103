import { createRef, useEffect, useState } from "react"
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { getData, numerosEditadoBR } from "../../services/Servicos"

import './Modal.scss'
import LoadingNew from "../loading/LoadingNew"
import { QRCodeSVG } from "qrcode.react"
import { useReactToPrint } from "react-to-print"
const printRef = createRef()

export default function ModalImprimirQRCode({ title, show, setShow, msg_obj}) {
    // const [headList, setHead] = useState(['index'])

    const handlerSimpleClose = () => setShow(last => ({ ...last, flag: false }))
    const [showLoading, setShowLoading] = useState({
        show: false,
        msg: 'Imprimir',
        style: 'primary'
    })
    const [nomeCliente, setNomeCliente] = useState(null)

    const user = useSelector(state => state.userSlice)
    

    const onClickPrint = useReactToPrint({
        content: () => printRef.current
    })
    useEffect(() => {
        if (user.tipo !== 'empresa') {
            
            getData('cargas/pedidos/separador', msg_obj.cargas_id).then(resp => {
                let pedido = null 
                if (msg_obj.numero_embalagem) {
                    pedido = user.produtosList.data.find(item => Number(item.numero_embalagem) == Number(msg_obj.numero_embalagem)) || msg_obj
                    setNomeCliente(resp.data.find(item => item.numero_pedido == pedido.numero_pedido)?.nome_razao)
                } else {
                    pedido = resp.data.find(item => item.numero_pedido == msg_obj.numero_pedido)
                    setNomeCliente(pedido?.nome_razao)
                }
            })
        } else {
            getData('cargas/pedidos/carga', msg_obj.cargas_id).then(resp => {
                let pedido = null 
                if (msg_obj.numero_embalagem) {

                    pedido = user.produtosList.data.find(item => Number(item.numero_embalagem) == Number(msg_obj.numero_embalagem)) || msg_obj
                    setNomeCliente(resp.data.find(item => item.numero_pedido == pedido.numero_pedido)?.nome_razao)
                } else {
                    pedido = resp.data.find(item => item.numero_pedido == msg_obj.numero_pedido)
                    setNomeCliente(pedido?.nome_razao)
                }
            })
            
        }
    },[])

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton className="no-printme">
                    <Modal.Title >{ title }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="no-printme">
                    {/* <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe> */}
                    <div  ref={printRef} style={{fontSize:'0.8rem', padding:'5px'}}>
                        <div className='card' style={{padding:'5px 10px', backgroundColor:'#F9F9F9', borderRadius:'5px', border:'0.5px solid #F9F9F9' }} >
                            <h4 style={{ fontFamily: 'Kanit', color: '#333333' }}>{msg_obj.descricao || 'Sem Descrição'}</h4>
                            <br />
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>Cliente: <span>{nomeCliente ? nomeCliente : <Spinner animation="border" size='sm'/>}</span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>Tipo: <span>{msg_obj.numero_embalagem ? 'EMBALAGEM' : 'PRODUTO'}</span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>{msg_obj.numero_embalagem ? 'N° ' : ""} <span>{msg_obj.numero_embalagem ? String(msg_obj.numero_embalagem).padStart(7,0) : ""}</span></h6>
                            <br />
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>Carga: <span>{msg_obj.cargas_id} </span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>Pedido: <span>{msg_obj.numero_pedido}</span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>Código Item: <span>{msg_obj.id}</span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>Código Produto: <span>{msg_obj.codigo ?? "N/A"}</span></h6>
                            <br/>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>Quantidade: <span>{numerosEditadoBR(msg_obj.qtde || msg_obj.qtde_produtos)}</span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>Comprimento <span>{numerosEditadoBR(msg_obj.comprimento)} [m]</span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>Peso: <span>{numerosEditadoBR(msg_obj.peso)} [kg]</span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>Unidade: <span>{msg_obj.abreviatura ?? 'N/A'}</span></h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>ID Volume: <span>{numerosEditadoBR(msg_obj.id_volume ?? 0)} </span></h6>
                            
                            <hr style={{ borderStyle: 'dashed', borderWidth: '2px' }} />
                            
                            
                            <p className="text-center">
                                <QRCodeSVG value={msg_obj.qr_sku}/>
                            </p>
                            
                            {new Array(2).fill(1).map((item, index) => <br key={index}/>)}

                            <Row className="justify-content-between">
                                <Col><span style={{fontSize:'0.8rem'}}>@B-LOG</span></Col>
                                <Col className="text-end"><span style={{fontSize:'0.8rem'}}>{ new Date().toLocaleString('pt-BR')}</span></Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border ">
                    <Button variant={showLoading.style} onClick={onClickPrint} disabled={ showLoading.style !== 'primary' ? true : false}>
                        <LoadingNew show={showLoading.show} msg={showLoading.msg} style={showLoading.style} icone={'bi bi-printer'}/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

