import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Col, Nav, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import Loading from "../../loading/Loading";
import {
	updateEmbalagensList,
	updateManifestosList,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getDateInitMonth,
	getTableColumns,
} from "../../../services/Servicos";
import HeadTable from "../../table/head/HeadRow";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import BodyRowManifestos from "../../table/body/BodyRowManifestos";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import { Outlet } from "react-router-dom";
import ManifestoControle from "./ManifestoControle";
import ManifestosNaoEncerrados from "./ManifestosNaoEncerrados";

const tableRef = createRef();
const tbodyRef = createRef();

const buttomStyle = {
	textAlign: "center",
	padding: "15px",
	boxShadow: "0 5px 10px #00000021",
	borderRadius: "5px",
	marginBottom: "0.8rem",
};

export default function Manifestos() {
	const user = useSelector((state) => state.userSlice);
	const manifestos = user.manifestosList.data;

	const dispatch = useDispatch();

	const [error, setError] = useState({
		flag: false,
		msg: "",
	});

	const [indexList, setIndexList] = useState(15);
	const [loading, setLoading] = useState(true);

	const [selected, setSelected] = useState("Geral");
	const [headList, setHead] = useState([]);
	const [dataFiltro, setDataFiltro] = useState("");

	const updateColums = async () => {
		const cargasGet = await getTableColumns("Manifestos");
		const except = [
			"user_cancelamento",
			"data_cancelamento",
			"created_at",
			"updated_at",
			"emissao",
			"filial",
		];
		setHead((last) => [
			"index",
			"admin",
			"emissao",
			"filial",
			...Object.keys(cargasGet.data).filter((item) => !except.includes(item)),
		]);
	};
	const updateData = async (data_inicial, data_final) => {
		let filial = user.id_filial;

		if (user.id_filial == 2 || user.tipo.toLowerCase() == "empresa") {
			filial = null;
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);
		let objCons = { filial, data_inicial, data_final };

		setDataFiltro(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);
		try {
			const cargas = await getAllData(
				user.tipo === "empresa" ? "manifesto/empresa" : "manifestos/gestao",
				objCons
			);
			dispatch(updateManifestosList(cargas.data));
		} catch (error) {
			setError({
				list:
					"Error na obtenção dos Manifestos existentes." +
					JSON.stringify(error),
				flag: true,
			});
		} finally {
			setLoading(false);
		}
	};
	const listNaoEncerrados = async (data_inicial, data_final) => {
		try {
			const data = await getAllData("manifesto/carga/nao-encerrados");

			setHead([
				"admin",
				"id_manifesto",
				...Object.keys(data.data[0]).filter((item) => !item.includes("uuid")),
			]);
			const dados = data.data.map((item) => ({
				chave_mdfe: item.chave_mdfe,
				protocolo: item.protocolo,
				id_manifesto: item.uuid,
			}));

			dispatch(updateManifestosList(dados));
		} catch (error) {
			setError({
				list:
					"Error na obtenção dos Manifestos existentes." +
					JSON.stringify(error),
				flag: true,
			});
		} finally {
			setLoading(false);
		}
	};




	const onChangePage = (page) => {
		setSelected(page);
	};
	return (
		<PlanoLositicaLayout>
			<div className="card-header d-flex align-items-center">
				<h3 style={{ marginBottom: 0, marginRight: "5px" }}>
					Tabela de Manifestos - {selected != "Geral"? "Não Encerrados" : "Lista Geral"}
				</h3>
				{/* {loading ? <Spinner size="sm" animation="border" /> : ""} */}
			</div>

			<Row>
				<Col sm="12" md="3">
					<Button
						onClick={() => onChangePage("Geral")}
						style={buttomStyle}
						variant="info"
						className={selected == "Geral" ? "bg-dark text-light" : ""}
					>
						{" "}
						<i className="bi bi-list"></i> Lista de Manifestos
					</Button>
					<Button
						onClick={() => onChangePage("Não Encerrado")}
						style={buttomStyle}
						variant="info"
						className={selected == "Não Encerrado" ? "bg-dark text-light" : ""}
					>
						{" "}
						<i className="bi bi-x-lg"></i> Não Encerrados
					</Button>
				</Col>
				<Col sm='12' md='9'>
					{selected != "Geral" ? <ManifestosNaoEncerrados /> : <ManifestoControle />}
				</Col>
			</Row>
		</PlanoLositicaLayout>
	);
}
