import { Fragment, useRef, useState } from "react";
import ModalAtualizarRotas from "../../modal/ModalAtualizarRotas";
import ModalError from "../../modal/ModalError";
import { useSelector } from "react-redux";

import "./BodyRow.scss";
import ModalErrorPretty from "../../modal/ModalErrorPretty";
import { getErrorMessage, getSuccessMessage } from "../../../services/helpers";
import { Spinner } from "react-bootstrap";
import { deleteData } from "../../../services/Servicos";
import ModalSuccessPretty from "../../modal/ModalSuccessPretty";

export default function BodyRowRotas({ headList, bodyList, index, getRotas }) {
	// const caminhoneiros = useSelector(state => state.userSlice.funcionariosList).data.filter(item => item.tipo === 'Caminhoneiro').map(item => item.cpf)

	const refRow = useRef(null);
	const refExcluir = useRef(null);
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;

	const [adminFunction, setAdminFunction] = useState({
		error: {
			flag: false,
			title: "",
			msg: "",
			confirmar: "",
		},
	});

	const [error, setError] = useState({
		show: false,
		message: "",
	});
	const [success, setSuccess] = useState({
		show: false,
		message: "",
	});

	const [loading, setLoading] = useState(false);
	const onClickExcluirRow = async (e) => {
		e.preventDefault();
		try {
            setLoading(true);
            const data = await deleteData(`rotas/${bodyList.rota}`)
            setSuccess({ show: true, message: getSuccessMessage(data) })
            getRotas()
		} catch (error) {
			setError({ show: true, message: getErrorMessage(error) });
		} finally {
            setLoading(false);
		}
	};

	return (
		<Fragment>
			{
				<tr ref={refRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											{loading ? (
												<Spinner size="sm" animation="border" color="black" />
											) : (
												<i
													className={`bi bi-x-lg`}
													title="Excluir Atribuição"
													onClick={onClickExcluirRow}
													ref={refExcluir}
												></i>
											)}
										</span>
										{/* <span className="editar"><i className="bi bi-pencil" title="Editar" onClick={onClickEditarRow} ref={refEditar}></i></span> */}
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "origem" || item === "destino") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "20rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "createdAt" || item === "updatedAt") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "10rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index} style={{ whiteSpace: "nowrap" }}>
									{filiaisList.find(
										(item) => item.idFilial == bodyList.id_filial
									)?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{adminFunction.error.flag ? (
				<ModalAtualizarRotas
					title={adminFunction.error.title}
					msg={adminFunction.error.msg}
					show={adminFunction}
					setShow={setAdminFunction}
					msgButton={adminFunction.error.confirmar}
				/>
			) : (
				""
			)}
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			{success.show ? <ModalSuccessPretty setShow={setSuccess} {...success} /> : ""}
		</Fragment>
	);
}
