import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	cancelarManifestoFunc,
	encerrarManifestoByProtocolo,
	encerrarManifestoFunc,
	getAllData,
	getData,
	getManifestoId,
	getManifestoPDF,
	getManifestoStatus,
	updateTable,
} from "../../services/Servicos";

import "./Modal.scss";
import ModalMontaManifesto from "./ModalMontaManifesto";
import ModalError from "./ModalError";
import ModalVisualizarPDFComputer from "./ModalVisualizarPDFComputer";
import ModalVisualizarDanfe from "./ModalVisualizarDanfe";
import axios from "axios";
import ModalManifesto from "./ModalManifesto";
import ModalVisualizarPDF from "./ModalVisualizarPDF";
import ModalVisualizarManifesto from "./ModalVisualizarManifesto";
import { update, updateManifestosList } from "../../redux/slicers/user";
import { getErrorMessage } from "../../services/helpers";
import ModalErrorPretty from "./ModalErrorPretty";
import ModalEncerraManifesto from "./ModalEncerraManifesto";
import { encodeToBase64 } from "pdf-lib";
import ModalMontaManifestoV2 from "./ModalMontaManifestoV2";
import ModalCancelaManifesto from "./ModalCancelaManifesto";

export default function ModalControleManifesto(props) {
	const user = useSelector((state) => state.userSlice);

	const [chave, setChave] = useState(props.body.chave);
	const [statusMDFE, setStatusMDFE] = useState(null);
	const [codigoMDFE, setCodigoMDFE] = useState(null);
	const [loadingStatus, setLoadingStatus] = useState({
		location: "pdf",
		flag: true,
	});
	const [montaManifesto, setMontaManifesto] = useState({
		flag: false,
	});
	const [cancelaManifesto, setCancelaManifesto] = useState({show: false});
	const [encerraManifesto, setEncerraManifesto] = useState({show: false});
	const [error, setError] = useState({
		show: false,
	});
	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});
	const dispatch = useDispatch();

	const handlerSimpleClose = () => props.setShow({ flag: false });

	const updateData = async () => {
		// dispatch(updateManifestosList([]))
		if (props.body.id_manifesto) {
			if (user.tipo === "empresa") {
				await getAllData("manifesto/empresa")
					.then((response) => {
						//
						dispatch(updateManifestosList(response.data));
					})
					.catch((error) => {
						setError({
							message: error.message,
							show: true,
						});
					});
			} else {
				await getAllData("manifestos/gestao")
					.then((response) => {
						//
						dispatch(updateManifestosList(response.data));
					})
					.catch((error) => {
						setError({
							message: error.message,
							show: true,
						});
					});
			}
		}

		handlerSimpleClose();
	};

	const cancelarManifesto = (e) => {
		if (user.tipo.toLowerCase() !== "empresa")
			return setError({
				show: true,
				message: `Função bloqueada`,
			});
		if (loadingStatus.flag)
			return setError({
				show: true,
				message: `Aguarde a finalização da solicitação feita: "${loadingStatus.location.toUpperCase()} MANIFESTO"`,
			});
			setCancelaManifesto({ show:  true, bodyList: props.body })

	};

	// const excluirManifesto = (e) => {
	// 	if (user.tipo.toLowerCase() !== "empresa")
	// 		return setError({
	// 			show: true,
	// 			message: `Função bloqueada`,
	// 		});
	// 	if (loadingStatus.flag)
	// 		return setError({
	// 			show: true,
	// 			message: `Aguarde a finalização da solicitação feita: "${loadingStatus.location.toUpperCase()} MANIFESTO"`,
	// 		});
	// 	if (!codigoMDFE?.id_manifesto)
	// 		return setError({
	// 			show: true,
	// 			message: `Manifesto não Gerado`,
	// 		});

	// 	setLoadingStatus({ location: "excluir", flag: true });
	// 	updateTable("manifesto/empresa/update", codigoMDFE.id_manifesto, {
	// 		data_cancelamento: new Date().toISOString(),
	// 		user_cancelamento: user.name,
	// 	})
	// 		.then((resp) => {
	// 			setError({
	// 				show: true,
	// 				message: "Requisição Excluida com Sucesso",
	// 			});

	// 			updateData();
	// 		})
	// 		.catch((err) => {
	// 			setError({ show: true, message: err.message });
	// 		})
	// 		.finally(() => {
	// 			setLoadingStatus({ location: "cancelar", flag: false });
	// 		});
	// };

	const encerrarManifesto = async() => {
		if (loadingStatus.flag)
			return setError({
				show: true,
				message: `Aguarde a finalização da solicitação feita: "${loadingStatus.location.toUpperCase()} MANIFESTO"`,
			});

		setEncerraManifesto({ show:  true, bodyList: props.body })

		// try {
		// 	const manifesto = await updateTableNew(`manifesto/v2/encerra-mdfe`, )
		// 	console.log(manifesto);
		// 	setManifestoDeCargaFile({
		// 		show: true,
		// 		PDF: new Uint8Array(Buffer.from(manifesto.data, 'base64')),
		// 	});
		// } catch (error) {
		// 	const decoded = JSON.parse(new TextDecoder().decode(error.response.data));
		// 	setError({ show: true, message: decoded });
		// } finally {
		// 	setLoadingStatus({ location: "pdf", flag: false });
		// }
	};

	const [manifestoDeCargaFile, setManifestoDeCargaFile] = useState({
		show: false,
		PDF: "",
	});

	const verPDfHandler = async (e) => {
		if (loadingStatus.flag)
			return setError({
				show: true,
				message: `Aguarde a finalização da solicitação feita: "${loadingStatus.location.toUpperCase()} MANIFESTO"`,
			});
		if (!statusMDFE == 'APROVADO')
			return setError({
				show: true,
				message: `Manifesto não Gerado`,
			});

		setLoadingStatus({ location: "pdf", flag: true });
		// if (statusMDFE == "ERRO") {
		// 	setError((last) => ({ ...last, show: true }));
		// 	return setLoadingStatus({ location: "pdf", flag: false });
		// }

		try {
			const manifesto = await getAllData(`manifesto/v2/get-pdf?chave=${props.body.chave}`, )
			
			// console.log(manifesto)
			setManifestoDeCargaFile({
				show: true,
				PDF: manifesto.data.data.data,
			});
		} catch (error) {
			setError({ show: true, message: getErrorMessage(error)});
		} finally {
			setLoadingStatus({ location: "pdf", flag: false });
			
		}
	};

	const consultarMDFE = async () => {

		if (!props.body.chave) {
			setStatusMDFE('Não Enviado')
			return setLoadingStatus({ location: "pdf", flag: false });
		}

		try {
			const manifesto = await getAllData(
				`manifesto/v2/consulta?chave=${props.body.chave}`
			);
			setStatusMDFE(manifesto.data.status.toUpperCase())
		} catch (error) {
			setError({ show: true, message: getErrorMessage(error) });
			setStatusMDFE('Não Enviado')
		} finally {
			setLoadingStatus({ location: "pdf", flag: false });
			
		}
	};
	const consultarMDFEAposCriacao = async () => {


		try {
			const {data} = await getAllData(`manifesto/v2/consulta-by-carga?cargas_id=${props.body.id}`)
			setChave(data.chave)
			const manifesto = await getAllData(
				`manifesto/v2/consulta?chave=${data.chave}`
			);
			setStatusMDFE(manifesto.data.status.toUpperCase())
		} catch (error) {
			setError({ show: true, message: getErrorMessage(error) });
			setStatusMDFE('Não Enviado')
		} finally {
			setLoadingStatus({ location: "pdf", flag: false });
			
		}
	};

	useEffect(() => {
		consultarMDFE();
	}, []);

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Controle de Manifesto de Carga: N°{" "}
						<b>
							<i style={{ fontSize: "inherit" }}>{props.body.id}</i>
						</b>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: "70px 30px" }}>
						<>
							<h4 className="text mb-4"> Chave: {chave}</h4>
							{/* <h4 className="text mb-4"> Protocolo: {props.body.protocolo}</h4> */}
						</>
					{/* {props.controleByManifestosList ? (
					) : (
						""
					)} */}
					<Row
						className={
							props.controleByManifestosList ? "justify-content-center" : ""
						}
					>
						{props.controleByManifestosList ? (
							""
						) : (
							<Col sm="12" md="6" lg="3">
								<Card
									className="card-manifesto"
									onClick={(e) => {
										if (!props.body.caminhao) {
											setError({
												show: true,
												message: 'Validar Manifesto. Caminhão não especificado para a carga'
											});
											return;
										}
										if (!props.body.caminhoneiro) {
											setError({
												show: true,
												message: 'Validar Manifesto. Caminhão sem CONDUTOR especificado para a carga'
											});
											return;
										}
										if (codigoMDFE?.id_manifesto && !codigoMDFE?.cancelado) {
											return setError({
												show: true,
												message: 'Manifesto gerado anteriormente!'
											});
										}
										setMontaManifesto({ flag: true });
									}}
								>
									<span style={{ position: "relative" }}>
										<i
											className="bi bi-truck"
											style={{
												fontSize: "3rem",
												opacity: "0.4",
												color: "#347d68",
												filter: "blur(1.5px)",
											}}
										></i>
										<i
											className="bi bi-card-heading"
											style={{
												position: "absolute",
												left: "-15px",
												bottom: "30px",
												fontSize: "2rem",
											}}
										></i>
									</span>
									<Card.Title>Gerar Manifesto</Card.Title>
								</Card>
							</Col>
						)}
						<Col sm="12" md="6" lg="3">
							<Card className="card-manifesto" onClick={encerrarManifesto}>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-truck"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-check-circle"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									></i>
								</span>
								<Card.Title>
									Encerrar Manifesto{" "}
									{loadingStatus.location == "encerrar" &&
									loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}
								</Card.Title>
							</Card>
						</Col>
						<Col sm="12" md="6" lg="3">
							<Card
								className={`card-manifesto active-animation-card-manifesto 
                            ${
															codigoMDFE?.cancelado
																? "active-animation-danger"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "APROVADO"
																? "active-animation-success"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "ENCERRADO"
																? "active-animation-warning"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "ERRO"
																? "active-animation-danger"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "REPROVADO"
																? "active-animation-danger"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "SOLICITADO"
																? "active-animation-warning"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "NÃO ENVIADO"
																? "active-animation-dark"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "CANCELADO"
																? "active-animation-dark"
																: ""
														}
                            ${!statusMDFE ? "active-animation-dark" : ""}
                            `}
								data-before={ !statusMDFE ? "VERIFICANDO" : statusMDFE}
								onClick={verPDfHandler}
							>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-truck"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-file-earmark-pdf"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									></i>
								</span>
								<Card.Title>
									Ver Manifesto{" "}
									{loadingStatus.location == "pdf" && loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}
								</Card.Title>
							</Card>
						</Col>
						<Col sm="12" md="6" lg="3">
							<Card className="card-manifesto" onClick={cancelarManifesto}>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-truck"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-x-circle"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									></i>
								</span>
								<Card.Title>
									Cancelar Manifesto{" "}
									{loadingStatus.location == "cancelar" &&
									loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}
								</Card.Title>
							</Card>
						</Col>
						{/* <Col sm="12" md="6" lg="3">
							<Card className="card-manifesto" onClick={excluirManifesto}>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-truck"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-trash"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									></i>
								</span>
								<Card.Title>
									Excluir Requisição{" "}
									{loadingStatus.location == "excluir" && loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}
								</Card.Title>
							</Card>
						</Col> */}
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Col sm="3">
						<Button variant="secondary" onClick={handlerSimpleClose}>
							Fechar
						</Button>
					</Col>
				</Modal.Footer>
			</Modal>
			{montaManifesto.flag ? (
				<ModalMontaManifestoV2
					show={montaManifesto.flag}
					setShow={setMontaManifesto}
					body={props.body}
					setCodigoMDFE={setCodigoMDFE}
					updateStatusManifesto={ consultarMDFEAposCriacao }
				/>
			) : (
				""
			)}
			{encerraManifesto.show ? (
				<ModalEncerraManifesto
					setShow={setEncerraManifesto}
					updateStatusManifesto={ consultarMDFE }
					{...encerraManifesto}
				/>
			) : (
				""
			)}
			{cancelaManifesto.show ? (
				<ModalCancelaManifesto
					setShow={setCancelaManifesto}
					updateStatusManifesto={ consultarMDFE }
					{...cancelaManifesto}
				/>
			) : (
				""
			)}
			{error.show? (
				<ModalErrorPretty setShow={setError} {...error} />
			) : (
				""
			)}
			{manifestoDeCargaFile.show ? (
				<ModalVisualizarManifesto
					title={"Manifesto de Carga"}
					pdf={manifestoDeCargaFile.PDF}
					setShow={setManifestoDeCargaFile}
					show={manifestoDeCargaFile.show}
				/>
			) : (
				""
			)}
		</>
	);
}
