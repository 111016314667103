import { createRef, useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import PlanoLositicaLayout from "../PlanoLositicaLayout"
import Loading from "../../loading/Loading";
import {
	updateCargasList,
	updateTransferencias,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getData,
	getTableColumns,
} from "../../../services/Servicos";
import BodyRowCargas from "../../table/body/BodyRowCargas";
import { SeparacaoLayout } from "../Separacao";
import HeadTable from "../../table/head/HeadRow";
import { removerDadosDuplicados } from "../../../services/helpers";
import BodyRowPedidosTransferencia from "../../table/body/BodyRowPedidosTransferencia";

const headList = [
	,
	"index",
	"admin",
	`id`,
	`tipo_transporte`,
	"caminhao",
	`nome`,
	`qtde_pedidos`,
	`qtde_produtos`,
	`peso_total`,
	`volume_total`,
	`status`,
	`observacao`,
];
const tableRef = createRef();
const tbodyRef = createRef();

const tableRefCargas = createRef();
const tbodyRefCargas = createRef();

export function SeparacaoDeCargas({
	height = 400,
	setNomeCargaSelecionada = () => {},
}) {
	const user = useSelector((state) => state.userSlice);
	const cargas = user.cargasList;
	const transferencias = user.transferenciasList;
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [headListTransferencias, setHeadListTransferencias] = useState([
		"index",
		"status",
		"transferencia",
		"peso_total",
		"id_filial",
		"id_filial_destino",
		"descricao",
		"emissao",
		"responsavel",
		"cargas_id",
	]);

	/* SCROLLBAR DAS CARGAS */
	useEffect(() => {
		const refTable = tableRefCargas.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	/* SCROLLBAR DAS TRANSFERENCIAS */
	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let actualFilter = "";
		let lastFilter = "";
		let filter = "";
		let keywords = [];

		let paramsToFilter = {};

		filters.map((filterM, indexM) => {
			Object.keys(filterM).map((key) => {
				actualFilter = key;

				if (actualFilter !== lastFilter) {
					lastFilter = actualFilter;
					keywords = [];
				}

				filter = key;
				keywords.push(filterM[key]);
			});
			paramsToFilter[filter] = keywords;
		});

		setFilteredTable([]);
		let testeArray = [];
		let arrayFinal = [];
		let arrayFinal2 = [];

		Object.keys(paramsToFilter).map((key, _index) => {
			paramsToFilter[key].map((element) => {
				if (key === "id_filial") {
					if (_index === 0) {
						testeArray.push(
							...cargas.data.filter((item) =>
								element.toLowerCase().includes(String(item[key]).toLowerCase())
							)
						);
					} else if (_index === 1) {
						arrayFinal.push(
							...testeArray.filter((item) =>
								element.toLowerCase().includes(String(item[key]).toLowerCase())
							)
						);
					} else {
						arrayFinal2.push(
							...arrayFinal.filter((item) =>
								element.toLowerCase().includes(String(item[key]).toLowerCase())
							)
						);
					}
					return;
				}

				if (_index === 0) {
					testeArray.push(
						...cargas.data.filter(
							(item) => String(item[key]).toLowerCase() == element.toLowerCase()
						)
					);
				} else if (_index === 1) {
					arrayFinal.push(
						...testeArray.filter(
							(item) => String(item[key]).toLowerCase() == element.toLowerCase()
						)
					);
				} else {
					arrayFinal2.push(
						...arrayFinal.filter(
							(item) => String(item[key]).toLowerCase() == element.toLowerCase()
						)
					);
				}
			});
		});
		const qtdeFiltes = Object.keys(paramsToFilter).length;
		setFilteredTable(
			removerDadosDuplicados(
				qtdeFiltes === 3
					? arrayFinal2
					: qtdeFiltes === 2
					? arrayFinal
					: testeArray,
				"id"
			)
		);
	}, [filters]);

	const updateArrayCargas = async () => {
		const cargas = await getData("separacao/cargas", user.id_filial);

		const cargas_unicas = cargas.data
			.filter((item) => item.status_pedido == 1 || item.status_pedido == 2)
			.filter((i, n, s) => s.findIndex((o) => o.id === i.id) === n);
		dispatch(updateCargasList(cargas_unicas));

		const trasnferencias = await getAllData(
			"transferencias/funcionarios/pedidos",
			{ id_filial: user.id_filial, status: 3 }
		);
		dispatch(updateTransferencias(trasnferencias.data));

		setLoading(false);
	};

	useLayoutEffect(() => {
		updateArrayCargas();
	}, []);

	const [filtersNew, setFiltersNew] = useState({});

	return (
		<SeparacaoLayout>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<Row className="mt-2 row-filters">
					<Col>
						<div className="card-header">
							<h3>Filtrar Cargas</h3>
						</div>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<Row>
				<Col sm="12" md="6">
					<div className="section-filters-table">
						<div className="card-header">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Cargas{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</div>

						<div
							className="responsive-table"
							ref={tableRefCargas}
							style={{ position: "relative", height }}
						>
							{
								// !loading ? "" :
								<Table hover size="sm">
									<thead style={{ zIndex: "49" }}>
										<HeadTable
											arrayValues={[...cargas.data]}
											headerList={headList}
											setArrayFilters={setFiltersNew}
											arrayFilters={filtersNew}
											setFiltersOld={setFilters}
											filtersOld={filters}
										/>
									</thead>
									<tbody ref={tbodyRefCargas}>
										{filteredTable
											? filteredTable.map((item, index) => {
													if (index < indexList) {
														return (
															<BodyRowCargas
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
															/>
														);
													}
											  })
											: cargas.data.map((item, index) => {
													if (index < indexList) {
														return (
															<BodyRowCargas
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
																tableLength={cargas.data.length}
																setCargaSelecionada={setNomeCargaSelecionada}
															/>
														);
													}
											  })}
									</tbody>
								</Table>
							}
							{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
							{filteredTable ? (
								indexList < filteredTable.length ? (
									<Loading color={"black"} msg="Loading" />
								) : (
									""
								)
							) : indexList < cargas.data.length ? (
								<Loading color={"black"} msg="Loading" />
							) : (
								""
							)}
						</div>
					</div>
				</Col>
				<Col sm="12" md="6">
					<div className="section-filters-table">
						<div className="card-header">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Transferências{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</div>

						<div
							className="responsive-table"
							ref={tableRef}
							style={{ position: "relative", height }}
						>
							{
								// !loading ? "" :
								<Table hover size="sm">
									<thead style={{ zIndex: "49" }}>
										<HeadTable
											arrayValues={[...transferencias.data]}
											headerList={headListTransferencias}
											setArrayFilters={setFiltersNew}
											arrayFilters={filtersNew}
											setFiltersOld={setFilters}
											filtersOld={filters}
										/>
									</thead>
									<tbody ref={tbodyRef}>
										{filteredTable
											? filteredTable.map((item, index) => {
													if (index < indexList) {
														return (
															<BodyRowPedidosTransferencia
																key={index}
																bodyList={item}
																headList={headListTransferencias}
																index={index}
															/>
														);
													}
											  })
											: [...transferencias.data]
													.reverse()
													.map((item, index) => {
														if (index < indexList) {
															return (
																<BodyRowPedidosTransferencia
																	key={index}
																	bodyList={item}
																	headList={headListTransferencias}
																	index={index}
																	tableLength={transferencias.data.length}
																	setCargaSelecionada={setNomeCargaSelecionada}
																/>
															);
														}
													})}
									</tbody>
								</Table>
							}
							{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
							{filteredTable ? (
								indexList < filteredTable.length ? (
									<Loading color={"black"} msg="Loading" />
								) : (
									""
								)
							) : indexList < cargas.data.length ? (
								<Loading color={"black"} msg="Loading" />
							) : (
								""
							)}
						</div>
					</div>
				</Col>
			</Row>
		</SeparacaoLayout>
	);
}

export function ConferenciaDeCargas() {
	const [loading, setLoading] = useState(false);
	return (
		<SeparacaoLayout>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<div className="card-header">
					<h3>Conferências</h3>
				</div>
			</div>
		</SeparacaoLayout>
	);
}
