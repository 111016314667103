import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../loading/Loading";

import "../../modal/Modal.scss";
import ModalError from "../../modal/ModalError";
import {
	getAllData,
	getData,
	getDateInitMonth,
	getTableColumns,
	insertInto,
	updateTable,
	valorMoeda,
} from "../../../services/Servicos";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ExportToExcel from "../../exportToExcel/ExportToExcel";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import BodyRowManutencoes from "../../table/body/BodyRowManutencoes";
import BodyRowManutencoesItems from "../../table/body/BodyRowManutencoesItems";
import { updateManutencoesList } from "../../../redux/slicers/user";
import ModalCancelamento from "./ModalCancelamento";
import ModalEdicao from "./ModalEdicao";
import pneu from "../../../assets/caminhoes/pneu/pneu_2.png";

export default function ModalHistoricoPneu(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const formREf = useRef();
	const textArea = useRef();

	const [qtdePneusTotal, setQtdePneusTotal] = useState(0);
	const [atualizar, setAtualizar] = useState(0);
	const [error, setError] = useState(false);
	const [showCardLancamento, setShowCardLancamento] = useState(true);

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		responsavel: String(user.name).toUpperCase(),
		cpf_responsavel: user.email || "N/A",
	});

	const createItem = async () => {
		if (!body.tipo) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Serviço",
					motivo: "Descreva o Tipo de Pneu.",
				},
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});
		let bodySend = {};

		Object.keys(body).map((item) => {
			if (item.includes("data")) {
				bodySend[item] = body[item];
				return;
			}
			if (item.includes("valor")) {
				bodySend[item] = Number(
					body[item].toUpperCase().replace(".", "").replace(",", ".")
				);
				return;
			}

			bodySend[item] = String(body[item])
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		bodySend.manutencao_id = props.bodyList.id;
		bodySend.emissao = new Date().toISOString();

		const url = "manutencao/pneus";

		insertInto(url, bodySend)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				setBody((last) => ({
					responsavel: String(user.name).toUpperCase(),
					cpf_responsavel: user.email || "N/A",
				}));
				setAtualizar((last) => last + 1);

				formREf.current.reset();

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					});
				}, 750);
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setError({
					flag: true,
					title: "Atenção",
					msg: error.response.data.errors,
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onClickButton = async () => {
		setLoading({ isLoading: true, style: "warning", msg: "Aguarde" });
		await createItem();
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const editValue = (e) => {
		let valor = String(e.target.value)
			.replaceAll(".", "")
			.replace(",", "")
			.replace(/\D/g, "");

		setBody((last) => ({
			...last,
			[e.target.name]: Number(Number(valor) * 0.01).toLocaleString("pt-br", {
				minimumFractionDigits: 2,
			}),
		}));
	};
	const handlerSimpleClose = () =>
		props.setShow((last) => ({ error: { flag: false } }));

	const [showTitle, setShowTitle] = useState({ item: "", show: false });
	const [dataTable, setDataTable] = useState([]);

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size={"xl"}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Histórico do Pneu -{" "}
						{props.fonte
							? `POSIÇÃO ${props.posicao_pneu}`
							: ` N°${props.bodyList?.numero_pneu}`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex justify-content-between">
					<TabelaItens
						numero_pneu={props.bodyList.numero_pneu}
						fonte={props.fonte || "numero_pneu"}
						{...props}
					/>

					{error.flag ? (
						<ModalError
							show={error.flag}
							setShow={setError}
							title={error.title}
							msg={error.msg}
						/>
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col sm="4">
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Fechar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function TabelaItens(props) {
	const [error, setError] = useState({});
	const [headList, setHeadList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const tableDiv = useRef();
	const tbodyRef = useRef();
	const userS = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();
	const getColumns = async () => {
		try {
			const columns = await getTableColumns("ManutencaoPneusHistorico");
			setHeadList((last) => [
				"index",
				...Object.keys(columns.data)
					.filter((item) => item !== "empresas_id")
					.filter((item) => item !== "created_at")
					.filter((item) => item !== "updated_at")
					.filter((item) => item !== "cpf_responsavel")
					.filter((item) => item !== "user_cancelamento")
					.filter((item) => item !== "data_cancelamento"),
				// .filter(item => item !== 'manutencao_id')
			]);
		} catch (error) {
			setError({
				flag: true,
				msg: { t: "Erro na obentção das Colunas" },
				title: "Aviso!",
			});
		}
	};

	const getDataL = useCallback(async (manutencao_id) => {
		const url = "manutencao/pneus/posicao_pneu";
		if (props.fonte == "posicao_pneu") {
			try {
				const data = await getAllData(url, {
					manutencao_id: props.manutencao_id,
					posicao_pneu: props.posicao_pneu,
				});

				setDataTable(
					data.data.map((item) => ({
						...item,
						emissao: item.emissao
							? new Date(item.emissao).toLocaleDateString("pt-br", {
									timeZone: "UTC",
							  })
							: "",
					}))
				);
			} catch (error) {
				setError({
					flag: true,
					msg: error,
					title: "Aviso!",
				});
			}
		} else {
			const url = "manutencao/pneus/numero_pneu";
			try {
				const data = await getAllData(url, { numero_pneu: props.numero_pneu });

				setDataTable(
					data.data.map((item) => ({
						...item,
						emissao: item.emissao
							? new Date(item.emissao).toLocaleDateString("pt-br", {
									timeZone: "UTC",
							  })
							: "",
					}))
				);
			} catch (error) {
				setError({
					flag: true,
					msg: error,
					title: "Aviso!",
				});
			}
		}
		setLoading(false);
	});

	// dados de entrada
	useEffect(() => {
		getColumns();
	}, []);

	useEffect(() => {
		getDataL(props.manutencao_id);
	}, [props.atualizar]);

	// scrollBar
	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [dataTable];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<div hidden={props.hidden} style={{ marginTop: "0", minWidth: "100%" }}>
			<div className="section-filters-table">
				<h3>Filtros Selecionados</h3>
				<hr />

				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Dados{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</Col>

						<Col sm="12" md="6" lg="3">
							<ExportToExcel
								tableName="Manutencoes_Items"
								reference={
									filteredTable.length ? filteredTable : [...dataTable]
								}
							/>
						</Col>
					</Row>
				</div>

				<div
					className="responsive-table"
					ref={tableDiv}
					style={{
						position: "relative",
						height: "600px",
						maxHeight: "unset",
						width: "inherit",
					}}
				>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={dataTable}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={self.length}
													setAtualizar={props.setAtualizar}
												/>
											);
										}
								  })
								: dataTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setDataTable={setDataTable}
													tableLength={self.length}
													setAtualizar={props.setAtualizar}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < dataTable.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			{error.flag ? (
				<ModalError
					title={error.title}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</div>
	);
}

function BodyRowLocal({
	headList,
	bodyList,
	index,
	setDataTable = () => {},
	tableLength = 0,
	setAtualizar = () => {},
}) {
	const refRow = useRef(null);
	const dispatch = useDispatch();
	const userS = useSelector((state) => state.userSlice);
	const filiaisList = userS.filiaisList.data;

	const [controleCancelamento, setControleCancelamento] = useState({
		falg: false,
	});
	const [controleEdicao, setControleEdicao] = useState({ falg: false });
	const [error, setError] = useState({
		flag: false,
		title: "",
		msg: "",
	});

	const getData = useCallback(async (manutencao_id) => {
		const url = "manutencao/pneus";
		try {
			const data = await getAllData(url, { manutencao_id });
			setDataTable(
				data.data.map((item) => ({
					...item,
					emissao: item.emissao
						? new Date(item.emissao).toLocaleDateString("pt-br", {
								timeZone: "UTC",
						  })
						: "",
				}))
			);

			const date = new Date();
			const dateCorrect = getDateInitMonth(date);

			const url2 = "manutencao";
			const data1 = await getAllData(
				url2 /* , { data_inicial: dateCorrect.data_inicial, data_final: dateCorrect.data_final} */
			);
			if (userS.tipo.toLowerCase().includes("gestor")) {
				data1 = data1.data.filter((item) => item.id_filial == userS.id_filial);
			} else {
				data1 = data1.data;
			}
			dispatch(updateManutencoesList(data1));
		} catch (error) {
			setError({
				flag: true,
				msg: error,
				title: "Aviso!",
			});
		}
	});

	const onClickCancelItem = async () => {
		const url = "manutencao/pneus";

		await updateTable(url, bodyList.id, {
			data_cancelamento: new Date(),
			user_cancelamento: userS.name,
			manutencao_id: bodyList.manutencao_id,
		}); // cancelado
		// getData(bodyList.manutencao_id)
		setAtualizar((last) => last + 1);
	};

	const onClickEditar = async (bodyUpdate) => {
		const url = "manutencao/pneus";
		await updateTable(url, bodyList.id, {
			...bodyUpdate,
			manutencao_id: bodyList.manutencao_id,
		});
		// getData(bodyList.manutencao_id)

		setAtualizar((last) => last + 1);
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
		// setCargaSelecionada({
		//     ...bodyList
		//     , disponivel: bodyList.status === 0 ? true : false
		// })
	};

	return (
		<Fragment>
			{
				<tr
					ref={refRow}
					style={{ verticalAlign: "middle" }}
					onClick={onClickTableRow}
				>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "tipo") {
							return (
								<td key={_index}>
									<div className="admin">
										<span
											style={{
												fontSize: "0.8rem",
												padding: "7px 15px",
												borderRadius: "10px",
												backgroundColor:
													bodyList.tipo == "CORRETIVA"
														? "#ff0000bf"
														: "#b7d972",
											}}
										>
											{bodyList[item]}
										</span>
									</div>
								</td>
							);
						}
						// else if (item === 'admin') {

						//     return <td key={_index}>
						//         <div className="admin">
						//             <span className="excluir">
						//                 <i className="bi bi-x-lg"
						//                     title="Excluir"
						//                     onClick={() => setControleCancelamento({ bodyList, flag: true, title: 'Cancelar Manutencao', fn: onClickCancelItem })}>
						//                 </i>
						//             </span>
						//             <span className="editar" hidden={bodyList.status == '2'}>
						//                 <i className="bi bi-pencil"
						//                     title="Editar"
						//                     onClick={() => setControleEdicao({ bodyList, flag: true, title: 'Editar Manutencao', fn: onClickEditar })}>
						//                 </i>
						//             </span>
						//             <span className="editar" hidden={bodyList.status == '2'}>
						//                 <i className="bi bi-hourglass-split"
						//                     title="Ver Histórico do pneu"
						//                     onClick={() => setHistoricoPneu({ bodyList, flag: true })}>
						//                 </i>
						//             </span>
						//         </div>
						//     </td>
						// }
						else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "placa") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "id_filial") {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} className={item}>
									{bodyList[item] == 2 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-success">
												Finalizado
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 1 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-warning">
												Em Manutencao
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 0 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-info">Agendado</span>
										</div>
									) : (
										""
									)}
								</td>
							);
						} else if (item === "createdAt" || item === "updatedAt") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "10rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "observacoes") {
							/* primary key can not be modified */

							return (
								<td
									key={_index}
									style={{ minWidth: "10rem", cursor: "pointer" }}
									onClick={() => {
										if (!bodyList.observacoes) return;
										setError({
											title: "OBSERVAÇÕES DO LANÇAMENTO N°" + bodyList.id,
											msg: bodyList.observacoes,
											flag: true,
										});
									}}
								>
									{bodyList[item]
										? String(bodyList[item]).slice(0, 50) + "..."
										: "N/A"}
								</td>
							);
						} else if (item.includes("km")) {
							return (
								<td key={_index}>
									<div className="admin">
										{Number(bodyList[item]).toLocaleString("pt-br", {
											minimumFractionDigits: 2,
										})}
									</div>
								</td>
							);
						} else if (item === "valor") {
							return (
								<td key={_index}>
									<div className="admin">{valorMoeda(bodyList[item])}</div>
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}

			{controleCancelamento.flag ? (
				<ModalCancelamento
					{...controleCancelamento}
					setShow={setControleCancelamento}
				/>
			) : (
				""
			)}
			{controleEdicao.flag ? (
				<ModalEdicao {...controleEdicao} setShow={setControleEdicao} />
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					show={error.flag}
					setShow={setError}
					msg={error.msg}
					title={error.title}
				/>
			) : (
				""
			)}
			{/* {historicoPneu.flag ? <ModalError show={historicoPneu.flag} setShow={setHistoricoPneu} msg={error.msg} title={error.title}/> : ""} */}
		</Fragment>
	);
}

function Toco({ title = "CAMINHÃO", data = [] }) {
	return (
		<div className="div-caminhoes">
			<div className="cabine" data-truck={title}></div>
			<div className="caminhao"></div>
			<div className="frente">
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						P1
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P2</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P3</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P4</div>
				</div>
			</div>
			<div className="frente frente-4">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P5</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P6</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P7</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P8</div>
				</div>
				{/* <img alt='pneu' src={pneu} className="frente-esquerda-1"></img> */}
				{/* <img alt='pneu' src={pneu} className="frente-esquerda-2"></img>
                <img alt='pneu' src={pneu} className="frente-direita-1"></img> */}
			</div>
			<div className="eixo-central"></div>
			<div className="eixo-frontal"></div>
			<div className="modulo-redutor modulo-redutor-1"></div>
		</div>
	);
}

function Truck({ title = "CAMINHÃO", data = [] }) {
	// useEffect(() => {
	//     )
	// }, [data])

	return (
		<div className="div-caminhoes">
			<div className="cabine" data-truck={title}></div>
			<div className="caminhao"></div>
			<div className="frente">
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P1") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P1")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P1")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						P1
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P2") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P2")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P2")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						P2
					</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P3") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P3")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P3")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						P3
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P4") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P4")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P4")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						P4
					</div>
				</div>
			</div>
			<div className="frente frente-4">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P5")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P5") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P5")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P5")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P5")
								? "#00ff95"
								: "",
						}}
					>
						P5
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P6")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P6") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P6")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P6")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P6")
								? "#00ff95"
								: "",
						}}
					>
						P6
					</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P7")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P7") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P7")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P7")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P7")
								? "#00ff95"
								: "",
						}}
					>
						P7
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P8")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P8") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P8")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P8")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P8")
								? "#00ff95"
								: "",
						}}
					>
						P8
					</div>
				</div>
			</div>
			<div className="frente frente-3">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P9")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P9") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P9")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P9")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P9")
								? "#00ff95"
								: "",
						}}
					>
						P9
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P10")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P10") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P10")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P10")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P10")
								? "#00ff95"
								: "",
						}}
					>
						P10
					</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P11")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P11") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P11")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P11")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P11")
								? "#00ff95"
								: "",
						}}
					>
						P11
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P12")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P12") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P12")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P12")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P12")
								? "#00ff95"
								: "",
						}}
					>
						P12
					</div>
				</div>
			</div>
			<div className="eixo-central"></div>
			<div className="eixo-frontal"></div>
			<div className="modulo-redutor modulo-redutor-1"></div>
		</div>
	);
}

function Bitruck({ title = "CAMINHÃO" }) {
	return (
		<div className="div-caminhoes">
			<div className="cabine" data-truck={title}></div>
			<div className="caminhao"></div>
			<div className="frente">
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P1</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P2</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P3</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P4</div>
				</div>
			</div>
			<div className="frente frente-2">
				<div className="eixo-frontal"></div>
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P13</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P14</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P15</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P16</div>
				</div>
			</div>
			<div className="frente frente-3">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P9</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P10</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P11</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P12</div>
				</div>
			</div>
			<div className="frente frente-4">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P5</div>
				</div>
				<div className="esquerda esquerda-2">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P6</div>
				</div>
				<div className="esquerda esquerda-3">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P7</div>
				</div>
				<div className="esquerda esquerda-4">
					<img alt="pneu" src={pneu} className="frente"></img>
					<div>informacoes</div>
					<div className="numero-pneu">P8</div>
				</div>
			</div>
			<div className="eixo-central"></div>
			<div className="eixo-frontal"></div>
			<div className="modulo-redutor modulo-redutor-1"></div>
		</div>
	);
}
