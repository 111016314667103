import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";

export default function GraficoPadraoComDataHorizontalMotoristas({
	url,
	title,
	unidadeMedida = "",
	parametroGrafico,
	posisaoUnidadeMedida,
	colors = "#f60809",
	arrayData,
	arrayDataFiltered,
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
				toolbar: {
					show: true,
				},
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					columnWidth: "100%",
					barHeight: "90%",
					dataLabels: {
						position: "top",
						// orientation: 'vertical',
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value)}`;
					}
					return `${parseInt(value).toLocaleString("pt-br")} ${unidadeMedida} `;
				},
				offsetX: 75,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},

				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				style: {
					fontSize: "10px",
				},
				fixed: {
					enabled: false,
					position: "topLeft",
					offsetX: 50,
					offsetY: 30,
				},
			},
			xaxis: {
				labels: {
					show: false,
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: true,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true)
		const resultado =  arrayDataFiltered || arrayData;
		const motoristas = resultado.filter((i, n, s) => s.findIndex((o) => o.cpf_motorista === i.cpf_motorista) === n);
		const resultatoGeral = motoristas
			.map((item) => {
				const nomeMotorista = item.motorista.split(" ");
				let motorista = "";

				switch (nomeMotorista.length) {
					case 0:
						motorista = "N/A";
						break;
					case 1:
						motorista = item.motorista.split(" ")[0];
						break;
					default:
						motorista =
							item.motorista.split(" ")[0] + " " + item.motorista.split(" ")[1]; //?.slice(0, 3) + '...'
						break;
				}
				return {
					motorista,
					valorTotal: resultado
						.filter((item2) => item2.cpf_motorista == item.cpf_motorista)
						.reduce((p, c) => p + c[parametroGrafico], 0),
				};
			})
			.sort((a, b) => {
				if (a.valorTotal > b.valorTotal) return 1;
				if (a.valorTotal < b.valorTotal) return -1;
				return 0;
			})
			.filter((item) => item.valorTotal)
			.reverse();

		setOptions((last) => ({
			...last,
			options: {
				...last.options,
				colors: [colors],
				xaxis: {
					...last.options.xaxis,
					show: false,
					categories: resultatoGeral.map((item) => item.motorista),
				},
			},
			series: [
				{
					name: "Total em " + unidadeMedida,
					data: resultatoGeral.map((item) =>
						Number(item.valorTotal).toFixed(3)
					),
				},
			],
			length: resultatoGeral.length < 7 ? 300 : 500
		}));

		setTimeout(() => {
			setLoading(false);
		}, 1000)
	}, [arrayData, arrayDataFiltered]);

	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				{/* <span className="filter-data" onClick={() => setDataParam(last => ({...last, show: true}))}>
                    Filtrar Data 
                <span className="filho"style={{backgroundColor:colors}}>{periodoInicial}</span>
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							height={options.length}
						/>
					</div>
				)}
			</Card.Body>
		</Card>
	);
}
