import { useEffect, useState } from "react";
import CardLayoutNavbar from "../card/CardLayoutNavbar";
import NavbarPonto from "./NavbarPonto";
import {Row, Col, Button, Spinner} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import ModalError from "../modal/ModalError";
import ModalLancamentosAjustesPontos from "../modal/ModalLancamentosAjustesPontos";
import { BackSide } from "three";
import { getAllData } from "../../services/Servicos";
import Loading from "../loading/Loading";

const diasSemanas = ['DOMINGO', 'SEGUNDA-FEIRA', 'TERÇA-FEIRA', 'QUARTA-FEIRA', 'QUINTA-FEIRA', 'SEXT-FEIRA', 'SÁBADO']
const meses = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO','SETEMBRO','OUTUBRO','NOVEMBRO','DEZEMBRO']
export default function RelatorioPontos() {
    const user = useSelector(state => state.userSlice)
    const pontos_entrada = useSelector(state => state.userSlice.pontosList)
    const [pontos, setPontos] = useState([...pontos_entrada.data])
    const [loading, setLoading] = useState(false)
    const [dias, setDias] = useState([])
    const [mes, setMes] = useState()
    const [ano, setAno] = useState()
    const [verLancamentos, setVerLancamentos] = useState({
        flag: false,
        dia: '',
        mes: '',
        ano: '',
    })

    const segundosParaHoras = (segundos) => {
        if(!segundos) return '00:00'
        const minutos = segundos / 60
        const horasTotais = minutos / 60 
        const horas = Math.trunc(horasTotais)
        const percentualDeMinutos = Number(String((String(horasTotais).padEnd(4,0) + "").split('.')[1] || 0 ).slice(0, 2)) / 100
        const minutosTotais = Math.trunc(60*percentualDeMinutos)
        return `${String(horas).padStart(2, 0)}:${String(minutosTotais).padStart(2, 0)}`
    }

    const updateArrayPontos = async (data_inicial, data_final) => {
        const pontos = await getAllData('funcionario/controle-ponto', { cpf: user.cpf, data_inicial, data_final })
        setPontos(pontos.data)
        return pontos.data
    } 

    const updateInfos = async (data = new Date(), firstRender = false) => {
       

        const mesAtual = data.getMonth() + 1
        const anoAtual = data.getFullYear()

        const ultimoDiaDoMes = new Date(anoAtual, mesAtual, 0)
        if (!firstRender) {
            let pontos = ''
            setLoading(true)
            pontos = await updateArrayPontos(`${anoAtual}-${mesAtual}-01`, `${anoAtual}-${mesAtual}-${ultimoDiaDoMes.getDate()}`)
            setTimeout(() => {
                
                setDias([])
                setMes(mesAtual)
                setAno(anoAtual)
                
                for (let i = 1; i < ultimoDiaDoMes.getDate() + 1; i++){
                        const totalSegundosTrabalhados = pontos
                            .filter(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano)
                            .sort((a, b) => {
                                if(a.id > b.id) return 1
                                if (a.id < b.id) return -1
                                return 0
                                
                            })
                            .map(item => ({ ent_sai: item.ent_sai, hora: item.hora, minuto: item.minuto, segundo: item.segundo, total: item.hora * 60 * 60 + item.minuto * 60 + item.segundo }))
                            .map((item, index, self) => {
                                if (item.ent_sai == 'SAIDA') {
                                    const entrada = self[index - 1]
                                    return {...item, totalTrabalhado: (item.total - entrada.total) || 0}
                                }
                            })
                            .filter(item => item)
                        .reduce((p, c) => p + c.totalTrabalhado, 0)
                    
                    setDias(last => [...last,
                        {
                            dia: i,
                            mes: mesAtual,
                            ano: anoAtual,
                            dia_semana: new Date(anoAtual, mesAtual - 1, i).getDay(),
                            dia_semana_long: diasSemanas[new Date(anoAtual, mesAtual - 1, i).getDay()],
                            totalSegundosTrabalhados,
                            pontos: pontos.filter(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano),
                            falta: pontos.find(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.falta) ? true : false,
                            ajustado: pontos.find(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.ajuste) ? true : false,
                            folga: pontos.find(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.folga) ? true : false,
        
                        }])
                }
    
                setLoading(false)
            }, 100)
            return
        }
            setDias([])
            setMes(mesAtual)
            setAno(anoAtual)
            
            
            for (let i = 1; i < ultimoDiaDoMes.getDate() + 1; i++){
                    const totalSegundosTrabalhados = pontos
                        .filter(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano)
                        .sort((a, b) => {
                            if(a.id > b.id) return 1
                            if (a.id < b.id) return -1
                            return 0
                            
                        })
                        .map(item => ({ ent_sai: item.ent_sai, hora: item.hora, minuto: item.minuto, segundo: item.segundo, total: item.hora * 60 * 60 + item.minuto * 60 + item.segundo }))
                        .map((item, index, self) => {
                            if (item.ent_sai == 'SAIDA') {
                                const entrada = self[index - 1]
                                return {...item, totalTrabalhado: (item?.total - entrada?.total) || 0}
                            }
                        })
                        .filter(item => item)
                    .reduce((p, c) => p + c.totalTrabalhado, 0)
                
                setDias(last => [...last,
                    {
                        dia: i,
                        mes: mesAtual,
                        ano: anoAtual,
                        dia_semana: new Date(anoAtual, mesAtual - 1, i).getDay(),
                        dia_semana_long: diasSemanas[new Date(anoAtual, mesAtual - 1, i).getDay()],
                        totalSegundosTrabalhados,
                        pontos: pontos.filter(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano),
                        ajustado: pontos.find(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.ajuste) ? true : false,
                        falta: pontos.find(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.falta) ? true : false,
                        folga: pontos.find(item => user.cpf == item.cpf && item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.folga) ? true : false,
    
                    }])
            }

            setLoading(false)
    }

    useEffect(() => {
        updateInfos(new Date(), true)
    },[])
    return (
        <CardLayoutNavbar>
            <div className='card-calendario' style={{ overflow: 'auto' }}>
                <div style={{width: 'max-content', padding:'10px'}}>
                    <div className="text-center align-items-center row-calendario">
                        <Col sm='2' className="">
                            <div className="d-flex">
                                <button variant='primary' className="button-calendar" onClick={() => updateInfos(new Date(ano, mes - 2, 1))}>
                                    <i className="bi bi-arrow-left"></i>
                                </button>   
                                <button variant='primary' className="button-calendar" onClick={() => updateInfos(new Date(ano, mes, 1))}>
                                    <i className="bi bi-arrow-right"></i>
                                </button>   
                            </div>
                        </Col>
                        <Col sm='8'>
                            <h3 className="d-flex align-items-center justify-content-center">
                                <span style={{fontSize:'1.5rem'}}>
                                    {`${meses[mes - 1]} - ${ano}`}
                                </span>
                                {
                                    loading ? <span className="ml-2"><Spinner animation='border' size='sm'/> </span> : ""}
                            </h3>
                            

                        </Col>
                        <Col sm='2'></Col>
                    </div>
                    <section className="calendario">
                        {diasSemanas.map((i, k) => <div key={k} className="dias-semana">{i}</div>)}
                        

                        {
                            dias.map((i, k) => {
                                if (k < dias[0].dia_semana) {
                                    const ultimoDiaMesAnterior = new Date(i.ano, i.mes - 1, 0).getDate()
                                    return <div key={k} className="dias-anteriores">{ ultimoDiaMesAnterior - dias[0].dia_semana  + k + 1 }</div>
                                }
                            })
                        }
                        {dias.map((i, k) => {
                            const date = new Date()
                            let backgroundColor = date.getMonth() == i.mes - 1 && date.getDate() == i.dia && date.getFullYear() == i.ano ? '#FFFADF' : ''
                            backgroundColor = i.ajustado ? '#0dd4e8' : backgroundColor
                            backgroundColor = i.falta ? '#ae0000b5' : backgroundColor
                            backgroundColor = i.folga ? '#ffd700c4' : backgroundColor

                            // ( date.getMonth() + 1 >= i.mes ? (  ? true :  false) : false)
                            let disabled = date.getFullYear() >= i.ano ? (i.mes < date.getMonth() + 1 ? false : (date.getDate() > i.dia ? false : true) ) : true
                            return <div key={k} className="dias" style={{ backgroundColor }}
                                onClick={() => { 
                                    if (disabled) return
                                    setVerLancamentos({
                                        flag: true,
                                        dia: i.dia,
                                        mes: i.mes,
                                        ano: i.ano,
                                        dia_semana_long: i.dia_semana_long,
                                        pontos: i.pontos,
                                        ajustado: i.ajustado || i.mes != new Date().getMonth() + 1 || i.dia <= new Date().getDate() - 3
                                    })
                                }}>
                                <div className="card-local">
                                    <div className="dia-atual">{i.dia}</div>
                                    <div className="total-horas-trabalhadas">
                                        {segundosParaHoras(i.totalSegundosTrabalhados)}
                                    </div>
                                    <div className="text-muted" style={{fontSize:'0.7rem'}}>
                                        {i.ajustado ? 'AJUSTE' : ''}
                                        {i.falta ? 'FALTA' : ''}
                                        {i.folga ? 'FOLGA' : ''}
                                    </div>
                                </div>
                            </div>
                        })}
                        {
                            dias.length && new Array(6 - dias[dias.length - 1].dia_semana).fill('').map((i, k) => {
                                return <div key={k} className="dias-anteriores">{k + 1}</div>
                            })
                        }
                        
                    </section>
                </div>
            </div>
            {verLancamentos.flag ? <ModalLancamentosAjustesPontos setShow={setVerLancamentos} show={verLancamentos.flag} {...verLancamentos} cpf={ user.cpf } /> : ''}
        </CardLayoutNavbar>
    )
}