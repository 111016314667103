import { createRef, Fragment, useEffect, useState, useRef } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateTable } from "../../services/Servicos";
import "./Modal.scss";
import { removeProduto } from "../../redux/slicers/carga";
import ModalErrorCarregamento from "./ModalErrorCarregameto";
import ModalErrorTotal from "./ModalErrorTotal";
import {
	updateEmbalagensListDescarregados,
	updateProdutosListDescarregados,
} from "../../redux/slicers/user";
import ModalError from "./ModalError";

const tableRef = createRef();
const tbodyRef = createRef();
const headList = [
	"index",
	"descarregado",
	"cargas_id",
	"numero_pedido",
	"codigo",
	"descricao",
	"altura",
	"largura",
	"comprimento",
	"volume",
	"peso",
	"pos_x",
	"pos_y",
	"pos_z",
	"caminhao_carregamento",
	"id",
	"qr_sku",
	"step_planejado",
	"sort_by_distance",
];

export default function ModalGetDescarregamentoEmbalagem({
	show,
	setShow,
	embalagem,
	cargas_id,
	target,
	setTarget = () => {},
	setLoadingEntregar = () => {},
}) {
	// const cofirmarPlanejamento = useState(state => state.userSlice.confirmar_planejamento)
	const userEmail = useSelector((state) => state.userSlice.email);
	const listaTotalProdutos = useSelector(
		(state) => state.userSlice.produtosList
	);
	const produtosRomaneio = useSelector(
		(state) => state.userSlice.produtosList
	).data.filter(
		(item) =>
			item.embalado &&
			Number(item.numero_embalagem) === Number(embalagem.numero_embalagem)
	);
	const produtosDaEmbalagem = useSelector(
		(state) => state.userSlice.produtosList
	).data.filter(
		(item) =>
			item.embalado &&
			Number(item.numero_embalagem) === Number(embalagem.numero_embalagem)
	);

	const boxes = useSelector((state) => state.cargaSlicer.listaProdutos);
	const dispatch = useDispatch();

	const handlerSimpleClose = () => {
		setLoadingEntregar((last) => ({
			...last,
			flag: false,
			msg: "Entregar Produto",
		}));
		setShow(false);
	};
	const [produtoSelecionado, setProdutoSelecionado] = useState({});

	const [loading, setLoading] = useState({
		flag: false,
		style: "primary",
		msg: "Descarregar",
	});
	const [error, setError] = useState({
		flag: false,
		msg: "",
	});
	const [errorP, setErrorP] = useState({
		flag: false,
		msg: "",
	});
	const [errorP2, setErrorP2] = useState({
		flag: false,
		msg: "",
		title: "",
	});
	const [correcao, setCorrecao] = useState(false);

	const onClickDescarregar = async (e) => {
		const produtosEmbaladosDescarregado = produtosRomaneio.filter(
			(item) => item.descarregado
		);

		setLoading({
			flag: true,
			style: "warning",
			msg: "Descarregando",
		});

		const algumItemNaoDescarregado = listaTotalProdutos.data.find(
			(item) =>
				embalagem.numero_pedido == item.numero_pedido && !item.descarregado
		);
		const algumItemDescarregado = listaTotalProdutos.data.find(
			(item) =>
				embalagem.numero_pedido == item.numero_pedido && item.descarregado
		);
		const status_pedido = algumItemDescarregado
			? algumItemNaoDescarregado
				? 12
				: 13
			: 11;

		const itensDescarregados = listaTotalProdutos.data.filter(
			(item) => item.descarregado
		).length;
		const status_carga =
			itensDescarregados == listaTotalProdutos.data.length
				? 13
				: listaTotalProdutos.data.find((item) => item.descarregado)
				? 12
				: 11;

		const objAtt = {
			user_descarregamento: userEmail,
			data_descarregamento: new Date().toISOString(),
			descarregado: true,
		};
		for await (let produto of produtosEmbaladosDescarregado) {
			// dispatch(updateProdutosListDescarregados({ qr_sku: produto.qr_sku, ...objAtt }))

			await updateTable(
				"carga/caminhoneiro/produto/carregado",
				produto.id,
				objAtt
			).catch((error) => {
				// let msg = null
				setErrorP({
					flag: true,
					msg: error,
					title: "Error",
				});
			});
		}

		const objSender = {
			pedidos: `${embalagem.numero_pedido}`,
			status_carga,
			local: "descarregamento",
			status: status_pedido,
		};

		//atualizamos a carga
		await updateTable(
			"cargas/carregada/caminhoneiro",
			cargas_id,
			objSender
		).catch((error) => {
			setError({
				flag: true,
				msg: error,
				title: "Erro ao Descarregar EMBALAGEM",
			});
		});

		//atualizamos a embalagem, verifica se existe algum produto nao descarregado e remove ele da tela
		if (!produtosDaEmbalagem.find((item) => !item.descarregado)) {
			await updateTable("cargas/funcionarios/embalagens", embalagem.qr_sku, {
				...objAtt,
				cargas_id,
			})
				.then((rep) => {
					setError({
						flag: true,
						msg: "Embalagem descarregada POR COMPLETO",
						title: "Aviso!",
					});
				})
				.catch((error) => {
					setError({
						flag: true,
						msg: error,
						title: "Erro ao Descarregar EMBALAGEM",
					});
				});

			dispatch(
				updateEmbalagensListDescarregados({
					qr_sku: embalagem.qr_sku,
					...objAtt,
				})
			);
			const targetLocal = target;
			setTarget(null);
			dispatch(
				removeProduto(
					[...boxes].filter((item) => item.name !== targetLocal.name)
				)
			);
		} else {
			setError({
				flag: true,
				msg: "Embalagem descarregada PARCIALMENTE",
				title: "Aviso!",
			});
		}

		setTimeout(() => {
			setLoading({
				flag: false,
				style: "success",
				msg: "Descarregados",
			});

			setTimeout(() => {
				handlerSimpleClose();
			}, 1500);
		}, 250);
	};

	useEffect(() => {
		const objAtt = {
			user_descarregamento: userEmail,
			data_descarregamento: new Date().toISOString(),
			descarregado: produtoSelecionado.checked,
			qr_sku: produtoSelecionado.qr_sku,
		};
		dispatch(updateProdutosListDescarregados({ ...objAtt }));
	}, [produtoSelecionado]);

	useEffect(() => {}, []);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Selecionar Produto da Embalagem N°
						{String(embalagem.numero_embalagem).padStart(7, 0)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="section-filters-table">
						<div className="card-header">
							<h5>Produtos da Embalagem </h5>
						</div>
						<div
							className=" responsive-table"
							ref={tableRef}
							style={{ position: "relative", height: "300px" }}
						>
							<Table hover size="sm">
								<thead>
									<tr>
										{headList.map((item, index) => {
											if (item === "index") {
												return <th key={index}>#</th>;
											} else if (item.toUpperCase() === "CREATED_AT") {
												return <th key={index}>DATA DE CRIAÇÃO</th>;
											} else if (item.toUpperCase() === "UPDATED_AT") {
												return <th key={index}>ULTIMA ATUALIZAÇÃO</th>;
											}
											return <th key={index}>{item.toUpperCase()}</th>;
										})}
									</tr>
								</thead>
								<tbody ref={tbodyRef}>
									{produtosRomaneio.map((item, index, self) => {
										// if(index < indexList){
										return (
											<BodyRowProdutoCarregamento
												key={index}
												bodyList={item}
												index={index}
												tableLength={self.length}
												setProdutoSelecionado={setProdutoSelecionado}
												setError={setErrorP2}
												correcao={correcao}
											/>
										);
										// }
									})}
								</tbody>
							</Table>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col sm="4">
							<Button
								variant={correcao ? "warning" : "info"}
								onClick={() => setCorrecao((last) => !last)}
							>
								Correção
							</Button>
						</Col>
						<Col sm="4">
							<Button
								variant={loading.style}
								onClick={onClickDescarregar}
								disabled={loading.style !== "primary" ? true : false}
							>
								{loading.msg}
								{loading.flag ? (
									<Spinner animation="border" color="black" size="sm" />
								) : (
									""
								)}
								{loading.style === "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col sm="4">
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
			{error.flag ? (
				<ModalError
					msg={error.msg}
					title={error.title}
					show={error}
					setShow={setError}
				/>
			) : (
				""
			)}
			{errorP.flag ? (
				<ModalErrorCarregamento
					msg={errorP.msg}
					title={"Erro ao Finalizar Carregamento"}
					show={errorP.flag}
					setShow={setErrorP}
				/>
			) : (
				""
			)}
			{errorP2.flag ? (
				<ModalErrorTotal
					msg={errorP2.msg}
					title={errorP2.title}
					show={errorP2.flag}
					setShow={setErrorP2}
				/>
			) : (
				""
			)}
		</>
	);
}

function BodyRowProdutoCarregamento({
	index,
	bodyList,
	tableLength,
	setProdutoSelecionado = () => {},
	qr_sku = 0,
	setError,
	correcao = false,
}) {
	const refRow = useRef();
	const refRowCheck = useRef(null);
	const produtosList = useSelector((state) => state.userSlice.produtosList);

	const onClickTableRow = (e) => {
		e.preventDefault();
		const checked = refRow.current.children[1].children[0].children[0].checked;

		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}

		// const checked = refRow.current.children[1].children[0].children[0].checked
		const descricao = refRow.current.children[5].innerText;
		const altura = Number(
			refRow.current.children[6].innerText.replace(",", ".")
		); // as dimensões vem em unidade de medida milimetros
		const largura = Number(
			refRow.current.children[7].innerText.replace(",", ".")
		);
		const comprimento = Number(
			refRow.current.children[8].innerText.replace(",", ".")
		);
		const peso = Number(
			refRow.current.children[10].innerText.replace(",", ".")
		);
		const id = refRow.current.children[15].innerText;
		const qr_sku = refRow.current.children[16].innerText;
		const pos_x = Number(
			refRow.current.children[11].innerText.replace(",", ".")
		);
		const pos_y = Number(
			refRow.current.children[12].innerText.replace(",", ".")
		);
		const pos_z = Number(
			refRow.current.children[13].innerText.replace(",", ".")
		);

		if (checked) {
			if (correcao) {
				setProdutoSelecionado({
					pos_x,
					pos_y,
					pos_z,
					id,
					qr_sku,
					descricao,
					altura,
					largura,
					comprimento,
					peso,
					tipo: "produto",
					checked: false,
				});
				return;
			}
			refRow.current.classList = "bg-selected-danger";
			return;
		} else {
			refRow.current.classList = "bg-selected";
		}
		setProdutoSelecionado({
			pos_x,
			pos_y,
			pos_z,
			id,
			qr_sku,
			descricao,
			altura,
			largura,
			comprimento,
			peso,
			tipo: "produto",
			checked: true,
		});
	};

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "planejado") {
							return (
								<td key={_index} className="text-center">
									<Form.Check
										type="checkbox"
										disabled
										ref={refRowCheck}
										checked={bodyList?.planejado ?? false}
										key={index}
									/>
								</td>
							);
						} else if (item === "descarregado") {
							return (
								<td key={_index} className="text-center">
									<Form.Check
										type="checkbox"
										disabled={bodyList?.descarregado ?? false}
										ref={refRowCheck}
										checked={bodyList?.descarregado ?? false}
										key={index}
									/>
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td key={_index} className={item} style={{ minWidth: "500px" }}>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "DTEMISSAO") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						}

						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
		</Fragment>
	);
}
