import { Button, Col, Modal } from "react-bootstrap"


import './Modal.scss'


export default function ModalFinalizarTransferenciaOpcoes({ msg, title, show, setShow, fn = () =>{} }) {

    const handlerSimpleClose = () => setShow(last => ({ ...last, flag: false }))

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow:'auto', margin:'0 20px'}}>
                    <ul>
                        {typeof msg === 'object' ? Object.keys(msg).map((key, index) =>
                            <li key={index}>{
                                key.toUpperCase()}:
                                {typeof msg[key] === 'object' ? <ul>

                                    {Object.keys(msg[key]).map((key2, index2) => {
                                        const resp = msg[key]   
                                        return typeof resp[key2] === 'string' ? <li key={index2}> <b>{resp[key2]}</b></li> : ""
                                    })}
                                </ul>
                                : <b> {String(msg[key]).toUpperCase()}</b>}
                                {/* <b>{String(msg[key]).toUpperCase()}</b> */}
                            </li>) : <li><b> {msg}</b></li>}
                    </ul>
                </Modal.Body>
                <Modal.Footer style={{flexFlow:'row', justifyContent:'center'}}>
                    <Col sm='6'>
                        <Button variant='primary' onClick={() => fn(false)}>Finalizar Parcial</Button>
                    </Col>
                    <Col sm='6'>
                        <Button variant='danger' onClick={() => fn(true)}>Finalizar e Cancelar</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>
    )
}
