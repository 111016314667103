import { createRef, useEffect, useState } from "react"
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { cpf_cnpj_mask, getData, valorMoeda, verificaTipoUsuario } from "../../services/Servicos"

import './Modal.scss'
import LoadingNew from "../loading/LoadingNew"
import { QRCodeSVG } from "qrcode.react"
import { useReactToPrint } from "react-to-print"
const printRef = createRef()

export default function ModalImprimir({ title, show, setShow, msg_obj}) {
    // const [headList, setHead] = useState(['index'])

    const handlerSimpleClose = () => setShow(last => ({ ...last, flag: false }))
    const [showLoading, setShowLoading] = useState({
        show: false,
        msg: 'Imprimir',
        style: 'primary'
    })
    const [loadingProdutos, setLoadingProdutos] = useState(true)
    const [produtos, setProdutos] = useState([])

    const user = useSelector(state => state.userSlice)
    
    const filiaisList = user.filiaisList.data

    const findProdutos = async () => {
        const produtosGet = await getData(verificaTipoUsuario(user) + 'cargas/produtos/id', msg_obj.cargas_id, {})
        setProdutos(produtosGet.data.filter(item => item.numero_pedido == msg_obj.numero_pedido))
        setLoadingProdutos(false)
        
    }

    useEffect(() => {
        findProdutos()    
    }, [])

    const onClickPrint = useReactToPrint({
        content: () => printRef.current
    })

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton className="no-printme">
                    <Modal.Title >{ title }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="no-printme">
                    {/* <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe> */}
                    <div  ref={printRef} style={{fontSize:'0.8rem', padding:'5px'}}>
                        <div className='card' style={{padding:'5px 10px', backgroundColor:'#F9F9F9', borderRadius:'5px', border:'0.5px solid #F9F9F9' }} >
                            <h4 style={{ fontFamily: 'Kanit', color: '#333333' }}>{msg_obj.nome_fantasia}</h4>
                            <br/>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>CPF/CNPJ: {cpf_cnpj_mask(msg_obj.cpf_cnpj)}</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>CEP: {msg_obj.cep}</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>ENDEREÇO: {msg_obj.endereco}</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>BAIRRO: {msg_obj.bairro}</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>CIDADE: {msg_obj.cidade}</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>TELEFONE 1: {msg_obj.tel_1}</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>TELEFONE 2: {msg_obj.tel_2}</h6>
                            
                            <hr style={{ borderStyle: 'dashed', borderWidth: '2px' }} />
                            <h4 style={{ fontFamily: 'Kanit', color: '#333333', display:'flex', alignItems:'center' }}>PRODUTOS
                                {loadingProdutos ? <Spinner animation="border" size="sm" className={"button-loading-span black"} /> : ""}
                            </h4>
                            <br />
                            <Row>
                                <Row className="mb-1">
                                    <Col sm='2'><h5 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0, fontSize:'0.7rem'   }}>ITEM</h5></Col>
                                    <Col sm='3'><h5 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0, fontSize:'0.7rem'   }}>CÓDIGO</h5></Col>
                                    <Col><h5 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0, fontSize: '0.7rem' }}>DESCRIÇÃO</h5></Col>
                                </Row>
                                {produtos.map((item, index) => {
                                    return(
                                        <Row className="mb-1" key={index}>
                                            <Col sm='2'><h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0, fontSize:'0.8rem' }}>{ index + 1}</h6></Col>
                                            <Col sm='3'><h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0, fontSize:'0.8rem'  }}>{ item.codigo}</h6></Col>
                                            <Col><h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0, fontSize:'0.8rem'  }}>{ item.descricao}</h6></Col>
                                        </Row>
                                    )
                                })}
                            </Row>

                            <hr style={{ borderStyle: 'dashed', borderWidth: '2px' }} />                            
                            <h4 style={{ fontFamily: 'Kanit', color: '#333333' }}>PEDIDO</h4>
                            <br />
                            
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0  }}>FILIAL: { filiaisList.filter(item => item.idFilial == msg_obj.id_filial)[0]?.identificacaoInterna }</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin:0 }}>EMISSÃO: {new Date(msg_obj.emissao).toLocaleDateString('pt-BR')}</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0 }}>ROMANEIO : {String(msg_obj.romaneio).padStart(7, 0)}</h6>
                            <h6 style={{ fontFamily: 'Kanit', color: '#333333', margin: 0  }}>PEDIDO: {msg_obj.numero_pedido }</h6>
                            <h4 style={{ fontFamily: 'Kanit', color: '#333333', textAlign:'end' }}>TOTAL {valorMoeda(msg_obj.valor_total)}</h4>
                            <hr style={{ borderStyle: 'dashed', borderWidth: '2px' }} />
                            
                            {new Array(2).fill(1).map((item, index) => <br key={index}/>)}
                            <p className="text-center">
                                <QRCodeSVG value={"{\n" +msg_obj.qr_code_id + "}"}/>

                            </p>
                            
                            {new Array(2).fill(1).map((item, index) => <br key={index}/>)}

                            <Row className="justify-content-between">
                                <Col><span style={{fontSize:'0.8rem'}}>@B-LOG</span></Col>
                                <Col className="text-end"><span style={{fontSize:'0.8rem'}}>{ new Date().toLocaleString('pt-BR')}</span></Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border ">
                    <Button variant={showLoading.style} onClick={onClickPrint} disabled={ showLoading.style !== 'primary' ? true : false}>
                        <LoadingNew show={showLoading.show} msg={showLoading.msg} style={showLoading.style} icone={'bi bi-printer'}/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

