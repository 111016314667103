import { useCallback, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { utils, writeFile } from "xlsx";
import { valorMoeda } from "../../services/Servicos";
import { useSelector } from "react-redux";

export default function ExportToExcel({
	reference = null,
	tableName = "tabela",
	paramExport = [],
}) {
	const filiais = useSelector((state) => state.userSlice.filiaisList.data);
	const funcionarios = useSelector(
		(state) => state.userSlice.funcionariosList.data
	);
	const [loadingExport, setLoadingExport] = useState({
		flag: false,
		msg: "Download",
		style: "primary",
	});

	const exportTxtEspecial = useCallback(() => {
		setLoadingExport({
			flag: true,
			msg: "Carregando...",
			style: "warning",
		});
		let arrayColumns = [...reference];
		if (paramExport.length) {
			arrayColumns = [];
			reference.map((item) => {
				let objectColumns = {};
				paramExport.map((colum) => {
					objectColumns[colum] = item[colum];
				});
				arrayColumns.push(objectColumns);
			});
		}
		setTimeout(() => {
			const textToExport = [];
			arrayColumns.map((item) => {
				let obj = { ...item };

				const pis =
					funcionarios.find((funcionario) => funcionario.cpf == obj.cpf)?.pis ||
					"N/A";
				textToExport.push(
					`${String(obj.dia).padStart(2, 0)}/${String(obj.mes).padStart(
						2,
						0
					)}/${obj.ano} ${String(obj.hora).padStart(2, 0)}:${String(
						obj.minuto
					).padStart(2, 0)} ${pis.replace(/\D/g, "")}\r`
				);
			});

			const element = document.createElement("a");
			const file = new Blob(textToExport, { type: "text/plain" });
			element.href = URL.createObjectURL(file);
			element.download = `${tableName}.txt`;
			element.click();

			setLoadingExport({
				flag: false,
				msg: "Concluido",
				style: "success",
			});

			setTimeout(() => {
				setLoadingExport({
					flag: false,
					msg: "Exportar",
					style: "primary",
				});
			}, 1000);
		}, 1500);
	}, [reference]);

	const exportExcel = useCallback(() => {
		setLoadingExport({
			flag: true,
			msg: "Carregando...",
			style: "warning",
		});
		let arrayColumns = [...reference];
		if (paramExport.length) {
			arrayColumns = [];
			reference.map((item) => {
				let objectColumns = {};
				paramExport.map((colum) => {
					objectColumns[colum] = item[colum];
				});
				arrayColumns.push(objectColumns);
			});
		}
		setTimeout(() => {
			const tableToExport = [];
			arrayColumns.map((item, index, self) => {
				let obj = { ...item };

				Object.keys(obj).forEach((key) => {
					if (key.includes("valor")) {
						obj[key] = valorMoeda(obj[key]);
					}
					if (key.includes("km")) {
						obj[key] = Number(obj[key]).toLocaleString("pt-br", {
							minimumFractionDigits: 2,
						});
					}
					if (key.includes("id_filial")) {
						obj[key] =
							filiais.find((item) => item.idFilial == obj[key])
								?.identificacaoInterna || "N/A";
					}
				});

				const anterior = self[index - 1]
				if (anterior && obj['CARGA B-LOG'] != anterior['CARGA B-LOG']) {
					tableToExport.push({})			
				}
				tableToExport.push(obj);
			});

			const data = String(new Date().toLocaleString("pt-BR"))
				.replaceAll("/", "")
				.replaceAll(":", "-");
			const worksheet = utils.json_to_sheet(tableToExport);
			const workbook = utils.book_new();

			utils.book_append_sheet(workbook, worksheet, data);
			writeFile(workbook, `${tableName}.xlsx`);
			setLoadingExport({
				flag: false,
				msg: "Concluido",
				style: "success",
			});

			setTimeout(() => {
				setLoadingExport({
					flag: false,
					msg: "Exportar",
					style: "primary",
				});
			}, 1000);
		}, 1500);
	}, [reference]);

	useEffect(() => {
		if(!reference) return
		exportExcel()
	}, [reference])

	return null
}
