import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	updateAbastecimentosList,
	updateCargasList,
	updateManutencoesList,
} from "../../../redux/slicers/user";
import {
	apenasNumeros,
	createAbastecimento,
	getAllData,
	getStatusCarga,
	insertInto,
	locationDev,
	numerosDecimal,
	updateTable,
	verificaTipoUsuario,
} from "../../../services/Servicos";

import Loading from "../../loading/Loading";
import ModalError from "../../modal/ModalError";

export default function ModalLancamento(props) {
	const dispatch = useDispatch();
	const caminhoesList = useSelector(
		(state) => state.userSlice.caminhoesList.data
	).filter((item) => item.status);
	const cargas = useSelector((state) => state.userSlice.cargasList.data);
	const user = useSelector((state) => state.userSlice);
	const id_filial = user.tipo.toLowerCase() == "empresa" ? 2 : user.id_filial;
	const filiaisList = user.filiaisList.data;

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		responsavel: String(user.name).toUpperCase(),
		tipo: "PREVENTIVA",
		cpf_responsavel: user.cpf || "N/A",
	});
	const [error, setError] = useState({
		flag: false,
	});

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const submitDataEdit = () => {
		if (!body.placa) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Abastecimento",
					motivo: "Placa/Caminhao não especificado.",
				},
			});
			return;
		}
		// if (!body.km_previsao && !body.data_previsao && body.tipo !== 'CORRETIVA') {
		//     setError({
		//         flag: true,
		//         title: 'Erro',
		//         msg: {
		//             erro: 'Lançamento de Abastecimento',
		//             motivo: 'Km de Previsão ou Data de Previsao devem ser Inserido!'
		//         }
		//     })
		//     return
		// }

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});

		let bodySend = {
			data_abastecimento: new Date().toISOString(),
		};

		Object.keys(body).map((item) => {
			if (item.includes("data")) {
				bodySend[item] = body[item];
				return;
			}

			bodySend[item] = body[item]
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		bodySend.emissao = new Date();

		const url = "manutencao";

		insertInto(url, bodySend)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				setTimeout(() => {
					updateDataTable();
				}, 700);
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setError({
					flag: true,
					title: "Atenção",
					msg: error,
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onChangeInput = (e) => {
		if (e.target.name === "placa") {
			setBody((last) => ({
				...last,
				[e.target.name]: e.target.value,
				id_filial:
					"" +
					caminhoesList.find((item) => item.placa == e.target.value)?.id_filial,
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const editValue = (e) => {
		let valor = String(e.target.value)
			.replaceAll(".", "")
			.replace(",", "")
			.replace(/\D/g, "");

		setBody((last) => ({
			...last,
			[e.target.name]: Number(Number(valor) * 0.01).toLocaleString("pt-br", {
				minimumFractionDigits: 2,
			}),
		}));
	};

	const updateDataTable = async () => {
		const url = "manutencao";
		try {
			let data = await getAllData(url, { id_filial });

			dispatch(updateManutencoesList(data.data));
			handlerSimpleClose();
		} catch (error) {
			setError({
				flag: true,
				message: error.message,
				title: "Aviso!",
			});
		}
	};

	// useEffect(() => {
	//     //atualizar lista de cargas
	//
	// }, [body])

	const onChangeInputFile = async (e) => {
		e.preventDefault();

		const imageFile = e.target.files[0];

		setBody((last) => ({
			...last,
			horimetro: imageFile,
		}));
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo de Manutenção:</Form.Label>
							</Col>
							<Col>
								<Form.Select type="text" name="tipo" onChange={onChangeInput}>
									<option value="PREVENTIVA">Preventiva</option>
									<option value="CORRETIVA">Corretiva</option>
									<option value="ROTAÇÃO DE PNEUS">Rotação de Pneus</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo de Máquina:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="tipo_maquina"
									onChange={onChangeInput}
								>
									<option value=""></option>
									<option value="caminhao">Caminhão</option>
									<option value="outro">Outro</option>
								</Form.Select>
							</Col>
						</Row>
						{body.tipo_maquina !== "outro" ? (
							""
						) : (
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Placa da Máquina:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										name="placa"
										onChange={onChangeInput}
										placeholder="Placa, Descricao"
									/>
								</Col>
							</Row>
						)}
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Caminhão:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="placa"
									onChange={onChangeInput}
									disabled={body.tipo_maquina === "caminhao" ? false : true}
								>
									<option value=""></option>
									{caminhoesList
										.filter((item) =>
											user.tipo.toLowerCase().includes("gestor")
												? item.id_filial == user.id_filial
												: 1 == 1
										)
										.map((i, k) => (
											<option value={i.placa} key={k}>
												{i.tipo} - {i.placa}
											</option>
										))}
								</Form.Select>
							</Col>
						</Row>
						{user.tipo.toLowerCase().includes("gestor") ? (
							""
						) : (
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Filial:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										onChange={onChangeInput}
										value={body.id_filial}
										name="id_filial"
									>
										<option value="N/A"></option>
										{filiaisList.map((item, index) => (
											<option value={item.idFilial} key={index}>
												{item.identificacaoInterna}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
						)}

						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Responsável:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={onChangeInput}
									value={body.responsavel}
									name="responsavel"
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Observações:</Form.Label>
							</Col>
							<Col>
								<textarea
									onChange={onChangeInput}
									value={body.observacao}
									name="observacoes"
									style={{ width: "100%" }}
									rows={7}
								></textarea>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={submitDataEdit}
								disabled={loading.style == "primary" ? false : true}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error ? (
					<ModalError
						show={error.flag}
						setShow={setError}
						title={error.title}
						msg={error.msg}
					/>
				) : (
					""
				)}
			</Modal>
		</>
	);
}
