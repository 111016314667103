import { Fragment } from "react";
import { Card } from "react-bootstrap";
import './Card.scss'

export default function CardLayoutNavbar({children}){
    return(
    <Fragment>
            <Card className='card-default'>
                {children}
            </Card>
    </Fragment>
    )    
}
