import { Fragment } from "react";
import { useSelector } from "react-redux";
import "./MonitoramentoPedidos.scss";
import PedidosFinanceiro from "../apiVST/PedidosFinanceiro";

export default function MonitoramentoPedidosFinanceiro() {
	const user = useSelector((state) => state.userSlice);
	return (
		<Fragment>
			<PedidosFinanceiro {...user} />
		</Fragment>
	);
}
