import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateEmbalagensListDimensoes } from "../../redux/slicers/user";
import {
	apenasNumeros,
	numerosDecimal,
	updateTable,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
const numberFormated = (value) =>
	String(Number(value).toFixed(2)).replace(".", ",");

export default function ModalAtualizarDimensoesEmbalagens(props) {
	const dimensioValue = useRef();

	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);

	const dispatch = useDispatch();
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});
	const [dimensioValu, setDimensionValu] = useState();
	const [tipoPeso, setTipoPeso] = useState("k");
	const [dimensionError, setDimensionError] = useState({
		flag: false,
		msg: "",
	});
	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const setEditDimensoes = async (e) => {
		e.preventDefault();
		const qr_sku = props.qr_sku;
		let value = Number(dimensioValu.replace(",", "."));
		let valueAltura = props.maxValue ? minDim.altura.min : 4.4;

		if (props.dimensao === "peso" && tipoPeso === "t") {
			value *= 1000;
		}

		//
		if (
			props.dimensao === "altura" &&
			(minDim.altura.max < value || value < valueAltura)
		) {
			setDimensionError({
				flag: true,
				msg: `m`,
			});
			setLoading((last) => ({
				...last,
				isLoading: false,
				style: "primary",
				msg: "Salvar",
			}));
			return;
		}
		if (
			props.dimensao === "largura" &&
			(minDim.largura.max < value || value < minDim.largura.min)
		) {
			setDimensionError({
				flag: true,
				msg: `m`,
			});
			setLoading((last) => ({
				...last,
				isLoading: false,
				style: "primary",
				msg: "Salvar",
			}));
			return;
		}
		if (
			props.dimensao === "comprimento" &&
			(minDim.comprimento.max < value || value < minDim.comprimento.min)
		) {
			setDimensionError({
				flag: true,
				msg: `m`,
			});
			setLoading((last) => ({
				...last,
				isLoading: false,
				style: "primary",
				msg: "Salvar",
			}));
			return;
		}
		//
		if (
			props.dimensao === "peso" &&
			(minDim.peso.max < value || value < minDim.largura.min)
		) {
			setDimensionError({
				flag: true,
				msg: `Ton`,
			});
			setLoading((last) => ({
				...last,
				isLoading: false,
				style: "primary",
				msg: "Salvar",
			}));
			return;
		}

		setLoading((last) => ({
			...last,
			isLoading: true,
			style: "warning",
			msg: "Salvando",
		}));

		/* update data on db */
		// cargas/produtos/separador
		const body = {
			[props.dimensao]: value,
			qr_sku,
			cargas_id: props.cargas_id,
		};

		await updateTable("cargas/funcionarios/embalagens", props.qr_sku, body)
			// .then(console.log)
			.catch((error) =>
				setLoading((last) => ({
					...last,
					isLoading: false,
					style: "primary",
					msg: "Salvar",
				}))
			);
		dispatch(
			updateEmbalagensListDimensoes({
				qr_sku,
				dimension: { name: props.dimensao, value: value },
			})
		);

		setTimeout(() => {
			setLoading((last) => ({
				...last,
				isLoading: false,
				style: "success",
				msg: "Salvo",
			}));

			setTimeout(() => {
				handlerSimpleClose();
			}, 500);
		}, 1000);
	};

	const changePeso = (e) => {
		setTipoPeso(e.target.id);
	};

	const onChangeValue = (e) => {
		e.preventDefault();
		setDimensionValu(
			numerosDecimal(apenasNumeros(dimensioValue.current.value))
		);
	};

	const [minDim, setMinDim] = useState({
		/* minimas dimensoes da embalagem de acordo com as maiores dimensoes dod produtos */
		altura: {
			showError: false,
			min: 0,
			max: props.caminhao.altura,
			typed: 0,
		},
		comprimento: {
			showError: false,
			min: 0,
			max: props.caminhao.comprimento,
			typed: 0,
		},
		largura: {
			showError: false,
			min: 0,
			max: props.caminhao.largura,
			typed: 0,
		},
		peso: {
			showError: false,
			min: 0,
			max: props.caminhao.capacidade * 1000,
			typed: 0,
		},
	});

	useEffect(() => {
		const produtosFiltrados = produtosRomaneio.data.filter(
			(item) => item.numero_embalagem === props.numero_embalagem
		);
		const maiorComprimento = produtosFiltrados
			.sort((a, b) => a.comprimento - b.comprimento)
			.map((item) => item.comprimento)
			.reverse();
		const maiorAltura = produtosFiltrados
			.sort((a, b) => a.altura - b.altura)
			.map((item) => item.altura)
			.reverse();
		const maiorLargura = produtosFiltrados
			.sort((a, b) => a.largura - b.largura)
			.map((item) => item.largura)
			.reverse();
		setMinDim((last) => ({
			...last,
			altura: {
				...last.altura,
				min: maiorAltura.at(0),
			},
			comprimento: {
				...last.comprimento,
				min: maiorComprimento.at(0),
			},
			largura: {
				...last.largura,
				min: maiorLargura.at(0),
			},
		}));
		//
	}, []);

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Editar <i>{props.dimensao}</i> do produto: <br></br>{" "}
						<i>{props.descricao}</i>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => e.preventDefault()}>
						<Row className="align-items-end">
							<Col sm="4">
								<Form.Label name="parameter">
									{props.dimensao.toUpperCase()}:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									className="Form-control mb-2"
									placeholder={props.currentValue}
									ref={dimensioValue}
									maxLength={5}
									max={
										props.dimensao === "altura"
											? 4.9
											: props.dimensao === "largura"
											? 2.2
											: 13
									}
									onChange={onChangeValue}
									value={dimensioValu}
								/>
							</Col>
						</Row>
						{props.dimensao !== "peso" ? (
							""
						) : (
							<Row className="justify-content-end">
								<Col sm="4">
									<Form.Check
										className="form-check"
										label="Kilograma"
										type="radio"
										name="group1"
										id="k"
										onClick={changePeso}
										defaultChecked
									/>
								</Col>
								<Col sm="4">
									<Form.Check
										className="form-check"
										label="Tonelada"
										type="radio"
										name="group1"
										id="t"
										onClick={changePeso}
									/>
								</Col>
							</Row>
						)}
						<Row className="justify-content-end mt-2">
							<Col sm="8">
								{dimensionError.flag ? (
									<Form.Text className="text-muted">{`Mínimo ${numberFormated(
										minDim[props.dimensao].min
									)} [ ${dimensionError.msg} ], Máximo ${numberFormated(
										minDim[props.dimensao].max
									)} [ ${dimensionError.msg} ]`}</Form.Text>
								) : (
									""
								)}
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={setEditDimensoes}
								disabled={
									loading.style !== "primary" || !dimensioValu ? true : false
								}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
