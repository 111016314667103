import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Card, Spinner, Table } from "react-bootstrap";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";
import BodyRow from "../../table/body/BodyRow";
import BodyRowCargas from "../../table/body/BodyRowCargas";
import { unicos } from "../../../services/helpers";

export default function GraficoPadraoTopProdutos({
	url,
	title,
	unidadeMedida = "",
	parametroGrafico,
	posisaoUnidadeMedida,
	colors = "#f60809",
	setCaminhaoSelecionado = () => {},
	dataParam,
	arrayData,
	dataFiltered
}) {
	const user = useSelector((state) => state.userSlice);
	const [firstRender, setFirstRender] = useState(true)
	const [options, setOptions] = useState({
		series: [],
	});
	const [loading, setLoading] = useState(true);
	const [res, setRes] = useState([]);
	const [headList, setHeadlist] = useState([]);


	useEffect(() => {
		setLoading(true);
		let resultado = dataFiltered || arrayData;
		setRes(resultado)
		const clientes = unicos(resultado, 'cliente', 'id_filial')

		if (!clientes.length && firstRender) return setFirstRender(false)
		if (!clientes.length && !firstRender) return setLoading(false)
		

		setOptions((last) => ({
			...last,
			series: [
				...clientes
					.map(item => ({ cliente: item.cliente, id_filial: item.id_filial, placa: item.placa, pedidos: resultado.filter(item_2 => item_2.cliente == item.cliente).reduce((p, c) => p + c.pedidos, 0) }))
					.sort((a, b) => b[parametroGrafico] - a[parametroGrafico])
					.slice(0, 15)
			]
		}));
		setHeadlist(["index", ...Object.keys(clientes[0]).filter(item => !['id', 'cargas_id','status_carga'].includes(item))])
		
		setTimeout(() => {
				setLoading(false);
		}, 1000);
	}, [arrayData, dataFiltered]);

	

	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center  pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
			</Card.Title>
			<Card.Body className="p-0">
				
			</Card.Body>
			{loading ? (
				""
			) : (!res.length) ? (
				"Sem dados para o periodo informado"
			) : (
				<Table bordered hover>
					<thead>
						<tr>
							{headList && headList.map((el, key) => <th key={key}>{el}</th>)}
						</tr>
					</thead>
					<tbody>
						{options.series[0] && options.series.map((item, key, s) => <BodyRow key={key} bodyList={item} index={key} headList={headList} tableLength={s.length} />)}
					</tbody>
				</Table>
			)
			}
		</Card>
	);
}
