import { createRef, useEffect, useLayoutEffect, useState } from "react";
import { Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import Loading from "../../loading/Loading";
import {
	update,
	updateCaminhoes,
	updateCaminhoesCaminhoeiros,
	updateCargasList,
} from "../../../redux/slicers/user";
import {
	Unauthorized,
	getAllData,
	getCaminhoes,
	getDateInitMonth,
	getTableColumns,
	locationDev,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import BodyRowCargas from "../../table/body/BodyRowCargas";
import HeadTable from "../../table/head/HeadRow";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import ModalFotoReport from "../../diarioDeBordo/ModalFotoReport";
import "./Cargas.scss";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import { clearPersistData } from "../../../redux/storeConfig/store";

const tableRef = createRef();
const tbodyRef = createRef();

export default function CargasEntregues() {
	const cargas = useSelector((state) => state.userSlice.cargasList);
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const [loading, setLoading] = useState(cargas.length ? false : true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);

	const updateCaminhoesList = () => {
		getCaminhoes(user.tipo).then((response) => {
			dispatch(updateCaminhoes(response));
		});
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		const sorted = filters.sort((a, b) => {
			const propertyA = Object.getOwnPropertyNames(a)[0];
			const propertyB = Object.getOwnPropertyNames(b)[0];
			//
			if (propertyA > propertyB) return 1;
			if (propertyA < propertyB) return -1;
			return 0;
		});
		let actualFilter = "";
		let lastFilter = "";
		let filter = "";
		let keywords = [];

		let paramsToFilter = {};

		filters.map((filterM, indexM) => {
			Object.keys(filterM).map((key) => {
				actualFilter = key;

				if (actualFilter !== lastFilter) {
					lastFilter = actualFilter;
					keywords = [];
				}

				filter = key;
				keywords.push(filterM[key]);
			});
			paramsToFilter[filter] = keywords;
		});

		setFilteredTable([]);
		let testeArray = [];

		Object.keys(paramsToFilter).map((key, _index) => {
			//
			paramsToFilter[key].map((value, index) => {
				if (_index > 0) {
					const dados = testeArray.filter((item) =>
						String(item[key.toLocaleLowerCase()])
							.toLocaleLowerCase()
							.includes(value.toLocaleLowerCase())
					);
					if (dados.length && index === 0) {
						setFilteredTable((last) => [
							...last.filter((item) =>
								String(item[key.toLocaleLowerCase()])
									.toLocaleLowerCase()
									.includes(value.toLocaleLowerCase())
							),
						]);
					} else {
						setFilteredTable((last) => [
							...last,
							...testeArray.filter((item) =>
								String(item[key.toLocaleLowerCase()])
									.toLocaleLowerCase()
									.includes(value.toLocaleLowerCase())
							),
						]);
					}
				} else {
					// ]).toLocaleLowerCase().includes(value.toLocaleLowerCase())))
					const dados = cargas.data.filter((item) =>
						String(item[key.toLocaleLowerCase()])
							.toLocaleLowerCase()
							.includes(value.toLocaleLowerCase())
					);
					if (dados.length && index == 0) {
						testeArray.push(
							...cargas.data.filter((item) =>
								String(item[key.toLocaleLowerCase()])
									.toLocaleLowerCase()
									.includes(value.toLocaleLowerCase())
							)
						);
						setFilteredTable(testeArray);
					} else {
						setFilteredTable((last) => [...last, ...dados]);
					}
				}
			});
		});
		/* remover duplicatas */
		setFilteredTable((last) => removerDadosDuplicados(last, "name"));
	}, [filters]);

	const [headList, setHead] = useState(["index", "admin", "emissao"]);

	const updateArrayCabecalho = async () => {
		const cargasGet = await getTableColumns("Cargas");
		let headList = [
			"id_filial",
			"id",
			"nome",
			"status",
			"peso_total",
			"volume_total",
			"metros_quadrados",
			"qtde_pedidos",
			"qtde_produtos",
			"transferencias",
			"tipo_carga",
			"tipo_transporte",
			"regiao_entrega",
			"caminhao",
			"caminhoneiro",
			"observacao",
			"data_saida",
			...Object.keys(cargasGet.data)
				.filter((key) => key !== "id_filial")
				.filter((key) => key !== "id")
				.filter((key) => key !== "nome")
				.filter((key) => key !== "status")
				.filter((key) => key !== "peso_total")
				.filter((key) => key !== "volume_total")
				.filter((key) => key !== "metros_quadrados")
				.filter((key) => key !== "qtde_pedidos")
				.filter((key) => key !== "qtde_produtos")
				.filter((key) => key !== "transferencias")
				.filter((key) => key !== "tipo_carga")
				.filter((key) => key !== "tipo_transporte")
				.filter((key) => key !== "regiao_entrega")
				.filter((key) => key !== "caminhao")
				.filter((key) => key !== "caminhoneiro")
				.filter((key) => key !== "observacao")
				.filter((key) => key !== "vale_refeicao")
				.filter((key) => key !== "valor_liberado")
				.filter((key) => key !== "cpf_caminhoneiro")
				.filter((key) => key !== "data_cancelamento")
				.filter((key) => key !== "data_abastecimento")
				.filter((key) => key !== "abastecimento_partida")
				.filter((key) => key !== "abastecimento_chegada")
				.filter((key) => key !== "cartao")
				.filter((key) => key !== "valor_cartao")
				.filter((key) => key !== "valor_dinheiro")
				.filter((key) => key !== "valor_pedagio")
				.filter((key) => key !== "valor_refeicao")
				.filter((key) => key !== "valor_")
				.filter((key) => key !== "id_abastecimento_partida")
				.filter((key) => key !== "empresas_id")
				.filter((key) => key !== "id_abastecimento_chegada")
				.filter((key) => key !== "updatedAt")
				.filter((key) => key !== "updated_at")
				.filter((key) => key !== "data_saida")
				.filter((key) => key !== "data_inicio_entrega")
				.filter((key) => key !== "data_chegada")
				.filter((key) => key !== "created_at")
				.filter((key) => key !== "updated_at")
				.filter((key) => key !== "cpf_responsavel")
				.filter((key) => key !== "emissao")
				.filter((item) => item !== "data_entrega"),
				"data_inicio_entrega",
				"data_chegada",
		];
		if (user.email.toUpperCase() ==  process.env.REACT_APP_EMAIL_SUPORTE) {
			setHead((last) => ["suporte", ...last, ...headList]);
		} else {
			setHead((last) => [...last, ...headList]);
		}
	};

	const firstRender = async () => {
		const [cabecalho, caminhoes, caminhoesCaminhoneiros] = await Promise.all([
			updateArrayCabecalho(),
			updateCaminhoesList(),
			getAllData(verificaTipoUsuario(user) + `caminhoes/caminhoneiros`, {}, "")
				.then((resp) => {
					dispatch(updateCaminhoesCaminhoeiros(resp.data));
					setLoading(false);
				})
				.catch((error) => {
					if (error.response.status === 401) {
						clearPersistData();
						return window.location.replace("/login");
					}
				}),
		]);
	};

	useEffect(() => {
		firstRender();
	}, []);

	return (
		<PlanoLositicaLayout>
			<ListarCarga headList={headList} />
		</PlanoLositicaLayout>
	);
}

export function ListarCarga({
	headList,
	height = 600,
	setNomeCarga = () => {},
}) {
	const cargas = useSelector((state) => state.userSlice.cargasList.data).filter(
		(item) => item.status >= 13
	);
	const user = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [cargaSelecionada, setNomeCargaSelecionada] = useState({});
	const [caminhao, setCaminhao] = useState([]);
	const [condutores, setCondutores] = useState([]);
	const [filtersNew, setFiltersNew] = useState({});
	const [cargasId, setCargasId] = useState(undefined);
	const [dataFiltro, setDataFiltro] = useState("");

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");

		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [cargas];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const selectCaminhao = (caminhao, caminhoneiro) => {
		if (!caminhao) {
			setCaminhao({});
			return;
		}
		const caminhaoSelecionado = user.caminhoesList.data.filter(
			(item) => item.placa === caminhao
		); //.includes(caminhao))
		const caminhoneiros = user.caminhoesCaminhoneirosList.data.filter(
			(item) => item.cpf_caminhoneiro === caminhoneiro && caminhoneiro
		);
		setCaminhao(caminhaoSelecionado);
		setCondutores(caminhoneiros);
	};

	const updateArrayCargas = async (data_inicial, data_final) => {
		setLoading(true);
		let id_filial = user.id_filial;

		if (user.id_filial == 2 || user.tipo == 'empresa') {
			id_filial = null;
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);
		let objCons = {
			id_filial,
			data_inicial,
			data_final,
			logica: "gt",
			status: 12,
		};

		setDataFiltro(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);
		const cargas = await getAllData("cargas/em-processamento",
			objCons
		);

		if (user.tipo !== "empresa") {
			dispatch(updateCargasList(cargas.data));
			setLoading(false);
			return;
		}
		// const cargas = await getAllData(verificaTipoUsuario(user) + 'cargas/em-processamento', { logica: 'gt', status: 12 })
		dispatch(updateCargasList(cargas.data));
		setLoading(false);
	};

	useLayoutEffect(() => {
		const date = new Date();
		const dateCorrect = getDateInitMonth(date);
		updateArrayCargas(dateCorrect.data_inicial, dateCorrect.data_final);
	}, []);

	useEffect(() => {
		selectCaminhao(cargaSelecionada?.caminhao, cargaSelecionada?.caminhoneiro);
		setNomeCarga(cargaSelecionada);
		setCargasId(cargaSelecionada.id);
	}, [cargaSelecionada]);

	return (
		<>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<div className="card-header">
					<h3>
						Filtros Selecionados -{" "}
						<i style={{ fontSize: "inherit" }}>{dataFiltro}</i>
					</h3>
				</div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header">
					<h3 style={{ display: "flex", alignItems: "center" }}>
						Tabela de Cargas{" "}
						{loading ? (
							<Spinner
								style={{ marginLeft: "10px", fontSize: "1rem" }}
								animation="border"
								size="sm"
							/>
						) : (
							""
						)}
					</h3>
				</div>
				<Row>
					<Col>
						<div
							className=" responsive-table tes"
							ref={tableRef}
							style={{ position: "relative", height, maxHeight: "unset" }}
						>
							{loading ? (
								""
							) : (
								<Table hover size="sm">
									<ScrollbarLeft
										tableRef={tableRef}
										coluna2={3}
										maxima_distancia={70}
									/>
									<thead style={{ zIndex: "49" }}>
										<HeadTable
											arrayValues={[...cargas]}
											headerList={headList}
											setArrayFilters={setFiltersNew}
											arrayFilters={filtersNew}
											setFiltersOld={setFilters}
											filtersOld={filters}
											fn={updateArrayCargas}
										/>
									</thead>
									<tbody ref={tbodyRef}>
										{filteredTable
											? filteredTable
													.filter((item) => item.status >= 13)
													.map((item, index, self) => {
														if (index < indexList) {
															return (
																<BodyRowCargas
																	key={index}
																	bodyList={item}
																	headList={headList}
																	index={index}
																	setCargaSelecionada={setNomeCargaSelecionada}
																	tableLength={self.length}
																/>
															);
														}
													})
											: [...cargas].reverse().map((item, index, self) => {
													if (index < indexList) {
														return (
															<BodyRowCargas
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
																tableLength={self.length}
																setCargaSelecionada={setNomeCargaSelecionada}
																setArrayFilters={setFiltersNew}
															/>
														);
													}
											  })}
									</tbody>
								</Table>
							)}
							{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
							{filteredTable ? (
								indexList < filteredTable.length ? (
									<Loading color={"black"} msg="Loading" />
								) : (
									""
								)
							) : indexList < cargas.length ? (
								<Loading color={"black"} msg="Loading" />
							) : (
								""
							)}
						</div>
					</Col>
				</Row>
			</div>

			<Informacoes caminhoes={caminhao} condutores={condutores} />
			<Diarios cargas_id={cargasId} />
		</>
	);
}

function Informacoes({ caminhoes, condutores }) {
	return (
		<>
			{!caminhoes.length ? (
				""
			) : (
				<div className="mt-2">
					<h3 className="card-header">Informações da Carga</h3>
					<Row>
						{caminhoes &&
							caminhoes.map((item, key) => {
								return (
									<Col sm="12" md="6" lg="4" key={key}>
										<Card
											className="card-dashboard"
											style={{ backgroundColor: "#F7D56C", padding: "10px" }}
										>
											<Card.Title className="text-center">
												Caminhão {key + 1}
											</Card.Title>
											<hr />
											<Row>
												<Col sm="4">Placa:</Col>
												<Col sm="4">{item.placa}</Col>
											</Row>
											<Row>
												<Col sm="4">Tipo:</Col>
												<Col sm="4">{item.tipo}</Col>
											</Row>
											<Row>
												<Col sm="4">Origem:</Col>
												<Col sm="4">{item.tipo_transporte}</Col>
											</Row>
											<Row>
												<Col sm="4">Comprimento:</Col>
												<Col sm="4">{item.comprimento}</Col>
												<Col sm="2">[m]</Col>
											</Row>
											<Row>
												<Col sm="4">Altura:</Col>
												<Col sm="4">{item.altura}</Col>
												<Col sm="2">[m]</Col>
											</Row>
											<Row>
												<Col sm="4">Largura:</Col>
												<Col sm="4">{item.largura}</Col>
												<Col sm="2">[m]</Col>
											</Row>
											<Row>
												<Col sm="4">Capacidade:</Col>
												<Col sm="4">{item.capacidade}</Col>
												<Col sm="2">[t]</Col>
											</Row>
											<Row>
												<Col sm="4">PBT:</Col>
												<Col sm="4">{item.pbt}</Col>
												<Col sm="2">[t]</Col>
											</Row>
											<Row>
												<Col sm="4">Tara:</Col>
												<Col sm="4">{item.tara}</Col>
												<Col sm="2">[t]</Col>
											</Row>
										</Card>
									</Col>
								);
							})}
						{condutores &&
							condutores.map((item, key) => {
								return (
									<Col sm="12" md="6" lg="4" key={key}>
										<Card
											className="card-dashboard"
											style={{
												backgroundColor: "rgb(108 209 247)",
												padding: "10px",
											}}
										>
											<Card.Title className="text-center">
												Condutor {key + 1}
											</Card.Title>
											<hr />
											<Row>
												<Col sm="4">Condutor:</Col>
												<Col>{item.nome_caminhoneiro}</Col>
											</Row>
											<Row>
												<Col sm="4">CPF:</Col>
												<Col sm="4">{item.cpf_caminhoneiro}</Col>
											</Row>
											<Row>
												<Col sm="4">Telefone:</Col>
												<Col sm="4">{item.telefone_caminhoneiro}</Col>
											</Row>
										</Card>
									</Col>
								);
							})}
					</Row>
				</div>
			)}
		</>
	);
}
function Diarios({ cargas_id = null }) {
	const [diarios, setDiarios] = useState([]);
	const user = useSelector((state) => state.userSlice);
	const [loading, setLoading] = useState(undefined);
	const [showFoto, setShowFoto] = useState({
		flag: false,
		imgSrc: "",
	});

	const updateArrayPontos = async () => {
		const dados = await getAllData(
			verificaTipoUsuario(user) + "diarios/empresas",
			{ cargas_id: cargas_id }
		);
		setDiarios(dados.data);
		setLoading(false);
	};

	useEffect(() => {
		setDiarios([]);
		if (!cargas_id) return setLoading(false);
		setLoading(true);
		updateArrayPontos();
	}, [cargas_id]);

	return (
		<>
			<div className="mt-2">
				<h3 className="card-header d-flex align-items-center">
					<span style={{ fontSize: "inherit" }}>Diário de Bordo da Carga</span>
					{loading ? (
						<Spinner
							size="sm"
							color="black"
							animation="border"
							className="ml-2"
						/>
					) : (
						""
					)}
				</h3>
				<Row className="">
					{diarios.length ? "" : <h4>Sem Diário Lançado</h4>}
					{diarios.map((item, index) => (
						<Col sm="12" md="6" xl="3" key={index} className="mb-4">
							<Card
								style={{ maxWidth: "18rem", margin: "auto" }}
								className="card-dashoard"
							>
								<Card.Title className="text-center my-1 p-2">
									Lançamento N°{String(index + 1).padStart(2, 0)}
								</Card.Title>
								<Card.Img
									variant="top"
									src={locationDev + "/files/users/" + item.upload_file}
									alt="Imagem do Diario"
									style={{ cursor: "pointer" }}
									className="card-dashoard-image-dashboard"
									onClick={(e) =>
										setShowFoto({ flag: true, imgSrc: item.upload_file })
									}
								/>
								<Card.Body>
									<Card.Text className="text-center">{item.mensagem}</Card.Text>
								</Card.Body>
								<Card.Footer className="d-flex justify-content-between card-footer">
									<Card.Text className="align-items-center">
										<i className="bi bi-calendar-date"></i>
										{new Date(item.createdAt).toLocaleDateString("pr-BR")}
									</Card.Text>
									<Card.Text className="align-items-center">
										<i className="bi bi-clock"></i>
										{new Date(item.createdAt).toLocaleTimeString("pr-BR")}
									</Card.Text>
								</Card.Footer>
							</Card>
						</Col>
					))}
				</Row>
			</div>
			{showFoto.flag ? (
				<ModalFotoReport
					imgSrc={showFoto.imgSrc}
					setShow={setShowFoto}
					show={showFoto.flag}
				/>
			) : (
				""
			)}
		</>
	);
}
