import { Fragment, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { update, updateRotasList } from "../../redux/slicers/user";
import {
	getAllData,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";

export default function ModalAtualizarRotas({
	msg,
	title,
	show,
	setShow,
	msgButton,
}) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: msgButton,
	});

	const user = useSelector((state) => state.userSlice);

	const updateRotasListArray = () => {
		getAllData(verificaTipoUsuario(user) + "rotas").then((response) => {
			dispatch(updateRotasList(response.data));
			// handlerSimpleClose()
		});
	};

	const handlerSimpleClose = () =>
		setShow((last) => ({ error: { flag: false } }));

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{title}: {msg.msg.pk}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ExcluirDados msg={msg} />
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function ExcluirDados({ msg }) {
	return (
		<Fragment>
			<div>{msg.title}</div>
			<div>
				{Object.keys(msg.msg).map((key, index) => (
					<div key={index}>
						{key}:{" "}
						<b>
							<i>{msg.msg[key]}</i>
						</b>
					</div>
				))}
			</div>
		</Fragment>
	);
}
