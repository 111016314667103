import { Fragment } from 'react'
import './Footer.scss'

export default function  Footer(){
    return(
        <Fragment>
            <div className="flex-grow-1">
    
            </div>
            <footer className="footer text-muted">
                 &copy; 2022 <i style={{fontSize:'inherit'}}>B-LOG - LOGÍSTICA INTELIGENTE</i>
            </footer>
        </Fragment>
    )
}