import { createRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	apenasNumeros,
	createDespesa,
	numeroDecimais,
	numerosDecimal,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalErrorTotal from "./ModalErrorTotal";

export default function ModalAdicionarDespesa(props) {
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Lançar",
		style: "primary",
	});

	const [error, setError] = useState({
		flag: false,
		msg: "",
	});
	const user = useSelector((state) => state.userSlice);

	const [body, setBody] = useState({
		titulo: "",
		caminhao: props.caminhao_placa,
		cargas_id: props.cargas_id,
		caminhoneiro: props.cpf_caminhoneiro,
		tipo: "",
		tipo_lancamento: "Adicional de Crédito",
		valor: "",
	});

	const submitDataEdit = (e) => {
		// e.preventDefault()
		setLoading({
			isLoading: true,
			msg: "Atualizando Carga",
			style: "warning",
		});

		createDespesa(
			{
				...body,
				valor: String(body.valor).replace(".", "").replace(",", "."),
				link: window.location.origin,
			},
			user.tipo
		)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Despesa Lançada",
					style: "success",
				});

				props.setAtualizarDespesa({ flag: true });
				setTimeout(() => {
					handlerSimpleClose();
				}, 700);
			})
			.catch((error) => {
				setError({
					flag: true,
					msg: error.response.data.msg ?? "Erro no Banco de Dados",
				});

				setLoading({
					isLoading: false,
					msg: "Lançar",
					style: "primary",
				});
			});
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const editValue = (e) => {
		// const value = Number(valor1).toLocaleString('fullwide', {maximumFractionDigits:2, style:'number', currency:'BRL', useGrouping:true})
		setBody((last) => ({
			...last,
			valor: numeroDecimais(apenasNumeros(e.target.value)),
		}));
	};

	const handlerSimpleClose = () => props.setShow((last) => ({ flag: false }));

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.title} - Carga N°{props.id}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => submitDataEdit(e)}>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo do Lancamento:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={onChangeInput}
									name="tipo_lancamento"
									value="Adicional de Crédito"
									disabled
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Titulo da Despesa:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={onChangeInput}
									name="titulo"
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Caminhao:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.caminhao_placa}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Carga:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.cargas_id}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Condutor:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.nome_caminhoneiro}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo:</Form.Label>
							</Col>
							<Col>
								<Form.Select type="text" name="tipo" onChange={onChangeInput}>
									<option value=""></option>
									<option value="manutencao">Manutenção do Caminhao</option>
									<option value="refeicao">Refeicao</option>
									<option value="outros">Outros</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Valor:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={editValue}
									value={body.valor}
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={submitDataEdit}
								disabled={loading.style == "primary" ? false : true}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error ? (
					<ModalErrorTotal
						show={error.flag}
						setShow={setError}
						msg={error.msg}
						title={"Error!"}
						receberNotificacoes={true}
					/>
				) : (
					""
				)}
			</Modal>
		</>
	);
}
