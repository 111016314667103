import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { QRCodeSVG } from "qrcode.react";

import "./Modal.scss";

export default function ModalErrorPretty({
	msg,
	title,
	show,
	setShow,
	message,
	children
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, show: false }));

	// useEffect(() => {,
	//
	// }, [])
	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="sm"
				style={{
					backdropFilter: "brightness(0.3)",
				}}
			>
				<Modal.Body className="pt-0">
					<Row className="text-center">
						<Col sm="12" className="mb-2">
							<div
								style={{
									position: "absolute",
									left: "100px",
									top: "-70px",
									width: "100px",
									height: "100px",
									background: "red",
									borderRadius: "50%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<i
									className="bi bi-exclamation-circle text-white"
									style={{ fontSize: "4rem" }}
								></i>
							</div>
						</Col>
						<Col sm="12" className="mt-5 mb-3">
							<h4>Algo não aconteceu como esperado...</h4>
						</Col>
						<Col sm="12" className="mb-4">
						{!!message && <i style={{ fontSize: "1rem" }}>"{message}"</i>}
						</Col>
						<Col sm="12" className="mb-4">
							{children}
						</Col>
						<Col sm="12" className="mb-2">
							<Button
								variant="danger"
								onClick={handlerSimpleClose}
								style={{ width: "150px" }}
							>
								Fechar
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>
	);
}
