import { Spinner } from "react-bootstrap";
import './Loading.scss'

export default function Loading({msg, color}){
    return (
        <div className="button-loading">  
            <span className={"button-loading-span " +color}>{msg}</span>
            <Spinner animation="border" size="sm" className={"button-loading-span " +color} /> 
        </div>
    )
}