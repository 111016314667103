import { createSlice } from "@reduxjs/toolkit";

export const vendasSlice = createSlice({
    name:'vendas',
    initialState:{
        vendas:[]
    },
    reducers:{
        updateVendas:( state, action ) => {
            if(action.payload){
                /* caso haja mais vendas, insere na */
                if (action.payload.length !== state.vendas.length){
                    state.vendas = action.payload
                }
            }
        }
    }
})


export const { updateVendas } = vendasSlice.actions
export default vendasSlice.reducer




