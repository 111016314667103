import { createRef, useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../loading/Loading";
import HeadTable from "../../table/head/HeadRow";
import { getTableColumns, getTransferencias } from "../../../services/Servicos";
import {
	updateTransferencias,
	updatetransferencias,
} from "../../../redux/slicers/user";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	getErrorMessage,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ModalError from "../../modal/ModalError";
import TransferenciasLayout from "../layout/TransferenciasLayout";
import BodyRowPedidosTransferencia from "../../table/body/BodyRowPedidosTransferencia";

import ExportToExcel from "../../exportToExcel/ExportToExcel";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

const tbodyRef = createRef();
const tableDiv = createRef();

export default function TransferenciasEmitidas({
	setTransferenciaSelecionada = () => {},
	headList_ = [],
	cargas_id,
}) {
	const transferencias = useSelector(
		(state) => state.userSlice.transferenciasList.data
	);

	const [loading, setLoading] = useState(true);

	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const user = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();

	const [headList, setHead] = useState(headList_);
	const [error, setError] = useState({ show: false, message: "", title: "" });

	const updateHead = async () => {
		if (!headList_.length) {
			const abastecimentopHeader = await getTableColumns(
				"CargasPedidosTransferencias"
			);
			setHead([
				"index",
				"admin",
				"transferencia",
				"status",
				"descricao",
				"tipo",
				...Object.keys(abastecimentopHeader.data)
				.filter((key) => key !== "empresas_id")
				.filter((key) => key !== "id")
				.filter((key) => key !== "tipo")
				.filter((key) => key !== "data_cancelamento")
				.filter((key) => key !== "transferencia")
				.filter((key) => key !== "status")
				.filter((key) => key !== "descricao")
				.filter((key) => key !== "emissao")
				.filter((key) => key !== "responsavel")
				.filter((key) => key !== "created_at")
				.filter((key) => key !== "updated_at"),
				"emissao",
				"responsavel",
			]);
		}

		const body =
			user.tipo != "empresa" && user.id_filial != 2
				? { id_filial_destino: user.id_filial }
				: {};

		getTransferencias(body)
			.then((response) => {
				if (headList_.length) {
					dispatch(
						updateTransferencias(
							response
								.filter((item) => !item.cargas_id)
								.filter((item) => item.status < 6)
						)
					);
				} else {
					dispatch(
						updateTransferencias(response.filter((item) => item.status < 6))
					);
				}
			})
			.catch((error) => {
				setError({
					show: true,
					message: getErrorMessage(error),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		updateHead();
	}, []);

	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [transferencias];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<TransferenciasLayout>
			<div className="section-filters-table">
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Transferências
								<span className="ms-2">
									{loading ? <Spinner size="sm" animation="border" /> : ""}
								</span>
							</h3>
						</Col>
						<Col sm="12" md="6" lg="2">
							<ExportToExcel
								tableName="Tranferencias"
								reference={transferencias}
							/>
						</Col>
					</Row>
				</div>
				<div
					className="responsive-table"
					ref={tableDiv}
					style={{ position: "relative", height: "600px", maxHeight: "unset" }}
				>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={transferencias}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowPedidosTransferencia
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setTransferenciaSelecionada={
														setTransferenciaSelecionada
													}
												/>
											);
										}
								  })
								: [...transferencias].reverse().map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowPedidosTransferencia
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={transferencias.length}
													setTransferenciaSelecionada={
														setTransferenciaSelecionada
													}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < transferencias.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</TransferenciasLayout>
	);
}
