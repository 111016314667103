import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { update, updateManutencoesList } from "../../../redux/slicers/user"
import { getAllData, getDateInitMonth, updateTable, valorMoeda, verificaTipoUsuario } from "../../../services/Servicos"
import ModalAtribuirCaminhoeiro from "../../modal/ModalAtribuirCaminhoeiro"
import ModalAtualizarCaminhoes from "../../modal/ModalAtualizarCaminhoes"
import ModalError from "../../modal/ModalError"
import ModalAtribuido from "../../modal/ModalJaAtribuido"

import './BodyRow.scss'
import { Spinner } from "react-bootstrap"
import ModalCancelamento from "../../frota/manutencao/ModalCancelamento"
import ModalLancamentoItens from "../../frota/manutencao/ModalLancamentoItens"
import ModalEdicao from "../../frota/manutencao/ModalEdicao"
import ModalLancamentoPecasItens from "../../frota/manutencao/ModalLancamentoPecasItens"
import { getDateUTC } from "../../../services/helpers"
import ModalErrorPretty from "../../modal/ModalErrorPretty"

export default function BodyRowManutencoesItems({ headList, bodyList, index, setDataTable = () =>{} }) {
    const refRow = useRef(null)
    const refExcluir = useRef(null)
    const dispatch = useDispatch()
    const userS = useSelector(state => state.userSlice)
    const id_filial = userS.tipo.toLowerCase() == 'empresa' ? 2 : userS.id_filial
    const filiaisList = userS.filiaisList.data

    const [controleCancelamento, setControleCancelamento] = useState({falg: false})
    const [controleEdicao, setControleEdicao] = useState({falg: false})
    const [controleEdicaoItensManutencao, setControleEdicaoItensManutencao] = useState({falg: false})
    const [adminFunction, setAdminFunction] = useState({
        error: {
            flag: false,
            title: '',
            msg: '',
            confirmar: ''
        }
    })
    const [atribuir, setAtribuir] = useState({
        flag: false,
        placa:'',
        returned:false
    })
    const [jaAtribuido, setJaAtribuido] = useState({
        flag: false,
    })
    const [error, setError] = useState({
        flag: false,
        title: '',
        msg: '',
    })
    const [loading, setLoading] = useState(false)


    const getData = useCallback(async (manutencao_id) => {
        const url = 'manutencao/items'
        try {
            let data = await getAllData(url, {manutencao_id})
            setDataTable(data.data.map(item => ({
                ...item,
                emissao: getDateUTC(item.emissao),
                data_previsao: item.data_previsao ? getDateUTC(item.data_previsao) : '',
                data_garantia: item.data_garantia ? getDateUTC(item.data_garantia) : '',
                data_finalizacao: item.data_finalizacao ? getDateUTC(item.data_finalizacao) : '',
            })))


            
        const url2 = 'manutencao'
            const date = new Date()
            let data1 = await getAllData(url2, {id_filial})

            if (userS.tipo.toLowerCase().includes('gestor')) {
                data1 = data1.data.filter(item => item.id_filial == userS.id_filial)
            } else {
                data1 = data1.data
            }

            dispatch(updateManutencoesList(data1))
        } catch (error) {
            setError({
                show: true, message: error.message, title:'Aviso!'
            })
        }
    })

    const onClickCancelItem = async () => {
        await updateTable('manutencao/item', bodyList.id, {data_cancelamento: new Date(), user_cancelamento: userS.name, status: -1, manutencao_id: bodyList.manutencao_id}) // cancelado
        getData(bodyList.manutencao_id)
    }

    const onClickEditar = async (bodyUpdate) => {
        await updateTable('manutencao/item', bodyList.id, { ...bodyUpdate, manutencao_id: bodyList.manutencao_id })
        getData(bodyList.manutencao_id)
    }
    const [pecasManutencao, setPecasManutencao] = useState({falg: false})
    


    useEffect(() => {
        if(jaAtribuido.returned){
            setAtribuir({flag: true, placa: jaAtribuido.placa})
        }
    }, [jaAtribuido])
    return (
        <Fragment>
            {
                <tr ref={refRow} style={{verticalAlign:'middle'}}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            }
                            else if (item === 'tipo') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span
                                            style={{
                                                fontSize: '0.8rem', padding: '7px 15px', borderRadius: '10px',
                                                backgroundColor: bodyList.tipo == 'CORRETIVA' ? "#ff0000bf" : '#b7d972'
                                            }}>{bodyList[item]}
                                        </span>
                                    </div>
                                </td>
                            }
                            else if (item === 'admin') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span className="excluir">
                                            <i className="bi bi-x-lg"
                                                title="Excluir"
                                                onClick={() => setControleCancelamento({ bodyList, flag: true, title: 'Cancelar Manutencao', fn: onClickCancelItem })}>
                                            </i>
                                        </span>
                                        <span className="editar" hidden={bodyList.status == '2'}>
                                            <i className="bi bi-pencil"
                                                title="Editar"
                                                onClick={() => setControleEdicao({ bodyList, flag: true, title: 'Editar Manutencao', fn: onClickEditar })}>
                                            </i>
                                        </span>
                                    </div>
                                </td>
                            }
                            else if (item === 'pecas') {

                                return <td key={_index}>
                                    <div className="admin align-items-center">
                                        <span className="editar">
                                            <i className="bi bi-plus-circle"
                                                title="Pecas do Iten de Manutenção"
                                                onClick={() => setPecasManutencao({ bodyList, flag: true, title:'Peças do Item', updateItem: getData, tipo:'item' })}
                                            >
                                            </i>
                                        </span>
                                        <span>{ bodyList[item]}</span>
                                    </div>
                                </td>
                            }
                            else if (item === 'placa') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            }
                            else if (item === 'id_filial') { /* primary key can not be modified */

                                return <td key={_index}>{filiaisList.filter(item => item.idFilial == bodyList.id_filial)[0]?.identificacaoInterna ?? 'NÃO INFORMADO'}</td>

                            }
                            else if (item === 'status') {

                                return <td key={_index} className={item}>
                                {
                                    bodyList[item] == 2 ? 
                                        <div className="div-status">
                                            <span className="div-status-ativo bg-success">Finalizado</span>
                                        </div>
                                    :
                                        ''
                                }
                                {
                                    bodyList[item] == 1 ? 
                                        <div className="div-status">
                                            <span className="div-status-ativo bg-warning">Em Manutencao</span>
                                        </div>
                                    :
                                        ''
                                }
                                {
                                    bodyList[item] == 0 ? 
                                        <div className="div-status">
                                            <span className="div-status-ativo bg-info">Agendado</span>
                                        </div>
                                    :
                                        ''
                                }
                                </td>

                            }
                            // else if (['createdAt','updatedAt','data_finalizacao'].includes(item)) { /* primary key can not be modified */
                            //     return <td key={_index}>{new Date(bodyList[item]).toLocaleDateString('pt-br', {timeZone:'UTC'})}</td>

                            // }
                            else if (item === 'observacoes') { /* primary key can not be modified */

                                return <td key={_index} style={{ minWidth: '10rem', cursor: 'pointer' }}>
                                    <textarea value={bodyList[item]}></textarea>
                                </td>

                            }
                            else if (item.includes('km')) {
                                return <td key={_index}>
                                    <div className="admin">
                                        {Number(bodyList[item]).toLocaleString('pt-br', {minimumFractionDigits: 2})}
                                    </div>
                                </td>
 
                            }
                            else if (item === 'valor') {
                                return <td key={_index}>
                                    <div className="admin">
                                        {valorMoeda(bodyList[item])}
                                    </div>
                                </td>
 
                            }
                            else if (item === 'itens') {
                                return <td key={_index}>
                                     <div className="admin">
                                        <span className="editar"> <i className="bi bi-plus-circle" title="Controle de Itens"
                                            onClick={() => setControleEdicaoItensManutencao({ bodyList, flag: true, title: 'Adicionar Itens de Manutencao' })}></i></span>
                                        <span> {bodyList[item]}</span>
                                    </div>
                                </td>
 
                            }
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            
            {controleEdicaoItensManutencao.flag ? <ModalLancamentoItens {...controleEdicaoItensManutencao} setShow={ setControleEdicaoItensManutencao } /> : ''}
            {controleCancelamento.flag ? <ModalCancelamento {...controleCancelamento} setShow={ setControleCancelamento } /> : ''}
            {controleEdicao.flag ? <ModalEdicao {...controleEdicao} setShow={setControleEdicao} /> : ''}

            {pecasManutencao.flag ? <ModalLancamentoPecasItens show={pecasManutencao.flag} setShow={setPecasManutencao} {...pecasManutencao} /> : ""}

            {atribuir.flag ? <ModalAtribuirCaminhoeiro  placa={atribuir.placa} show={atribuir.flag} setShow={setAtribuir} setError={setError}/> : ""}
            {jaAtribuido.flag ? <ModalAtribuido show={jaAtribuido.flag} setShow={setJaAtribuido} msgAlert={jaAtribuido.msg}/> : ""}
            {error.show ? <ModalErrorPretty setShow={setError} {...error}/> : ""}
        </Fragment>
    )
}