import { createRef, useEffect, useLayoutEffect, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

import { getAllData, getTableColumns } from "../../services/Servicos";
import { ListarProdutos } from "../planoLogistica/romaneios/Romaneios";

import "./Modal.scss";
import HeadTable from "../table/head/HeadRow";
import BodyRowProdutoEmbalado from "../table/body/BodyRowProdutosEmbalado";
import Loading from "../loading/Loading";
import user from "../../redux/slicers/user";

const headListEmbalagens = [
    "index",
	"admin",
	"id_filial",
	"numero_pedido",
	"numero_embalagem",
	'conferido',
	'planejado',
	'carregado',
	'descarregado',
	"qtde_produtos",
	"descricao",
	"comprimento",
	"peso",
	"id",
	"cargas_id",
];

const tableRef = createRef();
const tbodyRef = createRef();

export default function ModalListarEmbalagens({
	title,
	show,
	setShow,
	pedido,
	cargas_id,
}) {
	const user = useSelector(state => state.userSlice)
	const [headList, setHead] = useState(["index", "admin"]);
	const [loading, setLoading] = useState(true);
	const [embalagens, setEmbalagens] = useState([]);
	const handlerSimpleClose = () => setShow({ show: false });

	const findEmbalagens = async () => {
		try {
			let embal
			if (user.tipo.toLowerCase() == 'empresa') {
				embal = await getAllData(`embalagens/empresa/${cargas_id}`);
			} else {
				embal = await getAllData("cargas/funcionarios/embalagens", {cargas_id: cargas_id});
			}
			setEmbalagens(embal.data);
            
		} catch (error) {
			
		}
		setLoading(false)
	};
    useEffect(() => {
        setLoading(true)
       findEmbalagens() 
    },[]);
	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>{title}:</span>
						<span>
							<b>
								{pedido} - {cargas_id}
							</b>
						</span>
                            {loading ? <Spinner color={'black'} size='sm' animation='border'/> : ''}
					</Modal.Title>
				</Modal.Header>
                <Modal.Body>
                    
                    <div className=" responsive-table" ref={tableRef} style={{ position: 'relative', height: '600px' }}>
                        <Table size="sm">
                            <thead style={{ zIndex: "49" }}>
                                <HeadTable
                                    arrayValues={embalagens}
                                    headerList={headListEmbalagens}
                                />
                            </thead>
                            <tbody ref={tbodyRef}>
                                {embalagens &&
                                    [...embalagens].reverse().map((item, index, self) => {
                                        return (
                                            <BodyRowProdutoEmbalado
                                                key={index}
                                                bodyList={item}
                                                headList={headListEmbalagens}
                                                index={index}
                                                tableLength={self.length}
                                            />
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Sair
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
