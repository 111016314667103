import { Spinner } from "react-bootstrap";
import './Loading.scss'

export default function LoadingNew({ show, style, msg, icone }) {
    
    return (
        <div className="button-loading">  
            <span className={"button-loading-span"}>{msg}</span>
            {
                !show ?
                    (style !== 'success' ?
                        <i className={icone}></i> : <i className='bi bi-check'></i>) : <Spinner animation="border" size="sm" className={"button-loading-span white"} /> 

            }
        </div>
    )
}