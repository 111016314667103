import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../loading/Loading";

import "../../modal/Modal.scss";
import ModalError from "../../modal/ModalError";
import { getAllData } from "../../../services/Servicos";
export default function ModalEdicao(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const manutencaoList = user.manutencoesList.data;

	const [body, setBody] = useState({});
	const [dataFornecedores, setDataFornecedores] = useState([]);

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});
	const [error, setError] = useState(false);

	const onClickButton = async () => {
		if (body.posicao_atual && body.posicao_previsao && !body.km_realizado) {
			setError({
				flag: true,
				msg: "KM REALIZADO não informado",
				title: "Aviso",
			});
			return;
		}

		setLoading({ isLoading: true, style: "warning", msg: "Aguarde" });
		let bodySend = {};

		Object.keys(body).map((item) => {
			if (item.includes("data")) {
				bodySend[item] = body[item];
				return;
			}

			bodySend[item] = body[item]
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		if (body.status == 2 && props.fonte != "EDICAO PNEUS") {
			bodySend.data_finalizacao = new Date();
		}
		if (body.status == 1 && props.fonte == "EDICAO PNEUS") {
			bodySend.data_cancelamento = new Date();
			bodySend.user_cancelamento = user.email;
		}

		/* caso seja transferencia de pneus para outro caminhao, coloquei de onde veio */
		if (body.manutencao_id_novo && body.status == 2) {
			bodySend.transferencia =
				"PNEU TRANSFEREIDO DA MANUTENÇÃO N°" + props.bodyList.manutencao_id;
			bodySend.status = 0;
		}

		await props.fn(bodySend);
		setLoading({ isLoading: false, style: "success", msg: "Salvo!" });
		setTimeout(() => {
			handlerSimpleClose();
		}, 500);
	};

	const getFornecedores = useCallback(async () => {
		const url = "manutencao/fornecedores";

		try {
			const data = await getAllData(url, { id_filial: user.id_filial });
			setDataFornecedores(data.data);
		} catch (error) {
			setError({
				flag: true,
				msg: error,
				title: "Aviso!",
			});
		}
	});
	const onChangeInput = (e) => {
		if (e.target.name == "fornecedor_cnpj") {
			setBody((last) => ({
				...last,
				[e.target.name]: String(e.target.value).toUpperCase(),
				fornecedor:
					dataFornecedores.find((item) => item.cnpj == e.target.value)
						?.descricao || "N/A",
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};
	const editValue = (e) => {
		let valor = String(e.target.value)
			.replaceAll(".", "")
			.replace(",", "")
			.replace(/\D/g, "");
		if (e.target.name == "aviso_data") {
			setBody((last) => ({
				...last,
				[e.target.name]: Number(Number(valor)).toLocaleString("pt-br", {
					minimumFractionDigits: 0,
				}),
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: Number(Number(valor) * 0.01).toLocaleString("pt-br", {
				minimumFractionDigits: 2,
			}),
		}));
	};

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ error: { flag: false } }));

	useEffect(() => {
		getFornecedores();
	}, []);
	return (
		<>
			<Modal
				show={props.flag}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.title}: {props.bodyList.id}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						{Object.keys(props.bodyList)
							.filter(
								(item) =>
									![
										"item_manutencao",
										"transferencia",
										"pecas",
										"id",
										"id_filial",
										"tipo_maquina",
										"placa",
										"itens",
										"emissao",
										"data_previsao",
										"responsavel",
										"manutencao_id",
										"fotos",
										"numero_pneu",
										"createdAt",
										"updatedAt",
									].includes(item)
							)
							.map((key, index) => {
								if (key == "fornecedor") {
									return (
										<Row className="align-items-end" key={index}>
											<Col sm="4" className="mb-2">
												<Form.Label>{key.toUpperCase()}:</Form.Label>
											</Col>
											<Col className="mb-2">
												<Form.Select
													name="fornecedor_cnpj"
													onChange={onChangeInput}
													defaultValue={props.bodyList[key]}
												>
													<option value=""></option>
													{dataFornecedores.map((item, key) => (
														<option value={item.cnpj}>{item.descricao}</option>
													))}
												</Form.Select>
											</Col>
										</Row>
									);
								}
								if (key == "tipo" && props?.fonte == "EDICAO PNEUS") {
									return (
										<Row className="align-items-end" key={index}>
											<Col sm="4" className="mb-2">
												<Form.Label>{key.toUpperCase()}:</Form.Label>
											</Col>
											<Col className="mb-2">
												<Form.Select
													name="tipo"
													onChange={onChangeInput}
													defaultValue={props.bodyList[key]}
												>
													<option value="NOVO">NOVO</option>
													<option value="1° RECAPAMENTO">1° RECAPAMENTO</option>
													<option value="2° RECAPAMENTO">2° RECAPAMENTO</option>
													<option value="3° RECAPAMENTO">3° RECAPAMENTO</option>
													<option value="4° RECAPAMENTO">4° RECAPAMENTO</option>
													<option value="5° RECAPAMENTO">5° RECAPAMENTO</option>
												</Form.Select>
											</Col>
										</Row>
									);
								}
								if (key == "status" && props?.fonte == "EDICAO PNEUS") {
									return (
										<Row className="align-items-end" key={index}>
											<Col sm="4" className="mb-2">
												<Form.Label>{key.toUpperCase()}:</Form.Label>
											</Col>
											<Col className="mb-2">
												<Form.Select
													name="status"
													onChange={onChangeInput}
													defaultValue={props.bodyList[key]}
												>
													<option value="0">ATIVO</option>
													<option value="1">CANCELADO</option>
													<option value="2">TRANSFERIDO</option>
												</Form.Select>
											</Col>
										</Row>
									);
								}
								if (key == "status") {
									return (
										<Row className="align-items-end" key={index}>
											<Col sm="4" className="mb-2">
												<Form.Label>{key.toUpperCase()}:</Form.Label>
											</Col>
											<Col className="mb-2">
												<Form.Select
													name="status"
													onChange={onChangeInput}
													defaultValue={props.bodyList[key]}
												>
													<option value="0">Agendado</option>
													<option value="1">Em Manutencao</option>
													<option value="2">Finalizado</option>
												</Form.Select>
											</Col>
										</Row>
									);
								}
								if (key == "posicao_atual") {
									return (
										<Row className="align-items-end" key={index}>
											<Col sm="4" className="mb-2">
												<Form.Label>{key.toUpperCase()}:</Form.Label>
											</Col>
											<Col className="mb-2">
												<Form.Select
													name="posicao_atual"
													onChange={onChangeInput}
													defaultValue={props.bodyList[key]}
												>
													{new Array(16).fill("").map((item, key) => {
														return (
															<option
																key={key}
																value={`P${key + 1}`}
																disabled={props.dataTable.find(
																	(item) => item.posicao_atual == `P${key + 1}`
																)}
															>
																P{key + 1}
															</option>
														);
													})}
												</Form.Select>
											</Col>
										</Row>
									);
								}
								if (key == "posicao_previsao") {
									return (
										<Row className="align-items-end" key={index}>
											<Col sm="4" className="mb-2">
												<Form.Label>{key.toUpperCase()}:</Form.Label>
											</Col>
											<Col className="mb-2">
												<Form.Select
													name="posicao_previsao"
													onChange={onChangeInput}
													defaultValue={props.bodyList[key]}
												>
													{new Array(16).fill("").map((item, key) => {
														return (
															<option
																key={key}
																value={`P${key + 1}`}
																disabled={props.dataTable.find(
																	(item) =>
																		item.posicao_previsao == `P${key + 1}`
																)}
															>
																P{key + 1}
															</option>
														);
													})}
												</Form.Select>
											</Col>
										</Row>
									);
								}
								return (
									<Row className="align-items-end" key={index}>
										<Col sm="4" className="mb-2">
											<Form.Label>{key.toUpperCase()}:</Form.Label>
										</Col>
										<Col className="mb-2">
											<Form.Control
												type={
													key.includes("data") && !key.includes("aviso")
														? "date"
														: "text"
												}
												className="form-control-input"
												value={body[key]}
												defaultValue={props.bodyList[key]}
												name={key}
												onChange={
													[
														"valor",
														"km_real",
														"aviso_km",
														"aviso_data",
														"km_previsao",
														"km_realizado",
													].includes(key)
														? editValue
														: onChangeInput
												}
											/>
										</Col>
									</Row>
								);
							})}
						{body.status == "2" ? (
							<Row className="align-items-end">
								<Col sm="4" className="mb-2">
									<Form.Label>Nova Manutencao:</Form.Label>
								</Col>
								<Col className="mb-2">
									<Form.Select
										name="manutencao_id_novo"
										onChange={onChangeInput}
									>
										<option value=""></option>
										{manutencaoList
											.filter((item) => item.tipo.includes("ROTAÇÃO"))
											.filter((item) => item.id != props.bodyList.manutencao_id)
											.map((item, key) => (
												<option value={item.id}>
													{item.id} - {item.tipo} - {item.placa}{" "}
												</option>
											))}
									</Form.Select>
								</Col>
							</Row>
						) : (
							""
						)}
					</Form>
					{error.flag ? (
						<ModalError
							show={error.flag}
							setShow={setError}
							title={error.title}
							msg={error.msg}
						/>
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button variant={loading.style} onClick={onClickButton}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
