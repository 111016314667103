import { createRef, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";
import { saveNewPassword } from "../../services/Servicos";
import "./ResetPassword.scss";

const form = createRef(null);
const senhasDiferentes = createRef(null);
const minLengthError = createRef(null);

export function RecoverPassword() {
	const navigate = useNavigate();

	const [btnLoading, setBtnLoading] = useState({
		flag: false,
		msg: "Salvar",
		style: "primary",
	});

	const [error, setError] = useState({
		flag: false,
		msg: "",
		color: "red",
		errorLength: false,
	});

	const [liberarBotao, setLiberarBotao] = useState(true);

	const onChangeHandler = (e) => {
		e.preventDefault();

		const new_password = form.current.new_password.value;
		const confirm_password = form.current.confirm_password.value;

		/* desbloquear botao com dados inseridos */
		if (new_password.length && confirm_password.length) {
			setLiberarBotao(false);
		} else {
			setLiberarBotao(true);
		}

		/* mostrar erro de senhas diferentes */
		if (new_password !== confirm_password && confirm_password.length > 0) {
			setError((last) => ({
				...last,
				flag: true,
				msg: "Senhas Diferentes.",
			}));
		} else {
			setError((last) => ({
				...last,
				flag: false,
			}));
		}

		if (new_password.length && new_password.length < 8) {
			setError((last) => ({
				...last,
				errorLength: true,
			}));
		} else {
			setError((last) => ({
				...last,
				errorLength: false,
			}));
		}
	};

	const onSubmitHandler = (e) => {
		e.preventDefault();
		if (error.errorLength) {
			const textError = minLengthError.current;
			textError.style.fontSize = "1.3rem";
			setTimeout(() => {
				textError.style.fontSize = "1rem";
			}, 1000);
			return;
		} else if (error.flag) {
			const textError = senhasDiferentes.current;

			textError.style.fontSize = "1.3rem";
			setTimeout(() => {
				textError.style.fontSize = "1rem";
			}, 1000);
			return;
		}

		setBtnLoading({
			flag: true,
			msg: "Salvando",
		});
		const queryString = window.location.search;
		const params = new URLSearchParams(queryString);

		saveNewPassword({
			email: params.get("email"),
			token: params.get("token"),
			new_password: form.current.confirm_password.value,
		})
			.then((response) => {
				setBtnLoading({
					flag: false,
					msg: "Senha Atualizada",
					style: "success",
				});
				setError({
					flag: true,
					msg: response.msg,
				});
				setTimeout(() => {
					navigate("/login");
				}, 2000);
			})
			.catch((error) => {
				setError({
					flag: true,
					msg: error.msg.msg,
				});
				setBtnLoading({
					flag: false,
					msg: "Error",
					style: "danger",
				});
				setTimeout(() => {
					navigate("/login");
				}, 2000);
			});
	};

	return (
		<>
			<Row className="login mx-auto">
				<Col lg={10} className="mx-auto">
					<Form className="login-form" ref={form} onSubmit={onSubmitHandler}>
						<Row>
							<h1>
								Recuperação de Senha ou{" "}
								<span>
									<a href="/login">Entrar</a>
								</span>
							</h1>
						</Row>
						<hr />
						<Form.Group className="mb-3">
							<Form.Label>Nova Senha:</Form.Label>
							<Form.Control
								required
								type="text"
								name="new_password"
								onChange={onChangeHandler}
							/>
							{error.errorLength ? (
								<Form.Text
									className={"error-form-color-red"}
									ref={minLengthError}
								>
									Minimo de 8 Caracteres!
								</Form.Text>
							) : (
								""
							)}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Repita a Nova Senha:</Form.Label>
							<Form.Control
								required
								type="text"
								name="confirm_password"
								onChange={onChangeHandler}
							/>
							{error.flag ? (
								<Form.Text
									className={"error-form-color-red"}
									ref={senhasDiferentes}
								>
									{error.msg}
								</Form.Text>
							) : (
								""
							)}
						</Form.Group>
						<br />
						<br />
						<Button
							variant={btnLoading.style}
							type="submit"
							disabled={liberarBotao}
						>
							{btnLoading.flag ? (
								<Loading msg={"Enviando Código "} />
							) : (
								<span style={{ color: "#ffffff" }}>
									{btnLoading.msg}
									{btnLoading.style === "success" ? (
										<i className="bi bi-check"></i>
									) : (
										""
									)}
								</span>
							)}
						</Button>
					</Form>
				</Col>
			</Row>
		</>
	);
}
