import { useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import Loading from "../loading/Loading"

import './Modal.scss'
import ModalError from "../modal/ModalError"

export default function ModalAtualizarDataEntradaVST(props) {

    const [loading, setLoading] = useState({
        isLoading: false,
        msg: "Pesquisar",
        style: 'primary'
    })
    const [error, setError] = useState(false)
    const [parametros, setParametros] = useState({})

    const handlerSimpleClose = () => props.setShow(last => ({...last, show: false }))

    
    const onChangeValueDate = (e) => {
        setParametros(last => ({
                ...last,
                [e.target.name] : e.target.value
            })
        ) 
    }
    const onSaveInfo = (e) => {
        if (!parametros.data_inicial || !parametros.data_final) {
           setError({flag: true, list:'Data não informada corretamente'})
            return
        }
        setLoading({isLoading: true, msg:'Carregando',  style:'warning'})
        
        props.fn(parametros.data_inicial, parametros.data_final)
        setTimeout(() => {
            handlerSimpleClose()
        }, 1000)
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Editar Periodo - <i style={{fontSize:'inherit'}}>{props.filtro}</i></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={e => e.preventDefault()}>
                        <Row className='align-items-end'>
                            <Col sm='4'>
                                <Form.Label name='parameter'>Data Inicio do Periodo</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type='date' 
                                className="form-control mb-2" 
                                placeholder={props.currentValue} 
                                name={'data_inicial'}
                                onChange={onChangeValueDate}
                                />
                                
                            </Col>
                        </Row>
                        <Row className='align-items-end'>
                            <Col sm='4'>
                                <Form.Label name='parameter'>Data Fim do Periodo</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type='date' 
                                className="form-control mb-2" 
                                placeholder={props.currentValue} 
                                name={'data_final'}
                                onChange={onChangeValueDate}
                                />
                                
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{width:'100%'}}>
                            
                        <Col>
                            <Button variant={loading.style} onClick={onSaveInfo} disabled={loading.style !== 'primary' ? true : false}>
                                {
                                    loading.isLoading ?
                                    <Loading msg={loading.msg} />:
                                    loading.msg
                                }
                            </Button>
                        </Col>
                        <Col>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            {error.flag ? <ModalError show={error.flag} setShow={setError} msg={"Periodo Não Informado Corretamente! "} title={'Erro na Requisição'} {...error} /> : ""}
        </>
    )
}
