import { useEffect, useRef, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { updateProdutosListDimensoes } from "../../redux/slicers/user"
import { updateTable, verificaTipoUsuario } from "../../services/Servicos"
import Loading from "../loading/Loading"

import './Modal.scss'


export default function ModalEditarDimensaoProdutosCargas({ msg, title, show, setShow, produtoEditado, setProduto = () => {} }) {

    const dispatch = useDispatch()
    const buttonSubmit = useRef()
    const [loading, setLoading] = useState({
        isLoading: false,
        msg: "Salvar",
        style: 'primary'
    })

    const user = useSelector(state => state.userSlice)

    const handlerSimpleClose = () => setShow(last => ({...last, flag: false, msg:""}))

    const confirmarEdicao = async () => {
        setLoading(last => ({ ...last, isLoading: loading, style: 'warning', msg: "Atualizando..." }))
        let url = verificaTipoUsuario(user) + 'cargas/gestao/produtos'
        const body = {
            [produtoEditado.dimensao]: Number((produtoEditado.value.new).replace(',','.')),
            qr_sku: produtoEditado.qr_sku,
            cargas_id: produtoEditado.cargas_id
        }
        if (produtoEditado.dimensao == 'volume') {
            url += '/volume'
        } 

        updateTable(url, body.cargas_id, body)
        dispatch(updateProdutosListDimensoes({ qr_sku: body.qr_sku, dimension: { name: produtoEditado.dimensao, value: body[produtoEditado.dimensao] } }))
        
        
        setTimeout(()=>{
            setLoading(last => ({...last, isLoading: false, style:'success', msg:"Atualizado"}))
            
            setTimeout(() => {
                setProduto({
                    pedido: 0,
                    qr_sku: 0, //
                    value: {
                        old: 0,
                        new:0
                    },
                    dimensao: '',
                    cargas_id: 0
                })
                handlerSimpleClose()
            },500)

        },1000)
    }
    
    useEffect(() => {
        buttonSubmit.current.focus()
    }, [])

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msg}
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{width:'100%'}}>
                        <Col>
                            <Button variant={loading.style} onClick={confirmarEdicao} disabled={loading.style !== 'primary' ? true : false} ref={buttonSubmit}>
                                {
                                    loading.isLoading ?
                                    <Loading msg={loading.msg} />:
                                    loading.msg
                                }
                                {loading.style === 'success' ? <i className="bi bi-check"></i>:""}
                            </Button>
                        </Col>
                        <Col>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}
