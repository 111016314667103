import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllData, getDateInit } from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Button, Card, Spinner } from "react-bootstrap";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";
import ModalErrorNew from "../../modal/ModalErrorNew";
import ModalErrorPretty from "../../modal/ModalErrorPretty";
import { getErrorMessage } from "../../../services/helpers";

export default function PedidosSemLogistica({
	dataParam,
	setDataParam = () => {},
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					return value;
				},
				offsetY: -20,
				style: {
					fontSize: "10px",
					colors: ["#304758"],
				},
			},
			stroke: {
				show: true,
				width: 1,
				colors: ["#fff"],
			},
			tooltip: {
				shared: true,
				intersect: false,
				style: {
					fontSize: "10px",
				},
				fixed: {
					enabled: true,
					position: "topLeft",
					offsetX: 50,
					offsetY: 30,
				},
			},
			xaxis: {
				labels: {
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: false,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							// return `${valorMoeda(value)}`;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});

	const [loading, setLoading] = useState(true);
	const [errorModal, setErrorModal] = useState({
		show: false,
		message: false,
	});

	const [periodoInicial, setPeriodoInicial] = useState(``);

	const getVSTAPI = async (data_inicial, data_final) => {
		setLoading(true);
		let filial = user.tipo === "empresa" ? "" : `?filiais=(${user.id_filial})`;

		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setPeriodoInicial(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);

		axios
			.get(
				`${process.env.REACT_APP_API_VST}/1/dtini/${data_inicial}/dtfim/${data_final}${filial}`,
				{ timeout: 60000 }
			)
			// getAllData('vst/get-pedidos', { data_inicial, data_final, filial })
			.then((resp) => {
				const vendas = resp.data.Vendas; //.filter(item => item.clienteRetira == 'NAO')

				if (!vendas) {
					setOptions((last) => ({
						...last,
						series: [],
					}));
					return;
				}

				if (user.tipo !== "empresa" && user.id_filial != 2) {
					const filiais = [...user.filiaisList.data].filter(
						(item) => item.idFilial == user.id_filial
					);

					const resultadosIndividuais = filiais
						.map((item) => {
							return {
								filial: item.identificacaoInterna.replace("FERMAT - ", ""),
								PedidosSemLogistica: vendas.filter(
									(item2) =>
										item2.idFilial == item.idFilial &&
										Number(item2.stLogistica) == -1
								).length,
								pedidosComLogistica: vendas.filter(
									(item2) =>
										item2.idFilial == item.idFilial &&
										Number(item2.stLogistica) >= 0 &&
										Number(item2.stLogistica) < 13
								).length,
								pedidosEntregue: vendas.filter(
									(item2) =>
										item2.idFilial == item.idFilial &&
										Number(item2.stLogistica) == 14
								).length,
							};
						})
						.sort((a, b) => {
							if (a.PedidosSemLogistica > b.PedidosSemLogistica) return 1;
							if (a.PedidosSemLogistica < b.PedidosSemLogistica) return -1;
							return 0;
						});
					// .filter(item => item.PedidosSemLogistica || item.pedidosComLogistica || item.pedidosEntregue)

					setOptions((last) => ({
						...last,
						options: {
							...last.options,
							xaxis: {
								...last.options.xaxis,
								categories: resultadosIndividuais.map((item) => item.filial),
							},

							title: {
								text: `
                                    Total de Pedidos: ${vendas.length}
                                    , Em Processamento: ${
																			vendas.filter(
																				(item) =>
																					item.stLogistica >= 0 &&
																					item.stLogistica < 13
																			).length
																		}
                                    , Entregue: ${
																			vendas.filter(
																				(item) => item.stLogistica >= 13
																			).length
																		}
                                `,
							},
							// title:
						},
						series: [
							{
								name: "Pedidos sem Logística",
								data: resultadosIndividuais.map(
									(item) => item.PedidosSemLogistica
								),
							},
							{
								name: "Pedidos com Logística",
								data: resultadosIndividuais.map(
									(item) => item.pedidosComLogistica
								),
							},
							{
								name: "Pedidos Entregues",
								data: resultadosIndividuais.map((item) => item.pedidosEntregue),
							},
						],
					}));
				} else {
					const filiais = [...user.filiaisList.data].sort((a, b) => {
						if (a.idFilial > b.idFilial) return 1;
						if (a.idFilial < b.idFilial) return -1;
						return 0;
					});

					const resultadosIndividuais = filiais
						.map((item) => {
							return {
								filial: item.identificacaoInterna.replace("FERMAT - ", ""),
								PedidosSemLogistica: vendas.filter(
									(item2) =>
										item2.idFilial == item.idFilial &&
										Number(item2.stLogistica) == -1
								).length,
								pedidosComLogistica: vendas.filter(
									(item2) =>
										item2.idFilial == item.idFilial &&
										Number(item2.stLogistica) >= 0 &&
										Number(item2.stLogistica) < 13
								).length,
								pedidosEntregue: vendas.filter(
									(item2) =>
										item2.idFilial == item.idFilial &&
										Number(item2.stLogistica) >= 13
								).length,
							};
						})
						.sort((a, b) => {
							if (a.PedidosSemLogistica > b.PedidosSemLogistica) return 1;
							if (a.PedidosSemLogistica < b.PedidosSemLogistica) return -1;
							return 0;
						})
						.filter(
							(item) =>
								item.PedidosSemLogistica ||
								item.pedidosComLogistica ||
								item.pedidosEntregue
						);

					setOptions((last) => ({
						...last,
						options: {
							...last.options,
							xaxis: {
								...last.options.xaxis,
								categories: resultadosIndividuais.map((item) => item.filial),
							},

							title: {
								text: `
                                    Total de Pedidos: ${vendas.length}
                                    , Em Processamento: ${
																			vendas.filter(
																				(item) =>
																					item.stLogistica >= 0 &&
																					item.stLogistica < 13
																			).length
																		}
                                    , Entregue: ${
																			vendas.filter(
																				(item) => item.stLogistica >= 13
																			).length
																		}
                                `,
							},
							// title:
						},
						series: [
							{
								name: "Pedidos sem Logística",
								data: resultadosIndividuais.map(
									(item) => item.PedidosSemLogistica
								),
							},
							{
								name: "Pedidos com Logística",
								data: resultadosIndividuais.map(
									(item) => item.pedidosComLogistica
								),
							},
							{
								name: "Pedidos Entregues",
								data: resultadosIndividuais.map((item) => item.pedidosEntregue),
							},
						],
					}));
				}
			})
			.catch((error) => {
				setErrorModal({
					show: true,
					message: "API do VST fora do ar! Aguarde um instante"
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!user.filiaisList.data) return;
		const datas = getDateInit(new Date());
		getVSTAPI(datas.data_inicial, datas.data_final);
	}, [user.filiaisList.data]);

	useEffect(() => {
		if (!dataParam?.data_final && !dataParam?.data_inicial) return;
		setLoading(true);
		getVSTAPI(dataParam?.data_inicial, dataParam?.data_final);
	}, [dataParam]);

	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>
					Pedidos sem Logística
				</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				<div style={{ width: "max-content", marginRight: "auto" }}>
					{errorModal.error ? (
						<Button
							variant="danger"
							onClick={() => {
								setErrorModal({
									show: true,
									message: "API do VST fora do ar! Aguarde um instante"
								});
							}}
						>
							{" "}
							Ver Erro
						</Button>
					) : (
						""
					)}
				</div>
				{/* <span className="filter-data" onClick={() => setDataParam(last => ({ ...last, show: true }))}>
                    
                <span className="filho" >{periodoInicial}</span>
                    Filtrar Data 
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							height={500}
						/>
					</div>
				)}
			</Card.Body>
			{/* {dataParam.show ? <ModalAtualizarDataEntradaVST title={'Perido do Gráfico'} show={dataParam.show} setShow={setDataParam} {...dataParam} setDataParam={setDataParam}
                fn={getVSTAPI}
            /> : ""} */}
			{errorModal.show ? (
				<ModalErrorPretty
					setShow={setErrorModal}
					{...errorModal}
				/>
			) : (
				""
			)}
		</Card>
	);
}
