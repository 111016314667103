import { Fragment, useRef, useState } from "react"
import ModalAtualizarCaminhoesCaminhoneiros from "../../modal/ModalAtualizarCaminhoesCaminhoneiros"
import ModalError from "../../modal/ModalError"

import './BodyRow.scss'

export default function BodyRowCaminhoesCaminhoneiros({ headList, bodyList, index }) {

    const refRow = useRef(null)
    const refExcluir = useRef(null)

    const [adminFunction, setAdminFunction] = useState({
        error: {
            flag: false,
            title: '',
            msg: '',
            confirmar: ''
        }
    })


    const [error, setError] = useState({
        flag: false,
        title: '',
        msg: '',
    })

    const onClickExcluirRow = (e) => {
        e.preventDefault()

        let ref1 = null, ref2 = null, ref4 = null, ref5 = null

        ref1 = refRow.current.children[3].innerText //tipo
        ref2 = refRow.current.children[4].innerText //placa
        ref4 = refRow.current.children[6].innerText //nome_caminhoneiro
        ref5 = refRow.current.children[7].innerText //cpf_caminhoneiro

        setAdminFunction({
            error: {
                flag: true,
                title: 'Atenção',
                msg: {
                    title: `Deseja excluir a atribuição do caminhão ?`,
                    msg: {
                        TIPO: ref1,
                        PLACA: ref2,
                        CAMINHONEIRO: ref4,
                        CPF: ref5,
                    },
                },
                confirmar: 'Deletar Atribuição'
            }
        })
    }

    return (
        <Fragment>
            {
                <tr ref={refRow}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            } else if (item === 'admin') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span className="excluir"><i className="bi bi-x-lg" title="Excluir Atribuição" onClick={onClickExcluirRow} ref={refExcluir}></i></span>
                                        {/* <span className="editar"><i className="bi bi-pencil" title="Editar" onClick={onClickEditarRow} ref={refEditar}></i></span> */}
                                    </div>
                                </td>
                            } else if (item === 'id') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            } else if (item === 'placa') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            } else if (item === 'status') {

                                return bodyList[item] ?
                                    <td key={_index} className={item}>
                                        <div className="div-status">
                                            <span className="div-status-ativo">Ativo</span>
                                        </div>
                                    </td> : <td key={_index} className={item}>
                                        <div className="div-status">
                                            <span className="div-status-inativo">Inativo</span>
                                        </div>
                                    </td>

                            } else if (item === 'data_nascimento') {

                                return bodyList[item] ? <td key={_index} className={item}> {new Date(bodyList[item]).toLocaleDateString()}</td> : <td key={_index} className={item}></td>
                            } 
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            {adminFunction.error.flag ? <ModalAtualizarCaminhoesCaminhoneiros title={adminFunction.error.title} msg={adminFunction.error.msg} show={adminFunction} setShow={setAdminFunction} msgButton={adminFunction.error.confirmar} /> : ""}
            {error.flag ? <ModalError show={error.flag} setShow={setError} msg={error.msg} title={error.title}/> : ""}
        </Fragment>
    )
}