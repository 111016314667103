import { Fragment } from "react";
import './NotFound404.scss'
import { Button, Col, Container, Row } from "react-bootstrap";

export default function NotFound404(){
    return(
        <Fragment>
            <Container className='container-404'>
                <Row className="text-center justify-content-center align-items-center">
                    <Col sm={12}>
                        <h1>Opss...</h1>
                        <h2>Page não Encontrada</h2>
                        <Button variant='primary' onClick={() => document.location.replace('/dashboard')}>Página Inicial</Button>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}