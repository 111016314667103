import { useEffect, useRef, useState } from "react";
import CardLayoutNavbar from "../card/CardLayoutNavbar";
import {Row, Col, Button, Spinner, Modal, Form} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import ModalLancamentosAjustesPontos from "../modal/ModalLancamentosAjustesPontos";
import { ajustePontosEmpresa, getAllData, getDateInitMonth, updateTable } from "../../services/Servicos";
import ModalError from "../modal/ModalError";
import { updatePontosList } from "../../redux/slicers/user";
import ModalLancamentosPontos from "./ModalLancamentosPontos";

const diasSemanas = ['DOMINGO', 'SEGUNDA-FEIRA', 'TERÇA-FEIRA', 'QUARTA-FEIRA', 'QUINTA-FEIRA', 'SEXT-FEIRA', 'SÁBADO']
const meses = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO']

export default function RelatorioPontosGerentes() {
    const user = useSelector(state => state.userSlice)
    const pontos_entrada = useSelector(state => state.userSlice.pontosList)
    const dispatch = useDispatch()
    const [pontos, setPontos] = useState([...pontos_entrada.data])
    const [pontosFiltrados, setPontosFiltrados] = useState([])
    const [loading, setLoading] = useState(false)
    const [dias, setDias] = useState([])
    const [mes, setMes] = useState()
    const [ano, setAno] = useState()
    const [cpf, setCpf] = useState(null)
    const [hiddenConfigs, setHiddenConfigs] = useState(true)
    const [modaisControle, setModaisControle] = useState({
        flag: false
    })
    const [modalFalta, setModalFalta] = useState({
        flag: false
    })
    const [modalFeriado, setModalFeriado] = useState({
        flag: false
    })
    const [modalFolga, setModalFolga] = useState({
        flag: false
    })
    const [modalFiltrarCpf, setModalFiltrarCpf] = useState({
        flag: false
    })
    const [verLancamentos, setVerLancamentos] = useState({
        flag: false,
        dia: '',
        mes: '',
        ano: '',
    })


    // const updateArrayPontos = async () => {
        
    //     const date = new Date()
    //     const dateCorrect = getDateInitMonth(date)
    //     const pontosL = await getAllData('empresa/controle-ponto', { data_inicial: dateCorrect.data_inicial, data_final: dateCorrect.data_final })
    //     setPontos(pontosL.data)
    // } 
    

    const updateArrayPontosFromArrow = async (data = new Date()) => {
        setLoading(true)
        const mesAtual = data.getMonth() + 1
        const anoAtual = data.getFullYear()

        const ultimoDiaDoMes = new Date(anoAtual, mesAtual, 0)

        const pontosL = await getAllData('empresa/controle-ponto', { cpf, data_inicial: `${anoAtual}-${mesAtual}-01`, data_final: `${anoAtual}-${mesAtual}-${ultimoDiaDoMes.getDate()}` })
        const pontos = pontosL.data
        setPontos(pontosL.data)

        setDias([])
        setMes(mesAtual)
        setAno(anoAtual)

        for (let i = 1; i < ultimoDiaDoMes.getDate() + 1; i++){
            setDias(last => [...last,
                {
                    dia: i,
                    mes: mesAtual,
                    ano: anoAtual,
                    dia_semana: new Date(anoAtual, mesAtual - 1, i).getDay(),
                    dia_semana_long: diasSemanas[new Date(anoAtual, mesAtual - 1, i).getDay()],
                    qtde_pontos: pontos.filter(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && !item.falta && !item.feriado && !item.folga).length,
                    ajustes: pontos.find(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.ajuste) ? true : false,
                    faltas: pontos.find(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.falta) ? true : false,
                    hora_extra: false,
                    feriado: pontos.find(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.feriado) ? true : false,
                    folga: pontos.find(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.folga) ? true : false,
                    pontos: pontos.filter(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano),

                }])
        }

        setLoading(false)
    } 


    const updateInfos = async (data = new Date(), firstRender = false) => {
       

        const mesAtual = data.getMonth() + 1
        const anoAtual = data.getFullYear()

        const ultimoDiaDoMes = new Date(anoAtual, mesAtual, 0)
        
        setDias([])
        setMes(mesAtual)
        setAno(anoAtual)

        
        for (let i = 1; i < ultimoDiaDoMes.getDate() + 1; i++){
            setDias(last => [...last,
                {
                    dia: i,
                    mes: mesAtual,
                    ano: anoAtual,
                    dia_semana: new Date(anoAtual, mesAtual - 1, i).getDay(),
                    dia_semana_long: diasSemanas[new Date(anoAtual, mesAtual - 1, i).getDay()],
                    // qtde_pontos: pontos.filter(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && !item.falta && !item.feriado && !item.folga).length,
                    // ajustes: pontos.find(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.ajuste) ? true : false,
                    // faltas: pontos.find(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.falta) ? true : false,
                    // hora_extra: false,
                    // feriado: pontos.find(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.feriado) ? true : false,
                    // folga: pontos.find(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano && item.folga) ? true : false,
                    // pontos: pontos.filter(item => item.mes == mesAtual && item.dia == i && anoAtual == item.ano),

                }])
        }

        setLoading(false)
    }

    useEffect(() => {
        updateInfos(new Date(), true)
    }, [])

    
    
    useEffect(() => {
        updateArrayPontosFromArrow(new Date())
    },[cpf])
    
    return (
        <CardLayoutNavbar>
            <div className='card-calendario' style={{ overflow: 'auto' }}>
                <div style={{width: 'max-content', padding:'10px'}}>
                    <div className="text-center align-items-center row-calendario">
                        <Col sm='2' className="">
                            <div className="d-flex">
                                <button variant='primary' className="button-calendar" onClick={() => updateArrayPontosFromArrow(new Date(ano, mes - 2, 1))}>
                                    <i className="bi bi-arrow-left"></i>
                                </button>   
                                <button variant='primary' className="button-calendar" onClick={() => updateArrayPontosFromArrow(new Date(ano, mes, 1))}>
                                    <i className="bi bi-arrow-right"></i>
                                </button>   
                            </div>
                        </Col>
                        <Col sm='8'>
                            <h3 className="d-flex align-items-center justify-content-center m-0">
                                <span style={{fontSize:'1.5rem'}}>
                                    {`${meses[mes - 1]} - ${ano}`}
                                </span>
                                <i>
                                    {cpf ? `- ${user.funcionariosList.data.find(item => item.cpf === cpf).name}` : ""}
                                </i>
                                {
                                    loading ? <span className="ml-2"><Spinner animation='border' size='sm'/> </span> : ""}
                            </h3>
                            

                        </Col>
                        <Col sm='2'>
                            <div className="d-flex  justify-content-end">
                                <button variant='primary' className="button-calendar-config" style={{ backgroundColor: '#6c7379' }}
                                    onMouseOver={() => setHiddenConfigs(false)}
                                    onMouseLeave={() => setHiddenConfigs(true)}
                                >
                                    <i className="bi bi-gear" style={{ color: 'white' }}></i>
                                    <div className="configs" hidden={hiddenConfigs}>
                                        <div onClick={() => setModaisControle({flag: true, tilte:'Ajustar Ponto'})}>Ajustar Ponto</div>
                                        <div onClick={() => setModalFolga({flag: true, tilte:'Lançar Folga'})}>Lançar Folga</div>
                                        <div onClick={() => setModalFalta({flag: true, tilte:'Lançar Falta'})}>Lançar Falta</div>
                                        <div onClick={() => setModalFeriado({flag: true, tilte:'Lançar Falta'})}>Lançar Feriado</div>
                                        <div onClick={() => setModalFiltrarCpf({flag: true, tilte:'Filtrar Cpf'})}>Filtrar</div>
                                    </div>
                                </button>   
                            </div>
                        </Col>
                        <Col sm='2'></Col>
                    </div>
                    <section className="calendario">
                        {diasSemanas.map((i, k) => <div key={k} className="dias-semana">{i}</div>)}
                        

                        {
                            dias.map((i, k) => {
                                if (k < dias[0].dia_semana) {
                                    const ultimoDiaMesAnterior = new Date(i.ano, i.mes - 1, 0).getDate()
                                    return <div key={k} className="dias-anteriores">{ ultimoDiaMesAnterior - dias[0].dia_semana  + k + 1 }</div>
                                }
                            })
                        }
                        {dias.map((i, k) => {
                            const date = new Date()
                            let backgroundColor = date.getMonth() == i.mes - 1 && date.getDate() == i.dia && date.getFullYear() == i.ano ? '#FFFADF' : ''
                            backgroundColor = i.ajustado ? '#0dd4e8' : backgroundColor
                            // ( date.getMonth() + 1 >= i.mes ? (  ? true :  false) : false)
                            let disabled = date.getFullYear() >= i.ano ? (i.mes < date.getMonth() + 1 ? false : (date.getDate() > i.dia ? false : true) ) : true
                            return <div key={k} className="dias" style={{ backgroundColor }}
                                onClick={() => { 
                                    // if (disabled) return
                                    setVerLancamentos({
                                        flag: true,
                                        dia: i.dia,
                                        mes: i.mes,
                                        ano: i.ano,
                                        dia_semana_long: i.dia_semana_long,
                                        pontos: i.pontos,
                                        ajustado: i.ajustado || i.mes != new Date().getMonth() + 1
                                    })
                                }}>
                                <div className="card-local justify-content-start align-items-start" style={{padding:'25px 0px 0px 7px'}}>
                                    <div className="dia-atual">{i.dia}</div>
                                    
                                    {
                                        !disabled && i.qtde_pontos ? 
                                            <div className="d-flex align-items-center ">
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'black' }}></div>
                                                <div style={{ fontSize: '0.9rem', marginLeft: '3px' }}>{i.qtde_pontos}/{user.funcionariosList.data.length * 4}</div>
                                            </div>
                                            : ""
                                    }
                                    
                                        {i.ajustes ? 
                                            <div className="d-flex align-items-center ">
                                                <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'blue' }}></div>
                                                <div style={{fontSize:'0.9rem', marginLeft:'3px'}}>Ajustes</div>
                                            </div>
                                            : ""}
                                        
                                        {i.faltas ? 
                                            <div className="d-flex align-items-center ">
                                                <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red' }}></div>
                                                <div style={{fontSize:'0.9rem', marginLeft:'3px'}}>Falta</div>
                                            </div>
                                        : ""}
                                    
                                        {i.feriado ? 
                                            <div className="d-flex align-items-center ">
                                                <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#a3a307' }}></div>
                                                <div style={{fontSize:'0.9rem', marginLeft:'3px'}}>Feriado</div>
                                            </div>
                                        : ""}
                                    
                                        {i.folga ? 
                                            <div className="d-flex align-items-center ">
                                            <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#fff200a6' }}></div>
                                            <div style={{fontSize:'0.9rem', marginLeft:'3px'}}>Folga</div>
                                        </div>
                                        : ""}
                                            
                                    {/* <div className="total-horas-trabalhadas">
                                        {segundosParaHoras(i.totalSegundosTrabalhados)}
                                    </div> */}
                                    <div className="text-muted" style={{fontSize:'0.7rem'}}>
                                        {i.ajustado ? 'AJUSTE' : ''}
                                    </div>
                                </div>
                            </div>
                        })}
                        {
                            dias.length && new Array(6 - dias[dias.length - 1].dia_semana).fill('').map((i, k) => {
                                return <div key={k} className="dias-anteriores">{k + 1}</div>
                            })
                        }
                        
                    </section>
                </div>
            </div>
            {verLancamentos.flag ? <ModalLancamentosPontos setShow={setVerLancamentos} show={verLancamentos.flag} {...verLancamentos} cpf={ user.cpf } /> : ''}
            {modaisControle.flag ? <Ajustar setShow_={setModaisControle} show={modaisControle.flag} {...modaisControle} updateArrayPontos={updateArrayPontosFromArrow}  /> : ''}
            {modalFalta.flag ? <Falta setShow_={setModalFalta} show={modalFalta.flag}  title={'Falta'} {...modalFalta} updateArrayPontos={updateArrayPontosFromArrow} /> : ''}
            {modalFeriado.flag ? <Feriado setShow_={setModalFeriado} show={modalFeriado.flag} title={'Feriado'} {...modalFeriado} updateArrayPontos={updateArrayPontosFromArrow} /> : ''}
            {modalFolga.flag ? <Folga setShow_={setModalFolga} show={modalFolga.flag} title={'Folga'} {...modalFolga} updateArrayPontos={updateArrayPontosFromArrow} /> : ''}
            {modalFiltrarCpf.flag ? <FiltrarCpf setShow_={setModalFiltrarCpf} show={modalFiltrarCpf.flag} title={'Folga'} {...modalFiltrarCpf} setCpf={setCpf} /> : ''}
        </CardLayoutNavbar>
    )
}


function Ajustar(props) {
    
    const user = useSelector(state => state.userSlice)
    const funcionariosList = user.funcionariosList.data
    const handlerSimpleClose = () => props.setShow_(last => ({ flag: false }))
    const ent_sai_ref = useRef()
    const hora_ref = useRef()
    const minuto_ref = useRef()
    const segundo_ref = useRef()

    const [bntLoading, setBtnLoading] = useState({
        flag: false,
        style: 'primary',
        msg:'Salvar'
    })
    const [error, setError] = useState({
        flag: false
    })

    
    const [pontos, setPontos] = useState([]) 

    const [addPonto, setAddPonto] = useState({})
    
    const handlerAddPonto = () => { 
        
        if(!addPonto.data) return setError({flag: true, msg:'Especifique a Data do Ajuste'})
        if(!addPonto.ent_sai) return setError({flag: true, msg:'Especifique o Lancamento do Ponto'})
        if(!addPonto.hora) return setError({flag: true, msg:'Selecione as Horas'})
        if(!addPonto.minuto) return setError({flag: true, msg:'Selecione as Minutos'})
        
        const [ano, mes, dia] = addPonto.data.split('-')
        const dataSelecionada = new Date(ano, mes - 1, dia)

        if(!String(ano).length > 4) return setError({flag: true, msg:'A quantidade de ANOS selecionado excede o limite'})
        setPontos(last => [...last, {
            ...addPonto,
            ano,
            mes,
            dia,
            dia_semana: diasSemanas[dataSelecionada.getDay()],
            ponto_data: `${String(dia).padStart(2, 0)}/${String(mes).padStart(2, 0)}/${ano}`,
            ponto_hora: `${addPonto.hora}:${addPonto.minuto}`,
        }])

        setAddPonto(last => ({ cpf: last.cpf, data: last.data }))

        ent_sai_ref.current.value = ''
        hora_ref.current.value = ''
        minuto_ref.current.value = ''
        // segundo_ref.current.value = ''
    }

    const onChangeValues = (e) => {
        setAddPonto(last => ({...last, [e.target.name]: e.target.value}))
    }
    
    const removerPonto = (id) => {
        setPontos(last => last.filter((_, index) => index !== id))
    }
    const handlerSalvarAjuste = async () => {

        if (pontos.filter(item => item.ent_sai == 'ENTRADA').length < 1) {
            return setError({
                flag: true, msg:'Os pontos informados não obedecem o padrao minimo de entradas'
            })
        }
        if (pontos.filter(item => item.ent_sai == 'SAIDA').length < 1) {
            return setError({
                flag: true, msg:'Os pontos informados não obedecem o padrao minimo de SAÍDAS'
            })
        }
        setBtnLoading({
            flag: true,
            msg:'Atualizando',
            style:'warning'
        })
        const obj = {
            where: {
                cpf: pontos[0].cpf,
                dia: pontos[0].dia,
                mes: pontos[0].mes,
                ano: pontos[0].ano,
                data_cancelamento: null
            },
            update: {
                data_cancelamento: new Date().toISOString()
            }

        }

        await updateTable('empresa/controle-ponto', '', obj)
        setBtnLoading({
            flag: true,
            msg:'Salvando',
            style:'warning'
        })

        
        let funcionario = funcionariosList.find(item => item.cpf == pontos[0].cpf)
        let body = {
            nome: funcionario.name,
            cpf: funcionario.cpf,
            data_nascimento: new Date(funcionario.data_nascimento).toLocaleDateString('pt-br',{timeZone:'UTC'}),
            tipo: funcionario.tipo,
            dia: pontos[0].dia,
            mes: pontos[0].mes,
            ano: pontos[0].ano,
            dia_semana: pontos[0].dia_semana,
            ajuste: true
        }

        for await (let ponto of pontos) {
            await ajustePontosEmpresa(
                {
                    ...body,
                    ...ponto,
                    ponto_hora: `${ponto.hora}:${ponto.minuto}:${ponto.segundo || '00'}`,
                    segundo: ponto.segundo ?? 0,
                    ponto: new Date(body.ano, body.mes - 1, body.dia, ponto.hora, ponto.minuto, ponto.segundo || 0).toISOString()
                }
            )
            
        }

        setBtnLoading({
            flag: false,
            msg:'Salvo',
            style:'success'
        })

        await props.updateArrayPontos()
        setTimeout(() => {
            setBtnLoading({
                flag: false,
                msg:'Salvo',
                style:'success'
            })
            handlerSimpleClose()
        }, 500)
    }
    
    return (
        <Modal
            show={props.show}
            onHide={handlerSimpleClose}
            backdrop='static'
            keyboard={false}
            centered
            size='md'
        >
            <Modal.Header>
                <Modal.Title>Ajuste de Calendário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row onSubmit={handlerAddPonto}>
                        <Col sm='12' className='mb-4'>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Data</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control type="date" onChange={onChangeValues} name='data'/>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Funcionário</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Select type="text" name='cpf'  onChange={onChangeValues}  ref={ ent_sai_ref} >
                                        <option value=''></option>
                                        {[...user.funcionariosList.data]
                                            .sort((a, b) => {
                                                if(a.tipo > b.tipo) return 1
                                                if (a.tipo < b.tipo) return -1
                                                return 0
                                            })
                                            .map(item => <option value={item.cpf}>{item.tipo} - {item.name.slice(0, 10)}... - {item.cpf} </option>)}
                                    </Form.Select> 
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Lançamento</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Select type="text" name='ent_sai'  onChange={onChangeValues}  ref={ ent_sai_ref} >
                                        <option value=''></option>
                                        <option value='ENTRADA' >ENTRADA</option>
                                        <option value='SAIDA'>SAÍDA</option>
                                    </Form.Select> 
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Horário:</Form.Label>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label>Horas:</Form.Label>
                                            <Form.Select type="text" name='hora' onChange={onChangeValues}  ref={ hora_ref} >
                                                <option value=''></option>
                                                {new Array(24).fill('').map((i, k) => <option key={k} value={String(k).padStart(2, 0)}>{ String(k).padStart(2, 0) }</option>)}        
                                            </Form.Select>         
                                        </Col>
                                        <Col>
                                            <Form.Label>Minutos:</Form.Label>
                                            <Form.Select type="text" name='minuto' onChange={onChangeValues} ref={ minuto_ref}>
                                                <option value=''></option>
                                                {new Array(60).fill('').map((i, k) => <option key={k} value={String(k).padStart(2, 0)}>{ String(k).padStart(2, 0) }</option>)}        
                                            </Form.Select>             
                                        </Col>
                                        <Col>
                                            <Form.Label style={{color:'transparent'}}>:</Form.Label>
                                            <Button variant='info' onClick={handlerAddPonto}>+Add</Button>
                                            {/* <Col sm='4'>
                                            </Col> */}
                                            {/* <Form.Select type="text" name='segundo' onChange={onChangeValues} ref={ segundo_ref}>
                                                <option value=''></option>
                                                {new Array(60).fill('').map((i, k) => <option key={k} value={String(k).padStart(2, 0)}>{ String(k).padStart(2, 0) }</option>)}        
                                            </Form.Select>             */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                                    
                        </Col>    
                    </Row>
                    <div className="card-header mt-3">
                        <h6>Resumo do Ajuste</h6>
                    </div>
                    <Row className="">
                            {pontos.map((item, key) => 
                                <div className="d-flex justify-content-center text-center" key={ key }>
                                    <div style={{ margin: '0 10px 10px 0', padding: '1px 4px', border: 'solid 0.1px #a30c0c66', boxShadow:'0 0 3px #a30c0c66', backgroundColor:'red', cursor:'pointer' }} onClick={() => removerPonto(key)}>
                                        <i className="bi bi-x-lg" style={{color:'white'}}></i>
                                    </div>
                                    <Col sm='3' >
                                        <div style={{margin:'0 10px 10px 0', padding:'2px 7px', border:'solid 0.1px #a30c0c66', boxShadow:'0 0 3px #a30c0c66'}}>{item.ent_sai}</div>
                                    </Col>
                                    <Col sm='3'>
                                        <div style={{margin:'0 10px 10px 0', padding:'2px 7px', border:'solid 0.1px #a30c0c66', boxShadow:'0 0 3px #a30c0c66'}}>{item.ponto_data}</div>
                                    </Col>
                                    <Col sm='3'>
                                        <div  style={{margin:'0 10px 10px 0', padding:'2px  7px', border:'solid 0.1px #a30c0c66', boxShadow:'0 0 3px #a30c0c66'}}>{item.ponto_hora}</div>
                                    </Col>
                                </div>
                            )}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-block">
                <Row className="justify-content-end">
                    {/* <Col sm='4'>
                        <Button variant='info' onClick={handlerAddPonto}>Adicionar</Button>
                    </Col> */}
                    <Col sm='4'>
                        <Button variant={bntLoading.style} onClick={handlerSalvarAjuste} disabled={ bntLoading.style == 'primary' ? false : true}>
                            {bntLoading.style == 'success' ? <i className="bi bi-check"></i> :"" }
                            {bntLoading.flag ? <Spinner animation='border' size="sm"/> :"" }
                            {bntLoading.msg}
                        </Button>
                    </Col>
                    <Col sm='4'>
                        <Button variant='secondary' onClick={handlerSimpleClose} >Cancelar</Button>
                    </Col>
                </Row>
            </Modal.Footer>
            {error.flag ? <ModalError show={error.flag} setShow={setError} title='Aviso' msg={error.msg} /> : ""} 
        </Modal>
        
        
    )
}
function Falta(props) {
    
    const user = useSelector(state => state.userSlice)
    const funcionariosList = user.funcionariosList.data
    const handlerSimpleClose = () => props.setShow_(last => ({ flag: false }))
    const ent_sai_ref = useRef()
    const hora_ref = useRef()
    const minuto_ref = useRef()
    const segundo_ref = useRef()

    const [bntLoading, setBtnLoading] = useState({
        flag: false,
        style: 'primary',
        msg:'Salvar'
    })
    const [error, setError] = useState({
        flag: false
    })

    
    const [addPonto, setAddPonto] = useState({
        falta: true
    })
    
   

    const onChangeValues = (e) => {
        
        if (e.target.name === 'data') {
            if(!e.target.value) return
            const [ano, mes, dia] = e.target.value.split('-')
            
            const dataSelecionada = new Date(ano, mes - 1, dia)
            
            setAddPonto(last => ({
                ...last,
                ano,
                mes,
                dia,
                dia_semana: diasSemanas[dataSelecionada.getDay()],
                ponto_data: dataSelecionada.toLocaleDateString('pt-BR'),
                ponto: dataSelecionada.toISOString()
            }))
            return
        }
        
        setAddPonto(last => ({...last, [e.target.name]: e.target.value}))
    }
    
    const handlerSalvarAjuste = async () => {
        
        
        if (!addPonto.ponto) return setError({ flag: true, msg: 'Selecione uma Data' })
        if (!addPonto.cpf) return setError({flag: true, msg:'Selecione um Usuário'})
        
        setBtnLoading({
            flag: true,
            msg:'Atualizando',
            style:'warning'
        })
        const obj = {
            where: {
                cpf: addPonto.cpf,
                dia: addPonto.dia,
                mes: addPonto.mes,
                ano: addPonto.ano,
                data_cancelamento: null
            },
            update: {
                data_cancelamento: new Date().toISOString()
            }

        }
        
        let funcionario = funcionariosList.find(item => item.cpf == addPonto.cpf)
        
        let body = {
            nome: funcionario.name,
            data_nascimento: new Date(user.data_nascimento).toLocaleDateString('pt-br',{timeZone:'UTC'}),
            tipo: funcionario.tipo,
            ...addPonto
        }

        await updateTable('empresa/controle-ponto', '', obj)
        
        await ajustePontosEmpresa(body)

        await props.updateArrayPontos()
        
        setTimeout(() => {
            setBtnLoading({
                flag: false,
                msg:'Salvo',
                style:'success'
            })
            handlerSimpleClose()
        }, 500)
    }
    
    return (
        <Modal
            show={props.show}
            onHide={handlerSimpleClose}
            backdrop='static'
            keyboard={false}
            centered
            size='md'
        >
            <Modal.Header>
                <Modal.Title>Ajuste de Calendário  - { props.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col sm='12' className='mb-4'>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Data</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control type="date" onChange={onChangeValues} name='data' max='2099-12-31' />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Funcionário</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Select type="text" name='cpf'  onChange={onChangeValues}  ref={ ent_sai_ref} >
                                        <option value=''></option>
                                        {[...user.funcionariosList.data]
                                            .sort((a, b) => {
                                                if(a.tipo > b.tipo) return 1
                                                if (a.tipo < b.tipo) return -1
                                                return 0
                                            })
                                            .map(item => <option value={item.cpf}>{item.tipo} - {item.name.slice(0, 10)}... - {item.cpf} </option>)}
                                    </Form.Select> 
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Lançamento</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Select type="text" disabled >
                                        <option value='FALTA'>FALTA</option>
                                    </Form.Select> 
                                </Col>
                            </Row>                                    
                        </Col>    
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-block">
                <Row className="justify-content-end">
                    {/* <Col sm='4'>
                        <Button variant='info' onClick={handlerAddPonto}>Adicionar</Button>
                    </Col> */}
                    <Col sm='4'>
                        <Button variant={bntLoading.style} onClick={handlerSalvarAjuste} disabled={ bntLoading.style == 'primary' ? false : true}>
                            {bntLoading.style == 'success' ? <i className="bi bi-check"></i> :"" }
                            {bntLoading.flag ? <Spinner animation='border' size="sm"/> :"" }
                            {bntLoading.msg}
                        </Button>
                    </Col>
                    <Col sm='4'>
                        <Button variant='secondary' onClick={handlerSimpleClose} >Cancelar</Button>
                    </Col>
                </Row>
            </Modal.Footer>
            {error.flag ? <ModalError show={error.flag} setShow={setError} title='Aviso' msg={error.msg} /> : ""} 
        </Modal>
        
        
    )
}
function Feriado(props) {
    
    const user = useSelector(state => state.userSlice)
    const funcionariosList = user.funcionariosList.data
    const handlerSimpleClose = () => props.setShow_(last => ({ flag: false }))
    const ent_sai_ref = useRef()

    const [bntLoading, setBtnLoading] = useState({
        flag: false,
        style: 'primary',
        msg:'Salvar'
    })
    const [error, setError] = useState({
        flag: false
    })

    
    const [addPonto, setAddPonto] = useState({
        feriado: true
    })
    
   

    const onChangeValues = (e) => {
        if (e.target.name === 'data') {
            if(!e.target.value) return
            const [ano, mes, dia] = e.target.value.split('-')
            const dataSelecionada = new Date(ano, mes - 1, dia)
            setAddPonto(last => ({
                ...last,
                ano,
                mes,
                dia,
                dia_semana: diasSemanas[dataSelecionada.getDay()],
                ponto_data: dataSelecionada.toLocaleDateString('pt-BR'),
                ponto: dataSelecionada.toISOString()
            }))
            return
        }
        
        setAddPonto(last => ({...last, [e.target.name]: e.target.value}))
    }
    
    const handlerSalvarAjuste = async () => {
        
        
        if (!addPonto.ponto) return setError({ flag: true, msg: 'Selecione uma Data' })
        
        setBtnLoading({
            flag: true,
            msg:'Atualizando',
            style:'warning'
        })
        
        const funcionarioMatriz = funcionariosList.find(item => item.tipo.toLowerCase() == 'gestor')

        let body = {
            nome: 'EMPRESA',
            cpf: funcionarioMatriz.cpf,
            data_nascimento: user.data,
            tipo: funcionarioMatriz.tipo,
            funcionarios_id: funcionarioMatriz.id,
            ...addPonto
        }
        const process = await Promise.all([
            ajustePontosEmpresa(body)
        ])

        await props.updateArrayPontos()
        
        setTimeout(() => {
            setBtnLoading({
                flag: false,
                msg:'Salvo',
                style:'success'
            })
            handlerSimpleClose()
        }, 500)
    }
    
    return (
        <Modal
            show={props.show}
            onHide={handlerSimpleClose}
            backdrop='static'
            keyboard={false}
            centered
            size='md'
        >
            <Modal.Header>
                <Modal.Title>Ajuste de Calendário - { props.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col sm='12' className='mb-4'>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Data</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control type="date" onChange={onChangeValues} name='data'/>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Lançamento</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Select type="text" disabled >
                                        <option value='FALTA'>FERIADO</option>
                                    </Form.Select> 
                                </Col>
                            </Row>                                    
                        </Col>    
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-block">
                <Row className="justify-content-end">
                    {/* <Col sm='4'>
                        <Button variant='info' onClick={handlerAddPonto}>Adicionar</Button>
                    </Col> */}
                    <Col sm='4'>
                        <Button variant={bntLoading.style} onClick={handlerSalvarAjuste} disabled={ bntLoading.style == 'primary' ? false : true}>
                            {bntLoading.style == 'success' ? <i className="bi bi-check"></i> :"" }
                            {bntLoading.flag ? <Spinner animation='border' size="sm"/> :"" }
                            {bntLoading.msg}
                        </Button>
                    </Col>
                    <Col sm='4'>
                        <Button variant='secondary' onClick={handlerSimpleClose} >Cancelar</Button>
                    </Col>
                </Row>
            </Modal.Footer>
            {error.flag ? <ModalError show={error.flag} setShow={setError} title='Aviso' msg={error.msg} /> : ""} 
        </Modal>
        
        
    )
}
function Folga(props) {
    
    const user = useSelector(state => state.userSlice)
    const funcionariosList = user.funcionariosList.data
    const handlerSimpleClose = () => props.setShow_(last => ({ flag: false }))
    const ent_sai_ref = useRef()
    const hora_ref = useRef()
    const minuto_ref = useRef()
    const segundo_ref = useRef()

    const [bntLoading, setBtnLoading] = useState({
        flag: false,
        style: 'primary',
        msg:'Salvar'
    })
    const [error, setError] = useState({
        flag: false
    })

    
    const [addPonto, setAddPonto] = useState({
        folga: true
    })
    
   

    const onChangeValues = (e) => {
        if (e.target.name === 'data') {
            if(!e.target.value) return
            const [ano, mes, dia] = e.target.value.split('-')
            const dataSelecionada = new Date(ano, mes - 1, dia)
            setAddPonto(last => ({
                ...last,
                ano,
                mes,
                dia,
                dia_semana: diasSemanas[dataSelecionada.getDay()],
                ponto_data: dataSelecionada.toLocaleDateString('pt-BR'),
                ponto: dataSelecionada.toISOString()
            }))
            return
        }
        
        setAddPonto(last => ({...last, [e.target.name]: e.target.value}))
    }
    
    const handlerSalvarAjuste = async () => {
        
        
        if (!addPonto.ponto) return setError({ flag: true, msg: 'Selecione uma Data' })
        if (!addPonto.cpf) return setError({flag: true, msg:'Selecione um Usuário'})
        
        setBtnLoading({
            flag: true,
            msg:'Atualizando',
            style:'warning'
        })
        const obj = {
            where: {
                cpf: addPonto.cpf,
                dia: addPonto.dia,
                mes: addPonto.mes,
                ano: addPonto.ano,
                data_cancelamento: null
            },
            update: {
                data_cancelamento: new Date().toISOString()
            }

        }
        
        let funcionario = funcionariosList.find(item => item.cpf == addPonto.cpf)
        
        let body = {
            nome: funcionario.name,
            data_nascimento: new Date(user.data_nascimento).toLocaleDateString('pt-br',{timeZone:'UTC'}),
            tipo: funcionario.tipo,
            ...addPonto
        }

        await updateTable('empresa/controle-ponto', '', obj)
        await ajustePontosEmpresa(body)

        await props.updateArrayPontos()
        
        setTimeout(() => {
            setBtnLoading({
                flag: false,
                msg:'Salvo',
                style:'success'
            })
            handlerSimpleClose()
        }, 500)
    }
    
    return (
        <Modal
            show={props.show}
            onHide={handlerSimpleClose}
            backdrop='static'
            keyboard={false}
            centered
            size='md'
        >
            <Modal.Header>
                <Modal.Title>Ajuste de Calendário  - { props.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col sm='12' className='mb-4'>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Data</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control type="date" onChange={onChangeValues} name='data'/>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Funcionário</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Select type="text" name='cpf'  onChange={onChangeValues}  ref={ ent_sai_ref} >
                                        <option value=''></option>
                                        {[...user.funcionariosList.data]
                                            .sort((a, b) => {
                                                if(a.tipo > b.tipo) return 1
                                                if (a.tipo < b.tipo) return -1
                                                return 0
                                            })
                                            .map(item => <option value={item.cpf}>{item.tipo} - {item.name.slice(0, 10)}... - {item.cpf} </option>)}
                                    </Form.Select> 
                                </Col>
                            </Row>
                        </Col>    
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-block">
                <Row className="justify-content-end">
                    {/* <Col sm='4'>
                        <Button variant='info' onClick={handlerAddPonto}>Adicionar</Button>
                    </Col> */}
                    <Col sm='4'>
                        <Button variant={bntLoading.style} onClick={handlerSalvarAjuste} disabled={ bntLoading.style == 'primary' ? false : true}>
                            {bntLoading.style == 'success' ? <i className="bi bi-check"></i> :"" }
                            {bntLoading.flag ? <Spinner animation='border' size="sm"/> :"" }
                            {bntLoading.msg}
                        </Button>
                    </Col>
                    <Col sm='4'>
                        <Button variant='secondary' onClick={handlerSimpleClose} >Cancelar</Button>
                    </Col>
                </Row>
            </Modal.Footer>
            {error.flag ? <ModalError show={error.flag} setShow={setError} title='Aviso' msg={error.msg} /> : ""} 
        </Modal>
        
        
    )
}
function FiltrarCpf(props) {
    
    const user = useSelector(state => state.userSlice)
    const handlerSimpleClose = () => props.setShow_(last => ({ flag: false }))
    const cpf = useRef()

    const [error, setError] = useState({
        flag: false
    })

    
    
    const handlerSalvarAjuste = async () => {
        
        
        if (!cpf.current.value) {
            props.setCpf(null)
        }
        await props.setCpf(cpf.current.value)
        handlerSimpleClose()
    }
    
    return (
        <Modal
            show={props.show}
            onHide={handlerSimpleClose}
            backdrop='static'
            keyboard={false}
            centered
            size='md'
        >
            <Modal.Header>
                <Modal.Title>Ajuste de Calendário  - { props.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col sm='12' className='mb-4'>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Funcionário</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Select type="text" name='cpf' ref={cpf}>
                                        <option value=''></option>
                                        <option value=''>TODOS</option>
                                        {[...user.funcionariosList.data]
                                            .sort((a, b) => {
                                                if(a.tipo > b.tipo) return 1
                                                if (a.tipo < b.tipo) return -1
                                                return 0
                                            })
                                            .map(item => <option value={item.cpf}>{item.tipo} - {item.name.slice(0, 10)}... - {item.cpf} </option>)}
                                    </Form.Select> 
                                </Col>
                            </Row>
                        </Col>    
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-block">
                <Row className="justify-content-end">
                    {/* <Col sm='4'>
                        <Button variant='info' onClick={handlerAddPonto}>Adicionar</Button>
                    </Col> */}
                    <Col sm='4'>
                        <Button variant={'primary'} onClick={handlerSalvarAjuste}>
                           Filtrar
                        </Button>
                    </Col>
                    <Col sm='4'>
                        <Button variant='secondary' onClick={handlerSimpleClose} >Cancelar</Button>
                    </Col>
                </Row>
            </Modal.Footer>
            {error.flag ? <ModalError show={error.flag} setShow={setError} title='Aviso' msg={error.msg} /> : ""} 
        </Modal>
        
        
    )
}