import { createRef, useEffect, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { useSelector } from "react-redux"
import {Html5QrcodeScanner} from "html5-qrcode"
import './Modal.scss'
import ModalError from "./ModalError"


const qrCodeRef = createRef()

export default function ModalLerQRCodeCarga({ msg, title, show, setShow, setProdutoSelecionado = () => {}}) {
    const handlerSimpleClose = () => setShow(false)
    
    const user = useSelector(state => state.userSlice)
    const produtos = user.produtosList.data
    const embalagens = user.embalagensList.data
    const produtosEEmbalagens = user.produtosEEmbalagens.data;
    const [error, setError] = useState({
        flag: false
    })

    const [restart, setRestart] = useState(0)

    

    useEffect(() => {
        QRCodeScanner()
    }, [restart])

    const onInputQrCodeChange = (e) => {
        e.preventDefault()

        const qr_sku_selecionado = qrCodeRef.current.value
    

        const produtoSelecionadoA = produtosEEmbalagens.find(item => item.qr_sku == qr_sku_selecionado) 
        if (!produtoSelecionadoA) { 
            setError({
                flag: true,
                msg:'Produto Bipado não esta na disponivel nessa carga'
            })
            setRestart(last => last + 1)
            return
        }
        if (produtoSelecionadoA.tipo === 'PRODUTO') {
            setProdutoSelecionado({ ...produtoSelecionadoA, tipo:'PRODUTO' })
        } else {
            setProdutoSelecionado({ ...produtoSelecionadoA, tipo:'EMBALAGEM' })
            
        }
        qrCodeRef.current.value = ''    
        handlerSimpleClose()
    }
    
    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="reader" width="600px" className="mb-2"></div>
                    <Form.Control name='filterKeyword' className='form-control-input mb-2' placeholder='sku' ref={qrCodeRef} onInput={onInputQrCodeChange} disabled />
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant='primary' onClick={handlerSimpleClose}>Fechar</Button>
                </Modal.Footer> */}
            </Modal>
            
            {error.flag ? <ModalError msg={error.msg} title={error.title} show={error} setShow={setError}/> : ""}
        </>
    )
}




function QRCodeScanner(){
    let scanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: {width: 250, height: 250} },
        /* verbose= */ false);
    return scanner.render(onScanSuccess, onScanFailure)
    
    function onScanSuccess(decodedText, decodedResult) {
        // handle the scanned code as you like, for example:
        qrCodeRef.current.value = String(decodedText).replace('{','').replace('}','')
        /* dispachando um evento para o input perceber a mudança */
        const ev = new Event('input', {bubbles: true})
        qrCodeRef.current.dispatchEvent(ev)
        scanner.clear()
    }
    
    function onScanFailure(error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
        console.warn(`Code scan error = ${error}`);
    }
}