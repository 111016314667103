import { useEffect } from "react"

export default function ScrollbarDown({ tableRef, tbody, setIndexList = () => {}, indexList = 0} ){
    useEffect(() => {
        const refTable = tableRef.current
        const scrollHandler = (e) => {
            const tbodyHeight = tbody.current.clientHeight
            const divTableHeight = e.target.clientHeight
            const scrollPosition = e.target.scrollTop
            if(scrollPosition >= tbodyHeight - divTableHeight){
                setTimeout(() => {
                    setIndexList(indexList + 10)
                },1000)
            }

        }

        refTable.addEventListener('scroll', scrollHandler, false)

        return () => refTable.removeEventListener('scroll', scrollHandler, false)
    })
    return null
}