import { createRef, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { update, updateFuncionario } from "../../redux/slicers/user";
import {
	getAllData,
	getData,
	insertLogCaminhoes,
	locationDev,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";
import semFoto from "../../assets/perfil_sem_foto.jpg";

import "./Modal.scss";
import BodyRowAtribuir from "../table/body/BodyRowAtribuir";
import ScrollbarLeft from "../scrollbars/ScrollbarLeft";
import HeadTable from "../table/head/HeadRow";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../services/helpers";
import ModalErrorPretty from "./ModalErrorPretty";

const tableRef = createRef();
const tbodyRef = createRef();
const fotoDivStyle = {
	height: "200px",
	width: "200px",
	borderRadius: "50%",
	objectFit: "cover",
	border: "1px solid",
};
const headList = [
	"index",
	"id",
	"name",
	"email",
	"cnh",
	"cpf",
	"telefone",
	"caminhao",
];

export default function ModalAtribuirCaminhoeiro({ placa, show, setShow }) {
	const [error, setError] = useState({
		flag: false,
		title: "",
		message: "",
	});

	const dispatch = useDispatch();
	const [loading, setLoading] = useState({
		flag: false,
		msg: "Atribuir",
		style: "primary",
	});
	const [caminhoneiro, setCaminhoneiro] = useState({
		foto: "",
		id_caminhoneiro: "",
		nome_caminhoneiro: "",
		cpf_caminhoneiro: "",
		cnh_caminhoneiro: "",
		telefone_caminhoneiro: "",
		caminhao: "",
		tipo: "",
	});
	const [funcionarios, setFuncionarios] = useState([]);
	const [indexList, setIndexList] = useState(10);
	const userS = useSelector((state) => state.userSlice);

	const handlerSimpleClose = () =>
		setShow((last) => ({ error: { flag: false } }));

	const salvarAtribuicao = async (e) => {
		e.preventDefault();
		/* caminhoes/caminhoneiros */
		if (caminhoneiro.caminhao) {
			setError({
				show: true,
				title: "ATENÇÃO",
				message:
					"Impossivel disponibilizar mais de um caminhão para um caminhoneiro",
			});
			return;
		}
		setLoading((last) => ({
			...last,
			flag: true,
			msg: "Atribuindo",
		}));

		await updateTable(
			verificaTipoUsuario(userS) + "caminhoes/caminhoneiros",
			placa,
			{
				...caminhoneiro,
				tipo_operacao: "ATRIBUIÇÃO",
			}
		)
			.then((resp) => {})
			.catch((error) => {
				alert("error");
			});
		const body = {
			placa: placa,
			cpf_caminhoneiro: caminhoneiro.cpf_caminhoneiro,
			nome_caminhoneiro: caminhoneiro.nome_caminhoneiro,
			tipo_operacao: "ATRIBUIÇÃO",
		};
		//
		await insertLogCaminhoes(body, userS.tipo);
		setLoading((last) => ({
			...last,
			flag: false,
			msg: "Atribuido",
			style: "success",
		}));

		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	const verificarFuncionarios = async () => {
		try {
			const resp = await getAllData("dashboard/motorista_vs_caminhao");
			setFuncionarios(resp.data);
		} catch (error) {
			setError({ show: true, message: error.message });
		}
	};

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	/* scrollbar */
	useEffect(() => {
		const ref = tableRef.current;
		const handleScroll = (e) => {
			const heightTableBody = tbodyRef.current.clientHeight;
			const positionScroll = e.target.scrollTop;
			const heightDiv = e.target.clientHeight;

			if (positionScroll >= heightTableBody - heightDiv) {
				setTimeout(() => {
					setIndexList((last) => last + 30);
				}, 1000);
			}
		};
		ref.addEventListener("scroll", handleScroll, false);
		return () => {
			ref.removeEventListener("scroll", handleScroll, false);
		};
	}, []);

	useEffect(() => {
		verificarFuncionarios();
	}, []);

	const [filters, setFilters] = useState([]);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [
			funcionarios.filter((item) => item.tipo === "Caminhoneiro"),
		];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);

		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Atribuir <i style={{ fontSize: "inherit" }}>Caminhão {placa}</i> ao
						Caminhoneiro{" "}
						<i style={{ fontSize: "inherit" }}>
							'{caminhoneiro.nome_caminhoneiro}'
						</i>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col sm={4}>
							<Card
								style={{
									maxWidth: "18rem",
									margin: "auto",
									textAlign: "center",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{caminhoneiro.foto.length ? (
									<Card.Img
										variant="top"
										style={fotoDivStyle}
										src={locationDev + "/files/users" + caminhoneiro.foto}
										alt="Imagem CNH"
									/>
								) : (
									<Card.Img
										variant="top"
										style={fotoDivStyle}
										src={semFoto}
										alt="Sem Imagem Perfil"
									/>
								)}
								<Card.Body>
									<Card.Title>Foto do Perfil</Card.Title>
								</Card.Body>
							</Card>
						</Col>
						<Col sm="8">
							<Row className="mt-2 row-filters">
								<Col>
									<div>
										{filters.map((item, key) =>
											Object.keys(item).map((key, index) => {
												return (
													<span className="filters-card" key={index}>
														<span className="filters">
															<span className="title">
																{key}: {item[key]}
															</span>
															<i
																className="bi bi-x-lg"
																onClick={onClickRemoveFilter}
															></i>
														</span>
													</span>
												);
											})
										)}
									</div>
								</Col>
							</Row>
							<div className=" responsive-table" ref={tableRef}>
								<ScrollbarLeft
									tableRef={tableRef}
									coluna2={2}
									maxima_distancia={40}
								/>
								<Table hover size="sm">
									<thead style={{ zIndex: 49 }}>
										<HeadTable
											rota="atribuir_caminhoneiro"
											arrayValues={funcionarios}
											headerList={headList}
											setArrayFilters={setFiltersNew}
											arrayFilters={filtersNew}
											setFiltersOld={setFilters}
											filtersOld={filters}
										/>
									</thead>
									<tbody ref={tbodyRef}>
										{filteredTable
											? filteredTable.map((item, index) => {
													if (index < indexList) {
														return (
															<BodyRowAtribuir
																setCaminhoneiro={setCaminhoneiro}
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
																tableLength={indexList}
															/>
														);
													}
											  })
											: funcionarios.map((item, index) => {
													if (index < indexList) {
														return (
															<BodyRowAtribuir
																setCaminhoneiro={setCaminhoneiro}
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
																tableLength={indexList}
															/>
														);
													}
											  })}
									</tbody>
								</Table>
								{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
								{filteredTable ? (
									indexList < filteredTable.length ? (
										<Loading color={"black"} msg="Loading" />
									) : (
										""
									)
								) : indexList <
								  funcionarios.filter((item) => item.tipo === "Caminhoneiro")
										.length ? (
									<Loading color={"black"} msg="Loading" />
								) : (
									""
								)}
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button variant={loading.style} onClick={salvarAtribuicao}>
						{loading.flag ? (
							<Loading msg="Atribuindo" color={"white"} />
						) : (
							<span>
								<span style={{ color: "#ffffff" }}>{loading.msg}</span>
								<span style={{ color: "#ffffff" }}>
									{loading.style === "success" ? (
										<i className="bi bi-check"></i>
									) : (
										""
									)}
									{loading.style === "danger" ? (
										<i className="bi bi-exclamation-triangle"></i>
									) : (
										""
									)}
								</span>
							</span>
						)}
					</Button>
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</>
	);
}
