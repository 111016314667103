import { Fragment, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import ModalAtualizarUsuarios from "../../modal/ModalAtualizarUsuarios";

import "./BodyRow.scss";

export default function BodyRow({ headList, bodyList, index }) {
	const user = useSelector((state) => state.userSlice);
	const caminhoneiros = useSelector(
		(state) => state.userSlice.funcionariosList
	);

	const filiaisList = user.filiaisList.data;
	const ref = useRef(null);
	const refRow = useRef(null);
	const refEditar = useRef(null);
	const refExcluir = useRef(null);

	const [adminFunction, setAdminFunction] = useState({
		error: {
			flag: false,
			title: "",
			msg: "",
			confirmar: "",
		},
	});

	const onChangeCaminhoneiros = (e) => {
		e.preventDefault();
	};

	const onClickEditarRow = (e) => {
		e.preventDefault();
		// let ref1 = null, ref2 = null, ref3 = null, ref4 = null

		if (headList.indexOf("placa") >= 0) {
			setAdminFunction({
				error: {
					flag: true,
					title: "Editar Caminhão",
					msg: {
						title: `Deseja Editar o caminhão:`,
						msg: {
							...refRow.current.children,
							pk: refRow.current.children[5].innerText,
						},
					},
					confirmar: "Atualizar",
				},
			});
		} else if (headList.indexOf("cpf") >= 0) {
			setAdminFunction({
				error: {
					flag: true,
					title: "Editar Usuário",
					msg: {
						title: `Deseja editar o usuário:`,
						msg: {
							...refRow.current.children,
							pk: refRow.current.children[3].innerText,
						},
					},
					confirmar: "Atualizar",
				},
			});
		}
	};

	const onClickExcluirRow = (e) => {
		e.preventDefault();

		let ref1 = null,
			ref2 = null,
			ref3 = null,
			ref4 = null;

		if (headList.indexOf("placa") >= 0) {
			ref1 = refRow.current.children[3].innerText; //status
			ref2 = refRow.current.children[4].innerText; //tipo
			ref3 = refRow.current.children[6].innerText; //placa

			setAdminFunction({
				error: {
					flag: true,
					title: "Excluir Caminhão",
					msg: {
						title: `Deseja excluir o caminhão:`,
						msg: {
							TIPO: ref2,
							PLACA: ref3,
							STATUS: ref1,
						},
					},
					confirmar: "excluir",
				},
			});
		} else if (headList.indexOf("cpf") >= 0) {
			ref4 = refRow.current.children[3].innerText; //ID
			ref1 = refRow.current.children[4].innerText; //tipo
			ref2 = refRow.current.children[5].innerText; //nome
			ref3 = refRow.current.children[6].innerText; //cpf

			setAdminFunction({
				error: {
					flag: true,
					title: "Excluir Usuário",
					msg: {
						title: `Deseja excluir o usuário:`,
						// msg: `Tipo: ${ref1}; Nome: ${ref2}; CPF: ${ref3}; ID: ${ref4}`
						msg: {
							TIPO: ref1,
							NOME: ref2,
							CPF: ref3,
							ID: ref4,
						},
					},
					confirmar: "excluir",
				},
			});
		}
	};

	useEffect(() => {
		//
	}, [adminFunction]);
	return (
		<Fragment>
			{
				<tr ref={refRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Excluir"
												onClick={onClickExcluirRow}
												ref={refExcluir}
											></i>
										</span>
										<span className="editar">
											<i
												className="bi bi-pencil"
												title="Editar"
												onClick={onClickEditarRow}
												ref={refEditar}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "placa") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "createdAt" || item === "updatedAt") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "status") {
							return bodyList[item] ? (
								<td key={_index} className={item}>
									<div className="div-status">
										<span className="div-status-ativo">Ativo</span>
									</div>
								</td>
							) : (
								<td key={_index} className={item}>
									<div className="div-status">
										<span className="div-status-inativo">Inativo</span>
									</div>
								</td>
							);
						} else if (item === "data_nascimento") {
							return bodyList[item] ? (
								<td key={_index} className={item}>
									{" "}
									{new Date(bodyList[item]).toLocaleDateString()}
								</td>
							) : (
								<td key={_index} className={item}></td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "Atribuir") {
							return (
								<td key={_index}>
									<Form.Select onChange={onChangeCaminhoneiros} ref={ref}>
										<option value=""></option>
										{caminhoneiros.map((item, index__) => (
											<option value={item} key={index__}>
												{item}
											</option>
										))}
									</Form.Select>
								</td>
							);
						} else if (/folga|ajuste|feriado|falta/i.test(item)) {
							return <td key={_index}>{bodyList[item] ? "SIM" : ""}</td>;
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{/* {adminFunction.error.flag ? <ModalError title={adminFunction.error.title} msg={adminFunction.error.msg} show={adminFunction} setShow={setAdminFunction} msgButton={adminFunction.error.confirmar} /> : ""} */}
			{adminFunction.error.flag ? (
				<ModalAtualizarUsuarios
					title={adminFunction.error.title}
					msg={adminFunction.error.msg}
					show={adminFunction}
					setShow={setAdminFunction}
					msgButton={adminFunction.error.confirmar}
				/>
			) : (
				""
			)}
			{/* {adminFunction.error.flag ? <ModalAtualizarCaminhao title={adminFunction.error.title} msg={adminFunction.error.msg} show={adminFunction} setShow={setAdminFunction} msgButton={adminFunction.error.confirmar} /> : ""} */}
		</Fragment>
	);
}
