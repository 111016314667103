import { createRef, Fragment, useRef, useState } from "react";
import { Button, Col, Form, Modal, Table } from "react-bootstrap";

import "./Modal.scss";
import ModalImprimirQRCode from "./ModalImprimirQRCode";

const refRowCheck = createRef();
const refRow = createRef();
const tbodyRef = createRef();
const tableRef = createRef();

export default function ModalListarProdutosEmbalados(props) {
	const handlerSimpleClose = () => {
		props.setShow((last) => ({ ...last, flag: false }));
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Embalagem {String(props?.numero_embalagem).padStart(7, 0)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						className="responsive-table card max-heigth-card-table"
						ref={tableRef}
					>
						<Table size="sm" bordered>
							<thead>
								<tr>
									{props.headList.map((item, index) => {
										if (item === "index") {
											return <th key={index}>#</th>;
										} else if (item.toUpperCase() === "CREATED_AT") {
											return <th key={index}>DATA DE CRIAÇÃO</th>;
										} else if (item.toUpperCase() === "UPDATED_AT") {
											return <th key={index}>ULTIMA ATUALIZAÇÃO</th>;
										}
										return <th key={index}>{item.toUpperCase()}</th>;
									})}
								</tr>
							</thead>
							<tbody ref={tbodyRef}>
								{props.produtos.map((item, index) => (
									<Produtos
										bodyList={item}
										key={index}
										index={index}
										headList={props.headList}
									/>
								))}
							</tbody>
						</Table>
					</div>
				</Modal.Body>
				<Modal.Footer style={{ flexFlow: "row" }}>
					<Col sm="3">
						<Button variant="secondary" onClick={handlerSimpleClose}>
							Cancelar
						</Button>
					</Col>
				</Modal.Footer>
			</Modal>
		</>
	);
}

const Produtos = ({ bodyList, index, headList }) => {
	const refEditar = useRef();
	const [imprimir, setImprimir] = useState({
		flag: false,
		title: "",
		qr_sku: "",
		msg_obj: {},
	});

	const onClickPrint = (e) => {
		setImprimir({
			flag: true,
			msg_obj: {
				...bodyList,
				qtde: bodyList.qtde_produtos,
			},
			title: "Imprimir QRCode da Embalagem",
		});
	};
	return (
		<Fragment>
			{
				<tr ref={refRow}>
					{headList.map((item, _index) => {
						if (index > 19) {
							return false;
						}

						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item.toLowerCase() === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="imprimir">
											<i
												className="bi bi-printer"
												title="Imprimir Romaneio"
												onClick={onClickPrint}
												ref={refEditar}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (
							["conferido", "planejado", "carregado", "descarregado"].includes(
								item
							)
						) {
							return (
								<td
									key={_index}
									className={item}
									style={{ minWidth: "50px", textAlign: "center" }}
								>
									<div className="div-status">
										<span
											className={`div-status-ativo ${
												bodyList[item] ? "bg-success" : "bg-warning"
											}`}
										>
											{bodyList[item] ? "SIM" : "NÃO"}
										</span>
									</div>
								</td>
							);
						} else if (item === "data_carregamento") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "data_descarregamento") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "data_conferencia") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "numero_embalagem") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{String(bodyList.numero_embalagem).padStart(7, 0)}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "embalado") {
							return bodyList[item] ? (
								<td key={_index}> {bodyList.embalado ? "SIM" : "NÃO"}</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "DTEMISSAO") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						}
						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{imprimir.flag ? (
				<ModalImprimirQRCode
					title={imprimir.title}
					show={imprimir.flag}
					setShow={setImprimir}
					msg_obj={imprimir.msg_obj}
				/>
			) : (
				""
			)}
		</Fragment>
	);
};
