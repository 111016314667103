import { createRef, useEffect, useRef, useState } from "react"
import { Button, Row, Col, Form, Modal, Table, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
 
import { createPontos, updateTable } from "../../services/Servicos"
import './Modal.scss'
import HeadTable from "../table/head/HeadRow"
import { filtroDinamicoArrays, removerDadosDuplicados } from "../../services/helpers"
import BodyRow from "../table/body/BodyRow"
import ModalError from "./ModalError"

const tableRef = createRef()
const tbodyRef = createRef()
const headList = ['index', 'tipo', 'cpf', 'nome', 'ent_sai', 'dia_semana', 'ponto_data', 'ponto_hora', 'ajuste', 'feriado', 'falta']

export default function ModalLancamentosAjustesPontos(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [indexList, setIndexList] = useState(10)
    const [ajustar, setAjustar] = useState({
        flag: false
    })

    const handlerSimpleClose = () => props.setShow(last => ({ ...last, flag: false }))

    const [filtersNew, setFiltersNew] = useState({})
    const [filters, setFilters] = useState([]) 
    const [filteredTable, setFilteredTable] = useState(false)

    const onClickRemoveFilter = (e) => {
        e.preventDefault()
        const value = e.target.parentNode.firstElementChild.innerText
        const [key, keyValue] = value.split(':')
        setFilters(last => ([...last.filter(item => String(item[key]).replaceAll(' ','') !== keyValue.replaceAll(' ',''))]))

    }

    useEffect(() => {
        if(!filters.length){
            setIndexList(10)
            setFilteredTable(false)
            return
        }

        let testeFilters = {}

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            testeFilters[param] = []
        })

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            // testeFilters[param].push(ele[param])
            testeFilters[param].push(String(ele[param]).toLowerCase())
            
            if (String(ele[param]).toLowerCase() == 'false') {
                testeFilters[param].push('null')
                
            }
        })

        
        let arrayRetorno = [props.pontos]

        Object.keys(testeFilters).forEach((key, index) => {
            arrayRetorno.push(filtroDinamicoArrays(testeFilters, key, arrayRetorno[arrayRetorno.length - 1], ))
        })

        const arrayF = removerDadosDuplicados(arrayRetorno[arrayRetorno.length - 1], 'id')
        setFilteredTable(arrayF)        

    }, [filters])

    return (
        <>
            <Modal
                show={props.show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Lançamentos do dia {String(props.dia).padStart(2, 0)}/{String(props.mes).padStart(2, 0)}/{props.ano }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" responsive-table"  ref={tableRef}>
                        <Table hover size='sm'>
                            <thead style={{zIndex:'49'}}>
                                <HeadTable
                                    arrayValues={[...props.pontos]}
                                    headerList={headList}
                                    setArrayFilters={setFiltersNew}
                                    arrayFilters={filtersNew}
                                    setFiltersOld={setFilters}
                                    filtersOld={filters}
                                />
                                    
                            </thead>
                            
                            <tbody ref={tbodyRef}>

                                {
                                    [...props.pontos].reverse().map((item, index) => {
                                        if(index < indexList){
                                            return <BodyRow key={index} bodyList={item} headList={headList} index={index} />
                                        }

                                    })
                                }

                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-block">
                    
                    <Row >

                        <Col sm='6'>
                            <Button variant='primary' onClick={() => setAjustar({ flag: true, ...props })} disabled={ props.ajustado }>Ajuste</Button>
                        </Col>
                        <Col sm='6'>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Fechar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            {ajustar.flag ? <Ajustar show={ajustar.flag} setShow_={setAjustar} {...ajustar} /> : ''}
        </>
    )
}

function Ajustar(props) {
    
    const user = useSelector(state => state.userSlice)
    const handlerSimpleClose = () => props.setShow_(last => ({ flag: false }))
    const ent_sai_ref = useRef()
    const hora_ref = useRef()
    const minuto_ref = useRef()
    const segundo_ref = useRef()

    const [bntLoading, setBtnLoading] = useState({
        flag: false,
        style: 'primary',
        msg:'Salvar'
    })
    const [error, setError] = useState({
        flag: false
    })

    
    const [pontos, setPontos] = useState([
        {
            id: 1,
            ent_sai: 'ENTRADA',
            hora: '07',
            minuto: '30',
            segundo: '00',
            ponto_data: `${String(props.dia).padStart(2, 0)}/${String(props.mes).padStart(2, 0)}/${props.ano}`,
            ponto_hora: `07:30`,
        },
        {
            id: 2,
            ent_sai: 'SAIDA',
            hora: '11',
            minuto: '30',
            segundo: '00',
            ponto_data: `${String(props.dia).padStart(2, 0)}/${String(props.mes).padStart(2, 0)}/${props.ano}`,
            ponto_hora: `11:30`,
        },
        {
            id: 3,
            ent_sai: 'ENTRADA',
            hora: '13',
            minuto: '00',
            segundo: '00',
            ponto_data: `${String(props.dia).padStart(2, 0)}/${String(props.mes).padStart(2, 0)}/${props.ano}`,
            ponto_hora: `13:00`,
        },
        {
            id: 4,
            ent_sai: 'SAIDA',
            hora: '17',
            minuto: '45',
            segundo: '00',
            ponto_data: `${String(props.dia).padStart(2, 0)}/${String(props.mes).padStart(2, 0)}/${props.ano}`,
            ponto_hora: `17:45`,
        },
    ]) 

    const [addPonto, setAddPonto] = useState({})
    
    const handlerAddPonto = () => { 
        
        if(!addPonto.ent_sai) return setError({flag: true, msg:'Especifique o Lancamento do Ponto'})
        if(!addPonto.hora) return setError({flag: true, msg:'Selecione as Horas'})
        if(!addPonto.minuto) return setError({flag: true, msg:'Selecione as Minutos'})

        setPontos(last => [...last, {
            ...addPonto,
            ponto_data: `${String(props.dia).padStart(2, 0)}/${String(props.mes).padStart(2, 0)}/${props.ano}`,
            ponto_hora: `${addPonto.hora}:${addPonto.minuto}`,
        }])
        setAddPonto({})
        ent_sai_ref.current.value = ''
        hora_ref.current.value = ''
        minuto_ref.current.value = ''
        // segundo_ref.current.value = ''
    }

    const onChangeValues = (e) => {
        setAddPonto(last => ({...last, [e.target.name]: e.target.value}))
    }
    
    const removerPonto = (id) => {
        setPontos(last => last.filter((_, index) => index !== id))
    }
    const handlerSalvarAjuste = async () => {
        setBtnLoading({
            flag: true,
            msg:'Atualizando',
            style:'warning'
        })
        const obj = {
            where: {
                cpf: props.cpf,
                dia: props.dia,
                mes: props.mes,
                ano: props.ano,
                data_cancelamento: null
            },
            update: {
                data_cancelamento: new Date().toISOString()
            }

        }

        await updateTable('funcionario/controle-ponto', '', obj)
        setBtnLoading({
            flag: true,
            msg:'Salvando',
            style:'warning'
        })

        let body = {
            nome: user.name,
            cpf: user.cpf,
            data_nascimento: new Date(user.data_nascimento).toLocaleDateString('pt-br',{timeZone:'UTC'}),
            tipo: user.tipo,
            dia: props.dia,
            mes: props.mes,
            ano: props.ano,
            dia_semana: props.dia_semana_long,
            ajuste: true
        }
        for await (let ponto of pontos) {
            await createPontos(
                {
                    ...body,
                    ...ponto,
                    ponto_hora: `${ponto.hora}:${ponto.minuto}:${ponto.segundo || '00'}`,
                    segundo: ponto.segundo ?? 0,
                    ponto: new Date(body.ano, body.mes - 1, body.dia, ponto.hora, ponto.minuto, ponto.segundo || 0).toISOString()
                }
            )
            
        }
        setBtnLoading({
            flag: false,
            msg:'Salvo',
            style:'success'
        })
        setTimeout(() => {
            window.location.replace('/controle-ponto/cadastrar')
            // handlerSimpleClose()
        }, 500)
    }
    
    useEffect(() => {
        
    }, [addPonto])
    return (
        <Modal
            show={props.show}
            onHide={handlerSimpleClose}
            backdrop='static'
            keyboard={false}
            centered
            size='md'
        >
            <Modal.Header>
                <Modal.Title>Atualizar dia {String(props.dia).padStart(2, 0)}/{String(props.mes).padStart(2, 0)}/{props.ano }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row onSubmit={handlerAddPonto}>
                        <Col sm='12' className='mb-4'>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Lançamento</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Select type="text" name='ent_sai'  onChange={onChangeValues}  ref={ ent_sai_ref} >
                                        <option value=''></option>
                                        <option value='ENTRADA' >ENTRADA</option>
                                        <option value='SAIDA'>SAÍDA</option>
                                    </Form.Select> 
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='4'>
                                    <Form.Label>Horário:</Form.Label>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label>Horas:</Form.Label>
                                            <Form.Select type="text" name='hora' onChange={onChangeValues}  ref={ hora_ref} >
                                                <option value=''></option>
                                                {new Array(24).fill('').map((i, k) => <option key={k} value={String(k).padStart(2, 0)}>{ String(k).padStart(2, 0) }</option>)}        
                                            </Form.Select>         
                                        </Col>
                                        <Col>
                                            <Form.Label>Minutos:</Form.Label>
                                            <Form.Select type="text" name='minuto' onChange={onChangeValues} ref={ minuto_ref}>
                                                <option value=''></option>
                                                {new Array(60).fill('').map((i, k) => <option key={k} value={String(k).padStart(2, 0)}>{ String(k).padStart(2, 0) }</option>)}        
                                            </Form.Select>             
                                        </Col>
                                        <Col>
                                            <Form.Label style={{color:'transparent'}}>:</Form.Label>
                                            <Button variant='info' onClick={handlerAddPonto}>+Add</Button>
                                            {/* <Col sm='4'>
                                            </Col> */}
                                            {/* <Form.Select type="text" name='segundo' onChange={onChangeValues} ref={ segundo_ref}>
                                                <option value=''></option>
                                                {new Array(60).fill('').map((i, k) => <option key={k} value={String(k).padStart(2, 0)}>{ String(k).padStart(2, 0) }</option>)}        
                                            </Form.Select>             */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                                    
                        </Col>    
                    </Row>
                    <div className="card-header mt-3">
                        <h6>Resumo do Ajuste</h6>
                    </div>
                    <Row className="">
                        {pontos
                            .map((item, key) => 
                                <div className="d-flex justify-content-center text-center" key={ key }>
                                    <div style={{ margin: '0 10px 10px 0', padding: '1px 4px', border: 'solid 0.1px #a30c0c66', boxShadow:'0 0 3px #a30c0c66', backgroundColor:'red', cursor:'pointer' }} onClick={() => removerPonto(key)}>
                                        <i className="bi bi-x-lg" style={{color:'white'}}></i>
                                    </div>
                                    <Col sm='3' >
                                        <div style={{margin:'0 10px 10px 0', padding:'2px 7px', border:'solid 0.1px #a30c0c66', boxShadow:'0 0 3px #a30c0c66'}}>{item.ent_sai}</div>
                                    </Col>
                                    <Col sm='3'>
                                        <div style={{margin:'0 10px 10px 0', padding:'2px 7px', border:'solid 0.1px #a30c0c66', boxShadow:'0 0 3px #a30c0c66'}}>{item.ponto_data}</div>
                                    </Col>
                                    <Col sm='3'>
                                        <div  style={{margin:'0 10px 10px 0', padding:'2px  7px', border:'solid 0.1px #a30c0c66', boxShadow:'0 0 3px #a30c0c66'}}>{item.ponto_hora}</div>
                                    </Col>
                                </div>
                            )}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-block">
                <Row className="justify-content-end">
                    {/* <Col sm='4'>
                        <Button variant='info' onClick={handlerAddPonto}>Adicionar</Button>
                    </Col> */}
                    <Col sm='4'>
                        <Button variant={bntLoading.style} onClick={handlerSalvarAjuste} disabled={ bntLoading.style == 'primary' ? false : true}>
                            {bntLoading.style == 'success' ? <i className="bi bi-check"></i> :"" }
                            {bntLoading.flag ? <Spinner animation='border' size="sm"/> :"" }
                            {bntLoading.msg}
                        </Button>
                    </Col>
                    <Col sm='4'>
                        <Button variant='secondary' onClick={handlerSimpleClose} >Cancelar</Button>
                    </Col>
                </Row>
            </Modal.Footer>
            {error.flag ? <ModalError show={error.flag} setShow={setError} title='Aviso' msg={error.msg} /> : ""} 
        </Modal>
        
        
    )
}