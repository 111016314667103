import { Fragment } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NavbarDiario(){
    const user = useSelector(state => state.userSlice)
    return (
        <Fragment>
                <Nav variant='tabs'>
                    {
                        user.tipo.toLowerCase() === 'caminhoneiro' ? 
                            <Nav.Item>
                                <NavLink className="nav-link" to='lancamentos'>Lancamentos</NavLink>
                            </Nav.Item>
                        : 
                        ''
                    }
                    {
                        user.tipo.toLowerCase() === 'empresa' ? 
                            <Nav.Item>
                                <NavLink className="nav-link" to='gestao'>Lançamentos</NavLink>
                            </Nav.Item>
                        : 
                        ''
                    }
                </Nav>
            <Outlet/>
        </Fragment>
    )
}






