import { QRCodeSVG } from "qrcode.react";
import { Fragment, useEffect, useRef, useState } from "react";
import ModalTableProdutos from "../../modal/ModalTableProdutos";

import "./BodyRow.scss";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	updateProdutosList,
	updateProdutosListChecked,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getDanfe,
	getData,
	getXMLString,
	numerosInteiros,
	updateTable,
	updateTableNew,
} from "../../../services/Servicos";
import ModalError from "../../modal/ModalError";
import ModalLoading from "../../modal/ModalLoading";
import ModalAtualizarDimensoes from "../../modal/ModalAtualizarDimensoes";
import ModalListarProdutosEmbalados from "../../modal/ModalListarProdutosEmbalados";

const headListProdutos = [
	"index",
	"transferencia",
	"conferido",
	"codigo",
	"descricao",
	"qtde",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"abreviatura",
	"qr_sku",
	"numero_item_embalagem",
	// , 'numero_embalagem'
];
export default function BodyRowProdutosTransferencias({
	headList,
	bodyList,
	index,
	caminhao,
	setLoadingFinalizarParcial = () => {},
	setError = () => {},
	setSeparador = () => {},
	separador,
	setRowSelected = () => {},
	selectedRow,
	tableLength,
	cargaSelecionada,
	produtosArray,
	setLoading,
	loading,
	updateList,
	fnRetorno,
}) {
	const userEmail = useSelector((state) => state.userSlice.email);
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;
	const transferencias = user.transferenciasList;
	const refRow = useRef(null);
	const refRowCheck = useRef(null);
	const refEditar = useRef(null);
	const dispatch = useDispatch();

	const [produtos, setProdutos] = useState({
		flag: "",
		ID_EMPRESA: "",
		ID_FILIAL: "",
		ID_PEDIDO: "",
	});

	const [editarDimensoes, setEditarDimensoes] = useState({
		flag: false,
		dimensao: "",
		produto: "",
		descricao: "",
		parametro: "",
		qr_sku: "",
		msg_button: "",
		currentValue: 0,
	});


	const isSeparador = () => {
		if (separador.verificado && !separador.isSeparador) {
			setError({
				message:
					"Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
				show: true,
			});
			return false;
		}
		return true;
	};

	const setHandlerClicked = async (e, bodyList, checkedL) => {
		if (!isSeparador()) return;
		const qr_sku = e.target.name.trim();
		const obj = {
			conferido: checkedL,
			data_conferencia: new Date().toISOString(),
			user_conferencia: userEmail,
			setor_conferencia: user.local_separacao,
		};
		setChecked(checkedL);

		try {
			updateList((last) => [
				...last.map((item) => {
					let item_ = { ...item };
					if (item.qr_sku === qr_sku) {
						item_ = {
							...item_,
							qr_sku: qr_sku,
							conferido: checkedL,
							data: checkedL ? new Date().toISOString() : "null",
							user: checkedL ? userEmail : "null",
							setor: checkedL ? user.local_separacao : "null",
						};
					}
					return item_;
				}),
			]);

			if (bodyList.tipo == "PRODUTO") {
				const result = await updateTable(
					`carga/separador/produto/trasnferencia/${bodyList.transferencia}/${bodyList.id}`,
					"",
					obj
				);
			} else if (bodyList.tipo === "EMBALAGEM") {
				await Promise.all([
					updateTableNew(
						`transferencias/produtos/${bodyList.transferencia}/${bodyList.numero_embalagem}`,
						obj
					),
					updateTable(`transferencias/embalagens`, bodyList.qr_sku, obj),
				]);
			}
		} catch (error) {}
	};

	const onClickEditar = async (
		e,
		parametro,
		qr_sku,
		produto,
		currentValue,
		descricao
	) => {
		if (!isSeparador()) return;
		if (!separador.verificado) {
			const teste = await getData(
				"transferencias/funcionarios/produtos/verificar",
				bodyList.transferencia,
				{ id_filial: bodyList.id_filial }
			);

			if (
				teste.data.exists &
				(user.email.toLowerCase() !== teste.data?.separador?.toLowerCase()) &
				(user.local_separacao.toLowerCase() ===
					teste.data?.setor?.toLowerCase())
			) {
				setError({
					message:
						"Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
					show: true,
				});

				setSeparador({ verificado: true, isSeparador: false });
				return;
			}
			setSeparador({ verificado: true, isSeparador: true });
		}

		let maxValue = caminhao[parametro];

		if (parametro === "peso") {
			maxValue = caminhao.capacidade ?? 0;
		}
		setEditarDimensoes((last) => ({
			...last,
			flag: true,
			dimensao: parametro,
			produto,
			qr_sku,
			currentValue,
			maxValue,
			descricao,
			transferencia: bodyList.transferencia,
			bodyList,
		}));
	};

	const exluirProduto = async (e) => {
		if (!isSeparador()) return;
		if (bodyList.conferido)
			return setError({
				show: true,
				message: "Produto não pode ser cancelado caso esteja separado!",
			});
		setLoading({ show: true });
		// const transferencia = transferencias.data.find(
		// 	(transferencia) => transferencia.transferencia == bodyList.transferencia
		// );
		const bodyUpdate = {
			qr_sku: bodyList.qr_sku,
			data_cancelamento: new Date().toISOString(),
			user_cancelamento: user.name,
		};

		if (bodyList.tipo == "PRODUTO") {
			const result = await updateTable(
				`carga/separador/produto/trasnferencia/${bodyList.transferencia}/${bodyList.id}`,
				"",
				bodyUpdate
			);
		}

		if (bodyList.tipo == "EMBALAGEM") {
			const result = await updateTable(
				`transferencias/embalagens`,
				bodyList.qr_sku,
				{ ...bodyUpdate, numero_embalagem: bodyList.numero_embalagem }
			);
			const codigoTransf = bodyList.transferencia.split("_");
			return fnRetorno(codigoTransf[1]);
		}
		updateList((last) => [
			...last.filter((item) => item.qr_sku !== bodyUpdate.qr_sku),
		]);
		setLoading({ show: false });
	};

	const onClickTableRow = (e) => {
		refRow.current.classList =
			String(refRow.current.classList).replace(" bg-selected", "") +
			" bg-selected";

		let i = index;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = String(actualSibling.classList).replace(
				" bg-selected",
				""
			);

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = index;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = String(actualSibling.classList).replace(
				" bg-selected",
				""
			);
			actualSibling = actualSibling.previousSibling;
			i--;
		}

		setRowSelected(bodyList);
	};

	const [verProdutos, setVerProdutos] = useState({
		flag: false,
		produtosDaEmbalagem: [],
	});
	const [loadingBtn, setLoadingBtn] = useState(false);

	const onClickVerProdutosEmbalados = async (e) => {
		setLoadingBtn(true);
		try {
			const produtosEmbalados = await getAllData(
				"transferencias/get-produtos",
				{
					numero_embalagem: bodyList.numero_embalagem,
					transferencia: bodyList.transferencia,
				}
			);
			setVerProdutos((last) => ({
				...last,
				flag: true,
				produtosDaEmbalagem: produtosEmbalados.data,
				numero_embalagem: bodyList.numero_embalagem,
			}));
		} catch (error) {
			setError({
				show: true,
				message:
					"message: Erro na tentativa de recuperar produtos embalados: Embalagem N°" +
					bodyList.numero_embalagem,
			});
		}
		setLoadingBtn(false);
	};
	const [checked, setChecked] = useState(bodyList.conferido);
	useEffect(() => {
		const {conferido} = bodyList
		setChecked(conferido)
	}, [])

	return (
		<Fragment>
			{
				<tr
					ref={refRow}
					onClick={onClickTableRow}
					className={
						bodyList.data_cancelamento
							? "bg-cancelado"
							: bodyList.planejado
							? "bg-planejado"
							: checked
							? "bg-conferido"
							: ""
					}
				>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item.toLowerCase() === "admin") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Cancelar Produto"
												onClick={(e) => exluirProduto(bodyList)}
											>
												{" "}
											</i>
										</span>
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "qtde") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{bodyList.tipo == "PRODUTO"
												? Number(bodyList.qtde).toLocaleString("pt-BR")
												: !bodyList.tipo
												? numerosInteiros(bodyList.qtde)
												: Number(bodyList.qtde_produtos).toLocaleString(
														"pt-BR"
												  )}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												{bodyList.tipo == "PRODUTO" ? (
													<i
														className="bi bi-pencil"
														title="Editar Quantidade"
														onClick={(e) => {
															onClickEditar(
																e,
																"qtde",
																bodyList.qr_sku,
																bodyList.codigo,
																bodyList.qtde,
																bodyList.descricao
															);
														}}
													></i>
												) : loadingBtn ? (
													<Spinner size="sm" animation="border" />
												) : (
													<i
														className="bi bi-plus-circle"
														title="Ver Produtos Embalados"
														onClick={onClickVerProdutosEmbalados}
													></i>
												)}
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "qtde_planejada") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{bodyList.tipo == "PRODUTO"
												? Number(bodyList.qtde_planejada).toLocaleString(
														"pt-BR"
												  )
												: !bodyList.tipo
												? numerosInteiros(bodyList.qtde_planejada)
												: "N/A"}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												{bodyList.tipo == "PRODUTO" ? (
													<i
														className="bi bi-pencil"
														title="Editar Quantidade"
														onClick={(e) => {
															onClickEditar(
																e,
																"qtde",
																bodyList.qr_sku,
																bodyList.codigo,
																bodyList.qtde,
																bodyList.descricao
															);
														}}
													></i>
												) : (
													""
												)}
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "comprimento") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.comprimento).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Comprimento"
													onClick={(e) => {
														onClickEditar(
															e,
															"comprimento",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.comprimento,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index}>
									{filiaisList.find(
										(item) => item.idFilial == bodyList.id_filial
									)?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item.toLowerCase() === "altura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.altura).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Altura"
													onClick={(e) => {
														onClickEditar(
															e,
															"altura",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.altura,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "largura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.largura).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil bi-circle-animation"
													title="Editar Largura"
													onClick={(e) => {
														onClickEditar(
															e,
															"largura",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.largura,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "peso_unitario") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										{bodyList.tipo == "PRODUTO"
											? Number(bodyList[item]).toLocaleString("pt-BR")
											: "N/A"}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "peso") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{Number(bodyList.peso).toLocaleString()}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil bi-circle-animation"
													title="Editar Peso"
													onClick={(e) => {
														onClickEditar(
															e,
															"peso",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.peso,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "volume") {
							return (
								<td key={_index}>
									{String(bodyList.volume).replace(".", ",")}
								</td>
							);
						} else if (item === "conferido") {
							return (
								<td
									key={_index}
									className={item}
									style={{ minWidth: "50px", textAlign: "center" }}
								>
									{user.tipo.toLowerCase() !== "separador" ? (
										<Form.Check ref={refRowCheck} checked={checked} disabled />
									) : (
										<Form.Check
											ref={refRowCheck}
											checked={checked}
											onChange={(e) =>
												setHandlerClicked(e, bodyList, e.target.checked)
											}
											name={bodyList.qr_sku}
											disabled={
												(cargaSelecionada?.tipo_transporte === "FRETE" ||
													cargaSelecionada?.tipo_carga === "TRANSFERÊNCIA" ||
													cargaSelecionada?.regiao_entrega === "LOCAL") &&
												bodyList.qtde &&
												bodyList.peso
													? false
													: !bodyList.altura ||
													  !bodyList.comprimento ||
													  !bodyList.largura ||
													  !bodyList.peso
													? true
													: false
											}
										/>
									)}
									{/* name ={`${bodyList.numero_pedido} - ${bodyList.codigo}`}/> */}
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td
									key={_index}
									className={item}
									style={{ whiteSpace: "nowrap" }}
								>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_code_id + "}"} />
								</td>
							);
						} else if (
							[
								"carregado",
								"transferido",
								"planejado",
								"descarregado",
								"finalizado",
								"embalado",
							].includes(item)
						) {
							//
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList[item] ? "SIM" : "NÃO"}
								</td>
							);
						} else if (
							item === "data_carregamento" ||
							item === "data_planejamento" ||
							item === "data_conferencia" ||
							item === "data_cancelamento" ||
							item === "DTEMISSAO"
						) {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "numero_embalagem") {
							return (
								<td key={_index}>
									{" "}
									{bodyList.embalado
										? String(bodyList.numero_embalagem).padStart(7, 0)
										: "NÃO"}
								</td>
							);
							// return bodyList[item] ? <td key={_index}> { String(bodyList.numero_embalagem).padStart(7, 0)}</td> : <td key={_index}></td>
						} else if (item === "DTEMISSAO") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						}
						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item] ?? "NÃO"}
							</td>
						);
					})}
				</tr>
			}
			{produtos.flag ? (
				<ModalTableProdutos
					show={produtos.flag}
					setShow={setProdutos}
					ID_EMPRESA={produtos.ID_EMPRESA}
					ID_FILIAL={produtos.ID_FILIAL}
					ID_PEDIDO={produtos.ID_PEDIDO}
				/>
			) : (
				""
			)}
			{editarDimensoes.flag ? (
				<ModalAtualizarDimensoes
					show={editarDimensoes.flag}
					setShow={setEditarDimensoes}
					{...editarDimensoes}
					base="transferencia"
					updateList={updateList}
				/>
			) : (
				""
			)}

			{verProdutos.flag ? (
				<ModalListarProdutosEmbalados
					setShow={setVerProdutos}
					show={verProdutos.flag}
					produtos={verProdutos.produtosDaEmbalagem}
					headList={headListProdutos}
					setBtnLoaging={setLoadingBtn}
					{...verProdutos}
				/>
			) : (
				""
			)}
			{/* { loading.show ? <ModalLoading show={loading.show} setShow={setLoading}/> : ""} */}
		</Fragment>
	);
}
