import { Fragment, useRef, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { update, updateCaminhoesCaminhoeiros } from "../../redux/slicers/user"
import { getAllData, Unauthorized, updateTable, verificaTipoUsuario } from "../../services/Servicos"
import Loading from "../loading/Loading"

import './Modal.scss'


export default function ModalAtualizarCaminhoesCaminhoneiros({ msg, title, show, setShow, msgButton }) {

    const dispatch = useDispatch()
 

    const [loading, setLoading] = useState({
        isLoading: false,
        msg: msgButton,
        style:'primary'
    })


    const userS = useSelector(state => state.userSlice)
    const updateCaminhoesList = () => {
        getAllData(verificaTipoUsuario(userS) + 'caminhoes/caminhoneiros').then(response => {
            dispatch(updateCaminhoesCaminhoeiros(response.data))
            handlerSimpleClose()
        })
    }


    const submitDataExclude = async () => {
        const body = {
            id_caminhoneiro:'',
            nome_caminhoneiro:'',
            cpf_caminhoneiro:'',
            cnh_caminhoneiro:'',
            telefone_caminhoneiro:'',
            _caminhoneiro: '',
            tipo_operacao: 'EXCLUSÃO'
        }
            setLoading({
                isLoading: true,
                msg: 'Excluindo Atribuição',
                style:'danger'
            })
            await updateTable(verificaTipoUsuario(userS) + 'caminhoes/caminhoneiros', msg.msg.PLACA, body)
                .then(resp => {
                    setLoading({
                        isLoading: false,
                        msg: 'Excluido com Sucesso',
                        style:'success'
                    })
        
                    setTimeout(() => {
                        updateCaminhoesList()
                        window.location.reload()
                    }, 500)
                })
                .catch(error => {
                    if (error.msg === 'Unauthorized') {
                        Unauthorized(dispatch, update)
                    }
                })

                // const bodyLog = {
                //     placa: msg.msg.PLACA
                //     , cpf_caminhoneiro: msg.msg.CPF
                //     , tipo_operacao: 'EXCLUSÃO'
                // }
                // await insertLogCaminhoes(bodyLog, user.tipo)
                //     .then(resp => {
                
                //     })
                //     .catch(error => {
                //         if (error.msg === 'Unauthorized') {
                //             alert('Session has Expired')
                //             dispatch(update({ authenticated: false, token: '' }))
                //             return window.location.replace('/login')
                            
                //         }
                //         alert('Erro Inesperado')
                //         setLoading(last => ({
                //             ...last,
                //             flag: false,
                //             msg:'Error',
                //             style:'danger'
                //         }))
        
                //     })
    }

    const handlerSimpleClose = () => setShow(last => ({ error: { flag: false } }))

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}: {msg.msg.pk}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExcluirDados msg={msg}/>
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{width:'100%'}}>
                        <Col>
                            <Button variant={loading.style} onClick={submitDataExclude}>
                                {
                                    loading.isLoading ? <Loading msg={loading.msg} /> : loading.msg
                                }
                                {loading.style === 'success' ? <i className="bi bi-check"></i>:""}
                                
                            </Button>
                        </Col>
                        <Col>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function ExcluirDados({ msg }) {
    return (
        <Fragment>
            <div>
                {msg.title}
            </div>
            <div>
                {
                    Object
                        .keys(msg.msg)
                        .map((key, index) =>
                            <div key={index}>
                                {key}: <b><i>{msg.msg[key]}</i></b>
                            </div>
                        )
                }
            </div>
        </Fragment>
    )
}
