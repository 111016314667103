import { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	getDanfe,
	getDanfeVST,
	getData,
	getXMLString,
	savalDanfeAssinada,
} from "../../services/Servicos";

import "./Modal.scss";
import { PDFDocument, rgb, setStrokingCmykColor } from "pdf-lib";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import ModalPadAssinatura from "./ModalPadAssinatura";
import LoadingNew from "../loading/LoadingNew";
import { updateRomaneiosList } from "../../redux/slicers/user";
import { danfeController } from "../../redux/slicers/carga";

export default function ModalPDF_AssinarDanfe(props) {
	const canvasRef = useRef();
	const refSignedFile = useRef();
	pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

	const [pdfRef, setPdfRef] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [numberPages, setNumberOfPages] = useState(1);

	const [loading, setLoading] = useState(true);
	const [pdfFile, setPdfFile] = useState(null);
	const user = useSelector((state) => state.userSlice);
	const cargasSelecionadas = user.cargaSelecionada;
	const [pdf, setPdf] = useState(props.pdf);
	const [downloadPdf, setDownloadPdf] = useState(null);
	const dispatch = useDispatch();
	const romaneios = useSelector((state) => state.userSlice.romaneiosList.data);
	const romaneiosDoCliente = romaneios.filter(
		(item) =>
			item.cpf_cnpj === props.cpf_cnpj &&
			item.numero_pedido != props.numero_pedido
	);
	const [danfesCliente, setDanfesCliente] = useState([]);

	const getRomaneiosEntrega = async () => {
		try {
			// let romaneios = [];
			// for await (let cargas of cargasSelecionadas) {
			// 	const data = await getData("entregas/carga", cargas.id);
			// 	romaneios.push(...data.data);
			// }

			setShowLoading((last) => ({
				...last,
				flag: false,
				style: "success",
				msg: "Danfe Salva",
			}));
			// dispatch(updateRomaneiosList(romaneios));
			handlerSimpleClose();
		} catch (error) {}
	};

	async function modifyPdf() {
		const pdfDoc = await PDFDocument.load(pdf);

		const pages = pdfDoc.getPages();
		const firstPage = pages[0];
		const { width, height } = firstPage.getSize();
		firstPage.drawText(`${props.cliente}`, {
			x: 100,
			y: height / 1.078,
			size: 8,
			color: rgb(0, 0, 0),
			//   rotate: degrees(-45),
		});
		firstPage.drawText(`${new Date().toLocaleDateString()}`, {
			x: 20,
			y: height / 1.078,
			size: 8,
			color: rgb(0, 0, 0),
			//   rotate: degrees(-45),
		});

		const pdfBytes = await pdfDoc.save();

		const bytes = new Uint8Array(pdfBytes);
		const blob = new Blob([bytes], { type: "application/pdf" });
		setDownloadPdf(bytes);

		const docUrl = URL.createObjectURL(blob);
		setPdfFile(docUrl);
	}

	useEffect(() => {
		modifyPdf();
	}, [pdf]);

	// useEffect(() => {
	//
	// },[danfesCliente])

	const renderPage = useCallback(
		(pageNum, pdf = pdfRef) => {
			pdf &&
				pdf.getPage(pageNum).then(function (page) {
					const viewport = page.getViewport({ scale: 1.3 });
					const canvas = canvasRef.current;
					const context = canvas.getContext("2d");
					var outputScale = window.devicePixelRatio || 1;
					canvas.width = Math.floor(viewport.width * outputScale);
					canvas.height = Math.floor(viewport.height * outputScale);
					canvas.style.width = Math.floor(viewport.width) + "px";
					canvas.style.height = Math.floor(viewport.height) + "px";

					var transform =
						outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

					var renderContext = {
						canvasContext: context,
						transform: transform,
						viewport: viewport,
					};
					page.render(renderContext);
				});
		},
		[pdfRef]
	);

	useEffect(() => {
		renderPage(currentPage, pdfRef);
	}, [pdfRef, currentPage, renderPage]);

	useEffect(() => {
		if (!pdfFile) return;
		const loadingTask = pdfjsLib.getDocument(pdfFile);
		loadingTask.promise.then(
			(loadedPdf) => {
				setPdfRef(loadedPdf);
				setNumberOfPages(loadedPdf.numPages);
				setLoading(false);
			},
			function (reason) {}
		);
	}, [pdfFile]);

	const nextPage = () =>
		pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

	const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

	const handlerSimpleClose = () => props.setShow((last) => ({ flag: false }));

	const [showSignPad, setShowSignPad] = useState({
		flag: false,
	});

	const blobToFile = (blob, fileName) => {
		blob.lastModifiedDate = new Date();
		blob.name = fileName;
		const file = new File([blob], `${fileName}.pdf`);
		return file;
	};

	const salvarPdf = async () => {
		setShowLoading((last) => ({
			...last,
			flag: true,
			style: "warning",
			msg: "Salvando...",
		}));
		const pdfDoc = await PDFDocument.load(pdf);
		refSignedFile.current = blobToFile(pdfDoc, `${props.numero_pedido}`, {
			type: "application/pdf",
		});

		dispatch(
			danfeController({
				action: "update",
				numero_pedido: props.numero_pedido,
				id_filial: props.id_filial,
				danfe_assinada: downloadPdf,
				status: "ASSINADA",
				cargas_id: props.cargas_id,
			})
		);

		for await (let DANFE_ASSINADA of danfesCliente) {
			dispatch(
				danfeController({
					action: "update",
					numero_pedido: DANFE_ASSINADA.numero_pedido,
					id_filial: DANFE_ASSINADA.id_filial,
					danfe_assinada: DANFE_ASSINADA.PDF_SIGNED_FINAL,
					status: "ASSINADA",
					cargas_id: DANFE_ASSINADA.cargas_id,
				})
			);
		}
		getRomaneiosEntrega();
	};

	const [showLoading, setShowLoading] = useState({
		flag: false,
		style: "dark",
		msg: "Salvar",
	});

	async function alterPdfCliente(DANFE, nomeCliente, numero_pedido) {
		const pdfDoc = await PDFDocument.load(encodeURI(DANFE));

		const pages = pdfDoc.getPages();
		const firstPage = pages[0];
		const { width, height } = firstPage.getSize();
		firstPage.drawText(`${nomeCliente}`, {
			x: 110,
			y: height / 1.07,
			size: 10,
			color: rgb(0, 0, 0),
			//   rotate: degrees(-45),
		});
		firstPage.drawText(`${new Date().toLocaleDateString()}`, {
			x: 10,
			y: height / 1.07,
			size: 10,
			color: rgb(0, 0, 0),
			//   rotate: degrees(-45),
		});

		const pdfBytes = await pdfDoc.save();

		const bytes = new Uint8Array(pdfBytes);

		setDanfesCliente((last) => [
			...last,
			{
				DANFE: encodeURI(DANFE),
				PDF_SIGNED: bytes,
				numero_pedido,
			},
		]);
	}

	useEffect(() => {
		if (!romaneiosDoCliente.length) return;
		romaneiosDoCliente.map((item) => {
			getDanfeVST(item.numero_pedido, item.id_filial, item.nome_fantasia);
		});
	}, []);

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex">
						{props.title} - {props.numero_pedido}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{}
					<div className="text-center">
						<h1>Pedidos do Mesmo Cliente</h1>
						{/* {JSON.stringify(props)} */}
						{romaneiosDoCliente &&
							romaneiosDoCliente.map((item) => item.numero_pedido).join(", ")}
						{!romaneiosDoCliente.length && <p>Nenhum outro encontrado</p>}
					</div>
					<Row style={{ justifyContent: "center", padding: "0" }}>
						<div
							style={{
								display: "flex",
								maxHeight: "75vh",
								overflow: "auto",
								marginTop: "20px",
								padding: 0,
								justifyContent: "center",
								position: "relative",
							}}
						>
							<div className="div-itens-sing-danfe">
								<i
									className="bi bi-caret-left-fill"
									onClick={prevPage}
									title="Próximo"
								></i>
								<i
									className="bi bi-caret-right-fill"
									onClick={nextPage}
									title="Voltar"
								></i>
								{loading ? (
									<div style={{ textAlign: "center" }}>
										Carregando <Spinner size="sm" animation="border" />
									</div>
								) : (
									<div
										className="text-center"
										style={{ fontSize: "1.3rem", margin: "0 10px" }}
									>
										{" "}
										{currentPage} / {numberPages}
									</div>
								)}
								<i
									className="bi bi-vector-pen"
									onClick={() => setShowSignPad({ flag: true })}
									title="Assinar"
								></i>
								{showLoading.flag ? (
									<LoadingNew
										show={showLoading.flag}
										style={showLoading.style}
										icone={""}
									/>
								) : (
									<i
										className="bi bi-send-plus"
										onClick={salvarPdf}
										title="Salvar"
									></i>
								)}
								{/* <LoadingNew show={showLoading.show} msg={showLoading.msg} style={showLoading.style} icone={''}/> */}
							</div>
							<canvas
								ref={canvasRef}
								style={{
									boxShadow: "#000000b8 0px 0px 24px inset",
									padding: "64px",
									backgroundColor: "#9f9292",
								}}
							></canvas>
						</div>
					</Row>

					{showSignPad.flag ? (
						<ModalPadAssinatura
							title={"Assinatura"}
							show={showSignPad.flag}
							setShow={setShowSignPad}
							setPdf={setPdf}
							pdf={props.pdf}
							danfesCliente={danfesCliente}
							setDanfesCliente={setDanfesCliente}
							romaneiosDoCliente={romaneiosDoCliente}
						/>
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
}
