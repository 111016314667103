import { createRef, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	apenasNumeros,
	createProdutosFromSeparacao,
	getAllData,
	numerosDecimal3,
	updateTable,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalErrorTotal from "./ModalErrorTotal";
import ModalError from "./ModalError";

export default function ModalSepararProdutos({
	msg,
	title,
	show,
	setShow,
	produtos,
	caminhao,
}) {
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});
	const [produtosAdd, setProdutosAdd] = useState([]);
	const [volumeSelecionado, setVolumeSelecionado] = useState(null);
	const [produtoSelecinado, setProdutoSelecionado] = useState(null);
	const [pesoVolume, setPesoVolume] = useState({
		peso: 0,
		volume: 0,
	});

	const [minDim, setMinDim] = useState({
		/* minimas dimensoes da embalagem de acordo com as maiores dimensoes dod produtos */
		altura: {
			showError: false,
			min: 0,
			max: caminhao.altura < 1 ? 4.4 : caminhao.altura,
			typed: 0,
		},
		comprimento: {
			showError: false,
			min: 0,
			max: caminhao.comprimento,
			typed: 0,
		},
		largura: {
			showError: false,
			min: 0,
			max: caminhao.largura,
			typed: 0,
		},
		peso: {
			showError: false,
			min: 0,
			max: caminhao.capacidade * 1000,
			typed: 0,
		},
	});

	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, show: false, msg: "" }));

	const numberFormated = (value) =>
		String(Number(value).toFixed(2)).replace(".", ",");

	const [error, setError] = useState({
		flag: false,
		msg: "",
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		setProdutosAdd((last) => [...last.filter((item) => item !== value)]);
	};

	const confirmaEmbalagem = async (e) => {
		e.preventDefault();

		setLoading((last) => ({
			...last,
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		}));

		if (
			Object.keys(minDim).filter(
				(key) => minDim[key].showError || !minDim[key].typed
			).length
		) {
			setTimeout(() => {
				setError({
					flag: true,
					msg: "Não é possivel separado produtos sem dimensões minimas exigidas!",
				});
				setLoading((last) => ({
					...last,
					isLoading: false,
					msg: "Erro",
					style: "danger",
				}));
				Object.keys(minDim).forEach((key) => {
					if (minDim[key].typed === 0) {
						setMinDim((last) => ({
							...last,
							[key]: {
								...last[key],
								showError: true,
							},
						}));
					}
				});
				setTimeout(() => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					}));
				}, 1000);
			}, 300);
			return;
		}
		// const data = new Date()
		const novoProduto = {
			...volumeSelecionado,
			altura: Number(minDim.altura.typed.replace(",", ".")),
			comprimento: Number(minDim.comprimento.typed.replace(",", ".")),
			largura: Number(minDim.largura.typed.replace(",", ".")),
			volume:
				Number(minDim.altura.typed.replace(",", ".")) *
				Number(minDim.comprimento.typed.replace(",", ".")) *
				Number(minDim.largura.typed.replace(",", ".")),
			peso: Number(minDim.peso.typed.replace(",", ".")),
			cargas_id: produtos[0].cargas_id,
		};

		if (novoProduto.qtde <= 0) {
			setLoading((last) => ({
				...last,
				isLoading: false,
				msg: "Salvar",
				style: "primary",
			}));
			return setError({
				flag: true,
				msg: {
					title: "Erro ao salvar produto",
					motivo: "Quantidade não especificada!",
				},
			});
		}
		if (!novoProduto.qr_sku.includes("-V")) {
			setLoading((last) => ({
				...last,
				isLoading: false,
				msg: "Salvar",
				style: "primary",
			}));
			return setError({
				flag: true,
				msg: {
					title: "Erro ao salvar produto",
					motivo: "QR_SKU não formulado corretamente. Contate o Suporte!",
				},
			});
		}

		await createProdutosFromSeparacao(novoProduto)
			.then((response) => {
				setLoading((last) => ({
					...last,
					msg: "Atualizando Produtos",
					style: "danger",
				}));
				updateTable("cargas/produtos/separador", novoProduto.cargas_id, {
					qtde: produtoSelecinado.qtde - novoProduto.qtde,
					peso: produtoSelecinado.peso - novoProduto.peso,
					qr_sku: produtoSelecinado.qr_sku,
				}).then((resp) => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						msg: "Atualizado",
						style: "success",
					}));
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				});
			})
			.catch((error) => {
				setError({ flag: true, msg: "Erro no banco de dados!" });
				setLoading((last) => ({
					...last,
					isLoading: false,
					msg: "Erro",
					style: "danger",
				}));
				setTimeout(() => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					}));
				}, 1000);
			});
	};

	const onChangeDimensions = (e, parametro) => {
		e.preventDefault();

		const value = Number(
			numerosDecimal3(apenasNumeros(e.target.value)).replace(",", ".")
		);
		let valueAltura = minDim.altura.max < 1 ? 4.4 : minDim.altura.max;

		if (parametro === "altura" && value > valueAltura) {
			setMinDim((last) => ({
				...last,
				altura: { ...last.altura, showError: true },
			}));
		}
		if (parametro === "largura" && value > minDim.largura.max) {
			setMinDim((last) => ({
				...last,
				largura: { ...last.largura, showError: true },
			}));
		}
		if (parametro === "comprimento" && value > minDim.comprimento.max) {
			setMinDim((last) => ({
				...last,
				comprimento: { ...last.comprimento, showError: true },
			}));
		}

		setMinDim((last) => ({
			...last,
			[parametro]: {
				...last[parametro],
				showError:
					last[parametro].max >= value && value >= last[parametro].min
						? false
						: true,
				typed: numerosDecimal3(apenasNumeros(e.target.value)),
			},
		}));
	};

	/* debugar dimensoes minimas */
	useEffect(() => {
		setPesoVolume((last) => ({
			...last,
			volume:
				Number(String(minDim.altura.typed).replace(",", ".")) *
				Number(String(minDim.comprimento.typed).replace(",", ".")) *
				Number(String(minDim.largura.typed).replace(",", ".")),
		}));
	}, [minDim]);

	useEffect(() => {
		if (!produtoSelecinado) {
			setVolumeSelecionado("");
			return;
		}

		getAllData("cargas/funcionarios/produtos", {
			qr_sku: produtoSelecinado.qr_sku,
		})
			.then((response) => {
				setVolumeSelecionado({
					...produtoSelecinado,
					qr_sku: `${produtoSelecinado.qr_sku}-V${response.data.length}`,
					qtde: 0,
					id_volume: response.data.length,
				});
			})
			.catch((error) => {
				setError({
					msg: { title: "Erro na consulta dos Produtos.", ...error },
					flag: true,
				});
			});
	}, [produtoSelecinado?.qr_sku]);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row className="align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Produtos:</Form.Label>
							</Col>
							<Col col="6">
								<Form.Select
									type="text"
									onChange={(e) =>
										setProdutoSelecionado(
											produtos.find(
												(item) =>
													!item.embalado && item.qr_sku === e.target.value
											)
										)
									}
								>
									<option value=""></option>
									{produtos
										.filter((item) => item.id_volume == 0)
										.map((item, index, self) => {
											const pedidoHabilitado = self.find(
												(item2) => item2.qr_sku == produtosAdd[0]
											)?.numero_pedido;
											return (
												<option
													key={index}
													value={item.qr_sku}
													disabled={
														produtosAdd.indexOf(item.qr_sku) >= 0 ||
														(pedidoHabilitado &&
															pedidoHabilitado != item.numero_pedido)
															? true
															: false
													}
													style={{
														backgroundColor:
															produtosAdd.indexOf(item.qr_sku) >= 0
																? "#dc3545"
																: "inherit",
														color:
															produtosAdd.indexOf(item.qr_sku) >= 0
																? "white"
																: "inherit",
													}}
												>
													{item.numero_pedido} - {item.codigo} -
													{item.descricao.trim()}
												</option>
											);
										})}
								</Form.Select>
							</Col>
						</Row>
						<Row className="align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Código:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									name="filterKeyword"
									className="form-control-input"
									placeholder="Selecione um Produto"
									disabled
									value={volumeSelecionado?.qr_sku ?? ""}
								/>
							</Col>
						</Row>
						<Row className="align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Qtde Original:</Form.Label>
							</Col>
							<Col sm="3">
								<Form.Control
									type="number"
									value={produtoSelecinado?.qtde ?? 0}
									disabled
								/>
							</Col>
							<Col sm="3">
								<Form.Label>Qtde Separada:</Form.Label>
							</Col>
							<Col sm="3">
								<Form.Control
									type="text"
									value={volumeSelecionado?.qtde ?? 0}
									onChange={(e) => {
										const value = String(e.target.value).replace(/\D/, "");
										if (Number(value) > produtoSelecinado?.qtde) {
											setError({
												flag: true,
												msg: {
													erro: "Valor Maximo Excedido",
													motivo:
														"Digite um valor menor que o valor disponivel no produto",
													solucao: `Digite um valor menor que ${produtoSelecinado?.qtde}`,
												},
											});
											return;
										}

										setVolumeSelecionado((last) => ({ ...last, qtde: value }));
									}}
								/>
							</Col>
						</Row>
						<Row className="justify-content-end mb-2">
							<Col sm="9">
								<div className="d-flex" style={{ overflow: "auto" }}>
									{produtosAdd.map((item, index) => (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">{item}</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									))}
								</div>
							</Col>
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Altura:</Form.Label>
							</Col>
							<Col sm="9">
								<Form.Control
									type="text"
									className="Form-control mb-2"
									maxLength={7}
									min={minDim.altura.min}
									value={minDim.altura.typed}
									onChange={(e) => onChangeDimensions(e, "altura")}
								/>
							</Col>
							{minDim.altura.showError ? (
								<Col sm="9">
									<Form.Text className="text-muted">
										{minDim.altura.min
											? `Mínimo ${numberFormated(
													minDim.altura.min
											  )} [m], Máximo ${numberFormated(minDim.altura.max)} [m]`
											: `Produtos sem altura! Máximo ${numberFormated(
													minDim.altura.max
											  )} [m]`}
									</Form.Text>
								</Col>
							) : (
								""
							)}
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Comprimento:</Form.Label>
							</Col>
							<Col sm="9">
								<Form.Control
									type="text"
									className="Form-control mb-2"
									maxLength={7}
									min={minDim.comprimento.min}
									value={minDim.comprimento.typed}
									onChange={(e) => onChangeDimensions(e, "comprimento")}
								/>
							</Col>
							{minDim.comprimento.showError ? (
								<Col sm="9">
									<Form.Text className="text-muted">
										{minDim.comprimento.min
											? `Mínimo ${numberFormated(
													minDim.comprimento.min
											  )} [m], Máximo ${numberFormated(
													minDim.comprimento.max
											  )} [m]`
											: `Produtos sem comprimento! Máximo ${numberFormated(
													minDim.comprimento.max
											  )} [m]`}
									</Form.Text>
								</Col>
							) : (
								""
							)}
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Largura:</Form.Label>
							</Col>
							<Col sm="9">
								<Form.Control
									type="text"
									className="Form-control mb-2"
									maxLength={7}
									min={minDim.largura.min}
									value={minDim.largura.typed}
									onChange={(e) => onChangeDimensions(e, "largura")}
								/>
							</Col>
							{minDim.largura.showError ? (
								<Col sm="9">
									<Form.Text className="text-muted">
										{minDim.largura.min
											? `Mínimo ${numberFormated(
													minDim.largura.min
											  )} [m], Máximo ${numberFormated(
													minDim.largura.max
											  )} [m]`
											: `Produtos sem largura! Máximo ${numberFormated(
													minDim.largura.max
											  )} [m]`}
									</Form.Text>
								</Col>
							) : (
								""
							)}
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Peso:</Form.Label>
							</Col>
							<Col sm="9">
								<Form.Control
									type="text"
									className="Form-control mb-2"
									maxLength={8}
									min={minDim.peso.min}
									value={minDim.peso.typed}
									onChange={(e) => onChangeDimensions(e, "peso")}
								/>
							</Col>
							{minDim.peso.showError ? (
								<Col sm="9">
									<Form.Text className="text-muted">
										{minDim.peso.min
											? `Mínimo ${numberFormated(
													minDim.peso.min
											  )} [m], Máximo ${numberFormated(minDim.peso.max)} [m]`
											: `Produtos sem peso! Máximo ${numberFormated(
													minDim.peso.max
											  )} [m]`}
									</Form.Text>
								</Col>
							) : (
								""
							)}
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Resumo:</Form.Label>
							</Col>
							<Col sm="4">
								<Form.Text className="text-muted d-flex flex-column justify-content-center align-items-center">
									<span>Peso da Embalagem</span>
									<span>
										{numberFormated(
											String(minDim?.peso.typed).replace(",", ".")
										)}{" "}
										[ kg ]
									</span>
								</Form.Text>
							</Col>
							<Col sm="5">
								<Form.Text className="text-muted d-flex flex-column justify-content-center align-items-center">
									<span>Volume da Embalagem</span>
									<span>
										{numberFormated(pesoVolume.volume)} [ m<sup>3</sup> ]
									</span>
								</Form.Text>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer style={{ flexFlow: "row" }}>
					<Button
						variant={loading.style}
						onClick={confirmaEmbalagem}
						disabled={
							loading.style !== "primary" || !volumeSelecionado ? true : false
						}
					>
						{loading.isLoading ? <Loading msg={loading.msg} /> : loading.msg}
					</Button>
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>

			{error ? (
				<ModalError
					show={error.flag}
					setShow={setError}
					msg={error.msg}
					title={"Error!"}
				/>
			) : (
				""
			)}
		</>
	);
}
