import { Fragment } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import './ControlePonto.scss'
import { useSelector } from "react-redux";

export default function NavbarPonto(){
    const user = useSelector(state => state.userSlice)
    return (
        <Fragment>
            <Nav variant='tabs'>
                {
                    user.tipo.toLowerCase() === 'empresa' ? 
                    <Nav.Item>
                        <NavLink className="nav-link" to='consultar'>Consultar Ponto</NavLink>
                    </Nav.Item>
                    :
                    ""
                }
                {
                    user.tipo.toLowerCase() != 'empresa' ? 
                        <Nav.Item>
                            <NavLink className="nav-link" to='cadastrar'>Lançar Pontos</NavLink>
                        </Nav.Item>
                    : 
                    ''
                }
                {
                    user.tipo.toLowerCase() != 'empresa' ? 
                        <Nav.Item>
                            <NavLink className="nav-link" to='relatorio'>Relatório</NavLink>
                        </Nav.Item>
                    : 
                    ''
                }
                {
                    user.tipo.toLowerCase() === 'empresa' ? 
                        <Nav.Item>
                            <NavLink className="nav-link" to='relatorio-admin'>Relatório Gerencial</NavLink>
                        </Nav.Item>
                    : 
                    ''
                }
                    
            </Nav>
            <Outlet/>
        </Fragment>
    )
}






