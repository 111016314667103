import { Fragment } from "react"
import NavbarTop from "../navbar/NavbarTop"
// import Sidebar from "../sidebar/Sidebar"
import './Template.scss'

export default function Template({children}){
    
    return(
        <Fragment>
            <NavbarTop children={children}/>
        </Fragment>
    )
}

