import { useState } from "react"
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { updateTable } from "../../services/Servicos"

import './Modal.scss'


export default function ModalErrorDescarregameto({ msg, title, show, setShow}) {

    const navigate = useNavigate()
    const placaCaminhao = useSelector(state => state.userSlice.caminhaoSelecionado.caminhao_placa)
    const produtosCarregados = useSelector(state => state.userSlice.produtosList.data).filter(item => item.descarregado)
    const [loadingFinalizar, setLoadingFinalizar] = useState({
        flag: false,
        style:'primary',
        msg:'Salvar Parcial'
    })

    const onClickFinalizarParcial = async () =>{
        setLoadingFinalizar({
            flag: true,
            style: 'warning',
            msg: 'Aguarde'
        })

        const objSender = {
            status: 12,
            pedidos: null,
            local: 'descarregamento'
        }

        for await (let produto of produtosCarregados){
            let produtoAtt = {
                    caminhao_carregamento: placaCaminhao,
                    user_descarregamento: produto.user_descarregamento,
                    data_descarregamento: produto.data_descarregamento,
                    descarregado: produto.descarregado,
    
                }    
            
            await updateTable('carga/caminhoneiro/produto/carregado', produto.id, produtoAtt)
                .then(resp => {
                    // console.log({
                    //     flag: false,
                    //     msg: 'Atualizado com Sucesso',
                    //     style:'success'
                    // })
                })
                .catch(error => {
                    // let msg = null
                    console.log({
                        flag: false,
                        msg: 'Error!!!!',
                        style:'warning',
                    })
                })
        }
        

        updateTable('cargas/carregada/caminhoneiro', produtosCarregados[0].cargas_id, objSender)
            .then(resp => {
                setLoadingFinalizar(last => ({
                        ...last, 
                        flag:false
                        , msg:"Carga Finalizada com Sucesso!"
                        , style:'success'
                    }))

                setTimeout(() => {
                     
                    navigate('/map')

                }, 2000)
            })
            .catch(error => {
                setLoadingFinalizar({
                    flag: false,
                    msg: 'ERROR',
                    style:'danger',
                })
            })

        
        // setTimeout(()=>{
        //     setLoadingFinalizar(last => ({...last,
        //         flag:false,
        //         style: 'success',
        //         msg: 'Produtos Carregados!'
        //     }))
        //     window.location.reload()
        // },2000)
    }
    
    const handlerSimpleClose = () => setShow(last => ({...last, flag: false}))

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msg}
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{width:'100%'}}>
                        <Col>
                            <Button variant={loadingFinalizar.style} onClick={onClickFinalizarParcial}>
                                {loadingFinalizar.msg} {loadingFinalizar.flag ?<Spinner animation="border" variant="black" size='sm' /> : ""}
                            </Button>
                        </Col>
                        <Col>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}
