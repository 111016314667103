import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import ModalErrorPretty from "../modal/ModalErrorPretty";
import { getErrorMessage, getNewId, unicos } from "../../services/helpers";
import { getAllData, getCorretData, getStatusCarga, getStatusPedidos } from "../../services/Servicos";
import CardMotorista from "./CardMotorista";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import ModalPdfMotorista from "./ModalPdfMotorista";
import ExportToExcel from "./ExportToExcel";

export default function MotoristasRoute() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState({ show: false, message: "" });
	const [editarPdf, setEditarPdf] = useState({ show: false });
	const [motoristas, setMotoristas] = useState([]);
	const [filial, setFilial] = useState("");
	const dateNow = new Date();
	const [dia, mes, ano] = [
		dateNow.getDate(),
		dateNow.getMonth() + 1,
		dateNow.getFullYear(),
	];
	const [props, setProps] = useState(null)
	const [date, setDate] = useState({
		data_i: `${ano}-${String(mes).padStart(2, "0")}-01`,
		data_f: `${ano}-${String(mes).padStart(2, "0")}-${String(dia).padStart(2,"0")}`,
		status_i: '1',
		status_f: '24',
	});

	const [btnClicked, setBtnClicked] = useState(0);
	const user = useSelector((state) => state.userSlice);
	const filiais = user.filiaisList?.data;
	let mainJsx = <Loading msg={"Carregando"} color={"black"} />;

	function handlerSelectFilial(e) {
		if (e.target.value == "0") return setFilial(null);
		setFilial(e.target.value);
	}

	function handlerSelectStatus(e) {
		if (e.target.value == "0") {
			setDate(last => ({
				...last,
				status_i: '1',
				status_f:'24'
			}))
		}else if (e.target.value == "1") {
			setDate(last => ({
				...last,
				status_i: '1',
				status_f:'12'
			}))
		}else if (e.target.value == "2") {
			setDate(last => ({
				...last,
				status_i: '13',
				status_f:'24'
			}))
		}
	}
	
	function handlerSelectDate(e) {
		setDate(last => ({
			...last,
			[e.target.name]: e.target.value,
		}))
	}

	function handlerPesquisar() {
		setBtnClicked((last) => last + 1);
	}

	useEffect(() => {
		setLoading(true);
		async function getData() {
			try {
				let data = [];
				if (!filial) {
					const dataF = await getAllData("dashboard/motoristas", date);
					data = dataF.data;
				} else {
					const dataF = await getAllData("dashboard/motoristas", {
						...date,
						id_filial: filial,
					});
					data = dataF.data;
				}

				setMotoristas(
					data
						?.sort((a, b) => a.cargas_entregues - b.cargas_entregues)
						?.reverse()
						.map((item) => ({ ...item, uuid: getNewId(), loading: false }))
				);
			} catch (error) {
				setError({
					show: true,
					message: getErrorMessage(error),
				});
			} finally {
				setTimeout(() => {
					setLoading(false);
				}, 500);
			}
		}

		getData();
	}, [btnClicked]);

	const getInfo = async (p) => {
		try {
			const [cargas] = await Promise.all([
				getAllData("dashboard/motoristas-cargas-pedidos", {
					stringfilters: ` and caminhoneiro = '${p.cpf}' and emissao_carga between '${p.data_i}' and '${p.data_f}' and status_carga between '${p.status_i}' and '${p.status_f}'`,
				}),
			]);
			const obj = {
				...p,
				cargas: unicos(cargas.data, "id").map((item) => ({
					...item,
					status_carga: getStatusCarga(item.status_carga),
					uuid: getNewId(),
				})),
				pedidos: unicos(cargas.data, "romaneio").map((item) => ({
					DATA: getCorretData(item.emissao_carga),
					'STATUS DA CARGA': getStatusCarga(item.status_carga),
					'CARGA B-LOG': item.id,
					'CAMINHÃO': item.caminhao,
					'FILIAL CARGA': filiais.find(fili => fili.idFilial == item.filial_carga)?.identificacaoInterna,
					CLIENTE: item.cliente,
					'ROMANEIO / PEDIDO': `${item.romaneio} / ${item.numero_pedido}`,
					'STATUS DO PEDIDO': getStatusPedidos(item.status_pedido),
					VOLUME: item.volume_pedido,
					PESO: item.peso_nf,
					'FILIAL PEDIDO': filiais.find(fili => fili.idFilial == item.filial_pedido)?.identificacaoInterna
					// uuid: getNewId(),
				})),
			};
			setProps(obj)
			handlerOnClickLoading(p.uuid)
		} catch (error) {}
	};
	useEffect(() => {
		if(!props) return
	}, [props]);

	function handlerOnClickVerPdf(dadosMotorista) {
		setEditarPdf({ show: true, ...dadosMotorista, ...date });
	}
	
	function handlerOnClickVerExcel(dadosMotorista) {
		getInfo({ ...dadosMotorista, ...date });

	}
	
	function handlerOnClickLoading(uuidMotoristas, loading) {
		const motoristasUpdate = [...motoristas]
		const indexMot = motoristasUpdate.findIndex(o => o.uuid === uuidMotoristas)
		motoristasUpdate[indexMot].loading = loading
		setMotoristas(motoristasUpdate)
	}
	
	if (!loading) {
		mainJsx = (
			<div className="d-flex flex-row flex-wrap justify-content-center column-gap-3">
				{motoristas.map((motorista) => (
					<CardMotorista
						key={motorista.uuid}
						onClickVerPdf={handlerOnClickVerPdf}
						onClickVerExcel={handlerOnClickVerExcel}
						onClickLoading={handlerOnClickLoading}
					>
						{motorista}
					</CardMotorista>
				))}
				{
					props ? 
					<ExportToExcel tableName={props.motorista} reference={props?.pedidos} />
					: ""
				}
			</div>
		);
	}

	return (
		<div>
			<div className="d-flex flex-row flex-wrap column-gap-3 ">
				<div style={{ width: "250px" }} className="my-2">
					<Form.Group className="d-flex flex-row align-items-center column-gap-2">
						<Form.Label className="m-0" style={{ whiteSpace: "nowrap" }}>
							Periodo Inicial:
						</Form.Label>
						<Form.Control
							type="date"
							defaultValue={date.data_i}
							name="data_i"
							onChange={handlerSelectDate}
						/>
					</Form.Group>
				</div>
				<div style={{ width: "250px" }} className="my-2">
					<Form.Group className="d-flex flex-row align-items-center column-gap-2">
						<Form.Label className="m-0" style={{ whiteSpace: "nowrap" }}>
							Periodo Final:
						</Form.Label>
						<Form.Control
							type="date"
							defaultValue={date.data_f}
							name="data_f"
							onChange={handlerSelectDate}
						/>
					</Form.Group>
				</div>
				<div style={{ width: "200px" }} className="my-2">
					<Form.Group className="d-flex flex-row align-items-center column-gap-2">
						<Form.Label className="m-0">Status:</Form.Label>
						<Form.Select onChange={handlerSelectStatus}>
							<option value={"0"}>TODOS OS STATUS</option>
							<option value={"1"}>EM PROCESSAMENTO</option>
							<option value={"2"}>ENTEGUES</option>
						</Form.Select>
					</Form.Group>
				</div>
				<div style={{ width: "200px" }} className="my-2">
					<Form.Group className="d-flex flex-row align-items-center column-gap-2">
						<Form.Label className="m-0">Filiais:</Form.Label>
						<Form.Select onChange={handlerSelectFilial}>
							<option value={"0"}>TODAS FILIAIS</option>
							{filiais.map((item) => (
								<option value={item.idFilial} key={item.identificacaoInterna}>
									{item.identificacaoInterna}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</div>
				<div style={{ width: "200px" }} className="my-2">
					<button
						className="btn-dark button-report d-flex"
						style={{ width: "150px", height: "35px", borderRadius: "5px" }}
						variant="dark"
						onClick={handlerPesquisar}
					>
						<i class="bi bi-search" /> Pesquisar
					</button>
				</div>
			</div>
			{mainJsx}
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			{editarPdf.show ? (
				<ModalPdfMotorista setShow={setEditarPdf} {...editarPdf} />
			) : (
				""
			)}
		</div>
	);
}
