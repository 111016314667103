import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
	getData,
	isMobile,
	verificaTipoUsuario,
} from "../../services/Servicos";

import "./Modal.scss";
import PDFFile, { PDFFileDownload } from "../pdf/PDFFile";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

export default function ModalPDF(props) {
	const canvasRef = useRef();
	pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

	const [pdfRef, setPdfRef] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [numberPages, setNumberOfPages] = useState(1);

	const [loading, setLoading] = useState(true);
	const [propsEdit, setProps] = useState({ ...props });
	const [pdfFile, setPdfFile] = useState(null);
	const [pdfFileFull, setPdfFileFull] = useState(null);
	const [isMobileFlag, setIsMobileFlag] = useState(null);
	const user = useSelector((state) => state.userSlice);

	const printPDF = () => {
		var obj = pdf(<PDFFile {...propsEdit} />)
			.toBlob()
			.then((blob) => {
				var blob_ = new Blob([blob], { type: "application/pdf" });
				const docUrl = URL.createObjectURL(blob_);
				if (isMobile(String(window.navigator.userAgent))) {
					setPdfFile(docUrl);
				} else {
					setPdfFileFull(docUrl);
					setLoading(false);
				}
			});
	};

	const getInfo = async () => {
		const romaneiosGet = await getData(
			verificaTipoUsuario(user) + "cargas/pedidos/carga",
			props.cargas_id
		);
		const despesas = await getData(
			verificaTipoUsuario(user) + "despesas/carga",
			props.cargas_id
		);
		const diarios = await getData(
			verificaTipoUsuario(user) + "diarios/carga",
			props.cargas_id
		);
		setProps((last) => ({
			...last,
			romaneios: romaneiosGet.data,
			despesas: despesas.data.filter(
				(item) =>
					item.tipo_lancamento === "Fechamento Financeiro" ||
					item.aprovacao_financeiro === "APROVADO"
			),
			diario: diarios.data,
		}));
	};

	useEffect(() => {
		getInfo();
	}, []);

	useEffect(() => {
		if (!propsEdit.romaneios) return;

		setIsMobileFlag(isMobile(String(window.navigator.userAgent)));
		printPDF();
	}, [propsEdit]);

	const renderPage = useCallback(
		(pageNum, pdf = pdfRef) => {
			pdf &&
				pdf.getPage(pageNum).then(function (page) {
					const viewport = page.getViewport({ scale: 1.3 });
					const canvas = canvasRef.current;
					const context = canvas.getContext("2d");
					var outputScale = window.devicePixelRatio || 1;
					canvas.width = Math.floor(viewport.width * outputScale);
					canvas.height = Math.floor(viewport.height * outputScale);
					canvas.style.width = Math.floor(viewport.width) + "px";
					canvas.style.height = Math.floor(viewport.height) + "px";

					var transform =
						outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

					var renderContext = {
						canvasContext: context,
						transform: transform,
						viewport: viewport,
					};
					page.render(renderContext);
				});
		},
		[pdfRef]
	);

	useEffect(() => {
		renderPage(currentPage, pdfRef);
	}, [pdfRef, currentPage, renderPage]);

	useEffect(() => {
		if (!pdfFile) return;
		const loadingTask = pdfjsLib.getDocument(pdfFile);
		loadingTask.promise.then(
			(loadedPdf) => {
				setPdfRef(loadedPdf);
				setNumberOfPages(loadedPdf.numPages);
				setLoading(false);
			},
			function (reason) {}
		);
	}, [pdfFile]);

	const nextPage = () =>
		pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

	const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false, msg: "" }));

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex">
						<span style={{ fontSize: "1.5rem" }}>
							Carga N°{props.id} - {props.nome} {`->`}
						</span>
						<Button variant="primary" disabled={loading}>
							{loading ? (
								"Carregando"
							) : (
								<PDFDownloadLink
									document={<PDFFileDownload {...propsEdit} />}
									fileName={
										props.status === 13
											? `Fechamento_Carga_N${props.cargas_id}.pdf`
											: `Carga_N${props.cargas_id}.pdf`
									}
									style={{ color: "white", padding: "inherit" }}
								>
									Baixar
								</PDFDownloadLink>
							)}
						</Button>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!isMobileFlag ? (
						loading ? (
							<div style={{ textAlign: "center" }}>
								Carregando <Spinner size="sm" animation="border" />
							</div>
						) : (
							""
						)
					) : (
						<Row style={{ justifyContent: "center", alignItems: "center" }}>
							<Col sm="4" md="2">
								<Button variant="info" onClick={prevPage}>
									<i className="bi bi-caret-left-fill"></i> Anterior
								</Button>
							</Col>
							<Col sm="4" md="2">
								{loading ? (
									<div style={{ textAlign: "center" }}>
										Carregando <Spinner size="sm" animation="border" />
									</div>
								) : (
									<div className="text-center" style={{ fontSize: "1.3rem" }}>
										{" "}
										{currentPage} / {numberPages}
									</div>
								)}
							</Col>
							<Col sm="4" md="2">
								<Button variant="info" onClick={nextPage}>
									Próximo <i className="bi bi-caret-right-fill"></i>
								</Button>
							</Col>
						</Row>
					)}
					<Row style={{ justifyContent: "center", padding: "0" }}>
						{!isMobileFlag ? (
							<iframe src={pdfFileFull} style={{ height: "100vh" }}></iframe>
						) : (
							<div
								style={{
									display: "flex",
									maxHeight: "75vh",
									overflow: "auto",
									marginTop: "20px",
									padding: 0,
									justifyContent: "center",
								}}
							>
								<canvas
									ref={canvasRef}
									style={{
										boxShadow: "#000000b8 0px 0px 24px inset",
										padding: "64px",
										backgroundColor: "#9f9292",
									}}
								></canvas>
							</div>
						)}
					</Row>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
}
