import { useEffect, useState } from "react"
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { cancelarManifestoFunc, encerrarManifestoFunc, getManifestoId, getManifestoPDF, getManifestoStatus, savalDanfeAssinada, setOnline } from "../../services/Servicos"

import './Modal.scss'
import ModalMontaManifesto from "./ModalMontaManifesto"
import ModalError from "./ModalError"
import ModalVisualizarPDFComputer from "./ModalVisualizarPDFComputer"
import ModalVisualizarDanfe from "./ModalVisualizarDanfe"
import axios from "axios"
import ModalManifesto from "./ModalManifesto"
import ModalVisualizarPDF from "./ModalVisualizarPDF"
import ModalVisualizarManifesto from "./ModalVisualizarManifesto"
import ModalPDF_AssinarDanfe from "./ModalPDF_AssinarDanfe"
import ModalPDF_VerDanfe from "./ModalPDF_VerDanfe"
import { danfeController } from "../../redux/slicers/carga"
import ModalErrorPretty from "./ModalErrorPretty"
import ModalSuccessPretty from "./ModalSuccessPretty"


export default function ModalControleDanfe(props) {

    const user = useSelector(state => state.userSlice)
    const danfe = useSelector(state => state.cargaSlicer.listasDanfes).find(item => item.numero_pedido == props.numero_pedido)
    const danfes = useSelector(state => state.cargaSlicer.listasDanfes)
    const romaneios = useSelector(state => state.userSlice.romaneiosList.data)
    let fotos = useSelector(state => state.cargaSlicer.listasFotos)
    const dispatch = useDispatch()
    const [assinarDocumento, setAssinarDocumento] = useState({
        flag: false
    })

    const [loadingEnviar, setLoadingEnviar] = useState({
        flag: false,
        msg:'Enviar Danfe'
    })
    const [loadingStatus, setLoadingStatus] = useState({
        location: 'pdf', flag: true
    })
    
    const [error, setError] = useState({ show: false, message:'' })
    const [success, setSuccess] = useState({ show: false, message: '' })
    const [isOnline, setIsOnline] = useState(true)
    
    const [verPDF, setVerPDF] = useState({
        flag: false,
        PDF: '',
        title:""
    }) 
    
    const handlerSimpleClose = () => {
        props.setShow({ flag: false })
    }


    const handlerAssinarDanfe = () => {

        
        
        if (danfe.status == 'ASSINADA') return setError({ show: true, message: 'Danfe JÁ Assinada' })
        if (!props.foto && !fotos.find(item => item.romaneio == props.romaneio)) return setError({ show: true, message: 'Impossivel Assinar Danfe sem Foto do produto Entregue' })
            
        let pdfArray = danfe.danfe
        if (typeof danfe.danfe == 'object') {
            let array = []
            Object.keys(danfe.danfe).forEach(key => array.push(danfe.danfe[key]))
            pdfArray = new Uint8Array(array)
        } 

        setAssinarDocumento({
            flag: true,
            PDF: pdfArray,
            cliente: props.nome_fantasia,
            numero_pedido: props.numero_pedido,
            id_filial: props.id_filial,
            cpf_cnpj: props.cpf_cnpj,
            cargas_id: props.cargas_id
        })
    }

    const handlerEnviarDanfe = async () => {
        if(!isOnline) return setError({ show: true, message:'Você esta sem intertet. Tente novamente mais tarde.' })
        if(!danfes.find(item => item.status == 'ASSINADA')) return setError({ show: true, message: 'Nenhuma Danfe necessita ser enviada.' })
        setLoadingEnviar({
            flag: true, msg:'Salvando'
        })

        const blobToFile = (blob, fileName) => {

            blob.lastModifiedDate = new Date();
            blob.name = fileName;
            const file = new File([blob], `${fileName}.pdf`)
            return file ;
        }

        for await (let danfe of danfes.filter(item => item.status == 'ASSINADA')) {
            
            const blob = new Blob([danfe.danfe_assinada], { type: "application/pdf" })


            try {
                await savalDanfeAssinada({
                    file: blobToFile(blob, `${danfe.numero_pedido}`),
                    teste: new Date().toLocaleTimeString(),
                    cargas_id: danfe?.cargas_id,
                    numero_pedido: danfe.numero_pedido,
                    caminhoneiro: user.cpf,
                    caminhao: user.caminhaoSelecionado.caminhao_placa
                })
                dispatch(
                    danfeController({
                        action: 'update_status',
                        numero_pedido: danfe.numero_pedido,
                        id_filial: danfe.id_filial,
                        status:'ENVIADA'
                    })
                )
            } catch (error) {
                setError({ show: true, message: 'Tentativa de envio de Danfe para o DB.' })
                
            }

            
          
        
        }
        
        setSuccess({ show: true, message: 'Todas as Danfes foram Salvas' })
        setTimeout(() => {
            window.location.reload()
        },750)
    }   

    useEffect(() => {
        //online
        function toggleOnline() {
          setIsOnline(true)
        }
        function toggleOffline() {
          setIsOnline(false)
        }
        console.log()
        window.addEventListener('online', toggleOnline)
        window.addEventListener('offline', toggleOffline)
        setIsOnline(navigator.onLine)
        return () => {
          window.removeEventListener('online', toggleOnline)
          window.removeEventListener('offline', toggleOffline)
    
        }
      }, [])
    return (
        <>
            <Modal
                show={props.show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Controle de Danfe, Pedido N° <b><i style={{fontSize:'inherit'}}>{ props.numero_pedido }</i></b></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding:'70px 30px'}}>
                    <Row className="justify-content-center">
                        <Col sm='12' md='6' lg='3'>
                            <Card className="card-manifesto" onClick={handlerAssinarDanfe}>
                                <span style={{position:'relative'}}>
                                    <i className="bi bi-file-earmark-pdf" style={{fontSize:'3rem', opacity:'0.4', color:'#347d68', filter:'blur(1.5px)'}}></i>
                                    <i className="bi bi-vector-pen" style={{position: 'absolute', left:'-15px', bottom:'30px', fontSize:'2rem'}}></i>
                                </span>
                                <Card.Title>Assinar Danfe</Card.Title>
                            </Card>
                        </Col>
                        {!isOnline ? "" :
                            <Col sm='12' md='6' lg='3'>
                                <Card className={`card-manifesto active-animation-card-manifesto 
                            ${danfe?.status === 'ASSINADA' && !props.danfe ? "active-animation-warning" : ""}
                            ${danfe?.status === 'NÃO ASSINADA' && !props.danfe ? "active-animation-danger" : ""}
                            ${danfe?.status === 'ENVIADA' && !props.danfe ? "active-animation-success" : ""}
                            ${!danfe?.status && !props.danfe ? "active-animation-dark" : ""}
                            ${props.danfe ? "active-animation-success" : ""}
                            `}
                                    data-before={!danfe?.status && !props.danfe ? 'NÃO ENVIADO' : (props.danfe ? 'ENVIADO' : danfe?.status)}
                                    onClick={() => {
                                        handlerEnviarDanfe()
                                    }}
                                >
                                    <span style={{ position: 'relative' }}>
                                        <i className="bi bi-file-earmark-pdf" style={{ fontSize: '3rem', opacity: '0.4', color: '#347d68', filter: 'blur(1.5px)' }}></i>
                                        <i className="bi bi-send-check" style={{ position: 'absolute', left: '-15px', bottom: '30px', fontSize: '2rem' }}></i>
                                    </span>
                                    <Card.Title>{loadingEnviar.msg} {loadingEnviar.flag ? <Spinner animation="border" size='sm' /> : ""}</Card.Title>
                                </Card>
                            </Col>
                        }
                        
                        <Col sm='12' md='6' lg='3'>
                            <Card className="card-manifesto"
                                onClick={() => {
                                    if (danfe.status !== 'ASSINADA') return setError({ show: true, message: 'Danfe ainda não Assinada' })
                                    let pdfArray = danfe.danfe_assinada
                                    if (typeof danfe.danfe_assinada == 'object') {
                                        let array = []
                                        Object.keys(danfe.danfe_assinada).forEach(key => array.push(danfe.danfe_assinada[key]))
                                        pdfArray = new Uint8Array(array)
                                    } 
                                    setVerPDF({
                                        flag: true,
                                        PDF: pdfArray,
                                        title:'Danfe Assinada'
                                    })
                                }}
                            >
                                <span style={{position:'relative'}}>
                                    <i className="bi bi-file-earmark-pdf" style={{fontSize:'3rem', opacity:'0.4', color:'#347d68', filter:'blur(1.5px)'}}></i>
                                    <i className="bi bi-check" style={{position: 'absolute', left:'-15px', bottom:'30px', fontSize:'2rem'}}></i>
                                </span>
                                <Card.Title>Ver Danfe Assinada{loadingStatus.location == 'encerrar' && loadingStatus.flag ? <Spinner animation="border" size='sm' />: ""}</Card.Title>
                            </Card>
                        </Col>
                        <Col sm='12' md='6' lg='3'>
                            <Card className="card-manifesto"
                                onClick={() => {
                                    let pdfArray = danfe.danfe
                                    if (typeof danfe.danfe == 'object') {
                                        let array = []
                                        Object.keys(danfe.danfe).forEach(key => array.push(danfe.danfe[key]))
                                        pdfArray = new Uint8Array(array)
                                    } 
                                    setVerPDF({
                                        flag: true,
                                        PDF:  pdfArray,
                                        title:'Danfe Assinada'
                                    })
                                }}
                            >
                                <span style={{position:'relative'}}>
                                    <i className="bi bi-file-earmark-pdf" style={{fontSize:'3rem', opacity:'0.4', color:'#347d68', filter:'blur(1.5px)'}}></i>
                                    <i className="bi bi-bookmark-check-fill" style={{position: 'absolute', left:'-15px', bottom:'30px', fontSize:'2rem'}}></i>
                                </span>
                                <Card.Title>Ver Danfe{loadingStatus.location == 'encerrar' && loadingStatus.flag ? <Spinner animation="border" size='sm' />: ""}</Card.Title>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                        <Col sm='3'>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Fechar</Button>
                        </Col>
                </Modal.Footer>
            </Modal>
            {error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
            {success.show ? <ModalSuccessPretty setShow={setSuccess} {...success} /> : ""}
            {
                assinarDocumento.flag ? <ModalPDF_AssinarDanfe
                    title={"Assinar PDF"}
                    pdf={assinarDocumento.PDF}
                    setShow={setAssinarDocumento}
                    show={assinarDocumento.flag}
                    {...assinarDocumento}
                    
                /> : ""
            }
            {
                verPDF.flag ? <ModalPDF_VerDanfe title={verPDF.title} pdf={verPDF.PDF} setShow={setVerPDF} show={verPDF.flag} pedido={ props } /> : ""
            }
        </>
    )
}
