import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { QRCodeSVG } from "qrcode.react";

import "./Modal.scss";
import {
	apenasNumeros,
	getCorretData,
	getStatusPedidos,
	numeroDecimais,
	numerosDecimal,
	numerosEditadoBR,
	valorMoeda,
} from "../../services/Servicos";
import { telefoneMask } from "../../services/helpers";

export default function ModalEditarRow(props) {
	const user = useSelector((state) => state.userSlice);
	const handlerSimpleClose = () => props.setShow((last) => ({ show: false }));

	const [body, setBody] = useState(props.row);
	const [btn, setBtn] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar",
	});
	const [bodyEdit, setBodyEdit] = useState({});
	const [params, setParams] = useState(
		Object.keys(props.row).map((item) => item)
	);

	const getEditedValue = (params, value) => {
		if (["emissao", "createdAt", "updatedAt"].includes(params))
			return getCorretData(value);
		if (params == "id_filial")
			return (
				user.filiaisList.data.find((item) => item.idFilial == value)
					?.identificacaoInterna || value
			);
		if (params == "romaneio") return String(value).padStart(7, "0");
		if (params == "peso_nf") return numerosEditadoBR(value);
		if (params == "valor_total") return valorMoeda(value);
		return value;
	};

	const changeValue = (params, value) => {
		if (params.includes("tel_"))
			return setBodyEdit((last) => ({
				...last,
				[params]: telefoneMask(value),
			}));
		if (params.includes("peso_nf"))
			return setBodyEdit((last) => ({
				...last,
				[params]: Number(
					numerosDecimal(apenasNumeros(value)).replace(",", ".")
				).toLocaleString("pt-br", { minimumFractionDigits: 2 }),
			}));
		if (params.includes("valor_total"))
			return setBodyEdit((last) => ({
				...last,
				[params]: valorMoeda(
					numerosDecimal(apenasNumeros(value)).replace(",", ".")
				).toLocaleString("pt-br", { minimumFractionDigits: 2 }),
			}));
		setBodyEdit((last) => ({ ...last, [params]: value }));
	};

	const atualizarPedido = async () => {
		if (!bodyEdit) return;
		setBtn({ flag: true, msg: "Carregando", style: "warning" });

		if (bodyEdit.peso_nf) {
			bodyEdit.peso_nf = bodyEdit.peso_nf.replaceAll('.','').replaceAll(',','.')
		}
		if (bodyEdit.valor_total) {
			bodyEdit.valor_total = bodyEdit.valor_total.replaceAll('.','').replaceAll(',','.')
		}
		await props.fnRetorno(
			{ ...bodyEdit, cargas_id: body.cargas_id },
			body.romaneio
		);
		setBtn({ flag: false, msg: "Salvo", style: "success" });

		setTimeout(() => {
			setBtn({ flag: false, msg: "Salvar", style: "primary" });
			props.setShow(false)
		}, 1000);
	};

	// useEffect(() => {
	//
	// }, [bodyEdit])
	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.title || "TITULO NÃO INFORMADO"}</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: "auto", margin: "0 20px" }}>
					<Form>
						{params
							.filter(
								(item) =>
									![
										"empresas_id",
										"responsavel",
										"cpf_responsavel",
										"createdAt",
										"created_at",
										"updatedAt",
										"updated_at",
										"danfe",
										"foto_pedido_entregue",
										"qr_code_id",
										"cargas_id",
										"data_fim_descarregamento",
										"data_inicio_descarregamento",
										"cargas_id",
										"metros_quadrado",
										"data_cancelamento",
									].includes(item)
							)
							.map((item) => (
								<Row className="mb-2">
									<Col sm="3">{item}:</Col>
									<Col>
										{item == "tipo" ? (
											<Form.Select
												defaultValue={getEditedValue(item, body[item])}
												onChange={(e) =>
													setBodyEdit((last) => ({
														...last,
														[item]: e.target.value,
													}))
												}
											>
												<option value="ENTREGAR AO CLIENTE">
													Entregar ao Cliente
												</option>
												<option value="ENTREGAR NA LOJA">
													Entregar na Loja
												</option>
												<option value="RETIRAR NA LOJA">
													Cliente Retira na Loja
												</option>
											</Form.Select>
										) : item == "status" ? (
											<Form.Select
												defaultValue={getEditedValue(item, body[item])}
												onChange={(e) =>
													setBodyEdit((last) => ({
														...last,
														[item]: e.target.value,
													}))
												}
											>
												<option value="-3">{getStatusPedidos(-3)}</option>
												<option value="-2">{getStatusPedidos(-2)}</option>
												<option value="-1">{getStatusPedidos(-1)}</option>
												<option value="0">{getStatusPedidos(0)}</option>
												<option value="1">{getStatusPedidos(1)}</option>
												<option value="2">{getStatusPedidos(2)}</option>
												<option value="3">{getStatusPedidos(3)}</option>
												<option value="4">{getStatusPedidos(4)}</option>
												<option value="5">{getStatusPedidos(5)}</option>
												<option value="6">{getStatusPedidos(6)}</option>
												<option value="7">{getStatusPedidos(7)}</option>
												<option value="8">{getStatusPedidos(8)}</option>
												<option value="9">{getStatusPedidos(9)}</option>
												<option value="10">{getStatusPedidos(10)}</option>
												<option value="11">{getStatusPedidos(11)}</option>
												<option value="12">{getStatusPedidos(12)}</option>
												<option value="13">{getStatusPedidos(13)}</option>
												<option value="14">{getStatusPedidos(14)}</option>
												<option value="20">{getStatusPedidos(20)}</option>
												<option value="24">{getStatusPedidos(24)}</option>
												<option value="25">{getStatusPedidos(25)}</option>
											</Form.Select>
										) : (
											<Form.Control
												defaultValue={getEditedValue(item, body[item])}
												value={bodyEdit[item]}
												disabled={[
													"emissao",
													"id_filial",
													"romaneio",
													"numero_pedido",
													"nome_vendedor",
												].includes(item)}
												onChange={(e) => changeValue(item, e.target.value)}
											/>
										)}
									</Col>
								</Row>
							))}
					</Form>
				</Modal.Body>
				<Modal.Footer className="d-flex flex-row">
					<Button
						variant={btn.style}
						onClick={atualizarPedido}
						style={{ width: "fit-content", paddingInline: "40px" }}
					>
						{btn.msg}
						{btn.style == "success" ? <i className="bi bi-check"></i> : ""}
						{btn.style == "warning" ? (
							<Spinner size="sm" animation="border" color="black" />
						) : (
							""
						)}
					</Button>
					<Button
						variant="secondary"
						onClick={handlerSimpleClose}
						style={{ width: "fit-content", paddingInline: "40px" }}
					>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
