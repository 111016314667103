import { Fragment, useEffect, useRef, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { QRCodeSVG } from "qrcode.react"

import './Modal.scss'


export default function ModalTipoDePedido({ msg, title, show, setShow, setTipoPedido, fnRetorno  }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handlerSimpleClose = () => setShow(last => ({ ...last, flag: false }))

    const fin = () => {
        fnRetorno()
        handlerSimpleClose()
    }
     
    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tipo de Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: 'auto', margin: '0 20px' }}>
                    <h3>Informe o tipo de Pedido</h3>
                    <Form.Select onChange={(e) => setTipoPedido(last => ({...last, tipo: e.target.value}))}>
                        <option value =''></option>    
                        <option value ='ENTREGAR AO CLIENTE'>Entregar ao Cliente</option>    
                        <option value ='ENTREGAR NA LOJA'>Entregar na Loja</option>    
                        <option value ='RETIRAR NA LOJA'>Cliente Retira na Loja</option>    
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer className="d-block">
                    
                    <Row className="justify-content-between">
                        <Button variant='primary' onClick={fin} style={{width:'45%'}}>Salvar</Button>
                        <Button variant='secondary' onClick={handlerSimpleClose} style={{ width: '45%'}}>Fechar</Button>

                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}
