import { Fragment, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateEquipamentos } from "../../redux/slicers/user";
import { getEquipamentos, updateEquipamento } from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import { telefoneMask } from "../../services/helpers";

export default function ModalAtualizarEquipamento({
	msg,
	title,
	show,
	setShow,
	body,
}) {
	const userS = useSelector((state) => state.userSlice);
	const funcionarios = userS.funcionariosList.data;
	const dispatch = useDispatch();

	const [equipamento, setEquipamento] = useState({
		descricao: body.descricao,
		cpf_responsavel: body.cpf_responsavel,
		nome_responsavel: body.nome_responsavel,
		telefone: body.telefone,
	});

	const [loading, setLoading] = useState({
		flag: false,
		style: "primary",
	});

	const onChangeInput = (e) => {
		e.preventDefault();
		if (e.target.name == "nome_responsavel") {
			setEquipamento((last) => ({
				...last,
				cpf_responsavel: funcionarios.find(
					(funcionario) => funcionario.name == e.target.value
				)?.cpf,
			}));
		} else if (e.target.name == "telefone") {
			setEquipamento((last) => ({
				...last,
				[e.target.name]: telefoneMask(e.target.value),
			}));
			return;
		}
		setEquipamento((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const updateEquipamentosList = () => {
		getEquipamentos(userS.tipo)
			.then((response) => {
				dispatch(updateEquipamentos(response));
				handlerSimpleClose();
			})
			.catch((error) => {});
	};

	const submitHandler = (e) => {
		e.preventDefault();

		setLoading({
			flag: true,
			style: "warning",
		});

		updateEquipamento(userS.tipo, equipamento, body.id)
			.then((response) => {
				setLoading({
					flag: false,
					style: "success",
				});
				updateEquipamentosList();
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const handlerSimpleClose = () =>
		setShow((last) => ({ error: { flag: false } }));

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={submitHandler}>
						<Row>
							<Col md="4" className="mb-3">
								<Form.Label>Descricao:</Form.Label>
							</Col>
							<Col className="mb-3">
								<Form.Control
									type="text"
									name="descricao"
									placeholder=""
									value={equipamento.descricao}
									onChange={onChangeInput}
									required
								/>
							</Col>
						</Row>
						<Row>
							<Col md="4" className="mb-3">
								<Form.Label>Responsável:</Form.Label>
							</Col>
							<Col className="mb-3">
								<Form.Select
									type="text"
									name="nome_responsavel"
									value={equipamento.nome_responsavel}
									onChange={onChangeInput}
								>
									<option value=""></option>
									{funcionarios.map((item, key) => (
										<option value={item.name} key={key}>
											{" "}
											{item.tipo} - {item.name}{" "}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Col md="4" className="mb-3">
								<Form.Label>CPF Responsavel:</Form.Label>
							</Col>

							<Col className="mb-3">
								<Form.Control
									type="text"
									placeholder=""
									value={equipamento.cpf_responsavel}
									disabled
								/>
							</Col>
						</Row>
						<Row>
							<Col md="4" className="mb-3">
								<Form.Label>Telefone:</Form.Label>
							</Col>

							<Col className="mb-3">
								<Form.Control
									type="text"
									name="telefone"
									placeholder=""
									maxLength={15}
									value={equipamento.telefone}
									onChange={onChangeInput}
									required
								/>
							</Col>
						</Row>

						<Row className="mx-auto justify-content-center my-1">
							<Col sm="6" className="mb-3">
								<Button variant={loading.style} type="submit">
									{loading.flag ? (
										<Loading msg="Salvando" />
									) : loading.style === "success" ? (
										"Salvo"
									) : (
										"Atualizar"
									)}
									{loading.style === "success" ? (
										<i className="bi bi-check"></i>
									) : (
										""
									)}
								</Button>
							</Col>
							<Col sm="6" className="mb-3">
								<Button variant="secondary" onClick={handlerSimpleClose}>
									Cancelar
								</Button>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
}

function ExcluirDados({ msg }) {
	return (
		<Fragment>
			<div>{msg.title}</div>
			<div>
				{Object.keys(msg.msg).map((key, index) => (
					<div key={index}>
						{key}:{" "}
						<b>
							<i>{msg.msg[key]}</i>
						</b>
					</div>
				))}
			</div>
		</Fragment>
	);
}

function EditarDados({ msg, setUser }) {
	const ref = useRef(null);

	return (
		<Fragment>
			<div>{msg.title}</div>
			<div ref={ref}>
				{Object.keys(msg.msg).map((key, index) => {
					return msg.msg[key].className ? (
						<div key={index} className="div-row-editable">
							{msg.msg[key].className}:
							<EditableRow
								msg={msg.msg[key].innerText}
								setUser={setUser}
								name={msg.msg[key].className}
							/>
						</div>
					) : (
						""
					);
				})}
			</div>
		</Fragment>
	);
}

function EditableRow({ msg, name, setUser }) {
	const ref = useRef(null);

	const onChangeDados = (e) => {
		e.preventDefault();

		let string = `{"${name}":"${ref.current.value}"}`;

		if (name === "status" && ref.current.value.toLowerCase() === "inativo") {
			string = `{"${name}":false}`;
		} else if (
			name === "status" &&
			ref.current.value.toLowerCase() === "ativo"
		) {
			string = `{"${name}":true}`;
		}
		// )

		setUser((last) => ({ ...last, ...JSON.parse(string) }));
	};
	return (
		<Fragment>
			<Form.Control
				placeholder={msg}
				name={name}
				className="div-row-editable-input"
				ref={ref}
				onChange={onChangeDados}
			/>
		</Fragment>
	);
}
