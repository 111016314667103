import { useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import { updateTable } from "../../services/Servicos";

import "./Modal.scss";

export default function ModalErrorCarregameto({ msg, title, show, setShow }) {
	const produtosCarregados = useSelector(
		(state) => state.userSlice.produtosList.data
	).filter((item) => item.carregado);

	const embalagensCarregadas = useSelector(
		(state) => state.userSlice.embalagensList.data
	).filter((item) => item.carregado);
	const boxes = useSelector((state) => state.cargaSlicer);
	const [loadingFinalizar, setLoadingFinalizar] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar Parcial",
	});

	const onClickFinalizarParcial = async () => {
		setLoadingFinalizar({
			flag: true,
			style: "warning",
			msg: "Aguarde",
		});
		if (!produtosCarregados[0]?.cargas_id) return alert("Erro com a Carga");
		alert();
		for await (let produto of produtosCarregados) {
			const produtoCarregado = boxes.listaProdutos.filter(
				(item) => item.qr_sku == produto?.qr_sku
			);

			let produtoAtt = {};
			if (!produtoCarregado.length) {
				continue;
			} else {
				const [pos_z, pos_y, pos_x] = produtoCarregado[0]?.position;
				produtoAtt = {
					step_planejado: produtoCarregado[0].step,
					user_planejamento: produto.user_planejamento,
					data_planejamento: produto.data_planejamento,
					planejado: produto.planejado,
				};
			}

			await updateTable(
				"carga/carregador/produto/carregado",
				produto.id,
				produtoAtt
			)
				.then((resp) => {
					console.log({
						flag: false,
						msg: "Produto atualizado com Sucesso",
						style: "success",
					});
				})
				.catch((error) => {
					// let msg = null
					console.log({
						flag: false,
						msg: "Error!!!!",
						style: "warning",
					});
				});
		}
		for await (let embalagem of embalagensCarregadas) {
			const embalagemCarregada = boxes.listaProdutos.filter(
				(item) => item.qr_sku == embalagem?.qr_sku
			);
			let produtoAtt = {};
			if (!embalagemCarregada.length) {
				continue;
			} else {
				const [pos_z, pos_y, pos_x] = embalagemCarregada[0]?.position;
				produtoAtt = {
					step_planejado: embalagemCarregada[0].step,
					user_planejamento: embalagem.user_planejamento,
					data_planejamento: embalagem.data_planejamento,
					planejado: embalagem.planejado,
					cargas_id: embalagem.cargas_id, // passado para atualizar embalagens
				};
			}

			await updateTable(
				"cargas/empresas/embalagens",
				embalagemCarregada[0].qr_sku,
				produtoAtt
			)
				.then((resp) => {
					console.log({
						flag: false,
						msg: "Embalagem atualizada com Sucesso",
						style: "success",
					});
				})
				.catch((error) => {
					// let msg = null
					console.log({
						flag: false,
						msg: "Error!!!!",
						style: "warning",
					});
				});

			const produtosDaEmbalagem = produtosCarregados.filter(
				(item) =>
					item.embalado &&
					Number(embalagem.numero_embalagem) === item.numero_embalagem
			);

			for await (let produto of produtosDaEmbalagem) {
				const produtoAtt2 = {
					user_planejamento: produto.user_planejamento,
					data_planejamento: produto.data_planejamento,
					planejado: produto.planejado,
					step_planejado: produtoAtt.step_planejado,
				};
				await updateTable(
					"carga/carregador/produto/carregado",
					produto.id,
					produtoAtt2
				)
					.then((resp) => {
						console.log({
							flag: false,
							msg: "Produto embalado atualizado com Sucesso",
							style: "success",
						});
					})
					.catch((error) => {
						// let msg = null
						console.log({
							flag: false,
							msg: "Error!!!!",
							style: "warning",
						});
					});
			}
		}

		await updateTable(
			"cargas/caminhoneiro/carregada",
			produtosCarregados[0]?.cargas_id,
			{
				status: 4,
				pedidos: produtosCarregados
					.map((item) => item.numero_pedido)
					.filter((i, n, s) => s.indexOf(i) === n)
					.join(),
				local: "planejamento",
			}
		)
			.then((resp) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "success",
					msg: "Produtos Planejados!",
				}));
			})
			.catch((error) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "alert",
					msg: "Error!",
				}));
			});

		// dispatch(removeAll())
		// dispatch(updateCaminhaoSelecionado({status: false}))
		// dispatch(updateProdutosList([]))
		window.location.reload();
	};

	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, flag: false }));

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{msg}</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loadingFinalizar.style}
								onClick={onClickFinalizarParcial}
							>
								{loadingFinalizar.msg}{" "}
								{loadingFinalizar.flag ? (
									<Spinner animation="border" variant="black" size="sm" />
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
