import { useEffect, useLayoutEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { getAllData, getTableColumns, updateTable, updateTableNew, verificaTipoUsuario } from "../../services/Servicos"

import './Modal.scss'

import TransferenciasEmitidas from "../transferencias/cadastrado/TransferenciasEmitidas"
import Loading from "../loading/Loading"
import ModalError from "./ModalError"
import { updateCargasList } from "../../redux/slicers/user"

export default function ModalAddPedidosTransferenciaACarga({ title, show, setShow, cargas_id }) {
    
    const dispatch = useDispatch()
    const user = useSelector(state => state.userSlice)
    const [headList, setHead] = useState([])

    const [pedidoWasChanged, setPedidoWasChanged] = useState(0)
    const [bodyList, setBodyList] = useState({})
    const [loading, setLoading] = useState({
        isLoading: false,
        msg: 'Salvar',
        style:'primary'
    })
    const [error, setError] = useState({
        flag:false,
        msg:''
    })

    const updateArrayProdutos = async () => {
        const header = await getTableColumns('CargasPedidosTransferencias')
        setHead([ 'index',
            ...Object
                .keys(header.data)
                .filter(key => key !== 'empresas_id')
                .filter(key => key !== 'id')
                .filter(key => key !== 'created_at')
                .filter(key => key !== 'updated_at')
                .filter(key => key !== 'data_cancelamento')
                .filter(key => key !== 'numero_pedido_real')
        ])
    }

    useEffect(() => {
        updateArrayProdutos()
    },[])


    const handlerSimpleClose = () => {
        setShow(last => ({ error: { flag: false } }))
    }

    const updateArrayCargas = async () => {
        let id_filial = user.id_filial

        if (user.id_filial == 2) {
            id_filial = null
        }
        if (user.tipo !== 'empresa') {
            const cargas =  await getAllData(verificaTipoUsuario(user) + 'cargas', {id_filial})
            dispatch(updateCargasList(cargas.data))
            handlerSimpleClose()
            return
        }
        const cargas = await getAllData(verificaTipoUsuario(user) + 'cargas')
        dispatch(updateCargasList(cargas.data))
        handlerSimpleClose()
    }

    const handlerSalvarCarga = async () => {

        if(bodyList.cargas_id) return setError({flag: true, title:'Aviso!', list: 'Transferência ja atribuida a uma carga'})
        setLoading({
            isLoading: true,
            msg: 'Atribuindo',
            style:'warning'
        })

        const url = user.tipo != 'empresa' ? 'gestao/transferencias' : 'transferencias'
        const urlProdutos = `transferencias/produtos?transferencia=${bodyList.transferencia}`

        await updateTableNew(urlProdutos, {cargas_id})
    
        updateTable(url, bodyList.id, { cargas_id, status: 1 })
            .then(resp => {
                setLoading({
                    isLoading: false,
                    msg: 'Atribuido',
                    style:'success'
                })
                updateArrayCargas()
            })
            .catch(err => {
                setError({
                    flag: true,
                    list: err.message,
                    title:'Erro'
                })
            })
    }
    
    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <span>
                            {title}: <b>N°{cargas_id}</b> 
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        headList.length ? 
                            <TransferenciasEmitidas setTransferenciaSelecionada={setBodyList} headList_={headList} cargas_id={cargas_id} />
                        : <Loading msg={'Carregando...'} color={'black'}/>
                    }
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border">

                    <Button variant={loading.style} onClick={handlerSalvarCarga}>
                        {
                            loading.isLoading ? <Loading msg={loading.msg} /> : loading.msg
                        }
                        {
                            loading.style === 'success' ? <i className="bi bi-check"></i> : ""
                        }
                    </Button>
                    <Button variant='secondary' onClick={handlerSimpleClose}>Sair</Button>
                </Modal.Footer>
            </Modal>
            {error.flag ? <ModalError setShow={setError} show={error.flag} {...error} /> : "" }
        </>
    )
}

