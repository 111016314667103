import { createRef, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	apenasNumeros,
	createEmbalagem,
	getAllData,
	numerosDecimal,
	updateTable,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalErrorTotal from "./ModalErrorTotal";

const produtoD = createRef(null);
const descricaoEmbalagem = createRef(null);

export default function ModalEmbalarProdutos({
	msg,
	title,
	show,
	setShow,
	produtos,
	caminhao,
	carga,
	fnRetorno = () => {},
}) {
	const user = useSelector((state) => state.userSlice);
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});
	const [produtosAdd, setProdutosAdd] = useState([]);
	const [embalagens, setEmbalagens] = useState(undefined);
	const [numeroEmbalagem, setNumeroEmbalagem] = useState(undefined);
	const [pesoVolume, setPesoVolume] = useState({
		peso: 0,
		volume: 0,
	});

	const [minDim, setMinDim] = useState({
		/* minimas dimensoes da embalagem de acordo com as maiores dimensoes dod produtos */
		altura: {
			showError: false,
			min: 0,
			max: caminhao.altura < 1 ? 4.4 : caminhao.altura,
			typed: 0,
		},
		comprimento: {
			showError: false,
			min: 0,
			max: caminhao.comprimento,
			typed: 0,
		},
		largura: {
			showError: false,
			min: 0,
			max: caminhao.largura,
			typed: 0,
		},
	});

	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, show: false, msg: "" }));

	const numberFormated = (value) =>
		String(Number(value).toFixed(2)).replace(".", ",");
	const [error, setError] = useState({
		flag: false,
		msg: "",
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		setProdutosAdd((last) => [...last.filter((item) => item !== value)]);
	};

	const addProdutoToEmbalagem = (e) => {
		e.preventDefault();
		const produtoSelected = produtoD.current.value;

		if (produtosAdd.indexOf(produtoSelected) < 0 && produtoSelected.length) {
			setProdutosAdd((last) => [...last, produtoSelected]);
		} else if (!produtoSelected.length) {
			setError((last) => ({
				...last,
				flag: true,
				msg: "Selecione um produto.",
			}));
		} else {
			setError((last) => ({
				...last,
				flag: true,
				msg: "Produto já selecionado. Tente outro.!",
			}));
		}

		produtoD.current.value = "";
	};

	const atualizarProdutosEmbalados = async (numero_embalagem) => {
		let index = 0;
		for await (const produto of produtos) {
			const flag = produtosAdd.indexOf(produto.qr_sku) >= 0 ? true : false;
			if (flag) {
				index++;

				const obj = {
					embalado: true,
					numero_embalagem,
					numero_item_embalagem: index,
				};
				await updateTable("carga/separador/produto/conferido", produto.id, obj)
					.then((resp) => {
						console.log({
							flag: false,
							msg: "Atualizado com Sucesso",
							style: "success",
						});
					})
					.catch((error) => {
						// let msg = null
						console.log({
							flag: false,
							msg: "Error!!!!",
							style: "warning",
						});
					});
			}
		}

		setTimeout(() => {
			setLoading((last) => ({
				...last,
				isLoading: false,
				msg: "Salvo",
				style: "success",
			}));
			fnRetorno(carga.id);
			setTimeout(() => {
				handlerSimpleClose();
			}, 300);
			// setLoading(last => ({...last, isLoading: false, msg:'Salvar', style:'primary'}))
		}, 1000);
	};

	const confirmaEmbalagem = async (e) => {
		e.preventDefault();

		setLoading((last) => ({
			...last,
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		}));
		if (produtosAdd.length < 1) {
			setTimeout(() => {
				setError({
					flag: true,
					msg: "Não é possivel criar embalagem sem produtos!",
				});
				setLoading((last) => ({
					...last,
					isLoading: false,
					msg: "Erro",
					style: "danger",
				}));
				setTimeout(() => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					}));
				}, 1000);
			}, 300);
			return;
		}
		if (produtosAdd.length === 1) {
			setTimeout(() => {
				setError({
					flag: true,
					msg: "Não é possivel criar embalagem com apenas 1 produto!",
				});
				setLoading((last) => ({
					...last,
					isLoading: false,
					msg: "Erro",
					style: "danger",
				}));
				setTimeout(() => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					}));
				}, 1000);
			}, 300);
			return;
		}
		if (
			carga.regiao_entrega !== "LOCAL" &&
			Object.keys(minDim).filter(
				(key) => minDim[key].showError || !minDim[key].typed
			).length
		) {
			setTimeout(() => {
				setError({
					flag: true,
					msg: "Não é possivel criar embalagem sem dimensões minimas exigidas!",
				});
				setLoading((last) => ({
					...last,
					isLoading: false,
					msg: "Erro",
					style: "danger",
				}));
				Object.keys(minDim).forEach((key) => {
					if (minDim[key].typed === 0) {
						setMinDim((last) => ({
							...last,
							[key]: {
								...last[key],
								showError: true,
							},
						}));
					}
				});
				setTimeout(() => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					}));
				}, 1000);
			}, 300);
			return;
		}
		const data = new Date();

		const embalagem = {
			numero_embalagem: numeroEmbalagem,
			descricao: descricaoEmbalagem.current.value.toUpperCase(),
			altura:
				carga.regiao_entrega !== "LOCAL"
					? Number(minDim.altura.typed.replace(",", "."))
					: 1,
			comprimento:
				carga.regiao_entrega !== "LOCAL"
					? Number(minDim.comprimento.typed.replace(",", "."))
					: 1,
			largura:
				carga.regiao_entrega !== "LOCAL"
					? Number(minDim.largura.typed.replace(",", "."))
					: 1,
			volume:
				carga.regiao_entrega !== "LOCAL"
					? Number(minDim.altura.typed.replace(",", ".")) *
					  Number(minDim.comprimento.typed.replace(",", ".")) *
					  Number(minDim.largura.typed.replace(",", "."))
					: 1,
			peso: produtos
				.filter((item) => produtosAdd.indexOf(item.qr_sku) >= 0)
				.reduce((p, c) => p + Number(c.peso), 0),
			qr_sku: `${data.getTime()}`,
			cargas_id: produtos[0].cargas_id,
			qtde_produtos: produtosAdd.length,
			numero_pedido: produtos.find((item) => item.qr_sku == produtosAdd[0])
				.numero_pedido,
			id_filial: produtos.find((item) => item.qr_sku == produtosAdd[0])
				.id_filial,
		};

		await createEmbalagem(embalagem, user.tipo)
			.then((response) => {
				atualizarProdutosEmbalados(embalagem.numero_embalagem);
			})
			.catch((error) => {
				setError({ flag: true, msg: "Erro no banco de dados!" });
				setLoading((last) => ({
					...last,
					isLoading: false,
					msg: "Erro",
					style: "danger",
				}));
				setTimeout(() => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					}));
				}, 1000);
			});
	};

	const onChangeDimensions = (e, parametro) => {
		e.preventDefault();

		const value = Number(
			numerosDecimal(apenasNumeros(e.target.value)).replace(",", ".")
		);
		let valueAltura = minDim.altura.max < 1 ? 4.4 : minDim.altura.max;

		if (parametro === "altura" && value > valueAltura) {
			setMinDim((last) => ({
				...last,
				altura: { ...last.altura, showError: true },
			}));
		}
		if (parametro === "largura" && value > minDim.largura.max) {
			setMinDim((last) => ({
				...last,
				largura: { ...last.largura, showError: true },
			}));
		}
		if (parametro === "comprimento" && value > minDim.comprimento.max) {
			setMinDim((last) => ({
				...last,
				comprimento: { ...last.comprimento, showError: true },
			}));
		}

		setMinDim((last) => ({
			...last,
			[parametro]: {
				...last[parametro],
				showError:
					last[parametro].max >= value && value >= last[parametro].min
						? false
						: true,
				typed: numerosDecimal(apenasNumeros(e.target.value)),
			},
		}));
	};

	// retornar todas as embalagens cadastradas da api
	useEffect(() => {
		if (embalagens) {
			setNumeroEmbalagem(
				String(
					embalagens.length
						? embalagens
								.sort((a, b) => a.numero_embalagem - b.numero_embalagem)
								.reverse()[0]?.numero_embalagem + 1
						: 1
				).padStart(7, "0")
			);
			return;
		}
		getAllData("cargas/funcionarios/embalagens")
			.then((response) => {
				//
				setEmbalagens(response.data);
			})
			.catch((error) => {
				setError({
					msg:
						"Error na obtenção das embalagens existentes." +
						JSON.stringify(error),
					flag: true,
				});
			});
	}, [embalagens]);

	/* debugar dimensoes minimas */
	useEffect(() => {
		setPesoVolume((last) => ({
			...last,
			volume:
				Number(String(minDim.altura.typed).replace(",", ".")) *
				Number(String(minDim.comprimento.typed).replace(",", ".")) *
				Number(String(minDim.largura.typed).replace(",", ".")),
		}));
	}, [minDim]);

	useEffect(() => {
		const produtosFiltrados = produtos.filter(
			(item) => produtosAdd.indexOf(item.qr_sku) >= 0
		);
		const maiorComprimento = produtosFiltrados
			.sort((a, b) => a.comprimento - b.comprimento)
			.map((item) => item.comprimento)
			.reverse();
		const maiorAltura = produtosFiltrados
			.sort((a, b) => a.altura - b.altura)
			.map((item) => item.altura)
			.reverse();
		const maiorLargura = produtosFiltrados
			.sort((a, b) => a.largura - b.largura)
			.map((item) => item.largura)
			.reverse();
		setMinDim((last) => ({
			...last,
			altura: {
				...last.altura,
				min: maiorAltura.at(0),
			},
			comprimento: {
				...last.comprimento,
				min: maiorComprimento.at(0),
			},
			largura: {
				...last.largura,
				min: maiorLargura.at(0),
			},
		}));
		setPesoVolume((last) => ({
			...last,
			peso: produtosFiltrados.reduce((p, c) => Number(c.peso) + p, 0),
		}));
	}, [produtosAdd]);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row className="align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Código:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									name="filterKeyword"
									className="form-control-input"
									placeholder="Aguarde..."
									disabled
									value={numeroEmbalagem}
								/>
							</Col>
						</Row>
						<Row className="align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Descrição:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									name="filterKeyword"
									className="form-control-input"
									placeholder="Digite alguma informação do filtro."
									ref={descricaoEmbalagem}
								/>
							</Col>
						</Row>
						<Row className="align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Produtos:</Form.Label>
							</Col>
							<Col col="6">
								<Form.Select
									type="text"
									ref={produtoD} /* onChange={onChangeInput} */
								>
									<option value=""></option>
									{produtos
										.filter((item) => !item.embalado && !item.conferido)
										.filter((item) => item.id_filial === user.id_filial)
										.map((item, index, self) => {
											const pedidoHabilitado = self.find(
												(item2) => item2.qr_sku == produtosAdd[0]
											)?.numero_pedido;
											return (
												<option
													key={index}
													value={item.qr_sku}
													hidden={
														produtosAdd.indexOf(item.qr_sku) >= 0 ||
														(pedidoHabilitado &&
															pedidoHabilitado != item.numero_pedido)
															? true
															: false
													}
													style={{
														backgroundColor:
															produtosAdd.indexOf(item.qr_sku) >= 0
																? "#dc3545"
																: "inherit",
														color:
															produtosAdd.indexOf(item.qr_sku) >= 0
																? "white"
																: "inherit",
													}}
												>
													{item.numero_pedido} - {item.codigo} -
													{item.descricao.trim()}
												</option>
											);
										})}
								</Form.Select>
							</Col>
							<Col sm="3">
								<Button onClick={addProdutoToEmbalagem}>Add</Button>
							</Col>
						</Row>
						<Row className="justify-content-end mb-2">
							<Col sm="9">
								<div className="d-flex" style={{ overflow: "auto" }}>
									{produtosAdd.map((item, index) => (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">{item}</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									))}
								</div>
							</Col>
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Altura:</Form.Label>
							</Col>
							<Col sm="9">
								<Form.Control
									type="text"
									className="Form-control mb-2"
									maxLength={5}
									min={minDim.altura.min}
									value={minDim.altura.typed}
									onChange={(e) => onChangeDimensions(e, "altura")}
								/>
							</Col>
							{minDim.altura.showError ? (
								<Col sm="9">
									<Form.Text className="text-muted">
										{minDim.altura.min
											? `Mínimo ${numberFormated(
													minDim.altura.min
											  )} [m], Máximo ${numberFormated(minDim.altura.max)} [m]`
											: `Produtos sem altura! Máximo ${numberFormated(
													minDim.altura.max
											  )} [m]`}
									</Form.Text>
								</Col>
							) : (
								""
							)}
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Comprimento:</Form.Label>
							</Col>
							<Col sm="9">
								<Form.Control
									type="text"
									className="Form-control mb-2"
									maxLength={5}
									min={minDim.comprimento.min}
									value={minDim.comprimento.typed}
									onChange={(e) => onChangeDimensions(e, "comprimento")}
								/>
							</Col>
							{minDim.comprimento.showError ? (
								<Col sm="9">
									<Form.Text className="text-muted">
										{minDim.comprimento.min
											? `Mínimo ${numberFormated(
													minDim.comprimento.min
											  )} [m], Máximo ${numberFormated(
													minDim.comprimento.max
											  )} [m]`
											: `Produtos sem comprimento! Máximo ${numberFormated(
													minDim.comprimento.max
											  )} [m]`}
									</Form.Text>
								</Col>
							) : (
								""
							)}
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Largura:</Form.Label>
							</Col>
							<Col sm="9">
								<Form.Control
									type="text"
									className="Form-control mb-2"
									maxLength={5}
									min={minDim.largura.min}
									value={minDim.largura.typed}
									onChange={(e) => onChangeDimensions(e, "largura")}
								/>
							</Col>
							{minDim.largura.showError ? (
								<Col sm="9">
									<Form.Text className="text-muted">
										{minDim.largura.min
											? `Mínimo ${numberFormated(
													minDim.largura.min
											  )} [m], Máximo ${numberFormated(
													minDim.largura.max
											  )} [m]`
											: `Produtos sem largura! Máximo ${numberFormated(
													minDim.largura.max
											  )} [m]`}
									</Form.Text>
								</Col>
							) : (
								""
							)}
						</Row>
						<Row className="justify-content-end align-items-end mb-2">
							<Col sm="3">
								<Form.Label>Resumo:</Form.Label>
							</Col>
							<Col sm="4">
								<Form.Text className="text-muted d-flex flex-column justify-content-center align-items-center">
									<span>Peso da Embalagem</span>
									<span>{numberFormated(pesoVolume.peso)} [ kg ]</span>
								</Form.Text>
							</Col>
							<Col sm="5">
								<Form.Text className="text-muted d-flex flex-column justify-content-center align-items-center">
									<span>Volume da Embalagem</span>
									<span>
										{numberFormated(pesoVolume.volume)} [ m<sup>3</sup> ]
									</span>
								</Form.Text>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer style={{ flexFlow: "row" }}>
					<Button
						variant={loading.style}
						onClick={confirmaEmbalagem}
						disabled={loading.style !== "primary" ? true : false}
					>
						{loading.isLoading ? <Loading msg={loading.msg} /> : loading.msg}
					</Button>
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>

			{error ? (
				<ModalErrorTotal
					show={error.flag}
					setShow={setError}
					msg={error.msg}
					title={"Error!"}
				/>
			) : (
				""
			)}
		</>
	);
}
