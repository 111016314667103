import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./Modal.scss";

export default function ModalRotaCadastrada({ msg, title, show, setShow }) {
	const handlerSimpleClose = () => window.location.reload();

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: "auto", margin: "0 20px" }}>
					<ul>
						{typeof msg === "object" ? (
							Object.keys(msg).map((key, index) => (
								<li key={index}>
									{key.toUpperCase()}:
									{typeof msg[key] === "object" ? (
										<ul>
											{Object.keys(msg[key]).map((key2, index2) => {
												const resp = msg[key];

												return typeof resp[key2] === "string" ? (
													<li key={index2}>
														{" "}
														<b>{resp[key2]}</b>
													</li>
												) : (
													""
												);
											})}
										</ul>
									) : (
										<b> {String(msg[key]).toUpperCase()}</b>
									)}
									{/* <b>{String(msg[key]).toUpperCase()}</b> */}
								</li>
							))
						) : (
							<li>
								<b> {msg}</b>
							</li>
						)}
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handlerSimpleClose}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
