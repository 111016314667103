import { createRef, useCallback, useEffect, useState } from "react";
import { Col, Row, Spinner, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CaminhaoLayout from "../layout/CaminhaoLayout";
import Loading from "../../loading/Loading";
import HeadTable from "../../table/head/HeadRow";
import {
	getAllData,
	getCaminhoes,
	getDateInitMonth,
	getTableColumns,
} from "../../../services/Servicos";
import {
	update,
	updateCaminhoes,
	updateManutencoesList,
} from "../../../redux/slicers/user";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ModalError from "../../modal/ModalError";
import ExportToExcel from "../../exportToExcel/ExportToExcel";
import ModalLancamento from "./ModalLancamento";
import BodyRowManutencoes from "../../table/body/BodyRowManutencoes";
import ModalLancamentoItens from "./ModalLancamentoItens";
import ModalControleDeServicos from "./ModalControleDeServicos";
import ModalControleDeFornecedores from "./ModalControleDeFornecedores";
import ModalControleRotacaoPneus from "./ModalControleRotacaoPneus";

import "./caminhoes.scss";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

const tbodyRef = createRef();
const tableDiv = createRef();

export default function Manutencao() {
	const [error, setError] = useState({});
	const [headList, setHeadList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const user = useSelector((state) => state.userSlice);
	const id_filial = user.tipo.toLowerCase() == "empresa" ? 2 : user.id_filial;
	const dataTable = user.manutencoesList.data;
	const dispatch = useDispatch();

	const [lancarModal, setLancarModal] = useState({
		flag: false,
	});

	const getColumns = async () => {
		try {
			const columns = await getTableColumns("Manutencao");
			setHeadList((last) => [
				"index",
				"admin",
				"emissao",
				"id_filial",
				"status",
				...Object.keys(columns.data)
					.filter((item) => item !== "empresas_id")
					.filter((item) => item !== "created_at")
					.filter((item) => item !== "updated_at")
					.filter((item) => item !== "cpf_responsavel")
					.filter((item) => item !== "user_cancelamento")
					.filter((item) => item !== "data_cancelamento")
					// .filter(item => item !== 'id')
					.filter((item) => item !== "valor")
					.filter((item) => item !== "km_previsao")
					.filter((item) => item !== "data_previsao")
					.filter((item) => item !== "emissao")
					.filter((item) => item !== "id_filial")
					.filter((item) => item !== "status"),
			]);
		} catch (error) {
			setError({
				show: true,
				message: "Erro na obtenção das Colunas",
				title: "Aviso!",
			});
		}
	};

	const getData = useCallback(async (data_inicial, data_final) => {
		dispatch(updateManutencoesList([]));
		const url = "manutencao";
		try {
			let data = await getAllData(url, { id_filial });
			dispatch(updateManutencoesList(data.data));
		} catch (error) {
			setError({
				show: true,
				message: error.message,
				title: "Aviso!",
			});
		}
	});

	// dados de entrada
	useEffect(() => {
		const date = new Date();
		const dateCorrect = getDateInitMonth(date);

		getColumns();
		getData(dateCorrect.data_inicial, dateCorrect.data_final);
	}, []);

	// scrollBar
	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [dataTable];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const [hiddenConfigs, setHiddenConfigs] = useState(true);
	const [controleDeServicos, setControleDeServicos] = useState({ falg: false });
	const [controleDeFornecedores, setControleDeFornecedores] = useState({
		falg: false,
	});
	const [controleRotacaoPneus, setControleRotacaoPneus] = useState({
		falg: false,
	});

	return (
		<CaminhaoLayout>
			<div className="section-filters-table">
				<h3>Filtrar Manutenções</h3>
				<hr />

				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Dados{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</Col>

						<Col sm="12" md="6" lg="2">
							<ExportToExcel
								tableName="Manutencoes"
								reference={
									filteredTable.length ? filteredTable : [...dataTable]
								}
							/>
						</Col>

						<Col sm="12" md="6" lg="2">
							<Button
								variant="primary"
								className="button-export-config"
								onMouseOver={() => setHiddenConfigs(false)}
								onMouseLeave={() => setHiddenConfigs(true)}
							>
								+Adicionar
								<div className="configs" hidden={hiddenConfigs}>
									<div onClick={() => setLancarModal({ flag: true })}>
										Manutenção
									</div>
									<div onClick={() => setControleDeServicos({ flag: true })}>
										Serviços
									</div>
									<div
										onClick={() => setControleDeFornecedores({ flag: true })}
									>
										Fornecedores
									</div>
								</div>
							</Button>
						</Col>
					</Row>
				</div>

				<div
					className="responsive-table"
					ref={tableDiv}
					style={{ position: "relative", height: "600px", maxHeight: "unset" }}
				>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={dataTable}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
								fn={getData}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowManutencoes
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
								  })
								: dataTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowManutencoes
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < dataTable.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			{lancarModal.flag ? (
				<ModalLancamento
					show={lancarModal.flag}
					setShow={setLancarModal}
					title={"Lançar Novo Plano de Manutenção"}
				/>
			) : (
				""
			)}

			{/* lancamento de servicos */}
			{controleDeServicos.flag ? (
				<ModalControleDeServicos
					{...controleDeServicos}
					setShow={setControleDeServicos}
				/>
			) : (
				""
			)}
			{controleDeFornecedores.flag ? (
				<ModalControleDeFornecedores
					{...controleDeFornecedores}
					setShow={setControleDeFornecedores}
				/>
			) : (
				""
			)}
			{controleRotacaoPneus.flag ? (
				<ModalControleRotacaoPneus
					{...controleRotacaoPneus}
					setShow={setControleRotacaoPneus}
				/>
			) : (
				""
			)}
		</CaminhaoLayout>
	);
}
