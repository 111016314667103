/* descarregamento */

import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Fragment, Suspense, useEffect, useRef, useState } from "react";
import { TransformControls, OrbitControls, useHelper } from "@react-three/drei";
import * as THREE from "three";
import "../carregamento/Carregamento.scss";
import { Selection, Select } from "@react-three/postprocessing";
import {
	addProduto,
	removeProduto,
	updateIdSelected,
	updateProduto,
} from "../../redux/slicers/carga";

import {
	getData,
	numerosEditadoBR,
	updateTable,
} from "../../services/Servicos";
import {
	update,
	updateEmbalagensList,
	updateProdutosEEmbalagensBySku,
	updateProdutosList,
} from "../../redux/slicers/user";
import ModalError from "../modal/ModalError";
import { useNavigate } from "react-router-dom";
import ModalGetDescarregamento from "../modal/ModalGetCargaDescarregamento";
import { v4 as uuidv4 } from "uuid";
import { updateProdutosListDescarregados } from "../../redux/slicers/user";
import ModalErrorDescarregameto from "../modal/ModalErrorDescarregameto";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ModalGetDescarregamentoEmbalagem from "../modal/ModalGetDescarregamentoEmbalagem";
import Loading from "../loading/Loading";
import Caminhao from "../carregamento/Caminhao";
import ModalLerQRCode from "../modal/ModalLerQRCode";
import {
	Line,
	Loader,
	distanceVector,
	fatorMedida,
	positionCalculadaProduto,
} from "../carregamento/Helpers";
import DescarregarCargaLocal from "./DescarregarCargaLocal";
import ModalSelectCargas from "../modal/ModalSelectCargas";
import { unicos } from "../../services/helpers";

function WoodBox({
	position,
	args,
	target,
	setTarget,
	name,
	mass,
	argsDesenho,
	argsRealCaminhao,
	actual,
	last,
	setInformacoesCarga = () => {},
	qr_sku,
	id_selected_input,
	setIdSelected,
	tipo,
	cor_caixa,
	romaneio,
	pedido,
}) {
	const fatorMedidaObjeto = fatorMedida(argsDesenho, argsRealCaminhao);
	const argsReal = [
		args[0] * fatorMedidaObjeto.fator_largura,
		args[1] * fatorMedidaObjeto.fator_altura,
		args[2] * fatorMedidaObjeto.fator_comprimento,
	];
	const positionCalculada = positionCalculadaProduto(position, argsReal); // [position[0] + argsReal[0] / 2, position[1] + argsReal[1] / 2, position[2] + argsReal[2] / 2]

	const ref = useRef(null);

	const [hover, setHover] = useState(false);

	useFrame(() => {
		if (id_selected_input == ref.current.qr_sku) {
			if (target && target.qr_sku === id_selected_input) return;
			setTarget(ref.current);
			return;
		}
	});

	return (
		<Select enabled={hover}>
			<mesh
				dispose={null}
				castShadow
				receiveShadow
				onClick={(e) => {
					e.stopPropagation(); /* para não continuar e pegar o objeto atras do selecionado */
					setIdSelected(null);
					setTarget(e.object);
				}}
				name={name}
				side={THREE.DoubleSide}
				ref={ref}
				position={positionCalculada}
				mass={mass}
				tipo={tipo}
				qr_sku={qr_sku}
				romaneio={romaneio}
				pedido={pedido}
			>
				<boxGeometry args={argsReal} />
				{romaneio && target?.romaneio === romaneio ? (
					<meshLambertMaterial color={"#1F51DB"} />
				) : (
					<meshLambertMaterial color={cor_caixa} />
				)}
			</mesh>
		</Select>
	);
}
function LightComponent({ position, args }) {
	const sLightRef = useRef();
	useHelper(sLightRef, THREE.PointLightHelper, 1, "red");
	return (
		<pointLight
			/* ref={sLightRef} */ position={position}
			args={args}
			castShadow
		/>
	);
}

function LightScene() {
	return (
		<Fragment>
			<LightComponent position={[50, 10, 0]} args={["#ffffff", 1]} />
			<LightComponent position={[-50, 10, 0]} args={["#ffffff", 1]} />
			<LightComponent position={[0, 50, 50]} args={["#ffffff", 1]} />
			<LightComponent position={[0, 50, -50]} args={["#ffffff", 1]} />
			<ambientLight args={["#ffffff", 0.1]} />
		</Fragment>
	);
}

function AxisHelper() {
	return <primitive object={new THREE.AxesHelper(5)} />;
}

function Cena({
	setTarget,
	target,
	boxes,
	setBoxes,
	setInformacoesCarga = () => {},
	id_selected,
	setIdSelected,
}) {
	/*  caminhao Direcionado pela empresa para aquele determinado caminhoneiro*/
	// const caminhao = useSelector(state => state.cargaSlicer.dimensoesCaminhao)
	const user = useSelector((state) => state.userSlice);
	const caminhaoDB = useSelector(
		(state) => state.userSlice.caminhaoSelecionado
	);
	const produtos = useSelector((state) => state.userSlice.produtosList.data);
	const romaneiosList = useSelector(
		(state) => state.userSlice.romaneiosList.data
	);
	const embalagens = useSelector(
		(state) => state.userSlice.embalagensList.data
	);
	const produtosEEmbalagensA = user.produtosEEmbalagens.data;
	const [argsDesenho, setArgsDesenho] = useState({
		c: 0,
		a: 0,
		l: 0,
	});

	const selectProduto = (id) => produtos.filter((item) => item.id === id)[0];
	const selectEmbalagem = (id) =>
		embalagens.filter((item) => item.id === id)[0];
	const selectRomaneio = (numero_pedido) =>
		romaneiosList.find((item) => item.numero_pedido === numero_pedido);
	const [last, setLast] = useState({
		name: "",
		position: {
			x: 0,
			y: 0,
			z: 0,
		},
		geometry: {
			parameters: {
				width: 0,
				depth: 0,
				height: 0,
			},
		},
	});

	const [reset, setReset] = useState(false);
	const [actual, setActual] = useState({
		name: "",
		position: {
			x: 0,
			y: 0,
			z: 0,
		},
		geometry: {
			parameters: {
				width: 0,
				depth: 0,
				height: 0,
			},
		},
	});

	useEffect(() => {
		switch (caminhaoDB.caminhao_tipo) {
			case "Carreta":
				if (caminhaoDB.caminhao_qtd_eixos == 2) {
					setArgsDesenho({
						c: 7.33725,
						a: 2.55226,
						l: 2.35579,
					});
					return;
				}
				if (caminhaoDB.caminhao_qtd_eixos == 3) {
					setArgsDesenho({
						c: 9.87056,
						a: 2.55226,
						l: 2.35579,
					});
					return;
				}
			default:
				setArgsDesenho({ c: 6.00757, a: 2.4785, l: 2.3774 });
		}
	}, []);

	useEffect(() => {
		if (!target) {
			setInformacoesCarga((last) => ({
				...last,
				info_produto: {
					...last.info_produto,
					romaneio: "",
					cliente: "",
					cidade: "",
					numero_pedido: "",
					numero_embalagem: "",
					descricao: "",
					codigo: 0,
					tipo: "",

					altura: 0,
					comprimento: 0,
					largura: 0,
					peso: 0,
					volume: 0,
				},
			}));
			setActual({
				name: "",
				position: {
					x: 0,
					y: 0,
					z: 0,
				},
				geometry: {
					parameters: {
						width: 0,
						depth: 0,
						height: 0,
					},
				},
			});

			setLast({
				name: "",
				position: {
					x: 0,
					y: 0,
					z: 0,
				},
				geometry: {
					parameters: {
						width: 0,
						depth: 0,
						height: 0,
					},
				},
			});
			return;
		}

		const targetBox = boxes.filter((item) => item.name === target.name);
		let produto = {};
		let romaneio = {};
		if (target.tipo === "EMBALAGEM") {
			produto = produtosEEmbalagensA.find(
				(item) => item.id == Number(targetBox[0]?.id)
			);
			romaneio = selectRomaneio(produto?.numero_pedido);
		} else {
			produto = selectProduto(Number(targetBox[0]?.id));
			romaneio = selectRomaneio(produto?.numero_pedido);
		}

		setInformacoesCarga((last) => ({
			...last,
			info_produto: {
				...last.info_produto,
				descricao:
					produto?.descricao.length > 20
						? `${produto?.descricao.slice(0, 20)}... `
						: `${produto?.descricao}`,
				codigo: produto?.codigo || produto?.numero_embalagem,
				numero_pedido: romaneio?.numero_pedido || "NÃO ENCONTRADO",
				cliente:
					romaneio?.nome_razao?.length > 20
						? `${romaneio.nome_razao.slice(0, 20)}...`
						: romaneio?.nome_razao,
				romaneio: String(romaneio?.romaneio).padStart(7, 0),
				cidade: romaneio?.cidade || "NÃO ENCONTRADO",
				tipo: target.tipo,

				qtde: numerosEditadoBR(produto?.qtde || produto?.qtde_produtos || 0),
				altura: numerosEditadoBR(produto?.altura),
				comprimento: numerosEditadoBR(produto?.comprimento),
				largura: numerosEditadoBR(produto?.largura),
				peso: numerosEditadoBR(produto?.peso),
				volume: numerosEditadoBR(produto?.volume),
			},
		}));

		setActual((last) => ({ ...last, position: target.position }));
	}, [target]);

	return (
		<Fragment>
			{/* eixo central de ajuda do sistema */}
			<AxisHelper />
			<LightScene />
			{/* <Environment files="/estacao_de_onibus/hamburg_hbf_4k.hdr" ground={{ height: 32, radius: 130 }} /> */}
			{/* <Stage preset="rembrandt" intensity={0.2} environment="city"> */}

			{target && <TransformControls object={target} enabled={false} />}

			{/* </Stage> */}
			<Selection>
				{/* <EffectComposer multisampling={80} autoClear={false}>
                    <Outline blur visibleEdgeColor="white" edgeStrength={1000} width={5000} />
                </EffectComposer> */}
				{boxes.map((item, index) => (
					<WoodBox
						key={index}
						position={item.position}
						args={item.args}
						name={item.name}
						target={target}
						setTarget={setTarget}
						argsDesenho={argsDesenho}
						argsRealCaminhao={{
							a: caminhaoDB.caminhao_altura,
							c: caminhaoDB.caminhao_comprimento,
							l: caminhaoDB.caminhao_largura,
						}}
						mass={item.mass}
						actual={actual}
						last={last}
						tipo={item.tipo}
						setInformacoesCarga={setInformacoesCarga}
						id_selected_input={id_selected}
						qr_sku={item.qr_sku}
						setIdSelected={setIdSelected}
						cor_caixa={item.cor_caixa}
						romaneio={item?.romaneio}
						pedido={item?.pedido}
					/>
				))}
			</Selection>
			{target ? (
				<Line
					lineStart={actual.position}
					lineEnd={last.position}
					lineStraight={distanceVector(actual.position, last.position)}
				/>
			) : (
				""
			)}

			{/* responsavel pela movimentação do ambiente */}

			<OrbitControls
				rotateSpeed={0.15}
				makeDefault
				maxDistance={50} /* distancia maxima do centro da eixo */
				minDistance={1} /* distancia minima do centro do eixo */

				// minPolarAngle={Math.PI/4}
				// maxPolarAngle={Math.PI/1.8} /* rotação maxima em relação ao eixo y expressa em radianos, 180*0.49 ~ 88° */

				// maxAzimuthAngle={Math.PI /1.5}
				// minAzimuthAngle={-Math.PI*2.1}
			/>
		</Fragment>
	);
}

export default function Descarregamento() {
	const boxes = useSelector((state) => state.cargaSlicer);
	const user = useSelector((state) => state.userSlice);
	const userEmail = useSelector((state) => state.userSlice.email);
	const produtosCarregados = useSelector(
		(state) => state.userSlice.produtosList.data
	).filter((item) => item.carregado);
	const embalagensCarregadas = useSelector(
		(state) => state.userSlice.embalagensList.data
	).filter((item) => item.carregado);

	const produtosEEmbalagensA = user.produtosEEmbalagens.data;
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [target, setTarget] = useState(null);
	const [produtosList, setProdutosList] = useState(false);
	const [error, setError] = useState(false);
	const [loadingEntregar, setLoadingEntregar] = useState({
		flag: false,
		style: "warning",
		msg: "Entregar Produto",
	});
	const cargas_id = user.cargas_id;
	// const [cargas_id, setCargas_id] = useState(null)

	const [errorP, setErrorP] = useState({
		flag: false,
		msg: "",
		error: "",
	});
	const [idSelected, setIdSelected] = useState("");

	const [loadingFinalizar, setLoadingFinalizar] = useState({
		flag: false,
		style: "dark",
		msg: "Finalizar Descarregamento",
	});

	const [descargaProdEmba, setDescargaProdEmba] = useState({
		flag: false,
		embalagem: {},
		target: {},
	});

	const [produtoSelecionado, setProdutoSelecionado] = useState({});

	const onEntregarEmbalagem = (embalagem) => {
		// const produtosDaEmbalagem = produtosCarregados.filter(item => Number(item.numero_embalagem) === Number(embalagem.numero_embalagem))
		setDescargaProdEmba({
			flag: true,
			embalagem,
			target,
		});
	};

	const onVerificarQRCODE = async (e) => {
		if (user?.cargaSelecionada?.find((item) => !item.data_inicio_entrega)) {
			setError({
				msg: "O Processo de Entrega deve ser INICIADO antes de entregar qualquer Produto!",
				title: "ATENÇÃO!",
				flag: true,
			});
			return;
		}
		e.preventDefault();
		if (!target)
			return setError({
				msg: "Selecione um produto!",
				title: "Aviso!",
				flag: true,
			});

		setModalQrCode({ flag: true, title: "Identificar Produto" });
		return;
	};

	const [atualizarPedido, setAtualizarPedido] = useState({ flag: false });

	const atualizarStatusPedido = () => {
		if (!atualizarPedido.pedido) {
			return setError({
				flag: true,
				msg: atualizarPedido,
				title: "Erro ao Descarregar PRODUTO, sem numero do pedido",
			});
		}
		const algumItemNaoCarregado = produtosCarregados.find(
			(item) =>
				item.numero_pedido == atualizarPedido.pedido && !item.descarregado
		);
		const algumItemCarregado = produtosCarregados.find(
			(item) =>
				item.numero_pedido == atualizarPedido.pedido && item.descarregado
		);
		const status_pedido = algumItemCarregado
			? algumItemNaoCarregado
				? 12
				: 13
			: 11;

		const itensDescarregados = produtosCarregados.filter(
			(item) => item.descarregado
		).length;
		const status_carga =
			itensDescarregados == produtosCarregados.length
				? 13
				: produtosCarregados.find((item) => item.descarregado)
				? 12
				: 11;

		updateTable("cargas/carregada/caminhoneiro", cargas_id, {
			status: status_pedido,
			pedidos: "" + atualizarPedido.pedido,
			local: "descarregamento",
			status_carga,
		});

		setAtualizarPedido({ flag: false });

		let newArray = [...boxes.listaProdutos].filter(
			(item) => item.name !== target.name
		);
		setTarget(null);
		dispatch(removeProduto(newArray));

		setLoadingEntregar((last) => ({
			...last,
			flag: false,
			msg: "Entregar Produto",
		}));
	};
	useEffect(() => {
		if (!atualizarPedido.flag) return;
		atualizarStatusPedido();
	}, [atualizarPedido.flag]);

	const onEntregarProduto_old = async (e) => {
		setLoadingEntregar((last) => ({ ...last, flag: true, msg: "Entregando" }));
		const produto_qr_sku = boxes.listaProdutos.filter(
			(item) => item.name === target.name
		)[0].qr_sku;

		const objAtt = {
			user_descarregamento: userEmail,
			data_descarregamento: new Date().toISOString(),
			descarregado: true,
		};

		if (target.tipo === "EMBALAGEM") {
			const embalagem = embalagensCarregadas.filter(
				(item) => item.qr_sku === produto_qr_sku
			)[0];
			onEntregarEmbalagem(embalagem, target);
			return;
		}

		const productId = boxes.listaProdutos.filter(
			(item) => item.name == target.name
		)[0];
		const produto = produtosCarregados.find(
			(item) => !item.embalado && item.id == productId.id
		);

		try {
			await updateTable(
				"carga/caminhoneiro/produto/carregado",
				productId.id,
				objAtt
			);
			dispatch(
				updateProdutosListDescarregados({ qr_sku: produto_qr_sku, ...objAtt })
			);
			setAtualizarPedido({ flag: true, pedido: produto.numero_pedido });
		} catch (error) {
			setError({
				flag: true,
				msg: error,
				title: "Erro ao Descarregar PRODUTO",
			});
			setLoadingEntregar((last) => ({
				...last,
				flag: false,
				msg: "Descarregar",
				style: "Primary",
			}));
		}
	};

	const descarregarFn = async (produtoSelecionado, checked) => {
		const objAtt = {
			user_descarregamento: user.email,
			data_descarregamento: new Date().toISOString(),
			descarregado: true,
			qr_sku: produtoSelecionado.qr_sku,
			tipo: produtoSelecionado.tipo,
			numero_embalagem: produtoSelecionado.numero_embalagem,
		};
		dispatch(
			updateProdutosEEmbalagensBySku({
				parameter: "descarregado",
				qr_sku: produtoSelecionado.qr_sku,
				descarregado: true,
				data: new Date().toISOString(),
				user: objAtt.user_descarregamento,
			})
		);
		// try {
		// 	const updated = await updateTable(
		// 		"cargas/motorista/update-descarregamento",
		// 		produtoSelecionado.cargas_id,
		// 		{ ...objAtt }
		// 	);
		// 	setError({
		// 		title: "Aviso",
		// 		flag: true,
		// 		list: [
		// 			...Object.keys(updated)
		// 				.filter((item) => !["headers", "config", "request"].includes(item))
		// 				.map((e) => `${e}: ${updated[e]}`),
		// 		],
		//     });
		//
		// 	if(updated.data.includes('PRODUTO Entregue. Finalizando automáticamente a entrega')){
		// 		setTimeout(() => {
		// 			window.location.replace('map')
		// 		}, 2500)
		// 	}
		// } catch (error) {
		//     const err = error.response;
		//
		// 	setError({
		// 		flag: true,
		// 		list: Object.keys(err).map((e) => `${e}: ${err[e]}`),
		// 	});
		// }
	};

	const onEntregarProduto = async (e) => {
		setLoadingEntregar((last) => ({ ...last, flag: true, msg: "Entregando" }));
		const produto_qr_sku = boxes.listaProdutos.filter(
			(item) => item.name === target.name
		)[0].qr_sku;

		const objAtt = {
			user_descarregamento: userEmail,
			data_descarregamento: new Date().toISOString(),
			descarregado: true,
		};

		let produtoSelecionado;
		if (target.tipo === "EMBALAGEM") {
			produtoSelecionado = produtosEEmbalagensA.find(
				(item) => item.qr_sku === produto_qr_sku
			);
		} else {
			produtoSelecionado = produtosEEmbalagensA.find(
				(item) => item.qr_sku === produto_qr_sku
			);
			// dispatch(updateProdutosListDescarregados({qr_sku: produto_qr_sku, ...objAtt}))
		}
		await descarregarFn(produtoSelecionado, true);

		let newArray = [...boxes.listaProdutos].filter(
			(item) => item.name !== target.name
		);
		setTarget(null);
		dispatch(removeProduto(newArray));

		setLoadingEntregar((last) => ({
			...last,
			flag: false,
			msg: "Entregar Produto",
		}));
	};

	const onSelectProduto = (e) => {
		e.preventDefault();
		if (target) updateProdutoSelected(target);
		setTarget(null);
		setProdutosList(true);

		// dispatch(addProduto({ name: uuidv4(), position: [0, 0, 0], args: [2, 5, 6], mass: 10 }))
	};

	const onClickFinalizarDescarregamento = (e) => {
		e.preventDefault();
		const produtosList = user.produtosList.data.filter(
			(item) => !item.embalado
		);
		const produtosCheckeds = produtosList.filter((item) => item?.descarregado);
		const embalagesCheckeds = user.embalagensList.data.filter(
			(item) => item?.descarregado
		);

		if (!produtosCheckeds.length && !embalagesCheckeds.length) {
			setError((last) => ({
				...last,
				flag: true,
				msg: "Nenhum produto descarregados. Por favor, inicie o processo de planejamento!",
				title: "Erro ao Finalizar Carregamento",
			}));
			return;
		}
		if (
			produtosCheckeds.length !== produtosList.length ||
			embalagesCheckeds.length !== user.embalagensList.data.length
		) {
			setErrorP((last) => ({
				...last,
				flag: true,
				msg: "Existem produtos não descarregados. Deseja salvar planejamento parcial?",
				title: "Atenção!",
			}));
			return;
		}
		setLoadingFinalizar({
			flag: true,
			msg: "Finalizando",
			style: "warning",
		});

		const pedidosDescarregados = produtosCarregados.filter(
			(item) => item.descarregado
		);
		updateTable("cargas/carregada/caminhoneiro", cargas_id, {
			status: 13,
			pedidos: pedidosDescarregados
				.map((item) => item.numero_pedido)
				.filter((i, n, s) => s.indexOf(i) === n)
				.join(),
			local: "descarregamento",
			status_carga: 13,
		})
			.then((resp) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					msg: "Carga Totalmente Entregue.",
					style: "success",
				}));

				setTimeout(() => {
					navigate("/map");
				}, 2000);
			})
			.catch((error) => {
				setLoadingFinalizar({
					flag: false,
					msg: "ERROR",
					style: "danger",
				});
			});
	};

	const updateProdutoSelected = (target) => {
		const position = [target.position.x, target.position.y, target.position.z];
		const argsReal = [
			target.geometry.parameters.width,
			target.geometry.parameters.height,
			target.geometry.parameters.depth,
		];
		const positionCalculada = [
			position[0] - argsReal[0] / 2,
			position[1] - argsReal[1] / 2,
			position[2] - argsReal[2] / 2,
		];

		const item = {
			itemName: target.name,
			newPosition: positionCalculada,
		};
		dispatch(updateProduto(item));
	};

	const [firstRender, setFirstRender] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (user.caminhaoSelecionado.status) {
			setLoading(false);
			return;
		}
		setTimeout(() => {
			getData("caminhao/caminhoneiro", user.id)
				.then((resp) => {
					const result = {};
					setLoading(false);

					result.status = true;
					// Object.keys(resp.data).forEach(key => {
					//     result[key] = resp.data[key]
					// })
					// dispatch(updateCaminhaoSelecionado(result))
				})
				.catch((error) => {
					return;
					setLoading(false);
					// if (error.msg.toLowerCase() === 'unauthorized' || error.msg.toLowerCase() === 'network error') {
					dispatch(update({ authenticated: false }));
					// return window.location.replace('/login')
					// }
				});
		}, 1500);
	}, [user.caminhaoSelecionado.status /* hasPassed */]);

	useEffect(() => {
		if (!user.caminhaoSelecionado.status) return;
		// verificarCargaCarregamento()
	}, [user.caminhaoSelecionado]);

	const caminhaoDB = user.caminhaoSelecionado;
	const [informacoesCarga, setInformacoesCarga] = useState({
		distancia_ao_caminhao: {
			tampa_traseira: 0,
			tampa_frontral: 0,
			acoalho: 0,
			teto: 0,
			tampa_lateral_direita: 0,
			tampa_lateral_esquerda: 0,
		},
		info_produto: {
			// romaneio
			romaneio: "",
			numero_pedido: "",
			cliente: "",
			cidade: "",
			descricao: 0,
			codigo: 0,
			tipo: "",

			qtde: 0,
			altura: 0,
			comprimento: 0,
			largura: 0,
			peso: 0,
			volume: 0,
		},
		distancia_ao_produto: {
			altura: 0,
			comprimento: 0,
			largura: 0,
		},
	});

	const [modalQrCode, setModalQrCode] = useState({ flag: false });

	useEffect(() => {
		if (!target) return;
		setProdutoSelecionado(
			produtosEEmbalagensA.find((item) => item.qr_sku === target.qr_sku)
		);
	}, [target]);

	const [styledButton, setStyledButton] = useState(false);

	const [modalSelecionarCargas, setModalSelecionarCargas] = useState({
		cargas: [],
		flag: false,
	});

	const [produtosEEmbalagens, setProdutosEEmbalagens] = useState([]);

	const verificaCargasDiponiveis = async () => {
		setModalSelecionarCargas({ flag: true, setProdutosEEmbalagens });
	};

	const [loadingBtn, setLoadingBtn] = useState({
		style: "dark",
		msg: "Finalizar",
		flag: false,
	});

	const finalizarCarregamento = async () => {
		if (produtosEEmbalagens.find((item) => !item.descarregado)) {
			setError((last) => ({
				...last,
				flag: true,
				title: "Aviso",
				list: [
					"ERRO: Produtos não descarregados",
					...produtosEEmbalagens
						.filter((item) => !item.descarregado)
						.map(
							(item) =>
								`${item.codigo || item.numero_embalagem}: ${item.descricao}`
						),
				],
			}));
			return;
		}
		setLoadingBtn({
			flag: true,
			msg: "Salvando",
			style: "warning",
		});
		try {
			const data = await Promise.all([
				...unicos(produtosEEmbalagens, "cargas_id").map((item) =>
					updateTable("cargas/motorista/update-pedidos", item.cargas_id, {
						status: 13,
					})
				),
			]);
			setLoadingBtn({
				flag: false,
				msg: "Salvo",
				style: "success",
			});

			setTimeout(() => {
				window.location.replace("/map");
			}, 500);
		} catch (error) {
			setError((last) => ({
				...last,
				flag: true,
				msg: {
					ERRO: "Erro na finalização do carregamento!!",
					...error.response.data.separado.map((item) => ({
						pedido: `Numero do Pedido: ${item.numero_pedido}`,
						descricao: `Produto: ${item.codigo} - ${item.descricao}`,
						unidade: "Unidade: " + item.abreviatura,
						separado: item.conferido ? "SEPARADO: SIM" : "SEPARADO: NÃO",
						carregado: item.carregado ? "CARREGADO: SIM" : "CARREGADO: NÃO",
						item_embalagem: `Embalado: ${
							item.embalado
								? `SIM -> N°${String(item.numero_embalagem).padStart(
										7,
										0
								  )} - Item: ${item.numero_item_embalagem}`
								: "NÃO"
						}`,
					})),
					...error.response.data.carregado.map((item) => ({
						pedido: `Numero do Pedido: ${item.numero_pedido}`,
						descricao: `Produto: ${item.codigo} - ${item.descricao}`,
						unidade: "Unidade: " + item.abreviatura,
						separado: item.conferido ? "SEPARADO: SIM" : "SEPARADO: NÃO",
						carregado: item.carregado ? "CARREGADO: SIM" : "CARREGADO: NÃO",
						item_embalagem: `Embalado: ${
							item.embalado
								? `SIM -> N°${String(item.numero_embalagem).padStart(
										7,
										0
								  )} - Item: ${item.numero_item_embalagem}`
								: "NÃO"
						}`,
					})),
				},
				title: "Erro ao Finalizar Carregamento",
			}));
			setLoadingBtn({
				flag: false,
				msg: "Finalizar",
				style: "dark",
			});
		}
	};

	return (
		<Fragment>
			{loading ? <Loading msg={"Carregando"} color={"black"} /> : ""}
			<div className="">
				<Row className="justify-content-between">
					<Col sm="12" md="6" lg="8" className="mb-1  text-sm-center ">
						<h3 style={{ display: "flex", alignItems: "center" }}>
							Produtos da Carga
						</h3>
					</Col>
					<Col sm="12" md="6" lg="2" className="mb-1">
						<Button
							variant={"primary"}
							onClick={() => verificaCargasDiponiveis()}
						>
							Selecionar Cargas
						</Button>
					</Col>
				</Row>
			</div>
			{!user?.cargaSelecionada.length ? <h2>Selecione alguma carga</h2> : ""}
			{user?.cargaSelecionada?.find(
				(item) => item.regiao_entrega !== "LOCAL"
			) ? (
				!user.caminhaoSelecionado.status ? (
					<div>
						{loading ? (
							<Loading msg={"Carregando"} color={"black"} />
						) : (
							<h2>Caminhão Não Disponibilizado pela Empresa!</h2>
						)}
					</div>
				) : (
					<div className="carregamento">
						<Canvas
							shadows
							style={{ background: "#0CABA8" }}
							orthographic
							camera={{ zoom: 50, position: [100, 100, 100] }}
							onPointerMissed={() => {
								if (!target) return;
								updateProdutoSelected(target);
								setTarget(null);
								dispatch(updateIdSelected({ id: "" }));
								setIdSelected(null);
							}}
						>
							<Suspense fallback={<Loader />}>
								<Caminhao />
								<Cena
									target={target}
									setTarget={setTarget}
									boxes={boxes.listaProdutos.filter(
										(item) => !item.descarregado
									)}
									setBoxes={updateProdutoSelected}
									id_selected={idSelected}
									setIdSelected={setIdSelected}
									setInformacoesCarga={setInformacoesCarga}
								/>
							</Suspense>
						</Canvas>
						<div className="info-products">
							<pre>
								<div className="mb-2">
									<Button variant="info" onClick={onSelectProduto}>
										Ver Produtos
									</Button>
								</div>
								<div className="mb-2">
									{/* <Button variant='primary' onClick={onEntregarProduto}>Entregar Produto</Button> */}
									{/* <Button variant='warning' onClick={onRemove}>Remover Produto</Button> */}
									<Button
										variant={loadingEntregar.style}
										onClick={onVerificarQRCODE}
									>
										{
											<Fragment>
												{loadingEntregar.msg}{" "}
												{loadingEntregar.flag ? (
													<Spinner
														animation="border"
														variant="black"
														size="sm"
													/>
												) : (
													""
												)}
											</Fragment>
										}
									</Button>
								</div>
								<div className="mb-2">
									<Button
										variant={loadingBtn.style}
										onClick={() => finalizarCarregamento()}
									>
										{loadingBtn.msg}
										{loadingBtn.style === "success" ? (
											<i className="bi bi-check"></i>
										) : (
											""
										)}
										{loadingBtn.flag ? (
											<Spinner size="sm" animation="border" />
										) : (
											""
										)}
									</Button>
								</div>
							</pre>
						</div>

						<div
							className={`info-products info-carga ${
								styledButton ? "hide-card" : ""
							}`}
						>
							<pre>
								<div className="d-flex justify-content-between align-items-center">
									<h4 className="m-0">Informações da Carga </h4>
									<i
										className={`i bi-arrow-up-circle-fill rotateButton ${
											styledButton ? "rotate-up" : ""
										}`}
										onClick={(e) => setStyledButton(!styledButton)}
									></i>
								</div>
								<h6 className="card-header">Produto Selecionado</h6>
								<ul>
									<li>
										Romaneio: <b>{informacoesCarga.info_produto.romaneio}</b>
									</li>
									<li>
										Pedido: <b>{informacoesCarga.info_produto.numero_pedido}</b>
									</li>
									<li>
										Cliente: <b>{informacoesCarga.info_produto.cliente}</b>
									</li>
									<li>
										Cidade: <b>{informacoesCarga.info_produto.cidade}</b>
									</li>
									<li>
										Descrição: <b>{informacoesCarga.info_produto.descricao}</b>
									</li>
									<li>
										Tipo: <b>{informacoesCarga.info_produto.tipo}</b>
									</li>
									<li>
										Código: <b>{informacoesCarga.info_produto.codigo}</b>
									</li>
									<li>
										Quantidade: <b>{informacoesCarga.info_produto.qtde}</b>
									</li>
									<li>
										Altura: <b>{informacoesCarga.info_produto.altura} [ m ]</b>
									</li>
									<li>
										Comprimento:{" "}
										<b>{informacoesCarga.info_produto.comprimento}</b> [ m ]
									</li>
									<li>
										Largura:{" "}
										<b>{informacoesCarga.info_produto.largura} [ m ]</b>
									</li>
									<li>
										Peso: <b>{informacoesCarga.info_produto.peso}</b> [ kg ]
									</li>
									<li>
										Volume:{" "}
										<b>
											{informacoesCarga.info_produto.volume} [ m<sup>3</sup> ]
										</b>
									</li>
								</ul>

								<h6 className="card-header">Caminhão</h6>
								<ul>
									<li>
										PLACA: <b>{caminhaoDB.caminhao_placa}</b>
									</li>
									<li>
										CARGA: <b>{user.cargas_id}</b>
									</li>
									<li>
										ROMANEIOS:{" "}
										<b>
											{
												user.produtosList.data.filter(
													(i, n, s) =>
														s.findIndex(
															(o) => o.numero_pedido === i.numero_pedido
														) === n
												).length
											}
										</b>
									</li>
									<li>
										PRODUTOS: <b>{user.produtosList.data.length}</b>
									</li>
									<li>
										EMBALAGENS: <b>{user.embalagensList.data.length}</b>
									</li>
									<li title="Produtos + Embalagens Carregadas no Caminhão">
										VOLUME CARREGADO:
										<b>
											{Number(
												user.produtosList.data
													.filter((item) => !item.embalado && item.planejado)
													.reduce((p, c) => p + c.volume, 0) +
													user.embalagensList.data
														.filter((item) => item.planejado)
														.reduce((p, c) => p + c.volume, 0)
											).toLocaleString("pr-BR")}{" "}
											[ m<sup>3</sup> ]
										</b>
									</li>
									<li title="Produtos + Embalagens Carregadas no Caminhão">
										PESO CARREGADO:
										<b>
											{Number(
												user.produtosList.data
													.filter((item) => !item.embalado && item.planejado)
													.reduce((p, c) => p + c.peso, 0) +
													user.embalagensList.data
														.filter((item) => item.planejado)
														.reduce((p, c) => p + c.peso, 0)
											).toLocaleString("pr-BR")}{" "}
											[ kg ]
										</b>
									</li>
								</ul>
							</pre>
						</div>
					</div>
				)
			) : (
				""
			)}
			{user?.cargaSelecionada?.find(
				(item) => item.regiao_entrega === "LOCAL"
			) ? (
				<DescarregarCargaLocal />
			) : (
				""
			)}
			{produtosList ? (
				<ModalGetDescarregamento
					show={produtosList}
					setShow={setProdutosList}
					setError={setError}
					setIdSelected={setIdSelected}
					cargas_id={cargas_id}
				/>
			) : (
				""
			)}
			{descargaProdEmba.flag ? (
				<ModalGetDescarregamentoEmbalagem
					show={descargaProdEmba}
					setShow={setDescargaProdEmba}
					embalagem={descargaProdEmba.embalagem}
					cargas_id={cargas_id}
					target={descargaProdEmba.target}
					setLoadingEntregar={setLoadingEntregar}
					setTarget={setTarget}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					msg={error.msg}
					title={error.title}
					show={error}
					setShow={setError}
					{...error}
				/>
			) : (
				""
			)}

			{/* modal para finalizar parcialmente */}
			{errorP.flag ? (
				<ModalErrorDescarregameto
					msg={errorP.msg}
					title={errorP.title}
					show={errorP.flag}
					setShow={setErrorP}
				/>
			) : (
				""
			)}
			{modalQrCode.flag ? (
				<ModalLerQRCode
					msg={modalQrCode.msg}
					title={modalQrCode.title}
					show={modalQrCode.flag}
					setShow={setModalQrCode}
					setProdutoSelecionado={setProdutoSelecionado}
					produtoSelecionado={produtoSelecionado}
					entregarFn={onEntregarProduto}
				/>
			) : (
				""
			)}
			{modalSelecionarCargas.flag ? (
				<ModalSelectCargas
					show={modalSelecionarCargas.flag}
					setShow={setModalSelecionarCargas}
					{...modalSelecionarCargas}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
