import { createRef, useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CaminhaoLayout from "../layout/EquipamentoLayout";
import Loading from "../../loading/Loading";
import HeadTable from "../../table/head/HeadRow";
import { getEquipamentos, getTableColumns } from "../../../services/Servicos";
import { updateEquipamentos } from "../../../redux/slicers/user";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ModalError from "../../modal/ModalError";
import BodyRowEquipamentos from "../../table/body/BodyRowEquipamentos";
import ExportToExcel from "../../exportToExcel/ExportToExcel";

const tbodyRef = createRef();
const tableDiv = createRef();

export default function EquipamentosCadastrados() {
	const equipamentos = useSelector((state) => state.userSlice.equipamentosList);

	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const user = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();

	const [headList, setHead] = useState([]);
	const [error, setError] = useState({ flag: false, msg: "", title: "" });

	const updateHead = async () => {
		const abastecimentopHeader = await getTableColumns("Equipamentos");
		setHead([
			"admin",
			...Object.keys(abastecimentopHeader.data).filter(
				(key) => key !== "empresas_id"
			),
		]);

		getEquipamentos(user.tipo)
			.then((response) => {
				dispatch(updateEquipamentos(response));
				setLoading(false);
			})
			.catch((error) => {
				setError({
					flag: true,
					msg: error,
				});
			});
	};

	useEffect(() => {
		updateHead();
	}, []);

	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [equipamentos.data];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<CaminhaoLayout>
			<div className="section-filters-table">
				<h3>Filtrar Equipamentos</h3>
				<hr />

				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>

			<div className="card-header pb-2">
				<Row className="justify-content-between">
					<Col sm="12" md="6" lg="8">
						<h3 style={{ display: "flex", alignItems: "center" }}>
							Tabela de Equipamentos{" "}
							{loading ? (
								<Spinner
									style={{ marginLeft: "10px", fontSize: "1rem" }}
									animation="border"
									size="sm"
								/>
							) : (
								""
							)}
						</h3>
					</Col>
					<Col sm="12" md="6" lg="2">
						<ExportToExcel
							tableName="Equipamentos Cadastrados"
							reference={
								filteredTable.length ? filteredTable : [...equipamentos.data]
							}
							paramExport={headList}
						/>
					</Col>
				</Row>
			</div>

			<div className="section-filters-table">
				<div className="responsive-table" ref={tableDiv}>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={equipamentos.data}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowEquipamentos
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
								  })
								: [...equipamentos.data].reverse().map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowEquipamentos
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < equipamentos.data.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			{error.flag ? (
				<ModalError
					title={"ERRO!"}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</CaminhaoLayout>
	);
}
