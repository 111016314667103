import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../loading/Loading";

import "../../modal/Modal.scss";
import ModalError from "../../modal/ModalError";
import {
	getAllData,
	getData,
	getDateInitMonth,
	getTableColumns,
	insertInto,
	updateTable,
	valorMoeda,
} from "../../../services/Servicos";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ExportToExcel from "../../exportToExcel/ExportToExcel";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import BodyRowManutencoes from "../../table/body/BodyRowManutencoes";
import BodyRowManutencoesItems from "../../table/body/BodyRowManutencoesItems";
import { updateManutencoesList } from "../../../redux/slicers/user";
import ModalCancelamento from "./ModalCancelamento";
import ModalEdicao from "./ModalEdicao";
import pneu from "../../../assets/caminhoes/pneu/pneu_2.png";
import ModalHistoricoPneu from "./ModalHistoricoPneu";
import ModalLancamentoPecasItens from "./ModalLancamentoPecasItens";

export default function ModalControleRotacaoPneus(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const id_filial = user.tipo.toLowerCase() == "empresa" ? 2 : user.id_filial;
	const formREf = useRef();
	const textArea = useRef();

	const [qtdePneusTotal, setQtdePneusTotal] = useState(0);
	const [atualizar, setAtualizar] = useState(0);
	const [error, setError] = useState(false);
	const [showCardLancamento, setShowCardLancamento] = useState(true);

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		responsavel: String(user.name).toUpperCase(),
		cpf_responsavel: user.email || "N/A",
	});

	const createItem = async () => {
		if (!body.tipo) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Serviço",
					motivo: "Descreva o Tipo de Pneu.",
				},
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});
		let bodySend = {};

		Object.keys(body).map((item) => {
			if (item.includes("data") || item.includes("responsavel")) {
				bodySend[item] = body[item];
				return;
			}
			if (item.includes("valor")) {
				bodySend[item] = Number(
					body[item].toUpperCase().replace(".", "").replace(",", ".")
				);
				return;
			}

			bodySend[item] = String(body[item])
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		bodySend.manutencao_id = props.bodyList.id;
		bodySend.emissao = new Date().toISOString();

		const url = "manutencao/pneus";

		insertInto(url, bodySend)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				setBody((last) => ({
					responsavel: String(user.name).toUpperCase(),
					cpf_responsavel: user.email || "N/A",
				}));
				setAtualizar((last) => last + 1);

				formREf.current.reset();

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Salvar",
						style: "primary",
					});
				}, 750);
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setError({
					flag: true,
					title: "Atenção",
					msg: error.response.data.errors,
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onClickButton = async () => {
		setLoading({ isLoading: true, style: "warning", msg: "Aguarde" });
		await createItem();
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const editValue = (e) => {
		let valor = String(e.target.value)
			.replaceAll(".", "")
			.replace(",", "")
			.replace(/\D/g, "");

		setBody((last) => ({
			...last,
			[e.target.name]: Number(Number(valor) * 0.01).toLocaleString("pt-br", {
				minimumFractionDigits: 2,
			}),
		}));
	};
	const handlerSimpleClose = () =>
		props.setShow((last) => ({ error: { flag: false } }));

	const [showTitle, setShowTitle] = useState({ item: "", show: false });
	const [dataTable, setDataTable] = useState([]);
	const [historicoPneu, setHistoricoPneu] = useState({ flag: false });

	useEffect(() => {
		switch (props.caminhao?.tipo.toLowerCase()) {
			case "toco":
				setQtdePneusTotal(8);
				break;
			case "truck":
				setQtdePneusTotal(12);
				break;
			case "bitruck":
				setQtdePneusTotal(16);
				break;
			case "cavalo mecanico simples":
				setQtdePneusTotal(4);
				break;
			case "cavalo mecanico truck":
				setQtdePneusTotal(4);
				break;
			case "carreta":
				// const caminhao = user.caminhoesList.data.find(item => item.placa === props.bodyList.placa)
				setQtdePneusTotal(props.caminhao?.qtd_eixos || 0);
				break;
		}
	}, [props]);

	return (
		<>
			<Modal
				show={props.flag}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size={"xl"}
			>
				<Modal.Header closeButton>
					<Modal.Title>Controle de Pneus - {props.caminhao?.placa}</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex justify-content-between">
					<div className="pt-5 d-none d-xl-block" style={{ minWidth: "500px" }}>
						{props.caminhao?.tipo.toLowerCase() == "toco" ? (
							<Toco
								title="Toco"
								data={dataTable}
								setHistoricoPneu={setHistoricoPneu}
								setError={setError}
							/>
						) : (
							""
						)}
						{props.caminhao?.tipo.toLowerCase() == "truck" ? (
							<Truck
								title="Truck"
								data={dataTable}
								setHistoricoPneu={setHistoricoPneu}
								setError={setError}
							/>
						) : (
							""
						)}
						{props.caminhao?.tipo.toLowerCase() == "bitruck" ? (
							<Bitruck
								title="BiTruck"
								data={dataTable}
								setHistoricoPneu={setHistoricoPneu}
								setError={setError}
							/>
						) : (
							""
						)}
						{props.caminhao?.tipo.toLowerCase() == "carreta" ? (
							<Carreta
								data={dataTable}
								setHistoricoPneu={setHistoricoPneu}
								setError={setError}
								qtde_eixos={qtdePneusTotal}
							/>
						) : (
							""
						)}
						{props.caminhao?.tipo.toLowerCase() == "cavalo mecanico simples" ? (
							<CavaloMecanico
								title="Cavalo"
								data={dataTable}
								setHistoricoPneu={setHistoricoPneu}
								setError={setError}
							/>
						) : (
							""
						)}
						{props.caminhao?.tipo.toLowerCase() == "cavalo mecanico truck" ? (
							<CavaloMecanico
								title="Cavalo Truck"
								data={dataTable}
								setHistoricoPneu={setHistoricoPneu}
								setError={setError}
							/>
						) : (
							""
						)}
					</div>

					<div
						style={{
							marginTop: "0",
							width: "-webkit-fill-available",
							border: "0.1px solid grey",
							padding: "0.7rem",
							boxShadow: "0 0 4px #010f0a81",
						}}
						hidden={showCardLancamento}
					>
						<div className="card-header pb-2">
							<Row className="justify-content-between">
								<Col sm="12" md="6" lg="8">
									<h3 style={{ display: "flex", alignItems: "center" }}>
										Cadastro de Pneus
									</h3>
								</Col>
							</Row>
						</div>
						<Form ref={formREf}>
							<Row className="mb-2 align-items-center">
								<Col sm="4">
									<Form.Label>Tipo:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="tipo"
										onChange={onChangeInput}
										value={body?.tipo}
									>
										<option></option>
										<option value="NOVO">NOVO</option>
										<option value="1° RECAPAMENTO">1° RECAPAMENTO</option>
										<option value="2° RECAPAMENTO">2° RECAPAMENTO</option>
										<option value="3° RECAPAMENTO">3° RECAPAMENTO</option>
										<option value="4° RECAPAMENTO">4° RECAPAMENTO</option>
										<option value="5° RECAPAMENTO">5° RECAPAMENTO</option>
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2 align-items-center">
								<Col sm="4">
									<Form.Label>Numero do Pneu:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										name="numero_pneu"
										onChange={onChangeInput}
										value={body?.numero_pneu}
									/>
								</Col>
							</Row>
							<Row className="mb-2 align-items-center">
								<Col sm="4">
									<Form.Label>Posição Atual:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="posicao_atual"
										onChange={onChangeInput}
										value={body?.posicao_atual}
									>
										<option></option>
										{new Array(qtdePneusTotal).fill("").map((item, key) => {
											if (
												dataTable.find(
													(item) => item.posicao_atual == `P${key + 1}`
												)
											)
												return;
											return (
												<option key={key} value={`P${key + 1}`}>
													P{key + 1}
												</option>
											);
										})}
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2 align-items-center">
								<Col sm="4">
									<Form.Label>Posição Previsão:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="posicao_previsao"
										onChange={onChangeInput}
										value={body?.posicao_previsao}
									>
										<option></option>
										{new Array(qtdePneusTotal).fill("").map((item, key) => {
											if (
												dataTable.find(
													(item) => item.posicao_previsao == `P${key + 1}`
												)
											)
												return;
											return (
												<option key={key} value={`P${key + 1}`}>
													P{key + 1}
												</option>
											);
										})}
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2 align-items-center">
								<Col sm="4">
									<Form.Label>Km Previsão:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										name="km_previsao"
										onChange={editValue}
										value={body?.km_previsao}
									/>
								</Col>
							</Row>
							<Row className="mb-2  align-items-center">
								<Col sm="4">
									<Form.Label>Observações:</Form.Label>
								</Col>
								<Col>
									<textarea
										ref={textArea}
										onChange={onChangeInput}
										value={body?.observacoes}
										name="observacoes"
										style={{ width: "100%" }}
										rows={4}
									></textarea>
								</Col>
							</Row>
							<h5 className="card-header">Alertas</h5>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>
										Por Km{" "}
										<i
											className={`bi bi-info ${
												showTitle.item == "km" && showTitle.show
													? " describe"
													: ""
											}`}
											data-descricao="Quantidade de KM antes do KM de Previsão para emitir mensagens de alerta"
											onMouseOver={() =>
												setShowTitle({ item: "km", show: true })
											}
											onMouseLeave={() =>
												setShowTitle({ item: "km", show: false })
											}
										></i>{" "}
										:
									</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										name="aviso_km"
										onChange={editValue}
										value={body?.aviso_km}
									/>
								</Col>
							</Row>
						</Form>
					</div>
					<TabelaItens
						hidden={!showCardLancamento}
						atualizar={atualizar}
						manutencao_id={props.bodyList.id}
						setDataTable={setDataTable}
						setAtualizar={setAtualizar}
					/>

					{error.flag ? (
						<ModalError
							show={error.flag}
							setShow={setError}
							title={error.title}
							list={error.msg}
						/>
					) : (
						""
					)}
					{historicoPneu.flag ? (
						<ModalHistoricoPneu
							fonte={"posicao_pneu"}
							show={historicoPneu.flag}
							setShow={setHistoricoPneu}
							{...historicoPneu}
							placa_caminhao={props.caminhao?.placa}
							manutencao_id={props.bodyList.id}
						/>
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant="info"
								onClick={() => {
									setShowCardLancamento((last) => !last);
								}}
							>
								{showCardLancamento ? (
									<span>Lancamentos</span>
								) : (
									<span>Tabela</span>
								)}
							</Button>
						</Col>
						<Col style={{ display: showCardLancamento ? "none" : "flex" }}>
							<Button variant={loading.style} onClick={onClickButton}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function TabelaItens(props) {
	const [error, setError] = useState({});
	const [headList, setHeadList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const tableDiv = useRef();
	const tbodyRef = useRef();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);
	const id_filial = user.tipo.toLowerCase() == "empresa" ? 2 : user.id_filial;
	const getColumns = async () => {
		try {
			const columns = await getTableColumns("ManutencaoPneu");
			setHeadList((last) => [
				"index",
				"admin",
				"status",
				...Object.keys(columns.data)
					.filter((item) => item !== "empresas_id")
					.filter((item) => item !== "created_at")
					.filter((item) => item !== "updated_at")
					.filter((item) => item !== "cpf_responsavel")
					.filter((item) => item !== "user_cancelamento")
					.filter((item) => item !== "data_cancelamento")
					.filter((item) => item !== "manutencao_id")
					.filter((item) => item !== "status"),
			]);
		} catch (error) {
			setError({
				flag: true,
				msg: { t: "Erro na obentção das Colunas" },
				title: "Aviso!",
			});
		}
	};

	const getDataL = useCallback(async (manutencao_id) => {
		const url = "manutencao/pneus";

		try {
			const data = await getAllData(url, { manutencao_id });
			setDataTable(
				data.data.map((item) => ({
					...item,
					emissao: item.emissao
						? new Date(item.emissao).toLocaleDateString("pt-br", {
								timeZone: "UTC",
						  })
						: "",
				}))
			);

			const date = new Date();
			const dateCorrect = getDateInitMonth(date);

			const url2 = "manutencao";

			let data1 = await getAllData(url2, { id_filial });
			dispatch(updateManutencoesList(data1.data));
			props.setDataTable(
				data.data
					.filter((item) => item.status == 0)
					.map((item) => ({
						id: item.id,
						numero_pneu: item.numero_pneu,
						tipo: item.tipo,
						km_previsao: Number(item.km_previsao).toLocaleString("pt-br"),
						posicao_atual: item.posicao_atual,
						posicao_previsao: item.posicao_previsao,
					}))
			);
		} catch (error) {
			setError({
				flag: true,
				msg: error,
				title: "Aviso!",
			});
		}
		setLoading(false);
	});

	// dados de entrada
	useEffect(() => {
		getColumns();
	}, []);

	useEffect(() => {
		getDataL(props.manutencao_id);
	}, [props.atualizar]);

	// scrollBar
	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [dataTable];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<div hidden={props.hidden} style={{ marginTop: "0", minWidth: "50%" }}>
			<div className="section-filters-table">
				<h3>Filtros Selecionados</h3>
				<hr />

				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Dados{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</Col>

						<Col sm="12" md="6" lg="3">
							<ExportToExcel
								tableName="Manutencoes_Items"
								reference={
									filteredTable.length ? filteredTable : [...dataTable]
								}
							/>
						</Col>
					</Row>
				</div>

				<div
					className="responsive-table"
					ref={tableDiv}
					style={{
						position: "relative",
						height: "600px",
						maxHeight: "unset",
						width: "inherit",
					}}
				>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={dataTable}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={self.length}
													setAtualizar={props.setAtualizar}
													dataTable={self.filter((item) => item.status == 0)}
												/>
											);
										}
								  })
								: dataTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setDataTable={setDataTable}
													tableLength={self.length}
													setAtualizar={props.setAtualizar}
													dataTable={self.filter((item) => item.status == 0)}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < dataTable.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			{error.flag ? (
				<ModalError
					title={error.title}
					show={error.flag}
					setShow={setError}
					list={error.msg}
				/>
			) : (
				""
			)}
		</div>
	);
}

function BodyRowLocal({
	headList,
	bodyList,
	index,
	setDataTable = () => {},
	tableLength = 0,
	setAtualizar = () => {},
	dataTable,
}) {
	const refRow = useRef(null);
	const dispatch = useDispatch();
	const userS = useSelector((state) => state.userSlice);
	const filiaisList = userS.filiaisList.data;

	const [pecasManutencao, setPecasManutencao] = useState({ falg: false });
	const [controleCancelamento, setControleCancelamento] = useState({
		falg: false,
	});
	const [historicoPneu, setHistoricoPneu] = useState({ flag: false });
	const [controleEdicao, setControleEdicao] = useState({ falg: false });
	const [error, setError] = useState({
		flag: false,
		title: "",
		msg: "",
	});

	const getData = useCallback(async (manutencao_id) => {
		const url = "manutencao/pneus";
		try {
			const data = await getAllData(url, { manutencao_id });
			setDataTable(
				data.data.map((item) => ({
					...item,
					emissao: item.emissao
						? new Date(item.emissao).toLocaleDateString("pt-br", {
								timeZone: "UTC",
						  })
						: "",
				}))
			);
		} catch (error) {
			setError({
				flag: true,
				msg: error,
				title: "Aviso!",
			});
		}
	});

	const onClickEditar = async (bodyUpdate) => {
		const url = "manutencao/pneus";
		await updateTable(url, bodyList.id, {
			...bodyUpdate,
			manutencao_id: bodyList.manutencao_id,
		});
		// getData(bodyList.manutencao_id)

		setAtualizar((last) => last + 1);
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
		// setCargaSelecionada({
		//     ...bodyList
		//     , disponivel: bodyList.status === 0 ? true : false
		// })
	};

	return (
		<Fragment>
			{
				<tr
					ref={refRow}
					style={{ verticalAlign: "middle" }}
					onClick={onClickTableRow}
				>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "tipo") {
							return (
								<td key={_index}>
									<div className="admin">
										<span
											style={{
												fontSize: "0.8rem",
												padding: "7px 15px",
												borderRadius: "10px",
												backgroundColor:
													bodyList.tipo == "CORRETIVA"
														? "#ff0000bf"
														: "#b7d972",
											}}
										>
											{bodyList[item]}
										</span>
									</div>
								</td>
							);
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										{/* <span className="excluir">
                                            <i className="bi bi-x-lg"
                                                title="Excluir"
                                                onClick={() => setControleCancelamento({ bodyList, flag: true, title: 'Cancelar Manutencao', fn: onClickCancelItem })}>
                                            </i>
                                        </span> */}
										{bodyList.status != "0" ? (
											""
										) : (
											<span className="editar" hidden={bodyList.status == "2"}>
												<i
													className="bi bi-pencil"
													title="Editar"
													onClick={() =>
														setControleEdicao({
															bodyList,
															flag: true,
															title: "Editar Manutencao",
															fn: onClickEditar,
														})
													}
												></i>
											</span>
										)}
										<span className="editar">
											<i
												className="bi bi-hourglass-split"
												title="Ver Histórico do pneu"
												onClick={() =>
													setHistoricoPneu({ bodyList, flag: true })
												}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "pecas") {
							return (
								<td key={_index}>
									<div className="admin align-items-center">
										<span className="editar">
											<i
												className="bi bi-plus-circle"
												title="Pecas do Iten de Manutenção"
												onClick={() =>
													setPecasManutencao({
														bodyList,
														flag: true,
														title: "Peças do Item",
														updateItem: getData,
													})
												}
											></i>
										</span>
										<span>{bodyList[item]}</span>
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "placa") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "id_filial") {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} className={item}>
									{bodyList[item] == 2 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-warning">
												Transferido
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 1 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-danger">
												Cancelado
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 0 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-info">Ativo</span>
										</div>
									) : (
										""
									)}
								</td>
							);
						} else if (item === "createdAt" || item === "updatedAt") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "10rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "observacoes") {
							/* primary key can not be modified */

							return (
								<td
									key={_index}
									style={{ minWidth: "10rem", cursor: "pointer" }}
									onClick={() => {
										if (!bodyList.observacoes) return;
										setError({
											title: "OBSERVAÇÕES DO LANÇAMENTO N°" + bodyList.id,
											msg: bodyList.observacoes,
											flag: true,
										});
									}}
								>
									{bodyList[item]
										? String(bodyList[item]).slice(0, 50) + "..."
										: "N/A"}
								</td>
							);
						} else if (item.includes("km")) {
							return (
								<td key={_index}>
									<div className="admin">
										{Number(bodyList[item]).toLocaleString("pt-br", {
											minimumFractionDigits: 2,
										})}
									</div>
								</td>
							);
						} else if (item === "valor") {
							return (
								<td key={_index}>
									<div className="admin">{valorMoeda(bodyList[item])}</div>
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}

			{controleCancelamento.flag ? (
				<ModalCancelamento
					{...controleCancelamento}
					setShow={setControleCancelamento}
				/>
			) : (
				""
			)}
			{controleEdicao.flag ? (
				<ModalEdicao
					{...controleEdicao}
					setShow={setControleEdicao}
					fonte={"EDICAO PNEUS"}
					dataTable={dataTable}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					show={error.flag}
					setShow={setError}
					list={error.msg}
					title={error.title}
				/>
			) : (
				""
			)}
			{historicoPneu.flag ? (
				<ModalHistoricoPneu
					show={historicoPneu.flag}
					setShow={setHistoricoPneu}
					{...historicoPneu}
				/>
			) : (
				""
			)}
			{pecasManutencao.flag ? (
				<ModalLancamentoPecasItens
					show={pecasManutencao.flag}
					setShow={setPecasManutencao}
					{...pecasManutencao}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function Toco({
	title = "CAMINHÃO",
	data = [],
	setHistoricoPneu = () => {},
	setError = () => {},
}) {
	const onClickPneu = (posicao) => {
		const dados =
			data.find(
				(item) =>
					item.posicao_atual == posicao || item.posicao_previsao === posicao
			) || [];
		setHistoricoPneu({ flag: true, bodyList: dados, posicao_pneu: posicao });
	};

	// useEffect(() => {
	//     )
	// }, [data])

	return (
		<div className="div-caminhoes">
			<div className="cabine" data-truck={title}></div>
			<div className="caminhao"></div>
			<div className="frente">
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P1")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P1") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P1")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P1")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						P1
					</div>
				</div>
				{/* <div className="esquerda esquerda-2">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P2') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P2') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P2') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P2')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P2')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P2') ? '#00ff95':""}}>P2</div>
                </div> */}
				{/* <div className="esquerda esquerda-3">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P3')} ></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P3') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P3') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P3')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P3')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P3') ? '#00ff95':""}}>P3</div>
                </div> */}
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P2")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P2") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P2")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P2")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						P2
					</div>
				</div>
			</div>
			<div className="frente frente-4">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P3")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P3") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P3")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P3")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						P3
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P4")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P4") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P4")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P4")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						P4
					</div>
				</div>
				<div className="esquerda esquerda-3">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P5")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P5")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P5") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P5")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P5")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P5")
								? "#00ff95"
								: "",
						}}
					>
						P5
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P6")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P6")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P6") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P6")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P6")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P6")
								? "#00ff95"
								: "",
						}}
					>
						P6
					</div>
				</div>
			</div>
			{/* <div className="frente frente-3">
                <div className="eixo-frontal"></div>
                <div className="modulo-redutor modulo-redutor-1"></div>
                <div className="esquerda esquerda-1">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P9') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P9') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P9') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P9')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P9')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P9') ? '#00ff95':""}}>P9</div>
                </div>
                <div className="esquerda esquerda-2">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P10') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P10') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P10') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P10')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P10')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P10') ? '#00ff95':""}}>P10</div>
                </div>
                <div className="esquerda esquerda-3">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P11') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P11') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P11') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P11')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P11')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P11') ? '#00ff95':""}}>P11</div>
                </div>
                <div className="esquerda esquerda-4">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P12') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P12') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P12') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P12')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P12')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P12') ? '#00ff95':""}}>P12</div>
                </div>
            </div> */}
			<div className="eixo-central"></div>
			<div className="eixo-frontal"></div>
			<div className="modulo-redutor modulo-redutor-1"></div>
		</div>
	);
}
function Truck({
	title = "CAMINHÃO",
	data = [],
	setHistoricoPneu = () => {},
	setError = () => {},
}) {
	const onClickPneu = (posicao) => {
		const dados =
			data.find(
				(item) =>
					item.posicao_atual == posicao || item.posicao_previsao === posicao
			) || [];
		setHistoricoPneu({ flag: true, bodyList: dados, posicao_pneu: posicao });
	};

	// useEffect(() => {
	//     )
	// }, [data])

	return (
		<div className="div-caminhoes">
			<div className="cabine" data-truck={title}></div>
			<div className="caminhao"></div>
			<div className="frente">
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P1")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P1") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P1")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P1")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						P1
					</div>
				</div>
				{/* <div className="esquerda esquerda-2">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P2') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P2') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P2') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P2')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P2')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P2') ? '#00ff95':""}}>P2</div>
                </div> */}
				{/* <div className="esquerda esquerda-3">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P3')} ></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P3') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P3') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P3')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P3')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P3') ? '#00ff95':""}}>P3</div>
                </div> */}
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P2")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P2") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P2")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P2")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						P2
					</div>
				</div>
			</div>
			<div className="frente frente-4">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P3")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P3") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P3")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P3")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						P3
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P4")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P4") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P4")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P4")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						P4
					</div>
				</div>
				<div className="esquerda esquerda-3">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P5")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P5")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P5") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P5")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P5")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P5")
								? "#00ff95"
								: "",
						}}
					>
						P5
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P6")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P6")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P6") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P6")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P6")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P6")
								? "#00ff95"
								: "",
						}}
					>
						P6
					</div>
				</div>
			</div>
			<div className="frente frente-3">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P7")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P7")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P7") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P7")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P7")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P7")
								? "#00ff95"
								: "",
						}}
					>
						P7
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P8")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P8")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P8") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P8")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P8")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P8")
								? "#00ff95"
								: "",
						}}
					>
						P8
					</div>
				</div>
				<div className="esquerda esquerda-3">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P9")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P9")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P9") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P9")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P9")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P9")
								? "#00ff95"
								: "",
						}}
					>
						P9
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P10")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P10")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P10") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P10")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P10")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P10")
								? "#00ff95"
								: "",
						}}
					>
						P10
					</div>
				</div>
			</div>
			<div className="eixo-central"></div>
			<div className="eixo-frontal"></div>
			<div className="modulo-redutor modulo-redutor-1"></div>
		</div>
	);
}
function Bitruck({
	title = "CAMINHÃO",
	data = [],
	setHistoricoPneu = () => {},
	setError = () => {},
}) {
	const onClickPneu = (posicao) => {
		const dados =
			data.find(
				(item) =>
					item.posicao_atual == posicao || item.posicao_previsao === posicao
			) || [];
		setHistoricoPneu({ flag: true, bodyList: dados, posicao_pneu: posicao });
	};

	// useEffect(() => {
	//     )
	// }, [data])

	return (
		<div className="div-caminhoes">
			<div className="cabine" data-truck={title}></div>
			<div className="caminhao"></div>
			<div className="frente">
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P1")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P1") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P1")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P1")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						P1
					</div>
				</div>
				{/* <div className="esquerda esquerda-2">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P2') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P2') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P2') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P2')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P2')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P2') ? '#00ff95':""}}>P2</div>
                </div>
                <div className="esquerda esquerda-3">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P3')} ></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P3') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P3') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P3')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P3')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P3') ? '#00ff95':""}}>P3</div>
                </div> */}
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P2")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P2") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P2")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P2")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						P2
					</div>
				</div>
			</div>

			<div className="frente frente-2">
				<div className="eixo-frontal"></div>
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P11")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P11")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P11") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P11")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P11")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P11")
								? "#00ff95"
								: "",
						}}
					>
						P11
					</div>
				</div>
				{/* <div className="esquerda esquerda-2">
                    <img alt='pneu' src={pneu} className="frente" onClick={() => onClickPneu('P14')}></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P14') ? '#00ff95' : "" }} >
                       
                       {
                       data.find(item => item.posicao_atual == 'P14') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P14')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P14')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                       }

                   
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P14') ? '#00ff95':""}}>P14</div>
                </div> */}
				{/* <div className="esquerda esquerda-3">
                    <img alt='pneu' src={pneu} className="frente" onClick={() => onClickPneu('P15')}></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P15') ? '#00ff95' : "" }} >
                       
                       {
                       data.find(item => item.posicao_atual == 'P15') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P15')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P15')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                       }

                   
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P15') ? '#00ff95':""}}>P15</div>
                </div> */}
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P12")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P12")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P12") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P12")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P12")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P12")
								? "#00ff95"
								: "",
						}}
					>
						P12
					</div>
				</div>
			</div>

			<div className="frente frente-4">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P3")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P3") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P3")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P3")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						P3
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P4")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P4") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P4")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P4")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						P4
					</div>
				</div>
				<div className="esquerda esquerda-3">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P5")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P5")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P5") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P5")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P5")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P5")
								? "#00ff95"
								: "",
						}}
					>
						P5
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P6")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P6")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P6") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P6")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P6")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P6")
								? "#00ff95"
								: "",
						}}
					>
						P6
					</div>
				</div>
			</div>
			<div className="frente frente-3">
				<div className="eixo-frontal"></div>
				<div className="modulo-redutor modulo-redutor-1"></div>
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P7")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P7")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P7") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P7")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P7")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P7")
								? "#00ff95"
								: "",
						}}
					>
						P7
					</div>
				</div>
				<div className="esquerda esquerda-2">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P8")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P8")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P8") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P8")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P8")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P8")
								? "#00ff95"
								: "",
						}}
					>
						P8
					</div>
				</div>
				<div className="esquerda esquerda-3">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P9")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P9")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P9") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P9")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P9")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P9")
								? "#00ff95"
								: "",
						}}
					>
						P9
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P10")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P10")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P10") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P10")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P10")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P10")
								? "#00ff95"
								: "",
						}}
					>
						P10
					</div>
				</div>
			</div>
			<div className="eixo-central"></div>
			<div className="eixo-frontal"></div>
			<div className="modulo-redutor modulo-redutor-1"></div>
		</div>
	);
}
function Carreta({
	title = "CAMINHÃO",
	data = [],
	setHistoricoPneu = () => {},
	setError = () => {},
	qtde_eixos,
}) {
	const onClickPneu = (posicao) => {
		const dados =
			data.find(
				(item) =>
					item.posicao_atual == posicao || item.posicao_previsao === posicao
			) || [];
		setHistoricoPneu({ flag: true, bodyList: dados, posicao_pneu: posicao });
	};

	useEffect(() => {}, [data]);

	return (
		<div className="div-caminhoes">
			<div className="caminhao"></div>

			{new Array(qtde_eixos).fill("").map((item, key) => (
				<div
					className="frente frente-4"
					style={{ top: `${500 - key * 130}px` }}
				>
					<div className="eixo-frontal"></div>
					<div className="modulo-redutor modulo-redutor-1"></div>
					<div className="esquerda esquerda-1">
						<img
							alt="pneu"
							src={pneu}
							className="frente"
							onClick={() => onClickPneu(`P${1 + key * 4}`)}
						></img>
						<div
							style={{
								backgroundColor: data.find(
									(item) => item.posicao_atual == `P${1 + key * 4}`
								)
									? "#00ff95"
									: "",
							}}
						>
							{data.find((item) => item.posicao_atual == `P${1 + key * 4}`) ? (
								<ul>
									{Object.keys(
										data.find((item) => item.posicao_atual == `P${1 + key * 4}`)
									).map((item) => (
										<li>
											{item}:
											{
												data.find(
													(item) => item.posicao_atual == `P${1 + key * 4}`
												)[item]
											}
										</li>
									))}{" "}
								</ul>
							) : (
								"NÃO CADASTRADO"
							)}
						</div>
						<div
							className="numero-pneu"
							style={{
								backgroundColor: data.find(
									(item) => item.posicao_atual == `P${1 * (key + 1)}`
								)
									? "#00ff95"
									: "",
							}}
						>{`P${1 + key * 4}`}</div>
					</div>
					<div className="esquerda esquerda-2">
						<img
							alt="pneu"
							src={pneu}
							className="frente"
							onClick={() => onClickPneu(`P${2 + key * 4}`)}
						></img>
						<div
							style={{
								backgroundColor: data.find(
									(item) => item.posicao_atual == `P${2 + key * 4}`
								)
									? "#00ff95"
									: "",
							}}
						>
							{data.find((item) => item.posicao_atual == `P${2 + key * 4}`) ? (
								<ul>
									{Object.keys(
										data.find((item) => item.posicao_atual == `P${2 + key * 4}`)
									).map((item) => (
										<li>
											{item}:
											{
												data.find(
													(item) => item.posicao_atual == `P${2 + key * 4}`
												)[item]
											}
										</li>
									))}{" "}
								</ul>
							) : (
								"NÃO CADASTRADO"
							)}
						</div>
						<div
							className="numero-pneu"
							style={{
								backgroundColor: data.find(
									(item) => item.posicao_atual == `P${2 + key * 4}`
								)
									? "#00ff95"
									: "",
							}}
						>{`P${2 + key * 4}`}</div>
					</div>
					<div className="esquerda esquerda-3">
						<img
							alt="pneu"
							src={pneu}
							className="frente"
							onClick={() => onClickPneu(`P${2 + key * 4}`)}
						></img>
						<div
							style={{
								backgroundColor: data.find(
									(item) => item.posicao_atual == `P${2 + key * 4}`
								)
									? "#00ff95"
									: "",
							}}
						>
							{data.find((item) => item.posicao_atual == `P${2 + key * 4}`) ? (
								<ul>
									{Object.keys(
										data.find((item) => item.posicao_atual == `P${2 + key * 4}`)
									).map((item) => (
										<li>
											{item}:
											{
												data.find(
													(item) => item.posicao_atual == `P${2 + key * 4}`
												)[item]
											}
										</li>
									))}{" "}
								</ul>
							) : (
								"NÃO CADASTRADO"
							)}
						</div>
						<div
							className="numero-pneu"
							style={{
								backgroundColor: data.find(
									(item) => item.posicao_atual == `P${3 + key * 4}`
								)
									? "#00ff95"
									: "",
							}}
						>{`P${3 + key * 4}`}</div>
					</div>
					<div className="esquerda esquerda-4">
						<img
							alt="pneu"
							src={pneu}
							className="frente"
							onClick={() => onClickPneu(`P${3 + key * 4}`)}
						></img>
						<div
							style={{
								backgroundColor: data.find(
									(item) => item.posicao_atual == `P${3 + key * 4}`
								)
									? "#00ff95"
									: "",
							}}
						>
							{data.find((item) => item.posicao_atual == `P${3 + key * 4}`) ? (
								<ul>
									{Object.keys(
										data.find((item) => item.posicao_atual == `P${3 + key * 4}`)
									).map((item) => (
										<li>
											{item}:
											{
												data.find(
													(item) => item.posicao_atual == `P${3 + key * 4}`
												)[item]
											}
										</li>
									))}{" "}
								</ul>
							) : (
								"NÃO CADASTRADO"
							)}
						</div>
						<div
							className="numero-pneu"
							style={{
								backgroundColor: data.find(
									(item) => item.posicao_atual == `P${4 + key * 4}`
								)
									? "#00ff95"
									: "",
							}}
						>{`P${4 + key * 4}`}</div>
					</div>
				</div>
			))}

			{/* <div className="frente frente-3">
                <div className="eixo-frontal"></div>
                <div className="modulo-redutor modulo-redutor-1"></div>
                <div className="esquerda esquerda-1">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P7') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P7') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P7') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P7')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P7')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P7') ? '#00ff95':""}}>P7</div>
                </div>
                <div className="esquerda esquerda-2">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P8') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P8') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P8') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P8')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P8')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P8') ? '#00ff95':""}}>P8</div>
                </div>
                <div className="esquerda esquerda-3">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P9') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P9') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P9') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P9')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P9')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P9') ? '#00ff95':""}}>P9</div>
                </div>
                <div className="esquerda esquerda-4">
                    <img alt='pneu' src={pneu} className="frente"  onClick={() => onClickPneu('P10') }></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P10') ? '#00ff95' : "" }} >
                       
                            {
                            data.find(item => item.posicao_atual == 'P10') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P10')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P10')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                            }

                        
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P10') ? '#00ff95':""}}>P10</div>
                </div>
            </div> */}
			<div className="eixo-central"></div>
		</div>
	);
}

function CavaloMecanico({
	title = "CAMINHÃO",
	data = [],
	setHistoricoPneu = () => {},
	setError = () => {},
}) {
	const onClickPneu = (posicao) => {
		const dados =
			data.find(
				(item) =>
					item.posicao_atual == posicao || item.posicao_previsao === posicao
			) || [];
		setHistoricoPneu({ flag: true, bodyList: dados, posicao_pneu: posicao });
	};

	// useEffect(() => {
	//     )
	// }, [data])

	return (
		<div className="div-caminhoes">
			<div className="cabine" data-truck={title}></div>
			<div className="caminhao" style={{ height: "200px" }}></div>
			<div className="frente">
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P1")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P1") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P1")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P1")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P1")
								? "#00ff95"
								: "",
						}}
					>
						P1
					</div>
				</div>
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P2")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P2") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P2")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P2")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P2")
								? "#00ff95"
								: "",
						}}
					>
						P2
					</div>
				</div>
			</div>

			<div className="frente frente-2" style={{ top: "200px" }}>
				<div className="eixo-frontal"></div>
				<div className="esquerda esquerda-1">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P3")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P3") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P3")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P3")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P3")
								? "#00ff95"
								: "",
						}}
					>
						P3
					</div>
				</div>
				{/* <div className="esquerda esquerda-2">
                    <img alt='pneu' src={pneu} className="frente" onClick={() => onClickPneu('P14')}></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P14') ? '#00ff95' : "" }} >
                       
                       {
                       data.find(item => item.posicao_atual == 'P14') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P14')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P14')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                       }

                   
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P14') ? '#00ff95':""}}>P14</div>
                </div> */}
				{/* <div className="esquerda esquerda-3">
                    <img alt='pneu' src={pneu} className="frente" onClick={() => onClickPneu('P15')}></img>
                    <div style={{ backgroundColor: data.find(item => item.posicao_atual == 'P15') ? '#00ff95' : "" }} >
                       
                       {
                       data.find(item => item.posicao_atual == 'P15') ? <ul>{Object.keys(data.find(item => item.posicao_atual == 'P15')).map(item => <li>{item}:{data.find(item => item.posicao_atual == 'P15')[item]}</li>)} </ul>: 'NÃO CADASTRADO'
                       }

                   
                    </div>
                    <div className="numero-pneu" style={{backgroundColor: data.find(item => item.posicao_atual == 'P15') ? '#00ff95':""}}>P15</div>
                </div> */}
				<div className="esquerda esquerda-4">
					<img
						alt="pneu"
						src={pneu}
						className="frente"
						onClick={() => onClickPneu("P4")}
					></img>
					<div
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						{data.find((item) => item.posicao_atual == "P4") ? (
							<ul>
								{Object.keys(
									data.find((item) => item.posicao_atual == "P4")
								).map((item) => (
									<li>
										{item}:
										{data.find((item) => item.posicao_atual == "P4")[item]}
									</li>
								))}{" "}
							</ul>
						) : (
							"NÃO CADASTRADO"
						)}
					</div>
					<div
						className="numero-pneu"
						style={{
							backgroundColor: data.find((item) => item.posicao_atual == "P4")
								? "#00ff95"
								: "",
						}}
					>
						P4
					</div>
				</div>
			</div>

			<div className="eixo-central" style={{ height: "230px" }}></div>
			<div className="eixo-frontal"></div>
			<div className="modulo-redutor modulo-redutor-1"></div>
		</div>
	);
}

// function Bitruck({ title = 'CAMINHÃO'}) {
//     return (
//         <div className="div-caminhoes">
//             <div className="cabine" data-truck={title}></div>
//             <div className="caminhao"></div>
//             <div className="frente">
//                 <div className="esquerda esquerda-1">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P1</div>
//                 </div>
//                 <div className="esquerda esquerda-2">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P2</div>
//                 </div>
//                 <div className="esquerda esquerda-3">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P3</div>
//                 </div>
//                 <div className="esquerda esquerda-4">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P4</div>
//                 </div>
//             </div>
//             <div className="frente frente-2">
//                 <div className="eixo-frontal"></div>
//                 <div className="esquerda esquerda-1">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P13</div>
//                 </div>
//                 <div className="esquerda esquerda-2">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P14</div>
//                 </div>
//                 <div className="esquerda esquerda-3">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P15</div>
//                 </div>
//                 <div className="esquerda esquerda-4">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P16</div>
//                 </div>
//             </div>
//             <div className="frente frente-3">
//                 <div className="eixo-frontal"></div>
//                 <div className="modulo-redutor modulo-redutor-1"></div>
//                 <div className="esquerda esquerda-1">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P9</div>
//                 </div>
//                 <div className="esquerda esquerda-2">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P10</div>
//                 </div>
//                 <div className="esquerda esquerda-3">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P11</div>
//                 </div>
//                 <div className="esquerda esquerda-4">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P12</div>
//                 </div>
//             </div>
//             <div className="frente frente-4">
//                 <div className="eixo-frontal"></div>
//                 <div className="modulo-redutor modulo-redutor-1"></div>
//                 <div className="esquerda esquerda-1">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P5</div>
//                 </div>
//                 <div className="esquerda esquerda-2">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P6</div>
//                 </div>
//                 <div className="esquerda esquerda-3">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P7</div>
//                 </div>
//                 <div className="esquerda esquerda-4">
//                     <img alt='pneu' src={pneu} className="frente"></img>
//                     <div>informacoes</div>
//                     <div className="numero-pneu">P8</div>
//                 </div>
//             </div>
//             <div className="eixo-central"></div>
//             <div className="eixo-frontal"></div>
//             <div className="modulo-redutor modulo-redutor-1"></div>
//         </div>
//     )
// }
