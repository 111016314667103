import { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"

import { updateTable } from "../../services/Servicos"

import './Modal.scss'
import { useDispatch, useSelector } from "react-redux"
import { updateNotificacoesList } from "../../redux/slicers/user"

export default function ModalAtualizarRecebimentoDinheiro(props) {
    const [loading, setLoading] = useState({
        isLoading: false,
        msg: "Confirmar",
        style: 'primary'
    })
    
    const notificacoes = useSelector(state => state.userSlice.notificacoesList.data)
    const dispatch = useDispatch()

    const handlerSimpleClose = () => props.setShow(last => ({...last, show: false }))


    useEffect(() => {
        // do something with notification
        updateTable('notificacoes', props.id, { data_leitura_msg: new Date().toISOString(), status: 1 })
        const updatedArr = [...notificacoes.filter(item => item.id !== props.id)]

        dispatch(updateNotificacoesList(updatedArr))
    }, [])
    

    return (
        <>
            <Modal
                show={props.show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='sm'
            >
                <Modal.Header closeButton style={{border:'none'}}>

                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <h3 style={{ fontSize: "16px", textAlign: 'center', marginBottom: '35px' }}>Notificação Recebida N° { props.id }</h3>
                        <h3 style={{marginBottom:"35px", fontSize:"14px", textAlign:'center', fontWeight:'100'}}>"{props.msg}"</h3>
                        {/* <Row style={{justifyContent:'center', marginBottom:'20px'}}>

                            <Col sm='5'>
                                <Button variant={loading.style} onClick={clickSalvar} disabled={loading.style !== 'primary' ? true : false}>
                                    {
                                        loading.isLoading ?
                                        loading.msg:
                                        loading.msg
                                    }
                                </Button>
                            </Col>
                            <Col sm='5'>
                                <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                            </Col>
                        </Row> */}
                    </div>
                    </Modal.Body>
            </Modal>
        </>
    )
}
