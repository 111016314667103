import { createRef, useEffect, useState } from "react"
import { Col, Row, Spinner, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { updateDiarioList } from "../../redux/slicers/user"
import { getAllData, getTableColumns } from "../../services/Servicos"
import CardLayoutNavbar from "../card/CardLayoutNavbar"
import Loading from "../loading/Loading"
import BodyRowDiarios from "../table/body/BodyRowDiarios"
import HeadTable from "../table/head/HeadRow"
import { filtroDinamicoArrays, removerDadosDuplicados } from "../../services/helpers"

// const headList = ['index', 'nome', 'cpf', 'ano', 'mes', 'dia', 'ponto', 'createdAt', 'updatedAt']
const tableRef = createRef()
const tbodyRef = createRef()

export default function ConsultarDiarioGestor() {
    const user = useSelector(state => state.userSlice)
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [indexList, setIndexList] = useState(10)
    const [filteredTable, setFilteredTable] = useState(false)
    const [filters, setFilters] = useState([])
    const [headList, setHead] = useState(['index'])


    useEffect(() => {
        const refTable = tableRef.current
        const scrollHandler = (e) => {
            const tbodyHeight = tbodyRef.current.clientHeight
            const divTableHeight = e.target.clientHeight
            const scrollPosition = e.target.scrollTop
            if (scrollPosition >= tbodyHeight - divTableHeight) {
                setTimeout(() => {
                    setIndexList(last => last + 10)
                }, 1000)
            }

        }

        refTable.addEventListener('scroll', scrollHandler, false)

        return () => refTable.removeEventListener('scroll', scrollHandler, false)
    })


    const onClickRemoveFilter = (e) => {
        e.preventDefault()
        const value = e.target.parentNode.firstElementChild.innerText
        const [key, keyValue] = value.split(':')
        setFilters(last => ([...last.filter(item => String(item[key]).replaceAll(' ','') !== keyValue.replaceAll(' ',''))]))

    }


    const updateArrayPontos = async () => {
        const dados =  await getAllData('diarios/empresas')
        dispatch(updateDiarioList(dados.data))
    }

    const updateTableHead = async () => {
        const cargasGet =  await getTableColumns('Diarios')
        setHead(last => ([
            ...last, 
            ...Object
                .keys(cargasGet.data)
                // .filter(key => key !== 'id')
                .filter(key => key !== 'created_at')
                .filter(key => key !== 'updated_at')
                .filter(key => key !== 'empresas_id')
            , 'horario'
        ]))
        
        updateArrayPontos()
    }

    useEffect(() => {
        updateTableHead()
    }, [])
    
    
    const [filtersNew, setFiltersNew] = useState({})
    useEffect(() => {
        if(!filters.length){
            setIndexList(10)
            setFilteredTable(false)
            return
        }
        let testeFilters = {}

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            testeFilters[param] = []
        })

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            // testeFilters[param].push(ele[param])
            testeFilters[param].push(String(ele[param]).toLowerCase())
            
            if (String(ele[param]).toLowerCase() == 'false') {
                testeFilters[param].push('null')
                
            }
        })

        
        let arrayRetorno = [user.diariosList.data]

        Object.keys(testeFilters).forEach((key, index) => {
            arrayRetorno.push(filtroDinamicoArrays(testeFilters, key, arrayRetorno[arrayRetorno.length - 1], ))
        })

        const arrayF = removerDadosDuplicados(arrayRetorno[arrayRetorno.length - 1], 'id')
        setFilteredTable(arrayF.reverse())

    }, [filters])


    return (
        <CardLayoutNavbar>
            
            {
                filteredTable.length ? 
                <div className="section-filters-table">
                    <Row className='mt-2 row-filters'>
                        <Col>
                            <div>
                                {
                                    filters.map((item, key) =>
                                        Object
                                            .keys(item)
                                            .map((key, index) => {
                                                return <span className="filters-card" key={index}>
                                                    <span className='filters'>
                                                        <span className='title'>
                                                            {key}: {item[key]}
                                                        </span>
                                                        <i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
                                                    </span>
                                                </span>
                                            })
                                    )
                                }

                            </div>
                        </Col>
                    </Row>
                </div>
                    : ""
            }
            <div className="section-filters-table">
                <h3 style={{ display: 'flex', alignItems: 'center' }}>
                    Tabela de Relatórios {headList.length < 5 ? <Spinner style={{ marginLeft: '10px', fontSize: '1rem' }} animation="border" size="sm" /> : ""}
                </h3>

                <hr />
                <div className=" responsive-table" ref={tableRef} style={{position: 'relative',height:'600px', maxHeight:'unset'}}>
                    <Table hover size='sm'>
                        <thead>
                            <HeadTable  arrayValues={ user.diariosList.data } headerList={headList} setArrayFilters={setFiltersNew} arrayFilters={filtersNew} setFiltersOld={setFilters} filtersOld={filters} />
                        </thead>
                        <tbody ref={tbodyRef}>

                            {
                                filteredTable ?
                                    filteredTable.map((item, index) => {
                                        if (index < indexList) {
                                            return <BodyRowDiarios key={index} bodyList={item} headList={headList} index={index} setArrayFilters={setFiltersNew} arrayFilters={filtersNew} />
                                        }

                                    })
                                    :
                                    user.diariosList.data.map((item, index) => {
                                        if (index < indexList && headList.length > 2) {
                                            return <BodyRowDiarios key={index} bodyList={item} headList={headList} index={index}  setArrayFilters={setFiltersNew} arrayFilters={filtersNew} />
                                        }

                                    })
                            }

                        </tbody>
                    </Table>
                    {/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
                    {
                        filteredTable ?
                            indexList < filteredTable.length ? <Loading color={'black'} msg='Loading' /> : ""
                            :
                            indexList < user.diariosList.data.length ? <Loading color={'black'} msg='Loading' /> : ""
                    }
                </div>
            </div>
        </CardLayoutNavbar>
    )
}
