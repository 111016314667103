import { createRef, useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../loading/Loading";
import HeadTable from "../../table/head/HeadRow";
import {
	getAllData,
	getData,
	getStatusPedidosTransferencia,
	getTableColumns,
	getTransferencias,
} from "../../../services/Servicos";
import {
	updateTransferencias,
	updatetransferencias,
} from "../../../redux/slicers/user";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	getErrorMessage,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ModalError from "../../modal/ModalError";
import TransferenciasLayout from "../layout/TransferenciasLayout";
import ModalLoading from "../../modal/ModalLoading";
import "../Transferencias.scss";
import ModalListarProdutosTransferencias from "../../modal/ModalListarProdutosTransferencias";
import ModalListarProdutosTransferenciasRelatorio from "../../modal/ModalListarProdutosTransferenciasRelatorio";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

const tbodyRef = createRef();
const tableDiv = createRef();

export default function RelatorioTransferencias({
	setTransferenciaSelecionada = () => {},
	headList_ = [],
	cargas_id,
}) {
	const filiaisCadastradas = useSelector(
		(state) => state.userSlice.filiaisList.data
	);

	const [loading, setLoading] = useState(true);

	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [error, setError] = useState({ flag: false, msg: "", title: "" });

	const [data, setData] = useState([]);
	const [filiais, setFiliais] = useState([]);
	const [filiaisDestino, setFiliaisDestino] = useState([]);

	const [produtosModal, setProdutosModal] = useState({
		flag: false,
		numero_pedido: "",
	});

	useEffect(() => {
		setLoading(true);
		getAllData("dashboard/empresa/relatorio-transferencias")
			.then((resp) => {
				setData(resp.data);
				setFiliais(
					resp.data.filter(
						(i, n, s) => s.findIndex((o) => o.id_filial === i.id_filial) == n
					)
				);
				console.log(
					resp.data.filter(
						(i, n, s) =>
							s.findIndex((o) => o.id_filial_destino === i.id_filial_destino) ==
							n
					)
				);
				setFiliaisDestino(
					resp.data.filter(
						(i, n, s) =>
							s.findIndex(
								(o) =>
									o.id_filial === i.id_filial &&
									o.id_filial_destino === i.id_filial_destino
							) == n
					)
				);
			})
			.catch((err) => {
				setError({
					show: true,
					message: getErrorMessage(err),
				});
			})
			.finally(() => setLoading(false));
	}, []);

	const onClickPlusRow = (e, bodyList) => {
		e.preventDefault();

		setProdutosModal({
			flag: true,
			transferencia: bodyList.transferencia,
			pedido: bodyList,
		});
	};

	return (
		<TransferenciasLayout>
			<Row className="align-items-center" style={{ marginBottom: "1.5rem" }}>
				{filiais &&
					filiais.map((i, k, s) => {
						return (
							<div className="mb-5">
								<Col
									sm="12"
									style={{
										border: "solid 1px black",
										textAlign: "center",
										backgroundColor: "#afafc0",
										padding: "0.55rem",
										marginBottom: "0.5rem",
									}}
									key={k}
								>
									{
										filiaisCadastradas.find(
											(item) => item.idFilial == i.id_filial
										)?.identificacaoInterna || `Filial ${String(i.id_filial).padStart(2,'0')}`
									}
								</Col>
								<Row className="mx-auto justify-content-center">
									{filiaisDestino.map((i1, k1, s1) => {
										if (i.id_filial !== i1.id_filial) return;
										return (
											<Col
												sm="12"
												md="4"
												lg="3"
												xl="2"
												xxl="auto"
												className="card-transferencia"
											>
												{
													filiaisCadastradas.find(
														(item) => item.idFilial == i1.id_filial_destino
													)?.identificacaoInterna  || `Filial ${String(i.id_filial_destino).padStart(2,'0')}`
												}
												<hr></hr>
												<Row className="mx-auto justify-content-center">
													{data.map((i2, k2, s2) => {
														if (
															i2.id_filial !== i1.id_filial ||
															i2.id_filial_destino !== i1.id_filial_destino
														)
															return;
														return (
															<Col
																sm="12"
																className="transferencia"
																key={k2}
																onClick={(e) => onClickPlusRow(e, i2)}
															>
																<ul style={{ textAlign: "left" }}>
																	<li>
																		<b>{i2.transferencia}</b>
																	</li>
																	<li>
																		<b>{i2.tipo}</b>
																	</li>
																	<li>
																		Emissao:{" "}
																		<b>
																			{new Date(i2.emissao).toLocaleDateString(
																				"pt-br"
																			)}
																		</b>
																	</li>
																	<li>
																		<b>{i2.descricao}</b>
																	</li>
																	<br />
																	<br />
																	<li>
																		Produtos Planejados:{" "}
																		<b>
																			{Number(i2.qtde_itens).toLocaleString(
																				"pt-br"
																			)}
																		</b>
																	</li>
																	<li>
																		Peso Planejado:{" "}
																		<b>
																			{Number(
																				i2.peso_planejado_produtos
																			).toLocaleString("pt-br")}
																		</b>
																	</li>
																	<li>
																		Qtde Planejada:{" "}
																		<b>
																			{Number(
																				i2.qtde_planejada_produtos
																			).toLocaleString("pt-br")}
																		</b>
																	</li>
																	<br />
																	<br />
																	<li>
																		Produtos não Realizados:{" "}
																		<b>
																			{Number(
																				i2.qtde_itens - i2.qtde_itens_separados
																			).toLocaleString("pt-br")}
																		</b>
																	</li>
																	<li>
																		Peso não Realizado:{" "}
																		<b>
																			{Number(
																				i2.peso_planejado_produtos -
																					i2.peso_real_produtos
																			).toLocaleString("pt-br")}
																		</b>
																	</li>
																	<li>
																		Qtde não Realizada:{" "}
																		<b>
																			{Number(
																				i2.qtde_planejada_produtos -
																					i2.qtde_real_produtos
																			).toLocaleString("pt-br")}
																		</b>
																	</li>
																	<br />
																	<br />
																</ul>
																<b style={{ fontSize: "0.7rem" }}>
																	{getStatusPedidosTransferencia(i2.status)}
																</b>
															</Col>
														);
													})}
												</Row>
											</Col>
										);
									})}
								</Row>
							</div>
						);
					})}
			</Row>
			{loading ? <ModalLoading setShow={setLoading} show={loading} /> : ""}
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			{produtosModal.flag ? (
				<ModalListarProdutosTransferenciasRelatorio
					msg={""}
					title={"Produtos do Pedido"}
					show={produtosModal}
					setShow={setProdutosModal}
					pedido={produtosModal.pedido}
				/>
			) : (
				""
			)}
		</TransferenciasLayout>
	);
}
