import { Fragment, useEffect, useRef, useState } from "react"
import { Button, Col, Form, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import './Modal.scss'
import Loading from "../loading/Loading"


export default function ModalFinalizarPedidos({ show, setShow, pedidos, callback = false }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [pedidosAtualizado, setPedidosAtualizados] = useState([])
    const [loading, setLoading] = useState({
        flag: false,
        style: 'warning',
        msg:'Sim'
    })

    const user = useSelector(state => state.userSlice)
    const filiaisList = user.filiaisList.data 
    
    const handlerSimpleClose = () => setShow(last => ({ ...last, flag: false })) 
    const encerrarPedidos = async () => {
        let qtde = 0
        let qtdeRecusa = 0
        setLoading({
            flag: true, style:'info', msg: 'Atualizando...'
        })
        for await (let pedido of pedidos) {
            
                await axios
                    .get(`${process.env.REACT_APP_API_VST}st/1/id_filial/${pedido.idFilial}/id_saida/${pedido.codigoPedido}?status=20`)
                    .then(res => {
                        qtde++
                        setPedidosAtualizados(last => [
                            ...last,
                            `pedido ${pedido.codigoPedido} 
                            da filial ${filiaisList.find(item => item.idFilial == pedido.idFilial)?.identificacaoInterna ?? 'N/A'} 
                            Encerrado Manualmente: ${qtde}/${pedidos.length}`
                        ])
                    })
                    .catch(err => {
                        qtdeRecusa++
                        setPedidosAtualizados(last => [
                            ...last,
                            `pedido ${pedido.codigoPedido} da filial ${filiaisList.find(item => item.idFilial == pedido.idFilial)?.identificacaoInterna ?? 'N/A'} NÃO 
                            Encerrado Manualmente: ${qtdeRecusa}/${pedidos.length}`
                        ])
                    })
        }

        setLoading({
            flag: false, style:'success', msg: 'Atualizado'
        })

        if (callback) {
            callback()
            return 
        }
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Encerramento de Pedidos Manualmente</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    overflow: 'auto',
                    margin: '0 20px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <p className="text-center">Voce esta prestes a encerrar <b style={{ fontSize: '1.5rem' }}>{pedidos.length}</b> pedidos de venda! Deseja Continuar?</p>
                    {
                        pedidosAtualizado.length ? 
                        <>
                            <br />
                            <br />
                            <br />
                                <ul
                                    style={{
                                        height: '150px',
                                        overflow: 'auto',
                                        border: '0.1px solid black',
                                        paddingBlock:'10px',
                                        paddingRight:'20px',
                                        width:'fit-content'
                                    }}>
                                    {pedidosAtualizado.map((item, key) => <li key={key}>{item}</li>)}
                                </ul>
                        </>
                        :
                        ""}
                </Modal.Body>
                <Modal.Footer className="d-flex flex-nowrap px-2 mx-2 justify-content-center">
                    <Col sm='6'>
                        <Button
                            variant={loading.style}
                            onClick={encerrarPedidos}
                            disabled={loading.style != 'warning'}
                            className="d-flex justify-content-center align-items-center"
                        >
                            {loading.msg}
                            {loading.style == 'success' ? <i className="bi bi-check"></i>:''}
                            {loading.flag ? <Loading color={'black'} />:''}
                        </Button>
                    </Col>
                    <Col sm='6'>
                        <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>
    )
}
