import { Button, Spinner } from "react-bootstrap";

export default function BotaoSalvar({ msg, style, fnRetorno}){

    return (
        <Button variant={style} onClick={ fnRetorno }>
            {msg}
            <span className="ms-2">
                {style == 'success' ? <i className="bi bi-check2" style={{color:"white"}}></i>:''}
                {style == 'warning' ? <Spinner color="black" animation="border" size="sm"/>:''}
            </span>
        </Button>
    )
}