import { Button, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { QRCodeSVG } from "qrcode.react"
import './Modal.scss'


export default function ModalErrorTotal({ msg, title, show, setShow, receberNotificacoes }) {

    const handlerSimpleClose = () => setShow(last => ({...last, flag: false, msg:""}))

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msg}
                    {receberNotificacoes && <div>
                        <br/>
                        <br/>
                        <p>Compartilhe este QR Code para o recebimento das notificações</p>
                        <p className="text-center"> <QRCodeSVG value={'https://wa.me//5565998150052?text=Receber Notificações'}/></p>
                    </div> }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={handlerSimpleClose}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
