import { createRef, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";
import { resetPassword } from "../../services/Servicos";

const form = createRef(null);

export function ForgotPassword() {
	const navigate = useNavigate();
	const [btnLoading, setBtnLoading] = useState({
		flag: false,
		msg: "Enviar Código",
		style: "primary",
	});
	const [error, setError] = useState({
		flag: false,
		msg: "",
	});
	const onChangeHandler = (e) => {
		e.preventDefault();
		setError({
			flag: false,
			msg: "",
		});
	};

	const onSubmitHandler = (e) => {
		e.preventDefault();
		setBtnLoading({
			flag: true,
			msg: "Loading",
		});
		//
		resetPassword({
			email: form.current.user_email.value,
			link: window.location.origin,
		})
			.then((response) => {
				setBtnLoading({
					flag: false,
					msg: "Código Enviado",
					style: "success",
				});
				setError({
					flag: true,
					msg: response.msg,
				});
				setTimeout(() => {
					navigate("/login");
				}, 2000);
			})
			.catch((error) => {
				setError({
					flag: true,
					msg: error.msg.msg,
				});
				setBtnLoading({
					flag: false,
					msg: "Enviar Código",
				});
			});
	};

	return (
		<>
			<Row className="login mx-auto">
				<Col lg={10} className="mx-auto">
					<Form className="login-form" ref={form} onSubmit={onSubmitHandler}>
						<Row>
							<h1>
								Recuperação de Senha ou{" "}
								<span>
									<a href="/login">Entrar</a>
								</span>
							</h1>
						</Row>
						<hr />
						<Form.Group className="mb-3">
							<Form.Label>Email:</Form.Label>
							<Form.Control
								required
								type="email"
								name="user_email"
								onChange={onChangeHandler}
							/>
						</Form.Group>
						<Form.Group className="mb-3" hidden>
							<Form.Control
								name="message"
								value={"ola josé alexandre"}
								onChange={(e) => e.preventDefault()}
							/>
						</Form.Group>
						{error.flag ? (
							<Form.Text className="text-muted error-form ">
								{error.msg}
							</Form.Text>
						) : (
							""
						)}
						<br />
						<br />
						<Button
							variant={btnLoading.style}
							type="submit"
							disabled={btnLoading.style === "success"}
						>
							{btnLoading.flag ? (
								<Loading msg={"Enviando Código "} />
							) : (
								<span style={{ color: "#ffffff" }}>
									{btnLoading.msg}
									{btnLoading.style === "success" ? (
										<i className="bi bi-check"></i>
									) : (
										""
									)}
								</span>
							)}
						</Button>
					</Form>
				</Col>
			</Row>
		</>
	);
}
