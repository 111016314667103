import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Loading from "../../loading/Loading";
import { createEquipamento, getEquipamentos } from "../../../services/Servicos";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EquipamentoLayout from "../layout/EquipamentoLayout";
import { telefoneMask } from "../../../services/helpers";

export default function EquipamentoCadastro() {
	const userS = useSelector((state) => state.userSlice);
	const funcionarios = userS.funcionariosList.data;

	const [equipamento, setEquipamento] = useState({
		descricao: "",
		tipo: "",
		cpf_responsavel: "",
		nome_responsavel: "",
	});

	const [loading, setLoading] = useState({
		flag: false,
		style: "primary",
	});

	const onChangeInput = (e) => {
		e.preventDefault();
		if (e.target.name == "nome_responsavel") {
			setEquipamento((last) => ({
				...last,
				cpf_responsavel: funcionarios.find(
					(funcionario) => funcionario.name == e.target.value
				)?.cpf,
			}));
		} else if (e.target.name == "telefone") {
			setEquipamento((last) => ({
				...last,
				[e.target.name]: telefoneMask(e.target.value),
			}));
			return;
		}
		setEquipamento((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const updateEquipamentos = () => {
		getEquipamentos(userS.tipo)
			.then((response) => {
				// dispatch(updateEquipamentos(response.data))
				window.location.replace("/equipamentos/cadastrados");
			})
			.catch((error) => {});
	};

	const submitHandler = (e) => {
		e.preventDefault();

		setLoading({
			flag: true,
			style: "warning",
		});

		createEquipamento(userS.tipo, equipamento)
			.then((response) => {
				setLoading({
					flag: false,
					style: "success",
				});
				updateEquipamentos();
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	return (
		<EquipamentoLayout>
			<Form onSubmit={submitHandler}>
				<div className="card-header">
					<h5>Dados do Equipamento</h5>
				</div>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Tipo:</Form.Label>
						<Form.Select
							type="text"
							placeholder=""
							name="tipo"
							value={equipamento.tipo}
							onChange={onChangeInput}
							required
						>
							<option value=""></option>
							<option value="TABLET">Tablet</option>
							<option value="IMPRESSORA">Impressora</option>
							<option value="CELULAR">Celular</option>
							<option value="OUTROS">Outros</option>
						</Form.Select>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Descricao:</Form.Label>
						<Form.Control
							type="text"
							name="descricao"
							placeholder=""
							value={equipamento.descricao}
							onChange={onChangeInput}
							required
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>IMEI:</Form.Label>
						<Form.Control
							type="text"
							name="imei"
							placeholder=""
							value={equipamento.imei}
							onChange={onChangeInput}
							required
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Telefone:</Form.Label>
						<Form.Control
							type="text"
							name="telefone"
							placeholder=""
							maxLength={15}
							value={equipamento.telefone}
							onChange={onChangeInput}
							required
						/>
					</Col>
				</Row>
				<div className="card-header mt-3">
					<h5>Dados do Usuário</h5>
				</div>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Responsável:</Form.Label>
						<Form.Select
							type="text"
							name="nome_responsavel"
							value={equipamento.nome_responsavel}
							onChange={onChangeInput}
						>
							<option value=""></option>
							{funcionarios.map((item, key) => (
								<option value={item.name} key={key}>
									{" "}
									{item.tipo} - {item.name}{" "}
								</option>
							))}
						</Form.Select>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>CPF Responsavel:</Form.Label>
						<Form.Control
							type="text"
							placeholder=""
							value={equipamento.cpf_responsavel}
							disabled
						/>
					</Col>
				</Row>

				<Row className="mx-auto justify-content-center my-5">
					<Col md="3" className="mb-3">
						<Button variant={loading.style} type="submit">
							{loading.flag ? (
								<Loading msg="Salvando" />
							) : loading.style === "success" ? (
								"Salvo"
							) : (
								"Salvar"
							)}
							{loading.style === "success" ? (
								<i className="bi bi-check"></i>
							) : (
								""
							)}
						</Button>
					</Col>
					<Col md="3" className="mb-3">
						<Button variant="secondary" type="reset">
							Apagar
						</Button>
					</Col>
				</Row>
			</Form>
		</EquipamentoLayout>
	);
}
