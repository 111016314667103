import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CardLayoutNavbar from "../card/CardLayoutNavbar";
import { createRef, useEffect, useLayoutEffect, useState } from "react";
import { createPontos, getAllData, getDateInitMonth } from "../../services/Servicos";
import { updatePontosList } from "../../redux/slicers/user";
import BodyRow from "../table/body/BodyRow";
import Loading from "../loading/Loading";
import HeadTable from "../table/head/HeadRow";
import { filtroDinamicoArrays, removerDadosDuplicados } from "../../services/helpers";
import ModalLoading from "../modal/ModalLoading";


const headList = ['index', 'nome', 'ent_sai', 'dia_semana', 'ponto_data', 'ponto_hora', 'data_cancelamento', 'ajuste']
const diasDaSemana = ['Domingo', 'Segunda-Feira','Terça-Feira','Quarta-Feira','Quinta-Feira','Sexta-Feira','Sabádo']
const tableRef = createRef()
const tbodyRef = createRef()

export function CadastrarPonto(){
    const pontos = useSelector(state => state.userSlice.pontosList)
    const dispatch = useDispatch()
    const user = useSelector(state => state.userSlice)
    const [data, setData] = useState()
    const [dataFormated, setDataFormated] = useState()
    const [timeFormated, setTimeFormated] = useState()
    const [secondsFormated, setSecondsFormated] = useState()
    const [diaFormated, setDiaFormated] = useState()
    const [indexList, setIndexList] = useState(10)
    const [loading, setLoading] = useState({
        show: true
    })
    const [btnLoading, setBtnLoading] = useState({
        flag:false,
        msg:'Bater Ponto',
        style:'primary'
    })

    /* Effect para recuperar os pontos */
    useLayoutEffect(()=>{
        const date = new Date()
        const dateCorrect = getDateInitMonth(date)

        updateArrayPontos(dateCorrect.data_inicial, dateCorrect.data_final)
    },[])

    /* effect to set hours */
    useEffect(()=>{
        const update = () => {
            const date = new Date()
            const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}`: date.getMinutes() 
            const hours = date.getHours() < 10 ? `0${date.getHours()}`: date.getHours() 
            const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}`: date.getSeconds()

            setData(date)
            setDataFormated(date.toLocaleDateString())
            setTimeFormated(`${hours}:${minutes}`)
            setSecondsFormated(seconds)
            setDiaFormated(diasDaSemana[date.getDay()])
        }

        update()

        const interval = setInterval(()=>{
            update()
        },1000)

        return () => clearInterval(interval)
    },[])

    /* scroll event */
    useEffect(() => {
        
        const refTable = tableRef.current
        const scrollHandler = (e) => {
            const tbodyHeight = tbodyRef.current.clientHeight
            const divTableHeight = e.target.clientHeight
            const scrollPosition = e.target.scrollTop
            if(scrollPosition >= tbodyHeight - divTableHeight){
                setTimeout(() => {
                    setIndexList(indexList + 10)
                },1000)
            }

        }

        refTable.addEventListener('scroll', scrollHandler, false)

        return () => refTable.removeEventListener('scroll', scrollHandler, false)
    })


    const [filtersNew, setFiltersNew] = useState({})
    const [filters, setFilters] = useState([]) 
    const [filteredTable, setFilteredTable] = useState(false)

    const onClickRemoveFilter = (e) => {
        e.preventDefault()
        const value = e.target.parentNode.firstElementChild.innerText
        const [key, keyValue] = value.split(':')
        setFilters(last => ([...last.filter(item => String(item[key]).replaceAll(' ','') !== keyValue.replaceAll(' ',''))]))

    }

    useEffect(() => {
        if(!filters.length){
            setIndexList(10)
            setFilteredTable(false)
            return
        }

        let testeFilters = {}

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            testeFilters[param] = []
        })

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            // testeFilters[param].push(ele[param])
            testeFilters[param].push(String(ele[param]).toLowerCase())

            if (String(ele[param]).toLowerCase() == 'false') {
                testeFilters[param].push('null')
                
            }
        })

        
        let arrayRetorno = [pontos.data]

        Object.keys(testeFilters).forEach((key, index) => {
            arrayRetorno.push(filtroDinamicoArrays(testeFilters, key, arrayRetorno[arrayRetorno.length - 1], ))
        })

        const arrayF = removerDadosDuplicados(arrayRetorno[arrayRetorno.length - 1], 'id')
        setFilteredTable(arrayF)        

    }, [filters])

    const updateArrayPontos = async (data_inicial, data_final) => {
        const pontos = await getAllData('funcionario/controle-ponto', { cpf: user.cpf, data_inicial, data_final })

        dispatch(updatePontosList(pontos.data))

        setTimeout(() => {
            setBtnLoading({
                flag:false,
                msg:'Bater Ponto',
                style:'primary'
            })
            setLoading({
                show: false
            })
            
        },500)
    }

    const baterPonto = (e) => {
        setLoading({
            show: true
        })
        setBtnLoading({
            flag:true,
            msg:'Batendo Ponto',
            style:'warning'
        })
        let body = {
            nome: user.name,
            cpf: user.cpf,
            data_nascimento: new Date(user.data_nascimento).toLocaleDateString('pt-br',{timeZone:'UTC'}),
            tipo: user.tipo,
            dia:data.getDate(),
            mes:data.getMonth() +1,
            ano: data.getFullYear(),
            hora: data.getHours(),
            minuto: data.getMinutes(),
            segundo: data.getSeconds(),
            dia_semana: diasDaSemana[data.getDay()].toUpperCase(),
            ponto_data: `${data.toLocaleDateString('pt-BR')}`,
            ponto_hora: `${data.toLocaleTimeString('pt-BR')}`,
            ponto: `${data.toISOString()}`
        }
        const date = new Date()
        const [ano, mes, dia] = [date.getFullYear(), date.getMonth() + 1, date.getDate()]

        const qtdePontosBatidos = pontos.data.filter(item => item.ano == ano && item.mes == mes && item.dia == dia)

        if (qtdePontosBatidos.length % 2 == 1) {
            body.ent_sai = 'SAIDA'
        } else {
            
            body.ent_sai = 'ENTRADA'
        }

        createPontos(body)
            .then(async resp => {
                
                const dateCorrect = getDateInitMonth(new Date())

                setBtnLoading({
                    flag:false,
                    msg:'Ponto Ok',
                    style:'success'
                })
                updateArrayPontos(dateCorrect.data_inicial, dateCorrect.data_final)
            })
        

    }

    return(
        <CardLayoutNavbar>
            <div className="card-header">
                <h3>Filtros Selecionados</h3>
            </div>
            <Row className='mb-3 row-filters'>
                <Col>
                    <div>
                        {
                            filters.map((item, key1) => 
                                Object
                                    .keys(item)
                                    .map((key, index) => {
                                        return <span className="filters-card" key={key1}>
                                                    <span  className='filters'>
                                                        <span className='title'>
                                                            {key}: {item[key]}
                                                        </span>
                                                        <i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
                                                    </span>
                                            </span>
                                    })
                            ) 
                        }

                    </div>
                </Col>
            </Row>
            
            <Row className="align-items-top">
                <Col sm='12' className="mb-5">
                <div className="card-header">
                    <h3>Ultimos Registros</h3>
                </div>
                    <Row style={{overflow:'auto', flexWrap:'nowrap', padding:'20px 0'}}>
                        {
                            [...pontos.data]
                                .sort((a, b) => {
                                    if(a.ponto_data > b.ponto_data) return 1
                                    if (a.ponto_data < b.ponto_data) return -1
                                    return 0
                                })
                                .reverse()
                                .map((item, index) => {
                                    if (index > 11) return
                                    
                                    const date = new Date()
                                    const [ano, mes, dia] = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
                                    let diaAtual = ''
                                    if (ano == item.ano && mes == item.mes && dia == item.dia) {
                                        diaAtual = 'Hoje'
                                    }
                                    else if (ano == item.ano && mes == item.mes && (dia - 1) == item.dia) {
                                        diaAtual = 'Ontem'
                                    } else {
                                        diaAtual = String(new Date(item.ano, item.mes - 1, item.dia).toLocaleDateString('pt-BR',{month:'2-digit', day:'2-digit', weekday:'short'})).replace('.',' ').replace(',','-').toUpperCase()
                                    }
                                    return (
                                        <Col sm='3' lg='2' className="text-center " key={index}>
                                            <div className="card card-ponto p-2 mb-1" style={{backgroundColor:'#42a5f6'}}>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {
                                                        item.ent_sai == 'ENTRADA' ? 
                                                            <i className="bi bi-box-arrow-in-right" style={{color:'#fff'}}></i>
                                                            : ""
                                                    }
                                                    {
                                                        item.ent_sai == 'SAIDA' ? 
                                                            <i className="bi bi-box-arrow-in-left" style={{color:'#fff'}}></i>
                                                            : ""
                                                    }
                                                    <span className="ponto-header" style={{marginLeft:'5px'}}>{ item.ent_sai }</span>

                                                </div>
                                                <div className="text-muted">{ String(item.ponto).split(',')[1]}</div>
                                            </div>
                                            <span>
                                                {diaAtual}
                                            </span>
                                        </Col>
                                    )
                                })
                        }

                    </Row>
                </Col>                
                <Col sm='12' md='12' lg='6'>
                    <Card className='card-clock mt-4'>
                        <Card.Title className='card-clock-header'>Bem Vindo</Card.Title>
                        <Card.Body className='card-clock-body'>
                            <span className="hours-minutes">
                                {timeFormated} 
                            </span>
                            <span>
                                {secondsFormated}
                            </span>
                        </Card.Body>
                        <Card.Footer className='card-clock-footer'>
                            <span>
                                {dataFormated} 
                            </span>
                            <span>
                                {diaFormated}
                            </span>
                        </Card.Footer>
                    </Card>
                   
                    <Form>
                        <Row className='align-items-end justify-content-center'>
                            <Col sm='6'>
                                <Form.Label>Nome:</Form.Label>
                                <Form.Control type="text" disabled value={user.name}/>
                            </Col>
                           
                        </Row>
                        <Row className='justify-content-center mt-2'>
                            <Col sm='4'>
                                <Button variant={btnLoading.style} onClick={baterPonto} disabled={btnLoading.style == 'primary' ? false : true}>
                                    {btnLoading.flag ? <Loading msg={btnLoading.msg}/> : 
                                        <span style={{color:'#ffffff'}}>
                                            {btnLoading.msg} 
                                            {btnLoading.style === 'success' ? <i className="bi bi-check"></i>:''}
                                        </span>  
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col sm='12' md='12' lg='6'>
                    <h4>Tabela de Pontos</h4>
                    
                    <div className=" responsive-table"  ref={tableRef}>
                        <Table hover size='sm'>
                            <thead style={{zIndex:'49'}}>
                                <HeadTable
                                    arrayValues={[...pontos.data]}
                                    headerList={headList}
                                    setArrayFilters={setFiltersNew}
                                    arrayFilters={filtersNew}
                                    setFiltersOld={setFilters}
                                    filtersOld={filters}
                                    fn={updateArrayPontos}
                                />
                                    
                            </thead>
                            
                            <tbody ref={tbodyRef}>

                                {
                                    filteredTable.length ? 
                                        filteredTable.map((item, index) => { 
                                            if(index < indexList){
                                                return <BodyRow key={index} bodyList={item} headList={headList} index={index} />
                                            }
                                        }): 
                                        [...pontos.data]
                                            
                                            .sort((a, b) => {
                                                if(a.ponto_data > b.ponto_data) return 1
                                                if (a.ponto_data < b.ponto_data) return -1
                                                return 0
                                            })
                                            .reverse()
                                            .map((item, index) => {
                                                if(index < indexList){
                                                    return <BodyRow key={index} bodyList={item} headList={headList} index={index} />
                                                }

                                            })
                                }

                            </tbody>
                        </Table>
                        { indexList < pontos.data.length ? <Loading color={'black'} msg='Loading'/>:""}
                    </div>
                </Col>
            </Row>
            
            {loading.show ? <ModalLoading show={loading.show} setShow={setLoading}/> : ""}
        </CardLayoutNavbar>
    )
}