import { createRef, useEffect, useLayoutEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CaminhaoLayout from "../layout/CaminhaoLayout";
import Loading from "../../loading/Loading";
import {
	getAllData,
	Unauthorized,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import {
	update,
	updateCaminhoesCaminhoeiros,
} from "../../../redux/slicers/user";
import BodyRowCaminhoesCaminhoneiros from "../../table/body/BodyRowCaminhoesCaminhoneiros";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";

const headList = [
	"index",
	"admin",
	"id_filial",
	"caminhao_tipo",
	"caminhao_placa",
	"id_caminhoneiro",
	"nome_caminhoneiro",
	"cpf_caminhoneiro",
	"createdAt",
	"updatedAt",
];
const tbodyRef = createRef();
const tableDiv = createRef();
const formFiltros = createRef();

export default function CaminhaoCaminhoneiro() {
	const caminhoes = useSelector(
		(state) => state.userSlice.caminhoesCaminhoneirosList
	);

	const [loading, setLoading] = useState(false);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const user = useSelector((state) => state.userSlice);

	const dispatch = useDispatch();

	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList((last) => last + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const updateCaminhoesCaminhoenriosArray = (e) => {
		let body;
		// if (user.tipo !== 'empresa') {
		//     body = {id_filial: user.id_filial}
		// }

		setLoading(true);
		getAllData(verificaTipoUsuario(user) + "caminhoes/caminhoneiros", body, "")
			.then((resp) => {
				dispatch(updateCaminhoesCaminhoeiros(resp.data));
				setLoading(false);
			})
			.catch((error) => {
				if (error.msg === "Unauthorized") {
					Unauthorized(dispatch, update);
				}
				setLoading(false);
			});
	};

	useLayoutEffect(() => {
		updateCaminhoesCaminhoenriosArray();
	}, []);

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [caminhoes.data];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"caminhao_placa"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<CaminhaoLayout>
			<div className="section-filters-table">
				<h3>Filtros Selecionados</h3>
				<hr />
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div>
					<h3 style={{ display: "flex", alignItems: "center" }}>
						Tabela de Caminhões{" "}
						{loading ? (
							<Spinner
								style={{ marginLeft: "10px", fontSize: "1rem" }}
								animation="border"
								size="sm"
							/>
						) : (
							""
						)}
					</h3>
				</div>
				<hr />
				<div className="responsive-table" ref={tableDiv}>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={4}
						maxima_distancia={240}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="caminhoes"
								arrayValues={caminhoes.data}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>
						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowCaminhoesCaminhoneiros
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
								  })
								: caminhoes.data.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowCaminhoesCaminhoneiros
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < caminhoes.data.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>
		</CaminhaoLayout>
	);
}
