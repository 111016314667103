import { useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { updateProdutosListDimensoes } from "../../redux/slicers/user";
import {
	apenasNumeros,
	numerosDecimal3,
	updateTable,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";

export default function ModalAtualizarDimensoesFrete(props) {
	const dimensioValue = useRef();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});
	const [dimensioValu, setDimensionValu] = useState();
	const [tipoPeso, setTipoPeso] = useState("k");
	const [dimensionError, setDimensionError] = useState({
		flag: false,
		msg: "",
	});

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const setEditDimensoes = async (e) => {
		e.preventDefault();
		const qr_sku = props.qr_sku;
		let value = Number(dimensioValu.replaceAll(".", "").replace(",", "."));

		if (props.dimensao === "peso" && tipoPeso === "t") {
			value *= 1000;
		}

		setLoading((last) => ({
			...last,
			isLoading: true,
			style: "warning",
			msg: "Salvando",
		}));

		/* update data on db */
		// cargas/produtos/separador
		const body = {
			[props.dimensao]: value,
			qr_sku,
			cargas_id: props.cargas_id || props.bodyList.cargas_id,
		};

		if (props.bodyList?.tipo == "EMBALAGEM") {
			updateTable("cargas/funcionarios/embalagens", qr_sku, body).then(
				(resp) => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						style: "success",
						msg: "Salvo",
					}));
					if (props.updateList) {
						//alert()
						props.updateList((l) => [
							...l.map((item) => ({
								...item,
								[props.dimensao]:
									item.qr_sku == qr_sku ? value : item[props.dimensao],
								volume:
									(props.dimensao === "altura" ? value : item.altura) *
									(props.dimensao === "comprimento"
										? value
										: item.comprimento) *
									(props.dimensao === "largura" ? value : item.largura),
							})),
						]);
					} else {
						dispatch(
							updateProdutosListDimensoes({
								qr_sku,
								dimension: { name: props.dimensao, value: value },
							})
						);
					}
					setTimeout(() => {
						handlerSimpleClose();
					}, 250);
				}
			);
			return;
		}
		updateTable("cargas/produtos/separador", props.cargas_id, body).then(
			(resp) => {
				setLoading((last) => ({
					...last,
					isLoading: false,
					style: "success",
					msg: "Salvo",
				}));
				props.updateList((l) => [
					...l.map((item) => ({
						...item,
						[props.dimensao]:
							item.qr_sku == qr_sku ? value : item[props.dimensao],
						volume:
							(props.dimensao === "altura" ? value : item.altura) *
							(props.dimensao === "comprimento" ? value : item.comprimento) *
							(props.dimensao === "largura" ? value : item.largura),
					})),
				]);

				setTimeout(() => {
					handlerSimpleClose();
				}, 250);
			}
		);
	};

	const changePeso = (e) => {
		setTipoPeso(e.target.id);
	};

	const onChangeValue = (e) => {
		e.preventDefault();
		if (e.target.name == "qtde") {
			return setDimensionValu(
				Number(dimensioValue.current.value.replace(/\D/g, "")).toLocaleString(
					"pt-BR"
				)
			);
		}
		setDimensionValu(
			numerosDecimal3(apenasNumeros(dimensioValue.current.value))
		);
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Editar <i>{props.dimensao}</i> do produto: <br></br>{" "}
						<i>{props.descricao}</i>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							setEditDimensoes(e);
						}}
					>
						<Row className="align-items-end">
							<Col sm="4">
								<Form.Label name="parameter">
									{props.dimensao.toUpperCase()}:
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									className="Form-control mb-2"
									placeholder={props.currentValue}
									ref={dimensioValue}
									maxLength={8}
									name={props.dimensao}
									max={
										props.dimensao === "altura"
											? 4.9
											: props.dimensao === "largura"
											? 2.2
											: 13
									}
									onChange={onChangeValue}
									value={dimensioValu}
								/>
							</Col>
							{dimensionError.flag ? (
								<Form.Text className="text-muted error-form text-end">
									{dimensionError.msg}
								</Form.Text>
							) : (
								""
							)}
						</Row>
						{props.dimensao !== "peso" ? (
							""
						) : (
							<Row className="justify-content-end">
								<Col sm="4">
									<Form.Check
										className="form-check"
										label="Kilograma"
										type="radio"
										name="group1"
										id="k"
										onClick={changePeso}
										defaultChecked
									/>
								</Col>
								<Col sm="4">
									<Form.Check
										className="form-check"
										label="Tonelada"
										type="radio"
										name="group1"
										id="t"
										onClick={changePeso}
									/>
								</Col>
							</Row>
						)}
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={setEditDimensoes}
								disabled={loading.style !== "primary" ? true : false}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
