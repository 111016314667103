import { createRef, Fragment, useEffect, useState, useRef } from "react";
import {
	Button,
	Card,
	Col,
	Form,
	Modal,
	Row,
	Spinner,
	Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

import {
	updateEmbalagensListPlanejados,
	updateProdutosList,
	updateProdutosListPlanejados,
} from "../../redux/slicers/user";

import { v4 as uuidv4 } from "uuid";
import "./Modal.scss";
import {
	addProduto,
	updateLogicaDeCarregamento,
} from "../../redux/slicers/carga";
import ModalErrorCarregamento from "./ModalErrorCarregameto";
import ModalAtualizarDimensoes from "./ModalAtualizarDimensoes";
import ModalErrorTotal from "./ModalErrorTotal";
import ModalListarProdutosEmbalados from "./ModalListarProdutosEmbalados";
import ScrollbarLeft from "../scrollbars/ScrollbarLeft";
import ModalSelectColor from "./ModalSelectColor";
import ModalError from "./ModalError";

const tableRef = createRef();
const tbodyRef = createRef();
const headList = [
	"index",
	"cor_caixa",
	"planejado",
	// 'id_filial_destino',
	"tipo",
	"numero_pedido/embalagem",
	"codigo",
	"descricao",
	"altura",
	"largura",
	"comprimento",
	"volume",
	"peso",
];
const headListProdutos = [
	"index",
	"planejado",
	"numero_pedido",
	"codigo",
	"descricao",
	"qtde",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"abreviatura",
	"qr_sku",
	"numero_item_embalagem",
	// , 'numero_embalagem'
];

export default function ModalGetCargaCarregamentoSeparador({
	show,
	setShow,
	setError,
	placaCaminhao,
	cargas_id,
}) {
	const userEmail = useSelector((state) => state.userSlice.email);
	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const user = useSelector((state) => state.userSlice);
	const boxes = useSelector((state) => state.cargaSlicer.listaProdutos);
	const embalagens = useSelector((state) => state.userSlice.embalagensList);
	const dispatch = useDispatch();
	const [romaneios, setRomaneios] = useState([]);
	const [indexList, setIndexList] = useState(10);
	const [loading, setLoading] = useState(true);

	const handlerSimpleClose = () => setShow(false);
	const [produtoSelecionado, setProdutoSelecionado] = useState({});

	const logicaState = useSelector(
		(state) => state.cargaSlicer.logicaDeCarregamento
	);
	// const [error, setError] = useState(false)
	const [errorP, setErrorP] = useState({
		flag: false,
		msg: "",
	});

	const [errorP2, setErrorP2] = useState({
		flag: false,
		msg: "",
		title: "",
	});

	const onClickCarregar = (e) => {
		if (
			produtosRomaneio.data.filter(
				(item) => item.qr_sku === produtoSelecionado.qr_sku && item.planejado
			).length
		) {
			setError({
				flag: true,
				msg: "Produto já planejado. Prossiga com outros produtos...",
				title: "Atenção!",
			});
			return;
		}
		if (
			produtoSelecionado.largura <= 0 ||
			produtoSelecionado.altura <= 0 ||
			produtoSelecionado.comprimento <= 0
		) {
			setError({
				flag: true,
				msg: "Não é possivel carregar produto com parametro menor ou igual a zero!",
				title: "Atenção!",
			});
			return;
		}

		dispatch(
			addProduto({
				name: uuidv4(),
				position: [0, 2, 0],
				args: [
					produtoSelecionado.largura,
					produtoSelecionado.altura,
					produtoSelecionado.comprimento,
				],
				mass: produtoSelecionado.peso,
				id: produtoSelecionado.id,
				step: boxes.length + 1,
				tipo: produtoSelecionado.tipo,
				qr_sku: produtoSelecionado.qr_sku,
				cor_caixa: produtoSelecionado.cor_caixa,
				disponivel: true,
			})
		);

		if (produtoSelecionado.tipo === "EMBALAGEM") {
			dispatch(
				updateEmbalagensListPlanejados({
					qr_sku: produtoSelecionado.qr_sku,
					planejado: true,
					data: new Date().toISOString(),
					user: userEmail,
					cor_caixa: produtoSelecionado.cor_caixa,
				})
			);

			/* validar produtos embalados */
			const produtosDaEmbalagem = produtosRomaneio.data.filter(
				(item) =>
					item.embalado &&
					Number(produtoSelecionado.numero_embalagem) === item.numero_embalagem
			);

			produtosDaEmbalagem.forEach((el) => {
				dispatch(
					updateProdutosListPlanejados({
						qr_sku: el.qr_sku,
						planejado: true,
						data: new Date().toISOString(),
						user: userEmail,
					})
				);
			});
		} else {
			dispatch(
				updateProdutosListPlanejados({
					qr_sku: produtoSelecionado.qr_sku,
					planejado: true,
					data: new Date().toISOString(),
					user: userEmail,
					cor_caixa: produtoSelecionado.cor_caixa,
				})
			);
		}

		handlerSimpleClose();
	};

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition > tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList((last) => last + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	useEffect(() => {
		if (!produtosRomaneio.data.length) return;

		const embalagensL = [...embalagens.data].map((item) => {
			const pedido = produtosRomaneio.data.find(
				(item1) =>
					Number(item1.numero_embalagem) == Number(item.numero_embalagem)
			);
			return {
				...item,
				tipo: "EMBALAGEM",
				cidade: String(
					user.romaneiosList.data.find(
						(item1) => item1.numero_pedido == pedido.numero_pedido
					)?.cidade
				).toUpperCase(),
			};
		});

		const produtosL = [...produtosRomaneio.data]
			.filter((item) => item.transferencia)
			.map((item) => ({
				...item,
				tipo: "PRODUTO",
				cidade: String(
					user.romaneiosList.data.find(
						(item1) => item1.numero_pedido == item.numero_pedido
					)?.cidade
				).toUpperCase(),
			}));

		let sorting = [...produtosL, ...embalagensL];

		sorting.sort((a, b) => {
			let stringP = ``;
			const indexes = logicaState.logica.length;
			if (indexes === 1) {
				return a[logicaState.logica[0]] - b[logicaState.logica[0]];
			}
			if (indexes === 2) {
				return (
					a[logicaState.logica[0]] - b[logicaState.logica[0]] ||
					a[logicaState.logica[1]] - b[logicaState.logica[1]]
				);
			}
			if (indexes === 3) {
				return (
					a[logicaState.logica[0]] - b[logicaState.logica[0]] ||
					a[logicaState.logica[1]] - b[logicaState.logica[1]] ||
					a[logicaState.logica[2]] - b[logicaState.logica[2]]
				);
			}
			return a.codigo - b.codigo;
		});
		setRomaneios(sorting.reverse());
		setLoading(false);
	}, [logicaState, produtosRomaneio, embalagens]);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Selecionar Produto: {placaCaminhao}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Informacoes
						produtos={produtosRomaneio.data.filter(
							(item) => item.transferencia
						)}
						embalagens={embalagens}
						pedidos={romaneios}
						cargas_id={cargas_id}
					/>
					<div className="section-filters-table">
						<div className="card-header row justify-content between align-items-center pb-1">
							<Col>
								<h5>
									Items da Carga{" "}
									{loading ? (
										<Spinner
											style={{ marginLeft: "10px", fontSize: "1rem" }}
											animation="border"
											size="sm"
										/>
									) : (
										""
									)}
								</h5>
							</Col>
							<Col sm="6">
								<Row>
									<Col sm="6">
										<Button
											variant="primary"
											onClick={onClickCarregar}
											disabled={produtoSelecionado.qr_sku ? false : true}
										>
											Carregar
										</Button>
									</Col>
									<Col sm="6">
										<Button variant="secondary" onClick={handlerSimpleClose}>
											Cancelar
										</Button>
									</Col>
								</Row>
							</Col>
						</div>
						<ScrollbarLeft
							tableRef={tableRef}
							coluna2={4}
							maxima_distancia={250}
						/>
						<div
							className=" responsive-table"
							ref={tableRef}
							style={{ position: "relative", height: "300px" }}
						>
							<Table hover size="sm">
								<thead>
									<tr>
										{headList.map((item, index) => {
											if (item === "index") {
												return <th key={index}>#</th>;
											} else if (item.toUpperCase() === "CREATED_AT") {
												return <th key={index}>DATA DE CRIAÇÃO</th>;
											} else if (item.toUpperCase() === "UPDATED_AT") {
												return <th key={index}>ULTIMA ATUALIZAÇÃO</th>;
											}
											return <th key={index}>{item.toUpperCase()}</th>;
										})}
									</tr>
								</thead>
								<tbody ref={tbodyRef}>
									{romaneios
										.filter((item) => !item.embalado)
										.map((item, index) => {
											// if(index < indexList){
											return (
												<BodyRowProdutoCarregamento
													key={index}
													bodyList={item}
													index={index}
													tableLength={romaneios.length}
													setProdutoSelecionado={setProdutoSelecionado}
													setError={setErrorP2}
												/>
											);
											// }
										})}
								</tbody>
							</Table>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* {error.flag ? <ModalError msg={error.msg} title={error.title} show={error} setShow={setError}/> : ""} */}
			{errorP.flag ? (
				<ModalErrorCarregamento
					msg={errorP.msg}
					title={"Erro ao Finalizar Carregamento"}
					show={errorP.flag}
					setShow={setErrorP}
				/>
			) : (
				""
			)}
			{errorP2.flag ? (
				<ModalErrorTotal
					msg={errorP2.msg}
					title={errorP2.title}
					show={errorP2.flag}
					setShow={setErrorP2}
				/>
			) : (
				""
			)}
		</>
	);
}

function BodyRowProdutoCarregamento({
	index,
	bodyList,
	tableLength,
	setProdutoSelecionado = () => {},
	qr_sku = 0,
	setError,
}) {
	const refRow = useRef();
	const refRowCheck = useRef(null);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);
	const caminhao = useSelector((state) => state.userSlice.caminhaoSelecionado);
	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const [editarDimensoes, setEditarDimensoes] = useState({
		flag: false,
		dimensao: "",
		produto: "",
		descricao: "",
		parametro: "",
		qr_sku: "",
		msg_button: "",
		currentValue: 0,
		cargas_id: "",
	});

	const [verProdutos, setVerProdutos] = useState({
		flag: false,
		produtosDaEmbalagem: [],
	});

	const [selectColor, setSelectColor] = useState({
		flag: false,
		msg: "",
		title: "",
	});

	const onClickTableRow = (e) => {
		e.preventDefault();
		// const checked = refRow.current.children[1].children[0].children[0].checked
		const checked = bodyList.planejado;

		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}

		if (checked) {
			refRow.current.classList = "bg-selected-danger";
			setProdutoSelecionado({});
			return;
		} else {
			if (bodyList.cor_caixa) {
				refRow.current.classList = "bg-selected";
			} else {
			}
		}

		if (checked) {
			return;
		}
		// setProdutoSelecionado({id, qr_sku, descricao, altura, largura, comprimento, peso, tipo: 'produto'})
		if (bodyList.cor_caixa) {
			setProdutoSelecionado(bodyList);
		}
	};

	const onClickProdutos = (e, numero_embalagem) => {
		const produtosDaEmbalagem = produtosRomaneio.data.filter(
			(item) => item.numero_embalagem === numero_embalagem
		);
		//
		setVerProdutos((last) => ({
			...last,
			flag: true,
			produtosDaEmbalagem,
			numero_embalagem,
		}));
	};

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "planejado") {
							return (
								<td key={_index} className="text-center">
									<Form.Check
										type="checkbox"
										disabled
										ref={refRowCheck}
										checked={bodyList?.planejado ?? false}
										key={index}
									/>
								</td>
							);
						} else if (item === "carregado") {
							return (
								<td key={_index} className="text-center">
									<Form.Check
										type="checkbox"
										disabled
										ref={refRowCheck}
										checked={bodyList?.carregado ?? false}
										key={index}
									/>
								</td>
							);
						} else if (item === "cor_caixa") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{bodyList.cor_caixa}
										</span>
										<span
											className="verificar"
											style={{
												fontSize: "inherit",
												color: "inherit",
												cursor: "pointer",
											}}
										>
											<i
												className="bi bi-pencil bi-circle-animation"
												title="Editar Peso"
												onClick={(e) =>
													setSelectColor({
														flag: true,
														cor: bodyList.cor_caixa,
													})
												}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td key={_index} className={item} style={{ minWidth: "250px" }}>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "DTEMISSAO") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item.toLowerCase() === "comprimento") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.comprimento).replace(".", ",")}
										</span>
										{/* <span className="verificar" style={{ fontSize: 'inherit', color: 'inherit', cursor: 'pointer' }}>
                                            <i className="bi bi-pencil" title="Editar Comprimento" onClick={e => { onClickEditar(e, "comprimento", bodyList.qr_sku, bodyList.codigo, bodyList.comprimento, bodyList.descricao) }}></i>
                                        </span> */}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "altura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.altura).replace(".", ",")}
										</span>
										{/* <span className="verificar" style={{ fontSize: 'inherit', color: 'inherit', cursor: 'pointer' }}>
                                            <i className="bi bi-pencil" title="Editar Altura" onClick={e => { onClickEditar(e, "altura", bodyList.qr_sku, bodyList.codigo, bodyList.altura, bodyList.descricao) }}></i>
                                        </span> */}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "largura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.largura).replace(".", ",")}
										</span>
										{/* <span className="verificar" style={{ fontSize: 'inherit', color: 'inherit', cursor: 'pointer' }}>
                                            <i className="bi bi-pencil bi-circle-animation" title="Editar Largura" onClick={e => { onClickEditar(e, "largura", bodyList.qr_sku, bodyList.codigo, bodyList.largura, bodyList.descricao) }}></i>
                                        </span> */}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "peso") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{Number(bodyList[item]).toLocaleString()}
										</span>
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "qtde_produtos") {
							if (bodyList.tipo !== "EMBALAGEM") return <td key={_index}></td>;

							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.qtde_produtos).replace(".", ",")}
										</span>
										{/* <span className="verificar" style={{ fontSize: 'inherit', color: 'inherit', cursor: 'pointer' }}>
                                            <i className="bi bi-plus-circle bi-circle-animation" title="Ver Produtos" onClick={e => { onClickProdutos(e, bodyList.numero_embalagem) }}></i>
                                        </span> */}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "numero_pedido/embalagem") {
							if (bodyList.tipo !== "PRODUTO") {
								return (
									<td key={_index}>
										<div className="d-flex justify-content-between">
											<span
												className="verificar"
												style={{ fontSize: "inherit", color: "inherit" }}
											>
												{String(bodyList.numero_embalagem).padStart(7, 0)}
											</span>
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-plus-circle bi-circle-animation"
													title="Ver Produtos"
													onClick={(e) => {
														onClickProdutos(e, bodyList.numero_embalagem);
													}}
												></i>
											</span>
										</div>
									</td>
								);
							}
							return (
								<td key={_index} className={item}>
									{" "}
									{bodyList.numero_pedido}
								</td>
							);
						}

						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{editarDimensoes.flag ? (
				<ModalAtualizarDimensoes
					show={editarDimensoes.flag}
					setShow={setEditarDimensoes}
					{...editarDimensoes}
				/>
			) : (
				""
			)}
			{verProdutos.flag ? (
				<ModalListarProdutosEmbalados
					setShow={setVerProdutos}
					show={verProdutos.flag}
					produtos={verProdutos.produtosDaEmbalagem}
					headList={headListProdutos}
					{...verProdutos}
				/>
			) : (
				""
			)}
			{selectColor.flag ? (
				<ModalSelectColor
					msg={selectColor.msg}
					title={"Deseja Selecionar uma Cor"}
					show={selectColor.flag}
					setShow={setSelectColor}
					produtoSelecionado={bodyList}
					setProdutoSelecionado={setProdutoSelecionado}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function Informacoes({ produtos, embalagens, cargas_id }) {
	let google = window.google;
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);
	const logicaState = useSelector(
		(state) => state.cargaSlicer.logicaDeCarregamento
	);
	const romaneios = useSelector((state) => state.userSlice.romaneiosList);
	const [orderByPeso, setOrderByPeso] = useState([]);
	const [orderByLength, setOrderByLength] = useState([]);
	const [error, setError] = useState({
		flag: false,
	});

	let produtosRomaneio = useSelector((state) => state.userSlice.produtosList);

	useEffect(() => {
		if (!produtos.length) return;
		let produtosR = [...produtos]
			.filter((item) => !item.embalado)
			.map((item) => {
				return { ...item, tipo: "produto" };
			});
		let embalagensR = [...embalagens.data].map((item) => {
			return { ...item, tipo: "embalagem" };
		});

		let teste = [...produtosR, ...embalagensR];
		setOrderByPeso(teste);
		setOrderByLength(
			teste.sort((a, b) => {
				if (a.comprimento > b.comprimento) return -1;
				if (a.comprimento < b.comprimento) return 1;
				return 0;
			})
		);
	}, [produtos]);

	const [libraries] = useState(["places"]);
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
		libraries: libraries,
	});
	const [locations, setLocations] = useState(null);

	const [map, setMap] = useState(/** @type google.maps.Map */ (null));
	const [currentPosition, setCurrentPosition] = useState({
		lat: 0,
		lng: 0,
	});
	const [startPosition, setStartPosition] = useState({
		latitude: 0,
		longitude: 0,
	});

	/* perguntar ao usuário sobre permissão de uso da geolocalização */
	const sucessCallbackLocation = (position) => {
		if (currentPosition.lat) return;
		setCurrentPosition({
			lat: position.coords.latitude,
			lng: position.coords.longitude,
		});
		if (startPosition.latitude || startPosition.longitude) {
			setStartPosition({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			});
		}
	};

	const distanceCalculate = async (
		origem = "cuiaba",
		destinos = [
			"varzea grande",
			"barra do bugres",
			"sinop",
			"chapada dos guimaraes",
		]
	) => {
		navigator.geolocation
			// .getCurrentPosition(sucessCallbackLocation, errorCallbackLocation, {
			.getCurrentPosition(
				sucessCallbackLocation,
				(error) => {
					setError({
						flag: true,
						title: "Erro ao Carregar Localização",
						msg: `ERROR(${error.code}): ${error.message}`,
					});
				},
				{
					enableHighAccuracy: true,
					maximumAge: 30000,
					timeout: 27000,
				}
			);

		if (!map) return;
		if (!currentPosition.lat || !currentPosition.lng || !romaneios.data.length)
			return;

		const destinoss = romaneios.data
			.filter((item) => item.cargas_id == cargas_id)
			.map((item) => ({
				pedido: item.numero_pedido,
				end: `${item.endereco},${item.numero} ${item.bairro}, ${item.cidade}`,
			}));

		const distanceMatrix = new google.maps.DistanceMatrixService();

		const directionsMatrix = await distanceMatrix.getDistanceMatrix({
			origins: [currentPosition],
			destinations: destinoss.map((item) => item.end),
			travelMode: google.maps.TravelMode.DRIVING,
			unitSystem: google.maps.UnitSystem.METRIC,
		});

		let result = [
			{
				city: directionsMatrix.originAddresses[0],
				distance: {
					duration: 0,
					text: "Partida",
				},
			},
		];

		directionsMatrix.destinationAddresses.map((item, index) => {
			let obj = {
				city: item,
				distance: directionsMatrix.rows[0].elements[index].distance,
				duration: directionsMatrix.rows[0].elements[index].duration,
				pedido: destinoss.filter((i, n) => i.pedido && n === index)[0].pedido,
			};

			result.push(obj);
		});
		let produtosSortedByDistance = [];
		produtosRomaneio.data.forEach((item) => {
			const index = result.findIndex((o) => o.pedido === item.numero_pedido);
			produtosSortedByDistance.push({ ...item, sort_by_distance: index });
			return item;
		});

		result.sort((a, b) => {
			if (a.distance?.value > b.distance?.value) return -1;
			if (a.distance?.value < b.distance?.value) return 1;
			return 0;
		});
		produtosSortedByDistance.sort((a, b) => {
			if (a.distance > b.distance) return -1;
			if (a.distance < b.distance) return 1;
			return 0;
		});
		setLocations(result);
		dispatch(updateProdutosList(produtosSortedByDistance));
		return;
	};

	// useEffect(() => {

	//     distanceCalculate()
	// }, [map, isLoaded, currentPosition, romaneios])

	return (
		<div className="mt-2">
			<h3 className="card-header">Informações da Carga</h3>
			<Row>
				{isLoaded ? <GoogleMap onLoad={setMap} /> : ""}
				<Col sm="12" lg="6" xl="4">
					<Card
						className={`card-dashboard bg-info active-animation-card ${
							logicaState.sort_by_distance.flag ? "active-animation" : ""
						}`}
						style={{ padding: "10px" }}
						data-before={logicaState.logica.indexOf("sort_by_distance") + 1}
					>
						<Card.Title className="text-center">Distância</Card.Title>
						<hr />
						<Row
							className="align-items-center"
							style={{ borderBottom: "solid 1px black" }}
						>
							<Col sm="6" style={{ fontSize: "0.8rem" }}>
								<b>Local</b>
							</Col>
							<Col sm="3" style={{ fontSize: "0.8rem", textAlign: "center" }}>
								<b>Distância</b>
							</Col>
							<Col sm="3" style={{ fontSize: "0.8rem", textAlign: "center" }}>
								<b>Pedido</b>
							</Col>
						</Row>
						<Row className="align-items-center">
							<div
								style={{
									overflow: "hidden",
									overflowY: "auto",
									maxHeight: "200px",
								}}
							>
								{locations &&
									locations.map((item, index) => {
										if (!item.distance) return;
										return (
											<Row
												key={index}
												className="align-items-center text-left"
												style={{ borderBottom: "solid 1px black" }}
											>
												<Col sm="6">
													<p style={{ fontSize: "0.75rem", color: "inherit" }}>
														{item.city}
													</p>
												</Col>
												<Col sm="3">
													<p
														style={{
															fontSize: "0.75rem",
															color: "inherit",
															textAlign: "center",
														}}
													>
														{item.distance.text}
													</p>
												</Col>
												<Col sm="3">
													<p
														style={{
															fontSize: "0.75rem",
															color: "inherit",
															textAlign: "center",
														}}
													>
														{item.pedido}
													</p>
												</Col>
											</Row>
										);
									})}
							</div>
						</Row>
						<Card.Footer>
							<Button
								disabled={orderByLength.length ? false : true}
								onClick={(e) =>
									dispatch(
										updateLogicaDeCarregamento({
											parameter: "sort_by_distance",
											value: !logicaState.sort_by_distance.flag,
											msg: logicaState.sort_by_distance.flag
												? "Ativar"
												: "Ativado",
											pedidos: locations
												?.filter((item) => item.pedido != undefined)
												.map((item) => item.pedido),
										})
									)
								}
								variant="light"
								className={`animation-btn-ativar-logica ${
									logicaState.sort_by_distance.flag ? "active-animation" : ""
								}`}
							>
								<span>{logicaState.sort_by_distance.msg}</span>
							</Button>
						</Card.Footer>
					</Card>
				</Col>
				<Col sm="12" lg="6" xl="4">
					<Card
						className={`card-dashboard bg-info active-animation-card ${
							logicaState.peso.flag ? "active-animation" : ""
						}`}
						style={{ padding: "10px" }}
						data-before={logicaState.logica.indexOf("peso") + 1}
					>
						<Card.Title className="text-center">Peso</Card.Title>
						<hr />
						<Row
							className="align-items-center"
							style={{ borderBottom: "solid 1px black" }}
						>
							<Col sm="8" style={{ fontSize: "0.8rem" }}>
								<b>Produto</b>
							</Col>
							<Col sm="4" style={{ fontSize: "0.8rem", textAlign: "center" }}>
								<b>Peso [Kg]</b>
							</Col>
						</Row>
						<div
							style={{
								overflow: "hidden",
								overflowY: "auto",
								maxHeight: "200px",
							}}
						>
							<Row className="align-items-center">
								{orderByPeso
									.sort((a, b) => {
										if (a.peso > b.peso) return 1;
										if (a.peso < b.peso) return -1;
										return 0;
									})
									.reverse()
									.map((item, index) => {
										if (item.tipo === "embalagem") {
											return (
												<Row
													key={index}
													className="align-items-center"
													style={{ borderBottom: "solid 1px black" }}
												>
													<Col sm="9">
														<p
															style={{ fontSize: "0.75rem", color: "inherit" }}
														>
															<b>{item.tipo.toUpperCase()}</b>:{" "}
															{String(item.numero_embalagem).padStart(7, 0)}
														</p>
													</Col>
													<Col>
														<p
															style={{
																fontSize: "0.75rem",
																color: "inherit",
																padding: "3px 0px",
																borderRadius: "4px",
																marginTop: "5px",
																textAlign: "center",
															}}
														>
															{item.peso ?? 0}
														</p>
													</Col>
												</Row>
											);
										}
										return (
											<Row
												key={index}
												className="align-items-center"
												style={{ borderBottom: "solid 1px black" }}
											>
												<Col sm="9">
													<p style={{ fontSize: "0.75rem", color: "inherit" }}>
														<b>{item.tipo.toUpperCase()}</b>: {item.descricao}
													</p>
												</Col>
												<Col>
													<p
														style={{
															fontSize: "0.75rem",
															color: "inherit",
															padding: "3px 0px",
															borderRadius: "4px",
															marginTop: "5px",
															textAlign: "center",
														}}
													>
														{item.peso ?? 0}
													</p>
												</Col>
											</Row>
										);
									})}
							</Row>
						</div>
						<Card.Footer>
							<Button
								disabled={orderByLength.length ? false : true}
								onClick={(e) =>
									dispatch(
										updateLogicaDeCarregamento({
											parameter: "peso",
											value: !logicaState.peso.flag,
											msg: logicaState.peso.flag ? "Ativar" : "Ativado",
										})
									)
								}
								variant="light"
								className={`animation-btn-ativar-logica ${
									logicaState.peso.flag ? "active-animation" : ""
								}`}
							>
								<span>{logicaState.peso.msg}</span>
							</Button>
						</Card.Footer>
					</Card>
				</Col>
				<Col sm="12" lg="6" xl="4">
					<Card
						className={`card-dashboard bg-info active-animation-card ${
							logicaState.comprimento.flag ? "active-animation" : ""
						}`}
						style={{ padding: "10px" }}
						data-before={logicaState.logica.indexOf("comprimento") + 1}
					>
						<Card.Title className="text-center">Comprimento</Card.Title>
						<hr />
						<Row
							className="align-items-center"
							style={{ borderBottom: "solid 1px black" }}
						>
							<Col sm="8" style={{ fontSize: "0.8rem" }}>
								<b>Produto</b>
							</Col>
							<Col sm="4" style={{ fontSize: "0.8rem", textAlign: "center" }}>
								<b>Comprimento [m]</b>
							</Col>
						</Row>
						<div
							style={{
								overflow: "hidden",
								overflowY: "auto",
								maxHeight: "200px",
							}}
						>
							<Row className="align-items-center">
								{orderByLength
									.sort((a, b) => {
										if (a.comprimento > b.comprimento) return 1;
										if (a.comprimento < b.comprimento) return -1;
										return 0;
									})
									.reverse()
									.map((item, index) => {
										if (item.tipo === "embalagem") {
											return (
												<Row
													key={index}
													className="align-items-center"
													style={{ borderBottom: "solid 1px black" }}
												>
													<Col sm="9">
														<p
															style={{ fontSize: "0.75rem", color: "inherit" }}
														>
															<b>{item.tipo.toUpperCase()}</b>:{" "}
															{String(item.numero_embalagem).padStart(7, 0)}
														</p>
													</Col>
													<Col>
														<p
															style={{
																fontSize: "0.75rem",
																color: "inherit",
																padding: "3px 0px",
																borderRadius: "4px",
																marginTop: "5px",
																textAlign: "center",
															}}
														>
															{item.comprimento}
														</p>
													</Col>
												</Row>
											);
										}
										return (
											<Row
												key={index}
												className="align-items-center"
												style={{ borderBottom: "solid 1px black" }}
											>
												<Col sm="9">
													<p style={{ fontSize: "0.75rem", color: "inherit" }}>
														<b>{item.tipo.toUpperCase()}</b>: {item.descricao}
													</p>
												</Col>
												<Col>
													<p
														style={{
															fontSize: "0.75rem",
															color: "inherit",
															padding: "3px 0px",
															borderRadius: "4px",
															marginTop: "5px",
															textAlign: "center",
														}}
													>
														{item.comprimento}
													</p>
												</Col>
											</Row>
										);
									})}
							</Row>
						</div>
						<Card.Footer>
							<Button
								disabled={orderByLength.length ? false : true}
								onClick={(e) =>
									dispatch(
										updateLogicaDeCarregamento({
											parameter: "comprimento",
											value: !logicaState.comprimento.flag,
											msg: logicaState.comprimento.flag ? "Ativar" : "Ativado",
										})
									)
								}
								variant="light"
								className={`animation-btn-ativar-logica ${
									logicaState.comprimento.flag ? "active-animation" : ""
								}`}
							>
								<span>{logicaState.comprimento.msg}</span>
							</Button>
						</Card.Footer>
					</Card>
				</Col>
			</Row>

			{error.flag ? (
				<ModalError
					title={error.title}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</div>
	);
}
