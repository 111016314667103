import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../loading/Loading";

import "../../modal/Modal.scss";
import ModalError from "../../modal/ModalError";
import {
	getAllData,
	getCaminhoes,
	getDateInitMonth,
	getTableColumns,
	insertInto,
	updateTable,
	valorMoeda,
} from "../../../services/Servicos";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ExportToExcel from "../../exportToExcel/ExportToExcel";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import BodyRowManutencoes from "../../table/body/BodyRowManutencoes";
import BodyRowManutencoesItems from "../../table/body/BodyRowManutencoesItems";
import {
	updateCaminhoes,
	updateManutencoesList,
} from "../../../redux/slicers/user";
import ModalCancelamento from "./ModalCancelamento";
import ModalEdicao from "./ModalEdicao";

export default function ModalLancamentoItensEquipamentos(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const formREf = useRef();
	const textArea = useRef();

	const [atualizar, setAtualizar] = useState(0);
	const [error, setError] = useState(false);
	const [showCardLancamento, setShowCardLancamento] = useState(true);
	const [showTitle, setShowTitle] = useState({ item: "", show: false });

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		responsavel: String(user.name).toUpperCase(),
		cpf_responsavel: user.email,
	});

	const atualizarQtde = async () => {
		const date = new Date();
		const dateCorrect = getDateInitMonth(date);
		const url = user.tipo.toLowerCase().includes("gestor")
			? "caminhoes"
			: "manutencao";
		let data1 = await getCaminhoes(user.tipo);

		dispatch(updateCaminhoes(data1));
		setTimeout(() => {
			setLoading({
				isLoading: false,
				msg: "Salvar",
				style: "primary",
			});
		}, 500);
	};

	const createItem = async () => {
		if (!body.descricao) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Abastecimento",
					motivo: "Descreva o Item.",
				},
			});
			return;
		}
		// if (!body.km_previsao && !body.data_previsao && body.tipo !== 'CORRETIVA') {
		//     setError({
		//         flag: true,
		//         title: 'Erro',
		//         msg: {
		//             erro: 'Lançamento de Abastecimento',
		//             motivo: 'Km de Previsão ou Data de Previsao devem ser Inserido!'
		//         }
		//     })
		//     return
		// }

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});

		let bodySend = {
			data_cadastro: new Date().toISOString(),
			placa: props.bodyList.placa,
		};

		Object.keys(body).map((item) => {
			if (item.includes("data")) {
				bodySend[item] = body[item];
				return;
			}
			if (item.includes("valor")) {
				bodySend[item] = Number(
					body[item].toUpperCase().replace(".", "").replace(",", ".")
				);
				return;
			}
			if (item.includes("cpf_responsavel")) {
				bodySend[item] = body[item];
				return;
			}

			bodySend[item] = body[item]
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		const url = "caminhoes/equipamentos";

		// return
		insertInto(url, bodySend)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				setBody({
					responsavel: String(user.name).toUpperCase(),
					observacoes: "",
				});
				setAtualizar((last) => last + 1);
				formREf.current.reset();

				atualizarQtde();
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setError({
					flag: true,
					title: "Atenção",
					msg: error,
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onClickButton = async () => {
		setLoading({ isLoading: true, style: "warning", msg: "Aguarde" });
		await createItem();
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const editValue = (e) => {
		let valor = String(e.target.value)
			.replaceAll(".", "")
			.replace(",", "")
			.replace(/\D/g, "");

		if (e.target.name == "aviso_data") {
			setBody((last) => ({
				...last,
				[e.target.name]: Number(Number(valor) * 1).toLocaleString("pt-br", {
					minimumFractionDigits: 0,
				}),
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: Number(Number(valor) * 0.01).toLocaleString("pt-br", {
				minimumFractionDigits: 2,
			}),
		}));
	};
	const handlerSimpleClose = () =>
		props.setShow((last) => ({ error: { flag: false } }));

	const [servicos, setServicos] = useState([]);
	const getServicos = async () => {
		const url = "manutencao/servicos";
		try {
			const data1 = await getAllData(url);
			setServicos(data1.data);
		} catch (e) {
			setError({
				flag: true,
				title: "Atenção",
				msg: e,
			});
		}
	};

	useEffect(() => {}, []);

	return (
		<>
			<Modal
				show={props.flag}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size={!showCardLancamento ? "md" : "xl"}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.title}: Caminhão {props.bodyList.placa}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TabelaItens
						hidden={!showCardLancamento}
						placa={props.bodyList.placa}
						atualizar={atualizar}
					/>
					<Form hidden={showCardLancamento} ref={formREf}>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Descrição:</Form.Label>
							</Col>
							<Col>
								<Col>
									<Form.Control
										type="text"
										name="descricao"
										onChange={onChangeInput}
										value={body?.descricao}
									/>
								</Col>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Qtde:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="qtde"
									onChange={editValue}
									value={body?.qtde}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Responsável:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={onChangeInput}
									value={body.responsavel}
									name="responsavel"
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Observações:</Form.Label>
							</Col>
							<Col>
								<textarea
									ref={textArea}
									onChange={onChangeInput}
									value={body?.observacoes}
									name="observacoes"
									style={{ width: "100%" }}
									rows={4}
								></textarea>
							</Col>
						</Row>
					</Form>
					{error.flag ? (
						<ModalError
							show={error.flag}
							setShow={setError}
							title={error.title}
							msg={error.msg}
						/>
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant="info"
								onClick={() => {
									setShowCardLancamento((last) => !last);
								}}
							>
								{showCardLancamento ? (
									<span>Lancamentos</span>
								) : (
									<span>Tabela</span>
								)}
							</Button>
						</Col>
						<Col style={{ display: showCardLancamento ? "none" : "flex" }}>
							<Button variant={loading.style} onClick={onClickButton}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function TabelaItens(props) {
	const [error, setError] = useState({});
	const [headList, setHeadList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const userS = useSelector((state) => state.userSlice);
	const tableDiv = useRef();
	const tbodyRef = useRef();
	const getColumns = async () => {
		try {
			const columns = await getTableColumns("CaminhaoEquipamento");
			setHeadList((last) => [
				"index",
				"admin",
				...Object.keys(columns.data)
					.filter((item) => item !== "empresas_id")
					.filter((item) => item !== "created_at")
					.filter((item) => item !== "updated_at")
					.filter((item) => item !== "cpf_responsavel")
					.filter((item) => item !== "user_cancelamento")
					.filter((item) => item !== "data_cancelamento")
					.filter((item) => item !== "manutencao_id")
					.filter((item) => item !== "fornecedor_cnpj"),
			]);
		} catch (error) {
			setError({
				flag: true,
				msg: { t: "Erro na obentção das Colunas" },
				title: "Aviso!",
			});
		}
	};

	const getData = useCallback(async (placa) => {
		const url = "caminhoes/equipamentos";
		try {
			const data = await getAllData(url, { placa });
			setDataTable(
				data.data.map((item) => ({
					...item,
					data_cadastro: new Date(item.data_cadastro).toLocaleDateString(
						"pt-br",
						{ timeZone: "UTC" }
					),
				}))
			);
		} catch (error) {
			setError({
				flag: true,
				msg: error,
				title: "Aviso!",
			});
		}
	});

	useEffect(() => {
		getData(props.placa);
	}, [props.atualizar]);

	// dados de entrada
	useEffect(() => {
		getColumns();
	}, []);

	// scrollBar
	useEffect(() => {
		const refTable = tableDiv.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const scrollPosition = e.target.scrollTop;
			const tableHeight = e.target.clientHeight;

			if (scrollPosition >= tbodyHeight - tableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [dataTable];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"placa"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<div hidden={props.hidden}>
			<div className="section-filters-table">
				<h3>Filtrar Manutenções</h3>
				<hr />

				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Dados{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</Col>

						<Col sm="12" md="6" lg="3">
							<ExportToExcel
								tableName="Manutencoes_Items"
								reference={
									filteredTable.length ? filteredTable : [...dataTable]
								}
							/>
						</Col>
					</Row>
				</div>

				<div
					className="responsive-table"
					ref={tableDiv}
					style={{ position: "relative", height: "600px", maxHeight: "unset" }}
				>
					<ScrollbarLeft
						tableRef={tableDiv}
						coluna2={2}
						maxima_distancia={50}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="frota"
								arrayValues={dataTable}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>

						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={self.length}
												/>
											);
										}
								  })
								: dataTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowLocal
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setDataTable={setDataTable}
													tableLength={self.length}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < dataTable.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>

			{error.flag ? (
				<ModalError
					title={error.title}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</div>
	);
}

function BodyRowLocal({
	headList,
	bodyList,
	index,
	setDataTable = () => {},
	tableLength = 0,
	setAtualizar = () => {},
}) {
	const refRow = useRef(null);
	const dispatch = useDispatch();
	const userS = useSelector((state) => state.userSlice);
	const filiaisList = userS.filiaisList.data;

	const [controleCancelamento, setControleCancelamento] = useState({
		falg: false,
	});
	const [controleEdicao, setControleEdicao] = useState({ falg: false });
	const [error, setError] = useState({
		flag: false,
		title: "",
		msg: "",
	});

	const getData = useCallback(async (placa) => {
		const url = "caminhoes/equipamentos";
		try {
			const data = await getAllData(url, { placa });
			setDataTable(
				data.data.map((item) => ({
					...item,
					data_cadastro: item.data_cadastro
						? new Date(item.data_cadastro).toLocaleDateString("pt-br", {
								timeZone: "UTC",
						  })
						: "",
				}))
			);

			let data1 = await getCaminhoes(userS.tipo);

			dispatch(updateCaminhoes(data1));
		} catch (error) {
			setError({
				flag: true,
				msg: error,
				title: "Aviso!",
			});
		}
	});

	const onClickCancelItem = async () => {
		const url = "caminhoes/equipamentos";

		await updateTable(url, bodyList.id, {
			data_cancelamento: new Date(),
			user_cancelamento: userS.name,
			placa: bodyList.placa,
		}); // cancelado
		getData(bodyList.placa);
		setAtualizar((last) => last + 1);
	};

	const onClickEditar = async (bodyUpdate) => {
		const url = "caminhoes/equipamentos";
		await updateTable(url, bodyList.id, {
			...bodyUpdate,
			manutencao_id: bodyList.manutencao_id,
		});
		// getData(bodyList.manutencao_id)

		setAtualizar((last) => last + 1);
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
		// setCargaSelecionada({
		//     ...bodyList
		//     , disponivel: bodyList.status === 0 ? true : false
		// })
	};

	return (
		<Fragment>
			{
				<tr
					ref={refRow}
					style={{ verticalAlign: "middle" }}
					onClick={onClickTableRow}
				>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Excluir"
												onClick={() =>
													setControleCancelamento({
														bodyList,
														flag: true,
														title: "Cancelar Manutencao",
														fn: onClickCancelItem,
													})
												}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "placa") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "id_filial") {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} className={item}>
									{bodyList[item] == 2 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-success">
												Finalizado
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 1 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-warning">
												Em Manutencao
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == 0 ? (
										<div className="div-status">
											<span className="div-status-ativo bg-info">Agendado</span>
										</div>
									) : (
										""
									)}
								</td>
							);
						} else if (item === "createdAt" || item === "updatedAt") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "10rem" }}>
									{bodyList[item]}
								</td>
							);
						} else if (item === "observacoes") {
							/* primary key can not be modified */

							return (
								<td
									key={_index}
									style={{ minWidth: "10rem", cursor: "pointer" }}
									onClick={() => {
										if (!bodyList.observacoes) return;
										setError({
											title: "OBSERVAÇÕES DO LANÇAMENTO N°" + bodyList.id,
											msg: bodyList.observacoes,
											flag: true,
										});
									}}
								>
									{bodyList[item]
										? String(bodyList[item]).slice(0, 50) + "..."
										: "N/A"}
								</td>
							);
						} else if (item.includes("km")) {
							return (
								<td key={_index}>
									<div className="admin">
										{Number(bodyList[item]).toLocaleString("pt-br", {
											minimumFractionDigits: 2,
										})}
									</div>
								</td>
							);
						} else if (item === "valor") {
							return (
								<td key={_index}>
									<div className="admin">{valorMoeda(bodyList[item])}</div>
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}

			{controleCancelamento.flag ? (
				<ModalCancelamento
					{...controleCancelamento}
					setShow={setControleCancelamento}
				/>
			) : (
				""
			)}
			{controleEdicao.flag ? (
				<ModalEdicao {...controleEdicao} setShow={setControleEdicao} />
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					show={error.flag}
					setShow={setError}
					msg={error.msg}
					title={error.title}
				/>
			) : (
				""
			)}
			{/* {historicoPneu.flag ? <ModalError show={historicoPneu.flag} setShow={setHistoricoPneu} msg={error.msg} title={error.title}/> : ""} */}
		</Fragment>
	);
}
