import { Button, Modal } from "react-bootstrap"

import './Modal.scss'


export default function ModalAtribuido({ show, setShow, msgAlert }) {

    const handlerSimpleClose = () => setShow(last => ({...last, flag: false, returned: false}))
    const handlerSimpleConfirm = () => {
        setShow(last => ({...last, flag: false, returned: true}))}

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Caminhao {show.placa} já atribuido a:</h4>
                    <p><i>Caminhoneiro: {msgAlert.nome}</i></p>
                    <p><i>CPF: {msgAlert.cpf}</i></p>
                    <p><i>CNH: {msgAlert.cnh}</i></p>
                    <p><i>Telefone: {msgAlert.telefone}</i></p>
                    <h3 style={{marginTop:'50px'}}>Deseja Continuar com a atribuição?</h3>
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border">
                    <Button variant='primary' onClick={handlerSimpleConfirm}>Ok</Button>
                    <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
