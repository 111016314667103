import { useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	updateEmbalagensList,
	updateProdutosList,
} from "../../redux/slicers/user";

import "./Modal.scss";

export default function ModalSelectColor({
	msg,
	title,
	show,
	setShow,
	setProdutoSelecionado = () => {},
	produtoSelecionado,
}) {
	const colorPicker = useRef();

	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, flag: false, msg: "" }));
	const [color, setColor] = useState(
		produtoSelecionado?.cor_caixa || "#000000"
	);

	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const embalagens = useSelector((state) => state.userSlice.embalagensList);
	const dispatch = useDispatch();

	const showColorPicker = () => {
		colorPicker.current.click();
	};

	const onClickSalvar = (e) => {
		const cor_selecionada =
			e.target.name === "padrao" ? "#d5b59c" : colorPicker.current.value;
		if (produtoSelecionado.tipo === "EMBALAGEM") {
			const embalagensAtualizadas = [...embalagens.data].map((item) => ({
				...item,
				cor_caixa:
					item.qr_sku === produtoSelecionado.qr_sku
						? cor_selecionada
						: item.cor_caixa,
			}));

			dispatch(updateEmbalagensList(embalagensAtualizadas));
		} else {
			const produtosAtualizados = [...produtosRomaneio.data].map((item) => {
				return {
					...item,
					cor_caixa:
						item.numero_pedido === produtoSelecionado.numero_pedido
							? cor_selecionada
							: item.cor_caixa,
				};
			});
			dispatch(updateProdutosList(produtosAtualizados));
		}
		setProdutoSelecionado((last) => ({ ...last, cor_caixa: cor_selecionada }));
		handlerSimpleClose();
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card>
						<Card.Body
							style={{
								backgroundColor: color,
								borderRadius: "10px 10px 0px 0px",
							}}
						>
							<Card.Title
								className="text-center p-2"
								style={{ color: color, filter: "invert(1)" }}
							>
								{color}
							</Card.Title>
							<div style={{ position: "relative" }}>
								<Form.Control
									type="color"
									ref={colorPicker}
									onChange={(e) => setColor(e.target.value)}
									style={{
										position: "absolute",
										left: "65px",
										bottom: "-74px",
										zIndex: "-1",
									}}
								/>
							</div>
						</Card.Body>
						<Card.Footer className="justify-content-between">
							<Row className="my-3">
								<Col>
									<Button
										style={{ backgroundColor: "#d5b59c" }}
										name="padrao"
										onClick={onClickSalvar}
									>
										Padrão
									</Button>
								</Col>
								<Col>
									<Button variant="primary" onClick={onClickSalvar}>
										Salvar
									</Button>
								</Col>
								<Col>
									<Button variant="info" onClick={showColorPicker}>
										Selecionar
									</Button>
								</Col>
								<Col>
									<Button variant="secondary" onClick={handlerSimpleClose}>
										Fechar
									</Button>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
				</Modal.Body>
			</Modal>
		</>
	);
}
