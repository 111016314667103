import { createRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";
import {
	confirmarTokenAdicional,
	saveNewPassword,
} from "../../services/Servicos";
import { update } from "../../redux/slicers/user";
import { useDispatch } from "react-redux";
// import './ResetPassword.scss'

const form = createRef(null);
const senhasDiferentes = createRef(null);
const minLengthError = createRef(null);

export function ConfirmacaoAdicionalCredito() {
	const dispatch = useDispatch();

	const [btnLoading, setBtnLoading] = useState({
		flag: false,
		msg: "Aprovar",
		style: "primary",
	});

	const [btnLoadingCancelar, setBtnLoadingCancelar] = useState({
		flag: false,
		msg: "Reprovar",
		style: "secondary",
	});

	const [error, setError] = useState({
		flag: false,
		msg: "",
		color: "red",
		errorLength: false,
	});

	const confirmarLiberacao = (e) => {
		e.preventDefault();
		setError({
			flag: false,
		});
		const queryString = window.location.search;
		const params = new URLSearchParams(queryString);
		const token = params.get("token");
		setBtnLoading({
			flag: true,
			msg: "Aprovando...",
			style: "warning",
		});
		confirmarTokenAdicional({
			token,
			aprovado: true,
			link: document.location.origin,
		})
			.then((response) => {
				/* eu te amo meu amor */

				setTimeout(() => {
					setBtnLoading({
						flag: false,
						msg: "Aprovado",
						style: "success",
					});
					dispatch(update({ authenticated: false }));
					window.location.replace("/login");
				}, 2000);
			})
			.catch((err) => {
				setError({
					flag: true,
					msg: err.msg,
				});
				setBtnLoading({
					flag: false,
					msg: "Error",
					style: "danger",
				});
				setTimeout(() => {
					setBtnLoading({
						flag: false,
						msg: "Aprovar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const negarLiberacao = async (e) => {
		e.preventDefault();
		setError({
			flag: false,
		});
		const queryString = window.location.search;
		const params = new URLSearchParams(queryString);
		const token = params.get("token");
		const carga = params.get("carga");
		setBtnLoadingCancelar({
			flag: true,
			msg: "Reprovando...",
			style: "warning",
		});

		confirmarTokenAdicional({ token, aprovado: false })
			.then((response) => {
				setTimeout(() => {
					setBtnLoadingCancelar({
						flag: false,
						msg: "Reprovando!!!",
						style: "success",
					});
					dispatch(update({ authenticated: false }));
					window.location.replace("/login");
				}, 2000);
			})
			.catch((err) => {
				setError({
					flag: true,
					msg: err.msg,
				});

				setBtnLoadingCancelar({
					flag: false,
					msg: "Error",
					style: "danger",
				});

				setTimeout(() => {
					setBtnLoadingCancelar({
						flag: false,
						msg: "Reprovar",
						style: "secondary",
					});
				}, 1000);
			});
	};

	return (
		<>
			<Row className="login mx-auto">
				<Col lg={10} className="mx-auto">
					<Form className="login-form">
						<Row>
							<h1>Liberação Adicional de Valores </h1>
							<div className="text-muted">
								Você esta em um ambiente Seguro da B-LOG
							</div>
						</Row>
						<hr />
						<Row>
							<Col sm="6">
								<Button
									variant={btnLoading.style}
									type="submit"
									onClick={confirmarLiberacao}
								>
									{btnLoading.flag ? (
										<Loading msg={btnLoading.msg} />
									) : (
										<span style={{ color: "#ffffff" }}>
											{btnLoading.msg}
											{btnLoading.style === "success" ? (
												<i className="bi bi-check"></i>
											) : (
												""
											)}
										</span>
									)}
								</Button>
							</Col>
							<Col sm="6">
								<Button
									variant={btnLoadingCancelar.style}
									type="submit"
									onClick={negarLiberacao}
								>
									{btnLoadingCancelar.flag ? (
										<Loading msg={btnLoadingCancelar.msg} />
									) : (
										<span style={{ color: "#ffffff" }}>
											{btnLoadingCancelar.msg}
											{btnLoadingCancelar.style === "success" ? (
												<i className="bi bi-check"></i>
											) : (
												""
											)}
										</span>
									)}
								</Button>
							</Col>
						</Row>
						<div className="text-muted mt-3">{error ? error.msg : ""}</div>
					</Form>
				</Col>
			</Row>
		</>
	);
}
