import { cpf_mask, getAllData, locationDev, telefone_mask } from "../../services/Servicos";
import semFoto from '../../assets/perfil_sem_foto.jpg'
import { useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";

function CardMotorista({children: motorista, onClickVerPdf = null, onClickVerExcel = null, onClickLoading = null}) {
  const { name, id_filial, cpf, telefone, cargas_entregues: totalCargas, caminhao, status, uuid, loading } = motorista
  
  const user = useSelector(state => state.userSlice)
  const filial = user.filiaisList.data.find(item => item.idFilial == id_filial)?.identificacaoInterna || user.id_filial

  let cpfEdited = cpf_mask(cpf.replace(/\D/g,''))
  let telefoneEdited = telefone_mask(telefone.replace(/\D/g, ''))
  let statusAtual = ''
  let [nome, sobrenome] = name?.split(' ')
  let [urlFoto, setUrl] = useState(semFoto)

  useEffect(() => {
    if(!motorista.foto_perfil) return
    setUrl('https://bnautomacao.com.br' + '/files/users'+ motorista.foto_perfil)
  }, [])
  

  if (status) {
    statusAtual = <span class="div-status-ativo">Ativo</span>
  } else {
    statusAtual = <span class="div-status-inativo">Inativo</span>
  }

  function handlerClickPdf() {
    if (onClickVerPdf) {
      onClickVerPdf({cpf, motorista: name, cpf, cpfEdited, telefone: telefoneEdited, filial})
    }
  }
  function handlerClickExcel() {
    if (onClickLoading) {
      onClickLoading(uuid, true)
    }
    if (onClickVerExcel) {
      onClickVerExcel({cpf, motorista: name, cpf, cpfEdited, telefone: telefoneEdited, filial, uuid})
    }
  }

  return <div className="monitoramento-pedido-list  border shadow my-4 p-3" style={{width: '400px', borderRadius:'10px', backgroundColor:'#fff', cursor:'default' }}>
    <div className="d-flex flex-col column-gap-3">
      <img className='shadow' src={urlFoto} width={150} style={{maxHeight:'150px',}}/>
      <ul style={{listStyleType:'none'}}>
        <CardItem title={<i className="bi bi-shop"></i>} value={ filial } />
        <CardItem title={<i className="bi bi-person"></i>} value={ `${nome} ${sobrenome ?? ''}` } />
        <CardItem title={<i className="bi bi-question"/>} value={ statusAtual } />
        <CardItem title={<i className="bi bi-card-list"></i>} value={ cpfEdited } />
        <CardItem title={<i className="bi bi-phone"></i>} value={ telefoneEdited } />
        <CardItem title={<i className="bi bi-truck"></i>} value={ caminhao || 'Sem Caminhão' } />
        <CardItem title={<i className="bi bi-pin-map-fill"></i>} value={ totalCargas } />
      </ul>
    </div>
    <div className="d-flex justify-content-between">
      <button className={`${loading ? 'btn-warning':'btn-success'} button-report mb-2 mt-4`} style={{ width: '150px', borderRadius:'5px' }} variant="dark" onClick={handlerClickExcel}>
        { !loading ? <i class="bi bi-file-earmark-excel"></i> :<Spinner size="sm" color="black" animation="border"/>  }
        Excel
      </button>
      <button className='btn-dark button-report mb-2 mt-4' style={{ width: '150px', borderRadius:'5px' }} variant="dark" onClick={handlerClickPdf}>
        <i class="bi bi-file-earmark-pdf"></i>
        PDF
      </button>
    </div>
  </div>;
}

function CardItem({ title = 'Titulo', value = 'Valor' }) {
  return <li className="d-flex flex-col column-gap-1 my-1 align-items-center">
    <span>{ title }</span>
    <span style={{fontSize:'12px', whiteSpace:'break-spaces', fontWeight:'700'}}>{ value }</span>
  </li>
}

export default CardMotorista;{}